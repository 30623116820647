import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { withStyles } from "@mui/styles";
import i18next from "i18next";

const styles = (theme) => ({
  label: {
    "&$focusedLabel": {
      color: theme.palette.primary.main,
    },
    "&$errorLabel": {
      color: theme.palette.error.main,
    },
  },
  focusedLabel: {},
  errorLabel: {},
  underline: {
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
});

const MaterialTextfield = (props) => {
  const { classes, ...other } = props;
  return (
    <TextField
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focusedLabel,
          error: classes.errorLabel,
        },
      }}
      InputProps={{
        classes: {
          root: classes.underline,
        },
        inputProps: { maxLength: props.maxLength ? props.maxLength : 240 },
        endAdornment: (
          <InputAdornment position="end">
            {props.type !== "number" &&
            props.label !== i18next.t("Search") &&
            props.adornment !== "no_endAdornment"
              ? props.value
                ? `${props.value.length}/240`
                : ""
              : ""}
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      variant="standard"
      {...other}
    />
  );
};

export default withStyles(styles)(MaterialTextfield);
