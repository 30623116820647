import * as types from "./types";
import { core } from "./core";
import { isWeekStartWithMonday } from "@/utils/dateTimeFunctions";

export const getCompanyUsers = (queryString) => {
  if (!queryString) {
    queryString = `limit=1000`;
  }

  return core.API(
    "GET",
    `/users/${queryString ? `?${queryString}` : ""}`,
    types.GET_COMPANY_USERS,
    types.GET_COMPANY_USERS_SUCCESS,
    types.GET_COMPANY_USERS_FAILURE
  );
};

export const getPlans = (queryString) => {
  if (!queryString) {
    queryString = "group_by=user";
  }

  return core.API(
    "GET",
    `/planning/planned-hours/${queryString ? `?${queryString}` : ""}`,
    types.GET_PLANS,
    types.GET_PLANS_SUCCESS,
    types.GET_PLANS_FAILURE
    //data
  );
};

export const createPlan = (data) => {
  return core.API(
    "POST",
    `/planning/planned-hour`,
    types.CREATE_PLAN,
    types.CREATE_PLAN_SUCCESS,
    types.CREATE_PLAN_FAILURE,
    data
  );
};

export const getPlan = (id) => {
  return core.API(
    "GET",
    `/planning/planned-hour/${id}`,
    types.GET_PLAN,
    types.GET_PLAN_SUCCESS,
    types.GET_PLAN_FAILURE
  );
};

export const deletePlan = (id) => {
  return core.API(
    "DELETE",
    `/planning/planned-hour/${id}`,
    types.DELETE_PLAN,
    types.DELETE_PLAN_SUCCESS,
    types.DELETE_PLAN_FAILURE
  );
};

export const editPlan = (id, data) => {
  return core.API(
    "PUT",
    `/planning/planned-hour/${id}`,
    types.UPDATE_PLAN,
    types.UPDATE_PLAN_SUCCESS,
    types.UPDATE_PLAN_FAILURE,
    data
  );
};

export const getAvailability = (id, day, week) => {
  return core.API(
    "GET",
    `/user/${id}/availability/?${day ? `day=${day}` : ""}${
      week ? `&week=${week}` : ""
    }${isWeekStartWithMonday() ? `&week_start=Monday` : ""}`,
    types.GET_AVAILABILITY,
    types.GET_AVAILABILITY_SUCCESS,
    types.GET_AVAILABILITY_FAILURE
  );
};

export const getSpecificPlanUsers = (queryString) => {
  return core.API(
    "GET",
    `/planning/planned-hours/specific-task-users/${
      queryString ? `?${queryString}` : ""
    }`,
    types.GET_SPECIFIC_PLAN_USERS,
    types.GET_SPECIFIC_PLAN_USERS_SUCCESS,
    types.GET_SPECIFIC_PLAN_USERS_FAILURE
  );
};

export const createPlanMultipleProject = (data) => {
  return core.API(
    "POST",
    `/planning/planned-hours/multiple`,
    types.CREATE_PLAN_MULTI_PROJECT,
    types.CREATE_PLAN_MULTI_PROJECT_SUCCESS,
    types.CREATE_PLAN_MULTI_PROJECT_FAILURE,
    data
  );
};

export const assignPlan = (id) => {
  return core.API(
    "PUT",
    `/planning/planned-hour/${id}/assign`,
    types.ASSIGN_PLAN,
    types.ASSIGN_PLAN_SUCCESS,
    types.ASSIGN_PLAN_FAILURE
  );
};

export const approvePlan = (id, data = null) => {
  return core.API(
    "PUT",
    `/planning/planned-hour/${id}/approve`,
    types.APPROVE_PLAN,
    types.APPROVE_PLAN_SUCCESS,
    types.APPROVE_PLAN_FAILURE,
    data
  );
};

export const rejectPlan = (id, data = null) => {
  return core.API(
    "PUT",
    `/planning/planned-hour/${id}/reject`,
    types.REJECT_PLAN,
    types.REJECT_PLAN_SUCCESS,
    types.REJECT_PLAN_FAILURE,
    data
  );
};

export const approveUserPlans = (userId, data) => {
  return core.API(
    "PUT",
    `/user/${userId}/planning/planned-hour/approve`,
    types.APPROVE_USER_PLAN,
    types.APPROVE_USER_PLAN_SUCCESS,
    types.APPROVE_USER_PLAN_FAILURE,
    data
  );
};

export const rejectUserPlans = (userId, data) => {
  return core.API(
    "PUT",
    `/user/${userId}/planning/planned-hour/reject`,
    types.REJECT_USER_PLAN,
    types.REJECT_USER_PLAN_SUCCESS,
    types.REJECT_USER_PLAN_FAILURE,
    data
  );
};

export const getPlanApprovalUsers = (queryString) => {
  return core.API(
    "GET",
    `/planning/planned-hour/users${queryString ? `?${queryString}` : ""}`,
    types.GET_PLAN_APPROVAL_USERS,
    types.GET_PLAN_APPROVAL_USERS_SUCCESS,
    types.GET_PLAN_APPROVAL_USERS_FAILURE
  );
};

export const getPlanApprovalDetails = (id, queryString) => {
  return core.API(
    "GET",
    `/user/${id}/v2/planning/planned-hour/approvals/${
      queryString ? `?${queryString}` : ""
    }`,
    types.GET_PLAN_APPROVAL_DETAILS,
    types.GET_PLAN_APPROVAL_DETAILS_SUCCESS,
    types.GET_PLAN_APPROVAL_DETAILS_FAILURE
  );
};

// approval by plans
export const getPlanApprovalPlans = (queryString) => {
  return core.API(
    "GET",
    `/approval/planning/planned-hours${queryString ? `?${queryString}` : ""}`,
    types.GET_PLAN_APPROVAL_PLANS,
    types.GET_PLAN_APPROVAL_PLANS_SUCCESS,
    types.GET_PLAN_APPROVAL_PLANS_FAILURE
  );
};

export const getPlanApprovalUserDetails = (id, queryString) => {
  return core.API(
    "GET",
    `/planning/planned-hour/${id}/users/${
      queryString ? `?${queryString}` : ""
    }`,
    types.GET_PLAN_APPROVAL_USER_DETAILS,
    types.GET_PLAN_APPROVAL_USER_DETAILS_SUCCESS,
    types.GET_PLAN_APPROVAL_USER_DETAILS_FAILURE
  );
};

export const getPlanSchedules = (queryString) => {
  return core.API(
    "GET",
    `/planning/plan-schedules${queryString ? `?${queryString}` : ""}`,
    types.GET_PLAN_SCHEDULES,
    types.GET_PLAN_SCHEDULES_SUCCESS,
    types.GET_PLAN_SCHEDULES_FAILURE
  );
};

export const createPlanSchedule = (data) => {
  return core.API(
    "POST",
    `/planning/plan-schedule`,
    types.CREATE_PLAN_SCHEDULE,
    types.CREATE_PLAN_SCHEDULE_SUCCESS,
    types.CREATE_PLAN_SCHEDULE_FAILURE,
    data
  );
};

export const getPlanSchedule = (id) => {
  return core.API(
    "GET",
    `/planning/plan-schedule/${id}`,
    types.GET_PLAN_SCHEDULE,
    types.GET_PLAN_SCHEDULE_SUCCESS,
    types.GET_PLAN_SCHEDULE_FAILURE
  );
};

export const deletePlanSchedule = (id) => {
  return core.API(
    "DELETE",
    `/planning/plan-schedule/${id}`,
    types.DELETE_PLAN_SCHEDULE,
    types.DELETE_PLAN_SCHEDULE_SUCCESS,
    types.DELETE_PLAN_SCHEDULE_FAILURE
  );
};

export const updatePlanSchedule = (id, data) => {
  return core.API(
    "PUT",
    `/planning/plan-schedule/${id}/`,
    types.PUT_PLAN_SCHEDULE,
    types.PUT_PLAN_SCHEDULE_SUCCESS,
    types.PUT_PLAN_SCHEDULE_FAILURE,
    data
  );
};
