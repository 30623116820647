import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { makeStyles } from "@mui/styles";
import { Typography, InputAdornment } from "@mui/material";
import moment from "moment";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import { Search } from "@mui/icons-material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import MaterialTextfield from "@/common/MaterialTextfield";

const useStyles = makeStyles((theme) => ({
  RtableCell: {
    boxSizing: "border-box",
    flexGrow: 1,
    //width: "12.5%", // Default to full width
    //width: 220, // for scrollable table use digits
    minWidth: 170,
    width: "calc(100vw / 8)",
    //padding: "0.8em 1.2em",
    overflow: "hidden", // Or flex might break
    listStyle: "none",
    border: `0.5px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    textAlign: "center",
    position: "sticky",
    top: 0,
    zIndex: 1,
    boxShadow: `${theme.customShadows.planningCard}`,
  },
  dayText: {
    fontSize: 20,
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
  },
  dateView: {
    display: "flex",
    marginBottom: 8,
    alignItems: "center",
    textAlign: "center",
    marginTop: -2,
  },
  dateText: {
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "end",
    color: theme.palette.text.disabled,
    marginLeft: 8,
    fontWeight: 700,
  },
  hourText: {
    color: theme.palette.text.secondary,
    fontSize: 12,
  },
  separator: {
    marginLeft: 5,
    marginRight: 5,
  },
  timerIcon: {
    color: theme.palette.text.disabled,
    fontSize: 16,
  },
  dateViewIcons: {
    display: "flex",
    paddingRight: 2,
  },
  bgToday: {
    backgroundColor: theme.palette.hourTodayBackground,
  },
  webkitSticky: {
    position: "-webkit-sticky",
  },
  searchSticky: {
    backgroundColor: theme.palette.background.paper,
    zIndex: "2",
    left: 0,
    display: "flex",
    alignItems: "center",
  },
  weekRow: {
    width: "100%",
  },
  weekHeaderContainer: {
    alignItems: "end",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
  },
  weekSubHeaderContainer: {
    alignItems: "end",
    paddingLeft: 12,
    paddingTop: 4,
    height: "auto",
  },
}));

function WeekHeader({
  changeState,
  weekdays,
  weekDayTotalHours,
  groupBy,
  planSchedule,
  searchUser,
  searchProject,
  startDate,
}) {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState("");
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setSearchStr("");
  }, [groupBy]);

  const handleSearchInputOnChange = (e) => {
    clearTimeout(timer);

    const localTimer = setTimeout(async () => {
      await changeState({
        searchVal: e.target.value,
      });

      if (groupBy === "user") {
        searchUser();
      } else {
        searchProject();
      }
    }, 1000);

    setTimer(localTimer);
  };

  return (
    <React.Fragment>
      <div
        className={`${classes.RtableCell} ${classes.webkitSticky} ${classes.searchSticky}`}
        style={{
          width: weekdays.length === 7 ? `calc((100vw / 8) - 16px)` : 220,
          height: 74,
        }}
      >
        <MaterialTextfield
          label={i18next.t(`Search`)}
          type="text"
          value={searchStr}
          adornment="no_endAdornment"
          onChange={(e) => {
            setSearchStr(e.target.value);
            handleSearchInputOnChange(e);
          }}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ mx: 1 }}
        />
      </div>

      {weekdays.map((data, index) => {
        return (
          <div
            className={classes.RtableCell}
            style={{
              width: weekdays.length === 7 ? `calc((100vw / 8) - 16px)` : 220,
              height: 74,
            }}
            key={index}
          >
            <div className={classes.weekRow}>
              <div className={classes.weekHeaderContainer}>
                <div style={{ display: "flex", alignItems: "end" }}>
                  <Typography variant="h2" className={classes.dayText}>
                    {moment(startDate).add(data.index, "days").format("ddd")}
                  </Typography>
                  <Typography variant="h2">
                    {planSchedule ? (
                      <Typography component="p" className={classes.dateText}>
                        {data.index % 7 === 0
                          ? `Week ${data.index / 7 + 1}`
                          : ""}
                      </Typography>
                    ) : (
                      <Typography component="p" className={classes.dateText}>
                        {startDate
                          ? moment(startDate)
                              .clone()
                              .add(data.index, "days")
                              .format("DD MMM")
                          : ""}
                      </Typography>
                    )}
                  </Typography>
                </div>
              </div>

              <div className={classes.weekSubHeaderContainer}>
                <Typography component="div" className={classes.dateView}>
                  {weekDayTotalHours && weekDayTotalHours[data.index] ? (
                    <>
                      <Typography
                        component="div"
                        className={classes.dateViewIcons}
                      >
                        <AccessTimeFilledIcon className={classes.timerIcon} />
                      </Typography>
                      <Typography component="div" className={classes.hourText}>
                        <DecimalToHoursMin>
                          {weekDayTotalHours[data.index]}
                        </DecimalToHoursMin>
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default React.memo(WeekHeader);
