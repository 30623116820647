import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddUserForm from "./components/AddUserForm";
//import i18next from "i18next";

import {
  getDepartmentList,
  getUserGroupList,
  addNewUser,
} from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
  },
}));

const AddUser = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [departmentList, setDepartmentList] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    async function getDepartments() {
      let departmentList = [];
      try {
        const response = await getDepartmentList();
        if (response && response.data && response.data.entries) {
          let entries = response.data.entries;
          for (let i = 0; i < entries.length; i++) {
            let element = entries[i];
            departmentList.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        console.log(message);
      }
      setDepartmentList(departmentList);
    }

    async function getUserGroups() {
      let userGroupList = [];
      try {
        const response = await getUserGroupList();
        if (response && response.data && response.data.entries) {
          let entries = response.data.entries;
          for (let i = 0; i < entries.length; i++) {
            let element = entries[i];
            userGroupList.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        console.log(message);
      }
      setUserGroupList(userGroupList);
    }

    getDepartments();
    getUserGroups();
  }, []);

  const handleFormSubmit = async (params) => {
    try {
      const response = await addNewUser(params);
      //console.log(response);
      if (response && response.data) {
        if (response.data.id) {
          setSuccessMsg(response.data.message);
        } else {
          setErrorMsg("Error");
        }
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      setErrorMsg(message);
      console.log(message);
    }

    // clear message after 10 sec
    setTimeout(() => {
      setErrorMsg("");
      setSuccessMsg("");
    }, 20000);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentHeader}>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className={classes.contentBody}>
        <Grid container className={classes.formContainer}>
          <Grid item xl={4} lg={4} md={5} sm={10} xs={10}>
            <AddUserForm
              handleFormSubmit={handleFormSubmit}
              departmentList={departmentList}
              userGroupList={userGroupList}
              errorMsg={errorMsg}
              successMsg={successMsg}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddUser;
