import React, { useState, useEffect } from "react";
import i18next from "i18next";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  mb: {
    marginBottom: "1rem",
  },
  header: {
    marginBottom: 7,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButtons: {
    padding: "0 10px 10px 10px",
  },
}));

const AttendanceStatusModal = (props) => {
  const { isOpen, currentStatus, handleSubmit, handleCancel } = props;

  const classes = useStyles();

  const [isClicked, setIsClicked] = useState(false);

  let title = i18next.t("not-check-in-status-modal-message");
  if (currentStatus === "in") {
    title = i18next.t("check-in-status-modal-message");
  } else if (currentStatus === "out") {
    title = i18next.t("check-out-status-modal-message");
  }

  useEffect(() => {
    // activate the Submit button on modal close
    if (!isOpen && isClicked) {
      setIsClicked(false);
    }
  }, [isOpen, isClicked]);

  const handleSubmitClick = () => {
    setIsClicked(true);
    handleSubmit();
  };

  return (
    <Typography component="div">
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isOpen}
        onClose={handleCancel}
      >
        <DialogTitle onClose={handleCancel} className={classes.header}>
          {i18next.t("Attendance status")}
          <IconButton
            aria-label={i18next.t("Close")}
            className={classes.closeButton}
            onClick={handleCancel}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "0 34px 0 34px" }}>
          <Typography component="div">{title}</Typography>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <Typography component="div">
            <Button className={classes.btnCancel} onClick={handleCancel}>
              {i18next.t("No")}
            </Button>{" "}
            <Button
              type="submit"
              className={classes.button}
              onClick={handleSubmitClick}
              disabled={isClicked}
            >
              {i18next.t("Yes")}
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
    </Typography>
  );
};

export default AttendanceStatusModal;
