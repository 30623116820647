import createReducer from "./createReducer";
import * as types from "@/redux/actions/types";
import { logout } from "@/utils/api.config";

const initialState = {
  isLoading: false,
  data: [],
  geofenceList: {},
  geofenceData: {},
  geofenceSuccess: null,
  geofenceError: null,
};

export const geofenceReducer = createReducer(initialState, {
  [types.GET_GEOFENCES](state) {
    return Object.assign({}, state, {
      ...initialState,
      isLoading: true,
      geofenceError: null,
    });
  },
  [types.GET_GEOFENCES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      geofenceList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_GEOFENCES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      geofenceError: action.payload,
      isLoading: false,
    });
  },
  [types.GET_GEOFENCE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      geofenceData: null,
      geofenceError: null,
    });
  },
  [types.GET_GEOFENCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      geofenceData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_GEOFENCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      geofenceError: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_GEOFENCE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      geofenceSuccess: null,
      geofenceError: null,
    });
  },
  [types.DELETE_GEOFENCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      geofenceSuccess: action.payload,
      geofenceError: null,
      isLoading: false,
    });
  },
  [types.DELETE_GEOFENCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      geofenceError: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_GEOFENCE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      geofenceSuccess: null,
      geofenceError: null,
    });
  },
  [types.UPDATE_GEOFENCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      geofenceSuccess: action.payload,
      geofenceError: null,
      isLoading: false,
    });
  },
  [types.UPDATE_GEOFENCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      geofenceSuccess: null,
      geofenceError: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_GEOFENCE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      geofenceSuccess: null,
      geofenceError: null,
    });
  },
  [types.ADD_GEOFENCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      geofenceSuccess: action.payload,
      geofenceError: null,
      isLoading: false,
    });
  },
  [types.ADD_GEOFENCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      geofenceError: action.payload,
      geofenceSuccess: null,
      isLoading: false,
    });
  },
});
