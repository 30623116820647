import React, { useState, useEffect, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MaterialDatePicker } from "@/common/Pickers";
import AttendanceEditModal from "./AttendanceEditModal";
import AttendanceAddModal from "./AttendanceAddModal";
import { geolocated } from "react-geolocated";
import AlertModal from "@/common/AlertModal";
import OverlayIndicator from "@/common/OverlayIndicator";

import SearchInput from "@/common/SearchInput";
import UsersTable from "@/common/EmployeeTable";
import LightBox from "@/common/LightBox";

import {
  getUserAttendance,
  postUserAttendance,
  updateUserAttendance,
  getCompanySettings,
  getUsersNotCheckIn,
} from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";
import {
  getDateFormat,
  getDateWithFixedTimeFormat,
} from "@/utils/dateTimeFunctions";
import { withSnackbar } from "notistack";

import moment from "moment";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    color: "#ffffff",
    marginTop: 20,
  },
}));

const EmployeeTab = (props) => {
  const classes = useStyles();

  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = props;

  const [userList, setUserList] = useState([]);
  const [userListEntries, setUserListEntries] = useState([]);
  const [limit, setLimit] = useState(10);
  //const [offset, setOffset] = useState(0);
  //const [hasMore, setHasMore] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isPhotoOpen, setIsPhotoOpen] = useState(false);
  const [lightboxUrl, setLightboxUrl] = useState("");
  const [apiQuery, setApiQuery] = useState("");

  const [attendanceId, setAttendanceId] = useState(null);
  const [attendanceUserId, setAttendanceUserId] = useState(null);
  const [attendanceUserName, setAttendanceUserName] = useState(null);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [attendanceTimeToCompare, setAttendanceTimeToCompare] = useState(null);
  const [openAttendanceEditModal, setOpenAttendanceEditModal] = useState(false);

  const [attendanceCheckInTime, setAttendanceCheckInTime] = useState(moment());
  const [attendanceUser, setAttendanceUser] = useState(null);
  const [isGeoFenceForAttendace, setIsGeoFenceForAttendace] = useState(false);
  const [isGeoFenceDisabled, setIsGeoFenceDisabled] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  let [update, setUpdate] = useState(0);

  const onSetAttendanceCheckInTime = (date) => {
    setAttendanceCheckInTime(date);
  };

  const onSetAttendanceUser = (user) => {
    setAttendanceUser(user);
  };

  useEffect(() => {
    async function checkCompanySetting() {
      try {
        const settings = await getCompanySettings();
        if (settings.data && settings.data.entries) {
          if (settings.data.entries.length > 0) {
            let entries = settings.data.entries;
            if (entries && entries.length > 0) {
              for (let i = 0; i < entries.length; i++) {
                if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Geofence restriction for attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceForAttendace(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Disable geofence on Tablet app"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceDisabled(true);
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        console.log(message);
      }
    }

    checkCompanySetting();
  }, []);

  useEffect(() => {
    const getUserAttendanceList = async (queryString) => {
      try {
        const response = await getUserAttendance(queryString);
        if (response && response.data && response.data.entries) {
          setUserListEntries(response.data.entries);

          let users = [];
          let element = null;
          const dateTimeFormat = getDateWithFixedTimeFormat();

          for (let i = 0; i < response.data.entries.length; i++) {
            element = response.data.entries[i];

            // group multiple check-in of same user in single record
            let check_in = "";
            let check_out = "";
            let check_in_time = "";
            let check_out_time = "";
            let isOut = false;
            let id = null;
            if (
              element.user_attendances &&
              element.user_attendances.length > 0
            ) {
              for (let j = 0; j < element.user_attendances.length; j++) {
                const attendanceElement = element.user_attendances[j];

                if (attendanceElement.is_utc) {
                  check_in_time = attendanceElement.start_time
                    ? moment(attendanceElement.start_time).format(
                        dateTimeFormat
                      )
                    : "-";

                  check_out_time = attendanceElement.end_time
                    ? moment(attendanceElement.end_time).format(dateTimeFormat)
                    : "-";
                } else {
                  check_in_time = attendanceElement.start_time
                    ? moment(attendanceElement.start_time)
                        .utc()
                        .format(dateTimeFormat)
                    : "-";

                  check_out_time = attendanceElement.end_time
                    ? moment(attendanceElement.end_time)
                        .utc()
                        .format(dateTimeFormat)
                    : "-";
                }

                check_in =
                  check_in.length > 0
                    ? `${check_in}|${check_in_time}`
                    : check_in_time;

                check_out =
                  check_out.length > 0
                    ? `${check_out}|${check_out_time}`
                    : check_out_time;

                isOut = attendanceElement.end_time ? true : false;
                id = attendanceElement.id;
              }
            }

            users.push({
              id: id.toString(),
              user_id: element.user_id.toString(),
              name: element.user_name,
              email: element.user_email,
              check_in: check_in,
              check_out: check_out,
              avatarThumbUrl: element.user_avatar_thumb_url
                ? element.user_avatar_thumb_url
                : null,
              avatarUrl: element.user_avatar_url
                ? element.user_avatar_url
                : null,
              isOut: isOut,
            });
          }

          if (response && response.data && response.data.pagination) {
            //setOffset(response.data.pagination.offset);
            setTotalRecords(response.data.pagination.total_items);
            //setHasMore(response.data.pagination.has_more_items);
          }

          setUserList(users);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        let { message } = getErrorObject(error);
        console.log(message);
      }
    };

    setIsLoading(true);
    getUserAttendanceList(apiQuery);
  }, [apiQuery, update]);

  const loadUsers = (page) => {
    //if (hasMore) {
    const currentDate = moment(selectedDate).format("YYYY-MM-DD");
    const startDate =
      moment(`${currentDate}T00:00:00.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const endTime =
      moment(`${currentDate}T23:59:59.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const queryString = `offset=${
      page * limit
    }&limit=${limit}&start_time=${startDate}&end_time=${endTime}&attendanceable_type=User`;

    setApiQuery(queryString);
    //}
  };

  const handleSearch = (event) => {
    setSearchVal(event.target.value);
  };

  const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      setSelectedDate(date);
      props.handleDateChange(date);
    }
  };

  // Call once, onComponentDidMound
  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    const startDate =
      moment(`${currentDate}T00:00:00.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const endTime =
      moment(`${currentDate}T23:59:59.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const queryString = `limit=10&start_time=${startDate}&end_time=${endTime}&attendanceable_type=User`;

    setApiQuery(queryString);
  }, []);

  // Calls everytime, the searchVal changes
  useEffect(() => {
    const onEmployeeSearch = (searchTerm) => {
      const currentDate = moment(selectedDate).format("YYYY-MM-DD");
      const startDate =
        moment(`${currentDate}T00:00:00.000`)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      const endTime =
        moment(`${currentDate}T23:59:59.000`)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      let queryString = `limit=${limit}&start_time=${startDate}&end_time=${endTime}&attendanceable_type=User`;

      if (searchTerm && searchTerm.length > 0) {
        queryString += `&name=${searchTerm}`;
      }

      setApiQuery(queryString);
    };

    // search delay
    const handler = setTimeout(() => {
      onEmployeeSearch(searchVal);
    }, 1000);

    // A cleanup function that will be called every time useEffect is re-called. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [searchVal, limit, selectedDate]);

  const onLimitChange = (limit) => {
    setLimit(limit);
  };

  const onAvatarOpen = (url) => {
    setLightboxUrl(url);
    setIsPhotoOpen(true);
  };

  const handleEditModalClose = () => {
    setOpenAttendanceEditModal(false);
    setIsCheckOut(false);
    setAttendanceTimeToCompare(null);
    setAttendanceUserName(null);
    setAttendanceUserId(null);
    setAttendanceId(null);
  };

  const handleUserAttendanceClick = (user_id) => {
    const selectedUserEntry = userListEntries.find((user) => {
      return user.user_id === parseInt(user_id, 10);
    });

    if (selectedUserEntry) {
      if (
        selectedUserEntry.user_attendances &&
        selectedUserEntry.user_attendances.length > 0
      ) {
        const lastIndex = selectedUserEntry.user_attendances.length - 1;
        const userAttendance = selectedUserEntry.user_attendances[lastIndex];
        let attendanceTime = userAttendance.end_time
          ? userAttendance.end_time
          : userAttendance.start_time;

        if (userAttendance.is_utc) {
          attendanceTime = moment(attendanceTime);
        } else {
          attendanceTime = moment(attendanceTime).utc();
        }

        setOpenAttendanceEditModal(true);
        setIsCheckOut(userAttendance.end_time ? true : false);
        setAttendanceTimeToCompare(attendanceTime);
        setAttendanceUserName(selectedUserEntry.user_name);
        setAttendanceUserId(user_id);
        setAttendanceId(userAttendance.id);
      }
    }
  };

  const handleEditModalSubmit = async (inputTime) => {
    const currentTime = moment(inputTime)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    try {
      if (isCheckOut) {
        const attendaceObj = {
          start_time: currentTime + "Z",
          attendanceable_id: attendanceUserId,
          attendanceable_type: "User",
          is_utc: true,
        };

        // geo location
        if (isGeoFenceForAttendace && coords) {
          if (isGeoFenceDisabled) {
            attendaceObj["tablet"] = true;
          } else {
            attendaceObj["latitude"] = coords.latitude;
            attendaceObj["longitude"] = coords.longitude;
          }
        }

        const response = await postUserAttendance(attendaceObj);
        if (response.data) {
          // refresh the attendance list
          setUpdate(++update);
        }
      } else {
        const attendaceObj = {
          end_time: currentTime + "Z",
          attendanceable_id: attendanceUserId,
          attendanceable_type: "User",
          is_utc: true,
        };

        const result = await updateUserAttendance(attendanceId, attendaceObj);
        if (result.data) {
          // refresh the attendance list
          setUpdate(++update);
        }
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      props.enqueueSnackbar(message, { variant: "error" });
    }

    handleEditModalClose();
  };

  const handleAddModalSubmit = async () => {
    const currentTime = moment(attendanceCheckInTime)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS");

    const userId =
      attendanceUser && attendanceUser.value ? attendanceUser.value : null;

    const attendaceObj = {
      start_time: currentTime + "Z",
      attendanceable_id: userId,
      attendanceable_type: "User",
      is_utc: true,
    };

    // geo location
    if (isGeoFenceForAttendace && coords) {
      if (isGeoFenceDisabled) {
        attendaceObj["tablet"] = true;
      } else {
        attendaceObj["latitude"] = coords.latitude;
        attendaceObj["longitude"] = coords.longitude;
      }
    }

    try {
      const response = await postUserAttendance(attendaceObj);
      if (response.data) {
        // refresh the attendance list
        setUpdate(++update);
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      props.enqueueSnackbar(message, { variant: "error" });
    }

    handleAddModalClose();
  };

  const handleAddModalClose = () => {
    props.setOpenAttendanceAddModal(false);
    setAttendanceCheckInTime(moment());
    setAttendanceUser(null);
  };

  const loadUserList = async (searchVal, options) => {
    const currentDate = moment().format("YYYY-MM-DD");
    const startDate =
      moment(`${currentDate}T00:00:00.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const endTime =
      moment(`${currentDate}T23:59:59.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

    let offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `more_details=true&limit=${limit}&offset=${offset}&start_time=${startDate}&end_time=${endTime}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getUsersNotCheckIn(queryString);
    const result = await getUserListOptions(response);
    return result;
  };

  const getUserListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let userList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];
          userList.push({
            value: element.id.toString(),
            label: element.name,
          });
        }

        resolve({
          options: userList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const isToday =
    moment(selectedDate).format("DD,MM,YY") === moment().format("DD,MM,YY")
      ? true
      : false;

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <SearchInput
            placeholder={i18next.t("Search employee")}
            handleSearch={handleSearch}
            variant="outlined"
          />
          <div style={{ maxWidth: 190 }}>
            <MaterialDatePicker
              format={getDateFormat()}
              disableFuture
              value={selectedDate}
              onChangeDate={(date) => handleDateChange(date)}
            />
          </div>
        </div>
        <div className={classes.content}>
          <UsersTable
            users={userList}
            loadUsers={loadUsers}
            totalRecords={totalRecords}
            onLimitChange={onLimitChange}
            onAvatarOpen={onAvatarOpen}
            onAttendanceClick={handleUserAttendanceClick}
            isToday={isToday}
          />
        </div>
        {isPhotoOpen && (
          <LightBox
            isOpen={isPhotoOpen}
            currentImageIndex={0}
            onClose={() => setIsPhotoOpen(false)}
            images={[lightboxUrl]}
          />
        )}
      </div>
      <OverlayIndicator isLoading={isLoading} />
      <AttendanceEditModal
        isOpen={openAttendanceEditModal}
        onClose={handleEditModalClose}
        onSubmit={handleEditModalSubmit}
        isCheckOut={isCheckOut}
        attendanceTimeToCompare={attendanceTimeToCompare}
        attendanceUserName={attendanceUserName}
      />
      <AttendanceAddModal
        isOpen={props.openAttendanceAddModal}
        onClose={handleAddModalClose}
        onSubmit={handleAddModalSubmit}
        attendanceCheckInTime={attendanceCheckInTime}
        attendanceUser={attendanceUser}
        loadUserList={loadUserList}
        onSetAttendanceUser={onSetAttendanceUser}
        onSetAttendanceCheckInTime={onSetAttendanceCheckInTime}
      />
      {(!isGeolocationAvailable || positionError) &&
      isGeoFenceForAttendace &&
      !isGeoFenceDisabled ? (
        <AlertModal
          isOpen={openAlertModal}
          handleClose={() => {
            setOpenAlertModal(false);
          }}
          title={"Geo Locatoin!"}
          message={
            "Your browser does not support Geolocation. Please use another browser. The Geolocation feature is required for attendance."
          }
        />
      ) : !isGeolocationEnabled &&
        isGeoFenceForAttendace &&
        !isGeoFenceDisabled ? (
        <AlertModal
          isOpen={openAlertModal}
          handleClose={() => {
            setOpenAlertModal(false);
          }}
          title={"Geo Locatoin!"}
          message={
            "Please allow the app to determine your location. This is required for attendance."
          }
        />
      ) : null}
    </Fragment>
  );
};

const geoLockSettings = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
};

export default withSnackbar(geolocated(geoLockSettings)(EmployeeTab));
