import React from "react";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  Typography,
  SvgIcon,
  Tooltip,
  Button,
} from "@mui/material";
import { WatchLater, FilePresentRounded } from "@mui/icons-material";
import moment from "moment";
import handleFileDownload from "@/utils/handleFileDownload";

const styles = (theme) => ({
  card: {
    padding: 0,
    borderBottomWidth: 4,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "12px",
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.customShadows.hourCard,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  timerIcon: {
    fontSize: 16,
    marginRight: 4,
    position: "relative",
    color: theme.palette.text.secondary,
  },
  spantxtSize: {
    fontSize: 14,
  },
  spanTaskName: {
    fontSize: 14,
  },
  spanTime: {
    fontSize: 12,
    width: "100%",
  },
  cardHour: {
    marginTop: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  hoursLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    marginRight: 4,
  },
  tooltipIcon: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    marginLeft: 4,
    cursor: "pointer",
  },
  checkinContainer: {
    height: 30,
    cursor: "pointer",
    display: "flex",
    marginTop: 8,
    marginBottom: 8,
  },
  checkinBlock: {
    fontWeight: 500,
    fontSize: 14,
    display: "flex",
    flexWrap: "noWrap",
    alignItems: "center",
    justifyContent: "center",
    margin: 8,
    width: "100%",
  },
  disabledCheckin: {
    color: theme.palette.text.disabled,
  },
  flexLine: {
    display: "flex",
    alignItems: "center",
  },
  separatorV: {
    marginTop: 5,
  },
  bottomBorderDiv: {
    height: 4,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginTop: 5,
  },
  btnToday: {
    display: "flex",
    flexWrap: "nowrap",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 700,
    borderRadius: 8,
    width: "-webkit-fill-available",
  },
});

class HourCard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render = () => {
    const {
      data,
      classes,
      checkinStep,
      timeDuration,
      checkinPlan,
      isHourCheckin,
      isToday,
    } = this.props;

    return (
      <React.Fragment>
        <Typography component="div">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography component="div">
                <Typography
                  component="div"
                  style={{
                    padding: "0.5rem",
                    overflow: "hidden",
                  }}
                >
                  <Typography component="div" className={classes.flexLine}>
                    <SvgIcon className={classes.icon}>
                      <path
                        fill={`${data.project_color ? data.project_color : ""}`}
                        d={
                          "M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z"
                        }
                      />
                    </SvgIcon>
                    <Typography
                      component="p"
                      noWrap
                      className={classes.spantxtSize}
                    >
                      {data.project_name}
                    </Typography>
                  </Typography>
                  <Typography component="div" className={classes.separatorV} />
                  <Typography component="div" className={classes.flexLine}>
                    <SvgIcon className={classes.icon}>
                      <path
                        d={
                          "M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"
                        }
                      />
                    </SvgIcon>
                    <Typography
                      component="p"
                      noWrap
                      className={classes.spanTaskName}
                    >
                      {data.task_name}
                    </Typography>
                  </Typography>

                  <Typography className={classes.separatorV} />

                  <Typography component="div" className={classes.hoursLine}>
                    <Typography component="div" className={classes.cardHour}>
                      <WatchLater className={classes.timerIcon} />
                      <Typography
                        component="p"
                        noWrap
                        className={classes.spanTime}
                      >
                        {moment(data.start_time).utc().format(`HH:mm`)} -{" "}
                        {moment(data.end_time).utc().format(`HH:mm`)}
                      </Typography>
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    {data.comment && (
                      <Tooltip title={data.comment} placement="top">
                        <SvgIcon className={classes.tooltipIcon}>
                          <path
                            d={
                              "M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M5,5V7H19V5H5M5,9V11H13V9H5M5,13V15H15V13H5Z"
                            }
                          />
                        </SvgIcon>
                      </Tooltip>
                    )}
                    {data.document_id && (
                      <Tooltip title={data?.document_name} placement="top">
                        <FilePresentRounded
                          className={classes.tooltipIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFileDownload(
                              data?.document_url,
                              data?.document_name
                            );
                          }}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                </Typography>
              </Typography>

              {isToday && data.status !== "inactive" && (
                <Typography
                  component="div"
                  className={classes.checkinContainer}
                  onClick={() => {
                    // If no check-in present, then allow to check-in the plan
                    if (!isHourCheckin) {
                      this.props.checkInStatus(data);
                    }
                  }}
                >
                  <Typography
                    component="div"
                    className={`${classes.checkinBlock} ${
                      isHourCheckin && classes.disabledCheckin
                    }`}
                  >
                    <div style={{ width: "100%" }}>
                      {checkinPlan === data.id &&
                      checkinStep &&
                      checkinStep === "checkin" &&
                      timeDuration ? (
                        <Button
                          variant="outlined"
                          fullWidth
                          color="error"
                          className={classes.btnToday}
                        >
                          <>
                            {`${timeDuration.hours()}h ${timeDuration.minutes()}m ${timeDuration.seconds()}s`}
                            <SvgIcon style={{ marginLeft: 8, fontSize: 22 }}>
                              <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M9,9H15V15H9" />
                            </SvgIcon>
                          </>
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          fullWidth
                          disabled={
                            (checkinPlan !== data.id &&
                              checkinStep &&
                              checkinStep === "checkin") ||
                            isHourCheckin
                          }
                          className={classes.btnToday}
                        >
                          {i18next.t("Check in")}
                        </Button>
                      )}
                    </div>
                  </Typography>
                </Typography>
              )}

              <Typography
                component="div"
                className={classes.bottomBorderDiv}
                style={{
                  background:
                    data.status === "inactive"
                      ? "#FF4842"
                      : data.project_color
                        ? data.project_color
                        : "#18B24B",
                }}
              />
            </CardContent>
          </Card>
        </Typography>
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(HourCard);
