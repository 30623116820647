import React from "react";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  mb: {
    marginBottom: "1rem",
  },
  header: {
    marginBottom: 7,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButtons: {
    padding: "0 10px 10px 10px",
  },
  checkBox: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
});

class AttendanceCheckInModal extends React.Component {
  toggleCheckBox = () => {
    const { isCheckInForAttendance, changeState } = this.props;

    changeState({
      isCheckInForAttendance: !isCheckInForAttendance,
    });
  };

  handleCancel = () => {
    const { changeState } = this.props;

    changeState({
      isCheckInForAttendance: false,
      openCheckInForAttendanceModal: false,
    });
  };

  render() {
    const { isOpen, classes, isCheckInForAttendance, handleNext } = this.props;
    return (
      <Typography component="div">
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={isOpen}
          onClose={this.handleCancel}
        >
          <DialogTitle
            onClose={this.handleCancel}
            className={classes.header}
            id="check-in-for-attendance-dialog-title"
          >
            {i18next.t("Check-in for attendance")}
            <IconButton
              aria-label={i18next.t("Close")}
              className={classes.closeButton}
              onClick={this.handleCancel}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: "0 34px 0 34px" }}>
            <Typography
              component="div"
              id="check-in-for-attendance-dialog-content"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckInForAttendance}
                    onChange={this.toggleCheckBox}
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checked,
                    }}
                    id="check-in-for-attendance-dialog-tickbox"
                  />
                }
                label={i18next.t("Check-in for attendance")}
                id="check-in-for-attendance-dialog-option"
              />
            </Typography>
          </DialogContent>
          <DialogActions className={classes.actionButtons}>
            <Typography component="div">
              <Button
                className={classes.btnCancel}
                onClick={this.handleCancel}
                id="check-in-for-attendance-dialog-cancel-button"
              >
                {i18next.t("Cancel")}
              </Button>{" "}
              <Button
                type="submit"
                className={classes.button}
                onClick={handleNext}
                id="check-in-for-attendance-dialog-submit-button"
              >
                {i18next.t("Next")}
              </Button>
            </Typography>
          </DialogActions>
        </Dialog>
      </Typography>
    );
  }
}

export default withStyles(styles)(AttendanceCheckInModal);
