import React from "react";
import i18next from "i18next";
import { makeStyles } from "@mui/styles";
import { Typography, SvgIcon, Avatar } from "@mui/material";
import PlanCard from "./PlanCard";
import PlanCardUnassigned from "./PlanCardUnassigned";
import moment from "moment";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import { AccessTimeFilled } from "@mui/icons-material";
import { Droppable } from "react-beautiful-dnd";
import getInitials from "@/utils/getInitials";

const useStyles = makeStyles((theme) => ({
  RtableCell: {
    boxSizing: "border-box",
    flexGrow: 1,
    //width: "12.5%", // Default to full width
    //width: 220, // for scrollable table use digits
    minWidth: 170,
    width: "calc(100vw / 8)",
    padding: "8px",
    //overflow: "hidden", // Or flex might break
    listStyle: "none",
    border: `0.5px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  userContainer: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
  userIconContainer: {
    fontSize: 36,
    color: theme.palette.info.main,
  },
  projectIconContainer: {
    fontSize: 18,
  },
  userCardTitle: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  userCardDepartment: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  planCard: {
    minWidth: 100,
    maxWidth: 220,
    backgroundColor: theme.palette.background.paper,
    padding: "5px 10px",
  },
  icon: {
    fontSize: 14,
  },
  bgToday: {
    backgroundColor: theme.palette.hourTodayBackground,
  },
  userTotalContainer: {
    display: "flex",
    paddingTop: 2,
    color: theme.palette.text.secondary,
  },
  timerIcon: {
    fontSize: 14,
  },
  userTotalText: {
    fontSize: 12,
  },
  userTotalIcons: {
    paddingRight: 3,
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 25,
    height: 25,
    marginRight: 10,
    marginBottom: 10,
    fontSize: 13,
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  webkitSticky: {
    position: "-webkit-sticky",
  },
  resourceBox: {
    position: "sticky",
    left: 0,
    zIndex: 1,
  },
}));

const baseUrl = import.meta.env.VITE_API_BASE_URL;

export default function Rows({
  weekdays,
  state,
  planSchedule,
  formModalOpen,
  ...others
}) {
  const classes = useStyles();

  var enLocale = moment();
  enLocale.locale("en");

  return (
    <React.Fragment>
      {state.groupBy === "user" && (
        <React.Fragment key={`unassigned`}>
          <div
            key={`un-div`}
            className={`${classes.RtableCell} ${classes.webkitSticky} ${classes.resourceBox}`}
            style={{
              width: weekdays.length === 7 ? `calc((100vw / 8) - 16px)` : 220,
            }}
          >
            <Typography
              component="div"
              className={classes.userContainer}
              key={`c-un`}
            >
              <Typography component="div">
                <SvgIcon className={classes.userIconContainer}>
                  <path
                    d={
                      "M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 2.75c1.24 0 2.25 1.01 2.25 2.25S10.24 8.25 9 8.25 6.75 7.24 6.75 6 7.76 3.75 9 3.75zM9 14.5c-1.86 0-3.49-.92-4.49-2.33C4.62 10.72 7.53 10 9 10c1.47 0 4.38.72 4.49 2.17-1 1.41-2.63 2.33-4.49 2.33z"
                    }
                  />
                </SvgIcon>
              </Typography>
              <Typography component="div" style={{ overflow: "hidden" }}>
                <Typography
                  component="div"
                  noWrap
                  className={classes.userCardTitle}
                >
                  {i18next.t("Unassigned")}
                </Typography>
              </Typography>
            </Typography>
          </div>

          {weekdays &&
            weekdays.map((data, index) => {
              return (
                <Droppable
                  droppableId={`UN-${data.index}`}
                  ignoreContainerClipping
                  key={`UN-${data.index}`}
                >
                  {(provided) => (
                    <div
                      className={`${classes.RtableCell} ${
                        data.isToday && !planSchedule
                          ? classes.bgToday
                          : data.index % 2 !== 0
                      }`}
                      key={`u${index}${data.index}`}
                      style={{
                        width:
                          weekdays.length === 7
                            ? `calc((100vw / 8) - 16px)`
                            : 220,
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <PlanCardUnassigned
                        formModalOpen={formModalOpen}
                        dayNumber={data.index}
                        dateStr={data.dateStr}
                        isToday={data.isToday}
                        state={state}
                        {...others}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })}
        </React.Fragment>
      )}

      {state.groupBy === "user" &&
        state.userList &&
        state.userList.map((user, index) => {
          let userPlans = null;
          let department = null;
          if (Object.prototype.hasOwnProperty.call(state.planList, user.id)) {
            userPlans = state.planList[user.id];
          }
          if (user.department_id) {
            department = state.departmentList.find((entry) => {
              return entry.id === user.department_id;
            });
          }

          return (
            <React.Fragment key={`f${index}`}>
              <div
                key={`u${index}`}
                className={`${classes.RtableCell} ${classes.webkitSticky} ${classes.resourceBox}`}
                style={{
                  width:
                    weekdays.length === 7 ? `calc((100vw / 8) - 16px)` : 220,
                }}
              >
                <Typography
                  component="div"
                  className={classes.userContainer}
                  key={`c${index}`}
                >
                  <Typography component="div">
                    <Avatar
                      alt="User"
                      className={classes.avatar}
                      src={
                        user.avatar_thumb_url
                          ? `${baseUrl}${user.avatar_thumb_url}`
                          : null
                      }
                      style={{ background: "#919EAB" }}
                    >
                      {getInitials(user.name)}
                    </Avatar>
                  </Typography>
                  <Typography component="div" style={{ overflow: "hidden" }}>
                    <Typography
                      component="div"
                      noWrap
                      className={classes.userCardTitle}
                    >
                      {i18next.t(user.name)}
                    </Typography>
                    <Typography
                      component="div"
                      noWrap
                      className={classes.userCardDepartment}
                    >
                      {department && department.name}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.userTotalContainer}
                    >
                      <Typography
                        component="div"
                        className={classes.userTotalIcons}
                      >
                        <AccessTimeFilled className={classes.timerIcon} />
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.userTotalText}
                      >
                        {state.userTotalHours &&
                        state.userTotalHours[user.id] ? (
                          <DecimalToHoursMin>
                            {state.userTotalHours[user.id]}
                          </DecimalToHoursMin>
                        ) : (
                          "0m"
                        )}
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </div>

              {weekdays &&
                weekdays.map((data, index) => {
                  return (
                    <Droppable
                      droppableId={`${user.id}-${data.index}`}
                      ignoreContainerClipping
                      key={`${user.id}-${data.index}`}
                    >
                      {(provided) => (
                        <div
                          className={`${classes.RtableCell} ${
                            data.isToday && !planSchedule
                              ? classes.bgToday
                              : data.index % 2 !== 0
                          }`}
                          key={`u${index}${data.index}`}
                          style={{
                            width:
                              weekdays.length === 7
                                ? `calc((100vw / 8) - 16px)`
                                : 220,
                          }}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <PlanCard
                            formModalOpen={formModalOpen}
                            user={user}
                            dayNumber={data.index}
                            dateStr={data.dateStr}
                            plans={userPlans}
                            isToday={data.isToday}
                            state={state}
                            checkInData={state.checkInData}
                            {...others}
                          />

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })}
            </React.Fragment>
          );
        })}

      {state.groupBy === "project" &&
        state.projectList &&
        state.projectList.map((project, index) => {
          let projectPlans = null;
          if (
            Object.prototype.hasOwnProperty.call(state.planList, project.id)
          ) {
            projectPlans = state.planList[project.id];
          }
          return (
            <React.Fragment key={`f${index}`}>
              <div
                key={`p${index}`}
                className={`${classes.RtableCell} ${classes.webkitSticky} ${classes.resourceBox}`}
                style={{
                  width:
                    weekdays.length === 7 ? `calc((100vw / 8) - 16px)` : 220,
                }}
              >
                <Typography component="div" className={classes.userContainer}>
                  <Typography component="div">
                    <Avatar
                      className={classes.avatar}
                      style={{
                        background: "transparent",
                      }}
                    >
                      <SvgIcon
                        className={classes.projectIconContainer}
                        style={{ fontSize: 24 }}
                      >
                        <path
                          fill={project.color}
                          d={
                            "M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z"
                          }
                        />
                      </SvgIcon>
                    </Avatar>
                  </Typography>
                  <Typography component="div" style={{ overflow: "hidden" }}>
                    <Typography
                      component="div"
                      noWrap
                      className={classes.userCardTitle}
                    >
                      {i18next.t(project.name)}
                    </Typography>
                    <Typography
                      component="div"
                      noWrap
                      className={classes.userCardDepartment}
                    >
                      {project.customer_name}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.userTotalContainer}
                    >
                      <Typography
                        component="div"
                        className={classes.userTotalIcons}
                      >
                        <AccessTimeFilled className={classes.timerIcon} />
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.userTotalText}
                      >
                        {state.userTotalHours &&
                        state.userTotalHours[project.id] ? (
                          <DecimalToHoursMin>
                            {state.userTotalHours[project.id]}
                          </DecimalToHoursMin>
                        ) : (
                          "0m"
                        )}
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </div>
              {weekdays &&
                weekdays.map((data, index) => {
                  return (
                    <Droppable
                      droppableId={`${project.id}-${data.index}`}
                      ignoreContainerClipping
                      key={`${project.id}-${data.index}`}
                    >
                      {(provided) => (
                        <div
                          className={`${classes.RtableCell} ${
                            data.isToday && !planSchedule
                              ? classes.bgToday
                              : data.index % 2 !== 0
                          }`}
                          key={`p${index}${data.index}`}
                          style={{
                            width:
                              weekdays.length === 7
                                ? `calc((100vw / 8) - 16px)`
                                : 220,
                          }}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <PlanCard
                            formModalOpen={formModalOpen}
                            project={project}
                            dayNumber={data.index}
                            dateStr={data.dateStr}
                            plans={projectPlans}
                            isToday={data.isToday}
                            state={state}
                            checkInData={state.checkInData}
                            {...others}
                          />

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })}
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
}
