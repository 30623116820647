import * as types from "./types";
import { core } from "./core";

export const getDepartments = (queryString) => {
  if (!queryString) {
    queryString = "more_details=false&limit=200";
  }

  return core.API(
    "GET",
    `/company/departments${queryString ? `?${queryString}` : ""}`,
    types.GET_DEPARTMENTS,
    types.GET_DEPARTMENTS_SUCCESS,
    types.GET_DEPARTMENTS_FAILURE
  );
};

export const getDepartment = (id) => {
  return core.API(
    "GET",
    `/company/department/${id}`,
    types.GET_DEPARTMENT,
    types.GET_DEPARTMENT_SUCCESS,
    types.GET_DEPARTMENT_FAILURE
  );
};

export const addDepartment = (data) => {
  return core.API(
    "POST",
    `/company/department`,
    types.ADD_DEPARTMENT,
    types.ADD_DEPARTMENT_SUCCESS,
    types.ADD_DEPARTMENT_FAILURE,
    data
  );
};

export const updateDepartment = (id, data) => {
  return core.API(
    "PUT",
    `/company/department/${id}`,
    types.UPDATE_DEPARTMENT,
    types.UPDATE_DEPARTMENT_SUCCESS,
    types.UPDATE_DEPARTMENT_FAILURE,
    data
  );
};

export const deleteDepartment = (id) => {
  return core.API(
    "DELETE",
    `/company/department/${id}`,
    types.DELETE_DEPARTMENT,
    types.DELETE_DEPARTMENT_SUCCESS,
    types.DELETE_DEPARTMENT_FAILURE
  );
};

export const attachUserDepartments = (id, data) => {
  return core.API(
    "POST",
    `/company/department/${id}/users`,
    types.ATTACH_USER,
    types.ATTACH_USER_SUCCESS,
    types.ATTACH_USER_FAILURE,
    data
  );
};

export const getAttachUserDepartments = (id) => {
  return core.API(
    "GET",
    `/company/department/${id}/users`,
    types.GET_ATTACH_USER,
    types.GET_ATTACH_USER_SUCCESS,
    types.GET_ATTACH_USER_FAILURE
  );
};
