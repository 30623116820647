import * as types from "./types";
import { core } from "./core";

export const getCutomers = (data) => {
  let offset = data && data.offset ? data.offset : 0;
  return core.API(
    "GET",
    `/customers?offset=${offset}${
      data && data.limit ? `&limit=${data.limit}` : ""
    }${
      data && data.search && data.search.length > 0
        ? `&name=${data.search}`
        : ""
    }`,
    types.GET_CUSTOMERS,
    types.GET_CUSTOMERS_SUCCESS,
    types.GET_CUSTOMERS_FAILURE
  );
};
export const addCutomers = (data) => {
  return core.API(
    "POST",
    `/customer/`,
    types.ADD_CUSTOMER,
    types.ADD_CUSTOMER_SUCCESS,
    types.ADD_CUSTOMER_FAILURE,
    data
  );
};
export const deleteCustomer = (id) => {
  return core.API(
    "DELETE",
    `/customer/${id}`,
    types.DELETE_CUSTOMER,
    types.DELETE_CUSTOMER_SUCCESS,
    types.DELETE_CUSTOMER_FAILURE
  );
};
export const putCustomer = (id, data) => {
  return core.API(
    "PUT",
    `/customer/${id}/`,
    types.PUT_CUSTOMER,
    types.PUT_CUSTOMER_SUCCESS,
    types.PUT_CUSTOMER_FAILURE,
    data
  );
};
