import * as types from "./types";
import { core } from "./core";

export const getCompanyModules = () => {
  return core.API(
    "GET",
    `/company/modules`,
    types.COMPANY_MODULES,
    types.COMPANY_MODULES_SUCCESS,
    types.COMPANY_MODULES_FAILURE
  );
};

export const getCompanySettings = () => {
  return core.API(
    "GET",
    `/company-settings`,
    types.GET_COMPANY_SETTINGS,
    types.GET_COMPANY_SETTINGS_SUCCESS,
    types.GET_COMPANY_SETTINGS_FAILURE
  );
};

export const putCompanySettings = (id, data) => {
  return core.API(
    "PUT",
    `/company-setting/${id}`,
    types.PUT_COMPANY_SETTINGS,
    types.PUT_COMPANY_SETTINGS_SUCCESS,
    types.PUT_COMPANY_SETTINGS_FAILURE,
    data
  );
};

export const getCompanyDetails = () => {
  return core.API(
    "GET",
    `/company`,
    types.GET_COMPANY_DETAILS,
    types.GET_COMPANY_DETAILS_SUCCESS,
    types.GET_COMPANY_DETAILS_FAILURE
  );
};

export const putCompanyDetails = (data) => {
  return core.API(
    "PUT",
    `/company`,
    types.PUT_COMPANY_DETAILS,
    types.PUT_COMPANY_DETAILS_SUCCESS,
    types.PUT_COMPANY_DETAILS_FAILURE,
    data
  );
};

export const changePlan = (data) => {
  return core.API(
    "PUT",
    `/company/change-plan`,
    types.PUT_CHANGE_PLAN,
    types.PUT_CHANGE_PLAN_SUCCESS,
    types.PUT_CHANGE_PLAN_FAILURE,
    data
  );
};

export const changeInvoiceDetails = (data) => {
  return core.API(
    "PUT",
    `/company/change-invoice-details`,
    types.PUT_CHANGE_INVOICE_DETAILS,
    types.PUT_CHANGE_INVOICE_DETAILS_SUCCESS,
    types.PUT_CHANGE_INVOICE_DETAILS_FAILURE,
    data
  );
};

export const addCompanySettingValue = (settingId, data) => {
  return core.API(
    "POST",
    `/company-setting/${settingId}/value`,
    types.ADD_COMPANY_SETTING_VALUE,
    types.ADD_COMPANY_SETTING_VALUE_SUCCESS,
    types.ADD_COMPANY_SETTING_VALUE_FAILURE,
    data
  );
};

export const updateCompanySettingValue = (settingId, valueId, data) => {
  return core.API(
    "PUT",
    `/company-setting/${settingId}/value/${valueId}`,
    types.PUT_COMPANY_SETTING_VALUE,
    types.PUT_COMPANY_SETTING_VALUE_SUCCESS,
    types.PUT_COMPANY_SETTING_VALUE_FAILURE,
    data
  );
};

export const callCompanySettingAPI = (path) => {
  return core.API(
    "POST",
    `${path}`,
    types.CALL_COMPANY_SETTING_API,
    types.CALL_COMPANY_SETTING_API_SUCCESS,
    types.CALL_COMPANY_SETTING_API_FAILURE
  );
};

export const getLastFetchTransactions = () => {
  return core.API(
    "GET",
    `/company-settings/fetch-transactions`,
    types.GET_LAST_FETCH_TRANSACTIONS,
    types.GET_LAST_FETCH_TRANSACTIONS_SUCCESS,
    types.GET_LAST_FETCH_TRANSACTIONS_FAILURE
  );
};

export const getCompanyContract = (id) => {
  return core.API(
    "GET",
    `/contract/contract/${id}`,
    types.COMPANY_CONTRACT,
    types.COMPANY_CONTRACT_SUCCESS,
    types.COMPANY_CONTRACT_FAILURE
  );
};

export const putCompanyContract = (id, data) => {
  return core.API(
    "PUT",
    `/contract/contract/${id}`,
    types.PUT_COMPANY_CONTRACT,
    types.PUT_COMPANY_CONTRACT_SUCCESS,
    types.PUT_COMPANY_CONTRACT_FAILURE,
    data
  );
};
