import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import moment from "moment";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";

const useStyles = makeStyles((theme) => ({
  RtableCell: {
    boxSizing: "border-box",
    flexGrow: 1,
    minWidth: 170,
    width: "calc(100vw / 8)",
    overflow: "hidden", // Or flex might break
    listStyle: "none",
    border: `0.5px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    textAlign: "center",
    position: "sticky",
    top: 0,
    zIndex: 1,
    boxShadow: `${theme.customShadows.planningCard}`,
  },
  dayText: {
    fontSize: 20,
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
  },
  dateView: {
    display: "flex",
    marginBottom: 8,
    alignItems: "center",
    textAlign: "center",
    marginTop: -2,
  },
  dateText: {
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "end",
    color: theme.palette.text.disabled,
    marginLeft: 8,
    fontWeight: 700,
  },
  hourText: {
    color: theme.palette.text.primary,
    fontSize: 12,
    paddingTop: 3,
  },
  timerIcon: {
    color: theme.palette.text.disabled,
    fontSize: 16,
  },
  dateViewIcons: {
    paddingRight: 2,
  },
  bgToday: {
    backgroundColor: theme.palette.hourTodayBackground,
  },
  icon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  iconBorder: {
    top: "25px",
    right: "15px",
    position: "absolute",
  },
  webkitSticky: {
    position: "-webkit-sticky",
  },
  searchBox: {
    padding: "5px 10px",
  },
  searchSticky: {
    backgroundColor: theme.palette.background.paper,
    zIndex: "2",
    left: 0,
    display: "flex",
    alignItems: "center",
  },
  weekRow: {
    width: "100%",
  },
  weekHeaderContainer: {
    alignItems: "end",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
  },
  weekSubHeaderContainer: {
    alignItems: "end",
    paddingLeft: 12,
    paddingTop: 4,
    height: "auto",
  },
}));

export default function WeekHeader({
  state,
  weekdays,
  planSchedule,
  startDate,
}) {
  const classes = useStyles();

  const weekdaysLength = weekdays.length;

  return (
    <React.Fragment>
      <div
        className={`${classes.RtableCell} ${classes.webkitSticky} ${classes.searchSticky}`}
        style={{
          width: weekdaysLength === 7 ? `calc(100vw / 8)` : 220,
          height: 74,
          zIndex: 3,
        }}
      >
        <div className={classes.searchBox}></div>
      </div>

      {weekdays.map((data, index) => {
        return (
          <div
            className={classes.RtableCell}
            style={{
              width: weekdaysLength === 7 ? `calc(100vw / 8)` : 220,
              height: 74,
              zIndex: 2,
            }}
            key={index}
          >
            <Typography component="div" className={classes.weekRow}>
              <div>
                <div className={classes.weekHeaderContainer}>
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Typography variant="h2" className={classes.dayText}>
                      {moment(startDate).add(data.index, "days").format("ddd")}
                    </Typography>
                    <Typography variant="h2">
                      {planSchedule ? (
                        <Typography component="p" className={classes.dateText}>
                          {data.index % 7 === 0
                            ? `Week ${data.index / 7 + 1}`
                            : ""}
                        </Typography>
                      ) : (
                        <Typography component="p" className={classes.dateText}>
                          {startDate
                            ? moment(startDate)
                                .clone()
                                .add(data.index, "days")
                                .format("DD MMM")
                            : ""}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                </div>
                {state.hourlist &&
                state.hourlist.columns?.[data.name] &&
                state.hourlist.columns?.[data.name].totalHours ? (
                  <div className={classes.weekSubHeaderContainer}>
                    <Typography component="div" className={classes.dateView}>
                      {state.hourlist && state.hourlist.columns ? (
                        <>
                          <Typography
                            component="div"
                            className={classes.dateViewIcons}
                          >
                            <AccessTimeFilledIcon
                              className={classes.timerIcon}
                            />
                          </Typography>
                          <Typography
                            component="div"
                            className={classes.hourText}
                          >
                            <DecimalToHoursMin>
                              {state.hourlist.columns?.[data.name]?.totalHours}
                            </DecimalToHoursMin>
                          </Typography>
                        </>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Typography>
          </div>
        );
      })}
    </React.Fragment>
  );
}
