import React from "react";
import { Grid, Typography } from "@mui/material";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import Label from "@/common/Label";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  hourTypeItemTitle: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  addonTypeItemTitle: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
  hourTypeItemDescription: {
    fontWeight: 500,
    fontSize: 13,
    color: theme.palette.text.secondary,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "14px",
  },
  node: {
    padding: 10,
  },
}));

const summarizeHoursByType = (items) => {
  return Object.values(
    items
      ?.filter((item) => item.type_id)
      ?.reduce((accumulator, currentValue) => {
        let lunchTime = 0;
        if (currentValue.lunch_start_time !== "") {
          lunchTime =
            (currentValue.lunch_end_time - currentValue.lunch_start_time) / 60;
        }

        if (accumulator[currentValue.type_id]) {
          accumulator[currentValue.type_id].amount +=
            (currentValue.end_time - currentValue.start_time) / 60;

          if (lunchTime) {
            accumulator[currentValue.type_id].amount -= lunchTime;
          }
          return accumulator;
        } else {
          let amount = (currentValue.end_time - currentValue.start_time) / 60;
          if (lunchTime) {
            amount -= lunchTime;
          }
          return {
            ...accumulator,
            [currentValue.type_id]: {
              id: currentValue.type_id,
              name: currentValue.type_name,
              amount: amount,
            },
          };
        }
      }, {})
  );
};

const summarizeFixedAddonsByType = (items) => {
  return Object.values(
    items
      ?.filter((item) => item.type_id)
      ?.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue.type_id]) {
          accumulator[currentValue.type_id].amount += Number(
            currentValue.type_value
          );
          return accumulator;
        } else {
          return {
            ...accumulator,
            [currentValue.type_id]: {
              id: currentValue.type_id,
              name: currentValue.type_name,
              color: currentValue.type_color,
              measurement: currentValue.type_measurement,
              amount: Number(currentValue.type_value),
            },
          };
        }
      }, {})
  );
};

function HoursPerType({
  multiProjectTasks,
  multiProjectAddonTasks,
  fixedAddonTasks,
  previewError,
}) {
  const classes = useStyles();

  const hoursPerTypeSummary = summarizeHoursByType(multiProjectTasks);
  const addonHoursPerTypeSummary = summarizeHoursByType(multiProjectAddonTasks);
  const fixedAddonsPerTypeSummary = summarizeFixedAddonsByType(fixedAddonTasks);

  return (
    <Grid container className={classes.container}>
      {previewError && (
        <Grid item md={12} className={classes.node}>
          <Typography component="div" className={classes.errorText}>
            {previewError}
          </Typography>
        </Grid>
      )}
      {hoursPerTypeSummary?.map((item) => (
        <Grid item md={6} className={classes.node} key={item.id}>
          <Typography className={classes.hourTypeItemTitle}>
            <DecimalToHoursMin>{item.amount}</DecimalToHoursMin>
          </Typography>
          <Typography className={classes.hourTypeItemDescription}>
            {item.name}
          </Typography>
        </Grid>
      ))}
      {addonHoursPerTypeSummary?.map((item) => (
        <Grid item md={6} className={classes.node} key={item.id}>
          <Typography className={classes.addonTypeItemTitle}>
            <DecimalToHoursMin>{item.amount}</DecimalToHoursMin>
          </Typography>
          <Typography className={classes.hourTypeItemDescription}>
            {item.name}
          </Typography>
        </Grid>
      ))}
      {fixedAddonsPerTypeSummary?.map((item) => (
        <Grid item md={6} className={classes.node} key={item.id}>
          <Label variant="custom" hexColor={item.color} height="small">
            {item.amount} {item.measurement}
          </Label>
          <Typography className={classes.hourTypeItemDescription}>
            {item.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default HoursPerType;
