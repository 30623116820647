import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { Grow, IconButton, Typography, Zoom } from "@mui/material";

export default function LightBox({
  images = [],
  isOpen,
  currentImageIndex = 0,
  setCurrentImageIndex,
  onClose,
}) {
  const handlePrev = (e) => {
    e?.stopPropagation();
    setCurrentImageIndex(
      currentImageIndex > 0 ? currentImageIndex - 1 : images.length - 1
    );
  };

  const handleNext = (e) => {
    e?.stopPropagation();
    setCurrentImageIndex(
      currentImageIndex < images.length - 1 ? currentImageIndex + 1 : 0
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      handlePrev();
    } else if (e.key === "ArrowRight") {
      handleNext();
    } else if (e.key === "Escape") {
      onClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    // eslint-disable-next-line
  }, [currentImageIndex]);

  return (
    <div>
      <Backdrop
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isOpen}
        onClick={onClose}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            m: 2,
            color: "#fff",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>{`${currentImageIndex + 1}/${
              images.length
            }`}</Typography>
            <IconButton sx={{ m: 1 }} onClick={onClose}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
        </div>
        <Grow in={isOpen} timeout={500}>
          <Fab
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              m: 2,
            }}
            color="primary"
            onClick={handlePrev}
            size="small"
          >
            <ChevronLeftIcon />
          </Fab>
        </Grow>
        <Grow in={isOpen} timeout={500}>
          <Fab
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              m: 2,
            }}
            color="primary"
            onClick={handleNext}
            size="small"
          >
            <ChevronRightIcon />
          </Fab>
        </Grow>
        <div>
          {currentImageIndex !== null && (
            <Zoom in={isOpen}>
              <div>
                <Paper
                  component="img"
                  elevation={8}
                  src={images[currentImageIndex]}
                  alt={`Image ${currentImageIndex + 1}`}
                  sx={{
                    maxWidth: "80vw",
                    maxHeight: "80vh",
                    margin: "auto",
                    display: "block",
                    borderRadius: 2,
                  }}
                />
              </div>
            </Zoom>
          )}
        </div>
      </Backdrop>
    </div>
  );
}
