import { useTheme } from "@mui/material/styles";
import Select from "react-select";
import {
  Control,
  OutlinedControl,
  ValueContainer,
  IndicatorSeparator,
  DropdownIndicator,
  SingleValue,
} from "./Controls";
import i18next from "i18next";

export const MaterialSelect = (props) => {
  const outlinedComponent = props.variant === "outlined" ? true : false;
  const muiTheme = useTheme();
  const reactSelectStyles = {
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: isDisabled ? "#919EAB" : muiTheme.palette.text.primary,
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 1301 }),
  };

  return (
    <Select
      components={{
        Control: outlinedComponent ? OutlinedControl : Control,
        ValueContainer,
        IndicatorSeparator,
        DropdownIndicator,
        SingleValue,
      }}
      placeholder={`${i18next.t("Select")}...`}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: muiTheme.palette.action.selected,
          primary25: muiTheme.palette.action.hover,
          primary50: muiTheme.palette.grey[500_16],
          neutral0: muiTheme.palette.background.paper,
        },
      })}
      styles={reactSelectStyles}
      {...props}
    />
  );
};
