/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { List, ListItem, Button, SvgIcon } from "@mui/material";
import { alpha } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import Timer from "@mui/icons-material/Timer";
import i18next from "i18next";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    height: 48,
  },
  icon: {
    color: theme.palette.icon,
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
    "& svg": { width: "100%", height: "100%" },
  },
  active: {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const {
    isPlanningModule,
    showAdminPlanningView,
    showOnlyDashboard,
    ...rest
  } = props;

  const classes = useStyles();

  const isAdmin =
    getItem("userRole") && getItem("userRole") === "admin" ? true : false;

  const pages = [
    {
      title: i18next.t("Dashboard"),
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
  ];

  if (!showOnlyDashboard) {
    pages.push({
      title: i18next.t("Hours"),
      href: "/hours",
      icon: <Timer />,
    });

    if (isPlanningModule) {
      if (isAdmin || showAdminPlanningView) {
        pages.push({
          title: i18next.t("Planning"),
          href: "/planning",
          icon: (
            <SvgIcon>
              <path d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z" />
            </SvgIcon>
          ),
        });
      } else {
        pages.push({
          title: i18next.t("Planned hours"),
          href: "/planned-hours",
          icon: (
            <SvgIcon>
              <path d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z" />
            </SvgIcon>
          ),
        });
      }
    }

    pages.push({
      title: i18next.t("Items"),
      href: "/items",
      icon: (
        <SvgIcon>
          <path d="M10.78 12.05L13.81 11.79L15 9L16.19 11.79L19.22 12.05L16.92 14.04L17.61 17L15 15.47L12.39 17L13.08 14.04L10.78 12.05M22 8V18C22 19.11 21.11 20 20 20H4C2.9 20 2 19.11 2 18V6C2 4.89 2.9 4 4 4H10L12 6H20C21.11 6 22 6.9 22 8M20 8H4V18H20V8Z" />
        </SvgIcon>
      ),
    });

    pages.push({
      title: i18next.t("Checklists"),
      href: "/hmsk/checkList",
      icon: (
        <SvgIcon>
          <path d="M3,5H9V11H3V5M5,7V9H7V7H5M11,7H21V9H11V7M11,15H21V17H11V15M5,20L1.5,16.5L2.91,15.09L5,17.17L9.59,12.59L11,14L5,20Z" />
        </SvgIcon>
      ),
    });

    if (isAdmin) {
      pages.push({
        title: i18next.t("Attendance"),
        href: "/attendance",
        icon: (
          <SvgIcon>
            <path d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z" />
          </SvgIcon>
        ),
      });
    }
  }

  pages.push({
    title: i18next.t("Sign out"),
    href: "/logout",
    icon: <PowerSettingsNew />,
  });

  return (
    <List {...rest} disablePadding sx={{ px: 2, pb: 2 }}>
      {pages.map((page) => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
};

export default SidebarNav;
