import React from "react";
import { ActionCreators } from "@/redux/actions";
import { actions as filterActions } from "@/redux/reducers/filters";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getWeekday, isWeekStartWithMonday } from "@/utils/dateTimeFunctions";
import OverlayIndicator from "@/common/OverlayIndicator";
import moment from "moment";
import Table from "./components/table";
import DeleteModal from "@/common/deleteModal";
import FormCheckInModal from "./components/formCheckInModal";
import LeaveRequestModal from "./components/LeaveRequestModal";
import i18next from "i18next";
import { withSnackbar } from "notistack";
import { geolocated } from "react-geolocated";
import AlertModal from "@/common/AlertModal";
import CheckInAlert from "@/common/CheckInAlert";
import ResumeCheckInDialog from "./components/ResumeCheckInDialog";
import CheckoutModal from "@/common/CheckoutModal";
import ContinuesCheckInModal from "./components/ContinuesCheckInModal";
import InputMultiProjectHourModal from "./components/InputMultiProjectHourModal";
import SelectProjectTaskModal from "./components/SelectProjectTaskModal";
import AttendanceCheckInModal from "./components/AttendanceCheckInModal";
import AttendanceCheckOutModal from "./components/AttendanceCheckOutModal";
import ChecklistModal from "./components/ChecklistModal";
import AutoLogoutModal from "./components/AutoLogoutModal";
import { Avatar, Typography, ListItemText } from "@mui/material";
import getInitials from "@/utils/getInitials";
import { actions as authActions } from "@/utils/auth";
import { Redirect } from "react-router-dom";
import HourFilters from "./components/HourFilters";
import { getItem, setItem, removeItem } from "@/utils/localStorage";

import queryString from "query-string";

export const REQUEST_LOCATION_PERMISSION_MESSAGE = `${i18next.t(
  "Your company has enabled geofence restrictions. Please grant the app access to your location to register hours."
)}`;

export const DENIED_LOCATION_PERMISSION_MESSAGE = `${i18next.t(
  "The app was unable to access your location. Please grant location access in your browser settings."
)}`;

const baseUrl = import.meta.env.VITE_API_BASE_URL;

class Hour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUserId: null,
      hourlist: [],
      hourTypesList: [],
      hourTypeOptions: [],
      checkError: false,
      pagination: null,
      limit: 140, // standard pagination doesn't work with card view so use higher limit to prevent pagination
      checkNewData: 1, // use to wait for hours (=2) and addon (=3), both API response
      openDeleteModal: false,
      deleteId: null,
      title: "",
      selectedProject: null,
      selectedProjectOption: null,
      selectedCustomerOption: null,
      selectedTask: null,
      selectedHourType: null,
      durationType: "Amount",
      taskType: "day",
      comment: "",
      amount: "",
      hours: "",
      minutes: "",
      startTime: "00:00",
      endTime: "00:00",
      lunchStartTime: "00:00",
      lunchEndTime: "00:00",
      isLunchTime: false,
      dayNumber: null,
      editId: null,
      repeat_Dates: [],
      amountError: false,
      rulesModuleAssigned: false,
      isGeoLockRequired: false,
      openAlertModal: true,
      userId: null,
      selectedUser: null,
      userList: [],
      hourApprovalRequired: false,
      selectedType: "hour_type",
      addonObjlist: [],
      addonTypeOptions: [],
      fixedAddonObjlist: [],
      fixedAddonTypeOptions: [],
      fixedAddonValue: "",
      selectedAddonType: null,
      selectedFixedAddonType: null,
      isHourRulesException: false,
      isAddonRulesException: false,
      addonApprovalRequired: false,
      addonModuleAssigned: true,
      isCoreManualEntries: false,
      isHourManualEntries: false,
      isAddonManualEntries: false,
      openCheckInModal: false,
      showCheckInCard: false,
      checkInData: null,
      timerId: null,
      lateComment: "",
      isLateCheckout: false,
      isRequireCommentEnabled: false,
      checkoutData: null,
      openCheckoutModal: false,
      allowCheckIn: true,
      batchId: null,
      checkBatchError: false,
      isBatchEnabled: false,
      isOptionalHourType: false,
      isOptionalAddonType: false,
      checkCheckInError: false,
      isContinuesCheckIn: false,
      openContinuesCheckInModal: false,
      continuesCheckInStartTime: null,
      isTypeSelected: false,
      checkAttendanceError: false,
      attendaceModuleAssigned: false,
      checkoutLogObj: null,
      checkAttendanceLog: false,
      checkAttendanceLogStatus: false,
      isTaskFinished: false,
      allowFinishTask: false,
      openMultiProjectModal: false,
      groupId: null,
      multiProjectTasks: [],
      multiProjectAddonTasks: [],
      fixedAddonTasks: [],
      multiProjectTasksPreview: [],
      multiProjectAddonTasksPreview: [],
      fixedAddonTasksPreview: [],
      multipleProjectAllHours: [],
      multipleProjectAllAddons: [],
      openProjectTaskModal: false,
      checkMultiProjectError: false,
      checkMultiProjectEditError: false,
      multiTaskFinish: [],
      isGroupCrossDay: false,
      isCheckInEntry: false,
      isGeoFenceForAttendace: false,
      isGeoFenceDisabled: false,
      isLoadProjectResult: false,
      multipleProjectNodeEditIndex: null,
      isEditMultipleProjectNode: false,
      allowCreateProject: true,
      allowCreateTask: true,
      reportWebAttendance: true,
      restrictEditTimeMultiProject: false,
      isMultiProjectHourTypeRequired: false,
      checkInBatchGroupId: null,
      isRestrictManualEntry: false,
      allowManualEntrySince: null,
      isCheckInForAttendance: false,
      checkInForAttendanceDone: false,
      openCheckInForAttendanceModal: false,
      openCheckOutForAttendanceModal: false,
      isManualEntriesOnEdit: false,
      hourNodeRemoved: [],
      addonNodeRemoved: [],
      fixedAddonNodeRemoved: [],
      isAutoFillLunch: false,
      autoFillLunchAmount: null,
      userLunchTime: 0,
      adminLunchTime: 0,
      isPreviewRulesOutcome: false,
      showPreviewRulesOutcome: false,
      previewError: "",
      isBatchCollapse: true,
      checkListModuleAssigned: false,
      checkList: [],
      openCheckListModal: false,
      checkListProjectIds: [],
      checkListProjectNames: [],
      checkListTaskIds: [],
      checkListTaskNames: [],
      isCheckListOncePerProject: false,
      plannedHours: [],
      planningModuleAssigned: false,
      ignoreCheckListStep: false,
      checkCheckListNeedToFillStatus: false,
      checkTaskList: false,
      checkTaskOptions: false,
      checkCustomerList: false,
      checkCustomerOptions: false,
      checkUserList: false,
      checkUserOptions: false,
      selectedUserProject: null,
      selectedUserProjectOption: null,
      selectedUserTask: null,
      selectedUserStartTime: "00:00",
      selectedUserEndTime: "00:00",
      selectedUserGroupLunchByDay: null,
      loginUserGroupLunchByDay: null,
      isTruncateLines: false,
      checkOptionalTypeError: false,
      GroupStartTime: "00:00",
      GroupEndTime: "00:00",
      GroupLunchStartTime: "00:00",
      GroupLunchEndTime: "00:00",
      isQuickHoursSubmit: false,
      refreshHourModalAfterApi: "hour",
      hourModalDataChanged: false,
      repeatDateCallPending: false,
      autoLogoutModalOpen: false,
      isDeviceProjectTask: false,
      deviceProjectList: null,
      deviceTaskList: null,
      showEditOutsideRulesOption: false,
      allowRulesToManualEntry: false,
      allowManualToRulesEntry: false,
      isRulesBasedHourEntry: false,
      openLeaveModal: false,
      checkApprovalError: false,
      restrictLunchEditIfAutofill: false,
      isSortAlphabetically: false,
      taskSortedAlphabetically: false,
      isMergeExistingHoursOnCheckout: false,
      isRemoveExistingHoursOnOverlap: false,
      mergeOverlapHours: false,
      removeOverlapHours: false,
      showCheckoutOverlapOption: false,
      showOnlyDashboard: false,
      isItemCertificationRequiered: false,
      isDependencyRequiered: false,
      isCheckInForContinuesFlow: false,
      nextCheckInObj: null,
      checkCheckoutSubmitError: false,
      isAllowToSeeOtherUsersPlans: false,
      isAllowEditWithGeofence: false,
      isValidatedGeofenceEntry: false,
      checkCheckInDiscardError: false,
      isSickLeaveHours: false,
      markCheckInForAttendance: false,
      isLunchTimeInvalid: false,
      hideApproveButton: false,
      isHoursDataRefreshed: false,
      isCompanySettingsDone: false, // wait for company settings to run, execute respective actions after it
      processCheckout: false, // set flag if checkout request from Plan/Dashboard page, run after company setting status
      isResumeCheckInEnabled: true,
      resumeCheckInTimeWindow: 15, // 15 min default
      isHoursLoaded: false,
      openCheckInAlert: false,
      checkInAlertData: null,
      showResumeCheckInDialog: false,
      resumeCheckinData: null,
    };

    this.succMsgDone = false;
    this.channel = null;
  }

  componentDidMount = async () => {
    await this.props.actions.getCompanyModules();
    await this.props.actions.getCompanySettings();

    // call everytime to get updated user group lunch time
    this.props.actions.getProfileData(null, null);

    if (getItem("userId")) {
      this.getDefaultUserValues(getItem("userId"));
    }

    // check if querystring filter is there
    const parsed = queryString.parse(this.props.location.search);

    await this.resetFilter();

    // from Approval or Dashboard page
    if (parsed && parsed.id && parsed.date) {
      this.setFiltersFromQueryString(parseInt(parsed.id, 10), parsed.date);
    } else {
      this.resetDefaultDates();
    }

    if (parsed && parsed.checkout) {
      let checkInData = null;
      if (getItem("planCheckInData")) {
        checkInData = getItem("planCheckInData");
        removeItem("planCheckInData");
      } else if (getItem("dashCheckInData")) {
        checkInData = getItem("dashCheckInData");
        removeItem("dashCheckInData");
      }
      if (checkInData) {
        // process the checkout after hours/addons are loaded
        this.setState({
          checkInData: JSON.parse(checkInData),
          processCheckout: true,
        });
      }
    }

    if (getItem("userId")) {
      this.setState({
        loginUserId: parseInt(getItem("userId"), 10),
      });
      this.lookForCheckinHour();
    }

    this.setState({ checkNewData: 1 });

    this.checkDeviceProjectTaskList();
  };

  checkDeviceProjectTaskList = () => {
    const projectTaskList = localStorage.getItem("defualt_project_task_list")
      ? JSON.parse(localStorage.getItem("defualt_project_task_list"))
      : [];

    if (projectTaskList && projectTaskList.length) {
      const projectIds = [];
      const projects = [];
      const tasks = [];
      projectTaskList.forEach((node) => {
        if (projectIds.indexOf(node.project.value) === -1) {
          projectIds.push(node.project.value);
          projects.push(node.project);
          tasks[node.project.value] = [];
          tasks[node.project.value].push(node.task);
        } else {
          tasks[node.project.value].push(node.task);
        }
      });

      this.setState({
        isDeviceProjectTask: true,
        deviceProjectList: projects,
        deviceTaskList: tasks,
      });
    }
  };

  lookForCheckinHour = () => {
    const userId = getItem("userId") ? parseInt(getItem("userId"), 10) : null;

    if (userId) {
      const queryString = `user_id=${userId}`;
      this.props.actions.getCheckInHour(queryString);
    }
  };

  setFiltersFromQueryString = (userId, hourDate) => {
    this.props.actions.filterData("hours", {
      userId: userId,
    });

    this.setState(
      {
        userId: userId,
      },
      () => {
        this.props.actions.getProfileData(userId, null);
      }
    );

    this.props.filterActions.changeFilter({
      startDate: moment(hourDate).startOf("week"),
      endDate: moment(hourDate).endOf("week"),
    });
  };

  checkFilter = async (checkType) => {
    let { profileReducer, commonReducer } = this.props;

    if (commonReducer && commonReducer.filters && commonReducer.filters.hours) {
      await this.setState({
        userId: commonReducer.filters.hours.userId
          ? parseInt(commonReducer.filters.hours.userId, 10)
          : null,
      });

      let value, label;
      if (
        commonReducer.filters.hours &&
        commonReducer.filters.hours.userId &&
        profileReducer.userList &&
        profileReducer.userList.entries &&
        profileReducer.userList.entries.length > 0
      ) {
        value = commonReducer.filters.hours.userId;
        let userEntry = profileReducer.userList.entries.find((entry) => {
          if (entry.id === parseInt(value)) {
            return entry;
          }
          return null;
        });

        if (userEntry && userEntry.name) {
          label = userEntry.name;
        }
      }

      if (
        value &&
        label &&
        (!this.state.selectedUser ||
          this.state.selectedUser.value !== value.toString())
      ) {
        if (
          this.props.profileReducer.userData &&
          this.props.profileReducer.userData.id
        ) {
          if (this.props.profileReducer.userData.id === value) {
            this.getSelectedUserData();
          } else {
            this.setState({ userId: null });
          }
        }
      }
    }

    if (checkType) {
      if (checkType === "addon") {
        this.getAddons();
      } else {
        this.getHours();
      }
    } else {
      this.getHours();
      if (this.state.addonModuleAssigned) {
        this.setState({ addonObjlist: [], fixedAddonObjlist: [] }); // clear the current list till new addon list comes
        this.getAddons();
        this.getFixedAddons();
      }
    }
  };

  resetDefaultDates = () => {
    this.props.filterActions.changeFilter({
      startDate: moment().clone().startOf("week"),
      endDate: moment().clone().endOf("week"),
    });
  };

  getDefaultUserValues = (userId) => {
    this.props.actions.getUserSpecificDefaultData(userId);
  };

  setDefaultUserValues = (data) => {
    let selectedUserProject = null;
    let selectedUserTask = null;
    let selectedUserProjectOption = null;
    let selectedUserStartTime = "00:00";
    let selectedUserEndTime = "00:00";
    let selectedUserGroupLunchByDay = null;

    if (
      localStorage.getItem("default_project") &&
      JSON.parse(localStorage.getItem("default_project")) !== null
    ) {
      selectedUserProject = JSON.parse(
        localStorage.getItem("default_project")
      ).value.toString();
      selectedUserProjectOption = JSON.parse(
        localStorage.getItem("default_project")
      );
    } else {
      if (data.project_id) {
        selectedUserProject = data.project_id;
        selectedUserProjectOption = {
          value: data.project_id.toString(),
          label: data.project_name,
        };
      }
    }

    if (
      localStorage.getItem("default_project") &&
      JSON.parse(localStorage.getItem("default_project")) !== null
    ) {
      if (localStorage.getItem("default_task")) {
        selectedUserTask = JSON.parse(localStorage.getItem("default_task"));
      }
    } else {
      if (data.task_id) {
        selectedUserTask = {
          value: data.task_id.toString(),
          label: data.task_name,
        };
      }
    }

    if (data.default_start_time) {
      selectedUserStartTime = data.default_start_time.substr(0, 5);
    }

    if (data.default_end_time) {
      selectedUserEndTime = data.default_end_time.substr(0, 5);
    }

    if (data.group_daily_hours) {
      selectedUserGroupLunchByDay = data.group_daily_hours;
    }

    this.setState({
      selectedUserProject,
      selectedUserProjectOption,
      selectedUserTask,
      selectedUserStartTime,
      selectedUserEndTime,
      selectedUserGroupLunchByDay,
    });

    // default values for the login user
    if (!this.state.userId || this.state.userId === this.state.loginUserId) {
      this.setState({
        loginUserGroupLunchByDay: selectedUserGroupLunchByDay,
      });
    }
  };

  componentDidUpdate = async (prevProps, prevStates) => {
    if (
      this.props.companyReducer &&
      this.props.companyReducer.companyModules &&
      prevProps.companyReducer.companyModules !==
        this.props.companyReducer.companyModules
    ) {
      if (
        this.props.companyReducer.companyModules &&
        this.props.companyReducer.companyModules.length > 0
      ) {
        if (
          this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Rules";
          })
        ) {
          this.setState({
            rulesModuleAssigned: true,
          });
        }
        if (
          !this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Addon";
          })
        ) {
          this.setState({
            addonModuleAssigned: false,
          });
        }
        if (
          this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Attendance";
          })
        ) {
          this.setState({
            attendaceModuleAssigned: true,
          });

          this.checkAttendanceLogForCheckInEntry();
        }
        if (
          this.props.companyReducer.companyModules.find((data) => {
            return data.name === "HMSK Check List";
          })
        ) {
          this.setState({
            checkListModuleAssigned: true,
          });
        }
        if (
          this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Planning";
          })
        ) {
          this.setState({
            planningModuleAssigned: true,
          });
        }
      }
    }

    if (
      this.props.companyReducer.companySettings &&
      prevProps.companyReducer.companySettings !==
        this.props.companyReducer.companySettings &&
      this.props.companyReducer.companySettings.entries &&
      this.props.companyReducer.companySettings.entries.length > 0
    ) {
      let companySettings = this.props.companyReducer.companySettings.entries;
      const companyCurrencySetting = companySettings.find((setting) => {
        return setting.system_setting_name === "Company Currency";
      });
      if (companyCurrencySetting) {
        const adminValue = companyCurrencySetting.default_admin_value;
        setItem("companyCurrency", adminValue);
      }
    }

    if (
      this.props.profileReducer &&
      this.props.profileReducer.userData &&
      this.props.profileReducer.userData.id &&
      !getItem("userRole")
    ) {
      // in case userRole is not set for some reason, redirect user to Default page to set respective user data/cookies
      this.props.history.push("/");
    }

    if (
      this.props.profileReducer &&
      this.props.profileReducer.userData &&
      this.props.profileReducer.userData.id &&
      (this.state.userId || this.state.loginUserId) &&
      !this.state.selectedUser &&
      getItem("userRole") === "admin" // only admin user can see user dropdown on top
    ) {
      const userId = this.props.profileReducer.userData.id;
      if (userId === this.state.userId || userId === this.state.loginUserId) {
        this.getSelectedUserData();
      } else {
        this.resetFilter();
        this.props.actions.getProfileData(null, null);
      }
    }

    // load every time, like company settings, to get updated value of user
    if (
      this.props.profileReducer &&
      this.props.profileReducer.userData &&
      this.props.profileReducer.userData !== prevProps.profileReducer.userData
    ) {
      const userData = this.props.profileReducer.userData;

      if (userData && userData.id && userData.id === this.state.loginUserId) {
        if (userData.merge_existing_hours_on_checkout) {
          this.setState({ isMergeExistingHoursOnCheckout: true });
        }
      }

      // if redirected from Approval or Dashboard page, set user in the Filter section and App bar.
      if (
        this.props.commonReducer.filters.hours &&
        this.props.commonReducer.filters.hours.userId &&
        this.state.userId === userData.id
      ) {
        this.getSelectedUserData();
      }

      // Resume check-in setting for logged in user
      if (userData.id === this.state.loginUserId) {
        this.setState({
          isResumeCheckInEnabled: userData?.resume_checkin_enabled
            ? true
            : false,
          resumeCheckInTimeWindow: userData?.resume_checkin_interval || 15,
        });
      }
    }

    if (
      this.props.profileReducer &&
      this.props.profileReducer.userDefaultData &&
      this.props.profileReducer.userDefaultData !==
        prevProps.profileReducer.userDefaultData
    ) {
      const userData = this.props.profileReducer.userDefaultData;

      this.setDefaultUserValues(userData);
    }

    if (this.state.checkTaskList && this.props.taskReducer) {
      if (this.state.selectedProject) {
        if (
          this.props.taskReducer.taskList &&
          prevProps.taskReducer.taskList !== this.props.taskReducer.taskList
        ) {
          this.setState({
            checkTaskList: false,
            checkTaskOptions: true,
          });
        }
      } else {
        if (
          this.props.taskReducer.allTasks &&
          prevProps.taskReducer.allTasks !== this.props.taskReducer.allTasks
        ) {
          this.setState({
            checkTaskList: false,
            checkTaskOptions: true,
          });
        }
      }
    }

    if (prevProps.taskReducer.newTask !== this.props.taskReducer.newTask) {
      if (
        this.state.selectedProject &&
        this.props.taskReducer &&
        this.props.taskReducer.newTask &&
        this.props.taskReducer.newTask.id
      ) {
        if (this.state.selectedTask && this.state.selectedTask.__isNew__) {
          this.setState({
            selectedTask: {
              value: this.props.taskReducer.newTask.id.toString(),
              label: this.state.selectedTask.label,
            },
          });
        }
      }
    }

    if (
      prevProps.taskReducer.taskError !== this.props.taskReducer.taskError &&
      this.state.checkError
    ) {
      if (
        this.props.taskReducer.taskError &&
        this.props.taskReducer.taskError.response &&
        this.props.taskReducer.taskError.response.message
      ) {
        this.setState({ checkError: false });
        this.props.enqueueSnackbar(
          this.props.taskReducer.taskError.response.message,
          { variant: "error" }
        );
      }
    }

    if (prevProps.companyReducer !== this.props.companyReducer) {
      if (
        this.props.companyReducer.companySettings &&
        this.props.companyReducer.companySettings.entries &&
        prevProps.companyReducer.companySettings.entries !==
          this.props.companyReducer.companySettings.entries &&
        this.props.companyReducer.companySettings.entries.length > 0
      ) {
        let isRulesModule = false;
        let entries = this.props.companyReducer.companySettings.entries;
        if (entries && entries.length > 0) {
          for (let i = 0; i < entries.length; i++) {
            if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Geofence restriction"
            ) {
              this.setCompanySettingAccess(entries[i], "isGeoLockRequired");
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Disable geofence on Tablet app"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isGeoFenceDisabled: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Hour Enable Approvals"
            ) {
              this.setCompanySettingAccess(entries[i], "hourApprovalRequired");
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Hour Rules Exception"
            ) {
              isRulesModule = true;
              this.setCompanySettingAccess(entries[i], "isHourRulesException");

              if (getItem("userId")) {
                setTimeout(() => {
                  this.props.actions.getHourTypes(
                    getItem("userId"),
                    this.state.isHourRulesException
                  );
                }, 1000);
              }
            } else if (
              this.state.addonModuleAssigned &&
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Addon Rules Exception"
            ) {
              this.setCompanySettingAccess(entries[i], "isAddonRulesException");
              if (getItem("userId")) {
                setTimeout(() => {
                  this.props.actions.getAddonTypes(
                    getItem("userId"),
                    this.state.isAddonRulesException
                  );
                  this.props.actions.getFixedAddonTypes();
                }, 1000);
              }
            } else if (
              this.state.addonModuleAssigned &&
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Addon Enable Approvals"
            ) {
              this.setCompanySettingAccess(entries[i], "addonApprovalRequired");
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Manual Entries"
            ) {
              this.setCompanySettingAccess(entries[i], "isCoreManualEntries");
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Hour Manual Entries"
            ) {
              this.setCompanySettingAccess(entries[i], "isHourManualEntries");
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Addon Manual Entries"
            ) {
              this.setCompanySettingAccess(entries[i], "isAddonManualEntries");
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Prompt lunch upon checkout"
            ) {
              this.setCompanySettingAccess(entries[i], "isLunchTime");
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Restrict Edit of Start End time for Multi Project (Group)"
            ) {
              this.setCompanySettingAccess(
                entries[i],
                "restrictEditTimeMultiProject"
              );
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Apply Batch Setting For Hour"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isBatchEnabled: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Enable Continuous Check-in"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isContinuesCheckIn: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Apply optional hour type"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isOptionalHourType: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Apply optional addon type"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isOptionalAddonType: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Finish tasks when adding hours"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ allowFinishTask: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Show hours by starting day if crossing midnight"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isGroupCrossDay: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Geofence restriction for attendance"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  isGeoFenceForAttendace: true,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Restrict project create edit delete"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  allowCreateProject: false,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Restrict task create edit delete"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  allowCreateTask: false,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Sort tasks alphabetically"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  taskSortedAlphabetically: true,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Separate hours from attendance"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  reportWebAttendance: false,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Edit outside of rules"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isManualEntriesOnEdit: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Show outside of rules option in Hours edit"
            ) {
              if (
                entries[i].default_admin_value === "TRUE" &&
                getItem("userRole") === "admin"
              ) {
                this.setState({ showEditOutsideRulesOption: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Auto Fill Lunch"
            ) {
              if (getItem("userRole") === "admin") {
                if (entries[i].default_admin_value !== "Off") {
                  this.setState({
                    isAutoFillLunch: true,
                    autoFillLunchAmount: parseFloat(
                      entries[i].default_admin_value
                    ),
                  });
                }
              } else {
                if (entries[i].default_user_value !== "Off") {
                  this.setState({
                    isAutoFillLunch: true,
                    autoFillLunchAmount: parseFloat(
                      entries[i].default_user_value
                    ),
                  });
                }
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Standard Lunch Time"
            ) {
              this.setState({
                userLunchTime:
                  parseFloat(entries[i].default_user_value) > 0
                    ? parseFloat(entries[i].default_user_value)
                    : 0,
                adminLunchTime:
                  parseFloat(entries[i].default_admin_value) > 0
                    ? parseFloat(entries[i].default_admin_value)
                    : 0,
              });
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Restrict edit lunch"
            ) {
              this.setCompanySettingAccess(
                entries[i],
                "restrictLunchEditIfAutofill"
              );
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Preview Rules Outcome"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isPreviewRulesOutcome: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Restrict Manual Entry"
            ) {
              if (getItem("userRole") === "admin") {
                if (entries[i].default_admin_value !== "None") {
                  this.setState({
                    isRestrictManualEntry: true,
                    allowManualEntrySince: parseInt(
                      entries[i].default_admin_value,
                      10
                    ),
                  });
                }
              } else {
                if (entries[i].default_user_value !== "None") {
                  this.setState({
                    isRestrictManualEntry: true,
                    allowManualEntrySince: parseInt(
                      entries[i].default_user_value,
                      10
                    ),
                  });
                }
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Batch by project and expand to see hourtype/addon breakdown"
            ) {
              if (entries[i].default_admin_value !== "TRUE") {
                this.setState({ isBatchCollapse: false });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Truncate long lines in Hour card"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isTruncateLines: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Once per project to every check-out"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isCheckListOncePerProject: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Remove existing hours on checkout if overlapping"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isRemoveExistingHoursOnOverlap: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "On Tablet, show only Dashboard"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ showOnlyDashboard: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Allow item requirement for check-in/add hours"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isItemCertificationRequiered: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Allow dependencies"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isDependencyRequiered: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Hide approval in hours page"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ hideApproveButton: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Allow users to see other users planned hours"
            ) {
              if (getItem("userRole") === "admin") {
                if (entries[i].default_admin_value === "TRUE") {
                  this.setState({
                    isAllowToSeeOtherUsersPlans: true,
                  });
                }
              } else {
                if (entries[i].default_user_value === "TRUE") {
                  this.setState({
                    isAllowToSeeOtherUsersPlans: true,
                  });
                }
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Allow edit hours when geofence is active"
            ) {
              this.setCompanySettingAccess(
                entries[i],
                "isAllowEditWithGeofence"
              );
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Require comment if check-in/checkout is outside of planned time."
            ) {
              this.setCompanySettingAccess(
                entries[i],
                "isRequireCommentEnabled"
              );
            }
          }

          this.setState({ isCompanySettingsDone: true });
        }

        // In case Rules Module is not assigned, load Hour Types separately for Add Hour modal
        if (!isRulesModule) {
          if (getItem("userId")) {
            this.props.actions.getHourTypes(getItem("userId"), false);
          }
        }
      }
    }

    if (prevProps.hourTypes !== this.props.hourTypes) {
      if (this.props.hourTypes?.hourTypesList) {
        let entries = this.props.hourTypes.hourTypesList.entries;
        this.setState({ hourTypesList: entries });
        let data = [];
        if (entries && entries.length > 0) {
          // Add empty
          data.push({ value: "", label: `${i18next.t("Select")}...` });

          for (let i = 0; i < entries.length; i++) {
            const element = entries[i];
            data.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
          this.setState({ hourTypeOptions: data });
        } else {
          this.setState({ hourTypeOptions: [] });
        }
      }
    }

    if (
      this.state.addonModuleAssigned &&
      prevProps.addonTypes !== this.props.addonTypes
    ) {
      if (
        this.props.addonTypes?.addonTypesList &&
        prevProps.addonTypes?.addonTypesList !==
          this.props.addonTypes.addonTypesList
      ) {
        const entries = this.props.addonTypes.addonTypesList.entries;
        const data = [];
        if (entries && entries.length > 0) {
          // Add empty
          data.push({ value: "", label: `${i18next.t("Select")}...` });

          for (let i = 0; i < entries.length; i++) {
            const element = entries[i];
            data.push({
              value: element.id,
              label: element.name,
              amount: element.default_value ? element.default_value : "",
            });
          }
          this.setState({ addonTypeOptions: data });
        } else {
          this.setState({ addonTypeOptions: [] });
        }
      }

      if (
        this.props.addonTypes?.fixedAddonTypes &&
        prevProps.addonTypes?.fixedAddonTypes !==
          this.props.addonTypes.fixedAddonTypes
      ) {
        const entries = this.props.addonTypes.fixedAddonTypes.entries;
        const data = [];
        if (entries && entries.length > 0) {
          // Add empty
          data.push({ value: "", label: `${i18next.t("Select")}...` });

          for (let i = 0; i < entries.length; i++) {
            const element = entries[i];
            data.push({
              value: element.id,
              label: element.name,
              measurement: element?.addon_measurement_name,
              color: element?.color,
            });
          }
          this.setState({ fixedAddonTypeOptions: data });
        } else {
          this.setState({ fixedAddonTypeOptions: [] });
        }
      }
    }

    if (prevProps.projectReducer !== this.props.projectReducer) {
      if (
        this.props.projectReducer.addProjectSuccess &&
        prevProps.projectReducer.addProjectSuccess !==
          this.props.projectReducer.addProjectSuccess &&
        this.props.projectReducer.addProjectSuccess.id
      ) {
        if (
          this.state.selectedProjectOption &&
          this.state.selectedProjectOption.__isNew__
        ) {
          this.setState({
            selectedProject: this.props.projectReducer.addProjectSuccess.id,
            selectedProjectOption: {
              value: this.props.projectReducer.addProjectSuccess.id.toString(),
              label: this.state.selectedProjectOption.label,
            },
          });
        }
      }

      if (
        this.props.projectReducer.projectList &&
        this.props.projectReducer.projectList !==
          prevProps.projectReducer.projectList
      ) {
        this.setState({ isLoadProjectResult: true });
      }
    }

    // In case of creating a new customer, update the id in the listbox, create project
    if (prevProps.customerReducer !== this.props.customerReducer) {
      if (
        this.props.customerReducer.newCustomer &&
        prevProps.customerReducer.newCustomer !==
          this.props.customerReducer.newCustomer &&
        this.props.customerReducer.newCustomer.id
      ) {
        this.setState({
          selectedCustomerOption: {
            value: this.props.customerReducer.newCustomer.id.toString(),
            label: this.state.selectedCustomerOption.__isNew__
              ? this.state.selectedCustomerOption.value
              : this.state.selectedCustomerOption.label,
          },
        });

        this.addProject(
          this.state.selectedProjectOption.__isNew__
            ? this.state.selectedProjectOption.value
            : this.state.selectedProjectOption.label,
          this.props.customerReducer.newCustomer.id
        );
      }
    }

    if (
      this.props.hourReducer &&
      this.props.hourReducer.checkInHour &&
      this.props.hourReducer.checkInHour.entries &&
      this.props.hourReducer.checkInHour.entries !==
        prevProps.hourReducer.checkInHour.entries
    ) {
      const entries = this.props.hourReducer.checkInHour.entries;

      let data = null;
      for (let i = 0; i < entries.length; i++) {
        // skip entries where end_time value exists
        if (entries[i].end_time) {
          continue;
        }

        if (entries[i].planning_planned_hour_id) {
          this.props.actions.getPlan(entries[i].planning_planned_hour_id);
        }

        // show check-in hour from planning as well
        data = entries[i];
        break;
      }

      if (data) {
        this.setState({
          checkInData: data,
          showCheckInCard: true,
          allowCheckIn: false,
          isCheckInForContinuesFlow: data.batch_group_id ? true : false,
        });

        this.removeIfContinuousCheckInID(moment().clone());
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkNewData
    ) {
      if (
        this.props.hourReducer.hourList &&
        this.props.hourReducer.hourList.entries &&
        prevProps.hourReducer.hourList.entries !==
          this.props.hourReducer.hourList.entries
      ) {
        this.setState({
          pagination: this.props.hourReducer.hourList.pagination,
        });
        let hrsList =
          this.props.hourReducer.hourList.entries.length > 0
            ? this.props.hourReducer.hourList.entries
            : [];
        let hourlist = {};
        let tasklist = {};
        hrsList.map((task) => {
          let duration = task.amount.split(".");
          tasklist["task-" + task.id] = {
            hourId: task.id,
            id: task.id,
            project_id: task.project_id,
            project_name: task.project_name,
            customer_name: task.customer_name,
            task_id: task.task_id,
            task_name: task.task_name,
            task_finish: task.task_finish ? task.task_finish : false,
            hour_type_id: task.hour_type_id,
            hour_type_name: task.hour_type_name,
            comment: task.comment,
            duration: duration[0] + "h " + duration[1] + "m",
            amount: parseFloat(task.amount),
            start_time: task.start_time,
            end_time: task.end_time,
            type: "day",
            status: task.status,
            lunch_start_time: task.lunch_start_time,
            lunch_end_time: task.lunch_end_time,
            project_color: task.project_color,
            created_at: task.created_at,
            approved_by_name: task.approved_by_name,
            validated_geofence: task?.validated_geofence,
          };

          if (
            task.batch_id &&
            this.state.rulesModuleAssigned &&
            this.state.isBatchEnabled
          ) {
            tasklist["task-" + task.id]["batch_id"] = task.batch_id;
            if (task.batch_start_time) {
              tasklist["task-" + task.id]["batch_start_time"] =
                task.batch_start_time;
              tasklist["task-" + task.id]["batch_end_time"] =
                task.batch_end_time;
            }
            if (task.batch_lunch_start_time) {
              tasklist["task-" + task.id]["batch_lunch_start_time"] =
                task.batch_lunch_start_time;
              tasklist["task-" + task.id]["batch_lunch_end_time"] =
                task.batch_lunch_end_time;
            }
          } else {
            tasklist["task-" + task.id]["batch_id"] = null;
          }

          if (task.batch_group_id) {
            tasklist["task-" + task.id]["batch_group_id"] = task.batch_group_id;
            if (task.batch_group_start_time) {
              tasklist["task-" + task.id]["batch_group_start_time"] =
                task.batch_group_start_time;
              tasklist["task-" + task.id]["batch_group_end_time"] =
                task.batch_group_end_time;
            }
            if (task.batch_group_lunch_start_time) {
              tasklist["task-" + task.id]["batch_group_lunch_start_time"] =
                task.batch_group_lunch_start_time;
              tasklist["task-" + task.id]["batch_group_lunch_end_time"] =
                task.batch_group_lunch_end_time;
            }
          } else {
            tasklist["task-" + task.id]["batch_group_id"] = null;
          }

          // for checkin flow
          if (task?.check_in_out_id) {
            tasklist["task-" + task.id]["check_in_out_id"] =
              task.check_in_out_id;
            tasklist["task-" + task.id]["check_in_out_start_time"] =
              task?.check_in_out_start_time;
            tasklist["task-" + task.id]["check_in_out_end_time"] =
              task?.check_in_out_end_time;
          }

          if (task?.applicable_sick_leave_user_id) {
            tasklist["task-" + task.id]["sick_leave_hour"] = true;
          }

          return true;
        });

        hourlist.tasks = tasklist;
        let columnsList = {
          Sunday: {
            id: "Sunday",
            dayName: "Sunday",
            totalHours: 0,
            groupIds: [],
          },
          Monday: {
            id: "Monday",
            dayName: "Monday",
            totalHours: 0,
            groupIds: [],
          },
          Tuesday: {
            id: "Tuesday",
            dayName: "Tuesday",
            totalHours: 0,
            groupIds: [],
          },
          Wednesday: {
            id: "Wednesday",
            dayName: "Wednesday",
            totalHours: 0,
            groupIds: [],
          },
          Thursday: {
            id: "Thursday",
            dayName: "Thursday",
            totalHours: 0,
            groupIds: [],
          },
          Friday: {
            id: "Friday",
            dayName: "Friday",
            totalHours: 0,
            groupIds: [],
          },
          Saturday: {
            id: "Saturday",
            dayName: "Saturday",
            totalHours: 0,
            groupIds: [],
          },
        };

        hrsList.map((task) => {
          let weekDay = getWeekday(moment(task.start_time).utc());
          if (this.state.isGroupCrossDay) {
            if (task.batch_group_id && task.batch_group_start_time) {
              weekDay = getWeekday(moment(task.batch_group_start_time).utc());
            } else if (
              task.batch_id &&
              this.state.rulesModuleAssigned &&
              this.state.isBatchEnabled &&
              task.batch_start_time
            ) {
              weekDay = getWeekday(moment(task.batch_start_time).utc());
            }
          }

          if (weekDay !== undefined) {
            if (columnsList[weekDay]) {
              columnsList[weekDay].id = weekDay;
              columnsList[weekDay].dayName = weekDay;
              columnsList[weekDay].totalHours += parseFloat(task.amount);

              if (task.batch_group_id) {
                if (
                  columnsList[weekDay].groupIds.indexOf(task.batch_group_id) ===
                  -1
                ) {
                  columnsList[weekDay].groupIds.push(task.batch_group_id);
                }
              }
            }
          }
          return true;
        });

        hourlist.columns = columnsList;
        this.setState(
          { hourlist, checkNewData: this.state.checkNewData + 1 },
          () => {
            if (
              this.state.isQuickHoursSubmit &&
              this.state.refreshHourModalAfterApi === "hour"
            ) {
              this.loadUpdatedDataInHoursModal();
            }
          }
        );

        this.setState({ isHoursDataRefreshed: true });
      }

      // Addon
      if (
        this.state.addonModuleAssigned &&
        this.props.hourReducer.addonList &&
        this.props.hourReducer.addonList.entries &&
        prevProps.hourReducer.addonList.entries !==
          this.props.hourReducer.addonList.entries
      ) {
        let addonList =
          this.props.hourReducer.addonList.entries.length > 0
            ? this.props.hourReducer.addonList.entries
            : [];

        let addonObjlist = {};
        let tasklist = {};
        addonList.map((task) => {
          let duration = task.amount.split(".");
          tasklist["task-" + task.id] = {
            hourId: task.id,
            id: task.id,
            project_id: task.project_id,
            project_name: task.project_name,
            customer_name: task.customer_name,
            task_id: task.task_id,
            task_name: task.task_name,
            task_finish: task.task_finish ? task.task_finish : false,
            addon_type_id: task.addon_addon_type_id,
            addon_type_name: task.addon_addon_type_name,
            comment: task.comment,
            duration: duration[0] + "h " + duration[1] + "m",
            amount: parseFloat(task.amount),
            start_time: task.start_time,
            end_time: task.end_time,
            type: "day",
            addon: true,
            status: task.status,
            lunch_start_time: task.lunch_start_time,
            lunch_end_time: task.lunch_end_time,
            project_color: task.project_color,
            created_at: task.created_at,
            approved_by_name: task.approved_by_name,
          };

          if (
            task.batch_id &&
            this.state.rulesModuleAssigned &&
            this.state.isBatchEnabled
          ) {
            tasklist["task-" + task.id]["batch_id"] = task.batch_id;
            if (task.batch_start_time) {
              tasklist["task-" + task.id]["batch_start_time"] =
                task.batch_start_time;
              tasklist["task-" + task.id]["batch_end_time"] =
                task.batch_end_time;
            }
          } else {
            tasklist["task-" + task.id]["batch_id"] = null;
          }

          if (task.batch_group_id) {
            tasklist["task-" + task.id]["batch_group_id"] = task.batch_group_id;
            if (task.batch_group_start_time) {
              tasklist["task-" + task.id]["batch_group_start_time"] =
                task.batch_group_start_time;
              tasklist["task-" + task.id]["batch_group_end_time"] =
                task.batch_group_end_time;
            }
          } else {
            tasklist["task-" + task.id]["batch_group_id"] = null;
          }

          return true;
        });

        addonObjlist.tasks = tasklist;
        let columnsList = {
          Sunday: {
            id: "Sunday",
            dayName: "Sunday",
            totalHours: 0,
            groupIds: [],
          },
          Monday: {
            id: "Monday",
            dayName: "Monday",
            totalHours: 0,
            groupIds: [],
          },
          Tuesday: {
            id: "Tuesday",
            dayName: "Tuesday",
            totalHours: 0,
            groupIds: [],
          },
          Wednesday: {
            id: "Wednesday",
            dayName: "Wednesday",
            totalHours: 0,
            groupIds: [],
          },
          Thursday: {
            id: "Thursday",
            dayName: "Thursday",
            totalHours: 0,
            groupIds: [],
          },
          Friday: {
            id: "Friday",
            dayName: "Friday",
            totalHours: 0,
            groupIds: [],
          },
          Saturday: {
            id: "Saturday",
            dayName: "Saturday",
            totalHours: 0,
            groupIds: [],
          },
        };

        addonList.map((task) => {
          let weekDay = getWeekday(moment(task.start_time).utc());
          if (this.state.isGroupCrossDay) {
            if (task.batch_group_id && task.batch_group_start_time) {
              weekDay = getWeekday(moment(task.batch_group_start_time).utc());
            } else if (
              task.batch_id &&
              this.state.rulesModuleAssigned &&
              this.state.isBatchEnabled &&
              task.batch_start_time
            ) {
              weekDay = getWeekday(moment(task.batch_start_time).utc());
            }
          }

          if (weekDay !== undefined) {
            if (columnsList[weekDay]) {
              columnsList[weekDay].id = weekDay;
              columnsList[weekDay].dayName = weekDay;
              columnsList[weekDay].totalHours += parseFloat(task.amount);

              if (task.batch_group_id) {
                if (
                  columnsList[weekDay].groupIds.indexOf(task.batch_group_id) ===
                  -1
                ) {
                  columnsList[weekDay].groupIds.push(task.batch_group_id);
                }
              }
            }
          }
          return true;
        });

        addonObjlist.columns = columnsList;
        this.setState(
          { addonObjlist, checkNewData: this.state.checkNewData + 1 },
          () => {
            if (
              this.state.isQuickHoursSubmit &&
              this.state.refreshHourModalAfterApi === "addon"
            ) {
              this.loadUpdatedDataInHoursModal();
            }
          }
        );
      }

      // Fixed addon
      if (
        this.state.addonModuleAssigned &&
        this.props.hourReducer.fixedAddonList &&
        this.props.hourReducer.fixedAddonList.entries &&
        prevProps.hourReducer.fixedAddonList.entries !==
          this.props.hourReducer.fixedAddonList.entries
      ) {
        let fixedAddonList =
          this.props.hourReducer.fixedAddonList.entries.length > 0
            ? this.props.hourReducer.fixedAddonList.entries
            : [];

        let fixedAddonObjlist = {};
        let tasklist = {};
        fixedAddonList.map((task) => {
          tasklist["task-" + task.id] = {
            hourId: task.id,
            id: task.id,
            project_id: task.project_id,
            project_name: task.project_name,
            task_id: task.task_id,
            task_name: task.task_name,
            addon_type_id: task.addon_fixed_addon_type_id,
            addon_type_name: task.addon_fixed_addon_type_name,
            status: task.status,
            value: task.measurement_value,
            measurement: task.addon_measurement_name,
            fixed_addon: true,
            color: task.color,
            project_color: task.project_color,
          };

          if (
            task.batch_id &&
            this.state.rulesModuleAssigned &&
            this.state.isBatchEnabled
          ) {
            tasklist["task-" + task.id]["batch_id"] = task.batch_id;
            if (task.batch_start_time) {
              tasklist["task-" + task.id]["batch_start_time"] =
                task.batch_start_time;
              tasklist["task-" + task.id]["batch_end_time"] =
                task.batch_end_time;
            }
          } else {
            tasklist["task-" + task.id]["batch_id"] = null;
          }

          if (task.batch_group_id) {
            tasklist["task-" + task.id]["batch_group_id"] = task.batch_group_id;
            if (task.batch_group_start_time) {
              tasklist["task-" + task.id]["start_time"] =
                task.batch_group_start_time;
              tasklist["task-" + task.id]["batch_group_start_time"] =
                task.batch_group_start_time;
              tasklist["task-" + task.id]["end_time"] =
                task.batch_group_end_time;
              tasklist["task-" + task.id]["batch_group_end_time"] =
                task.batch_group_end_time;
            }
          } else {
            tasklist["task-" + task.id]["batch_group_id"] = null;
          }

          return true;
        });

        fixedAddonObjlist.tasks = tasklist;

        let columnsList = {
          Sunday: {
            id: "Sunday",
            dayName: "Sunday",
            totalHours: 0,
            groupIds: [],
          },
          Monday: {
            id: "Monday",
            dayName: "Monday",
            totalHours: 0,
            groupIds: [],
          },
          Tuesday: {
            id: "Tuesday",
            dayName: "Tuesday",
            totalHours: 0,
            groupIds: [],
          },
          Wednesday: {
            id: "Wednesday",
            dayName: "Wednesday",
            totalHours: 0,
            groupIds: [],
          },
          Thursday: {
            id: "Thursday",
            dayName: "Thursday",
            totalHours: 0,
            groupIds: [],
          },
          Friday: {
            id: "Friday",
            dayName: "Friday",
            totalHours: 0,
            groupIds: [],
          },
          Saturday: {
            id: "Saturday",
            dayName: "Saturday",
            totalHours: 0,
            groupIds: [],
          },
        };

        fixedAddonList.map((task) => {
          let weekDay = undefined;
          if (task.batch_group_id && task.batch_group_start_time) {
            weekDay = getWeekday(moment(task.batch_group_start_time).utc());
          } else if (
            task.batch_id &&
            this.state.rulesModuleAssigned &&
            this.state.isBatchEnabled &&
            task.batch_start_time
          ) {
            weekDay = getWeekday(moment(task.batch_start_time).utc());
          }

          if (weekDay !== undefined) {
            if (columnsList[weekDay]) {
              columnsList[weekDay].id = weekDay;
              columnsList[weekDay].dayName = weekDay;

              if (task.batch_group_id) {
                if (
                  columnsList[weekDay].groupIds.indexOf(task.batch_group_id) ===
                  -1
                ) {
                  columnsList[weekDay].groupIds.push(task.batch_group_id);
                }
              }
            }
          }
          return true;
        });

        fixedAddonObjlist.columns = columnsList;

        this.setState(
          { fixedAddonObjlist, checkNewData: this.state.checkNewData + 1 },
          () => {
            if (
              this.state.isQuickHoursSubmit &&
              this.state.refreshHourModalAfterApi === "fixed_addon"
            ) {
              this.loadUpdatedDataInHoursModal();
            }
          }
        );
      }
    }

    if (
      (prevProps.startDate !== this.props.startDate ||
        prevProps.endDate !== this.props.endDate) &&
      getItem("userId")
    ) {
      this.checkFilter();
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkCheckInError
    ) {
      if (this.props.hourReducer?.checkInError?.response?.message) {
        this.setState({
          checkCheckInError: false,
          checkInData: null,
          showCheckInCard: false,
          allowCheckIn: true,
          isLateCheckout: false,
        });

        this.props.enqueueSnackbar(
          this.props.hourReducer.checkInError.response.message,
          { variant: "error" }
        );
      }

      if (this.props.hourReducer?.createCheckInSuccess?.id) {
        let checkInDataObj = this.state.checkInData;
        checkInDataObj["id"] = this.props.hourReducer.createCheckInSuccess.id;

        if (this.props.hourReducer.createCheckInSuccess?.batch_group_id) {
          checkInDataObj["batch_group_id"] =
            this.props.hourReducer.createCheckInSuccess.batch_group_id;
        }

        this.setState({
          checkCheckInError: false,
          checkInData: checkInDataObj,
        });

        // auto logout
        this.setState({
          autoLogoutModalOpen: true,
        });
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkAttendanceError
    ) {
      this.setState({ checkAttendanceError: false });

      if (this.props.hourReducer?.attendanceError?.response?.message) {
        this.props.enqueueSnackbar(
          this.props.hourReducer.attendanceError.response.message,
          { variant: "error" }
        );
      } else {
        // success
        if (this.state.markCheckInForAttendance) {
          this.markCheckInForAttendanceStatus();
        }

        // call the get call to update status on Side menu bar
        this.props.actions.getLastUserAttendance();
      }
    }

    // update the user attendance status if that change through Sidebar
    if (
      this.props?.hourReducer?.lastUserAttendance &&
      prevProps?.hourReducer?.lastUserAttendance !==
        this.props.hourReducer.lastUserAttendance &&
      !this.state.checkInForAttendanceDone
    ) {
      if (this.props.hourReducer.lastUserAttendance?.status) {
        // get current status of the attendance
        if (
          this.props.hourReducer.lastUserAttendance.status !== "not-check-in"
        ) {
          this.setState({
            checkInForAttendanceDone: true,
          });
        }
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkAttendanceLog
    ) {
      if (this.props.hourReducer?.lastUserAttendance?.entry) {
        this.setState({ checkAttendanceLog: false });
        if (this.props.hourReducer.lastUserAttendance.entry.id) {
          const attendaceId =
            this.props.hourReducer.lastUserAttendance.entry.id;
          if (
            attendaceId &&
            this.state.checkoutLogObj &&
            this.state.reportWebAttendance
          ) {
            await this.props.actions.updateUserAttendance(
              attendaceId,
              this.state.checkoutLogObj
            );
            this.setState({
              checkoutLogObj: null,
              checkAttendanceError: true,
            });
          }
        }
      }
    }

    if (
      this.props?.hourReducer?.lastUserAttendance &&
      prevProps?.hourReducer?.lastUserAttendance !==
        this.props.hourReducer.lastUserAttendance &&
      this.state.checkAttendanceLogStatus
    ) {
      if (this.props.hourReducer?.lastUserAttendance?.status) {
        this.setState({ checkAttendanceLogStatus: false });
        if (
          this.props.hourReducer.lastUserAttendance.status !== "not-check-in"
        ) {
          this.setState({
            checkInForAttendanceDone: true,
          });
        }
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkAttendanceLogEntryForCheckout
    ) {
      if (this.props.hourReducer?.lastUserAttendance?.entry) {
        this.setState({ checkAttendanceLogEntryForCheckout: false });

        if (this.props.hourReducer.lastUserAttendance.entry.id) {
          const attendaceId =
            this.props.hourReducer.lastUserAttendance.entry.id;
          if (attendaceId) {
            await this.props.actions.updateUserAttendance(
              attendaceId,
              this.state.checkoutLogObj
            );
            this.setState({
              checkoutLogObj: null,
              checkAttendanceError: true,
            });
          }
        }
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkCheckInDiscardError
    ) {
      if (this.props.hourReducer?.checkInError?.response?.message) {
        this.setState({ checkCheckInDiscardError: false });
        this.refreshHoursData();

        const responseObj = this.props.hourReducer.checkInError.response;
        this.props.enqueueSnackbar(responseObj.message, { variant: "error" });
      }

      if (this.props.hourReducer?.checkInSuccess?.message) {
        this.setState({ checkCheckInDiscardError: false });
        this.refreshHoursData();

        this.props.enqueueSnackbar(
          this.props.hourReducer.checkInSuccess.message,
          {
            variant: "success",
          }
        );
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkMultiProjectEditError
    ) {
      if (this.props.hourReducer?.multiProjectHourError?.response?.message) {
        this.setState({ checkMultiProjectEditError: false });

        const responseObj =
          this.props.hourReducer.multiProjectHourError.response;
        this.props.enqueueSnackbar(responseObj.message, { variant: "error" });

        if (
          this.state.showEditOutsideRulesOption &&
          !this.state.isManualEntriesOnEdit
        ) {
          this.handleMultiProjectModalCancel();
          this.refreshHoursData();
        }

        if (this.state.isQuickHoursSubmit) {
          this.refreshHoursData();
        }
      }

      if (this.props.hourReducer?.multiProjectHourSuccess?.message) {
        this.setState({ checkMultiProjectEditError: false });
        this.props.enqueueSnackbar(
          this.props.hourReducer.multiProjectHourSuccess.message,
          {
            variant: "success",
          }
        );

        this.refreshHoursData();
      }

      // in case of 500 error code
      if (this.props.hourReducer?.multiProjectHourError?.response?.error) {
        this.setState({ checkMultiProjectEditError: false });

        const responseObj =
          this.props.hourReducer.multiProjectHourError.response;
        this.props.enqueueSnackbar(responseObj.error, { variant: "error" });

        if (
          this.state.showEditOutsideRulesOption &&
          !this.state.isManualEntriesOnEdit
        ) {
          this.handleMultiProjectModalCancel();
          this.refreshHoursData();
        }

        if (this.state.isQuickHoursSubmit) {
          this.refreshHoursData();
        }
      }
    }

    // process continuous checkout call
    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkCheckoutSubmitError
    ) {
      // for Tablet, check if Hour modal need to skip
      const isSkipHourModal =
        localStorage.getItem("skip_hour_modal") &&
        localStorage.getItem("skip_hour_modal") !== "true"
          ? false
          : true;

      if (this.props.hourReducer?.checkoutHourError?.response?.message) {
        this.setState({ checkCheckoutSubmitError: false });

        const responseObj = this.props.hourReducer.checkoutHourError.response;
        this.props.enqueueSnackbar(responseObj.message, { variant: "error" });

        if (this.state.isCheckInEntry) {
          let hourOverlap = false;
          if (responseObj.apply_overlap) {
            hourOverlap = true;
          }

          this.handleMultiProjectCheckOutDone(false, hourOverlap);
        }

        if (this.state.isQuickHoursSubmit || isSkipHourModal) {
          this.refreshHoursData();
        }
      }

      if (this.props.hourReducer?.checkoutHourSuccess?.message) {
        this.setState({ checkCheckoutSubmitError: false });
        this.props.enqueueSnackbar(
          this.props.hourReducer.checkoutHourSuccess.message,
          {
            variant: "success",
          }
        );

        if (this.state.isCheckInEntry || isSkipHourModal) {
          let checkInOutId = null;
          if (this.props.hourReducer.checkoutHourSuccess.check_ins) {
            const checkInEntries =
              this.props.hourReducer.checkoutHourSuccess.check_ins;

            for (let i = 0; i < checkInEntries.length; i++) {
              if (!checkInEntries[i].end_time) {
                checkInOutId = checkInEntries[i].id;
              }
            }
          }

          this.handleMultiProjectCheckOutDone(true, false, checkInOutId);
        }

        this.refreshHoursData();
      }

      // in case of 500 error code
      if (this.props.hourReducer?.checkoutHourError?.response?.error) {
        this.setState({ checkCheckoutSubmitError: false });

        const responseObj = this.props.hourReducer.checkoutHourError.response;
        this.props.enqueueSnackbar(responseObj.error, { variant: "error" });

        if (this.state.isCheckInEntry || isSkipHourModal) {
          this.handleMultiProjectCheckOutDone(false);
        }

        if (this.state.isQuickHoursSubmit || isSkipHourModal) {
          this.refreshHoursData();
        }
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkMultiProjectError
    ) {
      // todo: this error/success section is not in use
      if (this.props.hourReducer?.multiProjectHourError?.response?.message) {
        this.setState({ checkMultiProjectError: false });
        this.props.enqueueSnackbar(
          this.props.hourReducer.multiProjectHourError.response.message,
          { variant: "error" }
        );
      }
      if (this.props.hourReducer?.multiProjectHourSuccess?.message) {
        this.setState({ checkMultiProjectError: false });
        this.props.enqueueSnackbar(
          this.props.hourReducer.multiProjectHourSuccess.message,
          {
            variant: "success",
          }
        );

        this.setState({ checkNewData: 1, hourlist: [] });
        this.getHours();
        if (this.state.addonModuleAssigned) {
          this.setState({ addonObjlist: [], fixedAddonObjlist: [] });
          this.getAddons(); // in case the hour also create addon based on the Rules
          this.getFixedAddons();
        }
      }

      if (this.props.hourReducer?.multiProjectHourError?.response?.errors) {
        this.setState({ checkMultiProjectError: false });

        const responseObj =
          this.props.hourReducer.multiProjectHourError.response;
        this.props.enqueueSnackbar(responseObj.errors, { variant: "error" });

        if (this.state.isQuickHoursSubmit) {
          this.refreshHoursData();
        }
      }

      if (this.props.hourReducer?.multiProjectHourSuccess?.ids) {
        let batchGroupId = null;
        const responseObj = this.props.hourReducer.multiProjectHourSuccess;
        if (responseObj.errors && responseObj.errors.length > 0) {
          this.setState({ checkMultiProjectError: false });
          this.props.enqueueSnackbar(responseObj.errors, { variant: "error" });

          if (this.state.isQuickHoursSubmit) {
            this.refreshHoursData();
          }
        } else {
          this.props.enqueueSnackbar(
            i18next.t(
              this.state.groupId
                ? "Record Updated Successfully"
                : "Record Created Successfully"
            ),
            {
              variant: "success",
            }
          );
          this.setState({
            checkMultiProjectError: false,
          });

          // get batch_group_id of posted hours
          const hours = responseObj.ids;
          if (Array.isArray(hours) && hours.length > 0) {
            if (hours[0].batch_group_id) {
              batchGroupId = hours[0].batch_group_id;
            }
          }

          // in case, only manual addon is posted
          if (!batchGroupId) {
            const addons = responseObj?.addon_ids;
            if (Array.isArray(addons) && addons.length > 0) {
              if (addons[0].batch_group_id) {
                batchGroupId = addons[0].batch_group_id;
              }
            }
          }
        }

        this.refreshHoursData(batchGroupId);
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkPreviewMultiProject
    ) {
      if (
        this.props.hourReducer?.previewMultiProjectHourError?.response?.message
      ) {
        this.setState({ checkPreviewMultiProject: false });
      }

      if (this.props.hourReducer.previewMultiProjectHourSuccess) {
        this.setState({ checkPreviewMultiProject: false });
        this.prepareMultipleProjectPreview(
          this.props.hourReducer.previewMultiProjectHourSuccess
        );
      }
    }

    if (
      this.props.hourReducer?.approvalSuccess &&
      this.props.hourReducer.approvalSuccess !==
        prevProps?.hourReducer?.approvalSuccess
    ) {
      this.getHours();

      if (this.state.addonModuleAssigned) {
        this.setState({ addonObjlist: [], fixedAddonObjlist: [] });
        this.getAddons(); // in case the hour also create addon based on the Rules
        this.getFixedAddons();
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkApprovalError
    ) {
      if (this.props.hourReducer?.approvalSuccess?.message) {
        this.setState({ checkApprovalError: false });

        this.props.enqueueSnackbar(
          this.props.hourReducer.approvalSuccess.message,
          { variant: "success" }
        );

        this.channel.postMessage("approved_hours");
      }
      if (this.props.hourReducer?.approvalError?.message) {
        this.setState({ checkApprovalError: false });

        this.props.enqueueSnackbar(
          this.props.hourReducer.approvalError.message,
          { variant: "error" }
        );

        this.channel.postMessage("approved_hours");
      }
    }

    if (
      this.props.hourReducer.hours &&
      this.props.hourReducer.hours !== prevProps.hourReducer.hours
    ) {
      this.setState({ isHoursLoaded: true });
    }

    if (
      this.props.hourTypes &&
      prevProps.hourTypes !== this.props.hourTypes &&
      this.state.checkOptionalTypeError
    ) {
      if (this.props.hourTypes?.errMsg?.response?.message) {
        this.props.enqueueSnackbar(
          this.props.hourTypes.errMsg.response.message,
          { variant: "error" }
        );
      }

      if (this.props.hourTypes?.succMsg?.message && !this.succMsgDone) {
        this.props.enqueueSnackbar(this.props.hourTypes.succMsg.message, {
          variant: "success",
        });

        //show only single success message for multiple call of Optional hour type change
        this.succMsgDone = true;
      }
    }

    if (
      this.props.addonTypes &&
      prevProps.addonTypes !== this.props.addonTypes &&
      this.state.checkOptionalTypeError
    ) {
      if (this.props.addonTypes?.errMsg?.response?.message) {
        this.props.enqueueSnackbar(
          this.props.addonTypes.errMsg.response.message,
          { variant: "error" }
        );
      }

      if (this.props.addonTypes?.succMsg?.message && !this.succMsgDone) {
        this.props.enqueueSnackbar(this.props.addonTypes.succMsg.message, {
          variant: "success",
        });
        this.succMsgDone = true;
      }
    }

    if (
      this.props?.hmskTemplateReducer?.hmskTemplateList &&
      prevProps?.hmskTemplateReducer?.hmskTemplateList !==
        this.props.hmskTemplateReducer.hmskTemplateList &&
      this.props.hmskTemplateReducer.hmskTemplateList.entries
    ) {
      let checkListEntries = [];
      let checkListToProcess = [];

      if (this.props.hmskTemplateReducer.hmskTemplateList.entries.length) {
        checkListEntries =
          this.props.hmskTemplateReducer.hmskTemplateList.entries;
      }

      for (let i = 0; i < checkListEntries.length; i++) {
        if (checkListEntries[i].all_project === true) {
          this.state.checkListProjectIds.forEach((projectId) => {
            const matchedProject = this.state.checkListProjectNames.find(
              (node) => node.id === projectId
            );

            checkListToProcess.push({
              project_id: projectId,
              project_name: matchedProject ? matchedProject.name : "",
              template_id: checkListEntries[i].id,
            });
          });
        } else if (
          checkListEntries[i].assigned_projects &&
          checkListEntries[i].assigned_projects.length
        ) {
          const foundProject = checkListEntries[i].assigned_projects.find(
            (project) => {
              return (
                this.state.checkListProjectIds.includes(project.id) === true
              );
            }
          );

          if (foundProject) {
            checkListToProcess.push({
              project_id: foundProject.id,
              project_name: foundProject.name,
              template_id: checkListEntries[i].id,
            });
          }
        } else if (
          checkListEntries[i].assigned_tasks &&
          checkListEntries[i].assigned_tasks.length
        ) {
          const foundTask = checkListEntries[i].assigned_tasks.find((task) => {
            return this.state.checkListTaskIds.includes(task.id) === true;
          });

          if (foundTask) {
            checkListToProcess.push({
              task_id: foundTask.id,
              task_name: foundTask.name,
              project_id: foundTask.project_id,
              project_name: foundTask.project_name,
              template_id: checkListEntries[i].id,
            });
          }
        }
      }

      this.setState(
        {
          checkList: checkListToProcess,
        },
        () => {
          this.processCheckList();
        }
      );
    }

    if (
      this.state.checkCheckListNeedToFillStatus &&
      this.props?.hmskCheckListReducer?.hmskCheckListFillStatus &&
      prevProps?.hmskCheckListReducer?.hmskCheckListFillStatus !==
        this.props.hmskCheckListReducer.hmskCheckListFillStatus &&
      this.props.hmskCheckListReducer.hmskCheckListFillStatus.projects
    ) {
      const projectStatus =
        this.props.hmskCheckListReducer.hmskCheckListFillStatus.projects;
      const taskStatus =
        this.props.hmskCheckListReducer.hmskCheckListFillStatus.tasks;
      let needToFill = false;
      let checkListProjectIds = this.state.checkListProjectIds;
      let checkListTaskIds = this.state.checkListTaskIds;

      if (projectStatus && projectStatus.length) {
        projectStatus.forEach((project) => {
          if (checkListProjectIds.includes(project.id)) {
            if (project.need === true) {
              needToFill = true;
            } else {
              checkListProjectIds = checkListProjectIds.filter(
                (e) => e !== project.id
              );
            }
          }
        });
      }

      if (taskStatus && taskStatus.length) {
        taskStatus.forEach((task) => {
          if (checkListTaskIds.includes(task.id)) {
            if (task.need === true) {
              needToFill = true;
            } else {
              checkListTaskIds = checkListTaskIds.filter((e) => e !== task.id);
            }
          }
        });
      }

      this.setState({
        checkCheckListNeedToFillStatus: false,
        ignoreCheckListStep: !needToFill,
        checkListProjectIds: checkListProjectIds,
        checkListTaskIds: checkListTaskIds,
      });
    }

    if (
      this.props.planningReducer.planList &&
      prevProps.planningReducer.planList !==
        this.props.planningReducer.planList &&
      this.props.planningReducer.planList.entries
    ) {
      let plans = [];
      if (this.props.planningReducer.planList.entries[this.state.loginUserId]) {
        plans =
          this.props.planningReducer.planList.entries[this.state.loginUserId];
      }

      this.setState({
        plannedHours: plans,
      });
    }

    if (
      this.props.planningReducer.planData &&
      prevProps.planningReducer.planData !== this.props.planningReducer.planData
    ) {
      const plan = this.props.planningReducer.planData;
      if (plan.end_time && this.state.checkInData) {
        this.setState({
          checkInData: {
            ...this.state.checkInData,
            plan_end_time: plan.end_time,
          },
        });
      }
    }

    if (
      this.state.checkCustomerList &&
      this.props?.customerReducer?.customerList &&
      prevProps?.customerReducer?.customerList !==
        this.props.customerReducer.customerList
    ) {
      this.setState({
        checkCustomerList: false,
        checkCustomerOptions: true,
      });
    }

    if (
      this.state.checkUserList &&
      this.props?.profileReducer?.userList &&
      prevProps.profileReducer.userList !== this.props.profileReducer.userList
    ) {
      this.setState({
        checkUserList: false,
        checkUserOptions: true,
      });
    }

    if (
      this.state.isCompanySettingsDone !== prevStates.isCompanySettingsDone ||
      this.state.isHoursDataRefreshed !== prevStates.isHoursDataRefreshed
    ) {
      if (
        this.state.isCompanySettingsDone &&
        this.state.isHoursDataRefreshed &&
        this.state.processCheckout
      ) {
        this.setState({
          processCheckout: false,
        });

        this.handleCheckOutButtonClick();
      }
    }
  };

  getSelectedUserData = () => {
    let entries = this.props.profileReducer.userData;
    let currentUser;

    entries &&
      (currentUser = {
        value: entries && entries.id ? entries.id.toString() : "",
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={
                entries.avatar_url ? `${baseUrl}${entries.avatar_url}` : null
              }
              style={{
                fontSize: 14,
                width: 32,
                height: 32,
                fontWeight: 700,
              }}
            >
              {getInitials(entries.name)}
            </Avatar>
            <Typography
              style={{
                marginLeft: 8,
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {entries.name}
            </Typography>
          </div>
        ),
      });

    this.setState({
      selectedUser: currentUser,
    });
  };

  setCompanySettingAccess = (setting, stateKey) => {
    if (
      setting &&
      setting.default_user_value === "FALSE" &&
      setting.default_admin_value === "FALSE"
    ) {
      if (
        setting.company_settings_value &&
        setting.company_settings_value.values &&
        setting.company_settings_value.values.user_ids
      ) {
        let user_ids = setting.company_settings_value.values.user_ids;
        if (Array.isArray(user_ids) && user_ids.length > 0) {
          if (user_ids.indexOf(this.state.loginUserId) !== -1) {
            this.setState({ [stateKey]: true });
          }
        }
      }
    } else {
      if (
        setting &&
        setting.default_user_value === "TRUE" &&
        getItem("userRole") !== "admin"
      ) {
        this.setState({ [stateKey]: true });
      }
      if (
        setting &&
        setting.default_admin_value === "TRUE" &&
        getItem("userRole") === "admin"
      ) {
        this.setState({ [stateKey]: true });
      }
    }
  };

  resetFilter = async () => {
    await this.props.actions.filterData("hours", null);
    this.setState({
      userId: null,
    });
  };

  getHours = (offset) => {
    const { startDate, endDate } = this.props;

    let params = {
      limit: this.state.limit,
      offset: offset ? offset : 0,
      week: moment(startDate).day(1).isoWeek(), // add 1 day to remove first day (Monday/Sunday) issue
      month: moment(startDate).day(1).month() + 1, // 0 indexed
      year: moment(startDate).day(1).isoWeekYear(),
    };

    if (getItem("userRole") === "admin" && this.state.userId) {
      params["user_id"] = this.state.userId;
    }

    this.props.actions.getHours(params);

    const userId =
      getItem("userRole") === "admin" && this.state.userId
        ? this.state.userId
        : parseInt(getItem("userId"), 10);
    if (startDate && endDate && userId) {
      this.props.actions.getHourTypesSummary({
        id: userId,
        start_time: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        end_time: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      });
    }
    this.getHoursSummary();
  };

  getAddons = (offset) => {
    const { startDate, endDate } = this.props;
    let params = {
      limit: this.state.limit,
      offset: offset ? offset : 0,
      week: moment(startDate).day(1).isoWeek(), // add 1 day to remove first day (Monday/Sunday) issue
      month: moment(startDate).day(1).month() + 1, // 0 indexed
      year: moment(startDate).day(1).isoWeekYear(),
    };

    if (getItem("userRole") === "admin" && this.state.userId) {
      params["user_id"] = this.state.userId;
    }

    this.props.actions.getAddons(params);

    const userId =
      getItem("userRole") === "admin" && this.state.userId
        ? this.state.userId
        : parseInt(getItem("userId"), 10);
    if (startDate && endDate && userId) {
      this.props.actions.getAddonTypesSummary({
        id: userId,
        start_time: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        end_time: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      });
    }
    this.getAddonsSummary();
  };

  getFixedAddons = (offset) => {
    const { startDate, endDate } = this.props;
    let params = {
      limit: this.state.limit,
      offset: offset ? offset : 0,
      week: moment(startDate).day(1).isoWeek(), // add 1 day to remove first day (Monday/Sunday) issue
      month: moment(startDate).day(1).month() + 1, // 0 indexed
      year: moment(startDate).day(1).isoWeekYear(),
    };

    if (getItem("userRole") === "admin" && this.state.userId) {
      params["user_id"] = this.state.userId;
    }

    this.props.actions.getFixedAddons(params);

    const userId =
      getItem("userRole") === "admin" && this.state.userId
        ? this.state.userId
        : parseInt(getItem("userId"), 10);
    if (startDate && endDate && userId) {
      this.props.actions.getFixedAddonTypesSummary({
        id: userId,
        start_time: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        end_time: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      });
    }
  };

  loadUserList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    let limit = 20;
    let queryString = `more_details=false&limit=${limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const result = await this.getUserListOptions(queryString);
    return result;
  };

  getUserListOptions = (queryString) => {
    const promise = new Promise((resolve) => {
      this.props.actions.getUsers(queryString);
      this.setState({
        checkUserList: true,
      });
      let counter = 0;

      const timerId = setInterval(() => {
        counter++;
        if (counter > 9) {
          this.setState({
            checkUserList: false,
            checkUserOptions: true,
          });
        }

        if (this.state.checkUserOptions) {
          this.setState({
            checkUserOptions: false,
          });

          clearInterval(timerId);

          if (
            this.props.profileReducer.userList &&
            this.props.profileReducer.userList.entries
          ) {
            let entries = this.props.profileReducer.userList.entries;
            let userList = [];
            for (let i = 0; i < entries.length; i++) {
              let element = entries[i];
              userList.push({
                value: element.id.toString(),
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={
                        element.avatar_url
                          ? `${baseUrl}${element.avatar_url}`
                          : null
                      }
                      style={{
                        fontSize: 14,
                        width: 32,
                        height: 32,
                        fontWeight: 700,
                      }}
                    >
                      {getInitials(element.name)}
                    </Avatar>
                    <Typography
                      style={{
                        marginLeft: 8,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {element.name}
                    </Typography>
                  </div>
                ),
              });
            }
            this.setState({
              userList: userList,
            });

            resolve({
              options: userList,
              hasMore:
                this.props.profileReducer.userList.pagination.has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 600);
    });

    return promise;
  };

  loadMoreHours = (offset) => {
    this.getHours(offset);
  };

  getHoursSummary = () => {
    const { startDate } = this.props;

    // for Admin, check if user id based filter is applied
    const userId =
      getItem("userRole") === "admin" && this.state.userId
        ? this.state.userId
        : parseInt(getItem("userId"), 10);
    if (userId) {
      let params = {
        id: userId,
        week: moment(startDate).day(1).isoWeek(), // add 1 day to remove first day (Monday/Sunday) issue
        month: moment(startDate).day(1).month() + 1, // 0 indexed
        year: moment(startDate).day(1).isoWeekYear(),
      };

      this.props.actions.getHourSummary(params);
    }
  };

  getAddonsSummary = () => {
    const { startDate } = this.props;

    // for Admin, check if user id based filter is applied
    const userId =
      getItem("userRole") === "admin" && this.state.userId
        ? this.state.userId
        : parseInt(getItem("userId"), 10);
    if (userId) {
      let params = {
        id: userId,
        week: moment(startDate).day(1).isoWeek(), // add 1 day to remove first day (Monday/Sunday) issue
        month: moment(startDate).day(1).month() + 1, // 0 indexed
        year: moment(startDate).day(1).isoWeekYear(),
      };

      this.props.actions.getAddonSummary(params);
    }
  };

  refreshHoursData = (batchGroupId = null) => {
    this.setState({
      checkNewData: 1,
      hourlist: [],
      isHoursDataRefreshed: false,
    });
    this.getHours();
    if (this.state.addonModuleAssigned) {
      this.setState({ addonObjlist: [], fixedAddonObjlist: [] });
      this.getAddons(); // in case the hour also create addon based on the Rules
      this.getFixedAddons();

      if (this.state.isQuickHoursSubmit) {
        this.setState({
          refreshHourModalAfterApi: "addon",
        });

        if (batchGroupId) {
          this.setState({
            groupId: this.state.groupId ? this.state.groupId : batchGroupId,
          });
        }
      }
    } else if (this.state.isQuickHoursSubmit) {
      this.setState({
        refreshHourModalAfterApi: "hour",
      });

      if (batchGroupId) {
        this.setState({
          groupId: this.state.groupId ? this.state.groupId : batchGroupId,
        });
      }
    }
  };

  handleCheckoutCancel = () => {
    this.setState({
      openCheckoutModal: false,
      lateComment: "",
      isLateCheckout: false,
      isTaskFinished: false,
      lunchStartTime: "00:00",
      lunchEndTime: "00:00",
      isLunchTimeInvalid: false,
    });
  };

  handleMultiProjectModalCancel = () => {
    this.setState({
      openDeleteModal: false,
      openMultiProjectModal: false,
      groupId: null,
      repeat_Dates: [],
      multiProjectTasks: [],
      multiProjectAddonTasks: [],
      fixedAddonTasks: [],
      selectedType: false,
      isTypeSelected: false,
      multiTaskFinish: [],
      hourNodeRemoved: [],
      addonNodeRemoved: [],
      fixedAddonNodeRemoved: [],
      showPreviewRulesOutcome: false,
      multiProjectTasksPreview: [],
      multiProjectAddonTasksPreview: [],
      fixedAddonTasksPreview: [],
      multipleProjectAllHours: [],
      multipleProjectAllAddons: [],
      lunchStartTime: "00:00",
      lunchEndTime: "00:00",
      previewError: "",
      GroupStartTime: "00:00",
      GroupEndTime: "00:00",
      GroupLunchStartTime: "00:00",
      GroupLunchEndTime: "00:00",
      isQuickHoursSubmit: false,
      hourModalDataChanged: false,
      repeatDateCallPending: false,
      allowRulesToManualEntry: false,
      allowManualToRulesEntry: false,
      isRulesBasedHourEntry: false,
      mergeOverlapHours: false,
      removeOverlapHours: false,
      showCheckoutOverlapOption: false,
      dayNumber: null,
      isValidatedGeofenceEntry: false,
      comment: "",
      isSickLeaveHours: false,
      isLunchTimeInvalid: false,
    });

    this.handleCheckoutCancel();
  };

  handleCancelPreview = () => {
    this.setState({
      showPreviewRulesOutcome: false,
      multiProjectTasksPreview: [],
      multiProjectAddonTasksPreview: [],
      fixedAddonTasksPreview: [],
      previewError: "",
    });
  };

  closeCheckInModal = () => {
    this.setState({
      openCheckInModal: false,
      selectedCustomerOption: null,
    });
  };

  handleCheckInModalCancel = () => {
    this.closeCheckInModal();

    if (this.state.isCheckInForContinuesFlow) {
      this.setState({
        isCheckInForContinuesFlow: false,
      });
    }
  };

  handleDeleteCancel = () => {
    this.setState({
      openDeleteModal: false,
    });
  };

  handleDeleteSubmit = async () => {
    await this.props.actions.deleteBatchGroup(this.state.deleteId);
    this.setState({ deleteId: null, checkMultiProjectError: true });
    await this.checkFilter();
    this.handleMultiProjectModalCancel();
  };

  deleteModalOpen = (id) => {
    this.setState({ openDeleteModal: true, deleteId: id });
  };

  resetMultiProjectState = () => {
    this.setState({
      selectedProject: null,
      selectedProjectOption: null,
      selectedTask: null,
      selectedHourType: null,
      selectedAddonType: null,
      durationType: "CheckIn",
      taskType: "day",
      amount: "",
      hours: "",
      minutes: "",
      startTime: "00:00",
      endTime: "00:00",
    });
  };

  // checkout call without showing Hour modal,
  handleContinuesCheckInCheckout = () => {
    this.resetMultiProjectState();

    const checkInData = this.state.checkInData;

    if (checkInData) {
      const startTime = moment(checkInData.start_time).utc();

      // always consider current week start Date instead of this.props.startDate. In case checkout call from Header button, the date range may be of different week
      const weekStartDate = moment().clone().startOf("week");

      let dayNumber = moment(
        moment(startTime).format("YYYY-MM-DDTHH:mm:ss.SSS")
      ).diff(weekStartDate, "days");

      if (isWeekStartWithMonday()) {
        dayNumber++;
      }

      if (checkInData["batch_group_id"]) {
        this.prepareMultipleProjectEdit(
          checkInData["batch_group_id"],
          dayNumber,
          true,
          true
        );
      }
    }
  };

  handleCheckOutDialogSubmit = () => {
    // for Tablet, check if Hour modal need to skip
    const isSkipHourModal =
      localStorage.getItem("skip_hour_modal") &&
      localStorage.getItem("skip_hour_modal") !== "true"
        ? false
        : true;

    this.resetMultiProjectState();

    this.setState({
      comment: this.state.lateComment ? this.state.lateComment : "",
      openCheckoutModal: false,
    });

    const data = {};
    const checkInData = this.state.checkInData;
    if (checkInData) {
      data.start_time = moment(checkInData.start_time).utc();
      data.end_time = moment().format("YYYY-MM-DDTHH:mm:ss.SSS"); // current time

      // always consider current week start Date instead of this.props.startDate. In case checkout call from Header button, the date range may be of different week
      const weekStartDate = moment().clone().startOf("week");

      let dayNumber = moment(
        moment(data.start_time).format("YYYY-MM-DDTHH:mm:ss.SSS")
      ).diff(weekStartDate, "days");

      if (isWeekStartWithMonday()) {
        dayNumber++;
      }

      if (checkInData["batch_group_id"]) {
        this.prepareMultipleProjectEdit(
          checkInData["batch_group_id"],
          dayNumber,
          true
        );
      } else {
        // check: as per new checkin flow, this section will not occure as batch_group_id will be present for 1st check-in call as well

        console.log("Got checkout without batch_group_id");

        /*const hours = [];
        let hourNode = {};

        hourNode = {
          project_id: checkInData.project_id,
          project_name: checkInData.project_name,
          task_id: checkInData.task_id,
          task_name: checkInData.task_name,
          task_finish: this.state.isTaskFinished ? true : false,
        };

        hourNode["start_time"] = moment
          .duration(moment(data.start_time).format("HH:mm"))
          .asMinutes();
        hourNode["end_time"] = moment
          .duration(moment(data.end_time).format("HH:mm"))
          .asMinutes();
        if (hourNode["start_time"] > hourNode["end_time"]) {
          hourNode["end_time"] = hourNode["end_time"] + 1440;
        }

        if (checkInData["hour_type_id"]) {
          hourNode["type_id"] = checkInData["hour_type_id"];
          hourNode["type_name"] = checkInData["hour_type_name"];
        }

        hourNode["check_in_out_id"] = checkInData["id"];

        hours.push(hourNode);

        if (data) {
          this.setState({
            durationType: "CheckIn",
            startTime: moment(data.start_time).format("HH:mm"),
            endTime: moment(data.end_time).format("HH:mm"),
            multiProjectTasks: hours,
            dayNumber: parseInt(dayNumber, 10),
          });
        }*/
      }

      this.setState({
        checkInBatchGroupId: checkInData["batch_group_id"]
          ? checkInData["batch_group_id"]
          : null,
        isCheckInEntry: true,
      });

      if (!isSkipHourModal) {
        this.setState({
          openMultiProjectModal: true,
          title: i18next.t("Continuous hours for multiple projects"),
        });
      } else {
        // skip showing the hour modal

        // wait for dayNumber to set
        this.setState(
          {
            dayNumber: parseInt(dayNumber, 10),
          },
          async () => {
            // get autofill lunch time if applicable

            await this.checkForAutofilLunchTime();

            // call the hour modal submit action
            this.handleCheckoutHourModalSubmit();
          }
        );
      }
    }
  };

  checkForAutofilLunchTime = () => {
    const {
      isAutoFillLunch,
      autoFillLunchAmount,
      startTime,
      endTime,
      adminLunchTime,
      userLunchTime,
    } = this.state;

    const promise = new Promise((resolve) => {
      let showLunchTime = false;
      // autofill lunch input present
      if (isAutoFillLunch) {
        const standardLunchTime =
          getItem("userRole") === "admin" ? adminLunchTime : userLunchTime;

        if (autoFillLunchAmount > 0 && standardLunchTime > 0) {
          const checkInStart = moment(startTime, "HH:mm");
          const timeDeference = moment(endTime, "HH:mm").diff(checkInStart);
          const amount = moment.duration(timeDeference).asHours();
          const totalLunchTimeAmount =
            parseFloat(autoFillLunchAmount) + parseFloat(standardLunchTime);

          if (Math.abs(amount) > totalLunchTimeAmount) {
            const tempStartTime = moment(checkInStart).add(
              autoFillLunchAmount,
              "hours"
            );
            const tempEndTime = moment(checkInStart).add(
              totalLunchTimeAmount,
              "hours"
            );
            this.setState({
              lunchStartTime: moment(tempStartTime).format("HH:mm"),
              lunchEndTime: moment(tempEndTime).format("HH:mm"),
            });

            showLunchTime = true;
          }
        }
      } else {
        // group lunch time logic
        let lunchStartTime = null;
        let lunchEndTime = null;
        let groupLunchByDay = null;

        if (this.state.isCheckInEntry) {
          groupLunchByDay = this.state.loginUserGroupLunchByDay;
        } else {
          groupLunchByDay = this.state.selectedUserGroupLunchByDay;
        }

        if (groupLunchByDay) {
          var enLocale = moment(this.props.startDate)
            .clone()
            .day(this.state.dayNumber);
          enLocale.locale("en");
          const weekDay = enLocale.format("dddd").toLowerCase();
          if (groupLunchByDay[weekDay]) {
            const dayObj = groupLunchByDay[weekDay];
            lunchStartTime = dayObj.lunch_start_time;
            lunchEndTime = dayObj.lunch_end_time;
          }
        }

        if (lunchStartTime && lunchEndTime) {
          const tempStartTime = moment(lunchStartTime, "HH:mm");
          const tempEndTime = moment(lunchEndTime, "HH:mm");
          const checkInStart = moment(startTime, "HH:mm");
          const checkInEnd = moment(endTime, "HH:mm");

          if (
            moment(tempStartTime).isAfter(checkInStart) &&
            moment(tempEndTime).isBefore(checkInEnd)
          ) {
            this.setState({
              lunchStartTime: lunchStartTime,
              lunchEndTime: lunchEndTime,
            });

            showLunchTime = true;
          }
        }
      }

      resolve(showLunchTime);
    });

    return promise;
  };

  handleEditOutsideRules = (isTicked) => {
    this.setState(
      {
        allowRulesToManualEntry: isTicked,
        allowManualToRulesEntry: !isTicked,
      },
      () => {
        this.prepareMultipleProjectEdit(
          this.state.groupId,
          this.state.dayNumber
        );
      }
    );
  };

  multiProjectModalOpen = (dayNumber, groupId) => {
    this.resetMultiProjectState();

    this.setState({
      comment: "",
      lunchStartTime: "00:00",
      lunchEndTime: "00:00",
      isLunchTimeInvalid: false,
      selectedProject: this.state.selectedUserProject,
      selectedProjectOption: this.state.selectedUserProjectOption,
      selectedTask: this.state.selectedUserTask,
    });

    if (groupId) {
      this.prepareMultipleProjectEdit(groupId, dayNumber);
    } else {
      this.setState({
        dayNumber: parseInt(dayNumber, 10),
        startTime: this.state.selectedUserStartTime,
        endTime: this.state.selectedUserEndTime,
      });
    }

    this.setState({
      openMultiProjectModal: true,
      title: groupId
        ? i18next.t("Update multiple project hours")
        : i18next.t("Add multiple project hours"),
      isCheckInEntry: false,
    });
  };

  prepareMultipleProjectEdit = (
    groupId,
    dayNumber,
    isCheckout = false,
    isSubmit = false
  ) => {
    const {
      isManualEntriesOnEdit,
      allowRulesToManualEntry,
      allowManualToRulesEntry,
    } = this.state;

    if (groupId) {
      const data = {};
      let hours = [];
      const allHours = [];
      const addons = [];
      const allAddons = [];
      const fixedAddons = [];
      let hour = {};
      let hourNode = {};
      let addonNode = {};
      let addon = {};
      let batchIds = [];
      let batchIdTime = {};
      let isRulesBasedHourEntry = false;
      let isValidatedGeofenceEntry = false;
      let isSickLeaveHours = false;

      for (let key in this.state.hourlist.tasks) {
        if (
          Object.prototype.hasOwnProperty.call(this.state.hourlist.tasks, key)
        ) {
          hour = this.state.hourlist.tasks[key];
          if (hour.batch_group_id && hour.batch_group_id === groupId) {
            if (isCheckout && !hour.check_in_out_id) {
              continue;
            }

            // ignore hour with 0 amount
            if (!isCheckout && hour.amount === 0) {
              continue;
            }

            // for first hour
            if (!data.start_time) {
              if (isCheckout && hour.check_in_out_start_time) {
                data.start_time = hour.check_in_out_start_time;
              } else {
                data.start_time = hour.batch_group_start_time
                  ? hour.batch_group_start_time
                  : hour.start_time;
              }

              data.end_time = hour.batch_group_end_time
                ? hour.batch_group_end_time
                : hour.end_time;
              data.comment = hour.comment;
              if (hour.batch_group_lunch_start_time) {
                data.lunch_start_time = hour.batch_group_lunch_start_time
                  ? hour.batch_group_lunch_start_time
                  : null;
                data.lunch_end_time = hour.batch_group_lunch_end_time
                  ? hour.batch_group_lunch_end_time
                  : null;
              }
            }

            // all individual hours for Optinal Hour Type tab
            allHours.push({
              id: hour.id,
              project_id: hour.project_id,
              project_name: hour.project_name,
              task_id: hour.task_id,
              task_name: hour.task_name,
              type_id: hour.hour_type_id,
              type_name: hour.hour_type_name,
              start_time: moment
                .duration(moment(hour.start_time).utc().format("HH:mm:ss"))
                .asMinutes(),
              end_time: moment
                .duration(moment(hour.end_time).utc().format("HH:mm:ss"))
                .asMinutes(),
              old_type_id: hour.hour_type_id, // to store parent hour_type_id
            });

            hourNode = {
              id: hour.id,
              project_id: hour.project_id,
              project_name: hour.project_name,
              task_id: hour.task_id,
              task_name: hour.task_name,
            };

            // if Checkout modal, add validated_geofence parameter, can't edit the hour slider if TRUE
            if (isCheckout) {
              hourNode["validated_geofence"] = hour?.validated_geofence;
              if (hour?.validated_geofence) {
                isValidatedGeofenceEntry = true;
              }
            }

            // in case of checkout, we need to consider check_in_out start/end time as per backend requirement
            const hourStartTime =
              isCheckout && hour.check_in_out_id && hour.check_in_out_start_time
                ? hour.check_in_out_start_time
                : hour.start_time;
            const hourEndTime =
              isCheckout && hour.check_in_out_id && hour.check_in_out_end_time
                ? hour.check_in_out_end_time
                : hour.end_time;
            const checkInOutId =
              isCheckout && hour.check_in_out_id ? hour.check_in_out_id : null;

            // ignore hour type if batch_id found during checkout
            if (hour.batch_id && isCheckout) {
              hourNode["start_time"] = moment
                .duration(moment(hourStartTime).utc().format("HH:mm:ss"))
                .asMinutes();
              hourNode["end_time"] = moment
                .duration(moment(hourEndTime).utc().format("HH:mm:ss"))
                .asMinutes();

              // as per checkin process requirement
              if (checkInOutId) {
                hourNode["check_in_out_id"] = checkInOutId;
              }
            }
            // If single batch has multiple hours, prepare only one hour and start_time and end_time will be min/max of all the batch hours
            else if (
              hour.batch_id &&
              !isManualEntriesOnEdit &&
              !allowRulesToManualEntry
            ) {
              // if batch id not found, use as it is. If check-in entry, use as it is
              if (batchIds.indexOf(hour.batch_id) === -1) {
                hourNode["start_time"] = moment
                  .duration(moment(hourStartTime).utc().format("HH:mm:ss"))
                  .asMinutes();
                hourNode["end_time"] = moment
                  .duration(moment(hourEndTime).utc().format("HH:mm:ss"))
                  .asMinutes();
              } else {
                // compare min/max time with remaining batch hours of same batch_id
                if (
                  moment(batchIdTime[hour.batch_id].start_time).isAfter(
                    hourStartTime
                  )
                ) {
                  hours[batchIdTime[hour.batch_id].index]["start_time"] = moment
                    .duration(moment(hourStartTime).utc().format("HH:mm:ss"))
                    .asMinutes();
                }
                if (
                  moment(hourEndTime).isAfter(
                    batchIdTime[hour.batch_id].end_time
                  )
                ) {
                  hours[batchIdTime[hour.batch_id].index]["end_time"] = moment
                    .duration(moment(hourEndTime).utc().format("HH:mm:ss"))
                    .asMinutes();
                }

                continue;
              }
            } else {
              if (
                isManualEntriesOnEdit ||
                (!isManualEntriesOnEdit && !allowManualToRulesEntry)
              ) {
                // no batch id, consider full hour as manual entry
                hourNode["start_time"] = moment
                  .duration(moment(hourStartTime).utc().format("HH:mm:ss"))
                  .asMinutes();
                hourNode["end_time"] = moment
                  .duration(moment(hourEndTime).utc().format("HH:mm:ss"))
                  .asMinutes();
                hourNode["type_id"] = hour.hour_type_id;
                hourNode["type_name"] = hour.hour_type_name;

                // as per continuous checkin process requirement
                if (checkInOutId) {
                  hourNode["check_in_out_id"] = checkInOutId;
                }
              } else if (!isManualEntriesOnEdit && allowManualToRulesEntry) {
                // Convert manual entries to rule based entry
                if (hours.length) {
                  // compare min/max time with previous hours to convert all in single hour entry
                  for (let i = 0; i < hours.length; i++) {
                    const startTimeMinutes = moment
                      .duration(
                        moment(hour.start_time).utc().format("HH:mm:ss")
                      )
                      .asMinutes();
                    const endTimeMinutes = moment
                      .duration(moment(hour.end_time).utc().format("HH:mm:ss"))
                      .asMinutes();

                    if (hours[i].start_time > startTimeMinutes) {
                      hours[i].start_time = startTimeMinutes;
                    }
                    if (hours[i].end_time < endTimeMinutes) {
                      hours[i].end_time = endTimeMinutes;
                    }
                  }

                  continue;
                } else {
                  // first entry, add as it is, without type
                  hourNode["start_time"] = moment
                    .duration(moment(hour.start_time).utc().format("HH:mm:ss"))
                    .asMinutes();
                  hourNode["end_time"] = moment
                    .duration(moment(hour.end_time).utc().format("HH:mm:ss"))
                    .asMinutes();
                }
              }
            }

            if (hour.batch_id) {
              isRulesBasedHourEntry = true;
            }

            // need to ignore sick leave hours in payload of update action
            if (hour.sick_leave_hour) {
              hourNode["sick_leave_hour"] = true;
              isSickLeaveHours = true;
            }

            hours.push(hourNode);

            // Make unique list of batch_ids, to compare with future hour records
            if (
              hour.batch_id &&
              !isManualEntriesOnEdit &&
              !allowRulesToManualEntry &&
              batchIds.indexOf(hour.batch_id) === -1
            ) {
              batchIds.push(hour.batch_id);
              batchIdTime[hour.batch_id] = {
                index: hours.length - 1,
                start_time: hour.start_time,
                end_time: hour.end_time,
              };
            }
          }
        }
      }

      for (let key in this.state.addonObjlist.tasks) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.state.addonObjlist.tasks,
            key
          )
        ) {
          addon = this.state.addonObjlist.tasks[key];
          // Only those addon which do not have batch_id (auto generated)
          if (addon.batch_group_id && addon.batch_group_id === groupId) {
            // in case on hours in the group
            if (!data.start_time && hours.length === 0) {
              data.start_time = addon.batch_group_start_time
                ? addon.batch_group_start_time
                : addon.start_time;
              data.end_time = addon.batch_group_end_time
                ? addon.batch_group_end_time
                : addon.end_time;
              data.comment = addon.comment;
              if (
                !data.lunch_start_time &&
                addon.batch_group_lunch_start_time
              ) {
                data.lunch_start_time = addon.batch_group_lunch_start_time;
                data.lunch_end_time = addon.batch_group_lunch_end_time;
              }
            }

            addonNode = {
              id: addon.id,
              project_id: addon.project_id,
              project_name: addon.project_name,
              task_id: addon.task_id,
              task_name: addon.task_name,
              start_time: moment
                .duration(moment(addon.start_time).utc().format("HH:mm:ss"))
                .asMinutes(),
              end_time: moment
                .duration(moment(addon.end_time).utc().format("HH:mm:ss"))
                .asMinutes(),
            };

            if (addon.batch_id && isCheckout) {
              addonNode["disabled"] = true;
            } else if (
              addon.batch_id &&
              !isManualEntriesOnEdit &&
              !allowRulesToManualEntry
            ) {
              addonNode["disabled"] = true;
            } else {
              if (
                isManualEntriesOnEdit ||
                (!isManualEntriesOnEdit && !allowManualToRulesEntry)
              ) {
                addonNode["type_id"] = addon.addon_type_id;
                addonNode["type_name"] = addon.addon_type_name;
              } else if (!isManualEntriesOnEdit && allowManualToRulesEntry) {
                // ignore addon for manual to rules based conversion
                addonNode["disabled"] = true;
              }
            }

            if (addon.batch_id) {
              isRulesBasedHourEntry = true;
            }

            addons.push(addonNode);

            // all individual addons for Optinal Addon Type tab
            allAddons.push({
              id: addon.id,
              project_id: addon.project_id,
              project_name: addon.project_name,
              task_id: addon.task_id,
              task_name: addon.task_name,
              type_id: addon.addon_type_id,
              type_name: addon.addon_type_name,
              start_time: moment
                .duration(moment(addon.start_time).utc().format("HH:mm:ss"))
                .asMinutes(),
              end_time: moment
                .duration(moment(addon.end_time).utc().format("HH:mm:ss"))
                .asMinutes(),
              old_type_id: addon.addon_type_id, // to store parent hour_type_id
            });
          }
        }
      }

      for (let key in this.state.fixedAddonObjlist.tasks) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.state.fixedAddonObjlist.tasks,
            key
          )
        ) {
          addon = this.state.fixedAddonObjlist.tasks[key];

          // Only those addon which do not have batch_id (auto generated)
          if (addon.batch_group_id && addon.batch_group_id === groupId) {
            // in case on hours in the group
            if (!data.start_time && hours.length === 0) {
              data.start_time = addon.batch_group_start_time;
              data.end_time = addon.batch_group_end_time;
              if (
                !data.lunch_start_time &&
                addon.batch_group_lunch_start_time
              ) {
                data.lunch_start_time = addon.batch_group_lunch_start_time;
                data.lunch_end_time = addon.batch_group_lunch_end_time;
              }
            }

            addonNode = {
              id: addon.id,
              project_id: addon.project_id,
              project_name: addon.project_name,
              task_id: addon.task_id,
              task_name: addon.task_name,
              type_id: addon.addon_type_id,
              type_name: addon.addon_type_name,
              type_measurement: addon.measurement,
              type_color: addon.color,
              type_value: addon.value,
              start_time: moment
                .duration(moment(addon.start_time).utc().format("HH:mm:ss"))
                .asMinutes(),
              end_time: moment
                .duration(moment(addon.end_time).utc().format("HH:mm:ss"))
                .asMinutes(),
            };

            fixedAddons.push(addonNode);
          }
        }
      }

      // In case of isCheckout, if previous hour deleted after check-in start, there are no existing hours for the groupId
      if (!data.start_time && isCheckout) {
        data.start_time = moment(this.state.checkInData.start_time).utc();
      }

      const startTimeMinutes = moment
        .duration(moment(data.start_time).utc().format("HH:mm"))
        .asMinutes();

      for (let i = 0; i < hours.length; i++) {
        if (hours[i].start_time < startTimeMinutes) {
          hours[i].start_time = hours[i].start_time + 1440;
        }
        if (hours[i].end_time < startTimeMinutes) {
          hours[i].end_time = hours[i].end_time + 1440;
        }
      }

      for (let i = 0; i < addons.length; i++) {
        if (addons[i].start_time < startTimeMinutes) {
          addons[i].start_time = addons[i].start_time + 1440;
        }
        if (addons[i].end_time < startTimeMinutes) {
          addons[i].end_time = addons[i].end_time + 1440;
        }
      }

      // for Optional hour/addon type section
      for (let i = 0; i < allHours.length; i++) {
        if (allHours[i].start_time < startTimeMinutes) {
          allHours[i].start_time = allHours[i].start_time + 1440;
        }
        if (allHours[i].end_time < startTimeMinutes) {
          allHours[i].end_time = allHours[i].end_time + 1440;
        }
      }

      for (let i = 0; i < allAddons.length; i++) {
        if (allAddons[i].start_time < startTimeMinutes) {
          allAddons[i].start_time = allAddons[i].start_time + 1440;
        }
        if (allAddons[i].end_time < startTimeMinutes) {
          allAddons[i].end_time = allAddons[i].end_time + 1440;
        }
      }

      // Add CheckIn Hour data in case of Continuous CheckIn
      if (isCheckout) {
        // remove duplicate check_in_out_id based hours. On checkout, it might possible that, the hours splits into multiple hours based on rules so, each such hour has same check_in_out_id
        const checkInOutIds = [];
        const uniqueHours = [];
        for (let i = 0; i < hours.length; i++) {
          if (checkInOutIds.indexOf(hours[i].check_in_out_id) === -1) {
            uniqueHours.push(hours[i]);
            checkInOutIds.push(hours[i].check_in_out_id);
          }
        }

        hours = uniqueHours;

        const checkoutData = {};
        const checkInData = this.state.checkInData;

        checkoutData.start_time = moment(checkInData.start_time).utc();
        checkoutData.end_time = moment().format("YYYY-MM-DDTHH:mm:ss.SSS"); // current time

        // update the global end_time
        data.end_time = checkoutData.end_time + "Z";

        // add new hour entry
        hourNode = {
          project_id: checkInData.project_id,
          project_name: checkInData.project_name,
          task_id: checkInData.task_id,
          task_name: checkInData.task_name,
          task_finish: this.state.isTaskFinished ? true : false,
        };

        hourNode["start_time"] = moment
          .duration(moment(checkoutData.start_time).format("HH:mm"))
          .asMinutes();
        hourNode["end_time"] = moment
          .duration(moment(checkoutData.end_time).format("HH:mm"))
          .asMinutes();

        // cross day and this check-in entry started on current day
        if (hourNode.start_time < startTimeMinutes) {
          hourNode.start_time = hourNode.start_time + 1440;

          if (hourNode.end_time < startTimeMinutes) {
            hourNode.end_time = hourNode.end_time + 1440;
          }
        }
        //  cross day, check-in entry started on previous day
        else if (hourNode.start_time > hourNode.end_time) {
          hourNode.end_time = hourNode.end_time + 1440;
        }

        if (checkInData["hour_type_id"]) {
          hourNode["type_id"] = checkInData["hour_type_id"];
          hourNode["type_name"] = checkInData["hour_type_name"];
        }

        hourNode["check_in_out_id"] = checkInData?.id;

        hours.push(hourNode);
      }

      // if Edit modal date is not same as start_time date, it is cross day
      let weekStartDate = this.props.startDate;
      if (isCheckout) {
        // The checkout may be called from another week range (from Header button), consider current week as start date
        weekStartDate = moment().clone().startOf("week");
      }

      if (
        moment(weekStartDate).clone().day(dayNumber).format("DD,MM,YY") !==
        moment(data.start_time).utc().format("DD,MM,YY")
      ) {
        dayNumber = parseInt(dayNumber, 10) - 1;
      }

      if (data) {
        const startTime = moment(data.start_time).utc().format("HH:mm");
        const endTime = moment(data.end_time).utc().format("HH:mm");
        const lunchStartTime =
          data.lunch_start_time && data.lunch_start_time.length > 0
            ? data.lunch_start_time
            : this.state.lunchStartTime
              ? this.state.lunchStartTime
              : "00:00";
        const lunchEndTime =
          data.lunch_end_time && data.lunch_end_time.length > 0
            ? data.lunch_end_time
            : this.state.lunchEndTime
              ? this.state.lunchEndTime
              : "00:00";

        this.setState(
          {
            durationType: "CheckIn",
            comment: data.comment
              ? data.comment
              : this.state.lateComment
                ? this.state.lateComment
                : "",
            //amount: data.amount,
            startTime,
            endTime,
            lunchStartTime,
            lunchEndTime,
            groupId: groupId,
            multiProjectTasks: hours,
            multiProjectAddonTasks: addons,
            multipleProjectAllHours: allHours,
            multipleProjectAllAddons: allAddons,
            fixedAddonTasks: fixedAddons,
            GroupStartTime: startTime,
            GroupEndTime: endTime,
            GroupLunchStartTime: lunchStartTime,
            GroupLunchEndTime: lunchEndTime,
            dayNumber: parseInt(dayNumber, 10),
            isValidatedGeofenceEntry,
            isSickLeaveHours,
          },
          () => {
            // post previous checkout hour before each new continouse check-in starts
            if (isSubmit) {
              this.submitCheckoutData();
            }
          }
        );

        if (!isManualEntriesOnEdit) {
          this.setState({
            isRulesBasedHourEntry,
          });
        }
      }
    }
  };

  prepareMultipleProjectPreview = (previewResponse) => {
    const hourObjs = [];
    const addonObjs = [];
    const fixedAddonObjs = [];
    const hours = [];
    const addons = [];
    const fixedAddons = [];
    let hour = {};
    let hourNode = {};
    let addonNode = {};
    let addon = {};

    if (previewResponse?.batches?.length > 0) {
      previewResponse.batches.forEach((batch) => {
        if (batch.hours && batch.hours.length > 0) {
          batch.hours.forEach((hour) => {
            hour.batch_id = true;
            hourObjs.push(hour);
          });
        }
        if (batch.addons && batch.addons.length > 0) {
          batch.addons.forEach((addon) => {
            addon.batch_id = true;
            addonObjs.push(addon);
          });
        }
        if (batch.fixed_addons && batch.fixed_addons.length > 0) {
          batch.fixed_addons.forEach((addon) => {
            addon.batch_id = true;
            fixedAddonObjs.push(addon);
          });
        }
      });
    }

    if (previewResponse?.hours?.length > 0) {
      previewResponse.hours.forEach((hour) => {
        hourObjs.push(hour);
      });
    }

    if (previewResponse?.addons?.length > 0) {
      previewResponse.addons.forEach((addon) => {
        addonObjs.push(addon);
      });
    }

    if (previewResponse?.fixed_addons?.length > 0) {
      previewResponse.fixed_addons.forEach((addon) => {
        fixedAddonObjs.push(addon);
      });
    }

    for (let i = 0; i < hourObjs.length; i++) {
      hour = hourObjs[i];

      if (!hour || !hour.project_id) continue;

      hourNode = {
        project_id: hour.project_id,
        project_name: hour.project_name,
        task_id: hour.task_id,
        task_name: hour.task_name,
      };

      // no batch id, consider full hour
      hourNode["start_time"] = moment
        .duration(moment(hour.start_time).utc().format("HH:mm:ss"))
        .asMinutes();
      hourNode["end_time"] = moment
        .duration(moment(hour.end_time).utc().format("HH:mm:ss"))
        .asMinutes();

      if (hour.lunch_start_time && hour.lunch_end_time) {
        hourNode["lunch_start_time"] = moment
          .duration(hour.lunch_start_time)
          .asMinutes();
        hourNode["lunch_end_time"] = moment
          .duration(hour.lunch_end_time)
          .asMinutes();
      }

      hourNode["type_id"] = hour.hour_type_id;
      hourNode["type_name"] = hour.hour_type_name;
      hours.push(hourNode);
    }

    for (let i = 0; i < addonObjs.length; i++) {
      addon = addonObjs[i];

      if (!addon || !addon.project_id) continue;

      addonNode = {
        project_id: addon.project_id,
        project_name: addon.project_name,
        task_id: addon.task_id,
        task_name: addon.task_name,
        start_time: moment
          .duration(moment(addon.start_time).utc().format("HH:mm:ss"))
          .asMinutes(),
        end_time: moment
          .duration(moment(addon.end_time).utc().format("HH:mm:ss"))
          .asMinutes(),
      };

      if (addon.lunch_start_time && addon.lunch_end_time) {
        addonNode["lunch_start_time"] = moment
          .duration(addon.lunch_start_time)
          .asMinutes();
        addonNode["lunch_end_time"] = moment
          .duration(addon.lunch_end_time)
          .asMinutes();
      }

      addonNode["type_id"] = addon.addon_addon_type_id;
      addonNode["type_name"] = addon.addon_addon_type_name;
      addons.push(addonNode);
    }

    for (let i = 0; i < fixedAddonObjs.length; i++) {
      addon = fixedAddonObjs[i];

      if (!addon || !addon.project_id) continue;

      addonNode = {
        project_id: addon.project_id,
        project_name: addon.project_name,
        task_id: addon.task_id,
        task_name: addon.task_name,
        type_id: addon.addon_fixed_addon_type_id,
        type_name: addon.addon_fixed_addon_type_name,
        type_measurement: addon.addon_measurement_name,
        type_value: addon.measurement_value,
        type_color: addon.color,
      };

      fixedAddons.push(addonNode);
    }

    let previewError = "";

    if (previewResponse?.errors?.length > 0) {
      previewError = previewResponse.errors;
    }

    this.setState({
      multiProjectTasksPreview: hours,
      multiProjectAddonTasksPreview: addons,
      fixedAddonTasksPreview: fixedAddons,
      previewError: previewError,
      showPreviewRulesOutcome: true,
    });
  };

  handleCheckInButtonClick = async () => {
    this.setState({
      checkInBatchGroupId: null,
      isHoursLoaded: false,
    });

    await this.props.actions.getSingleDayHours(moment().format("YYYY-MM-DD"));

    const checkInStatus = await this.isCheckInAllowed();

    if (!checkInStatus.isValid) {
      this.setState({
        openCheckInAlert: true,
        checkInAlertData: {
          startTime: checkInStatus?.startTime,
          endTime: checkInStatus?.endTime,
        },
      });
    } else if (checkInStatus?.isResumeCheckin) {
      this.setState({
        showResumeCheckInDialog: true,
        resumeCheckinData: checkInStatus?.resumeCheckinData,
      });
    } else {
      if (
        this.state.attendaceModuleAssigned &&
        !this.state.checkInForAttendanceDone &&
        !this.state.reportWebAttendance
      ) {
        this.setState({
          openCheckInForAttendanceModal: true,
        });
      } else {
        this.checkInModalOpen();
      }
    }
  };

  checkInModalOpen = () => {
    this.setState({
      plannedHours: [],
    });

    if (this.state.planningModuleAssigned) {
      this.getPlannedHours();
    }

    this.setState({
      selectedProject: this.state.selectedUserProject,
      selectedProjectOption: this.state.selectedUserProjectOption,
      selectedTask: this.state.selectedUserTask,
      selectedCustomerOption: null,
      selectedHourType: null,
      selectedAddonType: null,
      openCheckInModal: true,
    });
  };

  getPlannedHours = async () => {
    if (this.state.loginUserId) {
      const currentDate = moment().format("YYYY-MM-DD");
      const queryString = `group_by=user&status=0&user_ids=${this.state.loginUserId}&start_time=${currentDate}&end_time=${currentDate}`;

      await this.props.actions.getPlans(queryString);
    }
  };

  getMultiProjectData = (selectedDate, isPreview) => {
    const data = {
      comment: this.state.comment,
    };

    if (!this.state.isCheckInEntry || isPreview) {
      data["user_id"] = this.state.isCheckInEntry
        ? this.state.loginUserId
        : getItem("userRole") === "admin" && this.state.userId
          ? parseInt(this.state.userId, 10)
          : parseInt(getItem("userId"), 10);
    }

    // lunch time
    if (this.state.lunchStartTime && this.state.lunchEndTime) {
      if (
        this.state.isLunchTimeInvalid ||
        (this.state.lunchEndTime === "00:00" &&
          this.state.lunchStartTime === "00:00")
      ) {
        data["lunch_start_time"] = "";
        data["lunch_end_time"] = "";
      } else {
        data["lunch_start_time"] = this.state.lunchStartTime;
        data["lunch_end_time"] = this.state.lunchEndTime;
      }
    }

    // geo location
    if (this.state.isGeoLockRequired && this.props.coords) {
      if (this.state.isGeoFenceDisabled) {
        data["tablet"] = true;
      } else {
        data["latitude"] = this.props.coords.latitude;
        data["longitude"] = this.props.coords.longitude;
      }
    } else {
      data["latitude"] = "";
      data["longitude"] = "";
    }

    // in case of check-out API call, pass this parameter in the API
    if (this.state.isCheckInEntry) {
      if (this.state.mergeOverlapHours) {
        data["overlap_action"] = "merge_record";
      } else if (this.state.removeOverlapHours) {
        data["overlap_action"] = "remove_record";
      }
    }

    if (this.state.isCheckInEntry && this.state.checkInBatchGroupId) {
      data["batch_group_id"] = this.state.checkInBatchGroupId;
    } else if (isPreview && this.state.groupId) {
      data["batch_group_id"] = this.state.groupId;
    }

    if (
      this.state.isContinuesCheckIn &&
      this.state.isCheckInEntry &&
      isPreview
    ) {
      data["check_in"] = true;
    }

    data["dates"] = [];

    let currentDateNode = this.getHoursDetailsMultiProject(
      selectedDate,
      isPreview
    );

    data["dates"].push(currentDateNode);

    if (!isPreview && !this.state.groupId) {
      for (let i = 0; i < this.state.repeat_Dates.length; i++) {
        selectedDate = moment(this.state.repeat_Dates[i]);
        currentDateNode = this.getHoursDetailsMultiProject(selectedDate, false);
        data["dates"].push(currentDateNode);
      }
    }

    return data;
  };

  getHoursDetailsMultiProject = (selectedDate, isPreview) => {
    const currentDateNode = {};
    // in case of cross day
    const nextSelectedDate = moment(selectedDate).add(1, "days");

    if (this.state.startTime) {
      const start = this.state.startTime.split(":");
      const startTimeHour = start[0];
      const startTimeMin = start[1];
      currentDateNode["start_time"] =
        selectedDate
          .clone()
          .set({
            hour: startTimeHour,
            minute: startTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    }

    if (this.state.endTime) {
      const end = this.state.endTime.split(":");
      const endTimeHour = end[0];
      const endTimeMin = end[1];
      let endTimeStr = "";

      if (this.state.endTime < this.state.startTime) {
        endTimeStr = nextSelectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      } else {
        endTimeStr = selectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      currentDateNode["end_time"] = endTimeStr + "Z";

      if (
        this.state.isContinuesCheckIn &&
        this.state.isCheckInEntry &&
        !isPreview
      ) {
        this.setState({
          continuesCheckInStartTime: endTimeStr,
        });
      }
    }

    if (
      this.state.multiProjectTasks &&
      this.state.multiProjectTasks.length > 0
    ) {
      let hours = [];
      let startTime, endTime;
      const projectList = this.state.checkListProjectIds;
      const projectNameList = this.state.checkListProjectNames;
      const taskList = this.state.checkListTaskIds;
      const taskNameList = this.state.checkListTaskNames;

      for (let i = 0; i < this.state.multiProjectTasks.length; i++) {
        const hourNode = {};

        if (this.state.isCheckInEntry && !this.state.isPreview) {
          // for checkList Need To Fill feature, prepare list of project ids to match
          if (
            !projectList.includes(this.state.multiProjectTasks[i].project_id)
          ) {
            projectList.push(
              parseInt(this.state.multiProjectTasks[i].project_id)
            );

            projectNameList.push({
              id: parseInt(this.state.multiProjectTasks[i].project_id),
              name: this.state.multiProjectTasks[i].project_name,
            });

            this.setState({
              checkListProjectIds: projectList,
              checkListProjectNames: projectNameList,
            });
          }
          if (!taskList.includes(this.state.multiProjectTasks[i].task_id)) {
            taskList.push(parseInt(this.state.multiProjectTasks[i].task_id));

            taskNameList.push({
              id: parseInt(this.state.multiProjectTasks[i].task_id),
              name: this.state.multiProjectTasks[i].task_name,
            });

            this.setState({
              checkListTaskIds: taskList,
              checkListTaskNames: taskNameList,
            });
          }

          // pass check_in_out_id for backend to work with rounding/autofill at final checkout. If not presnet, ignore the entry
          if (this.state.multiProjectTasks[i]?.check_in_out_id) {
            hourNode["check_in_out_id"] =
              this.state.multiProjectTasks[i].check_in_out_id;
          } else if (!this.state.multiProjectTasks[i].checkin_add) {
            continue;
          }
        }

        // ignore passing sick leave hours in update API call
        if (this.state.multiProjectTasks[i]?.sick_leave_hour) {
          hourNode["sick_leave_hour"] = true;
        }

        startTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectTasks[i].start_time, "minutes")
          .format("HH:mm")
          .split(":");

        if (this.state.multiProjectTasks[i].start_time >= 1440) {
          hourNode["start_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          hourNode["start_time"] =
            selectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        endTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectTasks[i].end_time, "minutes")
          .format("HH:mm")
          .split(":");
        if (this.state.multiProjectTasks[i].end_time >= 1440) {
          hourNode["end_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          hourNode["end_time"] =
            selectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        hourNode["task_id"] = this.state.multiProjectTasks[i].task_id;

        if (this.state.multiProjectTasks[i].type_id) {
          hourNode["hour_type_id"] = this.state.multiProjectTasks[i].type_id;
        }

        if (
          this.state.allowFinishTask &&
          this.state.multiProjectTasks[i].task_finish &&
          !isPreview
        ) {
          let multiTaskFinish = this.state.multiTaskFinish;
          multiTaskFinish.push(this.state.multiProjectTasks[i].task_id);
          this.setState({
            multiTaskFinish: multiTaskFinish,
          });
        }

        if (isPreview) {
          // In case of Add modal, with Preview request, add check_validation in each node, except Continuose Check In case
          if (!this.state.groupId && !this.state.multiProjectTasks[i].id) {
            hourNode["check_validation"] = "true";
          }

          // In case of Edit modal, with Preview request, add check_validation only if new hour/addon added
          if (this.state.groupId && !this.state.multiProjectTasks[i].id) {
            hourNode["check_validation"] = "true";
          }
        }

        // In case of Edit modal (or Continuouse Check In), for any existing manual entry, pass id parameter
        if (
          isPreview &&
          (this.state.groupId || this.state.isCheckInEntry) &&
          this.state.multiProjectTasks[i].type_id
        ) {
          if (this.state.multiProjectTasks[i].id) {
            hourNode["id"] = this.state.multiProjectTasks[i].id;
          }
        }

        hours.push(hourNode);
      }

      // In case of chech-out, some adjustment related etries from beggining are ignored, adjust the main start time (to same as 1st check-in entry)
      if (
        this.state.isContinuesCheckIn &&
        this.state.isCheckInEntry &&
        hours.length > 0
      ) {
        if (currentDateNode.start_time !== hours[0].start_time) {
          currentDateNode.start_time = hours[0].start_time;
        }
      }

      currentDateNode["multiple_hours"] = hours;
    }

    if (
      this.state.multiProjectAddonTasks &&
      this.state.multiProjectAddonTasks.length > 0
    ) {
      let addons = [];
      let startTime, endTime;
      for (let i = 0; i < this.state.multiProjectAddonTasks.length; i++) {
        const addonNode = {};

        // ignore auto generated
        if (
          this.state.multiProjectAddonTasks[i].disabled &&
          this.state.isCheckInEntry
        ) {
          continue;
        } else if (
          this.state.multiProjectAddonTasks[i].disabled &&
          !this.state.isManualEntriesOnEdit &&
          !this.state.allowRulesToManualEntry
        ) {
          continue;
        }

        startTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectAddonTasks[i].start_time, "minutes")
          .format("HH:mm")
          .split(":");

        if (this.state.multiProjectAddonTasks[i].start_time >= 1440) {
          addonNode["start_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          addonNode["start_time"] =
            selectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        endTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectAddonTasks[i].end_time, "minutes")
          .format("HH:mm")
          .split(":");
        if (this.state.multiProjectAddonTasks[i].end_time >= 1440) {
          addonNode["end_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          addonNode["end_time"] =
            selectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        addonNode["task_id"] = this.state.multiProjectAddonTasks[i].task_id;

        if (
          this.state.allowFinishTask &&
          this.state.multiProjectAddonTasks[i].task_finish &&
          !isPreview
        ) {
          let multiTaskFinish = this.state.multiTaskFinish;
          multiTaskFinish.push(this.state.multiProjectAddonTasks[i].task_id);
          this.setState({
            multiTaskFinish: multiTaskFinish,
          });
        }

        if (this.state.multiProjectAddonTasks[i].type_id) {
          addonNode["addon_addon_type_id"] =
            this.state.multiProjectAddonTasks[i].type_id;
        }

        if (isPreview) {
          // In case of Add modal, with Preview request, add check_validation in each node, except Continuose Check In case
          if (!this.state.groupId && !this.state.multiProjectAddonTasks[i].id) {
            addonNode["check_validation"] = "true";
          }

          // In case of Edit modal, with Preview request, add check_validation only if new hour/addon added
          if (this.state.groupId && !this.state.multiProjectAddonTasks[i].id) {
            addonNode["check_validation"] = "true";
          }
        }

        // In case of Edit modal (or Continuouse Check In), for any existing manual entry, pass id parameter
        if (
          isPreview &&
          (this.state.groupId || this.state.isCheckInEntry) &&
          this.state.multiProjectAddonTasks[i].type_id
        ) {
          if (this.state.multiProjectAddonTasks[i].id) {
            addonNode["id"] = this.state.multiProjectAddonTasks[i].id;
          }
        }

        addons.push(addonNode);
      }

      currentDateNode["multiple_addons"] = addons;
    }

    if (this.state.fixedAddonTasks && this.state.fixedAddonTasks.length > 0) {
      let fixedAddons = [];
      for (let i = 0; i < this.state.fixedAddonTasks.length; i++) {
        const addonNode = {};

        addonNode["task_id"] = this.state.fixedAddonTasks[i].task_id;

        if (this.state.fixedAddonTasks[i].type_id) {
          addonNode["addon_fixed_addon_type_id"] =
            this.state.fixedAddonTasks[i].type_id;
        }

        if (this.state.fixedAddonTasks[i].type_value) {
          addonNode["measurement_value"] =
            this.state.fixedAddonTasks[i].type_value;
        }

        // In case of Edit modal (or Continuouse Check In), for any existing manual entry, pass id parameter
        if (
          isPreview &&
          (this.state.groupId || this.state.isCheckInEntry) &&
          this.state.fixedAddonTasks[i].type_id
        ) {
          if (this.state.fixedAddonTasks[i].id) {
            addonNode["id"] = this.state.fixedAddonTasks[i].id;
          }
        }

        fixedAddons.push(addonNode);
      }

      currentDateNode["multiple_fixed_addons"] = fixedAddons;
    }

    return currentDateNode;
  };

  getMultiProjectRepeatDateData = () => {
    const data = {
      comment: this.state.comment,
    };

    data["user_id"] =
      getItem("userRole") === "admin" && this.state.userId
        ? parseInt(this.state.userId, 10)
        : parseInt(getItem("userId"), 10);

    // lunch time
    if (this.state.lunchStartTime && this.state.lunchEndTime) {
      if (
        this.state.isLunchTimeInvalid ||
        (this.state.lunchEndTime === "00:00" &&
          this.state.lunchStartTime === "00:00")
      ) {
        data["lunch_start_time"] = "";
        data["lunch_end_time"] = "";
      } else {
        data["lunch_start_time"] = this.state.lunchStartTime;
        data["lunch_end_time"] = this.state.lunchEndTime;
      }
    }

    // geo location
    if (this.state.isGeoLockRequired && this.props.coords) {
      if (this.state.isGeoFenceDisabled) {
        data["tablet"] = true;
      } else {
        data["latitude"] = this.props.coords.latitude;
        data["longitude"] = this.props.coords.longitude;
      }
    } else {
      data["latitude"] = "";
      data["longitude"] = "";
    }

    data["dates"] = [];

    let selectedDate = null;
    let currentDateNode = null;

    for (let i = 0; i < this.state.repeat_Dates.length; i++) {
      selectedDate = moment(this.state.repeat_Dates[i]);
      currentDateNode = this.getHoursDetailsMultiProject(selectedDate, false);
      data["dates"].push(currentDateNode);
    }

    return data;
  };

  getMultiProjectEditData = (selectedDate, isPreview) => {
    const data = {
      comment: this.state.comment,
    };

    if (!this.state.isCheckInEntry || isPreview) {
      data["user_id"] =
        getItem("userRole") === "admin" && this.state.userId
          ? parseInt(this.state.userId, 10)
          : parseInt(getItem("userId"), 10);
    }

    // lunch time
    if (this.state.lunchStartTime && this.state.lunchEndTime) {
      if (
        this.state.isLunchTimeInvalid ||
        (this.state.lunchEndTime === "00:00" &&
          this.state.lunchStartTime === "00:00")
      ) {
        data["lunch_start_time"] = "";
        data["lunch_end_time"] = "";
      } else {
        data["lunch_start_time"] = this.state.lunchStartTime;
        data["lunch_end_time"] = this.state.lunchEndTime;
      }
    }

    // geo location
    if (this.state.isGeoLockRequired && this.props.coords) {
      if (this.state.isGeoFenceDisabled) {
        data["tablet"] = true;
      } else {
        data["latitude"] = this.props.coords.latitude;
        data["longitude"] = this.props.coords.longitude;
      }
    } else {
      data["latitude"] = "";
      data["longitude"] = "";
    }

    if (this.state.isCheckInEntry) {
      if (this.state.mergeOverlapHours) {
        data["overlap_action"] = "merge_record";
      } else if (this.state.removeOverlapHours) {
        data["overlap_action"] = "remove_record";
      }
    }

    if (this.state.isCheckInEntry && this.state.checkInBatchGroupId) {
      data["batch_group_id"] = this.state.checkInBatchGroupId;
    } else if (isPreview && this.state.groupId) {
      data["batch_group_id"] = this.state.groupId;
    }

    if (
      this.state.isContinuesCheckIn &&
      this.state.isCheckInEntry &&
      isPreview
    ) {
      data["check_in"] = true;
    }

    data["dates"] = [];

    const currentDateNode = {};

    // in case of cross day
    const nextSelectedDate = moment(selectedDate).add(1, "days");

    let startTimeHour = null;
    let startTimeMin = null;
    if (this.state.startTime) {
      const start = this.state.startTime.split(":");
      startTimeHour = start[0];
      startTimeMin = start[1];
      currentDateNode["start_time"] =
        selectedDate
          .clone()
          .set({
            hour: startTimeHour,
            minute: startTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    }

    if (this.state.endTime) {
      const end = this.state.endTime.split(":");
      const endTimeHour = end[0];
      const endTimeMin = end[1];
      let endTimeStr = "";

      if (this.state.endTime < this.state.startTime) {
        endTimeStr = nextSelectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      } else {
        endTimeStr = selectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      currentDateNode["end_time"] = endTimeStr + "Z";

      if (
        this.state.isContinuesCheckIn &&
        this.state.isCheckInEntry &&
        !isPreview
      ) {
        this.setState({
          continuesCheckInStartTime: endTimeStr,
        });
      }
    }

    let hours = [];
    if (
      this.state.multiProjectTasks &&
      this.state.multiProjectTasks.length > 0
    ) {
      let startTime, endTime;
      const projectList = this.state.checkListProjectIds;
      const projectNameList = this.state.checkListProjectNames;
      const taskList = this.state.checkListTaskIds;
      const taskNameList = this.state.checkListTaskNames;

      for (let i = 0; i < this.state.multiProjectTasks.length; i++) {
        const hourNode = {};

        if (this.state.isCheckInEntry) {
          // for checkList Need To Fill feature, prepare list of project ids to match
          if (
            !projectList.includes(this.state.multiProjectTasks[i].project_id)
          ) {
            projectList.push(
              parseInt(this.state.multiProjectTasks[i].project_id)
            );

            projectNameList.push({
              id: parseInt(this.state.multiProjectTasks[i].project_id),
              name: this.state.multiProjectTasks[i].project_name,
            });

            this.setState({
              checkListProjectIds: projectList,
              checkListProjectNames: projectNameList,
            });
          }
          if (!taskList.includes(this.state.multiProjectTasks[i].task_id)) {
            taskList.push(parseInt(this.state.multiProjectTasks[i].task_id));

            taskNameList.push({
              id: parseInt(this.state.multiProjectTasks[i].task_id),
              name: this.state.multiProjectTasks[i].task_name,
            });

            this.setState({
              checkListTaskIds: taskList,
              checkListTaskNames: taskNameList,
            });
          }

          // ignore hour entries where check_in_out_id is not present
          if (this.state.multiProjectTasks[i]?.check_in_out_id) {
            hourNode["check_in_out_id"] =
              this.state.multiProjectTasks[i].check_in_out_id;
          } else if (!this.state.multiProjectTasks[i].checkin_add) {
            continue;
          }
        }

        // ignore passing sick leave hour in the update API call
        if (this.state.multiProjectTasks[i]?.sick_leave_hour) {
          hourNode["sick_leave_hour"] = true;
        }

        if (this.state.multiProjectTasks[i].id) {
          hourNode["id"] = this.state.multiProjectTasks[i].id;
        }

        startTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectTasks[i].start_time, "minutes")
          .format("HH:mm")
          .split(":");

        if (this.state.multiProjectTasks[i].start_time >= 1440) {
          hourNode["start_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          hourNode["start_time"] =
            selectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        endTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectTasks[i].end_time, "minutes")
          .format("HH:mm")
          .split(":");
        if (this.state.multiProjectTasks[i].end_time >= 1440) {
          hourNode["end_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          hourNode["end_time"] =
            selectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        hourNode["task_id"] = this.state.multiProjectTasks[i].task_id;

        if (this.state.multiProjectTasks[i].type_id) {
          hourNode["hour_type_id"] = this.state.multiProjectTasks[i].type_id;
        }

        if (
          this.state.allowFinishTask &&
          this.state.multiProjectTasks[i].task_finish &&
          !isPreview
        ) {
          let multiTaskFinish = this.state.multiTaskFinish;
          multiTaskFinish.push(this.state.multiProjectTasks[i].task_id);
          this.setState({
            multiTaskFinish: multiTaskFinish,
          });
        }

        if (isPreview && !this.state.multiProjectTasks[i].id) {
          hourNode["check_validation"] = "true";
        }

        hours.push(hourNode);
      }
    }

    if (
      this.state.hourNodeRemoved &&
      this.state.hourNodeRemoved.length > 0 &&
      !this.state.isContinuesCheckIn
    ) {
      for (let i = 0; i < this.state.hourNodeRemoved.length; i++) {
        if (this.state.hourNodeRemoved[i].id) {
          const hourNode = {
            id: this.state.hourNodeRemoved[i].id,
            delete: "true",
          };

          hours.push(hourNode);
        }
      }
    }

    // In case of chech-out, some adjustment related etries from beggining are ignored, adjust the main start time (to same as 1st check-in entry)
    if (
      this.state.isContinuesCheckIn &&
      this.state.isCheckInEntry &&
      hours.length > 0
    ) {
      if (currentDateNode.start_time !== hours[0].start_time) {
        currentDateNode.start_time = hours[0].start_time;
      }
    }

    currentDateNode["multiple_hours"] = hours;

    let addons = [];

    if (
      this.state.multiProjectAddonTasks &&
      this.state.multiProjectAddonTasks.length > 0
    ) {
      let startTime, endTime;

      for (let i = 0; i < this.state.multiProjectAddonTasks.length; i++) {
        const addonNode = {};

        // ignore auto generated addons during checkout
        if (
          this.state.multiProjectAddonTasks[i].disabled &&
          this.state.isCheckInEntry
        ) {
          continue;
        }

        if (this.state.multiProjectAddonTasks[i].id) {
          addonNode["id"] = this.state.multiProjectAddonTasks[i].id;
        }

        startTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectAddonTasks[i].start_time, "minutes")
          .format("HH:mm")
          .split(":");

        if (this.state.multiProjectAddonTasks[i].start_time >= 1440) {
          addonNode["start_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          addonNode["start_time"] =
            selectedDate
              .clone()
              .set({
                hour: startTime[0],
                minute: startTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        endTime = moment
          .utc()
          .startOf("day")
          .add(this.state.multiProjectAddonTasks[i].end_time, "minutes")
          .format("HH:mm")
          .split(":");
        if (this.state.multiProjectAddonTasks[i].end_time >= 1440) {
          addonNode["end_time"] =
            nextSelectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          addonNode["end_time"] =
            selectedDate
              .clone()
              .set({
                hour: endTime[0],
                minute: endTime[1],
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        addonNode["task_id"] = this.state.multiProjectAddonTasks[i].task_id;

        if (
          this.state.allowFinishTask &&
          this.state.multiProjectAddonTasks[i].task_finish &&
          !isPreview
        ) {
          let multiTaskFinish = this.state.multiTaskFinish;
          multiTaskFinish.push(this.state.multiProjectAddonTasks[i].task_id);
          this.setState({
            multiTaskFinish: multiTaskFinish,
          });
        }

        if (this.state.multiProjectAddonTasks[i].type_id) {
          addonNode["addon_addon_type_id"] =
            this.state.multiProjectAddonTasks[i].type_id;
        }

        if (isPreview && !this.state.multiProjectAddonTasks[i].id) {
          addonNode["check_validation"] = "true";
        }

        addons.push(addonNode);
      }
    }

    if (this.state.addonNodeRemoved && this.state.addonNodeRemoved.length > 0) {
      for (let i = 0; i < this.state.addonNodeRemoved.length; i++) {
        if (this.state.addonNodeRemoved[i].id) {
          const addonNode = {
            id: this.state.addonNodeRemoved[i].id,
            delete: "true",
          };

          addons.push(addonNode);
        }
      }
    }

    currentDateNode["multiple_addons"] = addons;

    let fixedAddons = [];

    if (this.state.fixedAddonTasks && this.state.fixedAddonTasks.length > 0) {
      for (let i = 0; i < this.state.fixedAddonTasks.length; i++) {
        const addonNode = {};

        if (this.state.fixedAddonTasks[i].id) {
          addonNode["id"] = this.state.fixedAddonTasks[i].id;
        }

        addonNode["task_id"] = this.state.fixedAddonTasks[i].task_id;

        if (this.state.fixedAddonTasks[i].type_id) {
          addonNode["addon_fixed_addon_type_id"] =
            this.state.fixedAddonTasks[i].type_id;
        }

        if (this.state.fixedAddonTasks[i].type_value) {
          addonNode["measurement_value"] =
            this.state.fixedAddonTasks[i].type_value;
        }

        fixedAddons.push(addonNode);
      }
    }

    if (
      this.state.fixedAddonNodeRemoved &&
      this.state.fixedAddonNodeRemoved.length > 0
    ) {
      for (let i = 0; i < this.state.fixedAddonNodeRemoved.length; i++) {
        if (this.state.fixedAddonNodeRemoved[i].id) {
          const addonNode = {
            id: this.state.fixedAddonNodeRemoved[i].id,
            delete: "true",
          };

          fixedAddons.push(addonNode);
        }
      }
    }

    currentDateNode["multiple_fixed_addons"] = fixedAddons;

    data["dates"].push(currentDateNode);

    return data;
  };

  handleCheckInStart = async (plan = null) => {
    let currentTime = null;
    if (this.state.isContinuesCheckIn && this.state.continuesCheckInStartTime) {
      currentTime = this.state.continuesCheckInStartTime;
    } else {
      currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    }

    const data = {
      task_id: parseInt(this.state.selectedTask.value, 10),
      task_name: this.state.selectedTask.label.props
        ? this.state.selectedTask.label.props.primary
        : this.state.selectedTask.label,
      project_id: parseInt(this.state.selectedProjectOption.value, 10),
      project_name: this.state.selectedProjectOption.label.props
        ? this.state.selectedProjectOption.label.props.primary
        : this.state.selectedProjectOption.label,
      user_id: parseInt(getItem("userId"), 10),
      start_time: currentTime + "Z",
      project_color: this.state.selectedProjectOption.color,
    };

    const postObj = {
      date: moment().format("YYYY-MM-DD"),
      start_time: currentTime + "Z",
      task_id: parseInt(this.state.selectedTask.value, 10),
    };

    if (!this.state.rulesModuleAssigned) {
      if (
        this.state.selectedHourType &&
        this.state.selectedHourType.value.length > 0
      ) {
        data["hour_type_id"] = parseInt(this.state.selectedHourType.value, 10);
        data["hour_type_name"] = this.state.selectedHourType.label;

        postObj["hour_type_id"] = parseInt(
          this.state.selectedHourType.value,
          10
        );
      }
    }

    // geo location
    if (this.state.isGeoLockRequired && this.props.coords) {
      postObj["latitude"] = this.props.coords.latitude;
      postObj["longitude"] = this.props.coords.longitude;
    } else {
      postObj["latitude"] = "";
      postObj["longitude"] = "";
    }

    let checkInBatchGroupId = null;
    if (this.state.checkInData?.batch_group_id) {
      checkInBatchGroupId = this.state.checkInData?.batch_group_id;
    } else if (this.state.checkInBatchGroupId) {
      // available in case of Resume check-in flow
      checkInBatchGroupId = this.state.checkInBatchGroupId;
    }

    if (checkInBatchGroupId) {
      data["batch_group_id"] = checkInBatchGroupId;
      postObj["batch_group_id"] = checkInBatchGroupId;
    }

    if (plan && plan.id) {
      data["planning_planned_hour_id"] = plan.id;
      data["plan_end_time"] = plan.end_time;
      postObj["planning_planned_hour_id"] = plan.id;
    }

    // geo location
    if (this.state.isGeoLockRequired && this.props.coords) {
      if (this.state.isGeoFenceDisabled) {
        postObj["tablet"] = true;
      } else {
        postObj["latitude"] = this.props.coords.latitude;
        postObj["longitude"] = this.props.coords.longitude;
      }
    }

    if (this.state.isCheckInForContinuesFlow) {
      this.setState(
        {
          nextCheckInObj: data,
          openCheckInModal: false,
          isCheckInEntry: true,
          checkInBatchGroupId: checkInBatchGroupId,
        },
        () => {
          this.handleContinuesCheckInCheckout();
        }
      );
    } else {
      this.props.actions.createCheckInHour(postObj);

      this.setState({
        checkInData: data,
        showCheckInCard: true,
        allowCheckIn: false,
        checkCheckInError: true,
        checkInBatchGroupId: null,
      });

      // log user attendace
      if (
        this.state.attendaceModuleAssigned &&
        this.state.reportWebAttendance
      ) {
        this.logUserAttendance(currentTime, false, data.task_id);
      }

      this.closeCheckInModal();
    }
  };

  logUserAttendance = async (
    currentTime,
    isCheckInForAttendance,
    taskId = null
  ) => {
    let attendanceTime = currentTime;
    if (!isCheckInForAttendance) {
      attendanceTime = moment(currentTime)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS");
    }

    const attendaceObj = {
      start_time: attendanceTime + "Z",
      attendanceable_id: parseInt(getItem("userId"), 10),
      attendanceable_type: "User",
      is_utc: true,
    };

    // pass task_id if present
    if (taskId) {
      attendaceObj["task_id"] = taskId;
    }

    // geo location
    if (this.state.isGeoFenceForAttendace && this.props.coords) {
      if (this.state.isGeoFenceDisabled) {
        attendaceObj["tablet"] = true;
      } else {
        attendaceObj["latitude"] = this.props.coords.latitude;
        attendaceObj["longitude"] = this.props.coords.longitude;
      }
    }

    await this.props.actions.postUserAttendance(attendaceObj);
    this.setState({
      checkAttendanceError: true,
      markCheckInForAttendance: isCheckInForAttendance ? true : false,
    });
  };

  // on Checkout button click
  handleCheckOutButtonClick = () => {
    if (
      this.state.isContinuesCheckIn &&
      !this.state.removeOverlapHours &&
      !this.state.mergeOverlapHours
    ) {
      this.setState({
        openContinuesCheckInModal: true,
      });
    } else {
      const showPreCheckout = this.isPreCheckoutModalApplicable();

      if (showPreCheckout) {
        // open pre checkout modal
        this.setState({ openCheckoutModal: true });
      } else {
        // open hour checkout modal
        this.handleCheckOutDialogSubmit();
      }
    }
  };

  isPreCheckoutModalApplicable = () => {
    const {
      isRequireCommentEnabled,
      checkInData,
      isLunchTime,
      allowFinishTask,
    } = this.state;

    // in case of Plan checkout, compare plan end time for late comment
    let isLateCheckout = false;
    if (isRequireCommentEnabled && checkInData?.plan_end_time) {
      const planEndTime = moment(checkInData.plan_end_time)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS");

      isLateCheckout = moment().isAfter(planEndTime);
    }

    this.setState({
      isLateCheckout: isLateCheckout,
    });

    // if any of following true, show pre-checkout modal
    return isLateCheckout || isLunchTime || allowFinishTask;
  };

  startCheckoutFlow = () => {
    const showPreCheckout = this.isPreCheckoutModalApplicable();

    if (showPreCheckout) {
      // open pre checkout modal
      this.setState({ openCheckoutModal: true });
    } else {
      // open hour checkout modal
      this.handleCheckOutDialogSubmit();
    }
  };

  processCheckList = () => {
    if (this.state.checkList.length) {
      this.setState({
        openCheckListModal: true,
      });
    } else {
      this.postCheckListSteps();
    }
  };

  postCheckListSteps = () => {
    this.setState({
      openCheckListModal: false,
      checkListProjectIds: [],
      checkListProjectNames: [],
      checkListTaskIds: [],
      checkListTaskNames: [],
    });

    // show attendance checkout modal for final checkout, the checklist shows on final checkout
    if (
      this.state.checkInForAttendanceDone &&
      !this.state.reportWebAttendance
    ) {
      this.setState({
        openCheckOutForAttendanceModal: true,
      });
    } else {
      // auto logout
      this.setState({
        autoLogoutModalOpen: true,
      });
    }
  };

  handleMultiProjectCheckOutDone = (
    success,
    overlap = false,
    checkInOutId = null
  ) => {
    if (success) {
      this.handleMultiProjectModalCancel();

      const nextCheckInObj = this.state.nextCheckInObj;

      if (this.state.isCheckInForContinuesFlow) {
        if (this.state.nextCheckInObj) {
          if (checkInOutId) {
            nextCheckInObj["id"] = checkInOutId;
          }

          this.setState({
            checkInData: nextCheckInObj,
            showCheckInCard: true,
            allowCheckIn: false,
            nextCheckInObj: null,
          });
        } else {
          this.handleHourCheckoutComplete();
        }
      } else {
        this.handleHourCheckoutComplete();
      }

      // Show Checklist and attendance check-out modal only for final checkout in case of Continouse check-in enabled, else for all check-out
      const isFinalCheckout =
        !this.state.isCheckInForContinuesFlow || !nextCheckInObj;

      if (isFinalCheckout) {
        // Checklist step
        if (
          this.state.checkListModuleAssigned &&
          (this.state.checkListProjectIds.length ||
            this.state.checkListTaskIds.length)
        ) {
          if (this.state.isCheckListOncePerProject) {
            if (this.state.ignoreCheckListStep) {
              this.postCheckListSteps();
            } else {
              this.getHmskTemplates(
                this.state.checkListProjectIds,
                this.state.checkListTaskIds
              );
            }
          } else {
            this.getHmskTemplates(
              this.state.checkListProjectIds,
              this.state.checkListTaskIds
            );
          }
        } else {
          // Attendance popup
          if (
            this.state.checkInForAttendanceDone &&
            !this.state.reportWebAttendance
          ) {
            this.setState({
              openCheckOutForAttendanceModal: true,
            });
          } else {
            // auto logout
            this.setState({
              autoLogoutModalOpen: true,
            });
          }
        }
      } else {
        // auto logout
        this.setState({
          autoLogoutModalOpen: true,
        });
      }
    } else {
      this.setState({
        openMultiProjectModal: true,
      });
      if (overlap) {
        this.setState({ showCheckoutOverlapOption: true });
      }
    }
  };

  handleHourCheckoutComplete = () => {
    this.setState({
      checkInData: null,
      showCheckInCard: false,
      allowCheckIn: true,
      groupId: null,
      continuesCheckInStartTime: null,
      isCheckInEntry: false,
      isCheckInForContinuesFlow: false,
      isValidatedGeofenceEntry: false,
      isLateCheckout: false,
    });
  };

  handleCheckInDiscard = async () => {
    if (this.state.checkInData?.id) {
      await this.props.actions.deleteCheckInHour(this.state.checkInData.id);
      this.setState(
        {
          checkCheckInDiscardError: true,
          isCheckInEntry: true,
        },
        () => {
          // report attendance check-out time if applicable
          this.prepareForUpdateAttendanceLog();
        }
      );
    }

    this.setState({
      lateComment: "",
      isLateCheckout: false,
      openCheckoutModal: false,
      checkInData: null,
      showCheckInCard: false,
      allowCheckIn: true,
      continuesCheckInStartTime: null,
      isCheckInEntry: false,
      checkInBatchGroupId: null,
      isCheckInForContinuesFlow: false,
      isValidatedGeofenceEntry: false,
    });

    if (this.state.openMultiProjectModal) {
      this.handleMultiProjectModalCancel();
    }
  };

  handleContinuesCheckIn = () => {
    this.setState({
      openContinuesCheckInModal: false,
      isCheckInForContinuesFlow: true,
    });

    this.checkInModalOpen();
  };

  checkAttendanceLogForCheckInEntry = () => {
    this.props.actions.getLastUserAttendance();

    this.setState({
      checkAttendanceLogStatus: true,
    });
  };

  lookForCheckListCondition = async (projectIds, taskIds) => {
    if (
      this.state.checkListModuleAssigned &&
      this.state.isCheckListOncePerProject &&
      projectIds.length
    ) {
      this.setState({
        checkCheckListNeedToFillStatus: true,
      });

      await this.props.actions.isNeedToFillCheckList(
        this.state.loginUserId,
        projectIds.join(","),
        taskIds.join(",")
      );
    }
  };

  markTaskStatus = (status, task_id) => {
    const putObj = { finish: status };
    this.props.actions.putTaskData(task_id, putObj);
  };

  handleCheckoutHourModalSubmit = () => {
    this.submitCheckoutData();
  };

  submitCheckoutData = async () => {
    const startDate = moment(this.props.startDate).clone();
    const selectedDate = startDate.clone().day(this.state.dayNumber);

    let data = null;
    if (
      !this.state.isManualEntriesOnEdit &&
      !this.state.allowRulesToManualEntry
    ) {
      data = this.getMultiProjectData(selectedDate, false);
    } else {
      data = this.getMultiProjectEditData(selectedDate, false);
    }

    const nextCheckInObj = this.state.nextCheckInObj
      ? this.state.nextCheckInObj
      : null;

    if (nextCheckInObj) {
      let checkInObj = {
        date: moment().format("YYYY-MM-DD"),
        task_id: nextCheckInObj["task_id"],
      };

      // the continuesCheckInStartTime state variable may not updated yet with the last value reset, so use data.dates[0].end_time
      if (data.dates[0] && data.dates[0].end_time) {
        checkInObj["start_time"] = data.dates[0].end_time;
      }

      nextCheckInObj["start_time"] = checkInObj["start_time"];
      this.setState({ nextCheckInObj: nextCheckInObj });

      if (nextCheckInObj["hour_type_id"]) {
        checkInObj["hour_type_id"] = nextCheckInObj["hour_type_id"];
      }

      if (nextCheckInObj["planning_planned_hour_id"]) {
        checkInObj["planning_planned_hour_id"] =
          nextCheckInObj["planning_planned_hour_id"];
      }

      data["new_check_in"] = checkInObj;
    } else {
      this.prepareForUpdateAttendanceLog();

      // look in advance if any checklist need to fill for current project, based on company settings
      await this.lookForCheckListCondition(
        this.state.checkListProjectIds,
        this.state.checkListTaskIds
      );
    }

    await this.props.actions.createCheckoutHour(data);

    this.setState({
      checkCheckoutSubmitError: true,
    });
  };

  handleMultiProjectFormSubmit = async () => {
    let weekStartDate = moment(this.props.startDate).clone();
    if (this.state.isCheckInEntry) {
      // For checkout call, consider current week's start date to prevent issues
      weekStartDate = moment().clone().startOf("week");
    }

    const selectedDate = weekStartDate.clone().day(this.state.dayNumber);

    if (this.state.groupId) {
      let data = null;
      if (
        !this.state.isManualEntriesOnEdit &&
        !this.state.allowRulesToManualEntry
      ) {
        data = this.getMultiProjectData(selectedDate, false);
      } else {
        data = this.getMultiProjectEditData(selectedDate, false);
      }
      await this.updateMultiProjectHours(this.state.groupId, data);
      this.setState({ groupId: null });
    } else {
      const data = this.getMultiProjectData(selectedDate, false);

      await this.createMultiProjectHours(data);

      // manual hour created so, check if BatchGroup Continues CheckIn ID present, if yes, remove it
      this.removeIfContinuousCheckInID(selectedDate);
    }

    if (this.state.allowFinishTask && this.state.multiTaskFinish.length > 0) {
      for (let i = 0; i < this.state.multiTaskFinish.length; i++) {
        this.markTaskStatus(true, this.state.multiTaskFinish[i]);
      }
    }

    if (!this.state.groupId && !this.state.isCheckInEntry) {
      this.handleMultiProjectModalCancel();
    }
  };

  onSubmitHourByTask = async () => {
    const startDate = moment(this.props.startDate).clone();
    const selectedDate = startDate.clone().day(this.state.dayNumber);

    if (this.state.groupId) {
      let data = null;
      if (
        !this.state.isManualEntriesOnEdit &&
        !this.state.allowRulesToManualEntry
      ) {
        data = this.getMultiProjectData(selectedDate, false);
      } else {
        data = this.getMultiProjectEditData(selectedDate, false);
      }
      await this.updateMultiProjectHours(this.state.groupId, data);

      // run the open modal process
      this.setState({
        isQuickHoursSubmit: true,
        hourModalDataChanged: false,
        repeatDateCallPending:
          this.state.repeat_Dates && this.state.repeat_Dates.length
            ? true
            : false,
      });
    } else {
      const data = this.getMultiProjectData(selectedDate, false);

      await this.props.actions.createMultiProjectHours(data);
      this.setState({ checkMultiProjectError: true });

      // manual hour created so, check if BatchGroup Continues CheckIn ID present, if yes, remove it
      this.removeIfContinuousCheckInID(selectedDate);

      // run the open modal process
      this.setState({
        isQuickHoursSubmit: true,
        hourModalDataChanged: false,
        repeatDateCallPending: false,
      });
    }

    if (this.state.allowFinishTask && this.state.multiTaskFinish.length > 0) {
      for (let i = 0; i < this.state.multiTaskFinish.length; i++) {
        this.markTaskStatus(true, this.state.multiTaskFinish[i]);
      }
    }
  };

  loadUpdatedDataInHoursModal = () => {
    // in case of addons, wait for hours and addons (both) API response and then proceed
    let counter = this.state.refreshHourModalAfterApi === "addon" ? 0 : 5;
    const timerId = setInterval(() => {
      counter++;

      if (
        counter > 5 ||
        (this.state.refreshHourModalAfterApi === "addon" &&
          this.state.checkNewData === 3)
      ) {
        this.multiProjectModalOpen(this.state.dayNumber, this.state.groupId);
        this.setState({
          isQuickHoursSubmit: this.state.repeatDateCallPending ? true : false,
          repeatDateCallPending: false,
        });

        clearInterval(timerId);
      }
    }, 200);
  };

  previewRulesOutcome = async () => {
    const startDate = moment(this.props.startDate).clone();
    const selectedDate = startDate.clone().day(this.state.dayNumber);

    let data = null;
    if (this.state.groupId) {
      if (
        !this.state.isManualEntriesOnEdit &&
        !this.state.allowRulesToManualEntry
      ) {
        data = this.getMultiProjectData(selectedDate, true);
      } else {
        data = this.getMultiProjectEditData(selectedDate, true);
      }
    } else {
      data = this.getMultiProjectData(selectedDate, true);
    }

    await this.props.actions.previewMultiProjectHours(data);
    this.setState({ checkPreviewMultiProject: true });
  };

  createMultiProjectHours = async (data) => {
    await this.props.actions.createMultiProjectHours(data);
    this.setState({ checkMultiProjectError: true });
  };

  prepareForUpdateAttendanceLog = () => {
    // get user attendace to log the end_time in the attendace
    if (
      this.state.isCheckInEntry &&
      this.state.attendaceModuleAssigned &&
      this.state.reportWebAttendance
    ) {
      this.props.actions.getLastUserAttendance();

      let attendaceTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
      if (
        this.state.isContinuesCheckIn &&
        this.state.continuesCheckInStartTime
      ) {
        attendaceTime = moment(this.state.continuesCheckInStartTime)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      this.setState({
        checkoutLogObj: {
          end_time: attendaceTime + "Z",
          attendanceable_id: this.state.loginUserId,
          attendanceable_type: "User",
        },
        checkAttendanceLog: true,
      });
    }
  };

  updateMultiProjectHours = async (groupId, data) => {
    if (this.state.hourApprovalRequired) {
      let updatedData = { batch_group_changes: data };
      if (this.state.isManualEntriesOnEdit) {
        updatedData["edit"] = "true";
      }
      await this.props.actions.updateBatchGroup(groupId, updatedData);
    } else {
      await this.props.actions.updateBatchGroup(groupId, data);
    }
    this.setState({ checkMultiProjectEditError: true });

    // in case repeat dates selected in Edit modal, prepare data for separate POST call
    if (this.state.repeat_Dates && this.state.repeat_Dates.length) {
      const repeatDateData = this.getMultiProjectRepeatDateData();
      if (repeatDateData) {
        await this.props.actions.createMultiProjectHours(repeatDateData);
        setTimeout(() => {
          this.setState({ checkMultiProjectError: true });
        }, 2000);
      }
    }
  };

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  getTasks = (newLimit, newOffset, newSearch, forLeave) => {
    if (this.state.selectedProject) {
      this.props.actions.getTasks({
        project_id: this.state.selectedProject,
        offset: newOffset,
        limit: newLimit,
        search: newSearch.length > 0 ? newSearch : "",
        finish: "false",
        hour_summary: false,
      });
    } else {
      this.props.actions.getAllTasks({
        offset: newOffset,
        limit: newLimit,
        search: newSearch.length > 0 ? newSearch : "",
        finish: "false",
        open: "true",
        hour_summary: true,
        forLeave: forLeave,
        isSortAlphabetically: this.state.isSortAlphabetically,
      });
    }
  };

  getTaskOptions = (limit, offset, search, forLeave) => {
    if (this.state.isDeviceProjectTask) {
      if (this.state.selectedProject) {
        const tasks = this.state.deviceTaskList[this.state.selectedProject];
        return {
          options: tasks || [],
          hasMore: false,
        };
      }
    } else {
      const promise = new Promise((resolve) => {
        this.getTasks(limit, offset, search, forLeave);
        this.setState({
          checkTaskList: true,
        });
        let counter = 0;

        const timerId = setInterval(() => {
          counter++;
          if (counter > 10) {
            this.setState({
              checkTaskList: false,
              checkTaskOptions: true,
            });
          }

          if (this.state.checkTaskOptions) {
            this.setState({
              checkTaskOptions: false,
            });

            let entries = [];
            let paginationObj = null;

            if (this.state.selectedProject) {
              if (
                this.props.taskReducer.taskList &&
                this.props.taskReducer.taskList[this.state.selectedProject] &&
                this.props.taskReducer.taskList[this.state.selectedProject]
                  .entries
              ) {
                entries =
                  this.props.taskReducer.taskList[this.state.selectedProject]
                    .entries;
                paginationObj =
                  this.props.taskReducer.taskList[this.state.selectedProject]
                    .pagination;
              }
            } else {
              if (
                this.props.taskReducer.allTasks &&
                this.props.taskReducer.allTasks.entries
              ) {
                entries = this.props.taskReducer.allTasks.entries;
                paginationObj = this.props.taskReducer.allTasks.pagination;
              }
            }

            if (entries && entries.length > 0) {
              //this.setState({ taskList: entries });
              if (
                this.state.selectedTask &&
                this.state.selectedTask.__isNew__
              ) {
                let newSelectedOption = entries.find((entry) => {
                  return entry.name === this.state.selectedTask.label;
                });

                if (
                  newSelectedOption &&
                  newSelectedOption.id &&
                  newSelectedOption.name
                ) {
                  this.setState({
                    selectedTask: {
                      value: newSelectedOption.id.toString(),
                      label: newSelectedOption.name,
                    },
                  });
                }
              }

              let data = [];
              for (let i = 0; i < entries.length; i++) {
                const element = entries[i];
                const node = {
                  value: element.id.toString(),
                  label: !this.state.selectedProject ? (
                    <ListItemText
                      primary={element.name}
                      secondary={element.project_name}
                    />
                  ) : (
                    element.name
                  ),
                };

                if (!this.state.selectedProject) {
                  node["project_id"] = element.project_id;
                  node["project_name"] = element.project_name;
                  node["customer_name"] = element.customer_name;
                }

                data.push(node);
              }

              clearInterval(timerId);

              resolve({
                options: data,
                hasMore: paginationObj.has_more_items,
              });
            } else {
              clearInterval(timerId);

              resolve({
                options: [],
                hasMore: false,
              });
            }
          }
        }, 1000);
      });

      return promise;
    }
  };

  addTask = async (name) => {
    const data = {
      name: name,
      project_id: this.state.selectedProject,
    };
    await this.props.actions.addTask(data);
    await this.setState({ checkError: true });
  };

  addProject = async (name, customer_id) => {
    var data = {
      name: name,
      customer_id: parseInt(customer_id, 10),
      responsible_id: this.state.loginUserId,
    };
    await this.props.actions.addProject(data);
  };

  isCheckInAllowed = () => {
    const promise = new Promise((resolve) => {
      let counter = 0;
      const hourTimer = setInterval(async () => {
        counter++;
        if (counter > 10) {
          this.setState({
            isHoursLoaded: true,
          });
        }

        if (this.state.isHoursLoaded) {
          clearInterval(hourTimer);

          let isValid = true;
          let isResumeCheckin = false;
          let resumeCheckinData = null;

          if (
            this.props.hourReducer.hours &&
            this.props.hourReducer.hours.entries &&
            this.props.hourReducer.hours.entries.length > 0
          ) {
            const totalHours = this.props.hourReducer.hours.entries.length;

            const lastHour =
              this.props.hourReducer.hours.entries[totalHours - 1];

            if (lastHour?.batch_group_end_time) {
              const lastHourEndTime = moment(lastHour.batch_group_end_time)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss.SSS");

              const secondsSinceLastCheckout = moment().diff(
                lastHourEndTime,
                "seconds"
              );

              if (secondsSinceLastCheckout < 1) {
                isValid = false;
              }
            }

            if (
              this.state.isResumeCheckInEnabled &&
              lastHour?.check_in_out_id &&
              lastHour?.check_in_out_end_time
            ) {
              const hourEndTime = moment(lastHour.check_in_out_end_time)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss.SSS");

              const minutesSinceLastCheckout = moment().diff(
                hourEndTime,
                "minutes"
              );

              const remainingTime =
                this.state.resumeCheckInTimeWindow - minutesSinceLastCheckout;

              if (remainingTime >= 0) {
                isResumeCheckin = true;
                resumeCheckinData = {
                  groupId: lastHour.batch_group_id,
                  startTime: lastHour?.batch_group_start_time,
                  endTime: hourEndTime,
                  timeRemain: remainingTime,
                };
              }
            }

            resolve({
              startTime: lastHour?.batch_group_start_time,
              endTime: lastHour?.batch_group_end_time,
              isResumeCheckin: isResumeCheckin,
              resumeCheckinData: resumeCheckinData,
              isValid: isValid,
            });
          } else {
            resolve({
              isValid: isValid,
            });
          }
        }
      }, 600);
    });

    return promise;
  };

  loadProjectList = async (searchVal, options) => {
    if (this.state.isDeviceProjectTask) {
      return {
        options: this.state.deviceProjectList,
        hasMore: false,
      };
    } else {
      let offset = options && options.length ? options.length : 0;
      let queryString = `more_details=false&open=true&limit=20&offset=${offset}`;

      if (searchVal && searchVal !== "") {
        queryString += `&name=${searchVal}`;
      }

      this.setState({ isLoadProjectResult: false });
      await this.props.actions.getProjects(queryString);
      const result = await this.getProjectListOptions();
      return result;
    }
  };

  getProjectListOptions = () => {
    const promise = new Promise((resolve) => {
      let counter = 0;
      const projTimer = setInterval(async () => {
        counter++;
        if (counter > 10) {
          this.setState({
            isLoadProjectResult: true,
          });
        }

        if (this.state.isLoadProjectResult) {
          clearInterval(projTimer);

          if (
            this.props.projectReducer.projectList &&
            this.props.projectReducer.projectList.entries
          ) {
            let data = [];
            for (
              let i = 0;
              i < this.props.projectReducer.projectList.entries.length;
              i++
            ) {
              const element = this.props.projectReducer.projectList.entries[i];
              const label = (
                <ListItemText
                  primary={element.name}
                  secondary={element.customer_name}
                />
              );

              data.push({
                value: element.id.toString(),
                label: label,
                color: element.color.toString(),
              });
            }

            resolve({
              options: data,
              hasMore:
                this.props.projectReducer.projectList.pagination.has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 600);
    });
    return promise;
  };

  addCustomer = async (name) => {
    var customerData = {
      name: name,
      company_id: this.props.profileReducer.userData.company_id,
    };
    await this.props.actions.addCutomers(customerData);
  };

  getCustomers = async (newLimit, newOffset, search) => {
    await this.props.actions.getCutomers({
      offset: newOffset,
      limit: newLimit,
      search: search && search.length > 0 ? search : "",
    });
  };

  getCustomersListOptions = (limit, offset, search) => {
    const promise = new Promise((resolve) => {
      this.getCustomers(limit, offset, search);
      this.setState({
        checkCustomerList: true,
      });
      let counter = 0;

      const timerId = setInterval(() => {
        counter++;
        if (counter > 10) {
          this.setState({
            checkCustomerList: false,
            checkCustomerOptions: true,
          });
        }

        if (this.state.checkCustomerOptions) {
          this.setState({
            checkCustomerOptions: false,
          });

          clearInterval(timerId);

          if (
            this.props.customerReducer.customerList &&
            this.props.customerReducer.customerList.entries
          ) {
            let data = [];
            for (
              let i = 0;
              i < this.props.customerReducer.customerList.entries.length;
              i++
            ) {
              const element =
                this.props.customerReducer.customerList.entries[i];
              data.push({
                value: element.id.toString(),
                label: element.name,
                company_id: element.company_id,
              });
            }

            resolve({
              options: data,
              hasMore:
                this.props.customerReducer.customerList.pagination
                  .has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 600);
    });

    return promise;
  };

  removeIfContinuousCheckInID = (selectedDate) => {
    if (this.state.checkInBatchGroupId) {
      // if new hour created today, then remove
      if (selectedDate.format("DD,MM,YY") === moment().format("DD,MM,YY")) {
        this.setState({
          checkInBatchGroupId: null,
        });
      }
    }
  };

  handleResumeCheckInButtonClick = (lastCheckOut) => {
    const batchGroupId = lastCheckOut?.groupId || null;

    if (batchGroupId && lastCheckOut?.endTime) {
      this.setState({
        continuesCheckInStartTime: lastCheckOut?.endTime,
        checkInBatchGroupId: batchGroupId,
      });

      this.checkInModalOpen();
    }
  };

  markCheckInForAttendanceStatus = () => {
    this.setState({
      checkInForAttendanceDone: true,
    });
  };

  handleCheckInForAttendaceAction = () => {
    // close attendance dialog
    this.setState({
      openCheckInForAttendanceModal: false,
    });

    // if checked, report attendace
    if (this.state.isCheckInForAttendance) {
      const currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS");

      this.logUserAttendance(currentTime, true);

      this.setState({
        isCheckInForAttendance: false,
      });
    }

    this.checkInModalOpen();
  };

  handleCheckOutForAttendaceAction = () => {
    this.props.actions.getLastUserAttendance();

    const currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

    this.setState({
      checkoutLogObj: {
        end_time: currentTime,
        attendanceable_id: this.state.loginUserId,
        attendanceable_type: "User",
      },
      checkAttendanceLogEntryForCheckout: true,
      isCheckInForAttendance: false,
      openCheckOutForAttendanceModal: false,
    });

    // auto logout
    this.setState({
      autoLogoutModalOpen: true,
    });
  };

  getHmskTemplates = async (projectIds, taskIds) => {
    const project_ids =
      projectIds && projectIds.length ? projectIds.join(",") : "";
    const task_ids = taskIds && taskIds.length ? taskIds.join(",") : "";
    if (project_ids || task_ids) {
      const queryString = `project_ids=${project_ids}&all_project=true&task_ids=${task_ids}`;
      await this.props.actions.getHmskTemplates(queryString);
    }
  };

  getSpecificPlanUsers = (taskId) => {
    const queryString = `task_id=${taskId}`;
    this.props.actions.getSpecificPlanUsers(queryString);
  };

  onSubmitOptionalType = () => {
    let hourChanged = false;
    let addonChanged = false;

    this.setState({ checkOptionalTypeError: true });

    for (let i = 0; i < this.state.multipleProjectAllHours.length; i++) {
      const hourNode = this.state.multipleProjectAllHours[i];
      if (
        hourNode.new_type_id &&
        hourNode.new_type_id !== hourNode.old_type_id
      ) {
        this.props.actions.setOptionalHourType(hourNode.id, {
          optional_hour_type_id: parseInt(hourNode.new_type_id, 10),
        });
        hourChanged = true;
      }
    }
    for (let i = 0; i < this.state.multipleProjectAllAddons.length; i++) {
      const addonNode = this.state.multipleProjectAllAddons[i];
      if (
        addonNode.new_type_id &&
        addonNode.new_type_id !== addonNode.old_type_id
      ) {
        this.props.actions.setOptionalAddonType(addonNode.id, {
          optional_addon_addon_type_id: parseInt(addonNode.new_type_id, 10),
        });
        addonChanged = true;
      }
    }

    this.handleMultiProjectModalCancel();

    // wait for all API calls to execute
    setTimeout(() => {
      this.setState({ checkOptionalTypeError: false });
      this.succMsgDone = false;
      if (hourChanged) this.getHours();
      if (addonChanged) this.getAddons();
    }, 2500);
  };

  onModalClose = () => {
    this.setState({
      autoLogoutModalOpen: false,
    });
  };

  handleLeaveModalOpen = () => {
    this.setState({
      openLeaveModal: true,
      selectedTask: null,
      selectedProject: null,
      repeat_Dates: [],
      startTime: this.state.selectedUserStartTime,
      endTime: this.state.selectedUserEndTime,
      isSortAlphabetically: this.state.taskSortedAlphabetically,
      isLunchTimeInvalid: false,
    });
  };

  handleLeaveModalCancel = () => {
    this.setState({
      openLeaveModal: false,
      lunchStartTime: "00:00",
      lunchEndTime: "00:00",
      startTime: "00:00",
      endTime: "00:00",
      startDate: this.props.startDate,
      selectedProject: null,
      selectedTask: null,
      repeat_Dates: [],
      comment: null,
      isSortAlphabetically: false,
      isLunchTimeInvalid: false,
    });
  };

  getLeaveDetail = (selectedDatee) => {
    const currentDateNode = {};
    const selectedDate = moment(selectedDatee);
    // in case of cross day
    const nextSelectedDate = moment(selectedDate).add(1, "days");

    let startTimeStr = "";
    if (this.state.startTime) {
      const start = this.state.startTime.split(":");
      const startTimeHour = start[0];
      const startTimeMin = start[1];
      startTimeStr = selectedDate
        .clone()
        .set({
          hour: startTimeHour,
          minute: startTimeMin,
        })
        .format("YYYY-MM-DDTHH:mm:ss.SSS");
      currentDateNode["start_time"] = startTimeStr + "Z";
    }

    let endTimeStr = "";
    if (this.state.endTime) {
      const end = this.state.endTime.split(":");
      const endTimeHour = end[0];
      const endTimeMin = end[1];

      if (this.state.endTime < this.state.startTime) {
        endTimeStr = nextSelectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      } else {
        endTimeStr = selectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      }
      currentDateNode["end_time"] = endTimeStr + "Z";
    }

    let currentDateData = [];

    currentDateData.push({
      start_time: startTimeStr + "Z",
      end_time: endTimeStr + "Z",
      task_id: parseInt(this.state.selectedTask.value, 10),
    });

    currentDateNode["multiple_hours"] = currentDateData;

    return currentDateNode;
  };

  getLeaveRepeatData = (selectedDate) => {
    const data = {
      comment: this.state.comment,
    };

    // lunch time ...
    data["user_id"] =
      getItem("userRole") === "admin" && this.state.userId
        ? parseInt(this.state.userId, 10)
        : parseInt(getItem("userId"), 10);

    if (this.state.lunchStartTime && this.state.lunchEndTime) {
      if (
        this.state.lunchEndTime === "00:00" &&
        this.state.lunchStartTime === "00:00"
      ) {
        data["lunch_start_time"] = "";
        data["lunch_end_time"] = "";
      } else {
        data["lunch_start_time"] = moment(
          this.state.lunchStartTime,
          "HH:mm"
        ).format("HH:mm");
        data["lunch_end_time"] = moment(
          this.state.lunchEndTime,
          "HH:mm"
        ).format("HH:mm");
      }
    }

    // geo location
    if (this.state.isGeoLockRequired && this.props.coords) {
      if (this.state.isGeoFenceDisabled) {
        data["tablet"] = true;
      } else {
        data["latitude"] = this.props.coords.latitude;
        data["longitude"] = this.props.coords.longitude;
      }
    } else {
      data["latitude"] = "";
      data["longitude"] = "";
    }

    data["dates"] = [];

    const currentDateNode = {};

    // in case of cross day
    const nextSelectedDate = moment(selectedDate).add(1, "days");

    let startTimeHour = null;
    let startTimeMin = null;

    let startTimeStr = "";
    if (this.state.startTime) {
      const start = this.state.startTime.split(":");
      startTimeHour = start[0];
      startTimeMin = start[1];
      startTimeStr = selectedDate
        .clone()
        .set({
          hour: startTimeHour,
          minute: startTimeMin,
        })
        .format("YYYY-MM-DDTHH:mm:ss.SSS");
      currentDateNode["start_time"] = startTimeStr + "Z";
    }

    let endTimeStr = "";
    if (this.state.endTime) {
      const end = this.state.endTime.split(":");
      const endTimeHour = end[0];
      const endTimeMin = end[1];

      if (this.state.endTime < this.state.startTime) {
        endTimeStr = nextSelectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      } else {
        endTimeStr = selectedDate
          .set({
            hour: endTimeHour,
            minute: endTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      currentDateNode["end_time"] = endTimeStr + "Z";
    }

    let currentDateData = [];

    currentDateData.push({
      start_time: startTimeStr + "Z",
      end_time: endTimeStr + "Z",
      task_id: parseInt(this.state.selectedTask.value, 10),
    });

    currentDateNode["multiple_hours"] = currentDateData;

    data["dates"].push(currentDateNode);

    for (let i = 0; i < this.state.repeat_Dates.length; i++) {
      selectedDate = moment(this.state.repeat_Dates[i]);
      currentDateData = this.getLeaveDetail(selectedDate, false);
      data["dates"].push(currentDateData);
    }

    return data;
  };

  handleLeaveSubmit = (selectedDate) => {
    const data = this.getLeaveRepeatData(moment(selectedDate));

    this.createMultiProjectHours(data);

    this.handleLeaveModalCancel();
  };

  handleBatchGroupApprove = async (groupId, status) => {
    if (status === "undefined" || status === "change") {
      await this.props.actions.approveGroup(groupId);
      this.setState({ checkApprovalError: true });
    }
  };

  handleSort = () => {
    this.setState(
      { isSortAlphabetically: !this.state.isSortAlphabetically },
      () => this.getTaskOptions(20, 0, "", true)
    );
  };

  render = () => {
    return !authActions.getAccessToken() ? (
      <Redirect to={`/token/${authActions.getCompanyToken()}`} />
    ) : (
      <React.Fragment>
        <HourFilters
          selectedUser={this.state.selectedUser}
          userId={this.state.userId}
          changeState={this.changeState}
          checkFilter={this.checkFilter}
          loadUserList={this.loadUserList}
          // check-in button ...
          checkInModalOpen={this.handleCheckInButtonClick}
          allowCheckIn={this.state.allowCheckIn}
          // check-in modal ...
          isOpen={this.state.openCheckListModal}
          checkList={this.state.checkList}
          handleClose={this.postCheckListSteps}
          hmskTemplateReducer={this.props.hmskTemplateReducer}
          hmskCheckListReducer={this.props.hmskCheckListReducer}
          actions={this.props.actions}
          // check-out ...
          handleCheckOut={this.handleCheckOutButtonClick}
          checkInData={this.state.checkInData}
          handleLeaveModalOpen={this.handleLeaveModalOpen}
          getDefaultUserValues={this.getDefaultUserValues}
          showOnlyDashboard={this.state.showOnlyDashboard}
          {...this.props}
        />
        {!this.state.showOnlyDashboard && (
          <Table
            state={this.state}
            hourTypeOptions={this.state.hourTypeOptions}
            getHours={this.getHours}
            loadMoreHours={this.loadMoreHours}
            multiProjectModalOpen={this.multiProjectModalOpen}
            handleCheckOut={this.handleCheckOutButtonClick}
            changeState={this.changeState}
            handleResumeCheckInButtonClick={this.handleResumeCheckInButtonClick}
            selectedUser={this.state.selectedUser}
            //
            userId={this.state.userId}
            checkFilter={this.checkFilter}
            loadUserList={this.loadUserList}
            //
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            // check-in button ...
            checkInModalOpen={this.handleCheckInButtonClick}
            allowCheckIn={this.state.allowCheckIn}
            // check-in modal ...
            isOpen={this.state.openCheckListModal}
            checkList={this.state.checkList}
            handleClose={this.postCheckListSteps}
            hmskTemplateReducer={this.props.hmskTemplateReducer}
            hmskCheckListReducer={this.props.hmskCheckListReducer}
            handleLeaveModalOpen={this.handleLeaveModalOpen}
            handleBatchGroupApprove={this.handleBatchGroupApprove}
            actions={this.props.actions}
            classes={this.props.classes}
            getDefaultUserValues={this.getDefaultUserValues}
            {...this.props}
          />
        )}
        <DeleteModal
          isOpen={this.state.openDeleteModal}
          handleCancel={this.handleDeleteCancel}
          handleSubmit={this.handleDeleteSubmit}
          reducer={this.props.hourReducer}
        />
        <InputMultiProjectHourModal
          state={this.state}
          handleCancel={this.handleMultiProjectModalCancel}
          onSubmit={this.handleMultiProjectFormSubmit}
          onSubmitOptionalType={this.onSubmitOptionalType}
          onSubmitHourByTask={this.onSubmitHourByTask}
          deleteModalOpen={this.deleteModalOpen}
          changeState={this.changeState}
          onCheckoutHourModalSubmit={this.handleCheckoutHourModalSubmit}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          filterActions={this.props.filterActions}
          handleCheckInDiscard={this.handleCheckInDiscard}
          previewRulesOutcome={this.previewRulesOutcome}
          handleCancelPreview={this.handleCancelPreview}
          handleEditOutsideRules={this.handleEditOutsideRules}
          checkForAutofilLunchTime={this.checkForAutofilLunchTime}
        />
        <SelectProjectTaskModal
          state={this.state}
          changeState={this.changeState}
          addTask={this.addTask}
          getTaskOptions={this.getTaskOptions}
          loadProjectList={this.loadProjectList}
          addProject={this.addProject}
          addCustomer={this.addCustomer}
          getCustomers={this.getCustomers}
          getCustomersListOptions={this.getCustomersListOptions}
          projectList={
            this.props?.projectReducer?.projectList?.entries
              ? this.props.projectReducer.projectList.entries
              : []
          }
        />
        <FormCheckInModal
          state={this.state}
          handleCancel={this.handleCheckInModalCancel}
          handleCheckInStart={this.handleCheckInStart}
          changeState={this.changeState}
          addTask={this.addTask}
          getTaskOptions={this.getTaskOptions}
          loadProjectList={this.loadProjectList}
          addProject={this.addProject}
          addCustomer={this.addCustomer}
          getCustomers={this.getCustomers}
          getCustomersListOptions={this.getCustomersListOptions}
          getSpecificPlanUsers={this.getSpecificPlanUsers}
          specificPlanUsersList={
            this.props.planningReducer.specificPlanUsersList
          }
          specificPlanUsersLoading={
            this.props.planningReducer.specificPlanUsersLoading
          }
          taskReducer={this.props.taskReducer}
        />
        <CheckoutModal
          isOpen={this.state.openCheckoutModal}
          lateComment={this.state.lateComment}
          isLateComment={
            this.state.isLateCheckout && this.state.isRequireCommentEnabled
          }
          isLunchTime={this.state.isLunchTime}
          lunchStartTime={this.state.lunchStartTime}
          lunchEndTime={this.state.lunchEndTime}
          changeState={this.changeState}
          isContinuesCheckIn={this.state.isContinuesCheckIn}
          isTaskFinished={this.state.isTaskFinished}
          allowFinishTask={this.state.allowFinishTask}
          handleCancel={this.handleCheckoutCancel}
          handleCheckInDiscard={this.handleCheckInDiscard}
          handleSubmit={this.handleCheckOutDialogSubmit}
          reducer={this.props.hourReducer}
          isAutoFillLunch={this.state.isAutoFillLunch}
          autoFillLunchAmount={this.state.autoFillLunchAmount}
          restrictLunchEditIfAutofill={this.state.restrictLunchEditIfAutofill}
          standardLunchTime={
            getItem("userRole") === "admin"
              ? this.state.adminLunchTime
              : this.state.userLunchTime
          }
          checkInStartTime={
            this.state.checkInData && this.state.checkInData.start_time
          }
          loginUserGroupLunchByDay={this.state.loginUserGroupLunchByDay}
        />
        {this.state.openContinuesCheckInModal && (
          <ContinuesCheckInModal
            isOpen={this.state.openContinuesCheckInModal}
            changeState={this.changeState}
            handleContinuesCheckIn={this.handleContinuesCheckIn}
            onFinishClick={this.startCheckoutFlow}
          />
        )}
        <AttendanceCheckInModal
          isOpen={this.state.openCheckInForAttendanceModal}
          changeState={this.changeState}
          isCheckInForAttendance={this.state.isCheckInForAttendance}
          handleNext={this.handleCheckInForAttendaceAction}
        />
        <AttendanceCheckOutModal
          isOpen={this.state.openCheckOutForAttendanceModal}
          changeState={this.changeState}
          handleSubmit={this.handleCheckOutForAttendaceAction}
        />
        <LeaveRequestModal
          state={this.state}
          changeState={this.changeState}
          handleLeaveModalCancel={this.handleLeaveModalCancel}
          getTaskOptions={this.getTaskOptions}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          handleSort={this.handleSort}
          onSubmit={this.handleLeaveSubmit}
        />
        {!this.props.isGeolocationAvailable &&
        (this.state.isGeoLockRequired || this.state.isGeoFenceForAttendace) &&
        !this.state.isGeoFenceDisabled ? (
          <AlertModal
            isOpen={this.state.openAlertModal}
            handleClose={() => {
              this.setState({ openAlertModal: false });
            }}
            title={`${i18next.t("Geofence")}`}
            message={DENIED_LOCATION_PERMISSION_MESSAGE}
          />
        ) : !this.props.isGeolocationEnabled &&
          (this.state.isGeoLockRequired || this.state.isGeoFenceForAttendace) &&
          !this.state.isGeoFenceDisabled ? (
          <AlertModal
            isOpen={this.state.openAlertModal}
            handleClose={() => {
              this.setState({ openAlertModal: false });
            }}
            title={`${i18next.t("Geofence")}`}
            message={REQUEST_LOCATION_PERMISSION_MESSAGE}
          />
        ) : null}
        {this.state.openCheckListModal && (
          <ChecklistModal
            isOpen={this.state.openCheckListModal}
            checkList={this.state.checkList}
            handleClose={this.postCheckListSteps}
            hmskTemplateReducer={this.props.hmskTemplateReducer}
            hmskCheckListReducer={this.props.hmskCheckListReducer}
            actions={this.props.actions}
            //projectIds={this.state.checkListProjectIds}
          />
        )}
        {this.state.autoLogoutModalOpen && (
          <AutoLogoutModal
            isOpen={this.state.autoLogoutModalOpen}
            history={this.props.history}
            isCheckOut={this.state.allowCheckIn}
            onModalClose={this.onModalClose}
          />
        )}
        <OverlayIndicator
          isLoading={
            this.props.hourReducer.hourLoading ||
            this.props.hourReducer.addonLoading
          }
        />
        <CheckInAlert
          open={this.state.openCheckInAlert}
          data={this.state.checkInAlertData}
          handleClose={() => {
            this.setState({ openCheckInAlert: false, checkInAlertData: null });
          }}
        />
        {this.state.showResumeCheckInDialog && (
          <ResumeCheckInDialog
            open={this.state.showResumeCheckInDialog}
            data={this.state.resumeCheckinData}
            handleResumeCheckInButtonClick={this.handleResumeCheckInButtonClick}
            handleCheckInButtonClick={this.checkInModalOpen}
            handleClose={() => {
              this.setState({
                showResumeCheckInDialog: false,
                resumeCheckinData: null,
              });
            }}
          />
        )}
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    projectReducer: state.projectReducer,
    customerReducer: state.customer,
    hourTypes: state.hourTypes,
    hourReducer: state.hourReducer,
    startDate: state.filters.startDate,
    endDate: state.filters.endDate,
    taskReducer: state.task,
    profileReducer: state.profile,
    companyReducer: state.companyReducer,
    commonReducer: state.commonReducer,
    addonTypes: state.addonTypes,
    hmskTemplateReducer: state.hmskTemplateReducer,
    hmskCheckListReducer: state.hmskCheckListReducer,
    planningReducer: state.planningReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
};

const geoLockSettings = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(geolocated(geoLockSettings)(Hour)));
