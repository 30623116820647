import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import i18next from "i18next";

export const NoRecordFoundWithI18n = withStyles(() => ({
  mainContainer: {
    maxWidth: "1000px",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#BFBFBF",
    margin: "20px auto",
  },
}))((props) => {
  const { classes } = props;
  return (
    <Typography component="div" className={classes.mainContainer}>
      {i18next.t("No record found")}
    </Typography>
  );
});

export const NotActiveModuleWithI18n = withStyles((theme) => ({
  mainContainer: {
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    position: "fixed",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "0",
    left: "0",
  },
}))((props) => {
  const { classes } = props;
  return (
    <Typography component="div" className={classes.mainContainer}>
      {i18next.t("The company does not have an active subscription")}
    </Typography>
  );
});
