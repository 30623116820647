import React, { Fragment } from "react";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Typography,
  Paper,
  ListItem,
  ListItemIcon,
  SvgIcon,
  ListItemText,
} from "@mui/material";
import { NoRecordFoundWithI18n } from "@/common/CommonComponents";
import MaterialTextfield from "@/common/MaterialTextfield";
import InfiniteScroll from "react-infinite-scroll-component";

const styles = (theme) => ({
  listContainer: {
    height: "calc(100vh - 150px)",
    overflow: "auto",
    paddingRight: 10,
    marginTop: 10,
  },
  listItem: {
    margin: "2px 2px 10px 2px",
    cursor: "pointer",
  },
  checkListSvg: {
    color: theme.palette.primary.main,
  },
});

class OwnCheckList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography component="div">
          <MaterialTextfield
            label={i18next.t("Search")}
            type="text"
            value={this.props.state.searchVal}
            onChange={async (e) => {
              await this.props.changeState({
                searchVal: e.target.value,
                templateList: [],
              });
              await this.props.getHmskTemplates();
            }}
          />
        </Typography>

        <Typography
          component="div"
          id="templateListDiv"
          className={classes.listContainer}
        >
          <InfiniteScroll
            dataLength={this.props.state.templateList.length}
            next={this.props.getHmskTemplates}
            hasMore={this.props.state.templateListHasMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="templateListDiv"
          >
            {this.props.state.templateList &&
            this.props.state.templateList.length > 0 ? (
              this.props.state.templateList.map((entry, index) => {
                return (
                  <Paper
                    elevation={1}
                    key={index}
                    className={classes.listItem}
                    onClick={() => {
                      this.props.changeState({
                        selctedCheckList: entry.id,
                      });
                    }}
                  >
                    <ListItem>
                      <ListItemIcon>
                        <SvgIcon className={classes.checkListSvg}>
                          <path d="M3,5H9V11H3V5M5,7V9H7V7H5M11,7H21V9H11V7M11,15H21V17H11V15M5,20L1.5,16.5L2.91,15.09L5,17.17L9.59,12.59L11,14L5,20Z" />
                        </SvgIcon>
                      </ListItemIcon>
                      <ListItemText primary={entry.name} />
                    </ListItem>
                  </Paper>
                );
              })
            ) : (
              <NoRecordFoundWithI18n />
            )}
          </InfiniteScroll>
        </Typography>
      </Fragment>
    );
  };
}

export default withStyles(styles)(OwnCheckList);
