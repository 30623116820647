import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { getDateAndTimeFormat } from "@/utils/dateTimeFunctions";

import moment from "moment";
import i18next from "i18next";

export default function CheckInAlert({ open, data, handleClose }) {
  let startTimeFormatted = "";
  let endTimeFormatted = "";
  if (data?.startTime && data?.endTime) {
    startTimeFormatted = moment(data?.startTime)
      .utc()
      .format(getDateAndTimeFormat());
    endTimeFormatted = moment(data?.endTime)
      .utc()
      .format(getDateAndTimeFormat());
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{i18next.t("Unable to start timer")}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="div">
          {i18next.t("msg-check-in-alert")}
          <div style={{ paddingTop: "16px" }}>
            <Typography
              variant="div"
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
            >
              {i18next.t("Existing hours")}:
            </Typography>
            <div>
              {i18next.t("Start time")}: {startTimeFormatted}
            </div>
            <div>
              {i18next.t("End time")}: {endTimeFormatted}
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ textTransform: "uppercase" }}
          autoFocus
        >
          {i18next.t("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
