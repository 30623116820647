import React, { useState, useEffect } from "react";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";

import { useTheme } from "@mui/material/styles";

import i18next from "i18next";

export default function AppBarDateRangePicker({ dates, onChangeDate }) {
  const theme = useTheme();

  const [value, setValue] = useState([null, null]);

  useEffect(() => {
    if (dates?.length) {
      setValue([dates[0], dates[1]]);
    }
  }, [dates]);

  const handleClose = () => {
    if (value[0] && value[1]) {
      onChangeDate([value[0], value[1]]);
      setValue([value[0], value[1]]);
    }
  };

  return (
    <MobileDateRangePicker
      calendars={1}
      value={value}
      onChange={(newValue) => {
        setValue([newValue[0], newValue[1] ? newValue[1] : newValue[0]]);
      }}
      onClose={handleClose}
      format={"DD MMM"}
      slots={{ field: SingleInputDateRangeField }}
      slotProps={{
        toolbar: {
          hidden: true,
        },
        textField: {
          variant: "standard",
          style: {
            justifyContent: "center",
          },
          sx: {
            input: {
              fontWeight: "700",
              fontSize: "14px",
              cursor: "pointer",
              minHeight: "42px",
              color: theme.palette.text.primary,
              caretColor: "transparent",
              width: "130px",
              textAlign: "center",
            },
            "&& .MuiInput-root:hover::before": {
              borderBottom: "none",
            },
            "& .MuiInput-root": {
              "&:before": {
                borderBottom: "none",
              },
              "&:after": {
                borderBottom: "none",
              },
            },
          },
        },
      }}
      localeText={{
        okButtonLabel: i18next.t("Ok"),
        cancelButtonLabel: i18next.t("Cancel"),
      }}
    />
  );
}
