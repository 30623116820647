import React from "react";
import i18next from "i18next";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  button: {
    fontSize: 16,
    fontWeight: 700,
    borderRadius: "0.5rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
    marginLeft: 16,
    width: "100%",
  },
}));

function CheckIn(props) {
  const { allowCheckIn, checkInModalOpen, data } = props;

  const checkInOnClickHandle = () => {
    checkInModalOpen(null);
  };

  const classes = useStyles();

  return (
    <>
      {allowCheckIn ? (
        <Button
          aria-label="check-in"
          onClick={checkInOnClickHandle}
          disabled={!allowCheckIn}
          id="check-in-fab-button"
          className={`${classes.button}`}
          variant="contained"
        >
          {i18next.t("Check in")}
        </Button>
      ) : (
        <Button
          aria-label="check-out"
          variant="contained"
          color="error"
          className={`${classes.button}`}
          id="check-out-fab-button"
          onClick={() => {
            props.handleCheckOut(data);
          }}
        >
          {i18next.t("Check out")}
        </Button>
      )}
    </>
  );
}

export default CheckIn;
