import React from "react";
import { connect } from "react-redux";
import { ActionCreators } from "@/redux/actions";
import { bindActionCreators } from "redux";
import { Typography, Button } from "@mui/material";
import { CommonHeaderInSettings } from "@/common/CommonHeaderInList";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import ListCard from "./components/ListCard";
import QuestionAnswerBlock from "./components/questionAnswerBlock";
import moment from "moment";
import { withSnackbar } from "notistack";
import OverlayIndicator from "@/common/OverlayIndicator";
import { Redirect } from "react-router-dom";
import { actions as authActions } from "@/utils/auth";
import DeleteModal from "@/common/deleteModal";

import { getItem } from "@/utils/localStorage";

const styles = (theme) => ({
  cardContainer: {
    margin: "30px auto",
    padding: "0px 20px",
    maxWidth: 1440,
  },
  listItem: {
    padding: 10,
    margin: "0 40px",
    backgroundColor: "#fff",
    alignContent: "center",
  },
  listContainer: {
    margin: "20px auto",
    maxWidth: "600px",
    //height: "calc(100vh - 130px)",
    overflow: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
    paddingBottom: "20px",
    maxWidth: "560px",
  },
  saveButton: {
    marginLeft: "10px",
    textTransform: "uppercase",
    fontWeight: "bold",
    cursor: "pointer",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "#00000000",
    },
    "&:disabled": {
      border: "none",
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.action.hover,
    },
  },
});

class CheckListDetail extends React.Component {
  constructor(props) {
    super(props);

    const userId = getItem("userId") ? Number(getItem("userId")) : null;

    this.state = {
      answers: [],
      checkResponse: false,
      checkListId: null,
      isCollbration: false,
      openDeleteModal: false,
      userId: userId,
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.setState({ checkListId: this.props.match.params.id });
      this.props.actions.getHmskCheckList(this.props.match.params.id);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.hmskCheckListReducer !== this.props.hmskCheckListReducer) {
      if (
        this.props.hmskCheckListReducer &&
        this.props.hmskCheckListReducer.successMessage &&
        this.props.hmskCheckListReducer.successMessage.status &&
        this.state.checkResponse
      ) {
        this.setState({ checkResponse: false });
        this.props.enqueueSnackbar(
          this.props.hmskCheckListReducer.successMessage.message,
          { variant: "success" }
        );

        // prevent reset filter on Checklist page
        this.props.actions.filterData("checkList", {
          referrer: "details-page",
        });
        this.props.history.goBack();
      }
      if (
        this.props.hmskCheckListReducer &&
        this.props.hmskCheckListReducer.errorMessage &&
        this.props.hmskCheckListReducer.errorMessage.response &&
        this.props.hmskCheckListReducer.errorMessage.response.message &&
        this.state.checkResponse
      ) {
        this.setState({ checkResponse: false });
        this.props.enqueueSnackbar(
          this.props.hmskCheckListReducer.errorMessage.response.message,
          { variant: "error" }
        );
      }

      if (
        prevProps.hmskCheckListReducer.hmskCheckListData !==
        this.props.hmskCheckListReducer.hmskCheckListData
      ) {
        this.props.actions.getHmskTemplate(
          this.props.hmskCheckListReducer.hmskCheckListData.hmsk_template_id
        );

        if (this.props.hmskCheckListReducer.hmskCheckListData) {
          this.setState({
            isCollbration: this.props.hmskCheckListReducer.hmskCheckListData
              .hmsk_template_collaboration
              ? true
              : false,
          });
        }

        if (
          this.props.hmskCheckListReducer.hmskCheckListData.answers &&
          this.props.hmskCheckListReducer.hmskCheckListData.answers.length > 0
        ) {
          this.setState({
            answers:
              this.props.hmskCheckListReducer.hmskCheckListData.answers.map(
                (answerData) => {
                  let images = [];
                  if (
                    answerData &&
                    answerData.images &&
                    answerData.images.length > 0
                  ) {
                    images = answerData.images.map((imageData) => {
                      return {
                        image_id: imageData.id,
                        image_url: imageData.url,
                        existing: true,
                      };
                    });
                  }
                  answerData["images"] = images;

                  if (answerData["answer_type"] === "date") {
                    answerData["answer_value"] = answerData["answer_value"]
                      ? moment(answerData["answer_value"])
                      : null;
                  } else if (answerData["answer_type"] === "date_time") {
                    answerData["answer_value"] = answerData["answer_value"]
                      ? moment(answerData["answer_value"])
                      : null;
                  } else if (answerData["answer_type"] === "users") {
                    if (answerData["answer_value"]) {
                      const ids = answerData["answer_value"].split(",");
                      const names = answerData["record_name"]
                        ? answerData["record_name"].split(",")
                        : [];
                      const userOptions = ids.map((user_id, index) => {
                        return {
                          label: names[index] ? names[index] : "",
                          value: user_id,
                        };
                      });
                      answerData["answer_value"] = userOptions;
                    }
                  } else if (answerData["answer_type"] === "item_types") {
                    if (answerData["answer_value"]) {
                      const ids = answerData["answer_value"].split(",");
                      const names = answerData["record_name"]
                        ? answerData["record_name"].split(",")
                        : [];
                      const userOptions = ids.map((item_id, index) => {
                        return {
                          label: names[index] ? names[index] : "",
                          value: item_id,
                        };
                      });
                      answerData["answer_value"] = userOptions;
                    }
                  }

                  return answerData;
                }
              ),
          });
        }
      }
    }
  };

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  onValueChange = (index, array, key, value) => {
    this.setState((prevState) => {
      let data = prevState[array];
      if (key) {
        if (!data[index]) {
          data[index] = {};
        }
        data[index][key] = value;
        data[index]["updated_by"] = this.state.userId;
        data[index]["date_time"] =
          moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      }
      return {
        [array]: data,
      };
    });
  };

  handleSaveButtonClick = async () => {
    const { hmskTemplateReducer } = this.props;

    const answers = this.state.answers.map((answerData) => {
      let images = [];
      const answerObj = { ...answerData };

      if (answerObj && answerObj.images && answerObj.images.length > 0) {
        images = answerObj.images.map((imageData) => {
          return imageData.image_id;
        });
      }
      answerObj["images"] = images;

      if (answerObj.answer_type && answerObj.answer_type === "users") {
        if (answerObj["answer_value"]) {
          const selectedUsers = answerObj["answer_value"];
          const user_ids = [];
          if (selectedUsers && selectedUsers.length) {
            for (let i = 0; i < selectedUsers.length; i++) {
              user_ids.push(selectedUsers[i].value);
            }
          }
          answerObj["answer_value"] = user_ids.join(",");
        } else {
          answerObj["answer_value"] = null;
        }
      } else if (
        answerObj.answer_type &&
        answerObj.answer_type === "item_types"
      ) {
        if (answerObj["answer_value"]) {
          const selectedItemTypes = answerObj["answer_value"];
          const item_type_ids = [];
          if (selectedItemTypes && selectedItemTypes.length) {
            for (let i = 0; i < selectedItemTypes.length; i++) {
              item_type_ids.push(selectedItemTypes[i].value);
            }
          }
          answerObj["answer_value"] = item_type_ids.join(",");
        } else {
          answerObj["answer_value"] = null;
        }
      } else if (answerObj.answer_type && answerObj.answer_type === "date") {
        answerObj["answer_value"] = answerObj["answer_value"]
          ? moment(answerObj["answer_value"]).format("YYYY-MM-DD")
          : null;
      } else if (
        answerObj.answer_type &&
        answerObj.answer_type === "date_time"
      ) {
        answerObj["answer_value"] = answerObj["answer_value"]
          ? moment(answerObj["answer_value"])
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z"
          : null;
      } else {
        answerObj["answer_value"] = answerObj["answer_value"]
          ? answerObj["answer_value"]
          : "";
      }

      return answerObj;
    });

    const data = {
      hmsk_template_id:
        hmskTemplateReducer.hmskTemplateData &&
        hmskTemplateReducer.hmskTemplateData.id,
      answers: answers,
      company_id:
        hmskTemplateReducer.hmskTemplateData &&
        hmskTemplateReducer.hmskTemplateData.company_id,
      project_id: this.state.project && this.state.project.value,
      task_id: this.state.task && this.state.task.value,
    };

    this.setState({ checkResponse: true });
    await this.props.actions.updateHmskCheckList(this.state.checkListId, data);
  };

  onDeleteButtonClick = () => {
    this.setState({
      openDeleteModal: true,
    });
  };

  deleteChecklist = async () => {
    this.setState({ checkResponse: true, openDeleteModal: false });
    await this.props.actions.deleteHmskCheckList(this.state.checkListId);
  };

  render = () => {
    const { classes, hmskTemplateReducer, ...others } = this.props;

    return !authActions.getAccessToken() ? (
      <Redirect to={`/token/${authActions.getCompanyToken()}`} />
    ) : (
      <React.Fragment>
        <CommonHeaderInSettings
          title={i18next.t("Checklist details")}
          haveBackButton={true}
          onBackClick={() => {
            // prevent reset filter on Checklist page
            this.props.actions.filterData("checkList", {
              referrer: "details-page",
            });
            this.props.history.goBack();
          }}
          haveFilter={false}
          buttonIcon={
            "M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
          }
          toggleCollapse={this.props.toggleCollapse}
        />
        {this.props.hmskCheckListReducer &&
          this.props.hmskCheckListReducer.hmskCheckListData && (
            <Typography component="div" className={classes.cardContainer}>
              <ListCard
                checkList={this.props.hmskCheckListReducer.hmskCheckListData}
                isDetailsPage={true}
                allowDelete={getItem("userRole") === "admin"}
                onDeleteButtonClick={this.onDeleteButtonClick}
                {...this.props}
              />
            </Typography>
          )}
        <Typography component="div" className={classes.listContainer}>
          {this.state.answers &&
            this.state.answers.length > 0 &&
            this.state.answers.map((answer, index) => {
              const question = {
                question: answer.template_question,
                answer_type: answer.answer_type,
              };

              return (
                <Typography key={index} component="div">
                  <QuestionAnswerBlock
                    disabled={!this.state.isCollbration}
                    changeState={this.changeState}
                    index={index}
                    question={question}
                    answers={this.state.answers}
                    onValueChange={this.onValueChange}
                    checkListId={this.state.checkListId}
                    {...others}
                  />
                </Typography>
              );
            })}
        </Typography>
        {this.state.isCollbration && (
          <Typography component="div" className={classes.buttonContainer}>
            <Button
              className={classes.saveButton}
              disabled={false}
              onClick={this.handleSaveButtonClick}
            >
              {i18next.t("Save")}
            </Button>
          </Typography>
        )}
        <DeleteModal
          isOpen={this.state.openDeleteModal}
          handleCancel={() => {
            this.setState({ openDeleteModal: false });
          }}
          handleSubmit={this.deleteChecklist}
          reducer={this.props.hmskCheckListReducer}
        />
        <OverlayIndicator
          isLoading={
            hmskTemplateReducer.isLoading ||
            this.props.hmskCheckListReducer.isLoading
          }
        />
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    hmskTemplateReducer: state.hmskTemplateReducer,
    hmskCheckListReducer: state.hmskCheckListReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(CheckListDetail)));
