//
import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import WeekRangeDatePicker from "@/common/WeekRangeDatePicker";
import CheckIn from "./CheckIn";
import i18next from "i18next";
import moment from "moment";

import { getItem } from "@/utils/localStorage";
const useStyles = makeStyles((theme) => ({
  gridBtnGroup: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    marginBottom: 8,
  },
  gridBtnGroup1: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    marginBottom: 8,
  },
  hiddenGridBtnGroup: {
    [theme.breakpoints.down("mlg")]: {
      display: "none",
    },
  },
  dropdownContainer: {
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
    minWidth: 280,
    borderRadius: 8,
    background: "none",
    "& input": {
      marginLeft: "38px !important",
      color: `${theme.palette.text.primary} !important`,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-between",
    verticalAlign: "middle",
    margin: "0 auto",
    height: 48,
    width: "100%",
  },
  btnIcon: {
    justifyContent: "center",
    minWidth: 20,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconSize: {
    fontSize: "2rem",
    color: theme.palette.text.primary,
  },
  weekRangeDatePicker: {
    display: "flex",
    justifyContent: "flex-end",
    verticalAlign: "middle",
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      minWidth: 140,
      "&:hover": {
        background: "none",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnToday: {
    fontSize: 16,
    fontWeight: 700,
    width: "100%",
    whiteSpace: "nowrap",
  },
  selectMenuItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 16,
  },
  selectInput: {
    display: "flex",
    padding: "0px 10px 0px 10px",
    minHeight: 48,
    height: "auto",
    fontSize: 14,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    "& input": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  viewContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 15,
    marginRight: 15,
  },
  /*selectPaper: {
    position: "absolute",
    overflow: "hidden",
    marginTop: theme.spacing(),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  userDropdown: {
    height: 48,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    alignItems: "center",
    background: "none",
    borderRadius: 8,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
}));

//
function AdditionalHourFilter(props) {
  const classes = useStyles();

  const { startDate, endDate, checkInModalOpen, allowCheckIn, handleCheckOut } =
    props;

  const isAdminUser = getItem("userRole") === "admin";

  const userFilterOnChange = async (selected) => {
    props.actions.filterData("hours", {
      userId: selected.value,
    });
    await props.changeState({
      selectedUser: selected,
      userId: selected.value,
    });
    await props.getDefaultUserValues(selected.value);
    props.checkFilter();
  };

  const selectPreviousWeek = () => {
    const { startDate, endDate } = props;
    //const { getHoursList } = this.props.taskActions;
    props.filterActions.changeFilter({
      startDate: moment(startDate)
        .clone()
        .subtract("1", "weeks")
        .startOf("week"),
      endDate: moment(endDate).clone().subtract("1", "weeks").endOf("week"),
    });
    /*let params = {
      week: moment(startDate)
        .clone()
        .subtract("1", "weeks")
        .startOf("week")
        .week(),
      year: moment(startDate)
        .clone()
        .subtract("1", "weeks")
        .startOf("week")
        .weekYear(),
    };
    getHoursList(params);*/
  };

  const onDateRangeChange = (dates) => {
    let { startDate, endDate } = props;

    // reset time to 00:00:00 to compare with date picker dates
    startDate = moment(startDate).startOf("day");
    endDate = moment(endDate).startOf("day");

    if (
      !moment(startDate).isSame(dates[0]) &&
      dates[1] &&
      !moment(endDate).isSame(dates[1])
    ) {
      props.filterActions.changeFilter({
        startDate: dates[0],
        endDate: dates[1] ? dates[1] : dates[0],
      });
      /*let params = {
        week: dates[0].clone().week(),
        year: dates[0].clone().weekYear(),
      };
      getHoursList(params);*/
    }
  };

  const selectNextWeek = () => {
    const { startDate, endDate } = props;
    //const { getHoursList } = this.props.taskActions;
    props.filterActions.changeFilter({
      startDate: moment(startDate).clone().add(1, "weeks").startOf("week"),
      endDate: moment(endDate).clone().add(1, "weeks").endOf("week"),
    });
    /*let params = {
      week: moment(startDate).clone().add(1, "weeks").startOf("week").week(),
      year: moment(startDate)
        .clone()
        .add(1, "weeks")
        .startOf("week")
        .weekYear(),
    };
    getHoursList(params);*/
  };

  const todayHrs = () => {
    props.filterActions.changeFilter({
      startDate: moment().clone().startOf("week"),
      endDate: moment().clone().endOf("week"),
    });
    /*const { getHoursList } = this.props.taskActions;
    let params = {
      week: moment().clone().week(),
      year: moment().clone().weekYear(),
    };
    getHoursList(params);*/
  };

  const handleLeaveModal = () => {
    props.handleLeaveModalOpen();
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        {isAdminUser ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={3}
            className={classes.gridBtnGroup}
          >
            <div className={classes.dropdownContainer}>
              <MaterialAsyncPaginate
                classes={classes}
                textFieldProps={{
                  label: false,
                  InputLabelProps: {
                    shrink: false,
                  },
                }}
                loadOptions={async (search, loadedOptions) => {
                  return props.loadUserList(search, loadedOptions);
                }}
                value={props.selectedUser}
                onChange={userFilterOnChange}
                variant="outlined"
              />
            </div>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={3}
            className={classes.hiddenGridBtnGroup}
          ></Grid>
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={3}
          className={classes.gridBtnGroup1}
        >
          <Typography component="div" className={classes.btnGroup}>
            <Button className={classes.btnIcon} onClick={selectPreviousWeek}>
              <ChevronLeftIcon className={classes.iconSize} />
            </Button>
            <div className={classes.weekRangeDatePicker}>
              <WeekRangeDatePicker
                value={[startDate, endDate]}
                onChangeDate={(dates) => {
                  if (dates && dates.length) {
                    onDateRangeChange(dates);
                  }
                }}
              />
            </div>
            <Button className={classes.btnIcon} onClick={selectNextWeek}>
              <ChevronRightIcon className={classes.iconSize} />
            </Button>
            <Button
              className={classes.btnToday}
              fullWidth
              onClick={todayHrs}
              id="hour-view-today-button"
            >
              {i18next.t("Today")}
            </Button>
          </Typography>
        </Grid>

        <div className={classes.flexGrow} />

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={3}
          className={classes.gridBtnGroup}
        >
          <Typography component="div" className={classes.btnGroup}>
            <Button
              variant="outlined"
              className={classes.btnToday}
              fullWidth
              nowrap="true"
              onClick={handleLeaveModal}
              id="request-leave-button"
            >
              {i18next.t("Request leave")}
            </Button>
            <CheckIn
              checkInModalOpen={checkInModalOpen}
              allowCheckIn={allowCheckIn}
              handleCheckOut={handleCheckOut}
            />
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AdditionalHourFilter;
