import React, { Fragment } from "react";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import { Grid, FormControl } from "@mui/material";
import { MaterialTimePicker } from "@/common/Pickers";
import moment from "moment";

import defaultStyles from "./styles";

const styles = (theme) => ({
  ...defaultStyles(theme),
  textField: {
    padding: "0px",
    height: "50px",
    width: "130px",
  },
  timePicker: {
    "& .MuiInputLabel-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-input": {
      color: theme.palette.text.primary,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
    },
  },
});

class TaskDuration extends React.Component {
  constructor(props) {
    super(props);

    let tempStartTime = new Date();
    let tempEndTime = new Date();

    if (this.props.type === "CheckIn") {
      tempStartTime = new Date(
        tempStartTime.setHours(props.customProps.state.startTime.split(":")[0])
      );
      tempStartTime = new Date(
        tempStartTime.setMinutes(
          props.customProps.state.startTime.split(":")[1]
        )
      );

      tempEndTime = new Date(
        tempEndTime.setHours(props.customProps.state.endTime.split(":")[0])
      );
      tempEndTime = new Date(
        tempEndTime.setMinutes(props.customProps.state.endTime.split(":")[1])
      );
    } else if (this.props.type === "Lunch") {
      tempStartTime = new Date(
        tempStartTime.setHours(
          props.customProps.state.lunchStartTime.split(":")[0]
        )
      );
      tempStartTime = new Date(
        tempStartTime.setMinutes(
          props.customProps.state.lunchStartTime.split(":")[1]
        )
      );

      tempEndTime = new Date(
        tempEndTime.setHours(props.customProps.state.lunchEndTime.split(":")[0])
      );
      tempEndTime = new Date(
        tempEndTime.setMinutes(
          props.customProps.state.lunchEndTime.split(":")[1]
        )
      );
    }

    this.state = {
      startTimeError: false,
      endTimeError: false,
      hours: `${
        parseInt(props.customProps.state.hours, 10)
          ? parseInt(props.customProps.state.hours, 10)
          : ""
      }`,
      minutes: `${
        parseInt(props.customProps.state.minutes, 10)
          ? parseInt(props.customProps.state.minutes, 10)
          : ""
      }`,
      startTime: moment(tempStartTime),
      endTime: moment(tempEndTime),
      hoursError: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { lunchStartTime, lunchEndTime } = this.props.customProps.state;
    const prevLunchStart = prevProps?.customProps?.state?.lunchStartTime;
    const prevLunchEnd = prevProps?.customProps?.state?.lunchEndTime;

    // check if lunch time changed
    if (
      (prevLunchStart !== lunchStartTime || prevLunchEnd !== lunchEndTime) &&
      this.props.type === "Lunch"
    ) {
      // reset local lunch values only if the parent lunch time becomes 00:00
      if (lunchStartTime === "00:00" && lunchEndTime === "00:00") {
        let tempStartTime = new Date();
        let tempEndTime = new Date();

        tempStartTime = new Date(
          tempStartTime.setHours(lunchStartTime.split(":")[0])
        );
        tempStartTime = new Date(
          tempStartTime.setMinutes(lunchStartTime.split(":")[1])
        );
        tempEndTime = new Date(
          tempEndTime.setHours(lunchEndTime.split(":")[0])
        );
        tempEndTime = new Date(
          tempEndTime.setMinutes(lunchEndTime.split(":")[1])
        );

        this.setState({
          startTime: moment(tempStartTime),
          endTime: moment(tempEndTime),
        });
      }
    }
  };

  render = () => {
    const { customProps, classes, isDisabled, endTimeDisabled } = this.props;
    const { startTime, endTime } = this.state;

    return (
      <Fragment>
        {this.props.type === "CheckIn" ? (
          <Grid item container xs={12}>
            <Grid item xs={6} id="start-time-input">
              <FormControl
                style={{ marginRight: 30 }}
                className={classes.timePicker}
              >
                <MaterialTimePicker
                  label={i18next.t("Start time")}
                  disabled={isDisabled ? true : false}
                  error={this.state.startTimeError}
                  variant="outlined"
                  value={startTime}
                  onChangeTime={(date) => {
                    this.setState({
                      startTime: date,
                    });
                    if (moment(date).isValid()) {
                      customProps.changeState({
                        startTime: moment(date).format("HH:mm"),
                        durationType: "CheckIn",
                      });
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} id="end-time-input">
              <FormControl
                style={{ marginLeft: 30 }}
                className={classes.timePicker}
              >
                <MaterialTimePicker
                  label={i18next.t("End time")}
                  disabled={isDisabled || endTimeDisabled ? true : false}
                  error={this.state.endTimeError}
                  variant="outlined"
                  value={endTime}
                  onChangeTime={(date) => {
                    this.setState({
                      endTime: date,
                    });
                    if (moment(date).isValid()) {
                      customProps.changeState({
                        endTime: moment(date).format("HH:mm"),
                        durationType: "CheckIn",
                      });
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : this.props.type === "Lunch" ? (
          <Grid item container xs={12}>
            <Grid item xs={6} id="lunch-start-time-input">
              <FormControl
                style={{ marginRight: 30 }}
                className={classes.timePicker}
              >
                <MaterialTimePicker
                  label={i18next.t("Start time")}
                  disabled={isDisabled ? true : false}
                  error={this.state.startTimeError}
                  variant="outlined"
                  value={startTime}
                  onChangeTime={(date) => {
                    this.setState({
                      startTime: date,
                    });
                    if (moment(date).isValid()) {
                      customProps.changeState({
                        lunchStartTime: moment(date).format("HH:mm"),
                      });
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} id="lunch-end-time-input">
              <FormControl
                style={{ marginLeft: 30 }}
                className={classes.timePicker}
              >
                <MaterialTimePicker
                  label={i18next.t("End time")}
                  disabled={isDisabled ? true : false}
                  error={this.state.endTimeError}
                  variant="outlined"
                  value={endTime}
                  onChangeTime={(date) => {
                    this.setState({
                      endTime: date,
                    });
                    if (moment(date).isValid()) {
                      customProps.changeState({
                        lunchEndTime: moment(date).format("HH:mm"),
                      });
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
      </Fragment>
    );
  };
}
export default withStyles(styles)(TaskDuration);
