import i18next from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import language_en from "../translations/en.json";
import language_no from "../translations/no.json";
import language_de from "../translations/de.json";
import language_ro from "../translations/ro.json";
import language_sv from "../translations/sv.json";
import language_da from "../translations/da.json";
import language_fr from "../translations/fr.json";
import language_th from "../translations/th.json";

const detectionOptions = {
  order: ["localStorage", "navigator"],
  lookupLocalStorage: "language",
  caches: [],
};

i18next.use(LngDetector).init({
  returnEmptyString: false,
  resources: {
    en: {
      translation: language_en,
    },
    nb: {
      translation: language_no,
    },
    nn: {
      translation: language_no,
    },
    no: {
      translation: language_no,
    },
    se: {
      translation: language_no,
    },
    "nn-NO": {
      translation: language_no,
    },
    "nb-NO": {
      translation: language_no,
    },
    "nb-SJ": {
      translation: language_no,
    },
    "se-NO": {
      translation: language_no,
    },
    de: {
      translation: language_de,
    },
    ro: {
      translation: language_ro,
    },
    sv: {
      translation: language_sv,
    },
    da: {
      translation: language_da,
    },
    fr: {
      translation: language_fr,
    },
    th: {
      translation: language_th,
    },
  },
  detection: detectionOptions,
  fallbackLng: "en",
  keySeparator: false,
});

export default i18next;
