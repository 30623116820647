import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { localStorageReducer } from "./reducers/localStorage";
import * as Sentry from "@sentry/react";
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const initialState = {};
const enhancers = [sentryReduxEnhancer];
const middleware = [thunk];

if (import.meta.env.DEV) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(
      devToolsExtension({
        name: "vrs-ipad-localstorage-store",
        instanceId: "vrs-ipad-localstorage-store",
      })
    );
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(localStorageReducer, initialState, composedEnhancers);

export default store;
