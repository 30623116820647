//
import React from "react";
import { MaterialTimePicker } from "@/common/Pickers";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Grid,
  FormControl,
  TextField,
  Collapse,
  Switch,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import MultiSelectDatePicker from "@/common/MultiSelectDatePicker";
import CalendarDateRangePicker from "@/common/CalendarDateRangePicker";
import moment from "moment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

import { getItem } from "@/utils/localStorage";
import {
  getDateFormat,
  enumerateDaysBetweenDates,
} from "@/utils/dateTimeFunctions";

const styles = (theme) => ({
  header: {
    padding: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  planHeading: {
    marginBottom: 12,
  },
  mb1: {
    marginBottom: "1rem",
  },
  mb2: {
    marginBottom: "2rem",
  },
  selectInput: {
    display: "flex",
    height: "auto",
    padding: 12,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    "& input": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  singleValue: {
    maxWidth: "calc(100% - 54px)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginLeft: 2,
    position: "absolute",
    textOverflow: "ellipsis",
    color: theme.palette.text.primary,
  },
  flexBlock: {
    display: "flex",
    alignItems: "center",
  },
  totalHoursBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 4px",
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  svgIcon: {
    height: 20,
    width: 20,
    marginRight: 8,
  },
  svgPrimary: {
    color: theme.palette.primary.main,
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: "16px 24px 16px 24px !important",
    [theme.breakpoints.up("sm")]: {
      padding: "16px 24px 16px 24px !important",
    },
  },
  btnCancel: {
    padding: "16px 26px",
    fontSize: 16,
    fontWeight: 500,
  },
  checkInButton: {
    fontSize: 16,
    fontWeight: 700,
    padding: "16px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 60px !important",
    },
  },
  hiddenButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class LeaveRequestModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startTime: null,
      endTime: null,
      lunchStartTime: null,
      lunchEndTime: null,
      showLunchTime: false,
      dates: [null, null],
      selectedDate: null,
      isDateError: false,
      disableLunch: false,
      isDateRangePicker: false,
      ignoreWeekEnds: true,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { state } = this.props;
    if (prevProps.state.openLeaveModal !== state.openLeaveModal) {
      if (state.openLeaveModal) {
        this.setDefaultValues();
      }

      // Autofill lunch time: only if Add modal, default start time
      if (state.startTime) {
        // autofill lunch input present
        if (state.isAutoFillLunch) {
          const standardLunchTime =
            getItem("userRole") === "admin"
              ? state.adminLunchTime
              : state.userLunchTime;

          if (state.autoFillLunchAmount > 0 && standardLunchTime > 0) {
            const checkInStart = moment(state.startTime, "HH:mm");
            const timeDeference = moment(state.endTime, "HH:mm").diff(
              checkInStart
            );
            const amount = moment.duration(timeDeference).asHours();
            const totalLunchTimeAmount =
              parseFloat(state.autoFillLunchAmount) +
              parseFloat(standardLunchTime);

            if (Math.abs(amount) > totalLunchTimeAmount) {
              const tempStartTime = moment(checkInStart).add(
                state.autoFillLunchAmount,
                "hours"
              );
              const tempEndTime = moment(checkInStart).add(
                totalLunchTimeAmount,
                "hours"
              );
              this.props.changeState({
                lunchStartTime: moment(tempStartTime, "HH:mm"),
                lunchEndTime: moment(tempEndTime, "HH:mm"),
              });
              this.isRestrictEditLunch();
            }
          }
        }
      }
    }
  };

  isRestrictEditLunch = () => {
    const { state } = this.props;

    // In case auto fill lunch value present and following setting are ON, disable lunch section
    if (state.restrictLunchEditIfAutofill) {
      this.setState({ disableLunch: true });
    }
  };

  setDefaultValues = () => {
    let tempStartTime = new Date();
    let tempEndTime = new Date();

    const { startTime, endTime } = this.props.state;

    tempStartTime = new Date(tempStartTime.setHours(startTime.split(":")[0]));
    tempStartTime = new Date(tempStartTime.setMinutes(startTime.split(":")[1]));

    tempEndTime = new Date(tempEndTime.setHours(endTime.split(":")[0]));
    tempEndTime = new Date(tempEndTime.setMinutes(endTime.split(":")[1]));

    this.setState({
      startTime: moment(tempStartTime),
      endTime: moment(tempEndTime),
    });
  };

  handleCancel = () => {
    this.setState({
      startTime: null,
      endTime: null,
      lunchEndTime: "00:00",
      lunchStartTime: "00:00",
      comment: null,
      showLunchTime: false,
      dates: [null, null],
      selectedDate: null,
      disableLunch: false,
      isDateRangePicker: false,
      ignoreWeekEnds: true,
    });
    this.props.handleLeaveModalCancel();
  };

  handleExpand = () => {
    this.setState({
      showLunchTime: !this.state.showLunchTime,
    });
    this.setDefaultValues();
  };

  loadTaskOptions = async (search, loadedOptions) => {
    let limit = 20;
    let offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;
    let forLeave = true;

    const result = await this.props.getTaskOptions(
      limit,
      offset,
      search,
      forLeave
    );
    return result;
  };

  onDateStatusChange = (status) => {
    this.setState({
      isDateError: status ? true : false,
    });
  };

  onDateRangeChange = (dates) => {
    let selectedDates = enumerateDaysBetweenDates(
      dates[0],
      dates[1],
      this.state.ignoreWeekEnds
    );

    if (selectedDates.length === 0) {
      this.setState({
        isDateError: true,
      });
    } else {
      this.setState({
        isDateError: false,
        selectedDate: selectedDates[0], // consider 1st date as current selected date
      });

      // get repeat dates by exluding 1st date
      const repeatDates = selectedDates.filter((_, index) => {
        return index !== 0;
      });

      this.props.changeState({
        repeat_Dates: repeatDates,
      });
    }
  };

  onSubmit = async () => {
    const { onSubmit } = this.props;
    this.handleCancel();
    onSubmit(this.state.selectedDate);
  };

  handleDatePickerSwitch = () => {
    this.setState({
      isDateRangePicker: !this.state.isDateRangePicker,
      dates: [null, null],
      selectedDate: null,
      ignoreWeekEnds: true,
    });
  };

  handleWeekEndsSwitch = () => {
    this.setState({
      ignoreWeekEnds: !this.state.ignoreWeekEnds,
    });
  };

  isWeekend = (date) => {
    const day = date.day();

    return day === 0 || day === 6;
  };

  render() {
    const { classes, state } = this.props;
    const {
      startTime,
      endTime,
      showLunchTime,
      disableLunch,
      isDateRangePicker,
      ignoreWeekEnds,
    } = this.state;

    let checkInStart = null;
    let timeDeference = null;
    if (moment(state.startTime, "HH:mm") > moment(state.endTime, "HH:mm")) {
      checkInStart = moment(startTime, "HH:mm");
      timeDeference = moment(
        moment(endTime, "HH:mm").add(24, "hours"),
        "HH:mm"
      ).diff(checkInStart);
    } else {
      checkInStart = moment(startTime, "HH:mm");
      timeDeference = moment(endTime, "HH:mm").diff(checkInStart);
    }

    let totalDiff = null;

    if (state.lunchStartTime && state.lunchEndTime) {
      const lunchStart = moment(state.lunchStartTime, "HH:mm");
      const lunchDiff = moment(state.lunchEndTime, "HH:mm").diff(lunchStart);
      totalDiff = timeDeference - lunchDiff;
    } else {
      totalDiff = timeDeference;
    }

    const amount =
      (state.repeat_Dates?.length + 1) * moment.duration(totalDiff).asHours();

    return (
      <Dialog
        sx={{
          borderRadius: 16,
        }}
        fullWidth={true}
        maxWidth={"sm"}
        open={state.openLeaveModal}
        onClose={this.handleCancel}
      >
        <DialogTitle
          onClose={this.handleCancel}
          className={classes.header}
          id="check-in-dialog-title"
        >
          {i18next.t("Request leave")}
          <IconButton
            aria-label={i18next.t("Close")}
            className={classes.closeButton}
            onClick={this.handleCancel}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: "0 34px 0 34px" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={classes.root}
          >
            <React.Fragment>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={classes.planHeading}
                    id="check-in-dialog-planned-hours-block-title"
                  >
                    {i18next.t("Leave reason")}
                  </Typography>

                  <Grid container spacing={2} className={classes.mb2}>
                    <Grid item xs={12} id="project-task-modal-task-container">
                      <MaterialAsyncPaginate
                        classes={classes}
                        variant="outlined"
                        textFieldProps={{
                          label: state.selectedTask ? i18next.t("Task") : false,
                          InputLabelProps: {
                            shrink: state.selectedTask ? true : false,
                          },
                        }}
                        onBlur={() => {
                          if (!state.selectedTask) {
                            this.setState({ taskRequired: true });
                          } else {
                            this.setState({ taskRequired: false });
                          }
                        }}
                        loadOptions={this.loadTaskOptions}
                        value={state.selectedTask}
                        onChange={async (selected) => {
                          await this.props.changeState({
                            selectedTask: selected,
                          });
                        }}
                        id="leave-reason-modal-reason-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.mb2}>
                    <Grid
                      item
                      xs={12}
                      id="leave-request-modal-date-selection-toggle"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <FormControlLabel
                          value="toggle"
                          control={
                            <Switch
                              checked={isDateRangePicker}
                              onChange={this.handleDatePickerSwitch}
                              color="primary"
                              size="small"
                            />
                          }
                          label={i18next.t("Select date range")}
                          id="leave-request-modal-switch"
                        />
                        {isDateRangePicker && (
                          <FormControlLabel
                            value="toggle"
                            control={
                              <Switch
                                checked={ignoreWeekEnds}
                                onChange={this.handleWeekEndsSwitch}
                                color="primary"
                                size="small"
                              />
                            }
                            label={i18next.t("Ignore weekend")}
                            style={{ marginRight: 0 }}
                            id="leave-request-modal-switch-weekends"
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} id="leave-modal-datepicker">
                      {!isDateRangePicker ? (
                        <MultiSelectDatePicker
                          selectedDate={this.state.selectedDate}
                          onChange={(repeatDates) => {
                            this.props.changeState({
                              repeat_Dates: repeatDates,
                            });
                          }}
                          isDefaultDateEditable={true}
                          changeSelectedDate={(date) => {
                            this.setState({
                              selectedDate: moment(date).clone().toDate(),
                            });
                          }}
                          isDateError={this.onDateStatusChange}
                        />
                      ) : (
                        <CalendarDateRangePicker
                          variant="outlined"
                          label={i18next.t("Dates")}
                          dates={this.state.dates}
                          onChangeDate={(dates) => {
                            this.setState({ dates: dates }, () => {
                              this.onDateRangeChange(dates);
                            });
                          }}
                          shouldDisableDate={
                            ignoreWeekEnds ? this.isWeekend : null
                          }
                          format={getDateFormat()}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Typography
                    variant="body1"
                    className={classes.planHeading}
                    id="check-in-dialog-planned-hours-block-title"
                  >
                    {i18next.t("Duration")}
                  </Typography>
                  <Grid item container xs={12} className={classes.mb1}>
                    <Grid item xs={6} id="start-time-input">
                      <FormControl
                        style={{ marginRight: 16 }}
                        className={classes.timePicker}
                      >
                        <MaterialTimePicker
                          label={i18next.t("Start time")}
                          variant="outlined"
                          value={startTime}
                          onChangeTime={(date) => {
                            this.setState({
                              startTime: date,
                            });
                            if (moment(date).isValid()) {
                              this.props.changeState({
                                startTime: moment(date).format("HH:mm"),
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} id="end-time-input">
                      <FormControl
                        style={{ marginLeft: 16 }}
                        className={classes.timePicker}
                      >
                        <MaterialTimePicker
                          label={i18next.t("End time")}
                          variant="outlined"
                          value={endTime}
                          onChangeTime={(date) => {
                            this.setState({
                              endTime: date,
                            });
                            if (moment(date).isValid()) {
                              this.props.changeState({
                                endTime: moment(date).format("HH:mm"),
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Typography
                    component="div"
                    className={`${classes.mb1} ${classes.lunchBlock}`}
                  >
                    <IconButton
                      className={classes.expandIcon}
                      onClick={this.handleExpand}
                      aria-label="expand row"
                      size="small"
                    >
                      {showLunchTime ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    <Typography
                      variant="body1"
                      id="check-in-dialog-planned-hours-block-title"
                    >
                      {i18next.t("Lunch Time")}
                    </Typography>
                  </Typography>
                  <Typography component="div">
                    <Collapse in={showLunchTime} timeout="auto" unmountOnExit>
                      <Typography component="div" className={classes.mb2}>
                        <Grid item container xs={12}>
                          <Grid item xs={6} id="start-time-input">
                            <FormControl
                              style={{ marginRight: 16 }}
                              className={classes.timePicker}
                            >
                              <MaterialTimePicker
                                label={i18next.t("Start time")}
                                variant="outlined"
                                value={moment(state.lunchStartTime, "HH:mm")}
                                onChangeTime={(date) => {
                                  this.setState({
                                    lunchStartTime: date,
                                  });
                                  if (moment(date).isValid()) {
                                    this.props.changeState({
                                      lunchStartTime:
                                        moment(date).format("HH:mm"),
                                    });
                                  }
                                }}
                                disabled={disableLunch ? true : false}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} id="end-time-input">
                            <FormControl
                              style={{ marginLeft: 16 }}
                              className={classes.timePicker}
                            >
                              <MaterialTimePicker
                                label={i18next.t("End time")}
                                variant="outlined"
                                value={moment(state.lunchEndTime, "HH:mm")}
                                onChangeTime={(date) => {
                                  this.setState({
                                    lunchEndTime: date,
                                  });
                                  if (moment(date).isValid()) {
                                    this.props.changeState({
                                      lunchEndTime:
                                        moment(date).format("HH:mm"),
                                    });
                                  }
                                }}
                                disabled={disableLunch ? true : false}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Collapse>
                  </Typography>

                  <div className={`${classes.totalHoursBlock} ${classes.mb2}`}>
                    <div className={classes.flexBlock}>
                      <WatchLaterIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginRight: "8px",
                          color: "primary.main",
                        }}
                      />
                      <Typography
                        component="span"
                        variant="body1"
                        id="check-in-dialog-planned-hours-block-title"
                      >
                        {i18next.t("Total hours")}
                      </Typography>
                    </div>
                    <Typography component="div">
                      <DecimalToHoursMin>{amount}</DecimalToHoursMin>
                    </Typography>
                  </div>

                  <Grid
                    item
                    xs={12}
                    id="input-hour-modal-comment"
                    className={classes.mb2}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        label={i18next.t("Comment")}
                        type="text"
                        size="normal"
                        placeholder={i18next.t("Type your comment here")}
                        value={state.comment}
                        style={{ width: "100%" }}
                        variant="outlined"
                        multiline
                        maxRows={4}
                        onChange={(e) => {
                          this.props.changeState({
                            comment: e.target.value,
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
            <button type="submit" style={{ display: "none" }} />
          </form>
        </DialogContent>

        <DialogActions
          className={classes.actionButtons}
          style={
            state.editId
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                }
              : {}
          }
        >
          <Button
            className={classes.btnCancel}
            onClick={this.handleCancel}
            size="large"
            id="check-in-dialog-cancel-button"
          >
            {i18next.t("Cancel")}
          </Button>
          <Button
            disabled={
              !state.selectedTask ||
              (!state.startTime && !state.endTime) ||
              !this.state.selectedDate ||
              this.state.isDateError
            }
            type="submit"
            className={classes.checkInButton}
            variant="contained"
            size="large"
            onClick={this.onSubmit}
            id="check-in-dialog-start-button"
          >
            {i18next.t("Submit")}
          </Button>
          <Typography
            component="div"
            className={classes.hiddenButton}
            style={{ minWidth: 100 }}
          >
            {""}
          </Typography>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(LeaveRequestModal);
