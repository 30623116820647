import React from "react";
import { TextField, MenuItem, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  selectInput: {
    display: "flex",
    padding: "8px 16px",
    height: "auto",
    minHeight: "40px",
  },
  valueContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    fontSize: 16,
    overflow: "hidden",
    paddingRight: 5,
    "& input": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  indicatorSeparator: {
    width: 0,
  },
  dropdownIndicator: {
    display: "flex",
  },
  singleValue: {
    maxWidth: "calc(100% - 32px)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginLeft: 2,
    position: "absolute",
    textOverflow: "ellipsis",
    color: theme.palette.text.primary,
  },
}));

const inputComponent = React.forwardRef(function inputComponent(props) {
  const { inputRef, ...other } = props;
  return <div {...other} ref={inputRef} />;
});

export const Control = (props) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth={true}
      error={props.selectProps.error}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props?.selectProps?.classes?.selectInput
            ? props.selectProps.classes.selectInput
            : classes?.selectInput
              ? classes.selectInput
              : "",
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      variant="standard"
      {...props.selectProps.textFieldProps}
    />
  );
};

export const OutlinedControl = (props) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth={true}
      error={props.selectProps.error}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props?.selectProps?.classes?.selectInput
            ? props.selectProps.classes.selectInput
            : classes?.selectInput
              ? classes.selectInput
              : "",
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      variant="outlined"
      {...props.selectProps.textFieldProps}
    />
  );
};

export function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props?.selectProps?.classes?.selectMenuItem || ""}
      sx={{
        fontWeight: props.isSelected ? 600 : 400,
        ...(props.isDisabled && {
          color: "#ccc",
          cursor: "not-allowed",
        }),
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

export function Menu(props) {
  return (
    <Paper
      elevation={2}
      square
      className={props?.selectProps?.classes?.selectPaper || ""}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

export const ValueContainer = (props) => {
  const classes = useStyles();

  return (
    <div
      className={
        props?.selectProps?.classes?.valueContainer
          ? props.selectProps.classes.valueContainer
          : classes?.valueContainer
            ? classes.valueContainer
            : ""
      }
    >
      {props.children}
    </div>
  );
};

export const SingleValue = (props) => {
  const classes = useStyles();

  return (
    <Typography
      component="div"
      className={
        props?.selectProps?.classes?.singleValue || classes.singleValue
      }
      {...props.innerProps}
    >
      {props?.children?.props?.primary || props.children}
    </Typography>
  );
};

export const DropdownIndicator = (props) => {
  const classes = useStyles();

  return (
    <div
      className={
        props?.selectProps?.classes?.dropdownIndicator
          ? props.selectProps.classes.dropdownIndicator
          : classes?.dropdownIndicator
            ? classes.dropdownIndicator
            : ""
      }
      {...props.innerProps}
    >
      <ExpandMoreIcon />
    </div>
  );
};

export const IndicatorSeparator = (props) => {
  const classes = useStyles();

  return (
    <span
      className={
        props?.selectProps?.classes?.indicatorSeparator
          ? props.selectProps.classes.indicatorSeparator
          : classes?.indicatorSeparator
            ? classes.indicatorSeparator
            : ""
      }
      {...props.innerProps}
    />
  );
};
