import React, { useEffect, useState } from "react";
import {
  validateCompanyTokenApi,
  getStripeSubscriptionApi,
} from "@/services/signin";
import { actions as authActions } from "@/utils/auth";
import i18next from "i18next";
import { kioskCheckIn } from "@/services/employee";
import KioskPage from "./components/KioskPage";

const KioskMode = (props) => {
  const { match } = props;
  // eslint-disable-next-line
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    // setErrorMsg("");

    const validateCompanyToken = async (token) => {
      try {
        const response = await validateCompanyTokenApi(token);
        if (response.data && response.data.id) {
          authActions.saveCompanyToken(token);

          // check subscription status
          if (response.data.stripe_subscription_id) {
            checkSubscriptionStatus(token);
          } else {
            // remove any previous status set, prevent issue on another account login in same tab
            sessionStorage.removeItem("status");
          }
          sessionStorage.setItem("companyId", response.data.id);
          //setCompanyStatus(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    // http://localhost:3003/token/30d08b19d9984
    if (match && match.params.token) {
      validateCompanyToken(match.params.token);
    } else {
      console.log(i18next.t("Company token not found"));
    }
    // eslint-disable-next-line
  }, [match]);

  const checkSubscriptionStatus = async (token) => {
    try {
      const response = await getStripeSubscriptionApi(token);
      if (response.data && response.data.status) {
        if (
          response.data.status !== "active" &&
          response.data.status !== "trialing"
        ) {
          setErrorMsg(i18next.t("Your subscription is expired"));
          sessionStorage.setItem("status", "expired");
        } else {
          sessionStorage.removeItem("status");
        }
      }
    } catch (error) {
      setErrorMsg(i18next.t("Your subscription is expired"));
      sessionStorage.setItem("status", "expired");
    }
  };

  const handleCheckin = async (code) => kioskCheckIn(code);

  return <KioskPage onCheckin={handleCheckin} />;
};

export default KioskMode;
