import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { StyledEngineProvider } from "@mui/material/styles";
import moment from "moment";
import { getUserAttendance } from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";
import { getDateWithFixedTimeFormat } from "@/utils/dateTimeFunctions";

import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    marginTop: "0",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
    borderRadius: 16,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
    paddingTop: 12,
    paddingBottom: 16,
  },
  cardHeader: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingBottom: 12,
  },
  attendanceTable: {
    // height: 248,
    // height: "100%",
  },
  smallText: {
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  customDate: {
    fontSize: 16,
    color: theme.palette.text.disabled,
  },
  customTableRow: {
    "& .MuiTableCell-root": {
      paddingTop: 12,
      paddingBottom: 0,
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  checkInButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 24,
  },
  button: {
    fontSize: 16,
    fontWeight: 700,
    padding: "14px 16px",
    borderRadius: "0.5rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
    minWidth: 170,
  },
  checkInButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "#00000000",
    },
  },
}));

const RecentCheckinTable = (props) => {
  const { userId } = props;

  const classes = useStyles();

  const [attendanceList, setAttendanceList] = useState([]);

  const dateTimeFormat = getDateWithFixedTimeFormat();

  const getRecentUserAttendanceList = async (queryString) => {
    try {
      const response = await getUserAttendance(queryString);
      if (response && response.data && response.data.entries) {
        let attendances = [];
        let element = null;

        for (let i = 0; i < response.data.entries.length; i++) {
          element = response.data.entries[i];

          let check_in_time = "";
          let check_out_time = "";
          let duration = "";

          for (let j = 0; j < element.user_attendances.length; j++) {
            const attendanceElement = element.user_attendances[j];

            if (attendanceElement.is_utc) {
              check_in_time = attendanceElement.start_time
                ? moment(attendanceElement.start_time).format(dateTimeFormat)
                : "-";

              check_out_time = attendanceElement.end_time
                ? moment(attendanceElement.end_time).format(dateTimeFormat)
                : "-";
            } else {
              check_in_time = attendanceElement.start_time
                ? moment(attendanceElement.start_time)
                    .utc()
                    .format(dateTimeFormat)
                : "-";

              check_out_time = attendanceElement.end_time
                ? moment(attendanceElement.end_time)
                    .utc()
                    .format(dateTimeFormat)
                : "-";
            }

            if (attendanceElement.end_time) {
              let startTime = null;
              let endTime = null;
              if (attendanceElement.is_utc) {
                startTime = moment(attendanceElement.start_time).format(
                  "YYYY-MM-DDTHH:mm:ss.SSS"
                );
                endTime = moment(attendanceElement.end_time).format(
                  "YYYY-MM-DDTHH:mm:ss.SSS"
                );
              } else {
                startTime = moment(attendanceElement.start_time)
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss.SSS");
                endTime = moment(attendanceElement.end_time)
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss.SSS");
              }

              const timeDeference = moment(endTime).diff(moment(startTime));
              const timeDuration = moment.duration(timeDeference);
              duration = `${timeDuration.hours()}h ${timeDuration.minutes()}m`;
            } else {
              duration = "";
            }

            attendances.push({
              check_in: check_in_time,
              check_out: check_out_time,
              duration: duration,
            });
          }
        }

        const groups = attendances.reduce((groups, entries) => {
          const date = moment(entries.check_in, dateTimeFormat).format(
            "ddd DD, MMMM"
          );

          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(entries);
          return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            entries: groups[date],
          };
        });

        setAttendanceList(groupArrays);
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      console.log(message);
    }
  };

  useEffect(() => {
    const queryString = `limit=10&single_user=true&user_id=${userId}&attendanceable_type=User`;

    getRecentUserAttendanceList(queryString);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <>
      <CardHeader
        title={i18next.t("Recent check-ins")}
        className={classes.cardHeader}
        titleTypographyProps={{
          fontSize: 20,
        }}
      />
      <Card className={classes.root}>
        <StyledEngineProvider injectFirst>
          <TableContainer
            className={classes.attendanceTable}
            id="attendanceListDiv"
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableRow}>
                    {i18next.t("Check-in")}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    {i18next.t("Check-out")}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    {i18next.t("Duration")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceList.map((attendance, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow className={classes.customTableRow}>
                        {attendance && attendance.date && (
                          <TableCell className={classes.customDate} colSpan={3}>
                            {attendance.date}
                          </TableCell>
                        )}
                      </TableRow>
                      {attendance.entries.map((entries) => (
                        <TableRow
                          key={entries.check_in}
                          className={classes.tableRow}
                        >
                          <TableCell className={classes.smallText}>
                            {entries && entries.check_in
                              ? moment(entries.check_in, dateTimeFormat).format(
                                  "HH:mm"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.smallText}>
                            {entries &&
                            entries.check_out &&
                            entries.check_out !== "-"
                              ? moment(
                                  entries.check_out,
                                  dateTimeFormat
                                ).format("HH:mm")
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.smallText}>
                            {entries.duration}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  );
                })}

                {attendanceList && attendanceList.length < 1 && (
                  <TableRow className={classes.tableRow} hover>
                    <TableCell colSpan={3} align="center">
                      {i18next.t("No data to display")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledEngineProvider>
      </Card>
    </>
  );
};

export default RecentCheckinTable;
