import React, { Fragment } from "react";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { CommonHeaderInSettings } from "@/common/CommonHeaderInList";
import OwnCheckList from "./components/ownCheckList";
import QuestionAnswerList from "./components/questionAnswerList";
import { connect } from "react-redux";
import { ActionCreators } from "@/redux/actions";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
//import { list } from "postcss";
import { Redirect } from "react-router-dom";
import { actions as authActions } from "@/utils/auth";

const styles = () => ({
  checklistContainer: {
    margin: "24px auto",
  },
  container: {
    padding: 20,
  },
  buttonContainer: {
    float: "right",
    position: "relative",
    top: "-45px",
    zIndex: "901",
  },
});

class AddCheckList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkResponse: false,
      searchVal: "",
      selctedCheckList: null,
      templateList: [],
      templateListHasMore: false,
      templateListOffset: 0,
    };
  }

  componentDidMount = () => {
    this.getHmskTemplates();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.hmskCheckListReducer !== this.props.hmskCheckListReducer) {
      if (
        this.props.hmskCheckListReducer &&
        this.props.hmskCheckListReducer.successMessage &&
        this.props.hmskCheckListReducer.successMessage.status &&
        this.state.checkResponse
      ) {
        this.setState({ checkResponse: false });
        this.props.enqueueSnackbar(
          this.props.hmskCheckListReducer.successMessage.message,
          { variant: "success" }
        );
        this.props.history.push(`/hmsk/checkList`);
      }
      if (
        this.props.hmskCheckListReducer &&
        this.props.hmskCheckListReducer.errorMessage &&
        this.props.hmskCheckListReducer.errorMessage.response &&
        this.props.hmskCheckListReducer.errorMessage.response.message &&
        this.state.checkResponse
      ) {
        this.setState({ checkResponse: false });
        this.props.enqueueSnackbar(
          this.props.hmskCheckListReducer.errorMessage.response.message,
          { variant: "error" }
        );
      }
    }

    if (
      this.props.hmskTemplateReducer &&
      this.props.hmskTemplateReducer.hmskTemplateList &&
      prevProps.hmskTemplateReducer.hmskTemplateList !==
        this.props.hmskTemplateReducer.hmskTemplateList
    ) {
      if (this.props.hmskTemplateReducer.hmskTemplateList.entries) {
        let listArray = this.props.hmskTemplateReducer.hmskTemplateList;
        await this.setState({
          templateList: [...this.state.templateList, ...listArray.entries],
          templateListHasMore: listArray.pagination.has_more_items,
        });
      }
    }
  };

  getHmskTemplates = async () => {
    let queryString = "";
    let offset = 0;
    if (this.state.templateListHasMore) {
      offset = this.state.templateList.length;
      queryString = `offset=${offset}`;
    }
    if (this.state.searchVal) {
      queryString = `name=${this.state.searchVal}`;
    }
    await this.props.actions.getHmskTemplates(queryString);
  };

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  addCheckList = async (data) => {
    await this.props.actions.addHmskCheckList(data);
    await this.setState({ checkResponse: true });
  };

  render = () => {
    const { classes, ...others } = this.props;
    return !authActions.getAccessToken() ? (
      <Redirect to={`/token/${authActions.getCompanyToken()}`} />
    ) : (
      <Fragment>
        <CommonHeaderInSettings
          title={i18next.t("Your checklists")}
          haveBackButton={true}
          onBackClick={() => {
            this.props.history.goBack();
          }}
          toggleCollapse={this.props.toggleCollapse}
        />

        <Grid container className={classes.checklistContainer}>
          <Grid
            item
            xs={4}
            className={classes.container}
            style={{
              height: "calc(100vh - 58px)",
              borderRight: "2px solid #BFBFBF",
            }}
          >
            <OwnCheckList
              state={this.state}
              changeState={this.changeState}
              getHmskTemplates={this.getHmskTemplates}
              {...others}
            />
          </Grid>

          <Grid item xs={8} className={classes.container}>
            <QuestionAnswerList
              state={this.state}
              changeState={this.changeState}
              addCheckList={this.addCheckList}
              {...others}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    hmskTemplateReducer: state.hmskTemplateReducer,
    hmskCheckListReducer: state.hmskCheckListReducer,
    projectReducer: state.projectReducer,
    taskReducer: state.task,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(AddCheckList)));
