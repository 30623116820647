import React, { Fragment } from "react";
import { Typography, SvgIcon, Grid, Fab } from "@mui/material";
import { withStyles } from "@mui/styles";
import CalendarDateRangePicker from "./CalendarDateRangePicker";
import i18next from "i18next";
import { green } from "@mui/material/colors";
import { getDateFormat } from "@/utils/dateTimeFunctions";

const CommonFilterDrawer = withStyles((theme) => ({
  mainContainer: {
    width: 350,
    height: "100%",
    padding: 12,
    background: theme.palette.background.default,
  },
  resetButtonContainer: {
    padding: "20px 20px 40px 20px",
    bottom: "100px",
    background: theme.palette.background.default,
  },
  icon: {
    cursor: "pointer",
  },
  iconBorder: {
    top: "-30px",
    position: "relative",
    float: "right",
  },
  extendedIcon: {
    marginRight: 5,
    fontSize: 18,
  },
  button: {
    color: "#fff",
    backgroundColor: "#18B24B",
    "&:hover": {
      backgroundColor: green[700],
    },
    marginRight: theme.spacing(1),
    padding: "0 10px !important",
    fontSize: 13,
  },
}))((props) => {
  const {
    classes,
    resultNumber,
    extraContent,
    buttonText,
    fromDate,
    toDate,
    changeState,
    hideDateRange,
  } = props;

  return (
    <Fragment>
      <Typography component="div" className={classes.mainContainer}>
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={6}>
            <Typography>
              {resultNumber} {i18next.t("Results")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Typography
              style={{
                fontSize: 14,
                color: "#18B24B",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => {
                props.closeDrawer();
              }}
            >
              {i18next.t("View results")}
            </Typography>
          </Grid>
        </Grid>
        {extraContent}
        {(!hideDateRange || hideDateRange === false) && (
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12}>
              <CalendarDateRangePicker
                label={i18next.t("Date range")}
                dates={[fromDate, toDate]}
                onChangeDate={(dates) => {
                  if (dates && dates.length) {
                    changeState({ fromDate: dates[0], toDate: dates[1] });
                  } else {
                    changeState({ fromDate: undefined, toDate: undefined });
                  }
                }}
                format={getDateFormat()}
              />
            </Grid>
          </Grid>
        )}
      </Typography>
      <Typography component="div" className={classes.resetButtonContainer}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Fab
              variant="extended"
              className={classes.button}
              size="small"
              onClick={() => {
                props.resetFilter();
              }}
            >
              <SvgIcon fontSize="small" className={classes.extendedIcon}>
                <path d="M12,3A9,9 0 0,0 3,12H0L4,16L8,12H5A7,7 0 0,1 12,5A7,7 0 0,1 19,12A7,7 0 0,1 12,19C10.5,19 9.09,18.5 7.94,17.7L6.5,19.14C8.04,20.3 9.94,21 12,21A9,9 0 0,0 21,12A9,9 0 0,0 12,3M14,12A2,2 0 0,0 12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12Z" />
              </SvgIcon>
              {buttonText}
            </Fab>
          </Grid>
        </Grid>
      </Typography>
    </Fragment>
  );
});

export default CommonFilterDrawer;
