import React from "react";
import i18next from "i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 4,
    padding: theme.spacing(2),
  },
  contentView: {
    marginBottom: 4,
    padding: theme.spacing(2),
  },
}));

const AlertModal = (props) => {
  const classes = useStyles();

  const { isOpen, handleClose, message, title } = props;
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle className={classes.header}>{i18next.t(title)}</DialogTitle>
      <DialogContent className={classes.contentView}>
        {i18next.t(message)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{i18next.t("Ok")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
