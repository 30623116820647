import React, { useState } from "react";
import Alerts from "./Alerts";
import { Box, Container, Typography } from "@mui/material";

const delay = (cb, ms) => setTimeout(cb, ms);

const KioskPage = ({ onCheckin }) => {
  const [cardCode, setCardCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogState, setDialogState] = useState({
    open: false,
    operation: "", // check-in | check-out | error
    message: "",
  });

  const resetDialog = () => {
    setDialogState({
      open: false,
      operation: "",
      message: "",
    });
    setTimeout(() => {
      document.getElementById("cardIdInput").focus();
    }, 1);
  };

  const handleSubmit = (e) => {
    if (loading) return;
    e.preventDefault();
    setLoading(true);
    onCheckin(cardCode)
      .then((resp) => {
        const { operation, message } = resp?.data ?? {};
        setDialogState({ open: true, operation, message });
      })
      .catch((err) => {
        const { message } = err?.response?.data ?? {};

        setDialogState({
          open: true,
          operation: "error",
          message: message ?? err?.message ?? "Unknown error",
        });
      })
      .finally(() => {
        delay(resetDialog, 6000);
        setCardCode("");
        setLoading(false);
      });
  };

  return (
    <>
      <Alerts
        open={dialogState.open}
        operation={dialogState.operation}
        message={dialogState.message}
        onClose={resetDialog}
      />
      <form onSubmit={handleSubmit}>
        <input
          id="cardIdInput"
          autoFocus
          value={cardCode}
          onChange={(e) => setCardCode(e.target.value)}
          onBlur={({ target }) => target.focus()}
          style={{ position: "absolute", top: -30 }}
          disabled={loading}
        />
        <input type="submit" hidden />
      </form>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ my: 3, textAlign: "center" }}>
            <Typography variant="h4">Please, scan your card</Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default KioskPage;
