import { logoutApi } from "@/services/logout";
import i18next from "i18next";
import * as Sentry from "@sentry/browser";
import { actions as authActions } from "@/utils/auth";

const intialState = {
  isLoading: false,
  data: {},
  error: null,
  isUserLoggedOut: false,
};

const types = {
  POST_LOGOUT: "logout/POST_LOGOUT",
  POST_LOGOUT_SUCCESS: "logout/POST_LOGOUT_SUCCESS",
  POST_LOGOUT_FAILURE: "logout/POST_LOGOUT_FAILURE",
};

export const logOut = (state = intialState, action) => {
  switch (action.type) {
    case types.POST_LOGOUT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.POST_LOGOUT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data,
        isLoading: false,
        isUserLoggedOut: true,
      };
    }
    case types.POST_LOGOUT_FAILURE: {
      const { error } = action.payload;
      return {
        ...intialState,
        error,
      };
    }
    default:
      return state;
  }
};

const logout = (callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.POST_LOGOUT });

      const postObj = {
        client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
        client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
        token: authActions.getAccessToken(),
      };

      // just call api and get response
      const response = await logoutApi(postObj);
      Sentry.setUser(null);
      dispatch({
        type: types.POST_LOGOUT_SUCCESS,
        payload: {
          data: response.data,
        },
      });
      callback();
    } catch (error) {
      let message = i18next.t("Authentication failed");
      dispatch({
        type: types.POST_LOGOUT_FAILURE,
        payload: { error: message },
      });
    }
  };
};

export const actions = {
  logout,
};
