import React, { Fragment } from "react";
import moment from "moment";
import i18next from "i18next";
import {
  Typography,
  Button,
  AppBar,
  Grid,
  Badge,
  Drawer,
  FormControl,
  IconButton,
  Toolbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FilterList } from "@mui/icons-material";
import { MaterialSelect } from "@/common/selectComponents/MaterialSelect";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import CommonFilterDrawer from "@/common/CommonFilterDrawer";
import useToggleMenubar from "@/hooks/useToggleMenubar";
import MenuIcon from "@mui/icons-material/Menu";
import AppBarDateRangePicker from "@/common/AppBarDateRangePicker";

const styles = (theme) => ({
  appbar: {
    zIndex: 900,
    position: "sticky",
    top: -5,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
  },
  toolbar: {
    position: "sticky",
    top: -5,
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 1),
    height: 64,
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
    marginRight: 16,
  },
  btnGroup: {
    display: "flex",
    justifyContent: "center",
    verticalAlign: "middle",
    height: 48,
    width: "100%",
  },
  btnIcon: {
    justifyContent: "center",
    minWidth: 20,
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconSize: {
    fontSize: "2rem",
    color: theme.palette.text.primary,
  },
  dateRangeContainer: {
    margin: theme.spacing(0, 1),
  },
  toolbarIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
    margin: 2,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  activeButton: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}14`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  checkboxLabel: {
    color: theme.palette.text.primary,
  },
  badge: {
    padding: 3,
    color: theme.palette.common.white,
    backgroundColor: "#2D99FF",
    boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.0),
       0px 6px 10px 0px rgba(0,0,0,0.12),
        0px 1px 18px 0px rgba(0,0,0,0.10)`,
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  /*selectPaper: {
    position: "absolute",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  dateRangePicker: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      minWidth: 140,
      "&:hover": {
        background: "none",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  viewContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 15,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  checkBox: {
    color: theme.palette.text.primary,
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  btnToday: {
    display: "flex",
    flexWrap: "nowrap",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 30px",
    margin: "0px 8px",
  },
});

const MobileMenuButton = withStyles(styles)((props) => {
  const { onToggleMenubar } = useToggleMenubar();
  const { classes } = props;

  return (
    <IconButton
      onClick={() => {
        onToggleMenubar();
      }}
      id="menu-toggle-button"
      size="large"
      sx={{
        marginRight: "8px",
      }}
      className={classes.toggleButton}
    >
      <MenuIcon />
    </IconButton>
  );
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
    "&.MuiToggleButton-root": {
      padding: "7px 27px",
    },
    "&.Mui-selected": {
      boxShadow: "none",
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}14`,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}));

class HourFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCards: true,
      showDrawer: false,
      formats: props.state.groupBy,
    };
  }

  todayHrs = () => {
    const { dateRangeStartsWithToday } = this.props.state;

    if (dateRangeStartsWithToday) {
      const today = moment().startOf("day");
      this.props.filterActions.changeFilter({
        startDate: today,
        endDate: moment(today).clone().add(6, "days"),
      });
    } else {
      this.props.filterActions.changeFilter({
        startDate: moment().clone().startOf("week"),
        endDate: moment().clone().endOf("week"),
      });
    }
  };

  onDateRangeChange = (dates) => {
    let { startDate, endDate } = this.props.filters;

    // reset time to 00:00:00 to compare with date picker dates
    startDate = moment(startDate).startOf("day");
    endDate = moment(endDate).startOf("day");
    // update only if date selection changed
    if (
      dates[1] &&
      (!moment(startDate).isSame(dates[0]) || !moment(endDate).isSame(dates[1]))
    ) {
      this.props.filterActions.changeFilter({
        startDate: dates[0],
        endDate: dates[1] ? dates[1] : dates[0],
      });
    }
  };

  selectNextWeek = async () => {
    const { endDate } = this.props.filters;

    this.props.filterActions.changeFilter({
      startDate: moment(endDate).clone().add(1, "days"),
      endDate: moment(endDate).clone().add(1, "weeks"),
    });
  };

  selectPreviousWeek = async () => {
    const { startDate } = this.props.filters;

    this.props.filterActions.changeFilter({
      startDate: moment(startDate).clone().subtract("1", "weeks"),
      endDate: moment(startDate).clone().subtract("1", "days"),
    });
  };

  handleDrawerClose = () => {
    this.setState({ showDrawer: false });
  };

  handleDrawerOpen = () => {
    this.setState({ showDrawer: true });
  };

  handleResetFilter = () => {
    this.props.resetDefaultDates();
    this.props.actions.filterData("planning", null);
    this.setState({ showDrawer: false });
    this.props.changeState({
      selectedProjectFilterOption: null,
      selectedDepartmentOption: null,
      selectedUserFilterOption: null,
      fromDate: null,
      toDate: null,
      filterCounter: 0,
    });
  };

  handleFilterChangeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.props.actions.filterData("planning", {
          [key]: data[key],
        });
        this.props.changeState({ [key]: data[key] });
      }
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
  };

  projectFilterOnChange = async (selected) => {
    const projectIds = [];
    if (Array.isArray(selected)) {
      for (let i = 0; i < selected.length; i++) {
        projectIds.push(selected[i].value);
      }
    }

    this.props.actions.filterData("planning", {
      projectIds: projectIds,
    });
    this.props.changeState({
      selectedProjectFilterOption: selected,
    });
  };

  userFilterOnChange = async (selected) => {
    this.props.actions.filterData("planning", {
      userId: selected.value,
    });
    this.props.changeState({
      selectedUserFilterOption: selected,
    });
  };

  departmentFilterOnChange = async (selected) => {
    const departmentIds = [];
    if (Array.isArray(selected)) {
      for (let i = 0; i < selected.length; i++) {
        departmentIds.push(selected[i].value);
      }
    }

    this.props.actions.filterData("planning", {
      departmentIds: departmentIds,
    });
    this.props.changeState({
      selectedDepartmentOption: selected,
    });
  };

  handleFormat = (event, newFormats) => {
    if (newFormats) {
      this.setState({ formats: newFormats });
    }
  };

  render() {
    const { classes, state } = this.props;
    const { startDate, endDate } = this.props.filters;

    let activeFilter =
      this.props.commonReducer &&
      this.props.commonReducer.filters &&
      this.props.commonReducer.filters.planning
        ? true
        : false;

    return (
      <Fragment>
        <AppBar elevation={0} className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <MobileMenuButton />

            <Typography variant="h4" className={classes.title}>
              {i18next.t("Planning")}
            </Typography>

            <div className={classes.flexGrow} />

            <StyledToggleButtonGroup
              value={this.state.formats}
              onChange={this.handleFormat}
              exclusive
            >
              <ToggleButton
                value="user"
                onClick={() => {
                  this.props.changeState({
                    groupBy: "user",
                    offset: 0,
                  });
                }}
                className={classes.icon}
              >
                {i18next.t("Users")}
              </ToggleButton>
              <ToggleButton
                value="project"
                onClick={() => {
                  this.props.changeState({
                    groupBy: "project",
                    offset: 0,
                  });
                }}
                className={classes.icon}
              >
                {i18next.t("Projects")}
              </ToggleButton>
            </StyledToggleButtonGroup>

            <Typography component="div" className={classes.dateRangeContainer}>
              <Typography component="div" role="toolbar">
                <Typography component="div" className={classes.btnGroup}>
                  <Button
                    className={classes.btnIcon}
                    onClick={this.selectPreviousWeek}
                  >
                    <ChevronLeftIcon className={classes.iconSize} />
                  </Button>
                  <div className={classes.dateRangePicker}>
                    <AppBarDateRangePicker
                      dates={[startDate, endDate]}
                      onChangeDate={(dates) => {
                        if (dates && dates.length) {
                          this.onDateRangeChange(dates);
                        }
                      }}
                    />
                  </div>
                  <Button
                    className={classes.btnIcon}
                    onClick={this.selectNextWeek}
                  >
                    <ChevronRightIcon className={classes.iconSize} />
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.btnToday}
                    onClick={this.todayHrs}
                  >
                    {i18next.t("Today")}
                  </Button>
                </Typography>
              </Typography>
            </Typography>

            <Typography component="div" style={{ marginInline: 8 }}>
              <Typography component="div" onClick={this.handleDrawerOpen}>
                <Badge
                  invisible={!activeFilter}
                  badgeContent={
                    state.filterCounter ? state.filterCounter : null
                  }
                  classes={{ badge: classes.badge }}
                >
                  <FilterList className={classes.toolbarIcon} />
                </Badge>
              </Typography>
            </Typography>
          </Toolbar>

          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            onClose={this.handleDrawerClose}
          >
            <CommonFilterDrawer
              resultNumber={""}
              closeDrawer={this.handleDrawerClose}
              resetFilter={this.handleResetFilter}
              fromDate={state.fromDate}
              toDate={state.toDate}
              changeState={this.handleFilterChangeState}
              buttonText={i18next.t("Reset filters")}
              hideDateRange={false}
              extraContent={
                <Grid container style={{ padding: 10 }}>
                  <Grid item xs={12}>
                    <form onSubmit={this.handleFormSubmit}>
                      {state.groupBy === "user" && (
                        <FormControl className={classes.formControl}>
                          <MaterialAsyncPaginate
                            classes={classes}
                            textFieldProps={{
                              label: i18next.t("Project"),
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            isMulti={true}
                            defaultOptions
                            loadOptions={async (search, loadedOptions) => {
                              return this.props.loadProjectList(
                                search,
                                loadedOptions
                              );
                            }}
                            value={state.selectedProjectFilterOption}
                            onChange={this.projectFilterOnChange}
                          />
                        </FormControl>
                      )}
                      {state.groupBy === "project" && (
                        <FormControl className={classes.formControl}>
                          <MaterialAsyncPaginate
                            classes={classes}
                            textFieldProps={{
                              label: i18next.t("User"),
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            defualtOptions
                            loadOptions={async (search, loadedOptions) => {
                              return this.props.loadUserList(
                                search,
                                loadedOptions
                              );
                            }}
                            value={state.selectedUserFilterOption}
                            onChange={this.userFilterOnChange}
                          />
                        </FormControl>
                      )}
                      <FormControl className={classes.formControl}>
                        <MaterialSelect
                          classes={classes}
                          textFieldProps={{
                            label: i18next.t("Department"),
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          isMulti={true}
                          options={state.departmentOptions}
                          value={state.selectedDepartmentOption}
                          onChange={this.departmentFilterOnChange}
                        />
                      </FormControl>
                      <button type="submit" style={{ display: "none" }} />
                    </form>
                  </Grid>
                </Grid>
              }
            />
          </Drawer>
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(HourFilters);
