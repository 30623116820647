import * as types from "./types";
import { core } from "./core";

export const getAddonTypes = (id, exception_types) => {
  return core.API(
    "GET",
    `/addon/addon-types?limit=200&offset=0${id ? `&user_id=${id}` : ""}${
      exception_types ? `&exception_types=${exception_types}` : ""
    }`,
    types.GET_ADDON_TYPES,
    types.GET_ADDON_TYPES_SUCCESS,
    types.GET_ADDON_TYPES_FAILURE
  );
};

export const createAddonTypes = (data) => {
  return core.API(
    "POST",
    `/addon/addon-type/`,
    types.CREATE_ADDON_TYPES,
    types.CREATE_ADDON_TYPES_SUCCESS,
    types.CREATE_ADDON_TYPES_FAILURE,
    data
  );
};

export const deleteAddonTypes = (id) => {
  return core.API(
    "DELETE",
    `/addon/addon-type/${id}`,
    types.DELETE_ADDON_TYPES,
    types.DELETE_ADDON_TYPES_SUCCESS,
    types.DELETE_ADDON_TYPES_FAILURE
  );
};

export const putAddonTypes = (id, data) => {
  return core.API(
    "PUT",
    `/addon/addon-type/${id}/`,
    types.PUT_ADDON_TYPES,
    types.PUT_ADDON_TYPES_SUCCESS,
    types.PUT_ADDON_TYPES_FAILURE,
    data
  );
};

export const getOptionalAddonTypes = (id) => {
  return core.API(
    "GET",
    `/addon/addon-type/${id}/optional`,
    types.GET_OPTIONAL_ADDON_TYPES,
    types.GET_OPTIONAL_ADDON_TYPES_SUCCESS,
    types.GET_OPTIONAL_ADDON_TYPES_FAILURE
  );
};
export const getAddonMeasurements = () => {
  return core.API(
    "GET",
    `/addon/measurements`,
    types.GET_ADDON_MEASUREMENTS,
    types.GET_ADDON_MEASUREMENTS_SUCCESS,
    types.GET_ADDON_MEASUREMENTS_FAILURE
  );
};
export const createAddonMeasurement = (data) => {
  return core.API(
    "POST",
    `/addon/measurement`,
    types.CREATE_ADDON_MEASUREMENT,
    types.CREATE_ADDON_MEASUREMENT_SUCCESS,
    types.CREATE_ADDON_MEASUREMENT_FAILURE,
    data
  );
};
export const getFixedAddonTypes = () => {
  return core.API(
    "GET",
    `/addon/fixed-addon-types`,
    types.GET_FIXED_ADDON_TYPES,
    types.GET_FIXED_ADDON_TYPES_SUCCESS,
    types.GET_FIXED_ADDON_TYPES_FAILURE
  );
};
export const createFixedAddonType = (data) => {
  return core.API(
    "POST",
    `/addon/fixed-addon-type`,
    types.CREATE_FIXED_ADDON_TYPE,
    types.CREATE_FIXED_ADDON_TYPE_SUCCESS,
    types.CREATE_FIXED_ADDON_TYPE_FAILURE,
    data
  );
};
export const deleteFixedAddonType = (id) => {
  return core.API(
    "DELETE",
    `/addon/fixed-addon-type/${id}`,
    types.DELETE_FIXED_ADDON_TYPE,
    types.DELETE_FIXED_ADDON_TYPE_SUCCESS,
    types.DELETE_FIXED_ADDON_TYPE_FAILURE
  );
};
export const putFixedAddonType = (id, data) => {
  return core.API(
    "PUT",
    `/addon/fixed-addon-type/${id}/`,
    types.PUT_FIXED_ADDON_TYPE,
    types.PUT_FIXED_ADDON_TYPE_SUCCESS,
    types.PUT_FIXED_ADDON_TYPE_FAILURE,
    data
  );
};

export const setOptionalAddonType = (id, data) => {
  return core.API(
    "PUT",
    `/addon/addon/${id}/optional-addon-type`,
    types.SET_OPTIONAL_ADDON_TYPE,
    types.SET_OPTIONAL_ADDON_TYPE_SUCCESS,
    types.SET_OPTIONAL_ADDON_TYPE_FAILURE,
    data
  );
};
