import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MuiPhoneInput from "material-ui-phone-number";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { MaterialSelect } from "@/common/selectComponents/MaterialSelect";
import errorMessages from "@/utils/errorMessages";
import { checkUserEmail } from "@/services/employee";

import i18next from "i18next";

const styles = (theme) => ({
  root: {},
  button: {
    color: "#ffffff",
  },
  contentView: {
    padding: "10px 50px",
  },
  textField: {
    marginBottom: 15,
    width: "100%",
  },
  selectInput: {
    display: "flex",
    padding: "5px 5px 5px 10px",
    height: "50px",
  },
  /*selectPaper: {
    position: "absolute",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  selectMenuItem: {
    fontSize: 16,
  },
  errorText: {
    color: theme.palette.error.main,
    padding: "0px 5px 10px 15px",
  },
  successText: {
    color: theme.palette.success.main,
    padding: "0px 5px 10px 15px",
  },
  inputError: {
    display: "block",
    width: "100%",
    marginTop: 5,
    fontSize: 12,
    color: "#dc3545",
  },
});

class AddUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      birthID: "",
      employeeNumber: "",
      bankAccountNumber: "",
      nameError: null,
      emailError: null,
      phoneError: null,
      departmentError: null,
      userGroupError: null,
      birthIdError: null,
      bankAccountNumberError: null,
      userExist: false,
      userAdd: false,
      confirmationSend: false,
      selectedDepartmentId: null,
      selectedUserGroupId: null,
      userRole: { label: i18next.t("User"), value: "user" },
      userRoleOptions: [
        { label: i18next.t("User"), value: "user" },
        { label: i18next.t("Department admin"), value: "department_admin" },
        { label: i18next.t("Admin"), value: "admin" },
      ],
      positionPercentage: "",
      positionPerError: null,
    };
  }

  handleCancel = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      birthID: "",
      employeeNumber: "",
      bankAccountNumber: "",
      selectedDepartmentId: null,
      selectedUserGroupId: null,
      nameError: false,
      emailError: false,
      phoneError: false,
      departmentError: false,
      userGroupError: false,
      birthIdError: false,
      bankAccountNumberError: false,
      userRole: null,
      positionPercentage: "",
      positionPerError: null,
    });
    //this.props.handleCancel();
  };

  checkName = () => {
    this.setState({
      nameError: !this.state.name ? errorMessages.required : null,
    });
  };

  checkEmail = async () => {
    if (!this.state.email) {
      this.setState({ emailError: errorMessages.required });
    } else if (!/[^@]+@[^@]+\.[^@]+/.test(this.state.email)) {
      this.setState({ emailError: errorMessages.invalidEmail });
    } else {
      try {
        const response = await checkUserEmail({ email: this.state.email });
        if (response && response.data) {
          this.setState({
            emailError: errorMessages.user_exist,
          });
        }
      } catch (error) {
        //let { message } = getErrorObject(error);
        // If user doesn't exist, API returns 404 so, use catch
        this.setState({ emailError: null });
      }
    }
  };

  checkPhone = () => {
    if (!this.state.phone) {
      this.setState({
        phoneError: errorMessages.required,
      });
    } else if (
      !parsePhoneNumberFromString(this.state.phone) ||
      (parsePhoneNumberFromString(this.state.phone) &&
        !parsePhoneNumberFromString(this.state.phone).isValid())
    ) {
      this.setState({
        phoneError: errorMessages.invalidPhone,
      });
    } else {
      this.setState({
        phoneError: null,
      });
    }
  };

  checkBirthID = (birthID) => {
    this.setState({
      birthIdError:
        birthID.length > 0 && birthID.length !== 11
          ? errorMessages.invalidBirthID
          : null,
    });
  };

  checkbankAccountNumber = (accountNumber) => {
    this.setState({
      bankAccountNumberError:
        accountNumber.length > 0 && accountNumber.length !== 11
          ? errorMessages.invalidBankAccountNumber
          : null,
    });
  };

  checkDepartment = () => {
    this.setState({
      departmentError: this.state.selectedDepartmentId
        ? false
        : errorMessages.required,
    });
  };

  checkUserGroup = () => {
    this.setState({
      userGroupError: this.state.selectedUserGroupId
        ? false
        : errorMessages.required,
    });
  };

  checkPositionPerInput = (value) => {
    if (value && value.length !== 0) {
      value = parseInt(value);
      if (value < 0 || value > 100) {
        this.setState({
          positionPerError: errorMessages.invalidPercentage,
        });
      } else {
        this.setState({
          positionPerError: null,
          positionPercentage: value,
        });
      }
    } else {
      this.setState({
        positionPerError: null,
      });
    }
  };

  render = () => {
    const { handleFormSubmit, classes, errorMsg, successMsg } = this.props;

    return (
      <Card className={classes.root}>
        <CardHeader title={i18next.t("Add new user")} />
        {errorMsg && (
          <Typography variant="body1" className={classes.errorText}>
            {errorMsg}
          </Typography>
        )}
        {successMsg && (
          <Typography variant="body1" className={classes.successText}>
            {successMsg}
          </Typography>
        )}
        <Divider />
        <CardContent>
          <FormControl className={classes.textField}>
            <TextField
              error={this.state.nameError ? true : false}
              label={i18next.t("Name")}
              type="text"
              value={this.state.name}
              variant="outlined"
              onChange={async (e) => {
                this.setState({ name: e.target.value });
                this.checkName();
              }}
              onBlur={() => {
                this.checkName();
              }}
            />
            <Typography className={classes.inputError}>
              {this.state.nameError}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <TextField
              error={this.state.emailError ? true : false}
              label={i18next.t("Email")}
              type="email"
              value={this.state.email}
              variant="outlined"
              onChange={async (e) => {
                this.setState({ email: e.target.value });
                //await this.checkEmail();
              }}
              onBlur={() => {
                this.checkEmail();
              }}
            />
            <Typography className={classes.inputError}>
              {this.state.emailError}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <MuiPhoneInput
              fullWidth={true}
              defaultCountry="no"
              label={i18next.t("Phone")}
              error={this.state.phoneError ? true : false}
              value={this.state.phone}
              variant="outlined"
              onChange={async (value) => {
                this.setState({ phone: value });
                this.checkPhone();
              }}
              onBlur={() => {
                this.checkPhone();
              }}
              sx={{
                svg: {
                  height: "18px",
                },
              }}
            />
            <Typography className={classes.inputError}>
              {this.state.phoneError}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <MaterialSelect
              classes={classes}
              textFieldProps={{
                label: i18next.t("User group"),
                InputLabelProps: {
                  shrink: true,
                },
              }}
              error={this.state.userGroupError ? true : false}
              options={this.props.userGroupList}
              value={this.state.selectedUserGroupId}
              onChange={(selected) => {
                this.setState(
                  {
                    selectedUserGroupId: selected,
                  },
                  this.checkUserGroup
                );
              }}
              onBlur={this.checkUserGroup}
            />
            <Typography className={classes.inputError}>
              {this.state.userGroupError}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <MaterialSelect
              classes={classes}
              textFieldProps={{
                label: i18next.t("Department"),
                InputLabelProps: {
                  shrink: true,
                },
              }}
              error={this.state.departmentError ? true : false}
              options={this.props.departmentList}
              value={this.state.selectedDepartmentId}
              onChange={(selected) => {
                this.setState(
                  {
                    selectedDepartmentId: selected,
                  },
                  this.checkDepartment
                );
              }}
              onBlur={this.checkDepartment}
            />
            <Typography className={classes.inputError}>
              {this.state.departmentError}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <TextField
              label={i18next.t("Employee number")}
              variant="outlined"
              value={this.state.employeeNumber}
              onChange={(e) => {
                this.setState({
                  employeeNumber: e.target.value,
                });
              }}
            />
          </FormControl>

          <FormControl className={classes.textField}>
            <TextField
              error={this.state.birthIdError ? true : false}
              label={i18next.t("Birth ID")}
              type="number"
              value={this.state.birthID}
              variant="outlined"
              onChange={(e) => {
                this.setState({ birthID: e.target.value });
                this.checkBirthID(e.target.value);
              }}
              onBlur={() => {
                this.checkBirthID(this.state.birthID);
              }}
            />
            <Typography className={classes.inputError}>
              {this.state.birthIdError}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <TextField
              error={this.state.bankAccountNumberError ? true : false}
              label={i18next.t("Bank account number")}
              type="number"
              value={this.state.bankAccountNumber}
              variant="outlined"
              onChange={(e) => {
                this.setState({ bankAccountNumber: e.target.value });
                this.checkbankAccountNumber(e.target.value);
              }}
              onBlur={() => {
                this.checkbankAccountNumber(this.state.bankAccountNumber);
              }}
            />
            <Typography className={classes.inputError}>
              {this.state.bankAccountNumberError}
            </Typography>
          </FormControl>

          <FormControl
            className={classes.textField}
            id="new-user-modal-position-percentage"
          >
            <TextField
              autoComplete="off"
              error={this.state.positionPerError ? true : false}
              label={`${i18next.t("Position")} %`}
              type={"number"}
              value={this.state.positionPercentage}
              onChange={(e) => {
                this.setState({
                  positionPercentage: e.target.value,
                });
                this.checkPositionPerInput(e.target.value);
              }}
              onBlur={() => {
                this.checkPositionPerInput(this.state.positionPercentage);
              }}
              id={"new-user-modal-position-percentage"}
            />
            <Typography className={classes.inputError}>
              {this.state.positionPerError}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <MaterialSelect
              classes={classes}
              textFieldProps={{
                label: i18next.t("User role"),
                InputLabelProps: {
                  shrink: true,
                },
                id: "new-user-modal-role-select",
              }}
              options={this.state.userRoleOptions}
              value={this.state.userRole}
              onChange={async (selected) => {
                this.setState({ userRole: selected });
              }}
              id={"new-user-modal-role"}
            />
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.button}
            disabled={
              !this.state.name ||
              !this.state.email ||
              !this.state.phone ||
              !this.state.selectedUserGroupId ||
              !this.state.selectedDepartmentId ||
              !this.state.userRole ||
              this.state.nameError ||
              this.state.emailError ||
              this.state.phoneError ||
              this.state.departmentError ||
              this.state.birthIdError ||
              this.state.bankAccountNumberError ||
              this.state.positionPerError ||
              this.state.userGroupError
                ? true
                : false
            }
            onClick={() => {
              handleFormSubmit({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                internal_number: this.state.employeeNumber,
                birth_id: this.state.birthID,
                bank_account: this.state.bankAccountNumber,
                group_id: this.state.selectedUserGroupId.value,
                department_id: this.state.selectedDepartmentId.value,
                role: this.state.userRole?.value,
                position_percentage: this.state.positionPercentage
                  ? this.state.positionPercentage
                  : null,
              });
              this.handleCancel();
            }}
          >
            {i18next.t("Submit")}
          </Button>
        </CardActions>
      </Card>
    );
  };
}

export default withStyles(styles)(AddUserForm);
