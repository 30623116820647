import { post, get } from "@/utils/api.config.js";
import { actions as authActions } from "@/utils/auth.js";

export const postSignInAuthorizeApi = (params) => {
  const request = {
    subUrl: `/oauth/authorize`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
    data: params,
  };
  return post(request);
};

export const postSignInTokenApi = (params) => {
  const request = {
    subUrl: `/oauth/token`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
    data: params,
  };
  return post(request);
};

export const validateCompanyTokenApi = (token) => {
  const request = {
    subUrl: `/attendance/company-info`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": token,
    },
  };
  return get(request);
};

export const getStripeSubscriptionApi = (token) => {
  const request = {
    subUrl: `/attendance/company/subscription`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": token,
    },
  };
  return get(request);
};
