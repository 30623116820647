import React from "react";
import ChecklistTable from "./components/ChecklistTable";
import { connect } from "react-redux";
import { ActionCreators } from "@/redux/actions";
import { bindActionCreators } from "redux";
import moment from "moment";
import { CommonHeaderInList } from "@/common/CommonHeaderInList";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import CommonFilterDrawer from "@/common/CommonFilterDrawer";
import { Redirect } from "react-router-dom";
import { actions as authActions } from "@/utils/auth";
import { Typography, Drawer, Grid, FormControl } from "@mui/material";
import { withStyles } from "@mui/styles";
import OverlayIndicator from "@/common/OverlayIndicator";
import {
  NoRecordFoundWithI18n,
  NotActiveModuleWithI18n,
} from "@/common/CommonComponents";
import i18next from "i18next";

const styles = (theme) => ({
  checklistContent: {
    textAlign: "center",
    padding: theme.spacing(4),
    paddingBottom: 56,
  },
  /*selectPaper: {
    position: "absolute",
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    "& input": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
});

class CheckList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 20,
      havePermission: true,
      fromDate: null,
      toDate: null,
      filterCounter: 0,
      isLoadTemplateResult: false,
      selectedTemplateFilterOptions: null,
      showDrawer: false,
      checkUsers: false,
      name: null,
      departmentId: null,
    };
  }

  componentDidMount = () => {
    this.props.actions.getCompanyModules();
    this.checkFiltersOnLoad();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.commonReducer.filters !== this.props.commonReducer.filters) {
      this.checkFilter();
    }

    if (prevState.limit !== this.state.limit) {
      this.getCheckList();
    }

    if (
      this.props.companyReducer &&
      this.props.companyReducer.companyModules &&
      prevProps.companyReducer.companyModules !==
        this.props.companyReducer.companyModules &&
      this.props.companyReducer.companyModules.length > 0
    ) {
      if (
        this.props.companyReducer.companyModules.find((data) => {
          return data.name === "HMSK Check List";
        })
      ) {
        this.checkFilter();
      } else {
        this.setState({
          havePermission: false,
        });
      }
    }

    if (
      this.props.hmskTemplateReducer &&
      this.props.hmskTemplateReducer.hmskTemplateList &&
      prevProps.hmskTemplateReducer.hmskTemplateList !==
        this.props.hmskTemplateReducer.hmskTemplateList &&
      this.props.hmskTemplateReducer.hmskTemplateList.entries
    ) {
      this.setState({ isLoadTemplateResult: true });
    }
  };

  onLimitChange = (value) => {
    this.setState({ limit: value });
  };

  checkFiltersOnLoad = () => {
    const checkListFilters =
      this.props.commonReducer.filters &&
      this.props.commonReducer.filters.checkList;

    let canResetFilters = true;

    // prevent reset filter if previous page was Checklist Details page
    if (checkListFilters && checkListFilters.referrer) {
      canResetFilters = false;

      this.props.actions.filterData("checkList", {
        referrer: null,
      });

      // assign filters to stats for sidebar to display them on page load
      this.setState({
        selectedTemplateFilterOptions:
          checkListFilters.templates && checkListFilters.templates.length
            ? checkListFilters.templates
            : null,
        fromDate: checkListFilters.fromDate ? checkListFilters.fromDate : null,
        toDate: checkListFilters.toDate ? checkListFilters.toDate : null,
      });
    }

    if (canResetFilters) {
      this.resetFilter();
    }
  };

  checkFilter = () => {
    let { commonReducer } = this.props;
    if (commonReducer && commonReducer.filters) {
      this.setState({
        fromDate:
          commonReducer.filters.checkList &&
          commonReducer.filters.checkList.fromDate
            ? commonReducer.filters.checkList.fromDate
            : null,
        toDate:
          commonReducer.filters.checkList &&
          commonReducer.filters.checkList.toDate
            ? commonReducer.filters.checkList.toDate
            : null,
      });
    }
    this.getCheckList();
  };

  getCheckList = (offset) => {
    const { commonReducer } = this.props;
    let queryString = "";
    let totalFilters = 0;
    queryString += `limit=${this.state.limit}&`;
    queryString += `offset=${offset ? offset : 0}&`;

    if (
      commonReducer &&
      commonReducer.filters &&
      commonReducer.filters.checkList &&
      commonReducer.filters.checkList.fromDate &&
      commonReducer.filters.checkList.fromDate !== "" &&
      commonReducer.filters.checkList.fromDate !== undefined
    ) {
      queryString += `start_time=${moment(
        commonReducer.filters.checkList.fromDate
      ).format("YYYY-MM-DD")}&`;
      totalFilters++;
    }

    if (
      commonReducer &&
      commonReducer.filters &&
      commonReducer.filters.checkList &&
      commonReducer.filters.checkList.toDate &&
      commonReducer.filters.checkList.toDate !== "" &&
      commonReducer.filters.checkList.toDate !== undefined
    ) {
      queryString += `end_time=${moment(
        commonReducer.filters.checkList.toDate
      ).format("YYYY-MM-DD")}&`;
    }

    if (
      commonReducer.filters.checkList &&
      commonReducer.filters.checkList.templates &&
      commonReducer.filters.checkList.templates !== undefined
    ) {
      const templates = commonReducer.filters.checkList.templates;

      const templateIds = [];
      if (Array.isArray(templates)) {
        for (let i = 0; i < templates.length; i++) {
          templateIds.push(templates[i].value);
        }
      }

      if (Array.isArray(templateIds) && templateIds.length > 0) {
        queryString += `hmsk_template_ids=${templateIds.join(",")}&`;
        totalFilters++;
      }
    }

    if (totalFilters) {
      this.setState({ filterCounter: totalFilters });
    }

    queryString = queryString.slice(0, -1);
    this.props.actions.getHmskCheckLists(queryString);
  };

  loadTemplateList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    let queryString = `limit=${this.state.limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    this.setState({ isLoadTemplateResult: false });
    await this.props.actions.getHmskTemplates(queryString);
    const result = await this.getTempatetListOptions();
    return result;
  };

  getTempatetListOptions = () => {
    const promise = new Promise((resolve) => {
      let counter = 0;
      const projTimer = setInterval(async () => {
        counter++;
        if (counter > 10) {
          this.setState({
            isLoadTemplateResult: true,
          });
        }

        if (this.state.isLoadTemplateResult) {
          clearInterval(projTimer);

          if (
            this.props.hmskTemplateReducer.hmskTemplateList &&
            this.props.hmskTemplateReducer.hmskTemplateList.entries
          ) {
            let data = [];
            for (
              let i = 0;
              i <
              this.props.hmskTemplateReducer.hmskTemplateList.entries.length;
              i++
            ) {
              const element =
                this.props.hmskTemplateReducer.hmskTemplateList.entries[i];
              data.push({
                value: element.id.toString(),
                label: element.name,
              });
            }

            resolve({
              options: data,
              hasMore:
                this.props.hmskTemplateReducer.hmskTemplateList.pagination
                  .has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 500);
    });

    return promise;
  };

  templateFilterOnChange = async (selected) => {
    this.props.actions.filterData("checkList", {
      templates: selected,
    });
    this.setState({
      selectedTemplateFilterOptions: selected,
    });
  };

  resetFilter = () => {
    this.setState(
      {
        selectedTemplateFilterOptions: null,
        fromDate: null,
        toDate: null,
        filterCounter: 0,
      },
      () => {
        this.props.actions.filterData("checkList", null);
      }
    );
  };

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  render = () => {
    const { commonReducer, hmskCheckListReducer, classes } = this.props;

    let checklistRecords = [];
    let totalRecords = null;

    if (
      hmskCheckListReducer.hmskCheckListRecords &&
      hmskCheckListReducer.hmskCheckListRecords.entries
    ) {
      checklistRecords = hmskCheckListReducer.hmskCheckListRecords.entries;
      totalRecords =
        hmskCheckListReducer.hmskCheckListRecords.pagination.total_items;
    }

    return !authActions.getAccessToken() ? (
      <Redirect to={`/token/${authActions.getCompanyToken()}`} />
    ) : (
      <>
        {!this.state.havePermission ? (
          <NotActiveModuleWithI18n />
        ) : (
          <>
            <CommonHeaderInList
              title={i18next.t("Checklists")}
              onButtonClick={(e) => {
                e.preventDefault();
                this.props.history.push(`/hmsk/addCheckList`);
              }}
              buttonTitle={i18next.t("New checklist")}
              activeFilter={
                commonReducer &&
                commonReducer.filters &&
                commonReducer.filters.checkList
                  ? true
                  : false
              }
              onFilterClick={() => {
                this.setState({ showDrawer: true });
              }}
              filterCounter={this.state.filterCounter}
              toggleCollapse={this.props.toggleCollapse}
            />
            <Drawer
              anchor="right"
              open={this.state.showDrawer}
              onClose={() => {
                this.setState({ showDrawer: false });
              }}
            >
              <CommonFilterDrawer
                resultNumber={totalRecords}
                closeDrawer={() => {
                  this.setState({ showDrawer: false });
                }}
                resetFilter={() => {
                  this.resetFilter();
                  this.setState({ showDrawer: false });
                }}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                changeState={(data) => {
                  for (let key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                      this.props.actions.filterData("checkList", {
                        [key]: data[key],
                      });
                      this.setState({ [key]: data[key] });
                    }
                  }
                }}
                extraContent={
                  <Grid container style={{ padding: 10 }}>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <MaterialAsyncPaginate
                          classes={classes}
                          textFieldProps={{
                            label: i18next.t("Template"),
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          isMulti={true}
                          defaultOptions
                          loadOptions={async (search, loadedOptions) => {
                            return this.loadTemplateList(search, loadedOptions);
                          }}
                          value={this.state.selectedTemplateFilterOptions}
                          onChange={this.templateFilterOnChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                }
                buttonText={i18next.t("Reset filters")}
              />
            </Drawer>
            <Typography component="div" className={classes.checklistContent}>
              {checklistRecords && checklistRecords.length > 0 ? (
                <ChecklistTable
                  checklistRecords={checklistRecords}
                  totalRecords={totalRecords}
                  onLimitChange={this.onLimitChange}
                  loadMore={this.getCheckList}
                  history={this.props.history}
                />
              ) : (
                <NoRecordFoundWithI18n />
              )}
            </Typography>
            <OverlayIndicator isLoading={hmskCheckListReducer.isLoading} />
          </>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    hmskTemplateReducer: state.hmskTemplateReducer,
    hmskCheckListReducer: state.hmskCheckListReducer,
    commonReducer: state.commonReducer,
    companyReducer: state.companyReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CheckList));
