import React from "react";
import moment from "moment";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import PlanCardUnassigned from "./PlanCardUnassigned";
import HourCard from "./hourCard";
import { Typography, SvgIcon, Avatar } from "@mui/material";
import { withStyles } from "@mui/styles";
import { AccessTimeFilled } from "@mui/icons-material";
import i18next from "i18next";
import getInitials from "@/utils/getInitials";

const styles = (theme) => ({
  droppableView: {
    minHeight: 640,
    height: "auto",
  },
  dateView: {
    display: "flex",
    marginBottom: 25,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginTop: -2,
  },
  timerIcon: {
    fontSize: 16,
  },
  borderCardCol: {
    border: "1px solid #ecf1f5",
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 8,
    width: "calc(100vw / 8)",
  },
  bgToday: {
    backgroundColor: theme.palette.hourTodayBackground,
  },
  RtableCell: {
    boxSizing: "border-box",
    flexGrow: 1,
    minWidth: 170,
    padding: "8px",
    overflow: "hidden", // Or flex might break
    textAlign: "center",
    zIndex: 1,
    border: `0.5px solid ${theme.palette.divider}`,
  },
  webkitSticky: {
    position: "-webkit-sticky",
  },
  resourceBox: {
    position: "sticky",
    left: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
  },
  userContainer: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
  userIconContainer: {
    fontSize: 32,
    color: theme.palette.info.main,
  },
  userCardTitle: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  avatar: {
    width: 25,
    height: 25,
    marginRight: 10,
    marginBottom: 10,
    fontSize: 13,
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  userCardDepartment: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  userTotalContainer: {
    display: "flex",
    paddingTop: 2,
    color: theme.palette.text.secondary,
  },
  userTotalIcons: {
    paddingRight: 3,
    display: "flex",
  },
  userTotalText: {
    fontSize: 12,
  },
});

const baseUrl = import.meta.env.VITE_API_BASE_URL;

class WeekDay extends React.Component {
  state = {};

  render = () => {
    const { state, classes, checkInStatus, weekdays, ...others } = this.props;

    // use moment locale to give day name in english, for comparing the dayName which is in English
    var enLocale = moment();
    enLocale.locale("en");
    const weekdaysLength = weekdays.length;

    let userPlans = [];
    if (state.hourlist && state.userId) {
      if (Object.prototype.hasOwnProperty.call(state.hourlist, state.userId)) {
        userPlans = state.hourlist[state.userId];
      }
    }

    return (
      <React.Fragment>
        <React.Fragment key={`unassigned`}>
          <div
            key={`un-div`}
            className={`${classes.RtableCell} ${classes.webkitSticky} ${classes.resourceBox}`}
            style={{
              width: weekdays.length === 7 ? `calc(100vw / 8)` : 220,
              zIndex: "3 !important",
              minHeight: 100,
            }}
          >
            <Typography
              component="div"
              className={classes.userContainer}
              key={`c-un`}
            >
              <Typography component="div">
                <SvgIcon className={classes.userIconContainer}>
                  <path
                    d={
                      "M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 2.75c1.24 0 2.25 1.01 2.25 2.25S10.24 8.25 9 8.25 6.75 7.24 6.75 6 7.76 3.75 9 3.75zM9 14.5c-1.86 0-3.49-.92-4.49-2.33C4.62 10.72 7.53 10 9 10c1.47 0 4.38.72 4.49 2.17-1 1.41-2.63 2.33-4.49 2.33z"
                    }
                  />
                </SvgIcon>
              </Typography>
              <Typography component="div" style={{ overflow: "hidden" }}>
                <Typography
                  component="div"
                  noWrap
                  className={classes.userCardTitle}
                >
                  {i18next.t("Unassigned")}
                </Typography>
              </Typography>
            </Typography>
          </div>

          {weekdays &&
            weekdays.length > 0 &&
            weekdays.map((data, index) => {
              return (
                <div
                  className={`${classes.RtableCell} ${
                    data.isToday ? classes.bgToday : ""
                  } `}
                  key={`u${index}${data.index}`}
                  style={{
                    width: weekdays.length === 7 ? `calc(100vw / 8)` : 220,
                  }}
                >
                  <PlanCardUnassigned
                    dayNumber={data.index}
                    dateStr={data.dateStr}
                    isToday={data.isToday}
                    state={state}
                    {...others}
                  />
                </div>
              );
            })}
        </React.Fragment>

        <React.Fragment>
          <div
            className={`${classes.RtableCell} ${classes.webkitSticky} ${classes.resourceBox}`}
            key={`c-us`}
            style={{
              width: weekdays.length === 7 ? `calc(100vw / 8)` : 220,
            }}
          >
            <Typography component="div" className={classes.userContainer}>
              <Typography component="div">
                <Avatar
                  alt="User"
                  className={classes.avatar}
                  src={
                    state.userAvatar ? `${baseUrl}${state.userAvatar}` : null
                  }
                  style={{ background: "#919EAB" }}
                >
                  {getInitials(state.userName)}
                </Avatar>
              </Typography>
              <Typography
                component="div"
                style={{ overflow: "hidden", textAlign: "left" }}
              >
                <Typography
                  component="div"
                  noWrap
                  className={classes.userCardTitle}
                >
                  {state.userName}
                </Typography>
                <Typography
                  component="div"
                  noWrap
                  className={classes.userCardDepartment}
                >
                  {state.userCompany}
                </Typography>
                <Typography
                  component="div"
                  className={classes.userTotalContainer}
                >
                  <Typography
                    component="div"
                    className={classes.userTotalIcons}
                  >
                    <AccessTimeFilled className={classes.timerIcon} />
                  </Typography>
                  <Typography component="p" className={classes.userTotalText}>
                    {state.userTotalHours &&
                    state.userTotalHours[state.userId] ? (
                      <DecimalToHoursMin>
                        {state.userTotalHours[state.userId]}
                      </DecimalToHoursMin>
                    ) : (
                      "0m"
                    )}
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </div>

          {weekdays &&
            weekdays.length > 0 &&
            weekdays.map((data, index) => {
              let dateStr = data.dateStr;
              return (
                <div
                  key={index}
                  className={`${classes.borderCardCol} ${
                    data.isToday ? classes.bgToday : ""
                  } ${classes.RtableCell}`}
                  style={{
                    width: weekdaysLength === 7 ? `calc(100vw / 8)` : 220,
                  }}
                >
                  <div className={classes.droppableView}>
                    {userPlans && userPlans.length > 0
                      ? userPlans.map((taskId, index) => {
                          return (
                            dateStr === taskId.start_date_str && (
                              <HourCard
                                key={index}
                                index={index}
                                data={taskId}
                                isToday={data.isToday}
                                checkInStatus={checkInStatus}
                                checkinStep={state.checkinStep}
                                checkinPlan={state.checkinPlan}
                                timeDuration={state.timeDuration}
                                isHourCheckin={state.isHourCheckin}
                                startDate={this.props.startDate}
                              />
                            )
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            })}
        </React.Fragment>
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(WeekDay);
