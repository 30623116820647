import createReducer from "./createReducer";
import * as types from "@/redux/actions/types";
import { logout } from "@/utils/api.config";

const initialState = {
  isLoading: false,
  companyModules: {},
  companySettings: {},
  updateCompanySetting: {},
  updateCompanyDetails: {},
  apiCall: {},
  error: null,
  apiCallError: null,
  lastFetchTransactions: null,
  lastFetchTransactionsError: null,
  companyContract: {},
  updateCompanyContract: {},
  companyDetails: null,
};

export const companyReducer = createReducer(initialState, {
  [types.COMPANY_MODULES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.COMPANY_MODULES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      companyModules: action.payload,
      isLoading: false,
    });
  },
  [types.COMPANY_MODULES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_COMPANY_SETTINGS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_COMPANY_SETTINGS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      companySettings: action.payload,
      isLoading: false,
    });
  },
  [types.GET_COMPANY_SETTINGS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_SETTINGS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      updateCompanySetting: {},
      error: null,
    });
  },
  [types.PUT_COMPANY_SETTINGS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      updateCompanySetting: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_SETTINGS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_COMPANY_DETAILS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_COMPANY_DETAILS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      companyDetails: action.payload,
      isLoading: false,
    });
  },
  [types.GET_COMPANY_DETAILS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_DETAILS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.PUT_COMPANY_DETAILS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      updateCompanyDetails: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_DETAILS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.PUT_CHANGE_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.PUT_CHANGE_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      updateCompanyDetails: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_CHANGE_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.PUT_CHANGE_INVOICE_DETAILS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.PUT_CHANGE_INVOICE_DETAILS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      updateCompanyDetails: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_CHANGE_INVOICE_DETAILS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_COMPANY_SETTING_VALUE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      updateCompanySetting: {},
      error: null,
    });
  },
  [types.ADD_COMPANY_SETTING_VALUE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      updateCompanySetting: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_COMPANY_SETTING_VALUE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_SETTING_VALUE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      updateCompanySetting: {},
      error: null,
    });
  },
  [types.PUT_COMPANY_SETTING_VALUE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      updateCompanySetting: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_SETTING_VALUE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.CALL_COMPANY_SETTING_API](state) {
    return Object.assign({}, state, {
      isLoading: true,
      apiCall: {},
      error: null,
      apiCallError: null,
    });
  },
  [types.CALL_COMPANY_SETTING_API_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      apiCall: action.payload,
      isLoading: false,
    });
  },
  [types.CALL_COMPANY_SETTING_API_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      apiCallError: action.payload,
      isLoading: false,
    });
  },

  [types.GET_LAST_FETCH_TRANSACTIONS](state) {
    return Object.assign({}, state, {
      isLoading: true,
      lastFetchTransactions: null,
      lastFetchTransactionsError: null,
    });
  },
  [types.GET_LAST_FETCH_TRANSACTIONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      lastFetchTransactions: action.payload,
      isLoading: false,
    });
  },
  [types.GET_LAST_FETCH_TRANSACTIONS_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...state,
      lastFetchTransactionsError: action.payload,
      isLoading: false,
    });
  },
  [types.COMPANY_CONTRACT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.COMPANY_CONTRACT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      companyContract: action.payload,
      isLoading: false,
    });
  },
  [types.COMPANY_CONTRACT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_CONTRACT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.PUT_COMPANY_CONTRACT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      updateCompanyContract: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_CONTRACT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      updateCompanyContract: action.payload,
      isLoading: false,
    });
  },
});
