import React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Container, Typography } from "@mui/material";
import Lottie from "react-lottie-player";
import checkinAnimation from "./checkinAnimation.json";

const CheckInDialog = ({ open, message, onClose }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <div
        style={{
          minWidth: 600,
          height: 600,
        }}
      >
        <Box
          component="main"
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
          }}
        >
          <Container maxWidth="sm">
            <Box sx={{ my: 3, textAlign: "center", alignItems: "center" }}>
              <Lottie
                animationData={checkinAnimation}
                play
                style={{
                  margin: "auto",
                  marginBottom: 75,
                  width: 300,
                  height: 300,
                }}
              />
              <Typography variant="h4" style={{ marginTop: -10 }}>
                Check-in successfully!
              </Typography>
              {message && (
                <Typography variant="h6" style={{ marginTop: 10 }}>
                  {message}
                </Typography>
              )}
            </Box>
          </Container>
        </Box>
      </div>
    </Dialog>
  );
};

export default CheckInDialog;
