import { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Card,
  CardHeader,
  Stack,
  LinearProgress,
  SvgIcon,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  getHourTypesSummary,
  getAddonTypesSummary,
  getFixedAddonTypesSummary,
} from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import moment from "moment";
import i18next from "i18next";
import Label from "@/common/Label";

const useStyles = makeStyles((theme) => ({
  textTruncate: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  root: {
    borderRadius: 16,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
    paddingRight: "8px",
    paddingLeft: "8px",
    paddingBottom: 0,
  },
  cardHeader: {
    padding: 0,
    paddingTop: 24,
    paddingBottom: 12,
  },

  hourName: {
    flexGrow: 1,
    marginLeft: 8,
  },

  expandButton: {
    width: "100%",
    padding: 14,
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.text.primary}`,
    "&:hover": {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: `${theme.palette.text.primary}0d`,
    },
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },

  actionButtons: {
    display: "flex",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  actionButton: {
    fontSize: 16,
    fontWeight: 700,
    padding: "16px 60px",
    margin: "13px",
  },
}));

const HourTypeSummary = (props) => {
  const { userId, isAddonModule } = props;

  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [hourTypeSummaryList, setHourTypeSummaryList] = useState([]);
  const [addonTypeSummaryList, setAddonTypeSummaryList] = useState([]);
  const [fixedAddonTypeSummaryList, setFixedAddonTypeSummaryList] = useState(
    []
  );

  const showSummaryDialog = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    const getHourTypeSummaryList = async (queryString) => {
      try {
        const response = await getHourTypesSummary(userId, queryString);
        if (response.data && response.data.length > 0) {
          setHourTypeSummaryList(response.data);
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        console.log(message);
      }
    };

    const getFixedAddonTypeSummaryList = async (queryString) => {
      try {
        const response = await getFixedAddonTypesSummary(userId, queryString);
        if (response.data && response.data.length > 0) {
          setFixedAddonTypeSummaryList(response.data);
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        console.log(message);
      }
    };

    const getAddonTypeSummaryList = async (queryString) => {
      try {
        const response = await getAddonTypesSummary(userId, queryString);
        if (response.data && response.data.length > 0) {
          setAddonTypeSummaryList(response.data);
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        console.log(message);
      }
    };

    const startDate = moment().clone().startOf("week").format("YYYY-MM-DD");
    const endDate = moment().clone().endOf("week").format("YYYY-MM-DD");

    const queryString = `start_time=${startDate}&end_time=${endDate}`;

    getHourTypeSummaryList(queryString);
    getFixedAddonTypeSummaryList(queryString);
    if (isAddonModule) {
      getAddonTypeSummaryList(queryString);
    }
  }, [isAddonModule, userId]);

  const fixedAddonTypeSummary =
    fixedAddonTypeSummaryList?.map((value) => ({
      ...value,
      isFixedAddon: true,
    })) || [];
  const hourTypes = hourTypeSummaryList.map((hour) => hour.amount);
  const addonTypes = addonTypeSummaryList.map((hour) => hour.amount);
  const maxHours = Math.max(...hourTypes, ...addonTypes);

  const hourTypesPercent = hourTypes.map((hour) => (hour * 100) / maxHours);
  const addonTypesPercent = addonTypes.map((hour) => (hour * 100) / maxHours);

  const newHourTypeSummary = hourTypeSummaryList
    .map((percent, i) => {
      return {
        ...percent,
        percentageOfHour: hourTypesPercent[i],
        type: "hour",
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  const newAddonTypeSummary = addonTypeSummaryList
    .map((percent, i) => {
      return {
        ...percent,
        percentageOfAddon: addonTypesPercent[i],
        type: "addon",
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  const totalHourTypes = newHourTypeSummary.length;
  const totalTimeAddons = newAddonTypeSummary.length;
  const totalFixedAddons = fixedAddonTypeSummary.length;
  const totalCount = totalHourTypes + totalTimeAddons + totalFixedAddons;

  const itemsToShow = 5;
  const typeArray = [];
  let itemCounter =
    totalHourTypes > 0
      ? totalHourTypes >= itemsToShow
        ? itemsToShow
        : totalHourTypes
      : 0;

  for (let i = 0; i < itemCounter; i++) {
    typeArray.push(newHourTypeSummary[i]);
  }

  if (itemCounter < itemsToShow) {
    const itemsRemained = itemsToShow - itemCounter;
    const itemToIterate =
      totalTimeAddons > 0
        ? totalTimeAddons >= itemsRemained
          ? itemsRemained
          : totalTimeAddons
        : 0;

    for (let i = 0; i < itemToIterate; i++) {
      typeArray.push(newAddonTypeSummary[i]);
    }

    itemCounter += itemToIterate;
  }

  if (itemCounter < itemsToShow) {
    const itemsRemained = itemsToShow - itemCounter;
    const itemToIterate =
      totalFixedAddons > 0
        ? totalFixedAddons >= itemsRemained
          ? itemsRemained
          : totalFixedAddons
        : 0;

    for (let i = 0; i < itemToIterate; i++) {
      typeArray.push(fixedAddonTypeSummary[i]);
    }

    itemCounter += itemToIterate;
  }

  return (
    <>
      <CardHeader
        className={classes.cardHeader}
        title={i18next.t("Hour types and addons")}
        titleTypographyProps={{
          fontSize: 20,
        }}
      />
      <Card className={classes.root}>
        <Stack spacing={3} sx={{ p: 3, pb: 2 }}>
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            sx={{ justifyContent: "space-between" }}
          >
            <Label variant="outlined" color="primary">
              {i18next.t(`${totalHourTypes} Hour types`)}
            </Label>
            <Label variant="outlined" color="primary">
              {i18next.t(`${totalTimeAddons} Time addons`)}
            </Label>
            <Label variant="outlined" color="primary">
              {i18next.t(`${totalFixedAddons} Fixed addons`)}
            </Label>
          </Stack>
          <Stack spacing={2.5} sx={{ pb: 1 }}>
            {itemCounter > 0 &&
              typeArray.map((item, i) => {
                return item.isFixedAddon ? (
                  <FixedProgressBar
                    key={i}
                    name={item?.name || ""}
                    amount={item?.measurement_value || 0}
                    label={item?.measurement}
                    color={item?.color}
                    isLast={i === typeArray.length - 1}
                  />
                ) : (
                  <LinearProgressBar
                    key={i}
                    name={item?.name || ""}
                    amount={item?.amount || 0}
                    percentage={item?.percentage || 0}
                    color={item?.type === "addon" ? "secondary" : "primary"}
                    type={item?.type}
                  />
                );
              })}

            {totalCount === 0 && (
              <Stack spacing={3} style={{ paddingLeft: 8 }}>
                <Typography variant="body2">
                  {i18next.t("No data to display")}
                </Typography>
              </Stack>
            )}

            {totalCount > 5 && (
              <Button
                fullWidth
                onClick={showSummaryDialog}
                color="primary"
                variant="outlined"
              >
                {i18next.t("View full summary")}
              </Button>
            )}
          </Stack>
          <AddonDialog
            showDialog={showDialog}
            onCloseDialog={onCloseDialog}
            hourTypes={newHourTypeSummary}
            addonTypes={newAddonTypeSummary}
            fixedAddonTypes={fixedAddonTypeSummary}
          />
        </Stack>
      </Card>
    </>
  );
};

function LinearProgressBar({ name, amount, percentage, color, type }) {
  const classes = useStyles();

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        {type === "hour" ? (
          <SvgIcon className={classes.primary}>
            <CheckCircleIcon className={classes.icon} />
          </SvgIcon>
        ) : (
          <SvgIcon className={classes.secondary}>
            <path
              d="M19,11H15V15H13V11H9V9H13V5H15V9H19M20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M4,6H2V20A2,2 0 0,0 4,22H18V20H4V6Z"
              className={classes.icon}
            />
          </SvgIcon>
        )}

        <Typography
          variant="body2"
          className={`${classes.hourName} ${classes.textTruncate}`}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          style={{ display: "flex", whiteSpace: "nowrap" }}
        >
          <DecimalToHoursMin>{amount}</DecimalToHoursMin>
        </Typography>
      </Stack>

      <LinearProgress variant="determinate" value={percentage} color={color} />
    </Stack>
  );
}

function FixedProgressBar({ name, amount, label, color, isLast }) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" style={{ flexGrow: 1 }}>
          {name}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" style={{ marginRight: 6 }}>
            {amount}
          </Typography>
          <Label variant="custom" hexColor={color} height="small">
            {label}
          </Label>
        </div>
      </Stack>

      {!isLast && (
        <LinearProgress
          variant="determinate"
          value={0}
          color={"inherit"}
          sx={{ height: "1px", opacity: "0.4" }}
        />
      )}
    </Stack>
  );
}

function AddonDialog({
  hourTypes,
  addonTypes,
  fixedAddonTypes,
  showDialog,
  onCloseDialog,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={showDialog}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={onCloseDialog}
    >
      <DialogContent>
        <Typography variant="h5">{i18next.t("Hour types")}</Typography>
        <Stack spacing={3.5} sx={{ p: 1.5, pb: 4 }}>
          {hourTypes.length > 0 ? (
            hourTypes.map((item, i) => {
              return (
                <LinearProgressBar
                  key={i}
                  name={item?.name || ""}
                  amount={item?.amount || 0}
                  percentage={item?.percentage || 0}
                  color={"primary"}
                  type={item?.type}
                />
              );
            })
          ) : (
            <Typography variant="body2">
              {i18next.t("No data to display")}
            </Typography>
          )}
        </Stack>

        <Typography variant="h5">{i18next.t("Time addons")}</Typography>
        <Stack spacing={3.5} sx={{ p: 1.5, pb: 4 }}>
          {addonTypes.length > 0 ? (
            addonTypes.map((item, i) => {
              return (
                <LinearProgressBar
                  key={i}
                  name={item?.name || ""}
                  amount={item?.amount || 0}
                  percentage={item?.percentage || 0}
                  color={"secondary"}
                  type={item?.type}
                />
              );
            })
          ) : (
            <Typography variant="body2">
              {i18next.t("No data to display")}
            </Typography>
          )}
        </Stack>

        <Typography variant="h5">{i18next.t("Fixed addons")}</Typography>
        <Stack spacing={2.5} sx={{ p: 1.8, justifyItems: "center" }}>
          {fixedAddonTypes.length > 0 ? (
            fixedAddonTypes.map((item, i) => {
              return (
                <FixedProgressBar
                  key={i}
                  name={item?.name || ""}
                  amount={item?.measurement_value || 0}
                  label={item?.measurement}
                  color={item?.color}
                  isLast={i === fixedAddonTypes.length - 1}
                />
              );
            })
          ) : (
            <Typography variant="body2">
              {i18next.t("No data to display")}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          type="submit"
          onClick={onCloseDialog}
          size="large"
          variant="contained"
          className={classes.actionButton}
        >
          {i18next.t("Done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HourTypeSummary;
