import React from "react";
import i18next from "i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnDanger: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  header: {
    marginBottom: 4,
    padding: theme.spacing(2),
  },
  contentView: {
    marginBottom: 4,
    padding: theme.spacing(2),
  },
}));

const DeleteModal = (props) => {
  const { isOpen, handleCancel, handleSubmit } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle className={classes.header}>
        {i18next.t("Confirm")}
      </DialogTitle>
      <DialogContent className={classes.contentView}>
        {i18next.t("Are you sure?")}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className={classes.btnDanger}>
          {i18next.t("Cancel")}
        </Button>
        <Button onClick={handleSubmit} className={classes.button}>
          {i18next.t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteModal;
