import React from "react";
import WeekDay from "./weekday";
import { withStyles } from "@mui/styles";
import { Card } from "@mui/material";
import WeekHeader from "./WeekHeader";

const styles = () => ({
  tableCard: {
    borderRadius: 0,
    height: "calc(100vh - 100px)",
    overflow: "auto",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
});

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  render = () => {
    const { state, classes, weekdays, ...others } = this.props;

    return (
      <React.Fragment>
        <div style={{ padding: 0 }}>
          <Card className={classes.tableCard}>
            <div
              className={classes.row}
              style={{
                minWidth:
                  weekdays.length === 7
                    ? (weekdays.length + 1) * 170
                    : (weekdays.length + 1) * 220,
                width:
                  weekdays.length < 7 ? (weekdays.length + 1) * 220 : "110%",
              }}
            >
              <WeekHeader
                weekdays={weekdays}
                changeState={this.changeState}
                state={state}
                {...others}
              />

              <WeekDay state={state} weekdays={weekdays} {...others} />
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(Table);
