import React from "react";
import TextTruncate from "react-text-truncate";
import { withStyles } from "@mui/styles";
import { Card, Typography, Tooltip, Button, Divider } from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import i18next from "i18next";
import moment from "moment";

const styles = (theme) => ({
  card: {
    color: theme.palette.text.primary,
    padding: 12,
    marginTop: 12,
    borderRadius: "0.5rem",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: theme.customShadows.hourCard,
    backgroundColor: theme.palette.background.paper,
  },
  spantxtSize: {
    fontWeight: "bold",
    fontSize: 14,
    alignItems: "center",
    width: "100%",
    marginBottom: 8,
    color: theme.palette.text.main,
  },
  borderParent: {
    display: "flex",
    marginBottom: 8,
  },
  borderLine: {
    width: "4px",
    borderRadius: "1rem",
  },
  projectLine: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    fontWeight: "bold",
    marginBottom: 8,
  },
  projectName: {
    // display: "flex",
    display: "block",
    fontSize: 14,
    width: "100%",
  },
  checkInText: {
    fontSize: 14,
    display: "flex",
    fontWeight: 700,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  spanTaskName: {
    width: "100%",
    fontSize: 14,
    paddingLeft: "1rem",
  },
  spanTypeName: {
    width: "100%",
    alignItems: "center",
    fontSize: 14,
    paddingLeft: "1rem",
    color: theme.palette.primary.main,
  },
  cardDivider: {
    marginBottom: 8,
  },
  checkinBlock: {
    color: "#18b24b",
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 8,
  },
  innerCard: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
});

class CheckInCard extends React.Component {
  constructor() {
    super();
    this.state = {
      durationStr: null,
    };
    this.timerId = null;
  }

  componentDidMount = () => {
    this.renderTime();
  };

  renderTime = () => {
    const timerId = setInterval(() => {
      this.renderTimeStr();
    }, 2000);

    this.timerId = timerId;
  };

  renderTimeStr = () => {
    const { data } = this.props;

    let durationStr = "";
    const currentTime = moment(data.start_time)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS");

    let timeDuration = "";

    const timeDeference = moment().diff(moment(currentTime));
    timeDuration = moment.duration(timeDeference);

    if (timeDuration && timeDuration.days() > 0) {
      durationStr = `${timeDuration.days()}d ${timeDuration.hours()}h ${timeDuration.minutes()}m ${timeDuration.seconds()}s`;
    } else {
      durationStr = `${timeDuration.hours()}h ${timeDuration.minutes()}m ${timeDuration.seconds()}s`;
    }

    this.setState({ durationStr });
  };

  componentWillUnmount = () => {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  };

  render = () => {
    const { data, classes, isTruncateLines, innerCard, handleCheckOut } =
      this.props;

    return (
      <React.Fragment>
        <Typography component="div">
          <Card className={`${classes.card} ${innerCard && classes.innerCard}`}>
            <Typography
              component="div"
              className={classes.projectLine}
              variant="h2"
            >
              <Typography component="div" className={classes.projectName}>
                {isTruncateLines ? (
                  <Tooltip title={data.project_name} placement="top">
                    <Typography
                      component="div"
                      className={`${classes.spantxtSize}`}
                    >
                      <TextTruncate
                        line={1}
                        truncateText="…"
                        text={data.project_name}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    component="div"
                    className={`${classes.spantxtSize}`}
                  >
                    {data.project_name}
                  </Typography>
                )}

                <Typography className={classes.borderParent}>
                  <Typography
                    className={classes.borderLine}
                    style={{
                      backgroundColor: data.project_color
                        ? `${data.project_color}`
                        : "#18B24B",
                    }}
                  ></Typography>
                  {isTruncateLines ? (
                    <Tooltip title={data.task_name} placement="top">
                      <Typography
                        component="div"
                        className={classes.spanTaskName}
                      >
                        <TextTruncate
                          line={1}
                          truncateText="…"
                          text={data.task_name}
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      component="div"
                      className={classes.spanTaskName}
                    >
                      {data.task_name}
                    </Typography>
                  )}
                </Typography>

                {data.hour_type_name && (
                  <Typography className={classes.borderParent}>
                    <Typography
                      className={classes.borderLine}
                      style={{
                        backgroundColor: data.project_color
                          ? `${data.project_color}`
                          : "#18B24B",
                      }}
                    ></Typography>
                    {isTruncateLines ? (
                      <Tooltip title={data.hour_type_name} placement="top">
                        <Typography
                          component="div"
                          className={classes.spanTypeName}
                        >
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={data.hour_type_name}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component="div"
                        className={classes.spanTypeName}
                      >
                        {data.hour_type_name}
                      </Typography>
                    )}
                  </Typography>
                )}
              </Typography>
            </Typography>

            <Divider className={classes.cardDivider}></Divider>

            <Button
              variant="contained"
              color="error"
              fullWidth
              className={classes.checkInStopButton}
              startIcon={<StopCircleIcon />}
              onClick={() => {
                handleCheckOut(data);
              }}
            >
              {this.state?.durationStr ?? i18next.t("Check out")}
            </Button>
          </Card>
        </Typography>
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(CheckInCard);
