// ----------------------------------------------------------------------

export default function DateCalendar() {
  return {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .PrivatePickersSlideTransition-root": {
            minHeight: "236px",
          },
        },
      },
    },
  };
}
