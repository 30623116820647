import React from "react";
import { withStyles } from "@mui/styles";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const styles = (theme) => ({
  addEntryButton: {
    marginRight: 4,
    width: 40,
    height: 40,
    borderRadius: 8,
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "#00000000",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
  svgAdd: {
    height: 24,
    width: 24,
  },
});

const CreateTaskButton = ({ onClick, classes, id }) => {
  return (
    <Fab onClick={onClick} className={classes.addEntryButton} id={id}>
      <AddIcon className={classes.svgAdd} />
    </Fab>
  );
};

export default withStyles(styles)(CreateTaskButton);
