import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import validate from "validate.js";
import ThemeProvider from "@/theme";
import { ToggleMenubarProvider } from "@/contexts/ToggleMenubarContext";
import { SnackbarProvider } from "notistack";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@/assets/index.css";
import validators from "@/utils/validators";
import Routes from "./Routes";
import Store from "@/state/Store";
import { Provider } from "react-redux";
import { store as reduxStore, persistor } from "@/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import ErrorPage from "@/common/ErrorPage";

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUIX_LICENSE_KEY);

const fallback = <ErrorPage />;

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators,
};

function App() {
  return (
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <Sentry.ErrorBoundary fallback={fallback}>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <ToggleMenubarProvider>
                <Store>
                  <Router history={browserHistory}>
                    <Routes />
                  </Router>
                </Store>
              </ToggleMenubarProvider>
            </SnackbarProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
