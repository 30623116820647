import * as profileAction from "./profile";
import * as hourAction from "./hour";
import * as departmentAction from "./department";
import * as projectAction from "./projects";
import * as taskAction from "./task";
import * as customerAction from "./customer";
import * as hourTypeAction from "./hourType";
import * as companyAction from "./company";
//import * as reportAction from "./report";
import * as commonAction from "./common";
//import * as devianceAction from "./deviance";
//import * as categoryAction from "./category";
import * as hmskTemplateAction from "./hmskTemplate";
import * as hmskCheckListAction from "./hmskCheckList";
import * as planningAction from "./planning";
//import * as rulesAction from "./rules";
import * as addonTypeAction from "./addonType";
import * as geoFenceAction from "./geoFence";
//import * as userGroupsAction from "./userGroups";
//import * as holidaysAction from "./holidays";
//import * as schedulesActions from "./schedules";
//import * as dashboardActions from "./dashboard";
//import * as itemActions from "./item";

export const ActionCreators = Object.assign(
  {},
  profileAction,
  hourAction,
  departmentAction,
  projectAction,
  taskAction,
  customerAction,
  hourTypeAction,
  companyAction,
  //reportAction,
  commonAction,
  //devianceAction,
  //categoryAction,
  hmskTemplateAction,
  hmskCheckListAction,
  planningAction,
  //rulesAction,
  addonTypeAction,
  geoFenceAction
  //userGroupsAction,
  //holidaysAction,
  //schedulesActions,
  //dashboardActions,
  //itemActions
);
