import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import withStyles from "@mui/styles/withStyles";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import { MaterialDatePicker } from "@/common/Pickers";
import { getDateFormat } from "@/utils/dateTimeFunctions";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import i18next from "i18next";
import { green } from "@mui/material/colors";

const styles = (theme) => ({
  root: {},
  button: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnCancel: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnDanger: {
    color: theme.palette.error.main,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  header: {
    marginBottom: 7,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButtons: {
    padding: "8px 10px 10px 10px",
  },
  formControl: {
    marginBottom: 15,
    width: "100%",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  selectMenuItem: {
    fontSize: 16,
  },
  dateIcon: {
    top: "15px",
    right: "12px",
    position: "absolute",
  },
  radioContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  radioTitle: {
    width: "80px",
    marginBottom: 0,
    flexWrap: "nowrap",
  },
  radioLabel: {
    marginBottom: 0,
  },
});

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
class AddItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProjectOption: null,
      selectedTask: null,
      selectedItemType: null,
      itemAmount: "",
      itemDate: moment(),
      assignedTo: "project",
      expiryDate: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.defaultUserProject !== this.props.defaultUserProject &&
      this.props.defaultUserProject
    ) {
      this.setState({
        selectedProjectOption: this.props.defaultUserProject,
      });
    }
    if (
      prevProps.defaultUserTask !== this.props.defaultUserTask &&
      this.props.defaultUserTask
    ) {
      this.setState({
        selectedTask: this.props.defaultUserTask,
      });
    }

    if (
      this.props.editId &&
      this.props.currentItem &&
      prevProps.currentItem !== this.props.currentItem
    ) {
      this.setState({
        itemDate: moment(this.props.currentItem.date, "YYYY-MM-DD"),
        itemAmount: this.props.currentItem.amount,
        selectedItemType: {
          value: this.props.currentItem.item_type_id,
          label: this.props.currentItem.item_type_name,
        },
      });

      if (this.props.currentItem.itemable_type === "Task") {
        this.setState({
          assignedTo: "project",
          selectedProjectOption: {
            value: this.props.currentItem.project_id,
            label: this.props.currentItem.project_name,
          },
          selectedTask: {
            value: this.props.currentItem.itemable_id,
            label: this.props.currentItem.task_name,
          },
        });
      } else {
        this.setState({
          assignedTo: "user",
          selectedUserOption: [
            {
              value: this.props.currentItem.itemable_id,
              label: this.props.currentItem.user_name,
            },
          ],
          expiryDate: this.props.currentItem.expiration_date
            ? moment(this.props.currentItem.expiration_date, "YYYY-MM-DD")
            : null,
        });
      }
    }

    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.isOpen &&
      !this.props.editId
    ) {
      this.resetFormValues();
    }
  };

  resetFormValues = () => {
    const { defaultUserProject, defaultUserTask } = this.props;
    this.setState({
      selectedProjectOption: defaultUserProject,
      selectedTask: defaultUserTask,
      selectedItemType: null,
      selectedUserOption: null,
      itemAmount: "",
      itemDate: moment(),
      expiryDate: null,
      assignedTo: "project",
    });
  };

  handleCancel = () => {
    this.resetFormValues();
    this.props.handleCancel();
  };

  onSubmit = () => {
    const object = {
      amount: this.state.itemAmount,
      date: moment(this.state.itemDate).format("YYYY-MM-DD"),
      item_type_id: this.state.selectedItemType.value,
    };

    if (this.state.assignedTo === "project") {
      object["project_id"] = this.state.selectedProjectOption?.value;
      object["itemable_id"] = this.state.selectedTask.value;
      object["itemable_type"] = "Task";
    } else {
      const user_ids = [];
      if (
        this.state.selectedUserOption &&
        this.state.selectedUserOption.length
      ) {
        for (let i = 0; i < this.state.selectedUserOption.length; i++) {
          user_ids.push(this.state.selectedUserOption[i].value);
        }
      }

      object["itemable_id"] = user_ids.join(",");
      object["itemable_type"] = "User";

      if (this.state.expiryDate) {
        object["expiration_date"] = moment(this.state.expiryDate).format(
          "YYYY-MM-DD"
        );
      }
    }

    this.props.handleFormSubmit(object);
    this.handleCancel();
  };

  onClose = () => {
    this.handleCancel();
  };

  handleAssignOptionChange = (event) => {
    this.setState({
      assignedTo: event.target.value,
    });
  };

  render = () => {
    const { classes, isOpen, editId, isAdmin, title } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isOpen}
        onClose={this.onClose}
      >
        <DialogTitle onClose={this.onClose} className={classes.header}>
          {title}
          <IconButton
            aria-label={i18next.t("Close")}
            className={classes.closeButton}
            onClick={this.onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ minHeight: "150px" }}>
          <FormControl className={classes.formControl}>
            <MaterialDatePicker
              label={i18next.t("Date")}
              value={this.state.itemDate}
              format={getDateFormat()}
              onChangeDate={async (date) => {
                this.setState({
                  itemDate: date,
                });
              }}
            />
          </FormControl>
          {isAdmin && (
            <Typography
              component="div"
              className={classes.radioContainer}
              id="item-modal-assign-switch"
            >
              <FormLabel component="legend" className={classes.radioTitle}>
                {i18next.t("Assign to")}
              </FormLabel>
              <RadioGroup
                aria-label="assign-options"
                row
                name="assignOptions"
                value={this.state.assignedTo}
                onChange={this.handleAssignOptionChange}
              >
                <FormControlLabel
                  value="project"
                  control={<GreenRadio />}
                  label={i18next.t("Project")}
                  className={classes.radioLabel}
                />
                <FormControlLabel
                  value="user"
                  control={<GreenRadio />}
                  label={i18next.t("User")}
                  className={classes.radioLabel}
                />
              </RadioGroup>
            </Typography>
          )}
          {(!isAdmin || this.state.assignedTo === "project") && (
            <>
              <FormControl
                className={classes.formControl}
                id="item-modal-project"
              >
                <MaterialAsyncPaginate
                  classes={classes}
                  textFieldProps={{
                    label: i18next.t("Project"),
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  defaultOptions
                  loadOptions={async (search, loadedOptions) => {
                    return this.props.loadProjectList(search, loadedOptions);
                  }}
                  value={this.state.selectedProjectOption}
                  onChange={async (selected) => {
                    this.props.onSelectProject(parseInt(selected.value, 10));
                    this.setState({
                      selectedProjectOption: selected,
                      selectedTask: null,
                    });
                  }}
                  id="item-modal-project-input"
                />
              </FormControl>
              <FormControl className={classes.formControl} id="item-modal-task">
                <MaterialAsyncPaginate
                  classes={classes}
                  textFieldProps={{
                    label: i18next.t("Task"),
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  isDisabled={!this.state.selectedProjectOption}
                  loadOptions={async (search, loadedOptions) => {
                    return this.props.loadTaskOptions(search, loadedOptions);
                  }}
                  value={this.state.selectedTask}
                  onChange={async (selected) => {
                    this.setState({
                      selectedTask: selected,
                    });
                  }}
                  cacheUniqs={[this.state.selectedProjectOption]}
                  id="item-modal-task-input"
                />
              </FormControl>
            </>
          )}
          {isAdmin && this.state.assignedTo === "user" && (
            <>
              <FormControl
                className={classes.formControl}
                id="item-modal-user-list"
              >
                <MaterialAsyncPaginate
                  classes={classes}
                  textFieldProps={{
                    label: i18next.t("User"),
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  isMulti={true}
                  defualtOptions
                  loadOptions={async (search, loadedOptions) => {
                    return this.props.loadUserList(search, loadedOptions);
                  }}
                  value={this.state.selectedUserOption}
                  onChange={(selected) => {
                    this.setState({
                      selectedUserOption: selected,
                    });
                  }}
                  id="item-modal-user-input"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <MaterialDatePicker
                  label={i18next.t("Expiry date")}
                  clearable={true}
                  value={this.state.expiryDate}
                  format={getDateFormat()}
                  onChangeDate={async (date) => {
                    this.setState({
                      expiryDate: date,
                    });
                  }}
                />
              </FormControl>
            </>
          )}
          <FormControl
            className={classes.formControl}
            id="item-modal-item-type"
          >
            <MaterialAsyncPaginate
              classes={classes}
              textFieldProps={{
                label: i18next.t("Item type"),
                InputLabelProps: {
                  shrink: true,
                },
              }}
              loadOptions={async (search, loadedOptions) => {
                return this.props.loadItemTypeList(search, loadedOptions);
              }}
              value={this.state.selectedItemType}
              onChange={async (selected) => {
                this.setState({
                  selectedItemType: selected,
                });
              }}
              id="item-modal-item-type-input"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              type="number"
              variant="outlined"
              label={i18next.t("item-amount")}
              value={this.state.itemAmount}
              onChange={(e) => {
                this.setState({
                  itemAmount: e.target.value,
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.selectedItemType &&
                    this.state.selectedItemType.UOF
                      ? this.state.selectedItemType.UOF
                      : ""}
                  </InputAdornment>
                ),
              }}
              id="item-modal-item-amount"
            />
          </FormControl>
        </DialogContent>
        <DialogActions
          className={classes.actionButtons}
          style={
            editId
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                }
              : {}
          }
        >
          {editId && (
            <Typography component="div">
              <Button
                onClick={this.props.onDelete}
                className={classes.btnDanger}
                id="item-delete-button-id"
              >
                {i18next.t("Delete")}
              </Button>
            </Typography>
          )}
          <Typography component="div">
            <Button className={classes.btnCancel} onClick={this.onClose}>
              {i18next.t("Cancel")}
            </Button>
            <Button
              color="primary"
              className={classes.button}
              disabled={
                !this.state.itemDate ||
                (this.state.assignedTo === "project" &&
                  !this.state.selectedTask) ||
                (this.state.assignedTo === "user" &&
                  !this.state.selectedUserOption) ||
                !this.state.selectedItemType ||
                !this.state.itemAmount
              }
              onClick={this.onSubmit}
            >
              {i18next.t("Submit")}
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
    );
  };
}

export default withStyles(styles)(AddItemForm);
