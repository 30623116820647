import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GuestDetails from "./components/GuestDetails";
import BackToHomePage from "@/common/BackToHomePage";

import {
  createGuest,
  postGuestAttendance,
  getCompanySettings,
} from "@/services/guest";
import { getErrorObject } from "@/utils/api.config";
import AlertModal from "@/common/AlertModal";
import { geolocated } from "react-geolocated";

import moment from "moment";
import i18next from "i18next";
import { withSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    marginTop: "-90px",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  formContainer: {
    //paddingTop: 100,
    display: "flex",
    justifyContent: "center",
  },
}));

const GuestCheckIn = (props) => {
  const {
    history,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
    coords,
  } = props;

  const classes = useStyles();

  const [checkInError, setCheckInError] = useState(null);
  const [checkInDone, setCheckInDone] = useState(false);
  const [isNewGuest, setIsNewGuest] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isGeoFenceForAttendace, setIsGeoFenceForAttendace] = useState(false);
  const [isGeoFenceDisabled, setIsGeoFenceDisabled] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(true);

  const onGuestCheckin = async (postObj, existingGuestId) => {
    try {
      if (existingGuestId) {
        const attendanceObj = {
          attendanceable_id: existingGuestId,
          visiting: postObj.visiting,
          attendanceable_type: "Guest",
          start_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
          is_utc: true,
        };

        // geo location
        if (isGeoFenceForAttendace && coords) {
          if (isGeoFenceDisabled) {
            attendanceObj["tablet"] = true;
          } else {
            attendanceObj["latitude"] = coords.latitude;
            attendanceObj["longitude"] = coords.longitude;
          }
        }

        postGuestCheckin(attendanceObj);
      } else {
        // extract visiting value
        const guestVisiting = postObj.visiting;
        delete postObj.visiting; // extra element in postObj

        const response = await createGuest(postObj);
        if (response.data && response.data.id) {
          const attendanceObj = {
            attendanceable_id: response.data.id,
            visiting: guestVisiting,
            attendanceable_type: "Guest",
            start_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
            is_utc: true,
          };
          // geo location
          if (isGeoFenceForAttendace && coords) {
            if (isGeoFenceDisabled) {
              attendanceObj["tablet"] = true;
            } else {
              attendanceObj["latitude"] = coords.latitude;
              attendanceObj["longitude"] = coords.longitude;
            }
          }
          postGuestCheckin(attendanceObj);
        }
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      setCheckInError(message);
      props.enqueueSnackbar(message, { variant: "error" });
      //console.log(message);
    }
  };

  const postGuestCheckin = async (attendanceObj) => {
    try {
      const attendanceRes = await postGuestAttendance(attendanceObj);
      if (attendanceRes.data) {
        setCheckInDone(true);
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      setCheckInError(message);
      props.enqueueSnackbar(message, { variant: "error" });
      //console.log(message);
    }
  };

  const onNewGuest = (status) => {
    setIsNewGuest(status);
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    async function checkCompanySetting() {
      try {
        const settings = await getCompanySettings();
        if (settings.data && settings.data.entries) {
          if (settings.data.entries.length > 0) {
            let entries = settings.data.entries;
            if (entries && entries.length > 0) {
              for (let i = 0; i < entries.length; i++) {
                if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Geofence restriction for attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceForAttendace(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Disable geofence on Tablet app"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceDisabled(true);
                  }
                }
              }
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        let { message } = getErrorObject(error);
        setIsLoading(false);
        console.log(message);
      }
    }

    checkCompanySetting();
  }, []);

  if (checkInDone) {
    return (
      <BackToHomePage
        history={history}
        isGuest={true}
        title={i18next.t("You are successfully checked-in.")}
        subHeading={i18next.t("You will be redirected to the home page")}
      />
    );
  } else {
    return (
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <IconButton onClick={handleBack} size="large">
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.contentBody}>
          <Grid container className={classes.formContainer}>
            <Grid item xl={4} lg={4} md={5} sm={10} xs={10}>
              {isLoading.checkInHour || isLoading.companySetting ? (
                <Typography>Loading ...</Typography>
              ) : (
                <GuestDetails
                  onGuestCheckin={onGuestCheckin}
                  checkInError={checkInError}
                  onNewGuest={onNewGuest}
                  isNewGuest={isNewGuest}
                />
              )}
            </Grid>
          </Grid>
        </div>
        {(!isGeolocationAvailable || positionError) &&
        isGeoFenceForAttendace &&
        !isGeoFenceDisabled ? (
          <AlertModal
            isOpen={openAlertModal}
            handleClose={() => {
              setOpenAlertModal(false);
            }}
            title={"Geo Locatoin!"}
            message={
              "Your browser does not support Geolocation. Please use another browser. The Geolocation feature is required for attendance."
            }
          />
        ) : !isGeolocationEnabled &&
          isGeoFenceForAttendace &&
          !isGeoFenceDisabled ? (
          <AlertModal
            isOpen={openAlertModal}
            handleClose={() => {
              setOpenAlertModal(false);
            }}
            title={"Geo Locatoin!"}
            message={
              "Please allow the app to determine your location. This is required for attendance."
            }
          />
        ) : null}
      </div>
    );
  }
};

const geoLockSettings = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
};

export default withSnackbar(geolocated(geoLockSettings)(GuestCheckIn));
