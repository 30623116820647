import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  FormControl,
  Dialog,
  DialogActions,
  Button,
} from "@mui/material";
import { PickersDay, DateCalendar } from "@mui/x-date-pickers";
import i18next from "i18next";
import moment from "moment";

import { useTheme } from "@mui/material/styles";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const WeekRangeDatePicker = ({ value, onChangeDate }) => {
  const theme = useTheme();

  const [date, setDate] = useState(moment());
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  React.useEffect(() => {
    if (value.length) {
      setDate(value[0]);
      setStartDate(value[0]);
      setEndDate(value[1]);
    }
  }, [value]);

  const RenderWeekPickerDay = ({ day, ...others }) => {
    if (!day) {
      return <PickersDay day={day} {...others} />;
    }

    const start = moment(date).startOf("week");
    const end = moment(date).endOf("week").startOf("day");
    const dayIsBetween = moment(day).isBetween(start, end, undefined, "[]");
    const isFirstDay = moment(day).isSame(start);
    const isLastDay = moment(day).isSame(end);

    return (
      <CustomPickersDay
        {...others}
        day={day}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const handleSubmit = () => {
    const begin = moment(date).startOf("week");
    const end = moment(date).endOf("week").startOf("day");
    onChangeDate([begin, end]);

    setIsOpenCalendar(false);
  };

  return (
    <>
      <FormControl>
        <TextField
          variant="standard"
          label={false}
          InputLabelProps={{ shrink: false }}
          value={`${moment(startDate).format("DD MMM")} - ${moment(
            endDate
          ).format("DD MMM")}`}
          onClick={() => setIsOpenCalendar(true)}
          style={{ justifyContent: "center" }}
          sx={{
            input: {
              fontWeight: "700",
              fontSize: "14px",
              cursor: "pointer",
              minHeight: "42px",
              color: theme.palette.text.primary,
              caretColor: "transparent",
              width: "130px",
              textAlign: "center",
            },
            "&& .MuiInput-root:hover::before": {
              borderBottom: "none",
            },
            "& .MuiInput-root": {
              marginTop: "0px",
              "&:before": {
                borderBottom: "none",
              },
              "&:after": {
                borderBottom: "none",
              },
            },
          }}
        />
      </FormControl>

      <Dialog open={isOpenCalendar} onClose={handleSubmit}>
        <DateCalendar
          value={date}
          onChange={(newValue) => {
            setDate(newValue);
          }}
          slots={{
            day: RenderWeekPickerDay,
          }}
          slotProps={{}}
        />

        <DialogActions>
          <Button onClick={handleSubmit}>{i18next.t("Ok")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WeekRangeDatePicker;
