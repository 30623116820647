import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import validate from "validate.js";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    padding: "0px 5px 10px 15px",
  },
  createButton: {
    margin: theme.spacing(1, 0),
  },
}));

const schema = {
  pin: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 4,
      minimum: 4,
    },
  },
  confirmPin: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 4,
      minimum: 4,
    },
  },
};

const CreatePinForm = (props) => {
  const { handlePinCreation, pinError, cancel, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handlePinCreation({
      pin: formState.values.pin,
      confirm_pin: formState.values.confirmPin,
    });
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Card {...rest} className={classes.root}>
      <form onSubmit={handleSubmit}>
        <CardHeader
          subheader={i18next.t("Enter 4 digit pin for quick login")}
          title={i18next.t("Create PIN")}
        />
        {pinError && (
          <Typography variant="body1" className={classes.errorText}>
            {pinError}
          </Typography>
        )}
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                className={classes.textField}
                error={hasError("pin")}
                fullWidth
                label={i18next.t("PIN")}
                name="pin"
                onChange={handleChange}
                type="number"
                value={formState.values.pin || ""}
                variant="outlined"
                data-sentry-ignore
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                error={hasError("pin")}
                fullWidth
                label={i18next.t("Confirm PIN")}
                name="confirmPin"
                onChange={handleChange}
                type="number"
                value={formState.values.confirmPin || ""}
                variant="outlined"
                data-sentry-ignore
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions style={{ justifyContent: "space-between" }}>
          <Button size="large" variant="contained" onClick={cancel}>
            {i18next.t("Skip")}
          </Button>
          <Button
            className={classes.createButton}
            color="primary"
            disabled={!formState.isValid}
            size="large"
            type="submit"
            variant="contained"
          >
            {i18next.t("Create PIN")}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

CreatePinForm.propTypes = {
  className: PropTypes.string,
};

export default CreatePinForm;
