import React, { Fragment } from "react";
import MaterialTextfield from "@/common/MaterialTextfield";
import { MaterialSelect } from "@/common/selectComponents/MaterialSelect";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import { MaterialDatePicker, MaterialTimePicker } from "@/common/Pickers";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  SvgIcon,
  Popover,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Checkbox,
  FormControlLabel,
  Tooltip,
  ClickAwayListener,
  Tabs,
  Tab,
} from "@mui/material";
import { LiveHelpOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { green } from "@mui/material/colors";
import { getDateFormat } from "@/utils/dateTimeFunctions";

const styles = (theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnDanger: {
    color: theme.palette.error.main,
  },
  btnSubmit: {
    color: theme.palette.primary.main,
  },
  addButton: {
    borderRadius: 4,
    textTransform: "uppercase",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: "#00000000",
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  mb: {
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  btnGreen: {
    backgroundColor: "#19B248",
    color: "#fff",
  },
  header: {
    //marginBottom: 7,
    padding: "16px 24px 0px 24px",
  },
  iconView: {
    color: "#19B248",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  redoView: {
    marginBottom: 15,
    color: "#19B248",
    transform: "rotateY(180deg) rotate(-60deg)",
  },
  textField: {
    padding: "0px",
    minHeight: "50px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textCenter: {
    textAlign: "center",
  },
  /*selectPaper: {
    position: "absolute",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  selectPaperRelative: {
    position: "relative",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    zIndex: 1001,
    left: 0,
    right: 0,
  },
  closeIconContainer: {
    color: "#ccc",
    position: "absolute",
    top: "0px",
    right: "0px",
    width: "100%",
    backgroundColor: "#f0f0f0",
    justifyContent: "flex-end",
    display: "flex",
  },
  actionButtons: {
    padding: "10px",
  },
  dateRow: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "-5px",
    marginLeft: "-5px",
  },
  hourButton: {
    position: "absolute",
    right: 50,
    top: 8,
    color: theme.palette.grey[500],
  },
  poperBody: {
    padding: "5px 25px 20px 25px",
    maxHeight: 400,
    overflowY: "auto",
  },
  poperHeader: {
    fontSize: 16,
    fontWeight: 500,
    padding: "5px 0px",
  },
  paperTable: {
    marginTop: 8,
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  poperUserName: {
    marginTop: 15,
  },
  projectList: {
    width: "100%",
  },
  projectListContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  helpIcon: {
    cursor: "pointer",
    marginLeft: 5,
    marginBottom: 2,
    color: theme.palette.text.secondary,
  },
  calanderIcon: {
    color: theme.palette.text.secondary,
  },
  planCard: {
    fontSize: 14,
    padding: "5px 4px 5px 0px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    backgroundColor: "#18B24B",
    color: "#ffffff",
    marginBottom: 5,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  textContainer: {
    width: 142,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  planCardText: {
    fontSize: 13,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 15,
    marginRight: 3,
    marginLeft: 8,
  },
  removeButton: {
    padding: 2,
    color: "#ffffff",
  },
  removeIcon: {
    fontSize: 16,
  },
  tooltip: {
    fontSize: 12,
    whiteSpace: "pre-line",
  },
  tooltipContainer: {
    cursor: "pointer",
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginRight: 40,
    fontSize: 16,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    height: 30,
  },
  label: {
    paddingLeft: 9,
    paddingRight: 9,
  },
  singleValue: {
    maxWidth: "calc(100% - 35px)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "absolute",
    textOverflow: "ellipsis",
    fontSize: 16,
    color: theme.palette.text.disabled,
  },
  tableHeader: {
    borderBottom: `1px dashed ${theme.palette.divider}`,
  },
  tableHeadCell: {
    background: "none",
    boxShadow: "none",
  },
  tableBodyRow: {
    paddingBottom: "16px !important",
    borderBottom: `1px dashed ${theme.palette.divider}`,
  },
  tableRowCell: {
    padding: "8px",
    paddingBottom: "8px",
    paddingTop: "8px",
    marginTop: "8px",
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class FormPlanModal extends React.Component {
  constructor(props) {
    super(props);

    let tempStartTime = new Date();
    tempStartTime = new Date(
      tempStartTime.setHours(props.state.startTime.split(":")[0])
    );
    tempStartTime = new Date(
      tempStartTime.setMinutes(props.state.startTime.split(":")[1])
    );

    let tempEndTime = new Date();
    tempEndTime = new Date(
      tempEndTime.setHours(props.state.endTime.split(":")[0])
    );
    tempEndTime = new Date(
      tempEndTime.setMinutes(props.state.endTime.split(":")[1])
    );

    this.state = {
      projectRequired: false,
      userRequired: false,
      taskRequired: false,
      startDate: new Date(),
      isOpenDateCalender: false,
      selectedDate: new Date(),
      newTaskCount: 0,
      startTimeError: false,
      endTimeError: false,
      startTime: moment(tempStartTime),
      endTime: moment(tempEndTime),
      openPopover: false,
      openTooltip: false,
      anchorEl: null,
      warningIconColor: "#18B24B",
      selectedTab: 0,
    };
  }

  handleChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    let { repeat_Dates } = this.props.state;
    var index = repeat_Dates.indexOf(newDate);
    if (index > -1) {
      repeat_Dates.splice(index, 1);
    } else {
      repeat_Dates.push(newDate);
    }
    this.props.changeState({ repeat_Dates });

    // process the availability
    if (index > -1) {
      this.props.removeDateFromAvailability(newDate);
    } else {
      this.props.addDateInAvailability(newDate);
    }
  };

  toggleDateCalendar = (e) => {
    e && e.preventDefault();
    this.setState({ isOpenDateCalender: !this.state.isOpenDateCalender });
  };

  handleCancel = () => {
    // Don't reset, Rune wants to show the last time
    /*let tempStartTime = new Date();
    tempStartTime = new Date(tempStartTime.setHours(0));
    tempStartTime = new Date(tempStartTime.setMinutes(0));

    let tempEndTime = new Date();
    tempEndTime = new Date(tempEndTime.setHours(0));
    tempEndTime = new Date(tempEndTime.setMinutes(0));*/

    this.setState({
      projectRequired: false,
      userRequired: false,
      taskRequired: false,
      newProject: false,
      warningIconColor: "#18B24B",
      //startTime: moment(tempStartTime), // Don't reset, Rune wants to show the last time
      //endTime: moment(tempEndTime)
    });
    this.props.changeState({ repeat_Dates: [] });
    this.props.handleCancel();
  };

  componentDidUpdate = (prevProps) => {
    const { state } = this.props;

    if (
      this.props.startDate &&
      (prevProps.state.dayNumber !== this.props.state.dayNumber ||
        prevProps.startDate !== this.props.startDate)
    ) {
      this.setState({
        selectedDate: moment(this.props.startDate)
          .clone()
          .add(
            this.props.state.dayNumber ? this.props.state.dayNumber : 0,
            "days"
          ),
      });
    }

    if (prevProps.state.editId !== state.editId) {
      let tempStartTime = new Date();
      tempStartTime = new Date(
        tempStartTime.setHours(state.startTime.split(":")[0])
      );
      tempStartTime = new Date(
        tempStartTime.setMinutes(state.startTime.split(":")[1])
      );

      let tempEndTime = new Date();
      tempEndTime = new Date(tempEndTime.setHours(state.endTime.split(":")[0]));
      tempEndTime = new Date(
        tempEndTime.setMinutes(state.endTime.split(":")[1])
      );

      this.setState({
        startTime: moment(tempStartTime),
        endTime: moment(tempEndTime),
      });
    }

    // for Unassigned plans (no user), set Start/End time as 07:00/15:00 by default
    if (
      prevProps.state.openPlanModal !== state.openPlanModal &&
      state.openPlanModal &&
      !state.editId &&
      state.groupBy === "user"
    ) {
      let tempStartTime = new Date();
      tempStartTime = new Date(
        tempStartTime.setHours(state.startTime.split(":")[0])
      );
      tempStartTime = new Date(
        tempStartTime.setMinutes(state.startTime.split(":")[1])
      );

      let tempEndTime = new Date();
      tempEndTime = new Date(tempEndTime.setHours(state.endTime.split(":")[0]));
      tempEndTime = new Date(
        tempEndTime.setMinutes(state.endTime.split(":")[1])
      );

      this.setState({
        startTime: moment(tempStartTime),
        endTime: moment(tempEndTime),
      });
    }
  };

  loadTaskOptions = async (loadedOptions, search) => {
    const limit = 20;
    const offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;

    const result = await this.props.getTaskOptions(limit, offset, search);
    return result;
  };

  checkAvailability = () => {
    if (this.state.startTime && this.state.endTime) {
      const start = moment(this.state.startTime);
      const end = moment(this.state.endTime);
      const duration = moment.duration(end.diff(start));
      let hours = duration.asHours();
      // Subtract lunch time and check, if the respective tickback is ticked
      if (this.props.state.promptLunch) {
        let lunchTime =
          this.props.state.user && this.props.state.user.admin
            ? this.props.state.adminLunchTime
            : this.props.state.userLunchTime;
        hours = hours - lunchTime;
      }

      let availability = this.props.state.availability;
      let dailyWarning = false;
      let weeklyWarning = false;
      let warningData = [];
      let warningStateUpdate = false;

      if (availability && availability.length > 0) {
        if (hours > 0) {
          availability.forEach(async (elements, user_id) => {
            if (availability[user_id] && availability[user_id].length > 0) {
              let userData = availability[user_id].filter((entry, index) => {
                dailyWarning = false;
                weeklyWarning = false;

                if (entry.daily > 0) {
                  //const hours = duration.asHours();
                  if (hours > entry.daily) dailyWarning = true;
                } else {
                  dailyWarning = true;
                }

                if (entry.weekly > 0) {
                  if (hours > entry.weekly) weeklyWarning = true;
                } else {
                  weeklyWarning = true;
                }

                if (dailyWarning || weeklyWarning) {
                  entry["warning"] = "true";
                  availability[user_id][index] = entry;
                  return true;
                } else {
                  if (entry.warning) {
                    entry["warning"] = "false";
                    availability[user_id][index] = entry;
                    warningStateUpdate = true;
                  }
                  return false;
                }
              });

              if (userData && userData.length > 0) {
                warningData[user_id] = userData;
              }
            }
          });
        } else {
          // If duration set to 0, clear the warning if it was set before
          availability.forEach(async (elements, user_id) => {
            if (availability[user_id] && availability[user_id].length > 0) {
              availability[user_id].forEach((entry, index) => {
                if (entry.warning) {
                  entry["warning"] = "false";
                  availability[user_id][index] = entry;
                  warningStateUpdate = true;
                }
                return true;
              });
            }
          });
        }
      }

      this.setState({
        warningIconColor:
          warningData && warningData.length > 0 ? "#dc3545" : "#18B24B",
      });

      if ((warningData && warningData.length > 0) || warningStateUpdate) {
        this.props.changeState({
          availability: availability,
        });
      }
    }
  };

  addMoreProject = () => {
    const { state, changeState } = this.props;

    if (
      state.selectedTask &&
      (state.startTime !== "00:00" || state.endTime !== "00:00")
    ) {
      let currentNodes = state.multipleProjectNodes;
      currentNodes.push({
        task_id: state.selectedTask ? parseInt(state.selectedTask.value) : null,
        task_name: state.selectedTask ? state.selectedTask.label : "",
        project_id: state.selectedProject,
        project_name: state.selectedProjectOption.label,
        start_time: state.startTime,
        end_time: state.endTime,
        comment: state.comment,
        user_ids: state.selectedUserOption,
      });

      changeState({
        isMultipleProjectInput: true,
        multipleProjectNodes: currentNodes,
        selectedProject: null,
        selectedProjectOption: null,
        selectedTask: null,
        comment: "",
      });
    }
  };

  handleRemovePlan = (keyIndex) => {
    const { state, changeState } = this.props;

    const updatedProjectNodes = state.multipleProjectNodes.filter(
      (node, index) => {
        return index !== keyIndex;
      }
    );

    changeState({
      isMultipleProjectInput:
        updatedProjectNodes && updatedProjectNodes.length > 0 ? true : false,
      multipleProjectNodes: updatedProjectNodes,
    });
  };

  handleTooltipClose = () => {
    this.setState({
      openTooltip: false,
    });
  };

  handleTooltipOpen = () => {
    this.setState({
      openTooltip: true,
    });
  };

  render() {
    const { onSubmit, /*handleDelete,*/ state, classes } = this.props;
    const { startTime, endTime } = this.state;

    return (
      <>
        <Typography component="div">
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={state.openPlanModal}
            onClose={this.handleCancel}
          >
            <DialogTitle
              onClose={this.handleCancel}
              className={classes.header}
              id="plan-hours-modal-title"
            >
              {`${state.title} ${
                this.props.planSchedule
                  ? `(Day ${this.props.state.dayNumber + 1})`
                  : ""
              }`}
              <br />
              <div className={classes.subTitle}>
                {state.groupBy === "user"
                  ? state.user
                    ? `${state.user.name}${
                        state.user.position ? ` (${state.user.position})` : ""
                      }`
                    : ""
                  : state.project
                    ? state.project.name
                    : ""}
              </div>
              {/* {!this.props.planSchedule && (
                <IconButton
                  className={classes.hourButton}
                  onClick={(event) => {
                    this.setState({
                      openPopover: true,
                      anchorEl: event.currentTarget,
                    });
                  }}
                  id="plan-hours-modal-hours-availability-button"
                  size="large"
                >
                  <SvgIcon>
                    <path
                      fill={this.state.warningIconColor}
                      d="M10.63,14.1C12.23,10.58 16.38,9.03 19.9,10.63C23.42,12.23 24.97,16.38 23.37,19.9C22.24,22.4 19.75,24 17,24C14.3,24 11.83,22.44 10.67,20H1V18C1.06,16.86 1.84,15.93 3.34,15.18C4.84,14.43 6.72,14.04 9,14C9.57,14 10.11,14.05 10.63,14.1V14.1M9,4C10.12,4.03 11.06,4.42 11.81,5.17C12.56,5.92 12.93,6.86 12.93,8C12.93,9.14 12.56,10.08 11.81,10.83C11.06,11.58 10.12,11.95 9,11.95C7.88,11.95 6.94,11.58 6.19,10.83C5.44,10.08 5.07,9.14 5.07,8C5.07,6.86 5.44,5.92 6.19,5.17C6.94,4.42 7.88,4.03 9,4M17,22A5,5 0 0,0 22,17A5,5 0 0,0 17,12A5,5 0 0,0 12,17A5,5 0 0,0 17,22M16,14H17.5V16.82L19.94,18.23L19.19,19.53L16,17.69V14Z"
                    />
                  </SvgIcon>
                </IconButton>
              )} */}
              <IconButton
                aria-label={i18next.t("Close")}
                className={classes.closeButton}
                onClick={this.handleCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent style={{ padding: "0 34px 0 34px" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (
                    !(
                      (state.groupBy === "user" && !state.selectedProject) ||
                      //(state.groupBy === "project" && !state.selectedUserOption) ||
                      !state.selectedTask ||
                      (this.props.state.startTime === "00:00" &&
                        this.props.state.endTime === "00:00") ||
                      state.amountError
                    )
                  ) {
                    onSubmit();
                  }
                }}
              >
                <Fragment>
                  {!this.props.planSchedule && (
                    <Grid container spacing={2} className={classes.mb}>
                      <Grid
                        item
                        xs={12}
                        id="plan-hours-modal-set-date-container"
                      >
                        <MaterialDatePicker
                          label={i18next.t("Set date")}
                          value={this.state.selectedDate}
                          format={getDateFormat()}
                          disabled
                          onChangeDate={async (date) => {
                            this.setState({ selectedDate: date });

                            if (moment(date).isValid()) {
                              const diffInt = moment(date).diff(
                                moment(this.props.startDate),
                                "days",
                                true
                              );
                              await this.props.changeState({
                                dayNumber: Math.ceil(diffInt), // 1.9 -> 2
                              });

                              let newDate = moment(date).format("YYYY-MM-DD");
                              this.props.addDateInAvailability(newDate);
                            }
                          }}
                          id="plan-hours-modal-set-date-picker"
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2} className={classes.mb}>
                    <Grid item xs={12} id="plan-hours-modal-user-container">
                      <MaterialAsyncPaginate
                        classes={classes}
                        textFieldProps={{
                          label: i18next.t("User"),
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        isDisabled={true}
                        isMulti={true}
                        defualtOptions
                        loadOptions={async (search, loadedOptions) => {
                          return this.props.loadUserList(search, loadedOptions);
                        }}
                        value={state.selectedUserOption}
                        onBlur={() => {
                          if (state.selectedUserOption) {
                            setTimeout(() => {
                              this.checkAvailability();
                            }, 2000);
                          }
                        }}
                        onChange={async (selected) => {
                          await this.props.changeState({
                            selectedUserOption: selected,
                            currentDate: moment(this.state.selectedDate).format(
                              "YYYY-MM-DD"
                            ),
                          });
                          if (!this.props.planSchedule) {
                            this.props.setUserInAvailability(selected);
                          }
                        }}
                        id="plan-hours-modal-user-input"
                      />
                    </Grid>
                  </Grid>
                  {state.isMultipleProjectInput &&
                    state.multipleProjectNodes.length && (
                      <Grid
                        item
                        xs={12}
                        id="plan-hours-modal-multi-project-task-nodes"
                      >
                        {state.multipleProjectNodes.map((node, index) => {
                          let selectedUsers = [];
                          if (node.user_ids) {
                            if (
                              !Array.isArray(node.user_ids) &&
                              node.user_ids.label
                            ) {
                              selectedUsers.push(node.user_ids.label);
                            } else {
                              if (node.user_ids.length) {
                                for (let i = 0; i < node.user_ids.length; i++) {
                                  selectedUsers.push(node.user_ids[i].label);
                                }
                              }
                            }
                          }

                          return (
                            <Paper
                              elevation={1}
                              className={classes.planCard}
                              key={index + 1}
                              id={`plan-hours-modal-task-node${index}`}
                            >
                              <Typography
                                component="div"
                                noWrap
                                className={classes.planCardText}
                              >
                                <SvgIcon className={classes.icon}>
                                  <path
                                    fill="#ffffff"
                                    d={
                                      "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M16.2,16.2L11,13V7H12.5V12.2L17,14.9L16.2,16.2Z"
                                    }
                                  />
                                </SvgIcon>
                                {`${node.start_time} - ${node.end_time}`}
                                <SvgIcon className={classes.icon}>
                                  <path
                                    fill="#ffffff"
                                    d={
                                      "M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z"
                                    }
                                  />
                                </SvgIcon>
                                <span className={classes.textContainer}>
                                  {node.project_name}
                                </span>
                                <SvgIcon className={classes.icon}>
                                  <path
                                    fill="#ffffff"
                                    d={
                                      "M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"
                                    }
                                  />
                                </SvgIcon>
                                <span className={classes.textContainer}>
                                  {node.task_name}
                                </span>
                                {selectedUsers.length > 0 && (
                                  <Typography
                                    component="span"
                                    className={classes.tooltipContainer}
                                  >
                                    <Tooltip
                                      title={selectedUsers.join(",\n")}
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <SvgIcon className={classes.icon}>
                                        <path
                                          fill="#ffffff"
                                          d={
                                            "M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
                                          }
                                        />
                                      </SvgIcon>
                                    </Tooltip>
                                  </Typography>
                                )}
                                {node.comment && node.comment.length > 0 && (
                                  <Typography
                                    component="span"
                                    className={classes.tooltipContainer}
                                  >
                                    <Tooltip
                                      title={node.comment}
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                    >
                                      <SvgIcon className={classes.icon}>
                                        <path
                                          fill="#ffffff"
                                          d={
                                            "M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M5,5V7H19V5H5M5,9V11H13V9H5M5,13V15H15V13H5Z"
                                          }
                                        />
                                      </SvgIcon>
                                    </Tooltip>
                                  </Typography>
                                )}
                              </Typography>
                              {(!state.editId ||
                                (state.editId && index !== 0)) && (
                                <IconButton
                                  className={classes.removeButton}
                                  onClick={() => this.handleRemovePlan(index)}
                                  size="large"
                                >
                                  <CloseIcon className={classes.removeIcon} />
                                </IconButton>
                              )}
                            </Paper>
                          );
                        })}
                      </Grid>
                    )}
                  <Grid container spacing={2} className={classes.mb}>
                    <Grid
                      item
                      xs={12}
                      className={classes.projectListContainer}
                      id="plan-hours-modal-project-container"
                    >
                      <MaterialAsyncPaginate
                        classes={classes}
                        className={classes.projectList}
                        isDisabled={true}
                        textFieldProps={{
                          label: i18next.t("Project"),
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        error={this.state.projectRequired}
                        defaultOptions
                        loadOptions={async (search, loadedOptions) => {
                          return this.props.loadProjectList(
                            search,
                            loadedOptions
                          );
                        }}
                        value={state.selectedProjectOption}
                        onBlur={() => {
                          if (!state.selectedProjectOption) {
                            this.setState({ projectRequired: true });
                          } else {
                            this.setState({ projectRequired: false });
                          }
                        }}
                        onChange={async (selected) => {
                          await this.props.changeState({
                            selectedProject: parseInt(selected.value, 10),
                          });
                          this.props.changeState({
                            selectedProjectOption: selected,
                            selectedTask: null,
                          });
                        }}
                        id="plan-hours-modal-project-input"
                      />
                      <ClickAwayListener onClickAway={this.handleTooltipClose}>
                        <div>
                          <Tooltip
                            title={
                              state.selectedProjectOption &&
                              state.selectedProjectOption.description
                                ? state.selectedProjectOption.description
                                : ""
                            }
                            placement="top"
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose}
                            open={this.state.openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                          >
                            <LiveHelpOutlined
                              onClick={this.handleTooltipOpen}
                              className={classes.helpIcon}
                              id="plan-hours-modal-project-help-icon"
                            />
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.mb}>
                    <Grid item xs={12} id="plan-hours-modal-task-container">
                      <MaterialAsyncPaginate
                        defaultOptions
                        classes={classes}
                        textFieldProps={{
                          label: i18next.t("Task"),
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        error={this.state.taskRequired}
                        onBlur={() => {
                          if (!state.selectedTask) {
                            this.setState({ taskRequired: true });
                          } else {
                            this.setState({ taskRequired: false });
                          }
                        }}
                        isDisabled={true}
                        // isDisabled={!state.selectedProjectOption}
                        loadOptions={async (search, loadedOptions) => {
                          return this.loadTaskOptions(loadedOptions, search);
                        }}
                        value={state.selectedTask}
                        onChange={async (selected) => {
                          if (selected.__isNew__) {
                            this.props.changeState({
                              selectedTask: {
                                ...selected,
                                value: selected.value,
                                label: selected.value,
                              },
                            });
                            await this.props.addTask(selected.value);
                            this.setState({
                              newTaskCount: this.state.newTaskCount + 1,
                            });
                          } else {
                            this.props.changeState({
                              selectedTask: selected,
                            });
                          }
                        }}
                        isCreatable={true}
                        cacheUniqs={[
                          state.selectedProject,
                          this.state.newTaskCount,
                        ]}
                        id="plan-hours-modal-task-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.mb}>
                    <Grid item xs={12}>
                      <Typography component="div">
                        <Grid item container xs={12}>
                          <Grid
                            item
                            xs={6}
                            id="plan-hours-modal-start-time-input"
                            style={{ paddingRight: 10 }}
                          >
                            <MaterialTimePicker
                              label={i18next.t("Start time")}
                              format="HH:mm"
                              disabled
                              error={this.state.startTimeError}
                              value={startTime}
                              onChangeTime={async (date) => {
                                await this.setState({
                                  startTime: date,
                                });

                                this.props.changeState({
                                  startTime: moment(date).format("HH:mm"),
                                });

                                this.checkAvailability();
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            id="plan-hours-modal-end-time-input"
                            style={{ paddingLeft: 10 }}
                          >
                            <MaterialTimePicker
                              label={i18next.t("End time")}
                              format="HH:mm"
                              disabled
                              error={this.state.endTimeError}
                              value={endTime}
                              onChangeTime={async (date) => {
                                await this.setState({
                                  endTime: date,
                                });

                                this.props.changeState({
                                  endTime: moment(date).format("HH:mm"),
                                });

                                this.checkAvailability();
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.mb}>
                    <Grid item xs={12} id="plan-hours-modal-comment-container">
                      <MaterialTextfield
                        adornment="no_endAdornment"
                        type="text"
                        variant="outlined"
                        disabled
                        multiline
                        minRows={4}
                        maxRows={8}
                        label={i18next.t("Comment")}
                        value={state.comment}
                        //placeholder={i18next.t("Comment")}
                        className={classes.textField}
                        onChange={(e) => {
                          this.props.changeState({
                            comment: e.target.value,
                          });
                        }}
                        maxLength={512}
                        id="plan-hours-modal-comment-input"
                      />
                    </Grid>
                  </Grid>

                  {state.isItemCertificationRequiered &&
                    state.selectedRequiredItems &&
                    state.selectedRequiredItems.length > 0 && (
                      <Typography component="div" sx={{ mb: 3 }}>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          sx={{ mb: 0, mt: 3 }}
                        >
                          {i18next.t("Items")}
                        </Typography>

                        <Tabs
                          value={this.state.selectedTab}
                          onChange={(event, value) => {
                            this.setState({ selectedTab: value });
                          }}
                          indicatorColor="primary"
                          textColor="secondary"
                          sx={{ mt: 1 }}
                          //variant="fullWidth"
                          id="plannings-modal-tabs"
                        >
                          <Tab
                            label={i18next.t("Required")}
                            sx={{ color: "text.secondary" }}
                            id="required-item-tab"
                          />
                          <Tab
                            label={i18next.t("Planned")}
                            sx={{ color: "text.secondary" }}
                            id="planned-item-tab"
                          />
                        </Tabs>

                        <Table>
                          <TableHead
                            sx={{ mb: 1 }}
                            className={classes.tableHeader}
                          >
                            <TableRow>
                              <TableCell
                                sx={{ maxWidth: "100px" }}
                                className={classes.tableHeadCell}
                              >
                                {i18next.t("Items")}
                              </TableCell>

                              {this.state.selectedTab === 1 && (
                                <TableCell
                                  sx={{ maxWidth: "100px" }}
                                  className={classes.tableHeadCell}
                                >
                                  {i18next.t("Quantity")}
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>

                          <TableBody className={classes.tableBody}>
                            {state.selectedRequiredItems
                              ?.filter(
                                (itm) =>
                                  itm.required ===
                                  (this.state.selectedTab === 1 ? true : false)
                              )
                              ?.map((item, index) => (
                                <TableRow
                                  className={classes.tableBodyRow}
                                  key={index}
                                >
                                  <TableCell
                                    className={classes.tableRowCell}
                                    sx={{
                                      maxWidth: "100px",
                                      color: "text.secondary",
                                    }}
                                  >
                                    {item.label ? item.label : ""}
                                  </TableCell>

                                  {this.state.selectedTab === 1 && (
                                    <TableCell
                                      className={classes.tableRowCell}
                                      sx={{
                                        maxWidth: "100px",
                                        color: "text.secondary",
                                      }}
                                    >
                                      {item.amount ? item.amount : 0}
                                    </TableCell>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Typography>
                    )}

                  {state.isDependencyRequiered &&
                    state.selectedDependencies &&
                    state.selectedDependencies.length > 0 && (
                      <Typography component="div" sx={{ mb: 3 }}>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          sx={{ mb: 0, mt: 2 }}
                        >
                          {i18next.t("Dependencies")}
                        </Typography>
                        <Table sx={{ mb: 2 }}>
                          <TableHead
                            sx={{ mb: 1 }}
                            className={classes.tableHeader}
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  maxWidth: "100px",
                                  color: "text.secondary",
                                }}
                                className={classes.tableHeadCell}
                              >
                                {i18next.t("Type")}
                              </TableCell>
                              <TableCell
                                sx={{
                                  maxWidth: "100px",
                                  color: "text.secondary",
                                }}
                                className={classes.tableHeadCell}
                              >
                                {i18next.t("Name")}
                              </TableCell>
                              <TableCell
                                className={classes.tableHeadCell}
                              ></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody className={classes.tableBody}>
                            {state.selectedDependencies.map(
                              (dependency, index) => (
                                <TableRow
                                  className={classes.tableBodyRow}
                                  key={index}
                                >
                                  <TableCell
                                    className={classes.tableRowCell}
                                    sx={{
                                      maxWidth: "100px",
                                      color: "text.secondary",
                                    }}
                                  >
                                    {dependency.dependencyType === "task"
                                      ? i18next.t("Task")
                                      : i18next.t("Project")}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableRowCell}
                                    sx={{
                                      maxWidth: "100px",
                                      color: "text.secondary",
                                    }}
                                  >
                                    {dependency.depedencyName
                                      ? dependency.depedencyName
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </Typography>
                    )}

                  {/*<Grid item xs={12} container>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Button
                        className={classes.addButton}
                        onClick={this.addMoreProject}
                        id="plan-hours-modal-add-more-button"
                      >
                        {`+ ${i18next.t("Add more")}`}
                      </Button>
                    </div>
                    </Grid>*/}
                  <Grid container spacing={2} className={classes.mb}>
                    <Grid item xs={12} container>
                      <Grid
                        item
                        xs={6}
                        id="plan-hours-modal-subtrack-lunch-container"
                      >
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={state.promptLunch ? true : false}
                              disabled
                              onChange={async (e) => {
                                await this.props.changeState({
                                  promptLunch: e.target.checked,
                                });
                                setTimeout(() => {
                                  this.checkAvailability();
                                }, 1000);
                              }}
                            />
                          }
                          label={i18next.t("Subtract lunch")}
                          id="plan-hours-modal-subtrack-lunch-input"
                        />
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={state.autofillAsHours ? true : false}
                              disabled
                              onChange={(e) => {
                                this.props.changeState({
                                  autofillAsHours: e.target.checked,
                                });
                              }}
                            />
                          }
                          label={i18next.t("Autofill as hours")}
                          id="plan-hours-modal-autofill-as-hours-input"
                        />
                      </Grid>
                      <Grid item xs={6} id="plan-hours-modal-status-container">
                        <MaterialSelect
                          classes={classes}
                          isDisabled={true}
                          textFieldProps={{
                            label: i18next.t("Status"),
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          options={[
                            { value: "active", label: i18next.t("Active") },
                            { value: "inactive", label: i18next.t("Inactive") },
                          ]}
                          value={state.planStatus}
                          onChange={(selected) => {
                            this.props.changeState({ planStatus: selected });
                          }}
                          id="plan-hours-modal-subtrack-status-input"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              </form>
            </DialogContent>

            <DialogActions className={classes.actionButtons}>
              <Typography component="div">
                <Button
                  className={`${classes.button} ${classes.btnCancel}`}
                  onClick={this.handleCancel}
                  id="plan-hours-modal-cancel-button"
                >
                  {i18next.t("Cancel")}
                </Button>
              </Typography>
            </DialogActions>
          </Dialog>
          <Popover
            open={this.state.openPopover}
            anchorEl={this.state.anchorEl}
            onClose={() => {
              this.setState({ openPopover: false });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Typography
              component="div"
              className={classes.poperBody}
              id="plan-hours-modal-hours-availability-popup"
            >
              <Typography
                className={classes.poperHeader}
                id="plan-hours-modal-hours-availability-popup-title"
              >
                {i18next.t("Hours availability")}
              </Typography>
              {this.props.state.availability &&
                this.props.state.availability.map((entries, user_id) => {
                  // find user name using user_id
                  let userObj = this.props.state.userList.find((list) => {
                    return list.id === user_id;
                  });
                  let userName = userObj && userObj.name ? userObj.name : "";

                  return (
                    <Fragment key={user_id}>
                      <Typography className={classes.poperUserName}>
                        {userName}
                      </Typography>
                      <Paper elevation={2} className={classes.paperTable}>
                        <Table size="small" style={{ marginTop: 8 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>{i18next.t("Date")}</TableCell>
                              <TableCell align="right">
                                {i18next.t("Daily")}
                              </TableCell>
                              <TableCell align="right">
                                {i18next.t("Weekly")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {entries.map((row) => (
                              <TableRow
                                key={row.date}
                                style={{
                                  backgroundColor:
                                    row.warning && row.warning === "true"
                                      ? this.state.warningIconColor
                                      : "#ffffff",
                                }}
                              >
                                <TableCell>{row.date}</TableCell>
                                <TableCell align="right">{row.daily}</TableCell>
                                <TableCell align="right">
                                  {row.weekly}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Fragment>
                  );
                })}
              {this.props.state.availability &&
                this.props.state.availability.length === 0 && (
                  <Typography>{i18next.t("No users selected")}</Typography>
                )}
            </Typography>
          </Popover>
        </Typography>
      </>
    );
  }
}

export default withStyles(styles)(FormPlanModal);
