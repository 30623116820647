import React, { Fragment } from "react";
import i18next from "i18next";
import {
  Typography,
  Popover,
  Button,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { ActionCreators } from "@/redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const styles = (theme) => ({
  popperTooltip: {
    zIndex: 1300,
    backgroundColor: theme.palette.background.paper,
  },
  tooltip: {
    borderRadius: 6,
    minWidth: 220,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.customShadows.card,
    padding: 15,
  },
  tooltipContent: {
    display: "block",
  },
  tooltipActions: {
    marginTop: 18,
    display: "flex",
    justifyContent: "space-between",
  },
  iconButton: {
    padding: 6,
    height: 28,
  },
  moreIcon: {
    fontSize: 18,
    color: theme.palette.text.primary,
  },
  inputLabel: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    marginBottom: 5,
  },
  styleSelect: {
    width: "100%",
    color: theme.palette.text.primary,
    "& fieldset": {
      "& >legend": {
        width: 0,
      },
    },
  },
  tooltipLabel: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  btnCancel: {
    borderRadius: 4,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  // btnSelect: {
  //   borderRadius: 4,
  //   textTransform: "uppercase",
  //   border: `1px solid ${theme.palette.primary.main}`,
  //   color: theme.palette.common.white,
  //   backgroundColor: theme.palette.primary.main,
  //   "&:hover": {
  //     color: theme.palette.primary.main,
  //     backgroundColor: "#00000000",
  //   },
  //   "&:disabled": {
  //     border: `1px solid ${theme.palette.text.disabled}`,
  //     color: theme.palette.text.disabled,
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
});

class TooltipChooseHourType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddon: props.isAddon,
      selectedType: null,
      anchorTooltip: null,
      optonalTypeList: [],
      optionalTypeLoading: false,
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.hourTypes &&
      this.props.hourTypes.optionalHourTypes &&
      this.props.hourTypes.optionalHourTypes.entries &&
      prevProps.hourTypes.optionalHourTypes.entries !==
        this.props.hourTypes.optionalHourTypes.entries
    ) {
      let options = [];

      for (
        let i = 0;
        i < this.props.hourTypes.optionalHourTypes.entries.length;
        i++
      ) {
        const element = this.props.hourTypes.optionalHourTypes.entries[i];
        options.push({
          value: element.optional_hour_type_id.toString(),
          label: element.optional_hour_type_name,
        });
      }
      this.setState({
        optonalTypeList: options,
        optionalTypeLoading: false,
      });
    }

    if (
      this.props.addonTypes &&
      this.props.addonTypes.optionalAddonTypes &&
      this.props.addonTypes.optionalAddonTypes.entries &&
      prevProps.addonTypes.optionalAddonTypes.entries !==
        this.props.addonTypes.optionalAddonTypes.entries
    ) {
      let options = [];

      for (
        let i = 0;
        i < this.props.addonTypes.optionalAddonTypes.entries.length;
        i++
      ) {
        const element = this.props.addonTypes.optionalAddonTypes.entries[i];
        options.push({
          value: element.optional_addon_addon_type_id.toString(),
          label: element.optional_addon_addon_type_name,
        });
      }
      this.setState({
        optonalTypeList: options,
        optionalTypeLoading: false,
      });
    }
  };

  getOptionalTypes = () => {
    const { value, isAddon } = this.props;

    if (!isAddon) {
      this.props.actions.getOptionalHourTypes(value.type_id);
    } else {
      this.props.actions.getOptionalAddonTypes(value.type_id);
    }

    this.setState({ optionalTypeLoading: true });
  };

  handleChange = () => {
    const { onChange } = this.props;
    const selectedType = this.state.selectedType;
    onChange(
      this.props.value,
      {
        type_id: parseInt(selectedType.value),
        type_name: selectedType.label,
      },
      this.state.isAddon
    );
    this.setState({ anchorTooltip: null });
  };

  closePopover = () => {
    this.setState({
      anchorTooltip: null,
    });
  };

  render = () => {
    const { classes, isAddon, uid } = this.props;
    const { optonalTypeList, optionalTypeLoading, selectedType } = this.state;
    const isOpened = Boolean(this.state?.anchorTooltip);
    const id = isOpened ? "chooseHoursType-popper" : undefined;

    return (
      <React.Fragment>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={(event) => {
            const isOpened = !!this.state.anchorTooltip;
            if (isOpened) {
              this.setState({
                anchorTooltip: null,
              });
            } else {
              this.getOptionalTypes();
              this.setState({ anchorTooltip: event.currentTarget });
            }
          }}
          id={`choose-type-more-button${uid}`}
          size="large"
        >
          <MoreVertIcon className={classes.moreIcon} />
        </IconButton>
        <Popover
          id={id}
          anchorEl={this.state.anchorTooltip}
          open={isOpened}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          placement="bottom-end"
        >
          <Typography component="div" className={classes.tooltip}>
            {optionalTypeLoading ? (
              <Typography component="h4">{i18next.t("Loading...")}</Typography>
            ) : optonalTypeList.length < 1 ? (
              <Typography component="h4">
                {i18next.t("No optional type found")}
              </Typography>
            ) : (
              <Fragment>
                <Typography component="div" className={classes.inputLabel}>
                  {i18next.t(
                    !isAddon ? "Choose hour type" : "Choose addon type"
                  )}
                </Typography>
                <Typography component="div" className={classes.tooltipContent}>
                  <Select
                    id="input-optional-type"
                    className={classes.styleSelect}
                    value={selectedType ? selectedType.value : ""}
                    onChange={(event) => {
                      const item = optonalTypeList?.find(
                        (option) => option.value === event.target.value
                      );
                      if (item) {
                        this.setState({ selectedType: item });
                      }
                    }}
                    variant="outlined"
                  >
                    {optonalTypeList?.map(({ value, label }) => (
                      <MenuItem
                        className={classes.tooltipLabel}
                        value={value}
                        key={value}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Typography>
                <Typography component="div" className={classes.tooltipActions}>
                  <Button
                    className={classes.btnCancel}
                    onClick={this.closePopover}
                    id="input-hour-modal-delete-button"
                  >
                    {i18next.t("Cancel")}
                  </Button>
                  <Button
                    className={classes.btnSelect}
                    variant="contained"
                    disabled={!selectedType}
                    type="submit"
                    onClick={this.handleChange}
                  >
                    {i18next.t("Select")}
                  </Button>
                </Typography>
              </Fragment>
            )}
          </Typography>
        </Popover>
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    hourTypes: state.hourTypes,
    addonTypes: state.addonTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TooltipChooseHourType));
