import createReducer from "./createReducer";
import * as types from "@/redux/actions/types";
import { logout } from "@/utils/api.config";

const initialState = {
  isLoading: false,
  data: {},
  addonTypesList: {},
  error: null,
  succMsg: null,
  errMsg: null,
  optionalAddonTypes: {},
  addonMeasurements: {},
  fixedAddonTypes: {},
};

export const addonTypes = createReducer(initialState, {
  [types.GET_ADDON_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonTypesList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_ADDON_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_ADDON_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_ADDON_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_OPTIONAL_ADDON_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      optionalAddonTypes: {},
      error: null,
    });
  },
  [types.GET_OPTIONAL_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      optionalAddonTypes: action.payload,
      isLoading: false,
    });
  },
  [types.GET_OPTIONAL_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.SET_OPTIONAL_ADDON_TYPE](state) {
    return Object.assign({}, state, {
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.SET_OPTIONAL_ADDON_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.SET_OPTIONAL_ADDON_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ADDON_MEASUREMENTS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      addonMeasurements: {},
      error: null,
    });
  },
  [types.GET_ADDON_MEASUREMENTS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonMeasurements: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ADDON_MEASUREMENTS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_ADDON_MEASUREMENT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_ADDON_MEASUREMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_ADDON_MEASUREMENT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_FIXED_ADDON_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      fixedAddonTypes: {},
      error: null,
    });
  },
  [types.GET_FIXED_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      fixedAddonTypes: action.payload,
      isLoading: false,
    });
  },
  [types.GET_FIXED_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_FIXED_ADDON_TYPE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_FIXED_ADDON_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_FIXED_ADDON_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_FIXED_ADDON_TYPE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_FIXED_ADDON_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_FIXED_ADDON_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_FIXED_ADDON_TYPE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_FIXED_ADDON_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_FIXED_ADDON_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
});
