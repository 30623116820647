import ReactDOM from "react-dom";
import "@/utils/i18n";
import * as Sentry from "@sentry/react";
import packageInfo from "../package.json";
import "moment/dist/locale/nb";
import "moment/dist/locale/en-ca";
import "moment/dist/locale/de";
import "moment/dist/locale/ro";
import "moment/dist/locale/sv";
import "moment/dist/locale/da";
import "moment/dist/locale/fr";
import "moment/dist/locale/th";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

import getLanguage from "@/utils/getLanguage";
import { getLocale } from "@/utils/dateTimeFunctions";

momentDurationFormatSetup(moment);

// Set moment locale based on the current language. This is required on event of page reload
moment.locale(getLocale(getLanguage()));
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
        });
      }
      return event;
    },
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
    ],
    tracesSampleRate: 0.25,
    environment: import.meta.env.VITE_RELEASE_ENV,
    release: packageInfo.version,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
