import * as types from "./types";
import { core } from "./core";

export const uploadImage = (data) => {
  return core.API(
    "POST",
    `/attachment/image`,
    types.UPLOAD_IMAGE,
    types.UPLOAD_IMAGE_SUCCESS,
    types.UPLOAD_IMAGE_FAILURE,
    data
  );
};

export const deleteImage = (queryString) => {
  return core.API(
    "DELETE",
    `/attachment/image${queryString ? `?${queryString}` : ""}`,
    types.DELETE_IMAGE,
    types.DELETE_IMAGE_SUCCESS,
    types.DELETE_IMAGE_FAILURE
  );
};

export const addHmskCheckList = (data) => {
  return core.API(
    "POST",
    `/hmsk/check-list`,
    types.ADD_HMSK_CHECK_LIST,
    types.ADD_HMSK_CHECK_LIST_SUCCESS,
    types.ADD_HMSK_CHECK_LIST_FAILURE,
    data
  );
};

export const getHmskCheckLists = (queryString) => {
  return core.API(
    "GET",
    `/hmsk/check-lists${queryString ? `?${queryString}` : ""}`,
    types.GET_HMSK_CHECK_LISTS,
    types.GET_HMSK_CHECK_LISTS_SUCCESS,
    types.GET_HMSK_CHECK_LISTS_FAILURE
  );
};

export const getHmskCheckList = (id) => {
  return core.API(
    "GET",
    `/hmsk/check-list/${id}`,
    types.GET_HMSK_CHECK_LIST,
    types.GET_HMSK_CHECK_LIST_SUCCESS,
    types.GET_HMSK_CHECK_LIST_FAILURE
  );
};

export const updateHmskCheckList = (id, data) => {
  return core.API(
    "PUT",
    `/hmsk/check-list/${id}`,
    types.UPDATE_HMSK_CHECK_LIST,
    types.UPDATE_HMSK_CHECK_LIST_SUCCESS,
    types.UPDATE_HMSK_CHECK_LIST_FAILURE,
    data
  );
};

export const deleteHmskCheckList = (id) => {
  return core.API(
    "DELETE",
    `/hmsk/check-list/${id}`,
    types.DELETE_HMSK_CHECK_LIST,
    types.DELETE_HMSK_CHECK_LIST_SUCCESS,
    types.DELETE_HMSK_CHECK_LIST_FAILURE
  );
};

export const isNeedToFillCheckList = (user_id, project_ids, task_ids) => {
  return core.API(
    "GET",
    `/hmsk/check-lists/need-to-fill?user_id=${user_id}&project_ids=${project_ids}&task_ids=${task_ids}`,
    types.GET_HMSK_CHECK_LISTS_NEED_TO_FILL,
    types.GET_HMSK_CHECK_LISTS_NEED_TO_FILL_SUCCESS,
    types.GET_HMSK_CHECK_LISTS_NEED_TO_FILL_FAILURE
  );
};
