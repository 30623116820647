import React from "react";
import i18next from "i18next";
import moment from "moment";
import { withStyles } from "@mui/styles";
import {
  Paper,
  Typography,
  Slider,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  Stack,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Label from "@/common/Label";

const styles = (theme) => ({
  container: {
    margin: "20px 0px 0px 0px",
  },
  card: {
    padding: "10px 10px 0px 10px",
    marginBottom: "10px",
    boxShadow: theme.customShadows.card,
    border: `1px solid ${theme.palette.text.secondary}29`,
  },
  cardHeader: {
    display: "flex",
    fontSize: 14,
    lineHeight: 1,
    color: "#424242",
    //paddingBottom: "10px"
  },
  cardContent: {
    padding: "5px 15px 15px 15px",
  },
  planHeading: {
    paddingBottom: 5,
    paddingTop: 6,
    fontSize: 16,
  },
  cardHeading: {
    flexGrow: 1,
    overflow: "hidden",
  },
  closeIcon: {
    fontSize: 18,
    cursor: "pointer",
  },
  iconButton: {
    padding: 6,
    height: 28,
  },

  moreIcon: {
    fontSize: 18,
  },
  actionIcon: {
    fontSize: 16,
    marginRight: 8,
  },
  divider: {
    borderColor: `0.5px solid ${theme.palette.divider}`,
    margin: "0px 10px 10px 10px",
  },
  iconContainer: {
    marginRight: "-5px",
    marginTop: "-5px",
    display: "flex",
    alignItems: "flex-start",
  },
  actionItem: {
    fontSize: 14,
  },
  taskName: {
    fontWeight: 700,
    paddingBottom: 8,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.palette.text.primary,
  },
  projectName: {
    paddingBottom: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.palette.text.primary,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "14px",
    paddingBottom: 18,
  },
  sliderTypeName: {
    color: theme.palette.text.secondary,
  },
  sliderOverride: {
    height: "2px",
    "& .MuiSlider-valueLabel": {
      top: 40,
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      fontWeight: "normal",
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.text.primary,
      },
    },
    "& .MuiSlider-thumb": {
      height: "12px",
      width: "12px",
    },
    "& .MuiSlider-mark": {
      height: "8px",
      width: "1px",
      backgroundColor: theme.palette.action.disabled,
    },
    "& .Mui-disabled": {
      color: theme.palette.action.disabled,
    },
  },
  hourTypeSlider: {
    color: theme.palette.primary.main,
    "& .MuiSlider-mark": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  addonTypeSlider: {
    color: theme.palette.secondary.main,
    "& .MuiSlider-mark": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  infoBox: {
    marginBottom: theme.spacing(2),
  },
  fixedAddonName: {
    flexGrow: 1,
  },
  fixedAddonDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class MultiProjectTaskNodes extends React.Component {
  constructor() {
    super();
    this.state = {
      //sliderValue: [720, 780],
      min: 0,
      max: 1440,
      lunchMarks: [],
      isDragable: true,
      anchorEl: null,
      openMenu: false,
      nodeType: null,
      nodeIndex: null,
    };
    this.disabled = false;
  }

  valuetext = (value) => {
    return moment.utc().startOf("day").add(value, "minutes").format("HH:mm");
  };

  shuffleTimeSlots = (projectTasks, sourceIndex, destinationIndex) => {
    let sStartTime = null;
    let sEndTime = null;

    if (sourceIndex < destinationIndex) {
      // drag from top to bottom

      // source value to temp var
      sStartTime = projectTasks[sourceIndex]["start_time"];
      sEndTime = projectTasks[sourceIndex]["end_time"];

      // destination assigned to source
      projectTasks[sourceIndex]["start_time"] =
        projectTasks[destinationIndex]["start_time"];
      projectTasks[sourceIndex]["end_time"] =
        projectTasks[destinationIndex]["end_time"];

      // loop thorugh other element (bottom to top) and shuffle one down
      for (let i = destinationIndex; i > sourceIndex + 1; i--) {
        projectTasks[i]["start_time"] = projectTasks[i - 1]["start_time"];
        projectTasks[i]["end_time"] = projectTasks[i - 1]["end_time"];
      }

      // actual source assigned to it's next element
      projectTasks[sourceIndex + 1]["start_time"] = sStartTime;
      projectTasks[sourceIndex + 1]["end_time"] = sEndTime;
    } else {
      // drag from bottom to top

      // source value to temp var
      sStartTime = projectTasks[sourceIndex]["start_time"];
      sEndTime = projectTasks[sourceIndex]["end_time"];

      // destination assigned to source
      projectTasks[sourceIndex]["start_time"] =
        projectTasks[destinationIndex]["start_time"];
      projectTasks[sourceIndex]["end_time"] =
        projectTasks[destinationIndex]["end_time"];

      // loop thorugh other element (top to bottom) and shuffle one up
      for (let i = destinationIndex; i < sourceIndex - 1; i++) {
        projectTasks[i]["start_time"] = projectTasks[i + 1]["start_time"];
        projectTasks[i]["end_time"] = projectTasks[i + 1]["end_time"];
      }

      // actual source assigned to it's above element
      projectTasks[sourceIndex - 1]["start_time"] = sStartTime;
      projectTasks[sourceIndex - 1]["end_time"] = sEndTime;
    }

    return projectTasks;
  };

  handleChange = (index, newValue) => {
    // disable Drag & Drop feature
    this.setState({
      isDragable: false,
    });

    const { multiProjectTasks } = this.props;
    const totalNodes = multiProjectTasks.length;
    let isChanged = false;

    if (index === 0) {
      // First node. Only end time change if more than 1 nodes. End time should be < next's end time
      if (totalNodes > 1) {
        // Round up to the next multiple of 5
        newValue[1] = Math.ceil(newValue[1] / 5) * 5;

        if (
          newValue[0] < newValue[1] &&
          newValue[1] < multiProjectTasks[index + 1]["end_time"]
        ) {
          multiProjectTasks[index]["end_time"] = newValue[1];
          multiProjectTasks[index + 1]["start_time"] = newValue[1];
          isChanged = true;
        }
      }
    } else if (index === totalNodes - 1) {
      // Last node. Only start time change if more than 1 nodes. Change only if start is less than it's end and > than previous start

      // Round up to the next multiple of 5
      newValue[0] = Math.ceil(newValue[0] / 5) * 5;

      if (
        newValue[0] < newValue[1] &&
        newValue[0] > multiProjectTasks[index - 1]["start_time"]
      ) {
        multiProjectTasks[index]["start_time"] = newValue[0];
        multiProjectTasks[index - 1]["end_time"] = newValue[0];
        isChanged = true;
      }
    } else {
      // Middle node. Change based on previous and next node values

      // Round up to the next multiple of 5
      newValue[0] = Math.ceil(newValue[0] / 5) * 5;
      newValue[1] = Math.ceil(newValue[1] / 5) * 5;

      if (
        newValue[0] < newValue[1] &&
        newValue[0] > multiProjectTasks[index - 1]["start_time"]
      ) {
        multiProjectTasks[index]["start_time"] = newValue[0];
        multiProjectTasks[index - 1]["end_time"] = newValue[0];
        isChanged = true;
      }
      if (newValue[1] < multiProjectTasks[index + 1]["end_time"]) {
        multiProjectTasks[index]["end_time"] = newValue[1];
        multiProjectTasks[index + 1]["start_time"] = newValue[1];
        isChanged = true;
      }
    }

    if (isChanged) {
      this.props.changeState({
        multiProjectTasks: multiProjectTasks,
      });
    }
  };

  handleAddonChange = (index, newValue) => {
    // disable Drag & Drop feature
    this.setState({
      isDragable: false,
    });

    const { multiProjectAddonTasks } = this.props;
    let isChanged = false;

    // Round up to the next multiple of 5, considering start and end
    newValue[0] = Math.ceil(newValue[0] / 5) * 5;
    if (newValue[0] < this.state.min) {
      newValue[0] = this.state.min;
    }
    newValue[1] = Math.ceil(newValue[1] / 5) * 5;
    if (newValue[1] > this.state.max) {
      newValue[1] = this.state.max;
    }

    // Start time < end time.
    if (newValue[0] < newValue[1]) {
      multiProjectAddonTasks[index]["start_time"] = newValue[0];
      multiProjectAddonTasks[index]["end_time"] = newValue[1];
      isChanged = true;
    }

    if (isChanged) {
      this.props.changeState({
        multiProjectAddonTasks: multiProjectAddonTasks,
      });
    }
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index !== result.destination.index
    ) {
      let arrResult =
        result.source.droppableId === "droppable-hours"
          ? this.props.multiProjectTasks
          : this.props.multiProjectAddonTasks;

      // Shuffle timings only for hours (not for addons)
      if (result.source.droppableId === "droppable-hours") {
        arrResult = this.shuffleTimeSlots(
          arrResult,
          result.source.index,
          result.destination.index
        );
      }

      const [removed] = arrResult.splice(result.source.index, 1);
      arrResult.splice(result.destination.index, 0, removed);

      if (result.source.droppableId === "droppable-hours") {
        this.props.changeState({
          multiProjectTasks: arrResult,
        });
      } else {
        this.props.changeState({
          multiProjectAddonTasks: arrResult,
        });
      }

      this.props.changeState({
        hourModalDataChanged: true,
      });
    }
  };

  assignTimeSlots = (nodes) => {
    const { startTime, endTime } = this.props;
    const min = moment.duration(startTime).asMinutes();
    let max = moment.duration(endTime).asMinutes();
    if (endTime < startTime) {
      max = max + 1440;
    }

    const totalNodes = nodes.length;

    if (totalNodes === 1) {
      nodes[0]["start_time"] = min;
      nodes[0]["end_time"] = max;
    } else {
      const slotSize = (max - min) / totalNodes;
      for (let i = 0; i < totalNodes; i++) {
        if (nodes[i]["disabled"]) continue;

        if (i === 0) {
          nodes[i]["start_time"] = min;
          nodes[i]["end_time"] = min + slotSize;
        } else if (i === totalNodes - 1) {
          nodes[i]["start_time"] = min + slotSize * (totalNodes - 1);
          nodes[i]["end_time"] = max;
        } else {
          nodes[i]["start_time"] = min + slotSize * i;
          nodes[i]["end_time"] = min + slotSize * (i + 1);
        }
      }
    }

    return nodes;
  };

  changeStartEndTimeSlotsHours = (nodes) => {
    const { startTime, endTime } = this.props;
    const min = moment.duration(startTime).asMinutes();
    let max = moment.duration(endTime).asMinutes();
    if (endTime < startTime) {
      max = max + 1440;
    }

    const totalNodes = nodes.length;
    let resetRequired = false;

    if (totalNodes > 0) {
      if (totalNodes === 1 && !nodes[0]["disabled"]) {
        nodes[0]["start_time"] = min;
        nodes[0]["end_time"] = max;
      } else {
        // first node, check end time
        const endTime = nodes[0]["end_time"];
        if (min <= endTime) {
          nodes[0]["start_time"] = min;
        } else {
          resetRequired = true;
        }

        if (!resetRequired) {
          // last node, check start time
          const startTime = nodes[nodes.length - 1]["start_time"];
          if (max >= startTime) {
            nodes[nodes.length - 1]["end_time"] = max;
          } else {
            resetRequired = true;
          }
        }
      }
    }

    if (resetRequired) {
      nodes = this.assignTimeSlots(nodes);
    }

    return nodes;
  };

  changeStartEndTimeSlotsAddons = (nodes) => {
    const { startTime, endTime, addonTypeOptions } = this.props;
    const min = moment.duration(startTime).asMinutes();
    let max = moment.duration(endTime).asMinutes();
    if (endTime < startTime) {
      max = max + 1440;
    }

    const totalNodes = nodes.length;

    let nodeStartTime = 0;
    let nodeEndTime = 0;
    let changeDone = false;
    let addonTypeObj = null;
    let isAddonAmount = false;
    let addonAmount = 0;
    let amountToCompare = 0;

    for (let i = 0; i < totalNodes; i++) {
      if (nodes[i]["disabled"]) continue;

      nodeStartTime = nodes[i]["start_time"];
      nodeEndTime = nodes[i]["end_time"];
      addonTypeObj = addonTypeOptions.find(
        (option) => option.value === nodes[i]["type_id"]
      );
      isAddonAmount = addonTypeObj
        ? addonTypeObj.amount
          ? true
          : false
        : false;
      if (isAddonAmount) {
        addonAmount = addonTypeObj.amount * 60;
      }
      changeDone = false;

      // compair hour's start time
      // hour start time after node start time AND hour start time is before (or same as) node end time, OR in case amount is present, the hour and node start time is not same, node is after hour
      if (
        (nodeStartTime < min && min <= nodeEndTime) ||
        (nodeStartTime > min && isAddonAmount)
      ) {
        nodes[i]["start_time"] = min;

        if (isAddonAmount) {
          amountToCompare = min + addonAmount;
          if (amountToCompare <= max) {
            nodes[i]["end_time"] = min + addonAmount;
          } else {
            nodes[i]["end_time"] = max;
          }
        }

        changeDone = true;
      } else if (min > nodeEndTime) {
        // hour start time after node end time
        nodes[i]["start_time"] = min;

        if (isAddonAmount) {
          amountToCompare = min + addonAmount;
          if (amountToCompare <= max) {
            // maintain gap of default value
            nodes[i]["end_time"] = amountToCompare;
          } else {
            nodes[i]["end_time"] = max;
          }
        } else {
          nodes[i]["end_time"] = min;
        }

        changeDone = true;
      }

      if (!changeDone) {
        // compair hour's end time
        // hour end time between node start-end time
        if (nodeEndTime > max && max >= nodeStartTime) {
          nodes[i]["end_time"] = max;

          if (isAddonAmount) {
            amountToCompare = max - addonAmount;
            if (amountToCompare >= min) {
              nodes[i]["start_time"] = amountToCompare;
            }
          }
        } else if (max < nodeStartTime) {
          // hour end time appears before node start time
          nodes[i]["end_time"] = max;

          if (isAddonAmount) {
            amountToCompare = max - addonAmount;
            if (amountToCompare >= min) {
              // maintain gap of default value
              nodes[i]["start_time"] = amountToCompare;
            }
          }
        } else if (max > nodeEndTime && isAddonAmount) {
          // hour end time is after node end time, where addon with default value
          amountToCompare = nodeStartTime + addonAmount;
          if (amountToCompare <= max) {
            nodes[i]["end_time"] = amountToCompare;
          } else {
            nodes[i]["end_time"] = max;
          }
        }
      }
    }

    return nodes;
  };

  copyProjectTask = () => {
    // close menu before the action, prevent multiple click
    this.disabled = true;

    this.props.openProjectTaskEditModal(
      this.state.nodeType,
      this.state.nodeIndex,
      true
    );

    this.handleMenuClose();
  };

  openProjectTask = () => {
    this.disabled = true;

    this.props.openProjectTaskEditModal(
      this.state.nodeType,
      this.state.nodeIndex,
      false
    );

    this.handleMenuClose();
  };

  removeProjectTask = () => {
    const {
      isEditMode,
      hourNodeRemoved,
      addonNodeRemoved,
      fixedAddonNodeRemoved,
      isManualEntriesOnEdit,
    } = this.props;

    this.disabled = true;

    let arrResult = null;

    if (this.state.nodeType === "hour") {
      arrResult = this.props.multiProjectTasks;
    } else if (this.state.nodeType === "time_addon") {
      arrResult = this.props.multiProjectAddonTasks;
    } else if (this.state.nodeType === "fixed_addon") {
      arrResult = this.props.fixedAddonTasks;
    }

    const index = this.state.nodeIndex;

    // If hours nodes, two or more, adjust timeings
    if (this.state.nodeType === "hour" && arrResult.length > 1) {
      // first node, adjust start time or next node
      if (index === 0) {
        arrResult[index + 1]["start_time"] = arrResult[index]["start_time"];
      } else {
        // change end time of previous node
        const preIndex = index - 1;
        arrResult[preIndex]["end_time"] = arrResult[index]["end_time"];
      }
    }

    // remove selected node
    let nodeToRemove = null;
    if (arrResult[index]["id"] && isManualEntriesOnEdit) {
      nodeToRemove = arrResult[index];
    }

    arrResult.splice(index, 1);

    if (this.state.nodeType === "hour") {
      if (isEditMode && nodeToRemove) {
        let updatedNodeList = hourNodeRemoved;

        updatedNodeList.push(nodeToRemove);

        this.props.changeState({
          multiProjectTasks: arrResult,
          hourNodeRemoved: updatedNodeList,
        });
      } else {
        this.props.changeState({
          multiProjectTasks: arrResult,
        });
      }
    } else if (this.state.nodeType === "time_addon") {
      if (isEditMode && nodeToRemove) {
        let updatedNodeList = addonNodeRemoved;

        updatedNodeList.push(nodeToRemove);

        this.props.changeState({
          multiProjectAddonTasks: arrResult,
          addonNodeRemoved: updatedNodeList,
        });
      } else {
        this.props.changeState({
          multiProjectAddonTasks: arrResult,
        });
      }
    } else if (this.state.nodeType === "fixed_addon") {
      if (isEditMode && nodeToRemove) {
        let updatedNodeList = fixedAddonNodeRemoved;

        updatedNodeList.push(nodeToRemove);

        this.props.changeState({
          fixedAddonTasks: arrResult,
          fixedAddonNodeRemoved: updatedNodeList,
        });
      } else {
        this.props.changeState({
          fixedAddonTasks: arrResult,
        });
      }
    }

    this.props.changeState({
      hourModalDataChanged: true,
    });

    this.handleMenuClose();
  };

  handleActionClick = (event, node, index) => {
    this.setState({
      anchorEl: event.currentTarget,
      nodeType: node,
      nodeIndex: index,
    });
  };

  handleMenuClose = () => {
    this.setState(
      {
        anchorEl: null,
        nodeType: null,
        nodeIndex: null,
      },
      () => {
        this.disabled = false;
      }
    );
  };

  componentDidMount = () => {
    const { startTime, endTime } = this.props;
    const min = moment.duration(startTime).asMinutes();
    let max = moment.duration(endTime).asMinutes();
    if (endTime < startTime) {
      max = max + 1440;
    }

    this.setState({
      min: min,
      max: max,
    });

    if (
      this.props.lunchStartTime !== "00:00" ||
      this.props.lunchEndTime !== "00:00"
    ) {
      let lunchStartTime = moment
        .duration(this.props.lunchStartTime)
        .asMinutes();
      let lunchEndTime = moment.duration(this.props.lunchEndTime).asMinutes();

      // cross day
      if (this.props.lunchStartTime < startTime) {
        lunchStartTime = lunchStartTime + 1440;
      }
      if (this.props.lunchEndTime < startTime) {
        lunchEndTime = lunchEndTime + 1440;
      }

      this.setState({
        lunchMarks: [
          {
            value: lunchStartTime,
          },
          {
            value: lunchEndTime,
          },
        ],
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.startTime !== this.props.startTime ||
      prevProps.endTime !== this.props.endTime
    ) {
      const min = moment.duration(this.props.startTime).asMinutes();
      let max = moment.duration(this.props.endTime).asMinutes();
      if (this.props.endTime < this.props.startTime) {
        max = max + 1440;
      }

      this.setState({
        min: min,
        max: max,
      });

      const arrResult = this.changeStartEndTimeSlotsHours(
        this.props.multiProjectTasks
      );

      const arrAddonResult = this.changeStartEndTimeSlotsAddons(
        this.props.multiProjectAddonTasks
      );

      this.props.changeState({
        multiProjectTasks: arrResult,
        multiProjectAddonTasks: arrAddonResult,
        hourModalDataChanged: true,
      });
    }

    if (
      prevProps.lunchStartTime !== this.props.lunchStartTime ||
      prevProps.lunchEndTime !== this.props.lunchEndTime
    ) {
      let lunchStartTime = moment
        .duration(this.props.lunchStartTime)
        .asMinutes();
      let lunchEndTime = moment.duration(this.props.lunchEndTime).asMinutes();

      // cross day
      if (this.props.lunchStartTime < this.props.startTime) {
        lunchStartTime = lunchStartTime + 1440;
      }
      if (this.props.lunchEndTime < this.props.startTime) {
        lunchEndTime = lunchEndTime + 1440;
      }

      this.setState({
        lunchMarks: [
          {
            value: lunchStartTime,
          },
          {
            value: lunchEndTime,
          },
        ],
      });

      this.props.changeState({
        hourModalDataChanged: true,
      });
    }
  };

  render = () => {
    const {
      classes,
      hoursByTypeNote,
      isAllowEditWithGeofence,
      isValidatedGeofenceEntry,
      isSickLeaveHours,
    } = this.props;

    const openMenu = Boolean(this.state.anchorEl);
    const isDragDisabled = isValidatedGeofenceEntry && !isAllowEditWithGeofence;

    return (
      <React.Fragment>
        <div className={classes.container}>
          {isDragDisabled && (
            <Alert
              severity="info"
              variant="outlined"
              className={classes.infoBox}
            >
              {i18next.t("msg-restricted-geofence")}
            </Alert>
          )}
          {isSickLeaveHours && (
            <Alert
              severity="info"
              variant="outlined"
              className={classes.infoBox}
            >
              {i18next.t("msg-sick-leave-hours")}
            </Alert>
          )}
          {hoursByTypeNote && (
            <Typography component="div" className={classes.errorText}>
              * {i18next.t(hoursByTypeNote)}
            </Typography>
          )}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={"droppable-hours"} ignoreContainerClipping>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {this.props.multiProjectTasks.map((data, index) => {
                    const isDisabled =
                      data?.validated_geofence && !isAllowEditWithGeofence;
                    const sickLeaveHour = data?.sick_leave_hour;

                    return (
                      <Draggable
                        draggableId={`h${index}`}
                        index={index}
                        isDragDisabled={
                          !this.state.isDragable ||
                          isDragDisabled ||
                          isSickLeaveHours
                        }
                        key={`h${index}`}
                      >
                        {(provided) => (
                          <Paper
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            className={classes.card}
                            id={`task-node-card${index}`}
                            key={`hp${index}`}
                          >
                            <div
                              className={classes.cardHeader}
                              {...provided.dragHandleProps}
                              id={`task-node-card-header${index}`}
                            >
                              <div className={classes.cardHeading}>
                                <div className={classes.taskName}>
                                  {data.task_name}
                                </div>
                                <div className={classes.projectName}>
                                  {data.project_name}
                                </div>
                              </div>
                              {!isDisabled && !sickLeaveHour && (
                                <div className={classes.iconContainer}>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    className={classes.iconButton}
                                    onClick={(e) => {
                                      this.handleActionClick(e, "hour", index);
                                    }}
                                    id={`task-node-card-more-button${index}`}
                                    size="large"
                                  >
                                    <MoreVertIcon
                                      className={classes.moreIcon}
                                    />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                            {data.type_id && (
                              <Typography
                                component="div"
                                variant="body2"
                                id={`task-node-card-type${index}`}
                                className={classes.sliderTypeName}
                              >
                                {data.type_name}
                              </Typography>
                            )}
                            <div
                              className={classes.cardContent}
                              id={`task-node-card-slider${index}`}
                            >
                              <Slider
                                value={[
                                  data.start_time,
                                  Math.ceil(data.end_time),
                                ]}
                                min={this.state.min}
                                max={this.state.max}
                                onChange={(_, newValue) => {
                                  this.handleChange(index, newValue);
                                }}
                                onChangeCommitted={() => {
                                  this.setState({
                                    isDragable: true,
                                  });
                                }}
                                step={5}
                                marks={this.state.lunchMarks}
                                valueLabelDisplay="on"
                                aria-labelledby="range-slider"
                                getAriaValueText={this.valuetext}
                                valueLabelFormat={this.valuetext}
                                className={`${classes.sliderOverride} ${
                                  !isDragDisabled && !isSickLeaveHours
                                    ? classes.hourTypeSlider
                                    : null
                                }`}
                                disabled={isDragDisabled || sickLeaveHour}
                              />
                            </div>
                          </Paper>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {this.props.multiProjectAddonTasks.find(
            (addon) => !addon.disabled
          ) && <Divider className={classes.divider} />}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={"droppable-addons"} ignoreContainerClipping>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {this.props.multiProjectAddonTasks.map((data, index) => {
                    if (!data.disabled) {
                      return (
                        <Draggable
                          draggableId={`a${index}`}
                          index={index}
                          isDragDisabled={
                            !this.state.isDragable || data.disabled
                          }
                          key={`a${index}`}
                        >
                          {(provided) => (
                            <Paper
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              className={classes.card}
                              id={`task-node-addon-card${index}`}
                              key={`ap${index}`}
                            >
                              <Typography
                                component="div"
                                className={classes.cardHeader}
                                {...provided.dragHandleProps}
                                id={`task-node-addon-card-header${index}`}
                              >
                                <div className={classes.cardHeading}>
                                  <div className={classes.taskName}>
                                    {data.task_name}
                                  </div>
                                  <div className={classes.projectName}>
                                    {data.project_name}
                                  </div>
                                </div>
                                {!data.disabled && (
                                  <div className={classes.iconContainer}>
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      className={classes.iconButton}
                                      onClick={(e) => {
                                        this.handleActionClick(
                                          e,
                                          "time_addon",
                                          index
                                        );
                                      }}
                                      id={`task-node-addon-card-more-button${index}`}
                                      size="large"
                                    >
                                      <MoreVertIcon
                                        className={classes.moreIcon}
                                      />
                                    </IconButton>
                                  </div>
                                )}
                              </Typography>
                              {data.type_id && (
                                <Typography
                                  component="div"
                                  variant="body2"
                                  id={`task-node-addon-card-type${index}`}
                                  className={classes.sliderTypeName}
                                >
                                  {data.type_name}
                                </Typography>
                              )}
                              <div
                                className={classes.cardContent}
                                id={`task-node-addon-card-slider${index}`}
                              >
                                <Slider
                                  value={[
                                    data.start_time,
                                    Math.ceil(data.end_time),
                                  ]}
                                  min={this.state.min}
                                  max={this.state.max}
                                  onChange={(event, newValue) => {
                                    this.handleAddonChange(index, newValue);
                                  }}
                                  onChangeCommitted={() => {
                                    this.setState({
                                      isDragable: true,
                                    });
                                  }}
                                  step={5}
                                  marks={this.state.lunchMarks}
                                  valueLabelDisplay="on"
                                  aria-labelledby="range-slider"
                                  getAriaValueText={this.valuetext}
                                  valueLabelFormat={this.valuetext}
                                  disabled={data.disabled}
                                  className={`${classes.sliderOverride} ${
                                    !data.disabled
                                      ? classes.addonTypeSlider
                                      : null
                                  }`}
                                />
                              </div>
                            </Paper>
                          )}
                        </Draggable>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {this.props.fixedAddonTasks?.length > 0 && (
            <Typography
              variant="body1"
              className={classes.planHeading}
              id="check-in-dialog-planned-hours-block-title"
            >
              {i18next.t("Fixed addons")}
            </Typography>
          )}

          {this.props.fixedAddonTasks?.map((data, index) => {
            return (
              <>
                <Paper
                  className={classes.card}
                  id={`task-node-fixed-addon-card${index}`}
                  key={`fap${index}`}
                >
                  <div
                    className={classes.cardHeader}
                    id={`task-node-fixed-addon-card-header${index}`}
                  >
                    <div className={classes.cardHeading}>
                      <div className={classes.taskName}>{data.task_name}</div>
                      <div className={classes.projectName}>
                        {data.project_name}
                      </div>
                    </div>
                    <div className={classes.iconContainer}>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        className={classes.iconButton}
                        onClick={(e) => {
                          this.handleActionClick(e, "fixed_addon", index);
                        }}
                        id={`task-node-fixed-addon-card-more-button${index}`}
                        size="large"
                      >
                        <MoreVertIcon className={classes.moreIcon} />
                      </IconButton>
                    </div>
                  </div>
                  <Divider className={classes.fixedAddonDivider} />
                  <Stack direction="row" alignItems="center" sx={{ pb: 1 }}>
                    <Typography variant="body2" style={{ flexGrow: 1 }}>
                      {data.type_name}
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" style={{ marginRight: 6 }}>
                        {data.type_value}
                      </Typography>
                      <Label
                        variant="custom"
                        hexColor={data.type_color}
                        height="small"
                      >
                        {data.type_measurement}
                      </Label>
                    </div>
                  </Stack>
                </Paper>
              </>
            );
          })}
        </div>
        <Menu
          id="action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={openMenu}
          onClose={this.handleMenuClose}
        >
          <MenuItem
            key={"Edit"}
            className={classes.actionItem}
            onClick={this.openProjectTask}
            disabled={this.disabled}
            id="task-node-card-edit-action"
          >
            <EditIcon className={classes.actionIcon} /> {i18next.t("Edit")}
          </MenuItem>
          <MenuItem
            key={"copy"}
            className={classes.actionItem}
            onClick={this.copyProjectTask}
            disabled={this.disabled}
            id="task-node-card-copy-action"
          >
            <FileCopyOutlinedIcon className={classes.actionIcon} />{" "}
            {i18next.t("Copy")}
          </MenuItem>
          <MenuItem
            key={"Remove"}
            className={classes.actionItem}
            onClick={this.removeProjectTask}
            disabled={this.disabled}
            id="task-node-card-remove-action"
          >
            <CloseIcon className={classes.actionIcon} /> {i18next.t("Remove")}
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(MultiProjectTaskNodes);
