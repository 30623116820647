import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";

import { getCheckInGuestList } from "@/services/guest";

import moment from "moment";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  selectInput: {
    display: "flex",
    padding: "0px 0px 0px 10px",
    height: "50px",
  },
  /*selectPaper: {
    position: "relative",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  selectMenuItem: {
    fontSize: 16,
  },
  errorText: {
    color: theme.palette.error.main,
    padding: "0px 5px 10px 15px",
  },
}));

const GuestSearch = (props) => {
  const { className, checkOutError, onGuestSelection, onCheckOut, ...rest } =
    props;

  const classes = useStyles();

  const [selectedGuest, setSelectedGuest] = useState(null);

  const loadGuestList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    const limit = 20;
    const currentDate = moment().format("YYYY-MM-DD");
    let queryString = `limit=${limit}&offset=${offset}&attendanceable_type=Guest&start_time=${currentDate}&end_time=${currentDate}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getCheckInGuestList(queryString);
    const result = await getGuestListOptions(response);
    return result;
  };

  const getGuestListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let guestList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];

          // ignore same user entry (multiple check-in)
          let attendaceElement = null;
          if (
            element.guest_attendances &&
            element.guest_attendances.length > 0
          ) {
            attendaceElement =
              element.guest_attendances[element.guest_attendances.length - 1];
          }

          if (attendaceElement) {
            guestList.push({
              value: element.guest_id.toString(),
              label: `${element.guest_name} - ${element.guest_email}`,
              attendaceId: attendaceElement.id,
            });
          }
        }

        resolve({
          options: guestList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onCheckOut();
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          subheader={i18next.t("Select guest name for check-out")}
          title={i18next.t("Guest")}
        />
        {checkOutError && (
          <Typography variant="body1" className={classes.errorText}>
            {checkOutError}
          </Typography>
        )}
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MaterialAsyncPaginate
                defualtOptions
                classes={classes}
                name="guest"
                value={selectedGuest}
                loadOptions={async (search, loadedOptions) => {
                  return loadGuestList(search, loadedOptions);
                }}
                onChange={(option) => {
                  setSelectedGuest(option);
                  onGuestSelection(option);
                }}
                autoFocus
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            onClick={handleSubmit}
          >
            {i18next.t("Check-out")}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

GuestSearch.propTypes = {
  className: PropTypes.string,
};

export default GuestSearch;
