import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Fab,
  SvgIcon,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ViewList, ViewModule, FilterList } from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import { withStyles } from "@mui/styles";
import useToggleMenubar from "@/hooks/useToggleMenubar";

const styles = (theme) => ({
  toggleButton: {
    color: theme.palette.text.primary,
  },
});

const MobileMenuButton = withStyles(styles)((props) => {
  const { onToggleMenubar } = useToggleMenubar();
  const { classes } = props;

  return (
    <IconButton
      onClick={() => {
        onToggleMenubar();
      }}
      id="menu-toggle-button"
      size="large"
      sx={{
        marginRight: "8px",
      }}
      className={classes.toggleButton}
    >
      <MenuIcon />
    </IconButton>
  );
});

export const CommonHeaderInList = withStyles((theme) => ({
  appbar: {
    zIndex: 900,
    position: "sticky",
    top: -5,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.paper}cc`
        : `${theme.palette.background.default}cc`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
  },
  viewContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 15,
    marginRight: 15,
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
    margin: 2,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  toolbar: {
    justifyContent: "center",
    position: "sticky",
    top: -5,
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 3),
    height: 64,
    [theme.breakpoints.up("md")]: {
      height: 92,
      padding: theme.spacing(0, 3),
    },
  },
  addButton: {
    display: "flex",
    flexWrap: "nowrap",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 30px",
    margin: "0px auto",
  },
  activeButton: {
    boxShadow: "none",
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}14`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  deactiveButton: {
    boxShadow: "none",
    background: "none",
    color: "none",
  },
  badge: {
    padding: 3,
    backgroundColor: "#2D99FF",
    boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.0),
         0px 6px 10px 0px rgba(0,0,0,0.12),
          0px 1px 18px 0px rgba(0,0,0,0.10)`,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
}))((props) => {
  const { classes, activeFilter } = props;

  return (
    <AppBar position="sticky" elevation={0} className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <MobileMenuButton />
        <Typography variant="h4" className={classes.title}>
          {props && props.title ? props.title : "Title"}
        </Typography>
        <div className={classes.flexGrow} />
        {props.view && (
          <Typography component="div" className={classes.viewContainer}>
            <Typography
              component="div"
              onClick={() => {
                props.changeView("list");
              }}
            >
              {props.view === "list" ? (
                <Fab
                  size="small"
                  className={`${classes.activeButton} ${classes.icon}`}
                >
                  <ViewList />
                </Fab>
              ) : (
                <IconButton
                  className={classes.icon}
                  style={{ padding: 8 }}
                  size="large"
                >
                  <ViewList />
                </IconButton>
              )}
            </Typography>
            <Typography
              component="div"
              onClick={() => {
                props.changeView("grid");
              }}
            >
              {props.view === "grid" ? (
                <Fab
                  size="small"
                  className={`${classes.activeButton} ${classes.icon}`}
                >
                  <ViewModule />
                </Fab>
              ) : (
                <IconButton
                  className={classes.icon}
                  style={{ padding: 8 }}
                  size="large"
                >
                  <ViewModule />
                </IconButton>
              )}
            </Typography>
          </Typography>
        )}
        {props && props.buttonTitle ? (
          <Button
            className={classes.addButton}
            variant="contained"
            onClick={(e) => {
              props.onButtonClick(e);
            }}
            disabled={props.isDisabledButton}
            id="page-navbar-add-action-button"
          >
            {props.buttonTitle}
          </Button>
        ) : null}
        {!props.removeFilter && (
          <Typography component="div" style={{ marginLeft: 15 }}>
            <Typography
              component="div"
              onClick={() => {
                props.onFilterClick();
              }}
            >
              <Badge
                invisible={!activeFilter}
                badgeContent={
                  props && props.filterCounter ? props.filterCounter : null
                }
                classes={{ badge: classes.badge }}
              >
                <FilterList className={classes.icon} />
              </Badge>
            </Typography>
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
});

export const CommonHeaderInSettings = withStyles((theme) => ({
  appbar: {
    zIndex: 900,
    position: "sticky",
    top: -5,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
  },
  title: {
    color: theme.palette.text.primary,
    // fontFamily: theme.Typography.h4,
  },
  addButton: {
    display: "flex",
    flexWrap: "nowrap",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 30px",
    marginRight: 16,
    marginLeft: 16,
  },
  toolbar: {
    justifyContent: "center",
    position: "sticky",
    top: -5,
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 2),
    height: 64,
    [theme.breakpoints.up("md")]: {
      height: 92,
      padding: theme.spacing(0, 3),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  buttonIcon: {
    fontSize: "16px",
    marginRight: "5px",
    marginTop: "-2px",
  },
  backIconContainer: {
    marginRight: "10px",
    verticalAlign: "center",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.text.primary,
  },
  badge: {
    top: -2,
    backgroundColor: "#2D99FF",
    boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.0),
       0px 6px 10px 0px rgba(0,0,0,0.12),
        0px 1px 18px 0px rgba(0,0,0,0.10)`,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
}))((props) => {
  const { classes, haveBackButton, id } = props;

  return (
    <AppBar position="sticky" elevation={0} className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <MobileMenuButton />
        {haveBackButton && (
          <Typography component="div" className={classes.backIconContainer}>
            <SvgIcon
              className={classes.icon}
              onClick={() => {
                props.onBackClick();
              }}
            >
              <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
            </SvgIcon>
          </Typography>
        )}
        <Typography component="div">
          <Typography variant="h4" className={classes.title} id={id ? id : ""}>
            {props && props.title ? props.title : "Title"}
          </Typography>
        </Typography>
        <div className={classes.flexGrow} />
        {props && props.buttonTitle && (
          <Button
            className={classes.addButton}
            variant="contained"
            onClick={(e) => {
              props.onButtonClick(e);
            }}
            id="page-navbar-action-button"
          >
            {props.buttonTitle}
          </Button>
        )}
        {props.haveFilter && (
          <Typography component="div" style={{ marginLeft: 15 }}>
            <Typography
              component="div"
              onClick={() => {
                props.onFilterClick();
              }}
            >
              <Badge
                invisible={!props.activeFilter}
                badgeContent={
                  props && props.filterCounter ? props.filterCounter : null
                }
                classes={{ badge: classes.badge }}
              >
                <FilterList className={classes.icon} />
              </Badge>
            </Typography>
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
});
