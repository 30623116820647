import { getBaseUrl, getApiDomain } from "@/utils/api.config.js";
import { actions as authActions } from "@/utils/auth.js";
import axios from "axios";

const baseUrl = getBaseUrl();
const apiDomain = getApiDomain();

export const core = {
  API: (
    method,
    url,
    baseType,
    commitType,
    rollbackType,
    data,
    metaArg = null,
    isClientToken
  ) => {
    const headers = {
      "Cache-Control": "no-cache",
      Accept: "*/*",
      Authorization: `Bearer ${
        authActions.isLoggedIn() && !isClientToken
          ? authActions.getAccessToken()
          : import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN
      }`,
    };

    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    return (dispatch) => {
      if (baseType) {
        dispatch({ type: baseType });
      }

      axios
        .request({
          url: `${baseUrl}${url}`,
          method,
          headers,
          [dataOrParams]: data,
        })
        .then(({ data }) => {
          dispatch({ type: commitType, meta: metaArg, payload: data });
        })
        .catch((error) => {
          let errorObj = error;
          if (error.response) {
            errorObj = {
              status: error.response.status || "Error",
              response: error.response.data || {},
              name: error.response.statusText || "",
            };
          }
          dispatch({ type: rollbackType, payload: errorObj });
        });
    };
  },
  API2: (
    method,
    url,
    baseType,
    commitType,
    rollbackType,
    data,
    metaArg = null,
    isClientToken
  ) => {
    const headers = {
      "Cache-Control": "no-cache",
      Accept: "*/*",
      Authorization: `Bearer ${
        authActions.isLoggedIn() && !isClientToken
          ? authActions.getAccessToken()
          : import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN
      }`,
    };

    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    return (dispatch) => {
      if (baseType) {
        dispatch({ type: baseType });
      }

      axios
        .request({
          url: `${apiDomain}${url}`,
          method,
          headers,
          [dataOrParams]: data,
        })
        .then(({ data }) => {
          dispatch({ type: commitType, meta: metaArg, payload: data });
        })
        .catch((error) => {
          let errorObj = error;
          if (error.response) {
            errorObj = {
              status: error.response.status || "Error",
              response: error.response.data || {},
              name: error.response.statusText || "",
            };
          }
          dispatch({ type: rollbackType, payload: errorObj });
        });
    };
  },
};
