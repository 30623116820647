import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import getLanguage from "@/utils/getLanguage";
import { getLocale } from "@/utils/dateTimeFunctions";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, title, path, ...rest } = props;

  const locale = getLocale(getLanguage());

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <Layout
            history={matchProps.history}
            location={matchProps.location}
            title={title}
            path={path}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={locale}
            >
              <Component {...matchProps} />
            </LocalizationProvider>
          </Layout>
        );
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
