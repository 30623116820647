import { useTheme } from "@mui/material/styles";
import {
  Control,
  ValueContainer,
  IndicatorSeparator,
  DropdownIndicator,
  SingleValue,
  OutlinedControl,
} from "./Controls";
import Creatable from "react-select/creatable";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";

import i18next from "i18next";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

export const MaterialAsyncPaginate = (props) => {
  const AsyncPaginateComponent = props.isCreatable
    ? CreatableAsyncPaginate
    : AsyncPaginate;

  const outlinedComponent = props.variant === "outlined" ? true : false;
  const muiTheme = useTheme();
  const reactSelectStyles = {
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: isDisabled
          ? muiTheme.palette.action.disabled
          : muiTheme.palette.text.primary,
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 1301 }),
    multiValueLabel: (base, state) => {
      return state.isDisabled ? { ...base, paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
      return state.isDisabled ? { ...base, display: "none" } : base;
    },
  };

  return (
    <AsyncPaginateComponent
      components={{
        Control: outlinedComponent ? OutlinedControl : Control,
        ValueContainer,
        IndicatorSeparator,
        DropdownIndicator,
        SingleValue,
      }}
      placeholder={`${i18next.t("Select")}...`}
      formatCreateLabel={(inputVal) => `${i18next.t("Create")} "${inputVal}"`}
      debounceTimeout={500}
      isValidNewOption={(inputValue, selectOptions) => {
        if (
          inputValue &&
          inputValue.length <= 240 &&
          !selectOptions.find((option) => {
            if (option.label.props) {
              return (
                option.label.props.primary.toLowerCase() ===
                inputValue.trim().toLowerCase()
              );
            } else {
              return (
                option.label.toLowerCase() === inputValue.trim().toLowerCase()
              );
            }
          })
        )
          return true;
      }}
      menuPortalTarget={document.body}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: muiTheme.palette.action.selected,
          primary25: muiTheme.palette.action.hover,
          primary50: muiTheme.palette.grey[500_16],
          neutral0: muiTheme.palette.background.paper,
        },
      })}
      styles={reactSelectStyles}
      {...props}
    />
  );
};
