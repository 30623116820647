import React from "react";
import { withStyles } from "@mui/styles";
import {
  Paper,
  Typography,
  Slider,
  Divider,
  Alert,
  Button,
} from "@mui/material";
import TooltipChooseHourType from "./InputMultiProjectHourModal/TooltipChooseHourType";
import SubmitSpinner from "@/common/Spinners";
import moment from "moment";
import i18next from "i18next";

const styles = (theme) => ({
  container: {
    margin: "20px 0px 0px 0px",
  },
  card: {
    padding: "10px 10px 0px 10px",
    marginBottom: 10,
    boxShadow: theme.customShadows.card,
    border: `1px solid ${theme.palette.text.secondary}29`,
  },
  cardHeader: {
    display: "flex",
    fontSize: 14,
    lineHeight: 1,
    color: theme.palette.text.primary,
  },
  cardContent: {
    padding: "5px 15px 15px 15px",
  },
  cardHeading: {
    flexGrow: 1,
    overflow: "hidden",
  },
  hourTypeName: {
    color: theme.palette.primary.main,
  },
  addonTypeName: {
    color: theme.palette.secondary.main,
  },
  divider: {
    borderColor: theme.palette.divider,
    margin: "0px 10px 10px 10px",

    "&:last-child": {
      border: 0,
      margin: 0,
      height: 0,
    },
  },
  iconContainer: {
    marginRight: "-5px",
    marginTop: "-5px",
    display: "flex",
    alignItems: "flex-start",
  },
  taskName: {
    fontWeight: 500,
    paddingBottom: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.palette.text.primary,
  },
  projectName: {
    paddingBottom: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  alertText: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    paddingBottom: 8,
  },
  sliderOverride: {
    height: "2px",
    "& .MuiSlider-valueLabel": {
      top: 40,
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      fontWeight: "normal",
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.text.primary,
      },
    },
    "& .MuiSlider-thumb": {
      height: "12px",
      width: "12px",
    },
    "& .MuiSlider-mark": {
      height: "8px",
      width: "1px",
    },
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
    },
  },
  hourTypeSlider: {
    color: theme.palette.primary.main,
    "& .MuiSlider-mark": {
      backgroundColor: theme.palette.action.disabled,
    },
  },
  addonTypeSlider: {
    color: theme.palette.secondary.main,
    "& .MuiSlider-mark": {
      backgroundColor: theme.palette.action.disabled,
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
  },
});

class MultiProjectHoursByType extends React.Component {
  constructor() {
    super();
    this.state = {
      min: 0,
      max: 1440,
      lunchMarks: [],
    };
  }

  valuetext = (value) => {
    return moment.utc().startOf("day").add(value, "minutes").format("HH:mm");
  };

  componentDidMount = () => {
    this.updateSliderData();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.startTime !== this.props.startTime ||
      prevProps.endTime !== this.props.endTime
    ) {
      this.updateSliderData();
    }

    if (
      prevProps.lunchStartTime !== this.props.lunchStartTime ||
      prevProps.lunchEndTime !== this.props.lunchEndTime
    ) {
      this.updateSliderData();
    }
  };

  updateSliderData = () => {
    const { startTime, endTime } = this.props;
    const min = moment.duration(startTime).asMinutes();
    let max = moment.duration(endTime).asMinutes();
    if (endTime < startTime) {
      max = max + 1440;
    }

    this.setState({
      min: min,
      max: max,
    });

    if (
      this.props.lunchStartTime !== "00:00" ||
      this.props.lunchEndTime !== "00:00"
    ) {
      let lunchStartTime = moment
        .duration(this.props.lunchStartTime)
        .asMinutes();
      let lunchEndTime = moment.duration(this.props.lunchEndTime).asMinutes();

      // cross day
      if (this.props.lunchStartTime < startTime) {
        lunchStartTime = lunchStartTime + 1440;
      }
      if (this.props.lunchEndTime < startTime) {
        lunchEndTime = lunchEndTime + 1440;
      }

      this.setState({
        lunchMarks: [
          {
            value: lunchStartTime,
          },
          {
            value: lunchEndTime,
          },
        ],
      });
    } else {
      this.setState({
        lunchMarks: [],
      });
    }
  };

  handleChangeTooltipHourType = (appointment, selectedType, isAddon) => {
    if (isAddon) {
      let multipleProjectAllAddons = this.props.multipleProjectAllAddons;
      multipleProjectAllAddons = multipleProjectAllAddons.map((item) => {
        if (item.id === appointment.id) {
          item.type_id = selectedType.type_id;
          item.type_name = selectedType.type_name;
          item["new_type_id"] = selectedType.type_id;
        }
        return item;
      });
      this.props.changeState({ multipleProjectAllAddons });
      this.props.setOptionalTypeChangeFlag();
    } else {
      let multipleProjectAllHours = this.props.multipleProjectAllHours;
      multipleProjectAllHours = multipleProjectAllHours.map((item) => {
        if (item.id === appointment.id) {
          item.type_id = selectedType.type_id;
          item.type_name = selectedType.type_name;
          item["new_type_id"] = selectedType.type_id;
        }
        return item;
      });
      this.props.changeState({ multipleProjectAllHours });
      this.props.setOptionalTypeChangeFlag();
    }
  };

  render = () => {
    const {
      classes,
      isOptionalHourType,
      isOptionalAddonType,
      hourModalDataChanged,
      onSubmitHourByTask,
      isQuickHoursSubmit,
    } = this.props;

    return (
      <Typography component="div" className={classes.container}>
        {hourModalDataChanged ? (
          <Alert
            severity="warning"
            variant="outlined"
            action={
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={onSubmitHourByTask}
              >
                {i18next.t("Submit")}
              </Button>
            }
          >
            {i18next.t("msg-hour-by-type-submit")}
          </Alert>
        ) : (
          <Typography component="div" className={classes.alertText}>
            *{" "}
            {i18next.t(
              "On this tab, only changes to optional types apply on submit"
            )}
          </Typography>
        )}
        <div className={classes.spinner}>
          <SubmitSpinner isLoading={isQuickHoursSubmit} />
        </div>
        <div style={{ marginTop: "8px" }}>
          {this.props.multipleProjectAllHours.map((data, index) => {
            return (
              <Paper
                className={classes.card}
                key={data.id}
                id={`hours-by-type-card${index}`}
              >
                <Typography
                  component="div"
                  className={classes.cardHeader}
                  id={`hours-by-type-card-header${index}`}
                >
                  <div className={classes.cardHeading}>
                    <div className={classes.taskName}>{data.task_name}</div>
                    <div className={classes.projectName}>
                      {data.project_name}
                    </div>
                  </div>
                  {data.type_id && isOptionalHourType && (
                    <div className={classes.iconContainer}>
                      <TooltipChooseHourType
                        hourTypeOptions={this.props.hourTypeOptions}
                        onChange={this.handleChangeTooltipHourType}
                        value={data}
                        isAddon={false}
                        uid={data.id}
                      />
                    </div>
                  )}
                </Typography>
                {data.type_id && (
                  <Typography
                    component="div"
                    variant="body2"
                    id={`hours-by-type-card-type${index}`}
                    className={classes.hourTypeName}
                  >
                    {data.type_name}
                  </Typography>
                )}
                <Typography
                  component="div"
                  className={classes.cardContent}
                  id={`hours-by-type-card-slider${index}`}
                >
                  <Slider
                    value={[data.start_time, Math.ceil(data.end_time)]}
                    min={this.state.min}
                    max={this.state.max}
                    //step={5}
                    marks={this.state.lunchMarks}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    getAriaValueText={this.valuetext}
                    valueLabelFormat={this.valuetext}
                    disabled={true}
                    className={`${classes.sliderOverride} ${classes.hourTypeSlider}`}
                  />
                </Typography>
              </Paper>
            );
          })}
        </div>
        {this.props.multipleProjectAllAddons.length > 0 && (
          <Divider className={classes.divider} />
        )}
        <div>
          {this.props.multipleProjectAllAddons.map((data, index) => {
            return (
              <Paper
                className={classes.card}
                key={index}
                id={`hours-by-type-addon-card${index}`}
              >
                <Typography
                  component="div"
                  className={classes.cardHeader}
                  id={`hours-by-type-addon-card-header${index}`}
                >
                  <div className={classes.cardHeading}>
                    <div className={classes.taskName}>{data.task_name}</div>
                    <div className={classes.projectName}>
                      {data.project_name}
                    </div>
                  </div>
                  {data.type_id && isOptionalAddonType && (
                    <TooltipChooseHourType
                      addonTypeOptions={this.props.addonTypeOptions}
                      onChange={this.handleChangeTooltipHourType}
                      value={data}
                      isAddon={true}
                      uid={data.id}
                    />
                  )}
                </Typography>
                {data.type_id && (
                  <Typography
                    component="div"
                    variant="body2"
                    id={`hours-by-type-addon-card-type${index}`}
                    className={classes.addonTypeName}
                  >
                    {data.type_name}
                  </Typography>
                )}
                <Typography
                  component="div"
                  className={classes.cardContent}
                  id={`hours-by-type-addon-card-slider${index}`}
                >
                  <Slider
                    value={[data.start_time, Math.ceil(data.end_time)]}
                    min={this.state.min}
                    max={this.state.max}
                    step={5}
                    marks={this.state.lunchMarks}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    getAriaValueText={this.valuetext}
                    valueLabelFormat={this.valuetext}
                    disabled={true}
                    className={`${classes.sliderOverride} ${classes.addonTypeSlider}`}
                  />
                </Typography>
              </Paper>
            );
          })}
        </div>
      </Typography>
    );
  };
}

export default withStyles(styles)(MultiProjectHoursByType);
