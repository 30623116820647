import * as types from "./types";
import { core } from "./core";
import moment from "moment";

export const getTasks = (data) => {
  let offset;
  if (data.offset) {
    offset = data.offset;
  } else {
    offset = 0;
  }

  let hour_summary = Object.prototype.hasOwnProperty.call(data, "hour_summary")
    ? data.hour_summary
    : true;

  return core.API(
    "GET",
    `/tasks?project_id=${data.project_id}&offset=${offset}&limit=${
      data.limit ? data.limit : 20
    }&hour_summary=${hour_summary}${
      data.fromDate
        ? `&start_time=${moment(data.fromDate).format("YYYY-MM-DD")}`
        : ""
    }${
      data.toDate ? `&end_time=${moment(data.toDate).format("YYYY-MM-DD")}` : ""
    }${data.search && data.search.length > 0 ? `&name=${data.search}` : ""}${
      data.finish ? `&finish=${data.finish}` : ""
    }`,
    types.GET_TASKS,
    types.GET_TASKS_SUCCESS,
    types.GET_TASKS_FAILURE,
    null,
    data
  );
};

export const getAllTasks = (data) => {
  let offset;
  if (data.offset) {
    offset = data.offset;
  } else {
    offset = 0;
  }

  let hour_summary = Object.prototype.hasOwnProperty.call(data, "hour_summary")
    ? data.hour_summary
    : true;

  return core.API(
    "GET",
    `/tasks?offset=${offset}&limit=${
      data.limit ? data.limit : 20
    }&hour_summary=${hour_summary}${
      data.fromDate
        ? `&start_time=${moment(data.fromDate).format("YYYY-MM-DD")}`
        : ""
    }${
      data.toDate ? `&end_time=${moment(data.toDate).format("YYYY-MM-DD")}` : ""
    }${data.search && data.search.length > 0 ? `&name=${data.search}` : ""}${
      data.finish ? `&finish=${data.finish}` : ""
    }${data.open ? `&open=${data.open}` : ""}${
      data.forLeave ? `&use_for_leave=${data.forLeave}` : ""
    }`,
    types.GET_ALL_TASKS,
    types.GET_ALL_TASKS_SUCCESS,
    types.GET_ALL_TASKS_FAILURE,
    null,
    data
  );
};

export const deleteTask = (id) => {
  return core.API(
    "DELETE",
    `/task/${id}`,
    types.DELETE_TASK,
    types.DELETE_TASK_SUCCESS,
    types.DELETE_TASK_FAILURE
  );
};

export const putTaskData = (id, data) => {
  return core.API(
    "PUT",
    `/task/${id}/`,
    types.PUT_TASK_DATA,
    types.PUT_TASK_DATA_SUCCESS,
    types.PUT_TASK_DATA_FAILURE,
    data
  );
};

export const addTask = (data) => {
  return core.API(
    "POST",
    `/task/`,
    types.ADD_TASK,
    types.ADD_TASK_SUCCESS,
    types.ADD_TASK_FAILURE,
    data
  );
};
