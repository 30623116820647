import { actions as authActions } from "@/utils/auth";
import { logout } from "@/utils/api.config.js";
import { removeItem } from "@/utils/localStorage";

import i18next from "i18next";

export default function performLogout(history) {
  const companyToken = authActions.getCompanyToken();

  const postObj = {
    client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
    client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
    token: authActions.getAccessToken(),
  };

  logout(postObj);

  // reset the language preference to browser
  localStorage.removeItem("language");
  i18next.changeLanguage(navigator.language);

  // reset other user parameters
  removeItem("userId");
  removeItem("userName");
  removeItem("userEmail");
  removeItem("userPosition");
  removeItem("userRole");
  removeItem("userPin");

  history.push("/token/" + companyToken);
}
