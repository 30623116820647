import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import WeekHeader from "./WeekHeader";
import Rows from "./Rows";
import { DragDropContext } from "react-beautiful-dnd";
import Pagination from "@mui/material/Pagination";

const styles = (theme) => ({
  Rtable: {
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
  },
  tableContainer: {
    border: `0.5px solid ${theme.palette.divider}`,
    overflow: "auto",
    height: "calc(100vh - 140px)",
    display: "block",
  },
  webkitSticky: {
    position: "-webkit-sticky",
  },
  paginationDiv: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 24,
    height: "60px",
  },
});

class Table extends Component {
  render() {
    const { state, classes, ...others } = this.props;
    const { weekdays } = this.props.state;

    return (
      <React.Fragment>
        <Grid item container xs={12} className={`${classes.tableContainer}`}>
          <div
            className={classes.Rtable}
            style={{
              minWidth:
                weekdays.length === 7
                  ? (weekdays.length + 1) * 170
                  : (weekdays.length + 1) * 220,
              width: weekdays.length < 7 ? (weekdays.length + 1) * 220 : "100%",
            }}
          >
            <WeekHeader
              weekdays={weekdays}
              weekDayTotalHours={state.weekDayTotalHours}
              groupBy={state.groupBy}
              searchUser={this.props.searchUser}
              searchProject={this.props.searchProject}
              planSchedule={this.props.planSchedule}
              startDate={this.props.startDate}
              changeState={this.props.changeState}
            />
            <DragDropContext onDragEnd={this.props.onDragEnd}>
              <Rows weekdays={weekdays} state={state} {...others} />
            </DragDropContext>
          </div>
        </Grid>

        <div className={`${classes.webkitSticky} ${classes.paginationDiv}`}>
          <Pagination
            color="primary"
            count={Math.ceil(state.totalItems / state.limit)}
            page={Math.ceil(state.offset / state.limit) + 1}
            onChange={(e, page) =>
              this.props.loadPlans((page - 1) * state.limit)
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Table);
