const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_ATTENDANCE":
      return {
        ...state,
        attendance: action.payload,
      };
    case "SET_COMPANY_MODULES":
      return {
        ...state,
        companyModules: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
