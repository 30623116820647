import { logoutApi } from "@/services/logout";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { actions as authActions } from "./auth";
import * as msal from "@azure/msal-browser";

import { getItem, clearAllItems } from "./localStorage";

export const authTokenKey = "AUTH_TOKEN";
export const responseHeaderKeys = {
  pagination: "x-pagination",
};

const baseUrl = import.meta.env.VITE_API_V1_URL;
const apiDomain = import.meta.env.VITE_API_BASE_URL;

export const get = (request) => {
  return commonFetch({ method: "get", ...request });
};

export const post = (request) => {
  return commonFetch({ method: "post", ...request });
};

export const patch = (request) => {
  return commonFetch({ method: "patch", ...request });
};

export const put = (request) => {
  return commonFetch({ method: "put", ...request });
};

export const deletee = (request) => {
  return commonFetch({ method: "delete", ...request });
};

const commonFetch = (request) => {
  const {
    subUrl,
    method,
    data = {},
    params = {},
    headers = {},
    v2Url,
  } = request;

  let url = null;
  if (request.url) {
    url = request.url;
  } else if (subUrl) {
    url = getFullUrl(subUrl);
  } else if (v2Url) {
    url = getV2Url(v2Url);
  }

  return axios({
    method,
    url,
    data,
    headers: { ...headers },
    params,
  }).then((response) => {
    if (handleResponseStatus(response)) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  });
};

const handleResponseStatus = (response) => {
  if (response.status >= 200 || response.status < 300) {
    return true;
  }
  return false;
};

const getFullUrl = (url) => {
  return `${baseUrl}${url}`;
};

const getV2Url = (url) => {
  return `${apiDomain}${url}`;
};

export const getBaseUrl = () => {
  return `${baseUrl}`;
};

export const getApiDomain = () => {
  return apiDomain;
};

export const getErrorObject = (error) => {
  let message = "Error!";
  if (error && error.response && error.response.data) {
    // API server error message
    let errorResponse = error.response.data;
    if (errorResponse.message) {
      message = errorResponse.message;
    } else if (errorResponse.errors) {
      message = errorResponse.errors;
    } else if (errorResponse.error_description) {
      message = errorResponse.error_description;
    } else if (errorResponse.error) {
      message = errorResponse.error;
    }
  } else if (error && error.message) {
    // js error message
    message = error.message;
  }
  return {
    message,
  };
};

export const getErrorCode = (error) => {
  let errorCode = null;
  if (error && error.response) {
    errorCode = error.response.status;
  }
  return errorCode;
};

export const logout = async (postObj) => {
  try {
    const response = await logoutApi(postObj);
    azureLogout();
    cleanData();

    // remove userData from sentry on logout
    Sentry.setUser(null);
    return response;
  } catch (error) {
    cleanData();
  }
};

export function cleanData() {
  var defaultDepartment =
    localStorage.getItem("default_department") &&
    localStorage.getItem("default_department");
  var defaultProject =
    localStorage.getItem("default_project") &&
    localStorage.getItem("default_project");
  var defaultTask =
    localStorage.getItem("default_task") &&
    localStorage.getItem("default_task");
  var deviceProjectTasks = localStorage.getItem("defualt_project_task_list")
    ? localStorage.getItem("defualt_project_task_list")
    : [];
  var skipHourModal =
    localStorage.getItem("skip_hour_modal") &&
    localStorage.getItem("skip_hour_modal") !== "true"
      ? false
      : true;
  var searchAllUsers =
    localStorage.getItem("allow_all_users_search") === "true" ? true : false;

  clearAllItems(); // remove all localStorage items and respective redux params
  localStorage.clear();

  localStorage.setItem("default_department", defaultDepartment);
  localStorage.setItem("default_project", defaultProject);
  localStorage.setItem("default_task", defaultTask);
  localStorage.setItem("defualt_project_task_list", deviceProjectTasks);
  localStorage.setItem("skip_hour_modal", skipHourModal);
  localStorage.setItem("allow_all_users_search", searchAllUsers);

  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function azureLogout() {
  // check if it is signin by Azure
  const azureAccount = getItem("azureAccount") ? getItem("azureAccount") : null;

  if (azureAccount) {
    const companyToken = authActions.getCompanyToken();
    const msalConfig = {
      auth: {
        clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID,
      },
    };
    try {
      const msalInstance = new msal.PublicClientApplication(msalConfig);

      // you can select which account application should sign out
      const logoutRequest = {
        account: msalInstance.getAccountByHomeId(azureAccount),
        postLogoutRedirectUri: "/token/" + companyToken,
      };

      msalInstance.logout(logoutRequest);
    } catch (err) {
      console.log(err);
    }
  }
}
