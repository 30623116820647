import React, { useEffect, useContext, useState, Fragment } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { Avatar, Typography, Badge, colors, Tooltip, Box } from "@mui/material";
import getInitials from "@/utils/getInitials";
import i18next from "i18next";
import OverlayIndicator from "@/common/OverlayIndicator";
import { Context } from "@/state/Store";
import { geolocated } from "react-geolocated";
import AttendanceStatusModal from "./AttendanceStatusModal";
import moment from "moment";
import { withSnackbar } from "notistack";

import {
  getLastUserAttendance,
  postUserAttendance,
  updateUserAttendance,
} from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  profile: {
    padding: 14,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    backgroundColor: "#18B24B",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 12,
    minWidth: 12,
  },
  notinBadge: {
    backgroundColor: colors.grey[600],
  },
  checkinBadge: {
    backgroundColor: "#26dc2e",
  },
  checkoutBadge: {
    backgroundColor: colors.red[600],
  },
  avatar: {
    width: 52,
    height: 52,
  },
  details: {
    marginTop: 12,
    overflow: "hidden",
  },
  whiteText: {
    color: "#ffffff",
  },
  pointer: {
    cursor: "pointer",
  },
}));

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const UserProfile = (props) => {
  const classes = useStyles();

  const {
    coords,
    isAttendanceModule,
    allowAttendanceChange,
    isGeoFenceForAttendace,
    isGeoFenceDisabled,
    checkInForAttendanceOnly,
    isLoading,
  } = props;

  const userId = getItem("userId") ? parseInt(getItem("userId"), 10) : null;

  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState("not-in");
  const [checkStatus, setCheckStatus] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const openStatusModal = () => {
    if (allowAttendanceChange) {
      setIsStatusModalOpen(true);
    }
  };

  const handleStatusModalCancel = () => {
    setIsStatusModalOpen(false);
  };

  const handleStatusModalSubmit = () => {
    setIsStatusModalOpen(false);
    setAttendanceStatus(status);
  };

  const setAttendanceStatus = async (currentStatus) => {
    const currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const attendaceObj = {
      attendanceable_id: userId,
      attendanceable_type: "User",
      is_utc: true,
    };
    // geo location
    if (isGeoFenceForAttendace && coords) {
      if (isGeoFenceDisabled) {
        attendaceObj["tablet"] = true;
      } else {
        attendaceObj["latitude"] = coords.latitude;
        attendaceObj["longitude"] = coords.longitude;
      }
    }

    try {
      if (currentStatus === "not-in" || currentStatus === "out") {
        attendaceObj["start_time"] = currentTime;
        const response = await postUserAttendance(attendaceObj);
        if (response.data) {
          setCheckStatus(true);
        }
      } else if (currentStatus === "in") {
        const response = await getLastUserAttendance();
        if (response && response.data && response.data.entry) {
          if (response.data.entry.id) {
            attendaceObj["end_time"] = currentTime;
            const attendaceId = response.data.entry.id;
            const result = await updateUserAttendance(
              attendaceId,
              attendaceObj
            );
            if (result.data) {
              setCheckStatus(true);
            }
          }
        }
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      console.log(message);
      props.enqueueSnackbar(message, { variant: "error" });
    }
  };

  useEffect(() => {
    setStatus(state.attendance.status);
  }, [state.attendance.status]);

  useEffect(() => {
    async function checkIfAttendanceReported() {
      const response = await getLastUserAttendance();
      if (response && response.data && response.data.status) {
        const newStatus = response.data.status;
        const attendanceObj = {
          status:
            newStatus === "not-check-in"
              ? "not-in"
              : newStatus === "check-out"
                ? "out"
                : "in",
        };

        dispatch({ type: "SET_ATTENDANCE", payload: attendanceObj });
      }
    }

    if (
      (!isLoading.companyModule && !isLoading.companySetting) ||
      checkStatus
    ) {
      checkIfAttendanceReported();

      if (checkStatus) {
        setCheckStatus(false);
      }
    }
  }, [isLoading, checkStatus, dispatch]);

  let toolTip = i18next.t("Check-in for attendance not done");
  if (status === "in") {
    toolTip = i18next.t("Check-in done for attendance");
  } else if (status === "out") {
    toolTip = i18next.t("Check-out done for attendance");
  }

  const name = getItem("userName") ? getItem("userName") : "";
  const position = getItem("userPosition") ? getItem("userPosition") : "";
  const avatar = getItem("avatarUrl") ? getItem("avatarUrl") : "";
  const company = sessionStorage.getItem("companyName")
    ? sessionStorage.getItem("companyName")
    : "";

  return (
    <Fragment>
      <RootStyle>
        <Tooltip title={toolTip} placement="bottom">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            classes={{
              dot: classes.badgeDot,
              badge: clsx({
                [classes.badge]: true,
                [classes.notinBadge]: status === "not-in",
                [classes.checkinBadge]: status === "in",
                [classes.checkoutBadge]: status === "out",
                [classes.pointer]:
                  allowAttendanceChange || checkInForAttendanceOnly,
              }),
            }}
            variant="dot"
            invisible={!isAttendanceModule}
            onClick={openStatusModal}
            id="menu_user_avatar"
          >
            <Avatar
              alt="Person"
              className={clsx({
                [classes.avatar]: true,
                [classes.pointer]:
                  allowAttendanceChange || checkInForAttendanceOnly,
              })}
              src={avatar ? `${baseUrl}${avatar}` : ""}
            >
              {getInitials(name)}
            </Avatar>
          </Badge>
        </Tooltip>
        <Box
          sx={{
            ml: 2,
            minWidth: 0,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          <Typography
            variant="body2"
            noWrap
            sx={{ color: "text.secondary", fontSize: "0.76rem" }}
          >
            {position && position}
          </Typography>
          <Typography
            variant="body2"
            noWrap
            sx={{ color: "text.secondary", fontSize: "0.76rem" }}
          >
            {company && company}
          </Typography>
        </Box>
      </RootStyle>
      <AttendanceStatusModal
        isOpen={isStatusModalOpen}
        currentStatus={status}
        handleCancel={handleStatusModalCancel}
        handleSubmit={handleStatusModalSubmit}
      />
      <OverlayIndicator
        isLoading={isLoading.companyModule || isLoading.companySetting}
      />
    </Fragment>
  );
};

const geoLockSettings = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
};

export default withSnackbar(geolocated(geoLockSettings)(UserProfile));
