import React, { useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Badge,
} from "@mui/material";

import i18next from "i18next";

import getInitials from "@/utils/getInitials";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
    padding: "8px 0",
  },
  content: {
    padding: 0,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  nameLink: {
    cursor: "pointer",
  },
  actions: {
    justifyContent: "flex-end",
    borderTop: `0.5px solid ${theme.palette.divider}`,
    "& .MuiTablePagination-root": {
      borderTop: "none",
    },
    "& .MuiToolbar-root": {
      color: theme.palette.text.primary,
      "& p": {
        marginBottom: 0,
      },
    },
  },
  pointer: {
    cursor: "pointer",
  },
  avatarBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
  },
  avatar: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.disabled,
    cursor: "pointer",
  },
  userIn: {
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  userOut: {
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
  userName: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const UsersTable = (props) => {
  const {
    users,
    loadUsers,
    totalRecords,
    onLimitChange,
    onAvatarOpen,
    onAttendanceClick,
    isToday,
    ...rest
  } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
    loadUsers(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    onLimitChange(event.target.value);
  };

  return (
    <Card {...rest} className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18next.t("Name")}</TableCell>
                  <TableCell>{i18next.t("Email")}</TableCell>
                  <TableCell>{i18next.t("Check-in")}</TableCell>
                  <TableCell>{i18next.t("Check-out")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow className={classes.tableRow} hover key={user.id}>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                          className={`${classes.avatarBadge} ${
                            user.isOut === true
                              ? classes.userOut
                              : classes.userIn
                          }`}
                        >
                          <Avatar
                            className={`${classes.avatar}  ${
                              isToday && classes.avatarCursor
                            }`}
                            src={
                              user.avatarThumbUrl
                                ? `${baseUrl}${user.avatarThumbUrl}`
                                : null
                            }
                            onClick={() => {
                              if (isToday) {
                                onAttendanceClick(user.user_id);
                              }
                            }}
                          >
                            {getInitials(user.name)}
                          </Avatar>
                        </Badge>
                        <Typography
                          variant="body1"
                          className={`${classes.userName} ${
                            user.avatarUrl && classes.nameLink
                          }`}
                          // className={user.avatarUrl && classes.nameLink}
                          onClick={() => {
                            if (user.avatarUrl) {
                              onAvatarOpen(`${baseUrl}${user.avatarUrl}`);
                            }
                          }}
                        >
                          {user.name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.check_in.split("|").map((checkin, index) => {
                        return <p key={index}>{checkin}</p>;
                      })}
                    </TableCell>
                    <TableCell>
                      {user.check_out.split("|").map((checkout, index) => {
                        return <p key={index}>{checkout}</p>;
                      })}
                    </TableCell>
                  </TableRow>
                ))}
                {users && users.length < 1 && (
                  <TableRow className={classes.tableRow} hover>
                    <TableCell colSpan={4} align="center">
                      {i18next.t("No data to display")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalRecords}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25]}
          labelRowsPerPage={i18next.t("Rows per page")}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default UsersTable;
