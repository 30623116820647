import i18next from "i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
});

function ConfirmModal(props) {
  const { isOpen, handleCancel, handleSubmit, classes, message } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>{i18next.t("Confirm")}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className={classes.btnCancel}>
          {i18next.t("Cancel")}
        </Button>
        <Button onClick={handleSubmit} className={classes.button}>
          {i18next.t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ConfirmModal);
