import React, { Fragment } from "react";
import TaskDuration from "./TaskDuration";
import i18next from "i18next";

import { withStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Switch,
  Tab,
  Tabs,
  Typography,
  FormControlLabel,
  Checkbox,
  Alert,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MultiProjectTaskNodes from "../MultiProjectTaskNodes";
import MultiProjectHoursByType from "../MultiProjectHoursByType";
import moment from "moment";
import { isWeekStartWithMonday } from "@/utils/dateTimeFunctions";

import TabsEditPreview from "./TabsEditPreview";
import MultiSelectDatePicker from "@/common/MultiSelectDatePicker";
import HoursPerType from "./HoursPerType";
import HoursPerProject from "./HoursPerProject";
import ConfirmModal from "@/common/ConfirmModal";

import styles from "./styles";
import { getItem } from "@/utils/localStorage";

const PreviewTabs = withStyles({
  root: {
    width: "100%",
    marginLeft: 16,
    borderBottom: "0.5px solid #e8e8e8",
  },
})(Tabs);

class InputMultiProjectHourModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      isOpenCalendar: false,
      selectedTab: 1,
      previewSelectedTab: 0,
      editingSelectedTab: 0,
      durationType: "CheckIn",
      selectedDate: new Date(),
      repeatDates: [],
      selectDateError: false,
      showLunchTime: false,
      loadedLunchTime: false,
      isDateError: false,
      disableAddProject: false,
      hoursByTypeNote: "",
      isOptionalTypeApplied: false,
      disableLunch: false,
      openConfirmModal: false,
    };
  }

  componentDidMount = () => {};

  handleChangePreviewTab = (event, previewSelectedTab) => {
    this.setState({ previewSelectedTab });
  };

  handleChangeEditingTab = (event, editingSelectedTab) => {
    this.setState({ editingSelectedTab });
  };

  toggleCalendar = (e) => {
    e && e.preventDefault();
    this.setState({ isOpenCalendar: !this.state.isOpenCalendar });
  };

  resetStateValues = () => {
    this.setState({
      selectedTab: 0,
      durationType: "CheckIn",
      showLunchTime: false,
      loadedLunchTime: false,
      hoursByTypeNote: "",
      isOptionalTypeApplied: false,
      editingSelectedTab: 0,
      disableLunch: false,
      selectedDate: new Date(),
    });
  };

  handleCancel = () => {
    this.resetStateValues();
    this.props.handleCancel();
  };

  openProjectTaskModal = () => {
    const { state } = this.props;
    if (state.startTime === "00:00" && state.endTime === "00:00") {
      this.setState({ selectDateError: true });
      setTimeout(() => {
        this.setState({ selectDateError: false });
      }, 5000);
    } else {
      this.props.changeState({
        openProjectTaskModal: true,
        selectedType: "hour_type",
        selectedProject: state.selectedUserProject || null,
        selectedProjectOption: state.selectedUserProjectOption || null,
        selectedTask: state.selectedUserTask || null,
      });

      if (
        !state.isCheckInEntry &&
        (state.restrictEditTimeMultiProject ||
          (state.isManualEntriesOnEdit && state.groupId))
      ) {
        this.props.changeState({
          isTypeSelected: true,
          isMultiProjectHourTypeRequired: true,
        });
      }
    }
  };

  openProjectTaskEditModal = (type, index, isCopy) => {
    const { state } = this.props;

    if (type === "hour") {
      if (this.props.state.multiProjectTasks[index]) {
        const taskNode = this.props.state.multiProjectTasks[index];
        this.props.changeState({
          selectedProject: taskNode.project_id,
          selectedProjectOption: {
            value: taskNode.project_id.toString(),
            label: taskNode.project_name,
          },
          selectedTask: {
            value: taskNode.task_id.toString(),
            label: taskNode.task_name,
          },
          selectedType: "hour_type",
          isTypeSelected: taskNode.type_id ? true : false,
          selectedHourType: taskNode.type_id
            ? { value: taskNode.type_id.toString(), label: taskNode.type_name }
            : null,
          openProjectTaskModal: true,
        });

        if (!isCopy) {
          this.props.changeState({
            multipleProjectNodeEditIndex: index,
            isEditMultipleProjectNode: true,
          });
        }

        // in case of manual entry, check if hour/addon type is mandatory
        if (!state.isCheckInEntry && state.restrictEditTimeMultiProject) {
          this.props.changeState({
            isTypeSelected: true,
            isMultiProjectHourTypeRequired: true,
          });
        }
      }
    } else if (type === "time_addon") {
      if (this.props.state.multiProjectAddonTasks[index]) {
        const taskNode = this.props.state.multiProjectAddonTasks[index];
        this.props.changeState({
          selectedProject: taskNode.project_id,
          selectedProjectOption: {
            value: taskNode.project_id.toString(),
            label: taskNode.project_name,
          },
          selectedTask: {
            value: taskNode.task_id.toString(),
            label: taskNode.task_name,
          },
          selectedType: "time_addon",
          isTypeSelected: taskNode.type_id ? true : false,
          selectedAddonType: taskNode.type_id
            ? { value: taskNode.type_id.toString(), label: taskNode.type_name }
            : null,
          openProjectTaskModal: true,
        });

        if (!isCopy) {
          this.props.changeState({
            multipleProjectNodeEditIndex: index,
            isEditMultipleProjectNode: true,
          });
        }

        // in case of manual entry, check if hour/addon type is mandatory
        if (!state.isCheckInEntry && state.restrictEditTimeMultiProject) {
          this.props.changeState({
            isTypeSelected: true,
            isMultiProjectHourTypeRequired: true,
          });
        }
      }
    } else if (type === "fixed_addon") {
      if (this.props.state.fixedAddonTasks[index]) {
        const taskNode = this.props.state.fixedAddonTasks[index];
        this.props.changeState({
          selectedProject: taskNode.project_id,
          selectedProjectOption: {
            value: taskNode.project_id.toString(),
            label: taskNode.project_name,
          },
          selectedTask: {
            value: taskNode.task_id.toString(),
            label: taskNode.task_name,
          },
          selectedType: "fixed_addon",
          isTypeSelected: taskNode.type_id ? true : false,
          selectedFixedAddonType: taskNode.type_id
            ? {
                value: taskNode.type_id,
                label: taskNode.type_name,
                measurement: taskNode.type_measurement,
                color: taskNode.type_color,
              }
            : null,
          fixedAddonValue: taskNode.type_value,
          openProjectTaskModal: true,
        });

        if (!isCopy) {
          this.props.changeState({
            multipleProjectNodeEditIndex: index,
            isEditMultipleProjectNode: true,
          });
        }

        // in case of manual entry, check if hour/addon type is mandatory
        if (!state.isCheckInEntry && state.restrictEditTimeMultiProject) {
          this.props.changeState({
            isTypeSelected: true,
            isMultiProjectHourTypeRequired: true,
          });
        }
      }
    }
  };

  isRestrictEditLunch = () => {
    const { state } = this.props;

    // In case auto fill lunch value present and following setting are ON, disable lunch section
    if (state.restrictLunchEditIfAutofill) {
      this.setState({ disableLunch: true });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { state } = this.props;
    if (
      prevProps.state.dayNumber !== state.dayNumber &&
      this.props.startDate &&
      !state.repeat_Dates?.length
    ) {
      let weekStartDate = this.props.startDate;
      if (state.isCheckInEntry) {
        weekStartDate = moment().clone().startOf("week");
      }

      const selectedDate = moment(weekStartDate)
        .clone()
        .day(state.dayNumber ? state.dayNumber : 0)
        .toDate();

      this.setState({
        selectedDate,
      });
    }

    if (prevProps.state.openMultiProjectModal !== state.openMultiProjectModal) {
      if (state.openMultiProjectModal) {
        // If all manual entries, tick the "Edit outside rule" checkbox so that, admin can untick and convert to rules based entries
        if (!state.isManualEntriesOnEdit && !state.isRulesBasedHourEntry) {
          this.props.changeState({
            allowRulesToManualEntry: true,
          });
        }

        this.setState({ selectedTab: 1 });

        if (
          !this.state.showLunchTime &&
          !this.state.loadedLunchTime &&
          (state.lunchStartTime !== "00:00" || state.lunchEndTime !== "00:00")
        ) {
          this.setState({ showLunchTime: true, loadedLunchTime: true });

          // if Edit modal, check for this
          if (state.groupId) {
            this.isRestrictEditLunch();
          }
        }

        // Autofill lunch time: only if Add modal, default start time
        if ((!state.groupId || state.isCheckInEntry) && state.startTime) {
          // autofill lunch input present
          const showLunchTime = await this.props.checkForAutofilLunchTime();
          this.setState({
            showLunchTime: showLunchTime,
          });

          if (showLunchTime) {
            this.isRestrictEditLunch();
          }
        }
      }
    }

    if (prevState.editingSelectedTab !== this.state.editingSelectedTab) {
      this.setState({
        disableAddProject: this.state.editingSelectedTab === 1 ? true : false,
      });

      if (
        this.state.editingSelectedTab === 0 &&
        this.state.isOptionalTypeApplied
      ) {
        this.setState({
          hoursByTypeNote:
            "On this tab, the optional type edits will not apply on Submit",
        });
      }
    }

    // validate lunch time on start/end time changes and see if lunch time becomes out of boundery
    if (
      prevProps.state.startTime !== state.startTime ||
      prevProps.state.endTime !== state.endTime ||
      prevProps.state.lunchStartTime !== state.lunchStartTime ||
      prevProps.state.lunchEndTime !== state.lunchEndTime
    ) {
      if (
        this.state.showLunchTime &&
        (state.lunchStartTime !== "00:00" || state.lunchEndTime !== "00:00")
      ) {
        let tempStartTime = moment(state.lunchStartTime, "HH:mm");
        let tempEndTime = moment(state.lunchEndTime, "HH:mm");
        let checkInStart = moment(state.startTime, "HH:mm");
        let checkInEnd = moment(state.endTime, "HH:mm");

        // check for cross day
        if (moment(checkInEnd).isBefore(checkInStart)) {
          // add 24 hour in the end time to match correctly
          checkInEnd = moment(checkInEnd).add(1, "days");

          // if lunch start/end time is before start time, means they are for next day, add 24 hour to match correctly
          if (moment(tempStartTime).isBefore(checkInStart)) {
            tempStartTime = moment(tempStartTime).add(1, "days");
          }

          if (moment(tempEndTime).isBefore(checkInStart)) {
            tempEndTime = moment(tempEndTime).add(1, "days");
          }
        }

        if (
          moment(tempStartTime).isBetween(checkInStart, checkInEnd, "minute") &&
          moment(tempEndTime).isBetween(checkInStart, checkInEnd, "minute")
        ) {
          this.props.changeState({
            isLunchTimeInvalid: false,
          });
        } else {
          this.props.changeState({
            isLunchTimeInvalid: true,
          });
        }
      } else {
        if (state.isLunchTimeInvalid) {
          this.props.changeState({
            isLunchTimeInvalid: false,
          });
        }
      }
    }
  };

  onDateStatusChange = (status) => {
    this.setState({
      isDateError: status ? true : false,
    });
  };

  // returns amount in minutes
  getAmountFromStartAndEndTime = (startTime, endTime) => {
    const diffTime = moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"));
    return moment.duration(diffTime).asMinutes();
  };

  SwitchLunchTime = () => {
    const { state } = this.props;

    return (
      <Fragment>
        <Typography
          component="div"
          style={{ marginLeft: -10, marginBottom: 10, marginTop: 10 }}
        >
          <Switch
            color="primary"
            checked={this.state.showLunchTime}
            onChange={(event, showLunchTime) => {
              this.setState({ showLunchTime });
              this.props.changeState({
                lunchStartTime: "00:00",
                lunchEndTime: "00:00",
                isLunchTimeInvalid: false,
              });
            }}
            disabled={this.state.disableLunch}
          />
          <Typography component="span" variant="subtitle2">
            {i18next.t("Add lunch time")}
          </Typography>
        </Typography>
        {this.state.showLunchTime ? (
          <TaskDuration
            type="Lunch"
            customProps={this.props}
            isDisabled={
              ((state.groupId || state.isCheckInEntry) &&
                state.restrictEditTimeMultiProject) ||
              this.state.disableLunch ||
              state.showPreviewRulesOutcome ||
              this.state.editingSelectedTab !== 0
            }
            isLunchError={state.isLunchTimeInvalid}
          />
        ) : null}
      </Fragment>
    );
  };

  ActionsButtons = () => {
    const { classes, state } = this.props;

    return (
      <Box
        style={{
          display: "flex",
          justifyContent: state.groupId ? "space-between" : "flex-end",
          width: "100%",
        }}
      >
        <div>
          {state.groupId &&
            !state.isCheckInEntry && ( // hide delete button if checkOut modal ...
              <Button
                onClick={() => {
                  this.props.deleteModalOpen(state.groupId);
                  this.setState({ editingSelectedTab: 0 });
                }}
                className={classes.btnDanger}
                id="input-hour-modal-delete-button"
              >
                {i18next.t("Delete")}
              </Button>
            )}
          {state.isCheckInEntry && (
            <Button
              onClick={this.handleDiscardClick}
              className={classes.btnDanger}
              id="input-hour-modal-discard-button"
            >
              {i18next.t("Discard check-in")}
            </Button>
          )}
        </div>
        <Box className={classes.rightButtons}>
          <Button
            onClick={this.handleCancel}
            className={classes.btnCancel}
            id="input-hour-modal-cancel-button"
          >
            {i18next.t("Cancel")}
          </Button>
          <Button
            className={classes.btnSubmit}
            style={{ marginLeft: 10 }}
            disabled={this.checkInputsForSubmit()}
            type="submit"
            color="primary"
            variant="contained"
            onClick={this.onSubmit}
          >
            {i18next.t("Submit")}
          </Button>
        </Box>
      </Box>
    );
  };

  onSubmit = async () => {
    const { onSubmit, onSubmitOptionalType, onCheckoutHourModalSubmit, state } =
      this.props;

    this.resetStateValues();

    if (this.state.editingSelectedTab === 1) {
      onSubmitOptionalType();
    } else {
      if (state.isCheckInEntry) {
        onCheckoutHourModalSubmit();
      } else {
        onSubmit();
      }
    }
  };

  setOptionalTypeChangeFlag = () => {
    this.setState({
      isOptionalTypeApplied: true,
    });
  };

  handleEditOutsideRulesClick = () => {
    const { state } = this.props;

    const isTicked = !state.allowRulesToManualEntry;

    this.props.handleEditOutsideRules(isTicked);
  };

  checkInputsForSubmit = () => {
    const { state } = this.props;

    if (
      this.getAmountFromStartAndEndTime(state.startTime, state.endTime) === 0
    ) {
      return true;
    }

    if (
      state.multiProjectTasks &&
      state.multiProjectTasks.length === 0 &&
      state.multiProjectAddonTasks &&
      state.multiProjectAddonTasks.length === 0 &&
      state.fixedAddonTasks &&
      state.fixedAddonTasks.length === 0
    ) {
      return true;
    }

    if (this.state.isDateError) {
      return true;
    }

    return false;
  };

  handleDiscardClick = () => {
    this.setState({
      openConfirmModal: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      openConfirmModal: false,
    });
  };

  submitDiscardClick = () => {
    this.closeConfirmModal();
    this.resetStateValues();
    this.props.handleCheckInDiscard();
  };

  render() {
    const {
      state,
      classes,
      previewRulesOutcome,
      handleCancelPreview,
      onSubmitHourByTask,
    } = this.props;
    const { SwitchLunchTime, ActionsButtons } = this;
    let allowAddProject = true;
    // In case of Edit and isManualEntriesOnEdit is set, allow add project only if user can select hour/addon type
    // because it is mandatory to pass hour/addon type in Edit action, for backend.
    if (
      state.isManualEntriesOnEdit &&
      state.groupId &&
      !state.isHourRulesException &&
      !state.isAddonRulesException
    ) {
      allowAddProject = false;
    }

    // check if hour/addon nodes are available
    let isTaskNodesAvailable = false;
    if (
      (state.multiProjectTasks && state.multiProjectTasks.length > 0) ||
      (state.multiProjectAddonTasks &&
        state.multiProjectAddonTasks.length > 0) ||
      (state.fixedAddonTasks && state.fixedAddonTasks.length > 0)
    ) {
      isTaskNodesAvailable = true;
    }

    const isEditMode = state.groupId ? true : false;
    const isAdmin = getItem("userRole") === "admin";

    const titleWords = state.title.split(" ");
    const headerTitle =
      titleWords[0] === "Continuous"
        ? i18next.t("Check out")
        : titleWords[0] === "Update"
          ? i18next.t("Update hours")
          : i18next.t("Add hours");

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={state.openMultiProjectModal}
          id="input-hour-modal"
        >
          <DialogTitle className={classes.header} id="input-hour-modal-title">
            {headerTitle}
            {state.isPreviewRulesOutcome ? (
              <TabsEditPreview
                selectedTab={this.state.selectedTab}
                onChange={(selectedTab) => {
                  this.setState({ selectedTab });
                  if (selectedTab === 0) {
                    previewRulesOutcome();
                  } else {
                    handleCancelPreview();
                  }
                }}
              />
            ) : (
              <IconButton
                aria-label={i18next.t("Close")}
                className={classes.closeButton}
                onClick={this.handleCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <form>
              <Grid container spacing={2} className={classes.mb}>
                <Grid item xs={12} id="input-hour-modal-check-in-out">
                  <Typography className={classes.durationLabel} color="primary">
                    {i18next.t("Duration")}
                  </Typography>
                  <TaskDuration
                    type="CheckIn"
                    customProps={this.props}
                    isDisabled={
                      ((state.groupId || state.isCheckInEntry) &&
                        state.restrictEditTimeMultiProject) ||
                      state.showPreviewRulesOutcome ||
                      this.state.editingSelectedTab !== 0
                    }
                    endTimeDisabled={
                      state.isCheckInEntry && state.isContinuesCheckIn
                    }
                  />
                </Grid>
                <Grid item xs={12} id="input-hour-modal-switch-lunch-time">
                  <SwitchLunchTime />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div" className={classes.titleLabel}>
                    {state.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} id="input-hour-modal-datepicker">
                  <MultiSelectDatePicker
                    selectedDate={this.state.selectedDate}
                    onChange={(repeatDates) => {
                      this.setState({ repeatDates });
                      this.props.changeState({ repeat_Dates: repeatDates });
                    }}
                    isDefaultDateEditable={isEditMode}
                    changeSelectedDate={(date) => {
                      this.props.changeState({
                        dayNumber: isWeekStartWithMonday()
                          ? moment(date).isoWeekday()
                          : moment(date).day(),
                      });
                    }}
                    isDateError={this.onDateStatusChange}
                    isDisabled={state.isCheckInEntry}
                  />
                </Grid>

                <Grid item xs={12} id="input-hour-modal-comment">
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      label={i18next.t("Comments")}
                      type="text"
                      size="small"
                      placeholder={i18next.t("Type your comments here")}
                      value={state.comment}
                      style={{ width: "100%" }}
                      variant="outlined"
                      multiline
                      rows={2}
                      onChange={(e) => {
                        this.props.changeState({
                          comment: e.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                {this.state.selectDateError && (
                  <Grid item xs={12} className={classes.textCenter}>
                    <Alert severity="error" sx={{ pt: 0, pb: 0 }}>
                      {i18next.t("Please select check-in/out time.")}
                    </Alert>
                  </Grid>
                )}
                {(allowAddProject || state.isPreviewRulesOutcome) &&
                  !state.showPreviewRulesOutcome && (
                    <Grid item xs={12} id="input-hour-modal-add-project">
                      <Button
                        onClick={this.openProjectTaskModal}
                        color="primary"
                        variant="outlined"
                        disabled={this.state.disableAddProject}
                        className={classes.addButton}
                      >
                        {`+ ${i18next.t("Add task")}`}
                      </Button>
                    </Grid>
                  )}
                {isEditMode &&
                  isAdmin &&
                  !state.isCheckInEntry &&
                  !state.isManualEntriesOnEdit &&
                  state.showEditOutsideRulesOption && (
                    <Grid item xs={12} id="input-hour-modal-edit-outside-rules">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allowRulesToManualEntry}
                            onChange={this.handleEditOutsideRulesClick}
                          />
                        }
                        label={i18next.t("Edit outside of rules")}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontWeight: 600,
                          },
                        }}
                      />
                    </Grid>
                  )}
                {state.showPreviewRulesOutcome ? (
                  <Fragment>
                    <PreviewTabs
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      value={this.state.previewSelectedTab}
                      onChange={this.handleChangePreviewTab}
                      style={{ width: "100%", paddingTop: 16 }}
                    >
                      <Tab label={i18next.t("Hour per project")} />
                      <Tab label={i18next.t("Hour per type")} />
                    </PreviewTabs>
                    {this.state.previewSelectedTab === 0 ? (
                      <Typography
                        component="div"
                        className={classes.previewSliderBlock}
                        id="input-hour-modal-task-nodes-preview-block"
                      >
                        {(state.multiProjectTasksPreview?.length > 0 ||
                          state.multiProjectAddonTasksPreview?.length > 0 ||
                          state.fixedAddonTasksPreview?.length > 0 ||
                          state.previewError) && (
                          <HoursPerProject
                            multiProjectTasks={state.multiProjectTasksPreview}
                            multiProjectAddonTasks={
                              state.multiProjectAddonTasksPreview
                            }
                            fixedAddonTasks={state.fixedAddonTasksPreview}
                            previewError={state.previewError}
                          />
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        component="div"
                        className={classes.previewSliderBlock}
                      >
                        <HoursPerType
                          multiProjectTasks={state.multiProjectTasksPreview}
                          multiProjectAddonTasks={
                            state.multiProjectAddonTasksPreview
                          }
                          fixedAddonTasks={state.fixedAddonTasksPreview}
                          previewError={state.previewError}
                        />
                      </Typography>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {!state.isCheckInEntry && isTaskNodesAvailable && (
                      <PreviewTabs
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        value={this.state.editingSelectedTab}
                        onChange={this.handleChangeEditingTab}
                        style={{ width: "100%", paddingTop: 16 }}
                      >
                        <Tab label={i18next.t("Hours by task")} />
                        <Tab label={i18next.t("Hours by type")} />
                      </PreviewTabs>
                    )}
                    {this.state.editingSelectedTab === 0 ? (
                      <Typography
                        component="div"
                        className={classes.sliderBlock}
                        id="input-hour-modal-task-nodes-block"
                      >
                        {isTaskNodesAvailable && (
                          <MultiProjectTaskNodes
                            multiProjectTasks={state.multiProjectTasks}
                            multiProjectAddonTasks={
                              state.multiProjectAddonTasks
                            }
                            fixedAddonTasks={state.fixedAddonTasks}
                            startTime={state.startTime}
                            endTime={state.endTime}
                            lunchStartTime={state.lunchStartTime}
                            lunchEndTime={state.lunchEndTime}
                            changeState={this.props.changeState}
                            openProjectTaskEditModal={
                              this.openProjectTaskEditModal
                            }
                            isEditMode={isEditMode}
                            hourNodeRemoved={state.hourNodeRemoved}
                            addonNodeRemoved={state.addonNodeRemoved}
                            fixedAddonNodeRemoved={state.fixedAddonNodeRemoved}
                            isManualEntriesOnEdit={state.isManualEntriesOnEdit}
                            hoursByTypeNote={this.state.hoursByTypeNote}
                            isAllowEditWithGeofence={
                              state.isAllowEditWithGeofence
                            }
                            isValidatedGeofenceEntry={
                              state.isValidatedGeofenceEntry
                            }
                            isSickLeaveHours={state.isSickLeaveHours}
                            addonTypeOptions={state.addonTypeOptions}
                          />
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        component="div"
                        className={classes.sliderBlock}
                        id="input-hour-modal-type-nodes-block"
                      >
                        <MultiProjectHoursByType
                          multipleProjectAllHours={
                            state.multipleProjectAllHours
                          }
                          multipleProjectAllAddons={
                            state.multipleProjectAllAddons
                          }
                          startTime={state.GroupStartTime}
                          endTime={state.GroupEndTime}
                          lunchStartTime={state.GroupLunchStartTime}
                          lunchEndTime={state.GroupLunchEndTime}
                          hourTypeOptions={state.hourTypeOptions}
                          addonTypeOptions={state.addonTypeOptions}
                          isOptionalHourType={state.isOptionalHourType}
                          isOptionalAddonType={state.isOptionalAddonType}
                          changeState={this.props.changeState}
                          setOptionalTypeChangeFlag={
                            this.setOptionalTypeChangeFlag
                          }
                          hourModalDataChanged={state.hourModalDataChanged}
                          isQuickHoursSubmit={state.isQuickHoursSubmit}
                          onSubmitHourByTask={onSubmitHourByTask}
                        />
                      </Typography>
                    )}
                  </Fragment>
                )}
                {state.isCheckInEntry &&
                  (state.isMergeExistingHoursOnCheckout ||
                    state.isRemoveExistingHoursOnOverlap) &&
                  state.showCheckoutOverlapOption && (
                    <Grid item xs={12} id="input-hour-modal-overlap-check-out">
                      <Alert severity="warning" variant="outlined">
                        {state.isMergeExistingHoursOnCheckout
                          ? state.isRemoveExistingHoursOnOverlap
                            ? i18next.t("msg-overlap-checkout-merge-remove")
                            : i18next.t("msg-overlap-checkout-merge")
                          : i18next.t("msg-overlap-checkout-remove")}
                        <Box className={classes.overlapButtons}>
                          {state.isMergeExistingHoursOnCheckout && (
                            <Button
                              className={classes.btnOverlapSubmit}
                              disabled={this.checkInputsForSubmit()}
                              onClick={async () => {
                                await this.props.changeState({
                                  mergeOverlapHours: true,
                                  groupId:
                                    state.checkInData &&
                                    state.checkInData["batch_group_id"]
                                      ? state.checkInData["batch_group_id"]
                                      : null,
                                });

                                this.onSubmit();
                              }}
                            >
                              {i18next.t("Merge")}
                            </Button>
                          )}
                          {state.isRemoveExistingHoursOnOverlap && (
                            <Button
                              className={classes.btnOverlapSubmit}
                              disabled={this.checkInputsForSubmit()}
                              onClick={async () => {
                                await this.props.changeState({
                                  removeOverlapHours: true,
                                  groupId:
                                    state.checkInData &&
                                    state.checkInData["batch_group_id"]
                                      ? state.checkInData["batch_group_id"]
                                      : null,
                                });

                                this.onSubmit();
                              }}
                            >
                              {i18next.t("Remove")}
                            </Button>
                          )}
                        </Box>
                      </Alert>
                    </Grid>
                  )}
              </Grid>
            </form>
          </DialogContent>

          <DialogActions>
            <ActionsButtons />
          </DialogActions>
        </Dialog>
        <ConfirmModal
          isOpen={this.state.openConfirmModal}
          handleCancel={this.closeConfirmModal}
          handleSubmit={this.submitDiscardClick}
          message={i18next.t(
            "Are you sure you want to discard the current check-in?"
          )}
        />
      </>
    );
  }
}

export default withStyles(styles)(InputMultiProjectHourModal);
