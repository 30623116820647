import { setItem, getItem, removeItem } from "./localStorage";

const ACCESS_TOKEN_KEY = "access_token";
const ACCESS_TOKEN_TYPE = "access_type";
const TOKEN_EXPIRY_KEY = "token_expires_in";
const COMPANY_TOKEN_KEY = "company_token";

const saveAccessToken = (accessToken) => {
  setItem(ACCESS_TOKEN_KEY, accessToken);
};

/*
 * Persist auth cred
 * */
const saveSession = (authResult) => {
  setItem(ACCESS_TOKEN_KEY, authResult.access_token);
  setItem(ACCESS_TOKEN_TYPE, authResult.token_type);
  const expiresAt = JSON.stringify(
    authResult.expires_in * 1000 + new Date().getTime()
  );
  setItem(TOKEN_EXPIRY_KEY, expiresAt);
};

/*
 * Clear auth cred
 * */
const clearSession = () => {
  removeItem(ACCESS_TOKEN_KEY);
  removeItem(ACCESS_TOKEN_TYPE);
  removeItem(TOKEN_EXPIRY_KEY);
};

/*
 * IsTokenExpired
 * */
const isTokenExpired = () => {
  const expiresAt = getExpiresAt();
  const expired = new Date().getTime() > expiresAt;
  if (expired) {
    clearSession();
  }
  return expired;
};

/*
 * IsLoggedIn
 * */
const isLoggedIn = () => {
  const accessToken = getAccessToken();
  let result = !!accessToken && !isTokenExpired();
  return result;
};

const getExpiresAt = () => {
  return JSON.parse(getItem(TOKEN_EXPIRY_KEY));
};

const getAccessToken = () => {
  return getItem(ACCESS_TOKEN_KEY) ? getItem(ACCESS_TOKEN_KEY) : "";
};

const saveCompanyToken = (token) => {
  sessionStorage.setItem(COMPANY_TOKEN_KEY, token);
};

const getCompanyToken = () => {
  return sessionStorage.getItem(COMPANY_TOKEN_KEY)
    ? sessionStorage.getItem(COMPANY_TOKEN_KEY)
    : "";
};

export const actions = {
  saveAccessToken,
  saveSession,
  clearSession,
  isLoggedIn,
  isTokenExpired,
  getExpiresAt,
  getAccessToken,
  saveCompanyToken,
  getCompanyToken,
};
