import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  SvgIcon,
  Fab,
} from "@mui/material";
import Label from "@/common/Label";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import i18next from "i18next";
import moment from "moment";
import { getDateFormat } from "@/utils/dateTimeFunctions";
import Delete from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexWrap: "wrap",
    },
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    marginLeft: 8,
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      flexBasis: "100%",
    },
  },
  headerText: {
    width: 260,
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    display: "flex",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  stats: {
    padding: theme.spacing(1),
    width: 130,
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
    },
  },
  textColumn: {
    padding: theme.spacing(1),
    width: 180,
    [theme.breakpoints.down("md")]: {
      flexBasis: "50%",
    },
  },
  iconContainer: {
    borderRadius: "50%",
    padding: 5,
    fontSize: 34,
  },
  textColumnHeader: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  textColumnTitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  icon: {
    cursor: "pointer",
    fontSize: 22,
  },
  deleteIcon: {
    boxShadow: "none",
    color: theme.palette.text.primary,
    background: "none",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  statusIcon: {
    color: theme.palette.text.secondary,
    marginRight: 5,
  },
}));

function ListCard({
  checkList,
  history,
  isDetailsPage,
  allowDelete,
  onDeleteButtonClick,
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <CardContent
        className={classes.content}
        style={{ cursor: !isDetailsPage ? "pointer" : "unset" }}
        onClick={() => {
          if (!isDetailsPage) {
            history.push(`/hmsk/checkListDetail/${checkList.id}`);
          }
        }}
      >
        <div className={classes.header}>
          <Avatar alt="User" className={classes.avatar}>
            <SvgIcon className={classes.iconContainer}>
              <path
                d={
                  "M3,5H9V11H3V5M5,7V9H7V7H5M11,7H21V9H11V7M11,15H21V17H11V15M5,20L1.5,16.5L2.91,15.09L5,17.17L9.59,12.59L11,14L5,20Z"
                }
              />
            </SvgIcon>
          </Avatar>
          <div className={classes.stats}>
            <Typography
              noWrap
              variant="h5"
              className={classes.textColumnHeader}
            >
              {moment(checkList.created_at).format(getDateFormat())}
            </Typography>
            <Typography variant="body2" className={classes.textColumnTitle}>
              {i18next.t("Date checked")}
            </Typography>
          </div>
        </div>
        <div className={classes.textColumn}>
          <Typography variant="h6" noWrap className={classes.textColumnHeader}>
            {checkList.project_name}
          </Typography>
          <Typography variant="body2" className={classes.textColumnTitle}>
            {i18next.t("Project")}
          </Typography>
        </div>
        <div className={classes.textColumn}>
          <Typography variant="h6" noWrap className={classes.textColumnHeader}>
            {checkList.task_name
              ? checkList.task_name
              : i18next.t("Not available")}
          </Typography>
          <Typography variant="body2" className={classes.textColumnTitle}>
            {i18next.t("Task")}
          </Typography>
        </div>
        <div className={classes.textColumn}>
          <Typography variant="h6" noWrap className={classes.textColumnHeader}>
            {checkList.hmsk_template_name}
          </Typography>
          <Typography variant="body2" className={classes.textColumnTitle}>
            {i18next.t("Checklist")}
          </Typography>
        </div>
        <div className={classes.textColumn}>
          <Typography variant="h6" noWrap className={classes.textColumnHeader}>
            {checkList.created_user_name}
          </Typography>
          <Typography variant="body2" className={classes.textColumnTitle}>
            {i18next.t("Reported by")}
          </Typography>
        </div>
        <div className={classes.stats}>
          <Typography component="div" className={classes.statusContainer}>
            {checkList?.hmsk_template_collaboration ? (
              <GroupIcon className={classes.statusIcon} />
            ) : (
              <PersonIcon className={classes.statusIcon} />
            )}
            {checkList?.hmsk_template_collaboration &&
            checkList.status === "Ongoing" ? (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={"success"}
              >
                {i18next.t("Ongoing")}
              </Label>
            ) : (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={"default"}
              >
                {i18next.t("Completed")}
              </Label>
            )}
          </Typography>
        </div>
        {allowDelete && (
          <div>
            <Fab
              className={classes.deleteIcon}
              onClick={(e) => {
                e.preventDefault();
                onDeleteButtonClick(checkList.id);
              }}
            >
              <Delete className={classes.icon} />
            </Fab>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default ListCard;
