import React, { useState, useEffect } from "react";
import AddItemForm from "./components/AddItemForm";
import ItemList from "./components/ItemList";
import DeleteModal from "@/common/deleteModal";
import OverlayIndicator from "@/common/OverlayIndicator";
import {
  getTasks,
  getProjects,
  getItems,
  getItemTypes,
  addNewItem,
  updateItem,
  removeItem,
  getUserProfileData,
  getUsers,
} from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";
import { Redirect } from "react-router-dom";
import { actions as authActions } from "@/utils/auth";
import { CommonHeaderInList } from "@/common/CommonHeaderInList";
import i18next from "i18next";

import { getItem } from "@/utils/localStorage";

const Items = () => {
  const [itemList, setItemList] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [editId, setEditId] = useState(null);
  const [openItemForm, setOpenItemForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [defaultUserProject, setDefaultUserProject] = useState(null);
  const [defaultUserTask, setDefaultUserTask] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [formTitle, setFormTitle] = useState(i18next.t("New item"));

  const isAdmin = getItem("userRole") === "admin";

  useEffect(() => {
    async function getCurrentUserData() {
      const response = await getUserProfileData();
      if (response && response.data && response.data.id) {
        if (response.data.project_id) {
          setDefaultUserProject({
            label: response.data.project_name,
            value: response.data.project_id.toString(),
          });
          setSelectedProject(response.data.project_id);
        }
        if (response.data.task_id) {
          setDefaultUserTask({
            label: response.data.task_name,
            value: response.data.task_id.toString(),
          });
        }
      }
    }

    getItemList();
    getCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItemList = async () => {
    setIsLoading(true);
    try {
      let queryString = "limit=5";

      // For non-admin user pass this flag to display all item records related to this user
      if (!isAdmin) {
        queryString += `&only_user=true`;
      }

      const response = await getItems(queryString);
      if (response && response.data && response.data.entries) {
        setItemList(response.data.entries);
      }
      setIsLoading(false);
    } catch (error) {
      let { message } = getErrorObject(error);
      setIsLoading(false);
      console.log(message);
    }
  };

  const loadItemTypeList = async (searchVal, options) => {
    const offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `limit=${limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getItemTypes(queryString);
    const result = await getItemTypeOptions(response);
    return result;
  };

  const getItemTypeOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let data = [];
        for (let i = 0; i < response.data.entries.length; i++) {
          const element = response.data.entries[i];
          data.push({
            value: element.id.toString(),
            label: element.name,
            UOF: element.measurement_name,
          });
        }

        resolve({
          options: data,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const loadProjectList = async (searchVal, options) => {
    const offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `more_details=false&open=true&limit=${limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getProjects(queryString);
    const result = await getProjectListOptions(response);
    return result;
  };

  const getProjectListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let data = [];
        for (let i = 0; i < response.data.entries.length; i++) {
          const element = response.data.entries[i];
          data.push({
            value: element.id.toString(),
            label: element.name,
          });
        }

        resolve({
          options: data,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const loadTaskOptions = async (searchVal, options) => {
    const offset = options && options.length ? options.length : 0;
    const limit = 20;

    const projectId = selectedProject || null;

    let queryString = "";
    if (projectId) {
      queryString = `limit=${limit}&offset=${offset}&project_id=${projectId}&finish=false`;
    } else {
      queryString = `limit=${limit}&offset=${offset}&finish=false`;
    }

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getTasks(queryString);
    const result = await getTaskOptions(response);
    return result;
  };

  const getTaskOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;

        let data = [];
        for (let i = 0; i < entries.length; i++) {
          const element = entries[i];
          const node = {
            value: element.id.toString(),
            label: element.name,
          };

          data.push(node);
        }

        resolve({
          options: data,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const loadUserList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `more_details=false&limit=${limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getUsers(queryString);
    const result = await getUserListOptions(response);
    return result;
  };

  const getUserListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let userList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];
          userList.push({
            value: element.id.toString(),
            label: element.name,
          });
        }

        resolve({
          options: userList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const onSelectProject = (project) => {
    setSelectedProject(project);
  };

  const handleFormCancel = () => {
    setSelectedProject(null);
    setEditId(null);
    setCurrentItem(null);
    setOpenItemForm(false);
    setFormTitle(i18next.t("New item"));
  };

  const handleEditClick = (id) => {
    const currentItem = itemList.find((item) => {
      return item.id === id;
    });

    if (currentItem) {
      setEditId(id);
      setCurrentItem(currentItem);
      setSelectedProject(currentItem.project_id);
      setOpenItemForm(true);
      setFormTitle(i18next.t("Update item"));
    }
  };

  const handleFormSubmit = async (params) => {
    try {
      let response = null;
      if (editId) {
        response = await updateItem(editId, params);
      } else {
        response = await addNewItem(params);
      }
      if (response && response.data) {
        if (response.data.message) {
          setSuccessMsg(response.data.message);
        }
      }
      getItemList();
    } catch (error) {
      let { message } = getErrorObject(error);
      setErrorMsg(message);
    }

    // clear message after 10 sec
    setTimeout(() => {
      setErrorMsg("");
      setSuccessMsg("");
    }, 20000);
  };

  const deleteModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const onDeleteCancel = () => {
    setOpenDeleteModal(false);
  };

  const onDeleteSubmit = async () => {
    setIsLoading(true);
    setOpenDeleteModal(false);
    setOpenItemForm(false);
    try {
      const response = await removeItem(editId);
      if (response && response.data) {
        setEditId(null);
        setIsLoading(false);
        if (response.data.message) {
          setSuccessMsg(response.data.message);
        }
      }
      getItemList();
    } catch (error) {
      let { message } = getErrorObject(error);
      setIsLoading(false);
      setErrorMsg(message);
    }
  };

  const openAddItemForm = () => {
    if (defaultUserProject && defaultUserProject.value) {
      setSelectedProject(defaultUserProject.value);
    }

    setOpenItemForm(true);
  };

  return !authActions.getAccessToken() ? (
    <Redirect to={`/token/${authActions.getCompanyToken()}`} />
  ) : (
    <>
      <CommonHeaderInList
        title={i18next.t("Items")}
        onButtonClick={(e) => {
          e.preventDefault();
          openAddItemForm();
        }}
        buttonTitle={i18next.t("New item")}
        removeFilter={true}
      />

      <div>
        <ItemList
          itemList={itemList}
          handleEditClick={handleEditClick}
          errorMsg={errorMsg}
          successMsg={successMsg}
        />
        <AddItemForm
          isOpen={openItemForm}
          loadProjectList={loadProjectList}
          loadTaskOptions={loadTaskOptions}
          loadUserList={loadUserList}
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleFormCancel}
          onSelectProject={onSelectProject}
          loadItemTypeList={loadItemTypeList}
          defaultUserProject={defaultUserProject}
          defaultUserTask={defaultUserTask}
          currentItem={currentItem}
          editId={editId}
          onDelete={deleteModalOpen}
          isAdmin={isAdmin}
          title={formTitle}
        />
        <DeleteModal
          isOpen={openDeleteModal}
          handleCancel={onDeleteCancel}
          handleSubmit={onDeleteSubmit}
        />
        <OverlayIndicator isLoading={isLoading} />
      </div>
    </>
  );
};

export default Items;
