import React, { Fragment } from "react";
import moment from "moment";
import i18next from "i18next";
import { Typography, Button, AppBar, Toolbar, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import WeekRangeDatePicker from "@/common/WeekRangeDatePicker";
import useToggleMenubar from "@/hooks/useToggleMenubar";

const styles = (theme) => ({
  appbar: {
    zIndex: 900,
    position: "sticky",
    top: -5,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
  },
  toolbar: {
    position: "sticky",
    top: -5,
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 3),
    height: 64,
    [theme.breakpoints.up("md")]: {
      height: 92,
      padding: theme.spacing(0, 3),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
    marginRight: 16,
  },
  btnGroup: {
    display: "flex",
    justifyContent: "center",
    verticalAlign: "middle",
    height: 48,
    width: "100%",
  },
  dateRangeContainer: {
    marginLeft: 15,
    marginRight: 15,
  },
  btnToday: {
    display: "flex",
    flexWrap: "nowrap",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 30px",
    margin: "0px 8px 0px 16px",
    borderRadius: 8,
  },
  btnIcon: {
    minWidth: 30,
    borderRadius: 8,
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconSize: {
    fontSize: "2rem",
    color: theme.palette.text.primary,
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
    margin: 2,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dateRangePicker: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      minWidth: 140,
      "&:hover": {
        background: "none",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  toggleButton: {
    color: theme.palette.text.primary,
  },
});

const MobileMenuButton = withStyles(styles)((props) => {
  const { onToggleMenubar } = useToggleMenubar();
  const { classes } = props;

  return (
    <IconButton
      onClick={() => {
        onToggleMenubar();
      }}
      id="menu-toggle-button"
      size="large"
      sx={{
        marginRight: "8px",
      }}
      className={classes.toggleButton}
    >
      <MenuIcon />
    </IconButton>
  );
});

class HourFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCards: true,
      showDrawer: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate = () => {};

  todayHrs = () => {
    this.props.filterActions.changeFilter({
      startDate: moment().clone().startOf("week"),
      endDate: moment().clone().endOf("week"),
    });
  };

  onDateRangeChange = (dates) => {
    let { startDate, endDate } = this.props.filters;

    // reset time to 00:00:00 to compare with date picker dates
    startDate = moment(startDate).startOf("day");
    endDate = moment(endDate).startOf("day");

    // get getHoursList only if date selection changed
    if (
      !moment(startDate).isSame(dates[0]) &&
      dates[1] &&
      !moment(endDate).isSame(dates[1])
    ) {
      this.props.filterActions.changeFilter({
        startDate: dates[0],
        endDate: dates[1] ? dates[1] : dates[0],
      });
    }
  };

  selectNextWeek = async () => {
    const { endDate } = this.props.filters;

    this.props.filterActions.changeFilter({
      startDate: moment(endDate).clone().add(1, "days"),
      endDate: moment(endDate).clone().add(1, "weeks"),
    });
  };

  selectPreviousWeek = async () => {
    const { startDate } = this.props.filters;

    this.props.filterActions.changeFilter({
      startDate: moment(startDate).clone().subtract("1", "weeks"),
      endDate: moment(startDate).clone().subtract("1", "days"),
    });
  };

  render() {
    const { classes } = this.props;
    const { startDate, endDate } = this.props.filters;

    return (
      <Fragment>
        <AppBar elevation={0} className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <MobileMenuButton toggleCollapse={this.props.toggleCollapse} />

            <Typography variant="h4" className={classes.title}>
              {i18next.t("Planned hours")}
            </Typography>

            <div className={classes.flexGrow} />

            <Typography component="div" className={classes.dateRangeContainer}>
              <Typography component="div" role="toolbar">
                <Typography component="div" className={classes.btnGroup}>
                  <Button
                    className={classes.btnIcon}
                    onClick={this.selectPreviousWeek}
                  >
                    <ChevronLeftIcon className={classes.iconSize} />
                  </Button>
                  <WeekRangeDatePicker
                    value={[startDate, endDate]}
                    onChangeDate={(dates) => {
                      if (dates && dates.length) {
                        this.onDateRangeChange(dates);
                      }
                    }}
                  />
                  <Button
                    className={classes.btnIcon}
                    onClick={this.selectNextWeek}
                  >
                    <ChevronRightIcon className={classes.iconSize} />
                  </Button>
                  <Button
                    className={classes.btnToday}
                    variant="contained"
                    onClick={this.todayHrs}
                  >
                    {i18next.t("Today")}
                  </Button>
                </Typography>
              </Typography>
            </Typography>
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(HourFilters);
