import * as types from "./types";

export const filterData = (page, params) => {
  return async (dispatch) => {
    dispatch({
      type: types.FILTER_DATA,
      payload: {
        page,
        params,
      },
    });
  };
};
