import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import MaterialTextfield from "@/common/MaterialTextfield";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import { MaterialDatePicker, MaterialDateTimePicker } from "@/common/Pickers";
import { makeStyles } from "@mui/styles";
import { getDateFormat, getDateAndTimeFormat } from "@/utils/dateTimeFunctions";
import { getUsers, getItemTypes } from "@/services/employee";
import i18next from "i18next";
import moment from "moment";

const useStyles = makeStyles(() => ({
  root: {},
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  radioLabel: {
    marginBottom: 0,
  },
}));

const UserListField = (props) => {
  const classes = useStyles();
  const { answer, index, onValueChange, disabled } = props;

  const loadUserList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `more_details=false&limit=${limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getUsers(queryString);
    const result = await getUserListOptions(response);
    return result;
  };

  const getUserListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let userList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];
          userList.push({
            value: element.id.toString(),
            label: element.name,
          });
        }

        resolve({
          options: userList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  return (
    <MaterialAsyncPaginate
      classes={classes}
      textFieldProps={{
        label: i18next.t("User"),
        InputLabelProps: {
          shrink: true,
        },
      }}
      isMulti={true}
      loadOptions={loadUserList}
      value={answer && answer["answer_value"] ? answer["answer_value"] : null}
      onChange={(selected) => {
        onValueChange(index, "answers", "answer_value", selected);
      }}
      isDisabled={disabled}
    />
  );
};

const ItemTypeListField = (props) => {
  const classes = useStyles();
  const { answer, index, onValueChange, disabled } = props;

  const loadItemTypeList = async (searchVal, options) => {
    const offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `limit=${limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getItemTypes(queryString);
    const result = await getItemTypeOptions(response);
    return result;
  };

  const getItemTypeOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let data = [];
        for (let i = 0; i < response.data.entries.length; i++) {
          const element = response.data.entries[i];
          data.push({
            value: element.id.toString(),
            label: element.name,
          });
        }

        resolve({
          options: data,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  return (
    <MaterialAsyncPaginate
      classes={classes}
      textFieldProps={{
        label: i18next.t("Item type"),
        InputLabelProps: {
          shrink: true,
        },
      }}
      isMulti={true}
      loadOptions={loadItemTypeList}
      value={answer && answer["answer_value"] ? answer["answer_value"] : null}
      onChange={(selected) => {
        onValueChange(index, "answers", "answer_value", selected);
      }}
      isDisabled={disabled}
    />
  );
};

const DateTimeField = (props) => {
  const { answer, index, onValueChange, disabled } = props;

  return (
    <MaterialDateTimePicker
      label={i18next.t("Date and time")}
      value={answer && answer["answer_value"] ? answer["answer_value"] : null}
      format={getDateAndTimeFormat()}
      onChange={(date) => {
        if (date === null || moment(date).isValid()) {
          onValueChange(index, "answers", "answer_value", date);
        }
      }}
      disabled={disabled}
    />
  );
};

const DateField = (props) => {
  const { answer, index, onValueChange, disabled } = props;

  return (
    <MaterialDatePicker
      label={i18next.t("Date")}
      value={answer && answer["answer_value"] ? answer["answer_value"] : null}
      format={getDateFormat()}
      onChangeDate={(date) => {
        onValueChange(index, "answers", "answer_value", date);
      }}
      clearable={true}
      disabled={disabled}
    />
  );
};

const TextField = (props) => {
  const { answer, index, onValueChange, disabled } = props;

  return (
    <MaterialTextfield
      disabled={disabled}
      label={i18next.t("Answer")}
      type="text"
      adornment="no_endAdornment"
      value={answer && answer["answer_value"] ? answer["answer_value"] : ""}
      onChange={(event) => {
        onValueChange(index, "answers", "answer_value", event.target.value);
      }}
      multiline={true}
      minRows={2}
      maxRows={8}
      maxLength={10000}
    />
  );
};

const AmountField = (props) => {
  const { answer, index, onValueChange, disabled } = props;

  return (
    <MaterialTextfield
      disabled={disabled}
      label={i18next.t("Amount")}
      type="number"
      min="0"
      value={answer && answer["answer_value"] ? answer["answer_value"] : ""}
      onChange={(event) => {
        onValueChange(index, "answers", "answer_value", event.target.value);
      }}
    />
  );
};

const YesNoNa = (props) => {
  const { answer, index, onValueChange, disabled } = props;
  const classes = useStyles();
  const radioStatus = answer && parseInt(answer["answer_value"], 10);

  return (
    <RadioGroup
      aria-label="position"
      name="position"
      value={answer && answer["answer_value"] ? answer["answer_value"] : ""}
      onChange={(event) => {
        onValueChange(index, "answers", "answer_value", event.target.value);
      }}
      row
    >
      <FormControlLabel
        value="0"
        control={
          <Radio
            disabled={disabled}
            checked={radioStatus === 0 ? true : false}
            color="primary"
          />
        }
        label={i18next.t("Yes")}
        labelPlacement="end"
        className={classes.radioLabel}
      />
      <FormControlLabel
        value="1"
        control={
          <Radio
            disabled={disabled}
            checked={radioStatus === 1 ? true : false}
            color="primary"
          />
        }
        label={i18next.t("No")}
        labelPlacement="end"
        className={classes.radioLabel}
      />
      <FormControlLabel
        value="2"
        control={
          <Radio
            disabled={disabled}
            checked={radioStatus === 2 ? true : false}
            color="primary"
          />
        }
        label={i18next.t("N/A")}
        labelPlacement="end"
        className={classes.radioLabel}
      />
    </RadioGroup>
  );
};

function ResponseComponents(props) {
  const { component, answer, disabled } = props;

  const responseType =
    disabled && answer?.answer_type ? answer.answer_type : component;

  switch (responseType) {
    case "yes_no_na":
      return <YesNoNa {...props} />;
    case "textfield":
      return <TextField {...props} />;
    case "amount":
      return <AmountField {...props} />;
    case "date":
      return <DateField {...props} />;
    case "date_time":
      return <DateTimeField {...props} />;
    case "users":
      return <UserListField {...props} />;
    case "item_types":
      return <ItemTypeListField {...props} />;
    default:
      return <YesNoNa {...props} />;
  }
}

export default ResponseComponents;
