import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Dialog,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";

import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  selectInput: {
    display: "flex",
    padding: "0px 0px 0px 10px",
    height: "50px",
    fontSize: "16px",
  },
  /*selectPaper: {
    position: "relative",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  selectMenuItem: {
    fontSize: "16px",
  },
  button: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnCancel: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}));

const ProjectTaskModal = (props) => {
  const { open, onCloseDialog, loadProject, loadTaskList, onSubmit } = props;

  const classes = useStyles();

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleCancel = () => {
    onCloseDialog();
  };

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleCancel}>
      <Card className={classes.root}>
        <form autoComplete="off" noValidate>
          <CardHeader
            title={i18next.t("Select project and respective task")}
            action={
              <IconButton
                aria-label={i18next.t("Close")}
                className={classes.closeButton}
                onClick={handleCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MaterialAsyncPaginate
                  classes={classes}
                  textFieldProps={{
                    label: i18next.t("Project"),
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  name="project"
                  defaultOptions={false}
                  loadOptions={async (search, loadedOptions) => {
                    return loadProject(search, loadedOptions);
                  }}
                  value={selectedProject}
                  onChange={async (option) => {
                    setSelectedProject(option);
                    setSelectedTask(null);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialAsyncPaginate
                  classes={classes}
                  textFieldProps={{
                    label: i18next.t("Task"),
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  name="task"
                  loadOptions={async (search, loadedOptions) => {
                    return loadTaskList(search, loadedOptions, selectedProject);
                  }}
                  value={selectedTask}
                  onChange={async (option) => {
                    setSelectedTask(option);
                  }}
                  cacheUniqs={[selectedProject]}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button className={classes.btnCancel} onClick={handleCancel}>
              {i18next.t("Cancel")}
            </Button>{" "}
            <Button
              color="primary"
              variant="contained"
              disabled={!selectedTask}
              onClick={async (event) => {
                event.preventDefault();
                if (selectedTask) {
                  onSubmit(selectedProject, selectedTask);
                }
              }}
            >
              {i18next.t("Submit")}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Dialog>
  );
};

export default ProjectTaskModal;
