import React from "react";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    padding: "38px 32px",
    fontSize: "1.2375rem",
    minWidth: 210,
  },
}));

const PageButton = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        //startIcon={props.icon ? props.icon : null}
        onClick={props.onClick}
      >
        {props.title}
      </Button>
    </div>
  );
};

export default PageButton;
