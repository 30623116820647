import React, { Fragment } from "react";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import { Typography, Button, SvgIcon } from "@mui/material";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import QuestionAnswerBlock from "./questionAnswerBlock";
import moment from "moment";

import { getItem } from "@/utils/localStorage";

const styles = (theme) => ({
  /*selectInput: {
    display: "flex",
    padding: 0,
    minHeight: "40px",
    height: "auto",
  },*/
  /*selectPaper: {
    position: "absolute",
    marginTop: theme.spacing(1),
    zIndex: 1001,
    top: 50,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  mainContainer: {},
  emptyMainContainer: {
    textAlign: "center",
    padding: 50,
  },
  iconContainer: {
    fontSize: 100,
  },
  labelContainer: {
    color: theme.palette.text.primary,
    fontSize: 20,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "20px",
  },
  listContainer: { height: "calc(100vh - 200px)", overflow: "auto" },
  saveButton: {
    marginLeft: "10px",
    textTransform: "uppercase",
    fontWeight: "bold",
    cursor: "pointer",
  },
});

class QuestionAnswerList extends React.Component {
  constructor(props) {
    super(props);

    const userId = getItem("userId") ? Number(getItem("userId")) : null;

    this.state = {
      project: null,
      answers: [],
      isLoadProjectResult: false,
      task: null,
      checkTaskList: false,
      checkTaskOptions: false,
      allowCollaboration: false,
      limit: 20,
      userId: userId,
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.state.selctedCheckList !== this.props.state.selctedCheckList
    ) {
      this.props.actions.getHmskTemplate(this.props.state.selctedCheckList);
    }

    if (
      this.props.hmskTemplateReducer &&
      this.props.hmskTemplateReducer.hmskTemplateData &&
      prevProps.hmskTemplateReducer.hmskTemplateData !==
        this.props.hmskTemplateReducer.hmskTemplateData &&
      this.props.hmskTemplateReducer.hmskTemplateData.questions &&
      this.props.hmskTemplateReducer.hmskTemplateData.questions.length > 0
    ) {
      let questionLength =
        this.props.hmskTemplateReducer.hmskTemplateData.questions;

      let data = questionLength.map(() => {
        return {};
      });

      this.setState({
        answers: Object.assign([], data),
        allowCollaboration:
          this.props.hmskTemplateReducer.hmskTemplateData?.collaboration,
        project: null,
        task: null,
      });
    }

    if (
      this.state.checkTaskList &&
      this.props.taskReducer &&
      this.props.taskReducer.taskList &&
      prevProps.taskReducer.taskList !== this.props.taskReducer.taskList
    ) {
      this.setState({
        checkTaskList: false,
        checkTaskOptions: true,
      });
    }

    if (
      this.props.projectReducer.projectList &&
      this.props.projectReducer.projectList !==
        prevProps.projectReducer.projectList &&
      this.props.projectReducer.projectList.entries
    ) {
      this.setState({ isLoadProjectResult: true });
    }
  };

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  onValueChange = (index, array, key, value) => {
    this.setState((prevState) => {
      let data = prevState[array];
      if (key && value) {
        data[index][key] = value;
        data[index]["updated_by"] = this.state.userId;
        data[index]["date_time"] =
          moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      } else {
        data[index] = {};
      }
      return {
        [array]: data,
      };
    });
  };

  loadProjectOptions = async (loadedOptions, search) => {
    const offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;
    await this.getProjects(this.state.limit, offset, search);
    const result = await this.getProjectListOptions();
    return result;
  };

  getProjects = async (newLimit, newOffset, newSearch) => {
    let queryString = `more_details=false&limit=${newLimit}&offset=${
      newOffset ? newOffset : 0
    }`;

    if (newSearch && newSearch !== "") {
      queryString += `&name=${newSearch}`;
    }

    this.setState({ isLoadProjectResult: false });
    await this.props.actions.getProjects(queryString);
  };

  getProjectListOptions = () => {
    const promise = new Promise((resolve) => {
      let counter = 0;
      const projTimer = setInterval(async () => {
        counter++;
        if (counter > 10) {
          this.setState({
            isLoadProjectResult: true,
          });
        }

        if (this.state.isLoadProjectResult) {
          clearInterval(projTimer);

          if (
            this.props.projectReducer.projectList &&
            this.props.projectReducer.projectList.entries
          ) {
            let data = [];
            for (
              let i = 0;
              i < this.props.projectReducer.projectList.entries.length;
              i++
            ) {
              const element = this.props.projectReducer.projectList.entries[i];
              data.push({
                value: element.id.toString(),
                label: element.name,
              });
            }

            resolve({
              options: data,
              hasMore:
                this.props.projectReducer.projectList.pagination.has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 500);
    });
    return promise;
  };

  loadTaskOptions = async (loadedOptions, search) => {
    let offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;

    const result = await this.getTaskOptions(this.state.limit, offset, search);
    return result;
  };

  getTasks = (newLimit, newOffset, newSearch) => {
    if (this.state.project) {
      this.props.actions.getTasks({
        project_id: this.state.project.value,
        offset: newOffset,
        limit: newLimit,
        search: newSearch.length > 0 ? newSearch : "",
        finish: "false",
        hour_summary: false,
      });
    }
  };

  getTaskOptions = (limit, offset, search) => {
    const promise = new Promise((resolve) => {
      this.getTasks(limit, offset, search);
      this.setState({
        checkTaskList: true,
      });
      let counter = 0;

      const timerId = setInterval(() => {
        counter++;
        if (counter > 10) {
          this.setState({
            checkTaskList: false,
            checkTaskOptions: true,
          });
        }

        if (this.state.checkTaskOptions) {
          this.setState({
            checkTaskOptions: false,
          });

          let entries = [];
          let paginationObj = null;

          if (this.state.project) {
            const projectId = this.state.project.value;
            if (
              this.props.taskReducer.taskList[projectId] &&
              this.props.taskReducer.taskList[projectId].entries
            ) {
              entries = this.props.taskReducer.taskList[projectId].entries;
              paginationObj =
                this.props.taskReducer.taskList[projectId].pagination;
            }
          }

          if (entries && entries.length > 0) {
            let data = [];
            for (let i = 0; i < entries.length; i++) {
              const element = entries[i];
              const node = {
                value: element.id.toString(),
                label: element.name,
              };

              data.push(node);
            }

            clearInterval(timerId);

            resolve({
              options: data,
              hasMore: paginationObj.has_more_items,
            });
          } else {
            clearInterval(timerId);

            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 800);
    });

    return promise;
  };

  handleSaveButtonClick = async () => {
    const { hmskTemplateReducer } = this.props;
    const questions =
      hmskTemplateReducer.hmskTemplateData &&
      hmskTemplateReducer.hmskTemplateData.questions;

    const answers = this.state.answers.map((answerData, index) => {
      let images = [];
      const answerObj = { ...answerData };

      if (answerObj && answerObj.images && answerObj.images.length > 0) {
        images = answerObj.images.map((imageData) => {
          return imageData.image_id;
        });
      }
      answerObj["images"] = images;

      answerObj["answer_type"] = questions[index].answer_type;
      answerObj["template_question"] = questions[index].question;

      if (questions[index] && questions[index].answer_type === "users") {
        if (answerObj["answer_value"]) {
          const selectedUsers = answerObj["answer_value"];
          const user_ids = [];
          if (selectedUsers && selectedUsers.length) {
            for (let i = 0; i < selectedUsers.length; i++) {
              user_ids.push(selectedUsers[i].value);
            }
          }
          answerObj["answer_value"] = user_ids.join(",");
        } else {
          answerObj["answer_value"] = null;
        }
      } else if (
        questions[index] &&
        questions[index].answer_type === "item_types"
      ) {
        if (answerObj["answer_value"]) {
          const selectedItemTypes = answerObj["answer_value"];
          const item_type_ids = [];
          if (selectedItemTypes && selectedItemTypes.length) {
            for (let i = 0; i < selectedItemTypes.length; i++) {
              item_type_ids.push(selectedItemTypes[i].value);
            }
          }
          answerObj["answer_value"] = item_type_ids.join(",");
        } else {
          answerObj["answer_value"] = null;
        }
      } else if (questions[index] && questions[index].answer_type === "date") {
        answerObj["answer_value"] = answerObj["answer_value"]
          ? moment(answerObj["answer_value"]).format("YYYY-MM-DD")
          : null;
      } else if (
        questions[index] &&
        questions[index].answer_type === "date_time"
      ) {
        answerObj["answer_value"] = answerObj["answer_value"]
          ? moment(answerObj["answer_value"])
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z"
          : null;
      } else {
        answerObj["answer_value"] = answerObj["answer_value"]
          ? answerObj["answer_value"]
          : "";
      }

      return answerObj;
    });

    const data = {
      hmsk_template_id:
        hmskTemplateReducer.hmskTemplateData &&
        hmskTemplateReducer.hmskTemplateData.id,
      answers: answers,
      company_id:
        hmskTemplateReducer.hmskTemplateData &&
        hmskTemplateReducer.hmskTemplateData.company_id,
      project_id: this.state.project && this.state.project.value,
      task_id: this.state.task && this.state.task.value,
    };
    await this.props.addCheckList(data);
  };

  render = () => {
    const { classes, state, hmskTemplateReducer, ...others } = this.props;

    let disabled = false;
    if (!this.state.project || !this.state.project.value) {
      disabled = true;
    }

    if (this.state.allowCollaboration) {
      if (!disabled) {
        let anyFilledQuestion = false;
        for (let i = 0; i < this.state.answers.length; i++) {
          const answerData = this.state.answers[i];
          if (
            Object.prototype.hasOwnProperty.call(answerData, "answer_value")
          ) {
            anyFilledQuestion = true;
            break;
          }
        }

        if (!anyFilledQuestion) {
          disabled = true;
        }
      }
    } else {
      for (let i = 0; i < this.state.answers.length; i++) {
        const answerData = this.state.answers[i];
        if (!Object.prototype.hasOwnProperty.call(answerData, "answer_value")) {
          disabled = true;
          break;
        }
      }
    }

    return (
      <Fragment>
        {!state.selctedCheckList ? (
          <Typography component="div" className={classes.emptyMainContainer}>
            <Typography component="div">
              <SvgIcon className={classes.iconContainer}>
                <path
                  fill="#BFBFBF"
                  d="M3,5H9V11H3V5M5,7V9H7V7H5M11,7H21V9H11V7M11,15H21V17H11V15M5,20L1.5,16.5L2.91,15.09L5,17.17L9.59,12.59L11,14L5,20Z"
                />
              </SvgIcon>
            </Typography>
            <Typography component="div" className={classes.labelContainer}>
              {i18next.t("Select a checklist")}
            </Typography>
          </Typography>
        ) : (
          <Typography component="div" className={classes.mainContainer}>
            <Typography
              component="div"
              style={{
                margin: "5px 2px",
                display: "flex",
                justifyContent: "space-between",
                maxWidth: 600,
              }}
            >
              <Typography component="span" className={classes.title}>
                {hmskTemplateReducer.hmskTemplateData &&
                  hmskTemplateReducer.hmskTemplateData.name}
              </Typography>
              <Typography component="div">
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  disabled={disabled}
                  onClick={this.handleSaveButtonClick}
                >
                  {i18next.t("Save")}
                </Button>
              </Typography>
            </Typography>
            <Typography
              component="div"
              style={{ maxWidth: 600, margin: "12px 2px" }}
            >
              <MaterialAsyncPaginate
                defaultOptions
                classes={classes}
                textFieldProps={{
                  label: i18next.t("Project"),
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                loadOptions={async (search, loadedOptions) => {
                  return this.loadProjectOptions(loadedOptions, search);
                }}
                value={this.state.project}
                onChange={async (project) => {
                  this.setState({ project, task: null });
                }}
              />
            </Typography>
            <Typography
              component="div"
              style={{ maxWidth: 600, margin: "12px 2px" }}
            >
              <MaterialAsyncPaginate
                defaultOptions
                classes={classes}
                textFieldProps={{
                  label: i18next.t("Task"),
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                isDisabled={!this.state.project}
                loadOptions={async (search, loadedOptions) => {
                  return this.loadTaskOptions(loadedOptions, search);
                }}
                value={this.state.task}
                onChange={async (selected) => {
                  this.setState({ task: selected });
                }}
                cacheUniqs={[this.state.project]}
              />
            </Typography>
            <Typography component="div" className={classes.listContainer}>
              {hmskTemplateReducer &&
                hmskTemplateReducer.hmskTemplateData &&
                hmskTemplateReducer.hmskTemplateData.questions &&
                hmskTemplateReducer.hmskTemplateData.questions.length > 0 &&
                hmskTemplateReducer.hmskTemplateData.questions.map(
                  (question, index) => {
                    return (
                      <Typography key={index} component="div">
                        <QuestionAnswerBlock
                          disabled={false}
                          changeState={this.changeState}
                          index={index}
                          question={question}
                          answers={this.state.answers}
                          onValueChange={this.onValueChange}
                          {...others}
                        />
                      </Typography>
                    );
                  }
                )}
            </Typography>
          </Typography>
        )}
      </Fragment>
    );
  };
}

export default withStyles(styles)(QuestionAnswerList);
