import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import {
  CardHeader,
  Divider,
  Grid,
  Paper,
  Button,
  Typography,
  SvgIcon,
  Stack,
  Alert,
  IconButton,
  Popover,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded";
import TextTruncate from "react-text-truncate";
import CheckIcon from "@mui/icons-material/Check";
import { People } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import handleFileDownload from "@/utils/handleFileDownload";
import { getPlannedHours } from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";

import moment from "moment";
import i18next from "i18next";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    paddingTop: 24,
    paddingBottom: 12,
  },
  planContainer: {
    padding: "3px 0px 3px 0px",
  },
  planCard: {
    width: "100%",
    marginBottom: 12,
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.customShadows.card,
    border: `0.5px solid ${theme.palette.background.neutral}`,
    [theme.breakpoints.up("sm")]: {
      marginRight: 16,
    },
  },
  projectName: {
    width: "100%",
    // maxWidth: 260,
    fontSize: 14,
    fontWeight: 700,
    marginLeft: 12,
    color: theme.palette.text.primary,
  },
  taskName: {
    // maxWidth: 180,
    width: "100%",
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 12,
    color: theme.palette.text.primary,
  },
  taskTime: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 12,
    display: "flex",
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
  },
  parentBorder: {
    display: "flex",
  },
  borderLine: {
    width: 8,
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  taskListClass: {
    marginInline: 24,
    display: "block",
    width: "100%",
  },
  checkInButtonContainer: {
    width: "100%",
    // display: "flex",
    // justifyContent: "flex-end",
    marginBottom: 12,
  },
  button: {
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 16px",
    borderRadius: "0.5rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
    width: "100%",
  },
  projectnameLine: {
    display: "flex",
    marginTop: 12,
  },
  taskNameLine: {
    // width: "100%",
    display: "flex",
    marginTop: 12,
    justifyContent: "space-between",
  },
  attachedFileLine: {
    display: "flex",
    marginTop: 12,
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  textTruncate: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  alertBox: {
    display: "flex",
    flexGrow: 1,
    paddingBottom: 16,
  },
  poperBody: {
    padding: "8px 20px 16px 20px",
    maxHeight: 220,
    minWidth: 150,
    overflowY: "auto",
  },
  poperHeader: {
    fontSize: 14,
    fontWeight: 500,
    padding: "5px 0px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  poperItem: {
    fontSize: 14,
    fontWeight: 400,
    padding: "8px 0px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  closePopoverButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: 2,
  },
  closePopoverIcon: {
    fontSize: 18,
  },
  poperMsg: {
    padding: 10,
  },
}));

const SelectPlannedHour = (props) => {
  const {
    onPlanCheckin,
    allowCheckIn,
    isItemCertificationRequiered,
    isDependencyRequiered,
    getPlanUsers,
    specificPlanUsersLoading,
    specificPlanUsersList,
    state,
  } = props;

  const { isAllowToSeeOtherUsersPlans } = state;

  const classes = useStyles();

  const [planList, setPlanList] = useState([]);
  const [userListAnchorEl, setUserListAnchorEl] = useState(null);

  const getCurrentPlans = async (queryString) => {
    try {
      const response = await getPlannedHours(queryString);
      if (response.data && response.data.entries) {
        if (response.data.entries.length > 0) {
          setPlanList(response.data.entries);
        }
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      console.log(message);
    }
  };

  useEffect(() => {
    const userId = getItem("userId") ? getItem("userId") : null;
    const currentDate = moment().format("YYYY-MM-DD");
    const queryString = `status=0&user_ids=${userId}&start_time=${currentDate}&end_time=${currentDate}`;

    getCurrentPlans(queryString);
  }, []);

  const userList = [];
  if (specificPlanUsersList.entries && specificPlanUsersList.entries.length) {
    for (let i = 0; i < specificPlanUsersList.entries.length; i++) {
      if (
        parseInt(getItem("userId"), 10) !==
          specificPlanUsersList.entries[i].user_id &&
        !userList.includes(specificPlanUsersList.entries[i].user_name)
      ) {
        userList.push(specificPlanUsersList.entries[i].user_name);
      }
    }
  }

  return (
    <>
      <CardHeader
        className={classes.root}
        //subheader={i18next.t("Select from the list of Planned hours")}
        title={i18next.t("Today's plans")}
        titleTypographyProps={{
          fontSize: 20,
        }}
      />

      <Grid container>
        <Grid item xs={12} className={classes.planContainer}>
          {planList &&
            planList.map((plan, index) => {
              return (
                <Paper className={classes.planCard} key={index}>
                  <div className={classes.parentBorder}>
                    <div
                      className={classes.borderLine}
                      style={{
                        backgroundColor: plan.project_color
                          ? `${plan.project_color}`
                          : null,
                      }}
                    ></div>
                    <div className={classes.taskListClass}>
                      <div className={classes.projectnameLine}>
                        <SvgIcon>
                          <FolderIcon
                            className={classes.icon}
                            style={{
                              color: plan.project_color
                                ? `${plan.project_color}`
                                : null,
                            }}
                          />
                        </SvgIcon>
                        <Typography
                          component="div"
                          className={classes.projectName}
                        >
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={plan.project_name}
                          />
                        </Typography>
                      </div>
                      <div className={classes.taskNameLine}>
                        <div style={{ display: "flex" }}>
                          <SvgIcon>
                            <CheckCircleIcon className={classes.icon} />
                          </SvgIcon>
                          <div>
                            <Typography
                              component="div"
                              className={`${classes.taskName} `}
                            >
                              <TextTruncate
                                line={1}
                                truncateText="…"
                                text={plan.task_name}
                              />
                            </Typography>
                          </div>
                        </div>

                        <Stack direction="row" alignItems="center">
                          {isAllowToSeeOtherUsersPlans ? (
                            <IconButton
                              size="small"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                getPlanUsers(plan.task_id, event.currentTarget);
                                setUserListAnchorEl(event.currentTarget);
                              }}
                            >
                              <People fontSize="small" />
                            </IconButton>
                          ) : null}
                          <Typography className={classes.taskTime}>
                            {`${moment(plan.start_time)
                              .utc()
                              .format(`HH:mm`)} - ${moment(plan.end_time)
                              .utc()
                              .format(`HH:mm`)}`}
                          </Typography>
                        </Stack>
                      </div>
                      {plan.document_id && (
                        <div
                          className={classes.attachedFileLine}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFileDownload(
                              plan?.document_url,
                              plan?.document_name
                            );
                          }}
                        >
                          <SvgIcon>
                            <FilePresentRoundedIcon className={classes.icon} />
                          </SvgIcon>
                          <Typography
                            component="div"
                            className={classes.taskName}
                          >
                            <TextTruncate
                              line={1}
                              truncateText="…"
                              text={plan?.document_name}
                            />
                          </Typography>
                        </div>
                      )}

                      <Divider className={classes.divider} />

                      <CheckInButton
                        plan={plan}
                        isItemCertificationRequiered={
                          isItemCertificationRequiered
                        }
                        isDependencyRequiered={isDependencyRequiered}
                        classes={classes}
                        onPlanCheckin={onPlanCheckin}
                        allowCheckIn={allowCheckIn}
                      />
                    </div>
                  </div>
                </Paper>
              );
            })}
        </Grid>
      </Grid>

      {userListAnchorEl ? (
        <Popover
          open={!!userListAnchorEl}
          anchorEl={userListAnchorEl}
          onClose={() => {
            setUserListAnchorEl(false);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div
            className={classes.poperBody}
            id="check-in-other-users-popup-todays-plan"
          >
            <div className={classes.poperHeader}>
              {i18next.t("Other users")}
              <IconButton
                aria-label={i18next.t("Close")}
                className={classes.closePopoverButton}
                onClick={() => setUserListAnchorEl(false)}
                size="small"
              >
                <CloseIcon
                  className={classes.closePopoverIcon}
                  fontSize="small"
                />
              </IconButton>
            </div>
            {specificPlanUsersLoading ? (
              <Typography variant="body2" className={classes.poperMsg}>
                {i18next.t("Loading...")}
              </Typography>
            ) : (
              <>
                {userList.length === 0 ? (
                  <Typography variant="body2" className={classes.poperMsg}>
                    {i18next.t("No users found")}
                  </Typography>
                ) : (
                  <div>
                    {userList.map((userName, index) => {
                      return (
                        <div className={classes.poperItem} key={index}>
                          {`${index + 1}. ${userName}`}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </Popover>
      ) : null}
    </>
  );
};

const CheckInButton = (props) => {
  const {
    isItemCertificationRequiered,
    isDependencyRequiered,
    plan,
    classes,
    onPlanCheckin,
    allowCheckIn,
  } = props;

  let hasDependencyPermission = true;
  if (isDependencyRequiered) {
    const depIndex = plan?.planning_planned_hours_dependencies?.findIndex(
      (dep) =>
        (dep.dependency_type === "task"
          ? dep.task_finish
          : dep.project_finish) === false
    );
    hasDependencyPermission = depIndex === -1 ? true : false;
  }

  return (
    <div className={classes.checkInButtonContainer}>
      {(isItemCertificationRequiered || isDependencyRequiered) && (
        <Typography component="div">
          <Stack className={classes.alertBox}>
            {(isItemCertificationRequiered ? plan?.allow_item : true) &&
            hasDependencyPermission ? (
              <Alert
                icon={<CheckIcon fontSize="inherit" />}
                severity="success"
                sx={{ py: 0 }}
              >
                {i18next.t("Required item or dependency")}
              </Alert>
            ) : (
              <Alert severity="warning" sx={{ py: 0 }}>
                {i18next.t("Required item or dependency")}
              </Alert>
            )}
          </Stack>
        </Typography>
      )}

      <Button
        aria-label="check-in"
        onClick={async (event) => {
          event.preventDefault();
          onPlanCheckin(true, { planId: plan.id, taskId: plan.task_id });
        }}
        disabled={
          !allowCheckIn ||
          !(isItemCertificationRequiered ? plan.allow_item : true) ||
          !(isDependencyRequiered
            ? plan?.planning_planned_hours_dependencies.every((dep) =>
                dep.dependency_type === "task"
                  ? dep.task_finish
                  : dep.project_finish
              )
            : true)
        }
        id="check-in-fab-button"
        className={`${classes.button}`}
        variant="outlined"
      >
        {i18next.t("Check in")}
      </Button>
    </div>
  );
};

SelectPlannedHour.propTypes = {
  className: PropTypes.string,
};

export default SelectPlannedHour;
