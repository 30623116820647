import { get, put, post, deletee } from "@/utils/api.config.js";
import { actions as authActions } from "@/utils/auth.js";

export const getEmployees = (queryString) => {
  const request = {
    subUrl: `/attendance/users/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const generateEmployeePin = (id, requestBody) => {
  const request = {
    subUrl: `/user/${id}/generate-pin`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return put(request);
};

export const getProjects = (queryString) => {
  const request = {
    subUrl: `/projects/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getHourTypes = (queryString) => {
  const request = {
    subUrl: `/hour-types?${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getDepartment = (queryString) => {
  const request = {
    subUrl: `/attendance/departments/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getProjectList = (queryString) => {
  const request = {
    subUrl: `/attendance/projects/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getTaskList = (queryString) => {
  const request = {
    subUrl: `/attendance/tasks/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getTasks = (queryString) => {
  const request = {
    subUrl: `/tasks/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getTaskByIdApi = (params) => {
  const request = {
    subUrl: `/task/${params}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const postHourCheckinApi = (requestBody) => {
  const request = {
    v2Url: `/v2/check-in`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const getHourCheckinApi = (queryString) => {
  const request = {
    subUrl: `/check-in-outs/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const removeHourCheckinApi = (id) => {
  const request = {
    subUrl: `/check-in-out/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return deletee(request);
};

export const createHour = (requestBody) => {
  const request = {
    subUrl: `/hour`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const createMultipleProjectHour = (requestBody) => {
  const request = {
    subUrl: `/hours/multiple-create`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const postUserAttendance = (requestBody) => {
  const request = {
    subUrl: `/attendance/user-attendance`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const getUserAttendance = (queryString) => {
  const request = {
    subUrl: `/attendance/v2/attendances/${
      queryString ? `?${queryString}` : ``
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getLastUserAttendance = () => {
  const request = {
    subUrl: `/attendance/user-last-attendance`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const updateUserAttendance = (id, requestBody) => {
  const request = {
    subUrl: `/attendance/user-attendance/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return put(request);
};

export const getPlannedHours = (queryString) => {
  const request = {
    subUrl: `/attendance/planned-hours/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getPlanDetails = (id) => {
  const request = {
    subUrl: `/planning/planned-hour/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getHours = (queryString) => {
  const request = {
    subUrl: `/hours/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getAddons = (queryString) => {
  const request = {
    subUrl: `/addon/addons/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getCompanyModules = () => {
  const request = {
    subUrl: `/company/modules`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getCompanySettings = () => {
  const request = {
    subUrl: `/company-settings`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const updateHour = (type, id, requestBody) => {
  let apiURL = `/hour/${id}`;
  if (type === "addon") {
    apiURL = `/addon/addon/${id}`;
  } else if (type === "batch") {
    apiURL = `/batch/${id}`;
  }
  const request = {
    subUrl: apiURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return put(request);
};

export const getDepartmentList = () => {
  const request = {
    subUrl: `/company/departments?more_details=false&limit=200`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getUserGroupList = () => {
  const request = {
    subUrl: `/groups?limit=200`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const checkUserEmail = (params) => {
  const request = {
    subUrl: `/user/exists`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
    params: params,
  };
  return get(request);
};

export const addNewUser = (requestBody) => {
  const request = {
    subUrl: `/user/`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const deleteHour = (type, id) => {
  let apiURL = `/hour/${id}`;
  if (type === "addon") {
    apiURL = `/addon/addon/${id}`;
  } else if (type === "batch") {
    apiURL = `/batch/${id}`;
  }
  const request = {
    subUrl: apiURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return deletee(request);
};

export const getHourTypesSummary = (id, queryString) => {
  const request = {
    subUrl: `/user/${id}/hour-types-summary/${
      queryString ? `?${queryString}` : ``
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getAddonTypesSummary = (id, queryString) => {
  const request = {
    subUrl: `/user/${id}/addon-types-summary/${
      queryString ? `?${queryString}` : ``
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getFixedAddonTypesSummary = (id, queryString) => {
  const request = {
    subUrl: `/user/${id}/fixed-addon-types-summary/${
      queryString ? `?${queryString}` : ``
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getUsersNotCheckIn = (queryString) => {
  const request = {
    subUrl: `/attendance/not-check-in-users${
      queryString ? `?${queryString}` : ``
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getUserProfileData = () => {
  const request = {
    subUrl: `/user/current/`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getHmskTemplatesData = (queryString) => {
  const request = {
    subUrl: `/hmsk/templates/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getHmskTemplateData = (id) => {
  const request = {
    subUrl: `/hmsk/template/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const addHmskCheckList = (data) => {
  const request = {
    subUrl: `/hmsk/check-list`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: data,
  };
  return post(request);
};

export const uploadImage = (data) => {
  const request = {
    subUrl: `/attachment/image`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: data,
  };
  return post(request);
};

export const deleteImage = (queryString) => {
  const request = {
    subUrl: `/attachment/image${queryString ? `?${queryString}` : ""}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return deletee(request);
};

export const isNeedToFillCheckList = (user_id, project_ids, task_ids) => {
  const request = {
    subUrl: `/hmsk/check-lists/need-to-fill?user_id=${user_id}&project_ids=${project_ids}&task_ids=${task_ids}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getItems = (queryString) => {
  const request = {
    subUrl: `/items${queryString ? `?${queryString}` : ""}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const updateItem = (id, requestBody) => {
  const request = {
    subUrl: `/item/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return put(request);
};

export const removeItem = (id) => {
  const request = {
    subUrl: `/item/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return deletee(request);
};

export const getItemTypes = (queryString) => {
  const request = {
    subUrl: `/item-types${queryString ? `?${queryString}` : ""}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const addNewItem = (requestBody) => {
  const request = {
    subUrl: `/item`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const getUsers = (queryString) => {
  const request = {
    subUrl: `/users/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getUserSpecificDefaultData = (userId) => {
  const request = {
    subUrl: `/user/${userId}/preferences`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + authActions.getAccessToken(),
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

// I know it is not the best place to put it, but it's an emergency
export const kioskCheckIn = async (cardId) => {
  const now = new Date();
  const time = new Date(
    now.getTime() - now.getTimezoneOffset() * 60000
  ).toJSON();
  const request = {
    subUrl: "/check-in-outs/kiosk/",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN}`,
      "Company-Token": authActions.getCompanyToken(),
    },
    data: { user_card_id: cardId, time: time },
  };
  return post(request);
};
