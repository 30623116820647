import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  btnRed: {
    fontSize: 16,
    fontWeight: 700,
    borderRadius: "0.5rem",
    whiteSpace: "nowrap",
    width: "100%",
  },
  btnOutline: {
    fontSize: 16,
    fontWeight: 700,
    width: "100%",
    whiteSpace: "nowrap",
  },
  actionButtons: {
    padding: "16px !important",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  btnCancel: {
    color: theme.palette.text.secondary,
  },
}));

export default function ContinuesCheckInModal({
  isOpen,
  changeState,
  onFinishClick,
  handleContinuesCheckIn,
}) {
  const classes = useStyles();

  const handleFinalCheckout = async () => {
    await changeState({
      openContinuesCheckInModal: false,
      continuesCheckInStartTime: null,
    });

    onFinishClick();
  };

  const handleNextTask = () => {
    handleContinuesCheckIn();
  };

  const handleCancel = () => {
    changeState({
      openContinuesCheckInModal: false,
    });
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={isOpen} onClose={handleCancel}>
      <DialogTitle>{i18next.t("Are you done?")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18next.t("Would you like to check out or add another task?")}
        </DialogContentText>
        <Stack spacing={1} style={{ marginTop: "16px" }}>
          <Button
            variant="contained"
            className={classes.btnOutline}
            fullWidth
            onClick={handleNextTask}
            id="next-task-button"
          >
            {i18next.t("Next Task")}
          </Button>
          <Button
            variant="contained"
            className={classes.btnRed}
            color="error"
            fullWidth
            onClick={handleFinalCheckout}
            id="check-out-button"
          >
            {i18next.t("Check Out")}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button className={classes.btnCancel} onClick={handleCancel}>
          {i18next.t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
