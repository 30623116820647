import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Button, Typography } from "@mui/material";

import { actions as authActions } from "@/utils/auth";
import { logout } from "@/utils/api.config.js";

import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: "100%",
    display: "flex",
  },
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    fontSize: 22,
  },
  subHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    color: "#808080",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  checkInContainer: {
    marginBottom: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  errorText: {
    color: theme.palette.error.main,
    padding: "0px 5px 10px 15px",
  },
}));

const BackToHomePage = (props) => {
  const {
    history,
    title,
    subHeading,
    isGuest,
    dashboardOption,
    action,
    isContinuesCheckIn,
    errorMsg,
  } = props;

  const classes = useStyles();
  const companyToken = authActions.getCompanyToken();

  const redirectToHome = async () => {
    if (!isGuest) {
      const postObj = {
        client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
        client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
        token: authActions.getAccessToken(),
      };

      await logout(postObj);
    }
    history.push("/token/" + companyToken);
  };

  let timer = setTimeout(() => {
    if (!dashboardOption || action === "check-in" || action === "check-out") {
      redirectToHome();
    }
  }, 10000);

  const redirectToDashboard = () => {
    clearTimeout(timer);
    history.push("/dashboard");
  };

  const redirectToCheckIn = () => {
    clearTimeout(timer);
    history.push("/dashboard/?continue=1");
  };

  useEffect(() => {
    return function cleanup() {
      clearTimeout(timer);
    };
  });

  return (
    <div className={classes.root}>
      <Grid container className={classes.pageContainer}>
        <Grid
          item
          xl={6}
          lg={8}
          sm={12}
          xs={10}
          className={classes.buttonContainer}
        >
          {errorMsg && errorMsg.length > 0 && (
            <Typography variant="body1" className={classes.errorText}>
              {errorMsg}
            </Typography>
          )}

          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subHeading}>{subHeading}</Typography>
          {isContinuesCheckIn && (
            <Typography component="div" className={classes.checkInContainer}>
              <Button
                color="primary"
                variant="contained"
                onClick={(event) => {
                  event.preventDefault();
                  redirectToCheckIn();
                }}
              >
                {i18next.t("Continuous check-in")}
              </Button>
            </Typography>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={(event) => {
              event.preventDefault();
              clearTimeout(timer);
              redirectToHome();
            }}
          >
            {isGuest ? i18next.t("Home") : i18next.t("Sign out")}
          </Button>
          {dashboardOption && (
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 20 }}
              onClick={(event) => {
                event.preventDefault();
                redirectToDashboard();
              }}
            >
              {i18next.t("Dashboard")}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default BackToHomePage;
