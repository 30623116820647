const styles = (theme) => ({
  tooltipItemTitle: {
    fontWeight: "bold",
  },
  hourTypeItemDescription: {
    fontWeight: "bold",
    fontSize: 10,
    color: "#9e9e9e",
  },
  button: {
    padding: "8px 24px",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    padding: "8px 24px",
    color: theme.palette.text.disabled,
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.action.hover,
    },
    paddingTop: 7,
  },
  btnSubmit: {
    padding: "7px 23px",
    textTransform: "uppercase",
    boxShadow: "none",
  },
  btnOverlapSubmit: {
    //padding: "7px 23px",
    textTransform: "uppercase",
    boxShadow: "none",
  },
  overlapButtons: {
    display: "flex",
    justifyContent: "end",
  },
  mb: {
    marginBottom: "1rem",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  textField: {
    padding: "0px",
    height: "50px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 6,
  },
  iconBorder: {
    top: 45,
    right: 25,
    position: "absolute",
    color: "#18B24B",
  },
  actionButtons: {
    padding: "0 10px 10px 10px",
  },
  inputLabel: {
    marginBottom: 7,
  },
  durationLabel: {
    marginBottom: 15,
    marginTop: 10,
    color: theme.palette.text.primary,
  },
  titleLabel: {
    marginTop: 10,
    color: theme.palette.text.primary,
  },
  pointer: {
    cursor: "pointer",
  },
  btnDanger: {
    padding: "8px 24px",
    textTransform: "uppercase",
    color: theme.palette.error.main,
    "&:hover": {
      background: `${theme.palette.error.lighter}50`,
    },
  },
  btnGreen: {
    backgroundColor: "#19B248",
    color: "#fff",
  },
  iconView: {
    color: "#19B248",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  redoView: {
    marginBottom: 15,
    color: "#19B248",
    transform: "rotateY(180deg) rotate(-60deg)",
  },
  repeatHour: {
    fontSize: 13,
    fontWeight: 500,
    background: theme.palette.primary.main,
    textAlign: "center",
    color: "#fff",
    borderRadius: 18,
    padding: 5,
    margin: 5,
    cursor: "pointer",
  },
  repeatHourButton: {
    fontSize: 14,
    fontWeight: 500,
    background: theme.palette.primary.main,
    textAlign: "center",
    color: "#fff",
    borderRadius: 18,
    padding: 5,
    margin: 5,
    cursor: "pointer",
  },
  feedback: {
    display: "block",
  },
  textCenter: {
    textAlign: "center",
  },
  selectInput: {
    display: "flex",
    padding: 0,
    height: "40px",
  },
  /*selectPaper: {
    position: "absolute",
    marginTop: theme.spacing(1),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  closeIconContainer: {
    color: "#ccc",
    position: "absolute",
    top: "0px",
    right: "0px",
    width: "100%",
    backgroundColor: "#f0f0f0",
    justifyContent: "flex-end",
    display: "flex",
  },
  tabHeader: {
    marginLeft: -32,
    marginRight: -32,
    boxShadow:
      "0px 1px 0px 0px rgba(0,0,0,0.2), 0px 2px 0px 0px rgba(0,0,0,0.14), 0px 7px 2px -2px rgba(0,0,0,0.12)",
  },
  checkBox: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  addProjectButton: {
    flexGrow: 1,
  },
  sliderBlock: {
    width: "100%",
    marginLeft: 16,
  },
  previewSliderBlock: {
    width: "100%",
    marginTop: 16,
    marginLeft: 16,
    borderRadius: 8,
    paddingLeft: 16,
    paddingBottom: 8,
    boxShadow: theme.customShadows.card,
    border: `1px solid ${theme.palette.background.neutral}`,
  },
  addButtonContainer: {
    marginTop: 20,
  },
  addButton: {},
  rightButtons: {
    display: "flex",
    alignItems: "center",
  },
  dialogContent: {
    padding: "0 35px",
    overflowX: "hidden",
    ".MuiDialogTitle-root + &": {
      paddingTop: theme.spacing(0),
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
});

export default styles;
