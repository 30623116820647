import React from "react";
import i18next from "i18next";
import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";

import { CustomTabs, CustomTab } from "./CustomTabs";

const styles = (theme) => ({
  root: {
    maxHeight: 50,
    width: "max-content",
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    border: `1px solid ${theme.palette.background.neutral}`,
    "& .MuiTabs-root": {
      minHeight: 42,
    },
  },
});

class TabsEditPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: props.selectedTab,
    };
  }

  componentDidMount() {
    this.setState({ selectedTab: this.props.selectedTab });
  }

  handleChangeTab = (event, selectedTab) => {
    const { onChange } = this.props;
    this.setState({ selectedTab });
    onChange(selectedTab);
  };

  render = () => {
    const { selectedTab } = this.state;
    const { classes } = this.props;

    return (
      <Paper square className={classes.root}>
        <CustomTabs
          className="custom-tab"
          value={selectedTab}
          onChange={this.handleChangeTab}
          textColor="primary"
        >
          <CustomTab label={i18next.t("Preview")} />
          <CustomTab label={i18next.t("Edit")} />
        </CustomTabs>
      </Paper>
    );
  };
}

export default withStyles(styles)(TabsEditPreview);
