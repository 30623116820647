import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const useIdleRefresh = (options = {}) => {
  const { idleTimeThreshold = 5000, checkInterval = 1000 } = options;
  const [lastInteraction, setLastInteraction] = useState(Date.now());

  useEffect(() => {
    const checkIdleTime = () => {
      const currentTime = Date.now();
      const idleTime = currentTime - lastInteraction;

      if (idleTime > idleTimeThreshold) {
        window.location.reload();
      }
    };

    const activityHandler = () => {
      setLastInteraction(Date.now());
    };

    const intervalId = setInterval(checkIdleTime, checkInterval);

    window.addEventListener("mousemove", activityHandler);
    window.addEventListener("keydown", activityHandler);
    window.addEventListener("touchstart", activityHandler);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("mousemove", activityHandler);
      window.removeEventListener("keydown", activityHandler);
      window.removeEventListener("touchstart", activityHandler);
    };
  }, [lastInteraction, idleTimeThreshold, checkInterval]);

  return lastInteraction;
};

useIdleRefresh.propTypes = {
  options: PropTypes.shape({
    // The maximum time of inactivity (in milliseconds) before triggering a refresh.
    idleTimeThreshold: PropTypes.number,
    // The interval (in milliseconds) at which the page checks for user activity.
    checkInterval: PropTypes.number,
  }),
};

export default useIdleRefresh;
