//

import React, { Component, Fragment } from "react";

import { withStyles } from "@mui/styles";
import {
  Typography,
  SvgIcon,
  Paper,
  Avatar,
  Button,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import { DoneOutlineRounded } from "@mui/icons-material";
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded";
import StopCircleIcon from "@mui/icons-material/StopCircle";

import i18next from "i18next";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";

import handleFileDownload from "@/utils/handleFileDownload";

import LockIcon from "@mui/icons-material/Lock";

const styles = (theme) => ({
  planCard: {
    minWidth: 100,
    marginTop: 8,
    //maxWidth: 210,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.customShadows.hourCard,
    cursor: "pointer",
    overflow: "hidden",
  },
  icon: {
    fontSize: 18,
    color: theme.palette.text.secondary,
  },
  cardText: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  flexLine: {
    display: "flex",
    alignItems: "center",
  },
  cardBottomLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardIconContainer: {
    paddingRight: 3,
    display: "flex",
  },
  cardIconContainer1: {
    padding: "0px 7px 0px 3px",
    display: "flex",
  },
  separator: {
    marginLeft: 5,
  },
  separatorV: {
    marginTop: 5,
  },
  addPlanView: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 8,
  },
  tooltip: {
    fontSize: 12,
    whiteSpace: "pre-line",
  },
  tooltipContainer: {
    cursor: "pointer",
    display: "flex",
  },
  checkinContainer: {
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    display: "flex",
    borderRadius: "0px 0px 4px 4px",
  },
  checkinBlock: {
    fontWeight: 500,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 8,
    width: "100%",
  },
  checkinIcon: {
    marginLeft: 5,
    fontSize: 20,
  },
  planContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  disabledCheckin: {
    color: theme.palette.text.disabled,
  },
  doneIcon: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginLeft: 5,
  },
  btnToday: {
    display: "flex",
    flexWrap: "nowrap",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 700,
    borderRadius: 8,
    width: "-webkit-fill-available",
    paddingInline: 8,
  },
  avatar: {
    width: 24,
    height: 24,
  },
  bottomBorderDiv: {
    height: 4,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    marginTop: 5,
  },
  inlineFlex: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

const baseUrl = import.meta.env.VITE_API_BASE_URL;

class PlanCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateButton: false,
      openTooltip: [],
    };
  }

  editPlan = (plan) => {
    this.props.formModalOpen(
      i18next.t("Plan detail"),
      plan.id,
      this.props.dayNumber,
      this.props.user,
      this.props.project,
      plan
    );
  };

  toggleCreateButton = (value) => {
    this.setState({
      showCreateButton: value,
    });
  };

  onMouseEnter = () => {
    this.toggleCreateButton(true);
  };

  onMouseLeave = () => {
    this.toggleCreateButton(false);
  };

  handleTooltipClose = (id) => {
    const openFlags = this.state.openTooltip;
    openFlags[id] = false;
    this.setState({
      openTooltip: openFlags,
    });
  };

  handleTooltipOpen = (id) => {
    const openFlags = this.state.openTooltip;
    openFlags[id] = true;
    this.setState({
      openTooltip: openFlags,
    });
  };

  render() {
    const {
      classes,
      user,
      //project,
      //dayNumber,
      plans,
      state,
      isToday,
      dateStr,
      planSchedule,
      checkInData,
    } = this.props;

    var enLocale = moment();
    enLocale.locale("en");

    return (
      <Fragment>
        <div
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className={classes.planContainer}
        >
          {plans &&
            plans.map((plan) => {
              return (
                dateStr === plan.start_date_str && (
                  <Draggable
                    draggableId={`di${plan.id}`}
                    key={plan.id}
                    index={plan.id}
                    // isDragDisabled={!state.isAdminUser}
                    isDragDisabled={true}
                  >
                    {(provided) => (
                      <div
                        key={`d${plan.id}`}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Paper
                          elevation={1}
                          className={classes.planCard}
                          key={`p${plan.id}`}
                        >
                          <ClickAwayListener
                            onClickAway={() => this.handleTooltipClose(plan.id)}
                          >
                            <Typography
                              component="div"
                              style={{ padding: "5px" }}
                              onClick={() => {
                                state.isAdminUser
                                  ? this.editPlan(plan)
                                  : this.handleTooltipOpen(plan.id);
                              }}
                            >
                              <Typography
                                component="div"
                                className={classes.flexLine}
                              >
                                <Typography
                                  component="div"
                                  className={classes.cardIconContainer}
                                >
                                  {state.groupBy === "user" ? (
                                    <LockSection plan={plan} state={state} />
                                  ) : (
                                    <Avatar
                                      className={classes.avatar}
                                      src={`${baseUrl}${plan.user_avatar_url}`}
                                    />
                                  )}
                                </Typography>
                                <Typography
                                  component="p"
                                  noWrap
                                  className={classes.cardText}
                                >
                                  {state.groupBy !== "user"
                                    ? plan.user_name
                                    : plan.project_name}
                                </Typography>
                              </Typography>
                              <Typography
                                component="div"
                                className={classes.separatorV}
                              />
                              <Typography
                                component="div"
                                className={classes.flexLine}
                              >
                                <Typography
                                  component="div"
                                  className={classes.cardIconContainer1}
                                >
                                  <SvgIcon className={classes.icon}>
                                    <path
                                      d={
                                        "M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"
                                      }
                                    />
                                  </SvgIcon>
                                </Typography>
                                <Typography
                                  component="p"
                                  noWrap
                                  className={classes.cardText}
                                >
                                  {plan.task_name}
                                </Typography>
                              </Typography>
                              <Typography
                                component="div"
                                className={classes.separatorV}
                              />
                              <Typography
                                component="div"
                                className={classes.cardBottomLine}
                              >
                                <div className={classes.flexLine}>
                                  <Typography
                                    component="div"
                                    className={classes.cardIconContainer1}
                                  >
                                    <SvgIcon className={classes.icon}>
                                      <path
                                        d={
                                          "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M16.2,16.2L11,13V7H12.5V12.2L17,14.9L16.2,16.2Z"
                                        }
                                      />
                                    </SvgIcon>
                                  </Typography>
                                  <Typography
                                    component="p"
                                    noWrap
                                    className={classes.cardText}
                                  >
                                    {moment(plan.start_time)
                                      .utc()
                                      .format(`HH:mm`)}{" "}
                                    -{" "}
                                    {moment(plan.end_time)
                                      .utc()
                                      .format(`HH:mm`)}
                                  </Typography>
                                </div>

                                <div style={{ flexGrow: 1 }} />

                                {plan.comment && plan.comment.length > 0 && (
                                  <Typography
                                    component="span"
                                    className={classes.tooltipContainer}
                                  >
                                    <Tooltip
                                      title={plan.comment}
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}
                                      onOpen={() =>
                                        this.handleTooltipOpen(plan.id)
                                      }
                                      onClose={() =>
                                        this.handleTooltipClose(plan.id)
                                      }
                                      open={
                                        this.state.openTooltip[plan.id]
                                          ? this.state.openTooltip[plan.id]
                                          : false
                                      }
                                      enterTouchDelay={200}
                                    >
                                      <SvgIcon className={classes.doneIcon}>
                                        <path
                                          d={
                                            "M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M5,5V7H19V5H5M5,9V11H13V9H5M5,13V15H15V13H5Z"
                                          }
                                        />
                                      </SvgIcon>
                                    </Tooltip>
                                  </Typography>
                                )}
                                {plan.document_id && (
                                  <Tooltip
                                    title={plan?.document_name}
                                    placement="top"
                                  >
                                    <FilePresentRoundedIcon
                                      className={classes.doneIcon}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleFileDownload(
                                          plan?.document_url,
                                          plan?.document_name
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                {plan.task_finish && (
                                  <DoneOutlineRounded
                                    className={classes.doneIcon}
                                  />
                                )}
                              </Typography>
                            </Typography>
                          </ClickAwayListener>

                          {isToday &&
                            !planSchedule &&
                            plan.status !== "inactive" &&
                            user &&
                            user.id === parseInt(state.userId) && (
                              <Typography
                                component="div"
                                className={classes.checkinContainer}
                                onClick={() => {
                                  // If no check-in present, then allow to check-in the plan
                                  if (
                                    !state.isHourCheckin &&
                                    (state.isItemCertificationRequiered
                                      ? plan.allow_item
                                      : true) &&
                                    (state.isDependencyRequiered
                                      ? plan?.planning_planned_hours_dependencies.every(
                                          (dep) =>
                                            dep.dependency_type === "task"
                                              ? dep.task_finish
                                              : dep.project_finish
                                        )
                                      : true)
                                  ) {
                                    this.props.checkInStatus(plan);
                                  }
                                }}
                              >
                                <Typography
                                  component="div"
                                  className={`${classes.checkinBlock} ${
                                    state.isHourCheckin &&
                                    classes.disabledCheckin
                                  }`}
                                >
                                  <div style={{ width: "100%" }}>
                                    {state.checkinPlan === plan.id &&
                                    state.checkinStep &&
                                    state.checkinStep === "checkin" ? (
                                      <CheckInTimerButton
                                        data={checkInData}
                                        classes={classes}
                                      />
                                    ) : (
                                      <Button
                                        variant="outlined"
                                        fullWidth
                                        disabled={
                                          (state.checkinPlan !== plan.id &&
                                            state.checkinStep &&
                                            state.checkinStep === "checkin") ||
                                          state.isHourCheckin ||
                                          !(state.isItemCertificationRequiered
                                            ? plan.allow_item
                                            : true) ||
                                          !(state.isDependencyRequiered
                                            ? plan?.planning_planned_hours_dependencies.every(
                                                (dep) =>
                                                  dep.dependency_type === "task"
                                                    ? dep.task_finish
                                                    : dep.project_finish
                                              )
                                            : true)
                                        }
                                        className={classes.btnToday}
                                      >
                                        {i18next.t("Check in")}
                                      </Button>
                                    )}
                                  </div>
                                </Typography>
                              </Typography>
                            )}

                          <Typography
                            component="div"
                            className={classes.bottomBorderDiv}
                            style={{
                              background:
                                plan.status === "inactive"
                                  ? "#d20404"
                                  : plan.project_color
                                    ? plan.project_color
                                    : "#18B24B",
                            }}
                          />
                        </Paper>
                      </div>
                    )}
                  </Draggable>
                )
              );
            })}
        </div>

        {/*state.isAdminUser && (
          <Typography
            component="div"
            className={classes.addPlanView}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <CreateTaskButtonPlanning
              show={this.state.showCreateButton}
              //show={true}
              size={"small"}
              onClick={() => {
                this.props.formModalOpen(
                  "Plan Hours",
                  null,
                  dayNumber,
                  user,
                  project,
                  null
                );
              }}
              title={""}
              buttonStyle={classes.buttonStyle}
              id={`create-new-plan-button-${dayNumber}`}
            />
          </Typography>
            )*/}
      </Fragment>
    );
  }
}

const CheckInTimerButton = (props) => {
  const { data } = props;
  const [durationStr, setDurationStr] = React.useState("");

  React.useEffect(() => {
    let timerId = null;
    const renderTime = () => {
      const timerIdTemp = setInterval(() => {
        let durationStr = "";
        const currentTime = moment(data?.start_time)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS");

        let timeDuration = "";

        const timeDeference = moment().diff(moment(currentTime));
        timeDuration = moment.duration(timeDeference);

        if (timeDuration && timeDuration.days() > 0) {
          durationStr = `${timeDuration.days()}d ${timeDuration.hours()}h ${timeDuration.minutes()}m ${timeDuration.seconds()}s`;
        } else {
          durationStr = `${timeDuration.hours()}h ${timeDuration.minutes()}m ${timeDuration.seconds()}s`;
        }
        setDurationStr(durationStr);
      }, 2000);

      timerId = timerIdTemp;
    };

    renderTime();

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [data]);

  return (
    <Button
      variant="outlined"
      fullWidth
      color="error"
      className={props.classes.btnToday}
      endIcon={<StopCircleIcon color="error.main" sx={{ ml: 0.5 }} />}
    >
      {durationStr ? durationStr : ""}
    </Button>
  );
};

const LockSection = ({ plan, state }) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const allowItem = state.isItemCertificationRequiered ? plan.allow_item : true;
  const allowDependency = state.isDependencyRequiered
    ? plan?.planning_planned_hours_dependencies.every((dep) =>
        dep.dependency_type === "task" ? dep.task_finish : dep.project_finish
      )
    : true;

  return (
    <Fragment>
      {(!allowItem || !allowDependency) && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              title={i18next.t("Required item or dependency")}
              placement="top"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <LockIcon
                onClick={handleTooltipOpen}
                sx={{
                  height: "12px",
                  width: "12px",
                  position: "relative",
                  left: "8px",
                }}
              />
            </Tooltip>
          </div>
        </ClickAwayListener>
      )}
      <SvgIcon style={{ fontSize: 22 }}>
        <path
          fill={`${
            plan.status === "inactive"
              ? "#d20404"
              : plan.project_color
                ? plan.project_color
                : "#18B24B"
          }`}
          d={
            state.groupBy !== "user"
              ? "M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
              : "M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z"
          }
        />
      </SvgIcon>
    </Fragment>
  );
};

export default withStyles(styles)(PlanCard);
