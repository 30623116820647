import React from "react";
import { Link as RouterLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Toolbar } from "@mui/material";

import VrsLogo from "@/assets/images/vrsLogo.svg?react";

import { actions as authActions } from "@/utils/auth";

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: 900,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
    boxShadow: theme.customShadows.card,
  },
  toolbar: {
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 3),
    height: 64,
  },
  logo: {
    display: "flex",
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

const Topbar = (props) => {
  const { history } = props;

  const classes = useStyles();

  const companyToken = authActions.getCompanyToken();

  const subscriptionStatus = sessionStorage.getItem("status");
  if (subscriptionStatus && subscriptionStatus === "expired") {
    // If plan expired, and user tries to access any other page, redirect them back to index page
    const pageName = props.location.pathname;
    if (pageName && pageName !== "/") {
      history.push("/token/" + companyToken);
    }
  }

  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to={`/token/${companyToken}`} className={classes.logo}>
          <VrsLogo fill={"black"} height={38} />
        </RouterLink>
        <div className={classes.flexGrow} />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
