import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography,
  Paper,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import i18next from "i18next";
import makeStyles from "@mui/styles/makeStyles";

import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import { MaterialSelect } from "@/common/selectComponents/MaterialSelect";
import { getProjectList } from "@/services/employee";
import { getDepartment } from "@/services/employee";
import { getTaskList } from "@/services/employee";
import { withSnackbar } from "notistack";
import ProjectTaskModal from "./ProjectTaskModal";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  sectionHeading: {
    margin: "10px 0px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listContainer: {
    maxHeight: 340,
    overflowY: "auto",
    padding: "4px 10px 4px 4px",
  },
  listCard: {
    display: "flex",
    marginBottom: "10px",
    padding: "10px",
    boxShadow: theme.customShadows.card,
    border: `1px solid ${theme.palette.text.secondary}29`,
    justifyContent: "space-between",
    alignItems: "center",
  },
  taskName: {
    fontWeight: 600,
    fontSize: 14,
    paddingBottom: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.palette.text.primary,
  },
  projectName: {
    fontSize: 14,
    //paddingBottom: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.palette.text.primary,
  },
  iconButton: {
    padding: 6,
  },
  inputField: {
    marginBottom: 15,
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "right",
  },
  checkboxContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const DEFAULT_DEPARTMENT = "default_department";
const DEFAULT_PROJECT = "default_project";
const DEFAULT_TASK = "default_task";
const DEFAULT_PROJECT_TASK_LIST = "defualt_project_task_list";
const SKIP_HOUR_MODAL = "skip_hour_modal";
const ALLOW_ALL_USERS_IN_SEARCH = "allow_all_users_search";

const DeviceSetting = (props) => {
  const classes = useStyles();

  const [selectedDepartment, setSelectedDepartment] = useState(
    localStorage.getItem(DEFAULT_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(DEFAULT_DEPARTMENT))
      : []
  );
  const [searchAllUsers, setSearchAllUsers] = useState(
    localStorage.getItem(ALLOW_ALL_USERS_IN_SEARCH) &&
      localStorage.getItem(ALLOW_ALL_USERS_IN_SEARCH) === "true"
      ? true
      : false
  );
  const [skipHourModalOnCheckout, setSkipHourModalOnCheckout] = useState(
    localStorage.getItem(SKIP_HOUR_MODAL) &&
      localStorage.getItem(SKIP_HOUR_MODAL) !== "true"
      ? false
      : true
  ); // default true
  const [selectedProject, setSelectedProject] = useState(
    localStorage.getItem(DEFAULT_PROJECT)
      ? JSON.parse(localStorage.getItem(DEFAULT_PROJECT))
      : ""
  );
  const [selectedTask, setSelectedTask] = useState(
    localStorage.getItem(DEFAULT_TASK)
      ? JSON.parse(localStorage.getItem(DEFAULT_TASK))
      : ""
  );
  const [projectTaskList, setProjectTaskList] = useState(
    localStorage.getItem(DEFAULT_PROJECT_TASK_LIST)
      ? JSON.parse(localStorage.getItem(DEFAULT_PROJECT_TASK_LIST))
      : []
  );

  const [departmentList, setDepartmentList] = useState([]);
  const [openProjctTaskModal, setOpenProjectTaskModal] = useState(false);

  useEffect(() => {
    loadDepartmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDepartmentList = async () => {
    const response = await getDepartment();
    const result = await getDepartmentListOption(response);
    setDepartmentList(result.options);
  };

  const getDepartmentListOption = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let departmentList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];

          if (element) {
            departmentList.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
        }

        resolve({
          options: departmentList,
        });
      }
    });

    return promise;
  };

  const loadProject = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `limit=${limit}&offset=${offset}&open=true`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getProjectList(queryString);
    const result = await getProjectListOption(response);
    return result;
  };

  const getProjectListOption = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let projectList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];

          if (element) {
            projectList.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
        }

        resolve({
          options: projectList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const loadTaskList = async (searchVal, options, projectNode = null) => {
    let offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `limit=${limit}&offset=${offset}`;

    if (projectNode) {
      // from SelectProjectTaskModal
      queryString += `&project_id=${projectNode.value}`;
    } else if (selectedProject && selectedProject !== "") {
      queryString += `&project_id=${selectedProject.value}`;
    }

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getTaskList(queryString);
    const result = await getTaskListOption(response);
    return result;
  };

  const getTaskListOption = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let taskList = [];
        for (let i = 0; i < entries.length; i++) {
          const element = entries[i];
          if (element) {
            taskList.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
        }

        resolve({
          options: taskList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const submitDepartment = (event) => {
    event.preventDefault();
    selectedDepartment?.length && selectedDepartment !== null
      ? localStorage.setItem(
          DEFAULT_DEPARTMENT,
          JSON.stringify(selectedDepartment)
        )
      : localStorage.removeItem(DEFAULT_DEPARTMENT);

    localStorage.setItem(ALLOW_ALL_USERS_IN_SEARCH, searchAllUsers);

    successMsg();
  };

  const submitSkipHourModal = (event) => {
    event.preventDefault();
    localStorage.setItem(SKIP_HOUR_MODAL, skipHourModalOnCheckout);

    successMsg();
  };

  const submitProjectTask = (event) => {
    event.preventDefault();

    selectedProject
      ? localStorage.setItem(DEFAULT_PROJECT, JSON.stringify(selectedProject))
      : localStorage.removeItem(DEFAULT_PROJECT);

    selectedTask
      ? localStorage.setItem(DEFAULT_TASK, JSON.stringify(selectedTask))
      : localStorage.removeItem(DEFAULT_TASK);

    successMsg();
  };

  const addProjectTaskEntry = (project, task) => {
    const currentProjectTaskList = projectTaskList || [];
    currentProjectTaskList.push({
      project,
      task,
    });
    setProjectTaskList(currentProjectTaskList);
    localStorage.setItem(
      DEFAULT_PROJECT_TASK_LIST,
      JSON.stringify(currentProjectTaskList)
    );

    onCloseDialog();
  };

  const deleteEntry = (entryIndex) => {
    const newProjectTaskList = projectTaskList.filter(
      (entry, index) => index !== entryIndex
    );
    setProjectTaskList(newProjectTaskList);
    localStorage.setItem(
      DEFAULT_PROJECT_TASK_LIST,
      JSON.stringify(newProjectTaskList)
    );
  };

  const addProjectTask = () => {
    setOpenProjectTaskModal(true);
  };

  const onCloseDialog = () => {
    setOpenProjectTaskModal(false);
  };

  const successMsg = () => {
    props.enqueueSnackbar(i18next.t("Record created successfully"), {
      variant: "success",
    });
  };

  return (
    <>
      <Card className={classes.root}>
        <form autoComplete="off">
          <CardHeader
            title={i18next.t("Device settings")}
            subheader={i18next.t(
              "These settings are applicable only on this device."
            )}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="div" className={classes.sectionHeading}>
                  {i18next.t(
                    "Select departments to filter users on the login page"
                  )}
                </Typography>
                <FormControl className={classes.inputField}>
                  <MaterialSelect
                    classes={classes}
                    textFieldProps={{
                      label: i18next.t("Department"),
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    isMulti={true}
                    value={selectedDepartment}
                    options={departmentList}
                    onChange={(option) => {
                      setSelectedDepartment(option);
                    }}
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={searchAllUsers}
                      onChange={() => {
                        setSearchAllUsers(!searchAllUsers);
                      }}
                    />
                  }
                  label={i18next.t("Show all users in search")}
                />
                <div className={classes.buttonContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitDepartment}
                  >
                    {i18next.t("Submit")}
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.checkboxContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={skipHourModalOnCheckout}
                        onChange={() => {
                          setSkipHourModalOnCheckout(!skipHourModalOnCheckout);
                        }}
                      />
                    }
                    label={i18next.t("Skip hour modal during checkout action")}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitSkipHourModal}
                  >
                    {i18next.t("Submit")}
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <Typography component="div" className={classes.sectionHeading}>
                  {i18next.t(
                    "Select default project and task. Applicable on the Hours page"
                  )}
                </Typography>
                <FormControl className={classes.inputField}>
                  <MaterialAsyncPaginate
                    defualtOptions
                    classes={classes}
                    textFieldProps={{
                      label: i18next.t("Project"),
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    name="project"
                    value={selectedProject}
                    loadOptions={async (search, loadedOptions) => {
                      return loadProject(search, loadedOptions);
                    }}
                    onChange={(option) => {
                      setSelectedProject(option ? option : "");
                      setSelectedTask("");
                    }}
                    isClearable={true}
                  />
                </FormControl>
                <FormControl className={classes.inputField}>
                  <MaterialAsyncPaginate
                    defualtOptions
                    classes={classes}
                    isDisabled={!(selectedProject && selectedProject !== null)}
                    textFieldProps={{
                      label: i18next.t("Task"),
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    name="task"
                    value={selectedTask}
                    loadOptions={async (search, loadedOptions) => {
                      return loadTaskList(search, loadedOptions);
                    }}
                    onChange={(option) => {
                      setSelectedTask(option ? option : "");
                    }}
                    isClearable={true}
                    cacheUniqs={[selectedProject]}
                  />
                </FormControl>
                <div className={classes.buttonContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitProjectTask}
                  >
                    {i18next.t("Submit")}
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <Typography component="div" className={classes.sectionHeading}>
                  {i18next.t(
                    "Project-task combinations to restrict selection on the hours page"
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={addProjectTask}
                    style={{ marginLeft: 10, whiteSpace: "nowrap" }}
                  >
                    {i18next.t("Add")}
                  </Button>
                </Typography>
                <div className={classes.listContainer}>
                  {projectTaskList &&
                    projectTaskList.map((entry, index) => {
                      return (
                        <Paper className={classes.listCard} key={index}>
                          <div>
                            <Typography className={classes.taskName}>
                              {entry.task.label}
                            </Typography>
                            <Typography className={classes.projectName}>
                              {entry.project.label}
                            </Typography>
                          </div>
                          <IconButton
                            aria-label={i18next.t("Delete")}
                            className={classes.iconButton}
                            onClick={() => deleteEntry(index)}
                            size="small"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Paper>
                      );
                    })}
                  {projectTaskList && projectTaskList.length < 1 && (
                    <Typography>
                      {i18next.t(
                        "No combination of project and task is selected"
                      )}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
      {openProjctTaskModal && (
        <ProjectTaskModal
          open={openProjctTaskModal}
          onCloseDialog={onCloseDialog}
          onSubmit={addProjectTaskEntry}
          loadProject={loadProject}
          loadTaskList={loadTaskList}
        />
      )}
    </>
  );
};

export default withSnackbar(DeviceSetting);
