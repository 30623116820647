import React from "react";
import moment from "moment";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import CreateTaskButton from "./CreateTaskButton";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import GroupViewV2 from "./GroupViewV2/index";
import { green } from "@mui/material/colors";

const styles = (theme) => ({
  droppableView: {
    minHeight: 300,
    height: "100%",
  },
  dayText: {
    fontSize: 20,
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
  },
  dateView: {
    display: "flex",
    alignItems: "baseline",
    textAlign: "end",
    marginTop: -2,
    height: 32,
  },
  dateText: {
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "end",
    color: theme.palette.text.disabled,
    marginLeft: 8,
    fontWeight: 700,
  },
  hourText: {
    fontSize: 14,
  },
  separator: {
    marginLeft: 10,
  },
  dateViewIcons: {
    paddingRight: 4,
  },
  weekRow: {
    display: "flex",
    width: "100%",
  },
  borderCardCol: {
    paddingRight: 10,
    paddingLeft: 10,
    height: "100%",
  },
  weekHeaderContainer: {
    alignItems: "end",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
  },
  weekSubHeaderContainer: {
    alignItems: "end",
    paddingLeft: 12,
    paddingTop: 4,
    height: "auto",
  },
  addButton: {
    display: "flex",
    alignItems: "center",
  },
  bgToday: {
    backgroundColor: theme.palette.hourTodayBackground,
  },
  todayText: {
    textTransform: "uppercase",
    fontSize: 12,
    paddingLeft: 16,
    paddingTop: 0,
    color: theme.palette.text.primary,
  },
  buttonStyle: {
    color: "#fff",
    backgroundColor: "#18B24B",
    fontSize: 12,
    fontWeight: "bold",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
});

class WeekDay extends React.Component {
  hourOnClickHandle = () => {
    this.props.multiProjectModalOpen(this.props.day);
  };

  render = () => {
    const { state, dayName, day, classes } = this.props;

    // use moment locale to give day name in english, for comparing the dayName which is in English
    var enLocale = moment();
    enLocale.locale("en");

    const isLoginUser =
      !state.userId || state.userId === state.loginUserId ? true : false;

    const currentDay = moment(this.props.startDate).clone().day(day);
    const isToday =
      currentDay.format("DD,MM,YY") === moment().format("DD,MM,YY")
        ? true
        : false;

    let allowManualEntry = true;
    if (state.isRestrictManualEntry && state.allowManualEntrySince) {
      const daysTillAllowed = state.allowManualEntrySince + 1;
      if (moment().subtract(daysTillAllowed, "days").isAfter(currentDay)) {
        allowManualEntry = false;
      }
    }

    return (
      <React.Fragment>
        <div
          className={`${classes.borderCardCol} ${
            enLocale.format("dddd") === dayName && isToday
              ? classes.bgToday
              : null
          }`}
        >
          <Typography component="div" className={classes.weekRow}>
            <div>
              <div className={classes.weekHeaderContainer}>
                <div style={{ display: "flex", alignItems: "end" }}>
                  <Typography variant="h2" className={classes.dayText}>
                    {state.hourlist && state.hourlist.columns
                      ? moment().day(day).format("ddd")
                      : ""}
                  </Typography>
                  <Typography className={classes.dateText} variant="h2">
                    {this.props.startDate
                      ? moment(this.props.startDate)
                          .clone()
                          .day(day)
                          .format("DD MMM")
                      : ""}
                  </Typography>
                </div>
              </div>

              <div className={classes.weekSubHeaderContainer}>
                <Typography component="div" className={classes.dateView}>
                  <Typography component="div" className={classes.dateViewIcons}>
                    <WatchLaterIcon
                      sx={{
                        color: "primary.main",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </Typography>
                  <Typography component="div" className={classes.hourText}>
                    {state.hourlist && state.hourlist.columns ? (
                      <DecimalToHoursMin>
                        {state.hourlist.columns[dayName].totalHours}
                      </DecimalToHoursMin>
                    ) : (
                      ""
                    )}
                  </Typography>

                  <Typography component="div" className={classes.separator} />
                  {state.addonModuleAssigned && (
                    <>
                      <Typography
                        component="div"
                        className={classes.dateViewIcons}
                      >
                        <AddToPhotosIcon
                          sx={{
                            color: "secondary.main",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </Typography>
                      <Typography component="div" className={classes.hourText}>
                        {state.hourlist && state.addonObjlist.columns ? (
                          <DecimalToHoursMin>
                            {state.addonObjlist.columns[dayName].totalHours}
                          </DecimalToHoursMin>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </>
                  )}
                </Typography>
              </div>
            </div>

            <div style={{ flexGrow: 1 }}></div>
            <div className={classes.addButton}>
              {allowManualEntry &&
                ((state.rulesModuleAssigned && state.isHourManualEntries) ||
                  (!state.rulesModuleAssigned && state.isCoreManualEntries)) &&
                !(isToday && state.showCheckInCard && isLoginUser) && (
                  <CreateTaskButton
                    onClick={this.hourOnClickHandle}
                    title={"Hour"}
                    id={`create-new-hour-button-${isToday ? "today" : day}`}
                  />
                )}
            </div>
          </Typography>

          <div
            className={classes.droppableView}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <GroupViewV2
              state={state}
              dayName={dayName}
              day={day}
              isToday={isToday}
              onClickCard={(groupId) => {
                if (groupId) {
                  this.props.multiProjectModalOpen(day, groupId);
                }
              }}
              changeState={this.props.changeState}
              multiProjectModalOpen={this.props.multiProjectModalOpen}
              handleResumeCheckInButtonClick={
                this.props.handleResumeCheckInButtonClick
              }
              handleCheckOut={this.props.handleCheckOut}
              isLoginUser={isLoginUser}
              handleBatchGroupApprove={this.props.handleBatchGroupApprove}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(WeekDay);
