import React from "react";
import { Tabs, Tab } from "@mui/material";
import { withStyles } from "@mui/styles";

export const CustomTabs = withStyles({
  root: {
    padding: 4,
    border: 1,
    borderRadius: 6,
  },
  indicator: {
    height: 0,
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const CustomTab = withStyles((theme) => ({
  root: {
    padding: "0px 12px",
    minHeight: 34,
    minWidth: 100,
    borderRadius: 6,
    textTransform: "none",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& > span > svg": {
      marginBottom: "0px !important",
      marginRight: 5,
    },
    "& > span": {
      display: "flex",
      alignItems: "unset",
      flexDirection: "unset",
    },
    "&:not(:last-of-type)": {
      marginRight: 2,
    },
    "&:last-child": {
      marginRight: 2,
    },
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}14`,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}))((props) => <Tab {...props} />);
