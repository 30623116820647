import * as types from "./types";
import { core } from "./core";

export const addHmskTemplate = (data) => {
  return core.API(
    "POST",
    `/hmsk/template`,
    types.ADD_HMSK_TEMPLATE,
    types.ADD_HMSK_TEMPLATE_SUCCESS,
    types.ADD_HMSK_TEMPLATE_FAILURE,
    data
  );
};

export const getHmskTemplates = (queryString) => {
  return core.API(
    "GET",
    `/hmsk/templates${queryString ? `?${queryString}` : ""}`,
    types.GET_HMSK_TEMPLATES,
    types.GET_HMSK_TEMPLATES_SUCCESS,
    types.GET_HMSK_TEMPLATES_FAILURE
  );
};

export const getHmskTemplate = (id) => {
  return core.API(
    "GET",
    `/hmsk/template/${id}`,
    types.GET_HMSK_TEMPLATE,
    types.GET_HMSK_TEMPLATE_SUCCESS,
    types.GET_HMSK_TEMPLATE_FAILURE
  );
};

export const updateHmskTemplate = (id, data) => {
  return core.API(
    "PUT",
    `/hmsk/template/${id}`,
    types.UPDATE_HMSK_TEMPLATE,
    types.UPDATE_HMSK_TEMPLATE_SUCCESS,
    types.UPDATE_HMSK_TEMPLATE_FAILURE,
    data
  );
};

export const deleteHmskTemplate = (id) => {
  return core.API(
    "DELETE",
    `/hmsk/template/${id}`,
    types.DELETE_HMSK_TEMPLATE,
    types.DELETE_HMSK_TEMPLATE_SUCCESS,
    types.DELETE_HMSK_TEMPLATE_FAILURE
  );
};
