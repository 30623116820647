import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
  Toolbar,
  AppBar,
  Avatar,
  Box,
  Divider,
  Stack,
  Button,
  Card,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
// import PageButton from "../../components/PageButton";
import i18next from "i18next";

import {
  validateCompanyTokenApi,
  getStripeSubscriptionApi,
} from "@/services/signin";
import { actions as authActions } from "@/utils/auth";
import { getErrorObject } from "@/utils/api.config";
import { getEmployees } from "@/services/employee";

import InfiniteScroll from "react-infinite-scroll-component";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import getInitials from "@/utils/getInitials";
import { actions } from "@/utils/auth";
import SearchInput from "@/common/SearchInput";
import SettingsIcon from "@mui/icons-material/Settings";

import { setItem } from "@/utils/localStorage";
import getLanguage from "@/utils/getLanguage";
import useIdleRefresh from "@/hooks/useIdleRefresh";

import VrsLogo from "@/assets/images/vrsLogo.svg?react";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  root: {
    width: "100%",
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  errorText: {
    color: theme.palette.error.main,
    padding: "0px 5px 10px 15px",
  },
  appbar: {
    zIndex: 900,
    position: "sticky",
    top: -5,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
  },
  toolbar: {
    position: "sticky",
    top: -5,
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 3),
    height: 74,
  },
  flexGrow: {
    flexGrow: 1,
  },
  icon: {
    marginRight: 8,
  },
  InfiniteScrollContainer: {
    width: "100%",
    padding: 20,
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      padding: 32,
    },
  },
  cardRoot: {
    width: "100%",
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  cardHeader: {
    padding: 12,
  },
  signInButtonContainer: {
    margin: "12px 12px",
  },
  button: {
    width: "100%",
    // margin: "6px 6px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
  },
  svgContainer: {
    borderRadius: "50%",
    height: 48,
    width: 48,
    backgroundColor: theme.palette.grey[400],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconGuest: {
    fontSize: 32,
    color: theme.palette.grey[800],
  },
  avatar: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[400],
  },
  userName: {
    fontSize: 18,
    fontWeight: "bold",
  },
  divider: {
    marginInline: 12,
  },
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  gearButton: {
    marginLeft: 12,
    height: 46,
  },
  gearIcon: {
    fontSize: 30,
    color: theme.palette.text.primary,
  },
  searchInput: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const LANGUAGE_OPTION = [
  { code: "US", label: "English", labelShort: "EN", language: "en" },
  { code: "NO", label: "Norwegian", labelShort: "NO", language: "nb" },
  { code: "DE", label: "German", labelShort: "DE", language: "de" },
  { code: "RO", label: "Romanian", labelShort: "RO", language: "ro" },
  { code: "SE", label: "Swedish", labelShort: "SV", language: "sv" },
  { code: "DK", label: "Danish", labelShort: "DA", language: "da" },
  { code: "FR", label: "French", labelShort: "FR", language: "fr" },
  { code: "TH", label: "Thai", labelShort: "TH", language: "th" },
];

const Main = ({ history, match }) => {
  useIdleRefresh({ idleTimeThreshold: 3600000, checkInterval: 60000 });
  const classes = useStyles();
  const language = getLanguage();

  const [isValidCompany, setCompanyStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const defaultDepartment = localStorage.getItem("default_department")
    ? JSON.parse(localStorage.getItem("default_department"))
    : "";

  const searchForAllUsers = localStorage.getItem("allow_all_users_search")
    ? localStorage.getItem("allow_all_users_search") === "true"
      ? true
      : false
    : false;

  const checkSubscriptionStatus = async (token) => {
    try {
      const response = await getStripeSubscriptionApi(token);
      if (response.data && response.data.status) {
        if (
          response.data.status !== "active" &&
          response.data.status !== "trialing"
        ) {
          setErrorMsg(i18next.t("Your subscription is expired"));
          sessionStorage.setItem("status", "expired");
        } else {
          sessionStorage.removeItem("status");
        }
      }
    } catch (error) {
      setErrorMsg(i18next.t("Your subscription is expired"));
      sessionStorage.setItem("status", "expired");
    }
  };

  const loadUserList = async () => {
    const limit = window.innerWidth > 900 ? 40 : 20;
    let offset = 0;
    if (hasMore) {
      offset = userList.length;
    }

    let queryString = `more_details=true&limit=${limit}&offset=${offset}`;

    const isSearchVal = searchVal && searchVal !== "" ? true : false;
    const ignoreDepartmentFilter =
      isSearchVal && searchForAllUsers ? true : false;

    let departmentIds = [];
    if (
      defaultDepartment &&
      defaultDepartment.length &&
      !ignoreDepartmentFilter
    ) {
      defaultDepartment.forEach((department) => {
        departmentIds = [...departmentIds, department.value];
      });
      const departmentQuery = departmentIds.join(",");
      queryString += `&department_id=${departmentQuery}`;
    }

    if (isSearchVal) {
      queryString += `&name=${searchVal}`;
    }

    const response = await getEmployees(queryString);
    const result = await getUserListOptions(response);
    setUserList([...userList, ...result.options]);
  };

  const getUserListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let userList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];
          userList.push({
            value: element.id.toString(),
            label: element.name,
            pin: element.pin,
            email: element.email,
            admin: element.admin,
            superadmin: element.superadmin,
            language: element.language,
            name: element.name,
            position: element.position ? element.position : "",
            avatar_url: element.avatar_url ? element.avatar_url : "",
            resume_checkin_enabled: element?.resume_checkin_enabled || false,
            resume_checkin_interval: element?.resume_checkin_interval,
          });
        }

        setHasMore(response.data.pagination.has_more_items);
        resolve({
          options: userList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  useEffect(() => {
    setErrorMsg("");

    const validateCompanyToken = async (token) => {
      try {
        const response = await validateCompanyTokenApi(token);
        if (response.data && response.data.id) {
          authActions.saveCompanyToken(token);

          // check subscription status
          if (response.data.stripe_subscription_id) {
            checkSubscriptionStatus(token);
          } else {
            // remove any previous status set, prevent issue on another account login in same tab
            sessionStorage.removeItem("status");
          }

          sessionStorage.setItem("companyId", response.data.id);
          setCompanyStatus(true);
          loadUserList();

          if (response.data.logo_url && response.data.logo_url.length > 0) {
            sessionStorage.setItem("companyLogo", response.data.logo_url);
          }
          if (response.data.name) {
            sessionStorage.setItem("companyName", response.data.name);
          }
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        setErrorMsg(message);
        //console.log(message);
      }
    };

    // http://localhost:3003/token/30d08b19d9984
    if (match && match.params.token) {
      validateCompanyToken(match.params.token);
    } else {
      setErrorMsg(i18next.t("Company token not found"));
    }
    // eslint-disable-next-line
  }, [match]);

  // employeeSearch ...
  const [userList, setUserList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchVal, setSearchVal] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (searchVal !== null && actions.getCompanyToken()) {
      loadUserList();
    }
    // eslint-disable-next-line
  }, [searchVal]);

  // Guest sign in - sign out ...
  const handleSignIn = (event) => {
    event.preventDefault();
    history.push("/guest/check-in");
  };

  const handleSignOut = (event) => {
    event.preventDefault();
    history.push("/guest/check-out");
  };

  // select employee ...
  const addUserDetails = (userDetails) => {
    handleNextClick(userDetails);
  };

  const handleNextClick = (currentUser) => {
    if (currentUser) {
      setItem("userId", parseInt(currentUser.value, 10));
      setItem("userEmail", currentUser.email);
      setItem("userName", currentUser.name);
      setItem("userPosition", currentUser.position);
      setItem("userPin", currentUser.pin ? "true" : "false");
      setItem("userRole", currentUser.admin ? "admin" : "user");
      setItem(
        "userLanguage",
        currentUser.language ? currentUser.language : "browser"
      );
      setItem("avatarUrl", currentUser.avatar_url);
      setItem(
        "isResumeCheckIn",
        currentUser?.resume_checkin_enabled ? "true" : "false"
      );
      setItem(
        "resumeCheckinInterval",
        currentUser?.resume_checkin_interval || 15
      );

      if (currentUser.superadmin) {
        setItem("superadmin", true);
        setItem("userRole", "admin");
      }

      history.push("/login");
    }
  };

  const handleSetting = (event) => {
    event.preventDefault();
    history.push("/login/projectsetting");
  };

  const handleLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const setLanguage = (langCode) => {
    localStorage.setItem("language", langCode);
    handleMenuClose();
    setInterval(() => {
      window.location.reload();
    }, 200);
  };

  const handleSearch = async (e) => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        setUserList([]);
        setSearchVal(e.target.value);
      }, 400)
    );
  };

  const handleLogoClick = () => {
    window.location.reload();
  };

  return (
    <div className={classes.content}>
      <AppBar elevation={0} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Box sx={{ cursor: "pointer" }} onClick={handleLogoClick}>
            <VrsLogo fill={"black"} height={38} />
          </Box>
          <div className={classes.flexGrow} />
          <div className={classes.searchContainer}>
            <SearchInput
              className={classes.searchInput}
              placeholder={i18next.t("Search")}
              type="text"
              variant="outlined"
              onChange={handleSearch}
            />

            <Button
              startIcon={
                <Box>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${LANGUAGE_OPTION.find(
                      (country) => country.language === language
                    )?.code?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${LANGUAGE_OPTION.find(
                      (country) => country.language === language
                    )?.code?.toLowerCase()}.png 2x`}
                    alt=""
                  />
                </Box>
              }
              sx={{ ml: 2, color: "text.primary" }}
              onClick={handleLanguage}
              color="inherit"
            >
              {
                LANGUAGE_OPTION?.find((lang) => lang.language === language)
                  ?.labelShort
              }
            </Button>

            <IconButton
              variant="outlined"
              onClick={handleSetting}
              className={classes.gearButton}
            >
              <SettingsIcon className={classes.gearIcon} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {!errorMsg && isValidCompany ? (
        <div className={classes.InfiniteScrollContainer}>
          <InfiniteScroll
            dataLength={userList.length}
            hasMore={hasMore}
            next={loadUserList}
            loader={<h5>{i18next.t("Loading...")}</h5>}
            scrollableTarget="userListDiv"
          >
            <Grid container spacing={2} className={classes.gridContainer}>
              {!searchVal && (
                <Grid item lg={3} md={4} sm={6} xs={6}>
                  <Card className={classes.cardRoot}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      className={classes.cardHeader}
                    >
                      <div className={classes.svgContainer}>
                        <AssignmentIndOutlinedIcon
                          className={classes.iconGuest}
                        />
                      </div>
                      <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
                        <Typography
                          variant="subtitle2"
                          className={classes.userName}
                          noWrap
                        >
                          {i18next.t("Guest")}
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider className={classes.divider} />
                    <Stack
                      direction="row"
                      className={classes.signInButtonContainer}
                    >
                      <div className={classes.button}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleSignIn}
                          size="large"
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {i18next.t("Check in")}
                        </Button>
                      </div>
                      <div style={{ width: 12 }} />
                      <div className={classes.button}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleSignOut}
                          size="large"
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {i18next.t("Check out")}
                        </Button>
                      </div>
                    </Stack>
                  </Card>
                </Grid>
              )}
              {userList &&
                userList.map((user, index) => {
                  return (
                    <Grid item lg={3} md={4} sm={6} xs={6} key={index}>
                      <Card className={classes.cardRoot}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          key={user.id}
                          className={classes.cardHeader}
                        >
                          <Avatar
                            className={classes.avatar}
                            src={
                              user.avatar_url
                                ? `${baseUrl}${user.avatar_url}`
                                : ""
                            }
                            sx={{ width: 48, height: 48 }}
                          >
                            {getInitials(user.name)}
                          </Avatar>
                          <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
                            <Typography
                              variant="subtitle2"
                              className={classes.userName}
                              noWrap
                            >
                              {user.name ? user.name : ""}
                            </Typography>
                          </Box>
                        </Stack>
                        <Divider className={classes.divider} />
                        <Stack className={classes.signInButtonContainer}>
                          <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            onClick={() => addUserDetails(user)}
                          >
                            {i18next.t("Sign in")}
                          </Button>
                        </Stack>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </InfiniteScroll>
        </div>
      ) : (
        <Typography variant="body1" className={classes.errorText}>
          {errorMsg}
        </Typography>
      )}

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          {LANGUAGE_OPTION?.map((lang) => {
            return (
              <MenuItem
                key={lang.code}
                onClick={() => setLanguage(lang.language)}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${lang.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                <Typography variant="body2" sx={{ mx: 2 }}>
                  {lang.label}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default Main;
