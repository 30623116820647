import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  SvgIcon,
  Card,
  CardContent,
  CardActions,
  TablePagination,
  Checkbox,
  Stack,
  Button,
} from "@mui/material";
import Label from "@/common/Label";
import getInitials from "@/utils/getInitials";
import { getDateFormat } from "@/utils/dateTimeFunctions";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import { actions as authActions } from "@/utils/auth";
import OverlayIndicator from "@/common/OverlayIndicator";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import i18next from "i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    alignItems: "center",
    overflowX: "auto",
    borderRadius: theme.spacing(2),
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  tableRow: {
    cursor: "pointer",
    "& .MuiTableCell-root": {
      fontSize: "14px !important",
    },
    "& .MuiTableCell-root:last-of-type": {
      paddingRight: "24px !important",
    },
  },
  projectText: {
    fontSize: 14,
    maxWidth: "280px",
    width: "100%",
  },
  taskText: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    maxWidth: "280px",
    width: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  },
  avatar: {
    height: 36,
    width: 36,
    marginRight: theme.spacing(1),
    fontSize: 16,
    fontWeight: 700,
  },
  nameText: {
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline",
    },
    minWidth: "150px",
  },
  projectIcon: {
    marginRight: 6,
  },
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: "flex-end",
    borderTop: `0.5px solid ${theme.palette.divider}`,
    "& .MuiTablePagination-root": {
      borderTop: "none",
    },
    "& .MuiToolbar-root": {
      color: theme.palette.text.primary,
      "& p": {
        marginBottom: 0,
      },
    },
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  statusIcon: {
    color: theme.palette.text.secondary,
    marginRight: 5,
  },
  templateName: {
    maxWidth: "140px",
    width: "100%",
  },
}));

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const apiBaseUrl = import.meta.env.VITE_API_V1_URL;

function ChecklistTable({
  checklistRecords,
  totalRecords,
  onLimitChange,
  loadMore,
  history,
}) {
  const classes = useStyles();
  const dateFormat = getDateFormat();

  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    setSelected([]);
  }, [checklistRecords]);

  const handlePageChange = (event, page) => {
    setPage(page);
    loadMore(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    onLimitChange(event.target.value);
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = checklistRecords.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const exportChecklist = () => {
    if (selected && selected.length) {
      const ids = selected.join(",");

      setPdfLoading(true);

      let apiUrl = `/hmsk/check-lists`;

      const fullUrl = apiBaseUrl + apiUrl;

      axios
        .get(fullUrl, {
          headers: {
            Accept: "application/pdf",
            Authorization: `Bearer ${authActions.getAccessToken()}`,
          },
          responseType: "blob",
          params: {
            ids: ids,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `checklist-multiple-${moment().format("DD-MM-YYYY")}.pdf`
          );
          document.body.appendChild(link);
          link.click();

          setPdfLoading(false);
        });
    }
  };

  const numSelected = selected.length;
  const rowCount = checklistRecords.length;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <TableContainer style={{ padding: "16px 8px" }}>
          {selected.length > 0 && (
            <TableSelectedActions
              numSelected={selected.length}
              rowCount={rowCount}
              onSelectAllRows={onSelectAllClick}
              actions={
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ fontSize: 16 }}
                  onClick={exportChecklist}
                >
                  <FileDownloadIcon
                    style={{
                      fontSize: "22px",
                      marginRight: "5px",
                    }}
                  />
                  {i18next.t("Export")}
                </Button>
              }
            />
          )}
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                <TableCell>{i18next.t("Checklist")}</TableCell>
                <TableCell align="left">{i18next.t("Date")}</TableCell>
                <TableCell align="left">
                  {i18next.t("Project and task")}
                </TableCell>
                <TableCell align="left">{i18next.t("Reported by")}</TableCell>
                <TableCell align="left">{i18next.t("Status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checklistRecords.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  classes={classes}
                  history={history}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  dateFormat={dateFormat}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalRecords}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[20, 50]}
          labelRowsPerPage={i18next.t("Rows per page")}
        />
      </CardActions>

      <OverlayIndicator isLoading={pdfLoading} />
    </Card>
  );
}

function Row(props) {
  const { row, history, classes, isSelected, dateFormat, handleClick } = props;
  const isItemSelected = isSelected(row.id);
  const labelId = `table-checkbox-${row.id}`;
  const theme = useTheme();

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={classes.tableRow}
        hover
        onClick={() => {
          history.push(`/hmsk/checkListDetail/${row.id}`);
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event, row.id);
            }}
          />
        </TableCell>
        <TableCell className={classes.nameText}>
          <Typography noWrap component="div" className={classes.templateName}>
            {row.hmsk_template_name}
          </Typography>
        </TableCell>
        <TableCell>{moment(row.created_at).format(dateFormat)}</TableCell>
        <TableCell>
          <Typography component="div" className={classes.nameContainer}>
            <SvgIcon
              className={classes.projectIcon}
              style={{
                fill: `${row.project_color ? row.project_color : "#18B24B"}`,
              }}
            >
              <path d="M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z" />
            </SvgIcon>
            <Typography noWrap className={classes.projectText}>
              {row.project_name}
            </Typography>
          </Typography>
          <Typography noWrap className={classes.taskText}>
            {row.task_name ? row.task_name : i18next.t("Not available")}
          </Typography>
        </TableCell>
        <TableCell className={classes.nameContainer}>
          <Avatar
            className={classes.avatar}
            src={
              row.created_user_avatar_thumb_url
                ? `${baseUrl}${row.created_user_avatar_thumb_url}`
                : null
            }
          >
            {getInitials(row.created_user_name ? row.created_user_name : "")}
          </Avatar>
          {row.created_user_name}
        </TableCell>
        <TableCell>
          <Typography component="div" className={classes.statusContainer}>
            {row?.hmsk_template_collaboration ? (
              <GroupIcon className={classes.statusIcon} />
            ) : (
              <PersonIcon className={classes.statusIcon} />
            )}
            {row?.hmsk_template_collaboration && row.status === "Ongoing" ? (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={"success"}
              >
                {i18next.t("Ongoing")}
              </Label>
            ) : (
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={"default"}
              >
                {i18next.t("Completed")}
              </Label>
            )}
          </Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TableSelectedActions({
  actions,
  rowCount,
  numSelected,
  onSelectAllRows,
  sx,
  ...other
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 2,
        top: 14,
        left: 8,
        right: 8,
        zIndex: 9,
        height: 58,
        borderRadius: 1,
        position: "absolute",
        bgcolor: "primary.lighter",
        ...sx,
      }}
      {...other}
    >
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllRows}
      />

      <Typography
        variant="subtitle1"
        sx={{
          ml: 2,
          display: "flex",
          flexGrow: 1,
          color: "primary.main",
        }}
      >
        {numSelected} {i18next.t("selected")}
      </Typography>

      {actions && actions}
    </Stack>
  );
}

export default ChecklistTable;
