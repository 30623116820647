import React from "react";
import i18next from "i18next";
import withStyles from "@mui/styles/withStyles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  FormControl,
} from "@mui/material";
import { MaterialTimePicker } from "@/common/Pickers";
import CloseIcon from "@mui/icons-material/Close";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import moment from "moment";

const styles = (theme) => ({
  root: {},
  button: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnCancel: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  header: {
    marginBottom: 7,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButtons: {
    padding: "0 10px 10px 10px",
  },
  formControl: {
    marginBottom: 20,
    width: "100%",
  },
  selectInput: {
    display: "flex",
    padding: "0px 0px 0px 10px",
    height: "50px",
    fontSize: "14px",
  },
  /*selectPaper: {
    position: "relative",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
});

class AttendanceAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attendanceInputTime: moment(),
      attendanceTimeError: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.setState({
        attendanceInputTime: moment(),
        attendanceTimeError: false,
      });
    }
  };

  handleDateTimeChange = (date) => {
    this.setState({
      attendanceInputTime: date,
    });

    if (moment(date).isValid()) {
      this.props.onSetAttendanceCheckInTime(date);
      this.setState({
        attendanceTimeError: false,
      });
    } else {
      this.setState({
        attendanceTimeError: true,
      });
    }
  };

  render() {
    const {
      isOpen,
      classes,
      onSubmit,
      onClose,
      attendanceUser,
      loadUserList,
      onSetAttendanceUser,
    } = this.props;

    return (
      <Typography component="div">
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={isOpen}
          onClose={onClose}
        >
          <DialogTitle onClose={onClose} className={classes.header}>
            {i18next.t("Add attendance")}
            <IconButton
              aria-label={i18next.t("Close")}
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <MaterialAsyncPaginate
                classes={classes}
                textFieldProps={{
                  label: i18next.t("User"),
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                defualtOptions
                loadOptions={async (search, loadedOptions) => {
                  return loadUserList(search, loadedOptions);
                }}
                value={attendanceUser}
                onChange={async (selected) => {
                  onSetAttendanceUser(selected);
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <MaterialTimePicker
                label={i18next.t("Check-in time")}
                value={this.state.attendanceInputTime}
                onChangeTime={this.handleDateTimeChange}
              />
            </FormControl>
          </DialogContent>
          <DialogActions className={classes.actionButtons}>
            <Typography component="div">
              <Button className={classes.btnCancel} onClick={onClose}>
                {i18next.t("Cancel")}
              </Button>{" "}
              <Button
                type="submit"
                className={classes.button}
                onClick={onSubmit}
                disabled={!attendanceUser || this.state.attendanceTimeError}
              >
                {i18next.t("Submit")}
              </Button>
            </Typography>
          </DialogActions>
        </Dialog>
      </Typography>
    );
  }
}

export default withStyles(styles)(AttendanceAddModal);
