import useToggleMenubar from "@/hooks/useToggleMenubar";
import performLogout from "@/utils/performLogout";

const Logout = (props) => {
  const { history } = props;

  // close the MenuBar on sign out ...
  const { isMenuOpen, onToggleMenubar } = useToggleMenubar();
  isMenuOpen && onToggleMenubar();

  performLogout(history);

  return null;
};

export default Logout;
