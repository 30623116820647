import createReducer from "./createReducer";
import * as types from "@/redux/actions/types";
import { logout } from "@/utils/api.config";

const initialState = {
  isLoading: false,
  userProfileLoading: false,
  userData: {},
  userList: {},
  userListNotCheckIn: {},
  userUpdateMessage: null,
  addUserMessage: null,
  deleteUserMessage: null,
  error: null,
  forgotPassSuccess: null,
  forgotPassError: null,
  resetPassSuccess: null,
  resetPassError: null,
  changePassSuccess: null,
  changePassError: null,
  userByDepartment: [],
  userByDepartmentLoading: false,
  resendConfirmationPassSuccess: null,
  resendConfirmationPassError: null,
  usersGroupUpdateSuccess: null,
  usersGroupUpdateError: null,
  sendContractMessage: null,
  userContractList: {},
  userContract: {},
  resendUserContractMessage: null,
  timebankMessage: null,
  userTimebankData: {},
  userDefaultData: {},
};

export const profile = createReducer(initialState, {
  [types.RESET_STORE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      error: null,
      resetPassSuccess: null,
      resetPassError: null,
      changePassSuccess: null,
      changePassError: null,
    });
  },
  [types.RESEND_CONFIRMATION](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.RESEND_CONFIRMATION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      resendConfirmationPassSuccess: action.payload,
    });
  },
  [types.RESEND_CONFIRMATION_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      resendConfirmationPassError: action.payload,
    });
  },
  [types.GET_USERS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_USERS](state) {
    return Object.assign({}, state, {
      ...state,
      deleteUserMessage: null,
      isLoading: true,
      error: null,
    });
  },
  [types.DELETE_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      deleteUserMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      deleteUserMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USERS_BY_DEPARTMENT](state) {
    return Object.assign({}, state, {
      ...state,
      error: null,
      userByDepartment: [],
      userByDepartmentLoading: true,
    });
  },
  [types.GET_USERS_BY_DEPARTMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userByDepartment: action.payload,
      userByDepartmentLoading: false,
    });
  },
  [types.GET_USERS_BY_DEPARTMENT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      userByDepartmentLoading: false,
    });
  },
  [types.GET_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      userProfileLoading: true,
      error: null,
      userData: null,
    });
  },
  [types.GET_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userData: action.payload,
      userProfileLoading: false,
    });
  },
  [types.GET_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      userProfileLoading: false,
    });
  },
  [types.PUT_PROFILE_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      userUpdateMessage: null,
      error: null,
    });
  },
  [types.PUT_PROFILE_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userUpdateMessage: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_PROFILE_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      userUpdateMessage: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_USER](state) {
    return Object.assign({}, state, {
      ...state,
      addUserMessage: null,
      isLoading: true,
      error: null,
    });
  },
  [types.ADD_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addUserMessage: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      addUserMessage: action.payload,
      isLoading: false,
    });
  },
  [types.FORGOT_PASSWORD](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      forgotPassSuccess: null,
      forgotPassError: null,
    });
  },
  [types.FORGOT_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      forgotPassSuccess: action.payload,
      isLoading: false,
    });
  },
  [types.FORGOT_PASSWORD_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      forgotPassError: action.payload,
      isLoading: false,
    });
  },
  [types.RESET_PASSWORD](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      resetPassSuccess: null,
      resetPassError: null,
    });
  },
  [types.RESET_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      resetPassSuccess: action.payload,
      resetPassError: null,
      isLoading: false,
    });
  },
  [types.RESET_PASSWORD_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      resetPassSuccess: null,
      resetPassError: action.payload,
      isLoading: false,
    });
  },
  [types.CHANGE_PASSWORD](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      changePassSuccess: null,
      changePassError: null,
    });
  },
  [types.CHANGE_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      changePassSuccess: action.payload,
      changePassError: null,
      isLoading: false,
    });
  },
  [types.CHANGE_PASSWORD_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      changePassSuccess: null,
      changePassError: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_USERS_GROUP](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      usersGroupUpdateSuccess: null,
      usersGroupUpdateError: null,
    });
  },
  [types.UPDATE_USERS_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      usersGroupUpdateSuccess: action.payload,
      usersGroupUpdateError: null,
      isLoading: false,
    });
  },
  [types.UPDATE_USERS_GROUP_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      usersGroupUpdateSuccess: null,
      usersGroupUpdateError: action.payload,
      isLoading: false,
    });
  },
  [types.ENABLE_USER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      userUpdateMessage: null,
      error: null,
    });
  },
  [types.ENABLE_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userUpdateMessage: action.payload,
      isLoading: false,
    });
  },
  [types.ENABLE_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      userUpdateMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_NOT_CHECKIN_USERS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_NOT_CHECKIN_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userListNotCheckIn: action.payload,
      isLoading: false,
    });
  },
  [types.GET_NOT_CHECKIN_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.CHANGE_PASSWORD_BY_ADMIN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      changePassSuccess: null,
      changePassError: null,
    });
  },
  [types.CHANGE_PASSWORD_BY_ADMIN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      changePassSuccess: action.payload,
      changePassError: null,
      isLoading: false,
    });
  },
  [types.CHANGE_PASSWORD_BY_ADMIN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      changePassSuccess: null,
      changePassError: action.payload,
      isLoading: false,
    });
  },
  [types.SEND_USER_CONTRACT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      sendContractMessage: null,
      error: null,
    });
  },
  [types.SEND_USER_CONTRACT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      sendContractMessage: action.payload,
      isLoading: false,
    });
  },
  [types.SEND_USER_CONTRACT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      sendContractMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_CONTRACTS_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      userContractList: {},
      error: null,
    });
  },
  [types.GET_USER_CONTRACTS_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userContractList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_CONTRACTS_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_CONTRACT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      userContract: {},
      error: null,
    });
  },
  [types.GET_USER_CONTRACT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userContract: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_CONTRACT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_USER_CONTRACT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      sendContractMessage: null,
      error: null,
    });
  },
  [types.UPDATE_USER_CONTRACT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      sendContractMessage: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_USER_CONTRACT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      sendContractMessage: action.payload,
      isLoading: false,
    });
  },
  [types.RESEND_USER_CONTRACT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      resendUserContractMessage: null,
      error: null,
    });
  },
  [types.RESEND_CONTRACT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      resendUserContractMessage: action.payload,
      isLoading: false,
    });
  },
  [types.RESEND_CONTRACT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      resendUserContractMessage: action.payload,
      isLoading: false,
    });
  },

  [types.ADD_USER_TIMEBANK](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      timebankMessage: null,
      error: null,
    });
  },
  [types.ADD_USER_TIMEBANK_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      timebankMessage: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_USER_TIMEBANK_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      timebankMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_TIMEBANK_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      userTimebankData: {},
      error: null,
    });
  },
  [types.GET_USER_TIMEBANK_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userTimebankData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_TIMEBANK_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_USER_TIMEBANK](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      timebankMessage: null,
      error: null,
    });
  },
  [types.DELETE_USER_TIMEBANK_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      timebankMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_USER_TIMEBANK_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      timebankMessage: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_USER_TIMEBANK](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      timebankMessage: null,
      error: null,
    });
  },
  [types.UPDATE_USER_TIMEBANK_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      timebankMessage: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_USER_TIMEBANK_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      timebankMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_DEFAULT_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      userDefaultData: null,
    });
  },
  [types.GET_USER_DEFAULT_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userDefaultData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_DEFAULT_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
});
