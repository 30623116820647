import createReducer from "./createReducer";
import * as types from "@/redux/actions/types";
const initialState = {
  filters: {
    project: null,
    user: null,
    deviance: null,
    category: null,
    checkList: null,
    planning: null,
    hours: null,
    holidays: null,
    attendance: null,
    storage: null,
    employeeReport: null,
    planSchedule: null,
  },
};

export const commonReducer = createReducer(initialState, {
  [types.FILTER_DATA](state, action) {
    const { page, params } = action.payload;
    if (params) {
      return Object.assign({}, state, {
        ...state,
        filters: {
          ...state.filters,
          [page]: { ...state.filters[page], ...params },
        },
      });
    } else {
      return Object.assign({}, state, {
        ...state,
        filters: {
          ...state.filters,
          [page]: null,
        },
      });
    }
  },
});
