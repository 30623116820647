import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import QuestionAnswerBlock from "../../CheckList/components/questionAnswerBlock";
import { useSnackbar } from "notistack";
import moment from "moment";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  mb: {
    marginBottom: "1rem",
  },
  header: {
    marginBottom: 12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButtons: {
    padding: "0 10px 10px 10px",
  },
  headerTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  projectName: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginBottom: 0,
    lineHeight: 1.4,
    fontSize: 16,
  },
  headerLine: {
    marginBottom: 4,
    display: "flex",
    color: theme.palette.text.primary,
    verticalAlign: "baseline",
  },
}));

const ChecklistModal = (props) => {
  const {
    checkList,
    hmskTemplateReducer,
    hmskCheckListReducer,
    actions,
    handleClose,
    isOpen,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const userId = getItem("userId") ? Number(getItem("userId")) : null;

  const [state, setState] = useState({
    project: null,
    answers: [],
    hmsk_template_id: null,
    counter: 0,
    checkResponse: false,
    currentProjectId: null,
    currentProjectName: null,
    currentTaskId: null,
    currentTaskName: null,
    userId: userId,
  });

  useEffect(() => {
    if (checkList && checkList.length > 0) {
      const template_id = checkList[0].template_id;
      actions.getHmskTemplate(template_id);
      const assignedToTask = checkList[0].task_id ? true : false;
      setState((prevState) => ({
        ...prevState,
        counter: 0,
        currentProjectId: checkList[0].project_id,
        currentProjectName: checkList[0].project_name,
        currentTaskId: assignedToTask ? checkList[0].task_id : null,
        currentTaskName: assignedToTask ? checkList[0].task_name : null,
      }));
    }
  }, [checkList, actions]);

  useEffect(() => {
    if (
      hmskTemplateReducer &&
      hmskTemplateReducer.hmskTemplateData &&
      hmskTemplateReducer.hmskTemplateData.questions &&
      hmskTemplateReducer.hmskTemplateData.questions.length > 0
    ) {
      let questionLength = hmskTemplateReducer.hmskTemplateData.questions;
      let data = questionLength.map(() => ({}));
      setState((prevState) => ({
        ...prevState,
        answers: Object.assign([], data),
      }));
    }

    if (state.checkResponse) {
      if (
        hmskCheckListReducer &&
        hmskCheckListReducer.errorMessage &&
        hmskCheckListReducer.errorMessage.response &&
        hmskCheckListReducer.errorMessage.response.message
      ) {
        setState((prevState) => ({ ...prevState, checkResponse: false }));
        enqueueSnackbar(hmskCheckListReducer.errorMessage.response.message, {
          variant: "error",
        });
      }
    }
  }, [
    hmskTemplateReducer,
    hmskCheckListReducer,
    state.checkResponse,
    enqueueSnackbar,
  ]);

  const handleSubmit = async () => {
    const questions =
      hmskTemplateReducer.hmskTemplateData &&
      hmskTemplateReducer.hmskTemplateData.questions;

    const answers = state.answers.map((answerData, index) => {
      let images = [];
      const answerObj = { ...answerData };

      if (answerObj && answerObj.images && answerObj.images.length > 0) {
        images = answerObj.images.map((imageData) => imageData.image_id);
      }
      answerObj["images"] = images;

      answerObj["answer_type"] = questions[index].answer_type;
      answerObj["template_question"] = questions[index].question;

      if (questions[index].answer_type === "users") {
        const selectedUsers = answerObj["answer_value"];
        const user_ids = selectedUsers
          ? selectedUsers.map((user) => user.value)
          : [];
        answerObj["answer_value"] = user_ids.join(",");
      }

      if (questions[index].answer_type === "item_types") {
        const selectedItemTypes = answerObj["answer_value"];
        const item_type_ids = selectedItemTypes
          ? selectedItemTypes.map((item) => item.value)
          : [];
        answerObj["answer_value"] = item_type_ids.join(",");
      }

      if (questions[index].answer_type === "date") {
        answerObj["answer_value"] = moment(answerObj["answer_value"]).format(
          "YYYY-MM-DD"
        );
      }

      if (questions[index].answer_type === "date_time") {
        answerObj["answer_value"] =
          moment(answerObj["answer_value"])
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      }

      return answerObj;
    });

    let data = {
      hmsk_template_id: hmskTemplateReducer.hmskTemplateData.id,
      answers: answers,
      company_id: hmskTemplateReducer.hmskTemplateData.company_id,
      project_id: state.currentProjectId,
    };

    if (state.currentTaskId) {
      data["task_id"] = state.currentTaskId;
    }

    await addCheckList(data);

    if (state.counter < checkList.length) {
      const nextIndex = state.counter + 1;
      const nextTemplate = checkList[nextIndex];
      if (nextTemplate && nextTemplate.template_id) {
        actions.getHmskTemplate(nextTemplate.template_id);
        const assignedToTask = nextTemplate.task_id ? true : false;
        setState((prevState) => ({
          ...prevState,
          counter: nextIndex,
          currentProjectId: nextTemplate.project_id,
          currentProjectName: nextTemplate.project_name,
          currentTaskId: assignedToTask ? nextTemplate.task_id : null,
          currentTaskName: assignedToTask ? nextTemplate.task_name : null,
        }));
      } else {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const onValueChange = (index, array, key, value) => {
    setState((prevState) => {
      let data = [...prevState[array]];

      if (key && value) {
        data[index][key] = value;
        data[index]["updated_by"] = state.userId;
        data[index]["date_time"] =
          moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      } else {
        data[index] = {};
      }
      return { ...prevState, [array]: data };
    });
  };

  const addCheckList = async (data) => {
    await actions.addHmskCheckList(data);
    setState((prevState) => ({ ...prevState, checkResponse: true }));
  };

  let disabled = state.answers.some((answerData) => !answerData.answer_value);

  return (
    <Typography component="div">
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle onClose={handleClose} className={classes.header}>
          <Typography className={classes.headerLine}>
            <h6
              className={classes.projectName}
            >{`${i18next.t("Checklist")}:\u00A0`}</h6>
            <span className={classes.headerTitle}>
              {hmskTemplateReducer?.hmskTemplateData?.name}
            </span>
          </Typography>
          <Typography className={classes.headerLine}>
            <h6
              className={classes.projectName}
            >{`${i18next.t("Project")}:\u00A0`}</h6>
            <span className={classes.headerTitle}>
              {state.currentProjectName}
            </span>
          </Typography>
          {state.currentTaskName && (
            <Typography className={classes.headerLine}>
              <h6
                className={classes.projectName}
              >{`${i18next.t("Task")}:\u00A0`}</h6>
              <span className={classes.headerTitle}>
                {state.currentTaskName}
              </span>
            </Typography>
          )}
        </DialogTitle>
        <DialogContent style={{ padding: "0 34px 0 34px" }}>
          <Typography component="div" className={classes.listContainer}>
            {hmskTemplateReducer?.hmskTemplateData?.questions?.length > 0 ? (
              hmskTemplateReducer.hmskTemplateData.questions.map(
                (question, index) => (
                  <Typography key={index} component="div">
                    <QuestionAnswerBlock
                      disabled={!state.answers || !state.answers.length}
                      changeState={setState}
                      index={index}
                      question={question}
                      answers={state.answers}
                      onValueChange={onValueChange}
                      hmskCheckListReducer={hmskCheckListReducer}
                      actions={actions}
                      isPreviewOff={true}
                    />
                  </Typography>
                )
              )
            ) : (
              <Typography variant="body1">{i18next.t("Loading...")}</Typography>
            )}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <Typography component="div">
            <Button
              type="submit"
              className={classes.button}
              onClick={handleSubmit}
              disabled={disabled}
            >
              {i18next.t("Submit")}
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
    </Typography>
  );
};

export default ChecklistModal;
