export const GET_PROJECT_HOUR_TYPE = "profile/GET_PROJECT_HOUR_TYPE";
export const GET_PROJECT_HOUR_TYPE_SUCCESS =
  "profile/GET_PROJECT_HOUR_TYPE_SUCCESS";
export const GET_PROJECT_HOUR_TYPE_FAILURE =
  "profile/GET_PROJECT_HOUR_TYPE_FAILURE";

export const GET_PROJECT_ADDON_TYPE = "profile/GET_PROJECT_ADDON_TYPE";
export const GET_PROJECT_ADDON_TYPE_SUCCESS =
  "profile/GET_PROJECT_ADDON_TYPE_SUCCESS";
export const GET_PROJECT_ADDON_TYPE_FAILURE =
  "profile/GET_PROJECT_ADDON_TYPE_FAILURE";

export const RESEND_CONFIRMATION = "profile/RESEND_CONFIRMATION";
export const RESEND_CONFIRMATION_SUCCESS =
  "profile/RESEND_CONFIRMATION_SUCCESS";
export const RESEND_CONFIRMATION_FAILURE =
  "profile/RESEND_CONFIRMATION_FAILURE";

export const GET_USERS = "profile/GET_USERS";
export const GET_USERS_SUCCESS = "profile/GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "profile/GET_USERS_FAILURE";

export const GET_NOT_CHECKIN_USERS = "attendance/GET_NOT_CHECKIN_USERS";
export const GET_NOT_CHECKIN_USERS_SUCCESS =
  "attendance/GET_NOT_CHECKIN_USERS_SUCCESS";
export const GET_NOT_CHECKIN_USERS_FAILURE =
  "attendance/GET_NOT_CHECKIN_USERS_FAILURE";

export const GET_USERS_HOURS = "profile/GET_USERS_HOURS";
export const GET_USERS_HOURS_SUCCESS = "profile/GET_USERS_HOURS_SUCCESS";
export const GET_USERS_HOURS_FAILURE = "profile/GET_USERS_HOURS_FAILURE";

export const GET_HOUR_TYPE_SUMMARY = "profile/GET_HOUR_TYPE_SUMMARY";
export const GET_HOUR_TYPE_SUMMARY_SUCCESS =
  "profile/GET_HOUR_TYPE_SUMMARY_SUCCESS";
export const GET_HOUR_TYPE_SUMMARY_FAILURE =
  "profile/GET_HOUR_TYPE_SUMMARY_FAILURE";

export const GET_USERS_ADDONS = "profile/GET_USERS_ADDONS";
export const GET_USERS_ADDONS_SUCCESS = "profile/GET_USERS_ADDONS_SUCCESS";
export const GET_USERS_ADDONS_FAILURE = "profile/GET_USERS_ADDONS_FAILURE";

export const GET_ADDON_MEASUREMENTS = "addontypes/GET_ADDON_MEASUREMENTS";
export const GET_ADDON_MEASUREMENTS_SUCCESS =
  "addontypes/GET_ADDON_MEASUREMENTS_SUCCESS";
export const GET_ADDON_MEASUREMENTS_FAILURE =
  "addontypes/GET_ADDON_MEASUREMENTS_FAILURE";
export const CREATE_ADDON_MEASUREMENT = "addontypes/CREATE_ADDON_MEASUREMENT";
export const CREATE_ADDON_MEASUREMENT_SUCCESS =
  "addontypes/CREATE_ADDON_MEASUREMENT_SUCCESS";
export const CREATE_ADDON_MEASUREMENT_FAILURE =
  "addontypes/CREATE_ADDON_MEASUREMENT_FAILURE";
export const GET_FIXED_ADDON_TYPES = "addontypes/GET_FIXED_ADDON_TYPES";
export const GET_FIXED_ADDON_TYPES_SUCCESS =
  "addontypes/GET_FIXED_ADDON_TYPES_SUCCESS";
export const GET_FIXED_ADDON_TYPES_FAILURE =
  "addontypes/GET_FIXED_ADDON_TYPES_FAILURE";
export const CREATE_FIXED_ADDON_TYPE = "addontypes/CREATE_FIXED_ADDON_TYPE";
export const CREATE_FIXED_ADDON_TYPE_SUCCESS =
  "addontypes/CREATE_FIXED_ADDON_TYPE_SUCCESS";
export const CREATE_FIXED_ADDON_TYPE_FAILURE =
  "addontypes/CREATE_FIXED_ADDON_TYPE_FAILURE";
export const DELETE_FIXED_ADDON_TYPE = "addontypes/DELETE_FIXED_ADDON_TYPE";
export const DELETE_FIXED_ADDON_TYPE_SUCCESS =
  "addontypes/DELETE_FIXED_ADDON_TYPE_SUCCESS";
export const DELETE_FIXED_ADDON_TYPE_FAILURE =
  "addontypes/DELETE_FIXED_ADDON_TYPE_FAILURE";
export const PUT_FIXED_ADDON_TYPE = "addontypes/PUT_FIXED_ADDON_TYPE";
export const PUT_FIXED_ADDON_TYPE_SUCCESS =
  "addontypes/PUT_FIXED_ADDON_TYPE_SUCCESS";
export const PUT_FIXED_ADDON_TYPE_FAILURE =
  "addontypes/PUT_FIXED_ADDON_TYPE_FAILURE";

export const GET_FIXED_ADDONS = "addon/GET_FIXED_ADDONS";
export const GET_FIXED_ADDONS_SUCCESS = "addon/GET_FIXED_ADDONS_SUCCESS";
export const GET_FIXED_ADDONS_FAILURE = "addon/GET_FIXED_ADDONS_FAILURE";

export const GET_ADDON_TYPE_SUMMARY = "profile/GET_ADDON_TYPE_SUMMARY";
export const GET_ADDON_TYPE_SUMMARY_SUCCESS =
  "profile/GET_ADDON_TYPE_SUMMARY_SUCCESS";
export const GET_ADDON_TYPE_SUMMARY_FAILURE =
  "profile/GET_ADDON_TYPE_SUMMARY_FAILURE";

export const GET_FIXED_ADDON_TYPE_SUMMARY =
  "profile/GET_FIXED_ADDON_TYPE_SUMMARY";
export const GET_FIXED_ADDON_TYPE_SUMMARY_SUCCESS =
  "profile/GET_FIXED_ADDON_TYPE_SUMMARY_SUCCESS";
export const GET_FIXED_ADDON_TYPE_SUMMARY_FAILURE =
  "profile/GET_FIXED_ADDON_TYPE_SUMMARY_FAILURE";

export const DELETE_USERS = "profile/DELETE_USERS";
export const DELETE_USERS_SUCCESS = "profile/DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAILURE = "profile/DELETE_USERS_FAILURE";

export const GET_USERS_BY_DEPARTMENT = "profile/GET_USERS_BY_DEPARTMENT";
export const GET_USERS_BY_DEPARTMENT_SUCCESS =
  "profile/GET_USERS_BY_DEPARTMENT_SUCCESS";
export const GET_USERS_BY_DEPARTMENT_FAILURE =
  "profile/GET_USERS_BY_DEPARTMENT_FAILURE";

export const GET_USER_DEFAULT_DATA = "profile/GET_USER_DEFAULT_DATA";
export const GET_USER_DEFAULT_DATA_SUCCESS =
  "profile/GET_USER_DEFAULT_DATA_SUCCESS";
export const GET_USER_DEFAULT_DATA_FAILURE =
  "profile/GET_USER_DEFAULT_DATA_FAILURE";

export const GET_DATA = "profile/GET_DATA";
export const GET_DATA_SUCCESS = "profile/GET_DATA_SUCCESS";
export const GET_DATA_FAILURE = "profile/GET_DATA_FAILURE";

export const PUT_PROFILE_DATA = "profile/PUT_PROFILE_DATA";
export const PUT_PROFILE_DATA_SUCCESS = "profile/PUT_PROFILE_DATA_SUCCESS";
export const PUT_PROFILE_DATA_FAILURE = "profile/PUT_PROFILE_DATA_FAILURE";

export const GET_HOURS = "profile/GET_HOURS";
export const GET_HOURS_SUCCESS = "profile/GET_HOURS_SUCCESS";
export const GET_HOURS_FAILURE = "profile/GET_HOURS_FAILURE";

export const GET_SINGLE_DAY_HOURS = "hour/GET_SINGLE_DAY_HOURS";
export const GET_SINGLE_DAY_HOURS_SUCCESS = "hour/GET_SINGLE_DAY_HOURS_SUCCESS";
export const GET_SINGLE_DAY_HOURS_FAILURE = "hour/GET_SINGLE_DAY_HOURS_FAILURE";

export const GET_HOUR_TYPES = "hourtypes/GET_HOUR_TYPES";
export const GET_HOUR_TYPES_SUCCESS = "hourtypes/GET_HOUR_TYPES_SUCCESS";
export const GET_HOUR_TYPES_FAILURE = "hourtypes/GET_HOUR_TYPES_FAILURE";

export const GET_OPTIONAL_HOUR_TYPES = "hourtypes/GET_OPTIONAL_HOUR_TYPES";
export const GET_OPTIONAL_HOUR_TYPES_SUCCESS =
  "hourtypes/GET_OPTIONAL_HOUR_TYPES_SUCCESS";
export const GET_OPTIONAL_HOUR_TYPES_FAILURE =
  "hourtypes/GET_OPTIONAL_HOUR_TYPES_FAILURE";

export const SET_OPTIONAL_HOUR_TYPE = "hour/SET_OPTIONAL_HOUR_TYPE";
export const SET_OPTIONAL_HOUR_TYPE_SUCCESS =
  "hour/SET_OPTIONAL_HOUR_TYPE_SUCCESS";
export const SET_OPTIONAL_HOUR_TYPE_FAILURE =
  "hour/SET_OPTIONAL_HOUR_TYPE_FAILURE";

export const GET_HOUR_TYPE = "profile/GET_HOUR_TYPE";
export const GET_HOUR_TYPE_SUCCESS = "profile/GET_HOUR_TYPE_SUCCESS";
export const GET_HOUR_TYPE_FAILURE = "profile/GET_HOUR_TYPE_FAILURE";

export const CREATE_HOUR_TYPES = "hourtypes/CREATE_HOUR_TYPES";
export const CREATE_HOUR_TYPES_SUCCESS = "hourtypes/CREATE_HOUR_TYPES_SUCCESS";
export const CREATE_HOUR_TYPES_FAILURE = "hourtypes/CREATE_HOUR_TYPES_FAILURE";

export const DELETE_HOUR_TYPES = "hourtypes/DELETE_HOUR_TYPES";
export const DELETE_HOUR_TYPES_SUCCESS = "hourtypes/DELETE_HOUR_TYPES_SUCCESS";
export const DELETE_HOUR_TYPES_FAILURE = "hourtypes/DELETE_HOUR_TYPES_FAILURE";

export const PUT_HOUR_TYPES = "hourtypes/PUT_HOUR_TYPES";
export const PUT_HOUR_TYPES_SUCCESS = "hourtypes/PUT_HOUR_TYPES_SUCCESS";
export const PUT_HOUR_TYPES_FAILURE = "hourtypes/PUT_HOUR_TYPES_FAILURE";

export const GET_DEPARTMENTS_HOUR_TYPES =
  "hourtypes/GET_DEPARTMENTS_HOUR_TYPES";
export const GET_DEPARTMENTS_HOUR_TYPES_SUCCESS =
  "hourtypes/GET_DEPARTMENTS_HOUR_TYPES_SUCCESS";
export const GET_DEPARTMENTS_HOUR_TYPES_FAILURE =
  "hourtypes/GET_DEPARTMENTS_HOUR_TYPES_FAILURE";

export const ADD_USER = "profile/ADD_USER";
export const ADD_USER_SUCCESS = "profile/ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "profile/ADD_USER_FAILURE";

export const ENABLE_USER = "profile/ENABLE_USER";
export const ENABLE_USER_SUCCESS = "profile/ENABLE_USER_SUCCESS";
export const ENABLE_USER_FAILURE = "profile/ENABLE_USER_FAILURE";

export const ATTACH_USER = "profile/ATTACH_USER";
export const ATTACH_USER_SUCCESS = "profile/ATTACH_USER_SUCCESS";
export const ATTACH_USER_FAILURE = "profile/ATTACH_USER_FAILURE";

export const GET_ATTACH_USER = "profile/GET_ATTACH_USER";
export const GET_ATTACH_USER_SUCCESS = "profile/GET_ATTACH_USER_SUCCESS";
export const GET_ATTACH_USER_FAILURE = "profile/GET_ATTACH_USER_FAILURE";

export const GET_DEPARTMENTS = "profile/GET_DEPARTMENTS";
export const GET_DEPARTMENTS_SUCCESS = "profile/GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "profile/GET_DEPARTMENTS_FAILURE";

export const GET_DEPARTMENT = "profile/GET_DEPARTMENT";
export const GET_DEPARTMENT_SUCCESS = "profile/GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_FAILURE = "profile/GET_DEPARTMENT_FAILURE";

export const ADD_DEPARTMENT = "profile/ADD_DEPARTMENT";
export const ADD_DEPARTMENT_SUCCESS = "profile/ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAILURE = "profile/ADD_DEPARTMENT_FAILURE";

export const UPDATE_DEPARTMENT = "profile/UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "profile/UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "profile/UPDATE_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT = "profile/DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_SUCCESS = "profile/DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "profile/DELETE_DEPARTMENT_FAILURE";

export const FORGOT_PASSWORD = "profile/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "profile/FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "profile/FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD = "profile/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "profile/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "profile/RESET_PASSWORD_FAILURE";

export const CHANGE_PASSWORD = "profile/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "profile/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "profile/CHANGE_PASSWORD_FAILURE";

export const CHANGE_PASSWORD_BY_ADMIN = "profile/CHANGE_PASSWORD_BY_ADMIN";
export const CHANGE_PASSWORD_BY_ADMIN_SUCCESS =
  "profile/CHANGE_PASSWORD_BY_ADMIN_SUCCESS";
export const CHANGE_PASSWORD_BY_ADMIN_FAILURE =
  "profile/CHANGE_PASSWORD_BY_ADMIN_FAILURE";

export const UPDATE_USERS_GROUP = "profile/UPDATE_USERS_GROUP";
export const UPDATE_USERS_GROUP_SUCCESS = "profile/UPDATE_USERS_GROUP_SUCCESS";
export const UPDATE_USERS_GROUP_FAILURE = "profile/UPDATE_USERS_GROUP_FAILURE";

export const GET_PROJECTS = "project/GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "project/GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "project/GET_PROJECTS_FAILURE";

export const GET_PROJECT = "project/GET_PROJECT";
export const GET_PROJECT_SUCCESS = "project/GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "project/GET_PROJECT_FAILURE";

export const GET_USES_BY_PROJECT = "project/GET_USES_BY_PROJECT";
export const GET_USES_BY_PROJECT_SUCCESS =
  "project/GET_USES_BY_PROJECT_SUCCESS";
export const GET_USES_BY_PROJECT_FAILURE =
  "project/GET_USES_BY_PROJECT_FAILURE";

export const ADD_PROJECT = "project/ADD_PROJECT";
export const ADD_PROJECT_SUCCESS = "project/ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAILURE = "project/ADD_PROJECT_FAILURE";

export const DELETE_PROJECTS = "project/DELETE_PROJECTS";
export const DELETE_PROJECTS_SUCCESS = "project/DELETE_PROJECTS_SUCCESS";
export const DELETE_PROJECTS_FAILURE = "project/DELETE_PROJECTS_FAILURE";

export const PUT_PROJECTS_DATA = "project/PUT_PROJECTS_DATA";
export const PUT_PROJECTS_DATA_SUCCESS = "project/PUT_PROJECTS_DATA_SUCCESS";
export const PUT_PROJECTS_DATA_FAILURE = "project/PUT_PROJECTS_DATA_FAILURE";

export const GET_TASKS = "task/GET_TASKS";
export const GET_TASKS_SUCCESS = "task/GET_TASKS_SUCCESS";
export const GET_TASKS_FAILURE = "task/GET_TASKS_FAILURE";

export const GET_ALL_TASKS = "task/GET_ALL_TASKS";
export const GET_ALL_TASKS_SUCCESS = "task/GET_ALL_TASKS_SUCCESS";
export const GET_ALL_TASKS_FAILURE = "task/GET_ALL_TASKS_FAILURE";

export const ADD_TASK = "task/ADD_TASK";
export const ADD_TASK_SUCCESS = "task/ADD_TASK_SUCCESS";
export const ADD_TASK_FAILURE = "task/ADD_TASK_FAILURE";

export const DELETE_TASK = "task/DELETE_TASK";
export const DELETE_TASK_SUCCESS = "task/DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE = "task/DELETE_TASK_FAILURE";

export const PUT_TASK_DATA = "task/PUT_TASK_DATA";
export const PUT_TASK_DATA_SUCCESS = "task/PUT_TASK_DATA_SUCCESS";
export const PUT_TASK_DATA_FAILURE = "task/PUT_TASK_DATA_FAILURE";

export const GET_CUSTOMERS = "customer/GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "customer/GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "customer/GET_CUSTOMERS_FAILURE";

export const ADD_CUSTOMER = "customer/ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "customer/ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "customer/ADD_CUSTOMER_FAILURE";

export const PUT_CUSTOMER = "customer/PUT_CUSTOMER";
export const PUT_CUSTOMER_SUCCESS = "customer/PUT_CUSTOMER_SUCCESS";
export const PUT_CUSTOMER_FAILURE = "customer/PUT_CUSTOMER_FAILURE";

export const DELETE_CUSTOMER = "customer/DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "customer/DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "customer/DELETE_CUSTOMER_FAILURE";

export const COMPANY_MODULES = "customer/COMPANY_MODULES";
export const COMPANY_MODULES_SUCCESS = "customer/COMPANY_MODULES_SUCCESS";
export const COMPANY_MODULES_FAILURE = "customer/COMPANY_MODULES_FAILURE";

export const GET_COMPANY_SETTINGS = "customer/GET_COMPANY_SETTINGS";
export const GET_COMPANY_SETTINGS_SUCCESS =
  "customer/GET_COMPANY_SETTINGS_SUCCESS";
export const GET_COMPANY_SETTINGS_FAILURE =
  "customer/GET_COMPANY_SETTINGS_FAILURE";

export const PUT_COMPANY_SETTINGS = "customer/PUT_COMPANY_SETTINGS";
export const PUT_COMPANY_SETTINGS_SUCCESS =
  "customer/PUT_COMPANY_SETTINGS_SUCCESS";
export const PUT_COMPANY_SETTINGS_FAILURE =
  "customer/PUT_COMPANY_SETTINGS_FAILURE";

export const ADD_COMPANY_SETTING_VALUE = "customer/ADD_COMPANY_SETTING_VALUE";
export const ADD_COMPANY_SETTING_VALUE_SUCCESS =
  "customer/ADD_COMPANY_SETTING_VALUE_SUCCESS";
export const ADD_COMPANY_SETTING_VALUE_FAILURE =
  "customer/ADD_COMPANY_SETTING_VALUE_FAILURE";

export const CALL_COMPANY_SETTING_API = "customer/CALL_COMPANY_SETTING_API";
export const CALL_COMPANY_SETTING_API_SUCCESS =
  "customer/CALL_COMPANY_SETTING_API_SUCCESS";
export const CALL_COMPANY_SETTING_API_FAILURE =
  "customer/CALL_COMPANY_SETTING_API_FAILURE";

export const GET_LAST_FETCH_TRANSACTIONS =
  "company/GET_LAST_FETCH_TRANSACTIONS";
export const GET_LAST_FETCH_TRANSACTIONS_SUCCESS =
  "company/GET_LAST_FETCH_TRANSACTIONS_SUCCESS";
export const GET_LAST_FETCH_TRANSACTIONS_FAILURE =
  "company/GET_LAST_FETCH_TRANSACTIONS_FAILURE";

export const PUT_COMPANY_SETTING_VALUE = "customer/PUT_COMPANY_SETTING_VALUE";
export const PUT_COMPANY_SETTING_VALUE_SUCCESS =
  "customer/PUT_COMPANY_SETTING_VALUE_SUCCESS";
export const PUT_COMPANY_SETTING_VALUE_FAILURE =
  "customer/PUT_COMPANY_SETTING_VALUE_FAILURE";

export const GET_COMPANY_DETAILS = "customer/GET_COMPANY_DETAILS";
export const GET_COMPANY_DETAILS_SUCCESS =
  "customer/GET_COMPANY_DETAILS_SUCCESS";
export const GET_COMPANY_DETAILS_FAILURE =
  "customer/GET_COMPANY_DETAILS_FAILURE";

export const PUT_COMPANY_DETAILS = "customer/PUT_COMPANY_DETAILS";
export const PUT_COMPANY_DETAILS_SUCCESS =
  "customer/PUT_COMPANY_DETAILS_SUCCESS";
export const PUT_COMPANY_DETAILS_FAILURE =
  "customer/PUT_COMPANY_DETAILS_FAILURE";

export const PUT_CHANGE_PLAN = "customer/PUT_CHANGE_PLAN";
export const PUT_CHANGE_PLAN_SUCCESS = "customer/PUT_CHANGE_PLAN_SUCCESS";
export const PUT_CHANGE_PLAN_FAILURE = "customer/PUT_CHANGE_PLAN_FAILURE";

export const PUT_CHANGE_INVOICE_DETAILS = "customer/PUT_CHANGE_INVOICE_DETAILS";
export const PUT_CHANGE_INVOICE_DETAILS_SUCCESS =
  "customer/PUT_CHANGE_INVOICE_DETAILS_SUCCESS";
export const PUT_CHANGE_INVOICE_DETAILS_FAILURE =
  "customer/PUT_CHANGE_INVOICE_DETAILS_FAILURE";

export const GET_HOURS_SUMMARY = "user/GET_HOURS_SUMMARY";
export const GET_HOURS_SUMMARY_SUCCESS = "user/GET_HOURS_SUMMARY_SUCCESS";
export const GET_HOURS_SUMMARY_FAILURE = "user/GET_HOURS_SUMMARY_FAILURE";

export const GET_ADDONS_SUMMARY = "user/GET_ADDONS_SUMMARY";
export const GET_ADDONS_SUMMARY_SUCCESS = "user/GET_ADDONS_SUMMARY_SUCCESS";
export const GET_ADDONS_SUMMARY_FAILURE = "user/GET_ADDONS_SUMMARY_FAILURE";

export const FILTER_DATA = "profile/FILTER_DATA";
export const RESET_STORE = "profile/RESET_STORE";

export const ADD_TEMPLATE = "report/ADD_TEMPLATE";
export const ADD_TEMPLATE_SUCCESS = "report/ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "report/ADD_TEMPLATE_FAILURE";

export const GET_TEMPLATES = "report/GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "report/GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "report/GET_TEMPLATES_FAILURE";

export const GET_TEMPLATE = "report/GET_TEMPLATE";
export const GET_TEMPLATE_SUCCESS = "report/GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "report/GET_TEMPLATE_FAILURE";

export const PUT_TEMPLATE = "report/PUT_TEMPLATE";
export const PUT_TEMPLATE_SUCCESS = "report/PUT_TEMPLATE_SUCCESS";
export const PUT_TEMPLATE_FAILURE = "report/PUT_TEMPLATE_FAILURE";

export const DELETE_TEMPLATE = "report/DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "report/DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "report/DELETE_TEMPLATE_FAILURE";

export const GET_REPORT_TABLE = "report/GET_REPORT_TABLE";
export const GET_REPORT_TABLE_SUCCESS = "report/GET_REPORT_TABLE_SUCCESS";
export const GET_REPORT_TABLE_FAILURE = "report/GET_REPORT_TABLE_FAILURE";

export const GET_EMPLOYEE_REPORT_TABLE = "report/GET_EMPLOYEE_REPORT_TABLE";
export const GET_EMPLOYEE_REPORT_TABLE_SUCCESS =
  "report/GET_EMPLOYEE_REPORT_TABLE_SUCCESS";
export const GET_EMPLOYEE_REPORT_TABLE_FAILURE =
  "report/GET_EMPLOYEE_REPORT_TABLE_FAILURE";

export const GET_ADDON_REPORT_TABLE = "report/GET_ADDON_REPORT_TABLE";
export const GET_ADDON_REPORT_TABLE_SUCCESS =
  "report/GET_ADDON_REPORT_TABLE_SUCCESS";
export const GET_ADDON_REPORT_TABLE_FAILURE =
  "report/GET_ADDON_REPORT_TABLE_FAILURE";

export const GET_PLANNING_REPORT_TABLE = "report/GET_PLANNING_REPORT_TABLE";
export const GET_PLANNING_REPORT_TABLE_SUCCESS =
  "report/GET_PLANNING_REPORT_TABLE_SUCCESS";
export const GET_PLANNING_REPORT_TABLE_FAILURE =
  "report/GET_PLANNING_REPORT_TABLE_FAILURE";

export const ADD_DEVIANCE = "deviance/ADD_DEVIANCE";
export const ADD_DEVIANCE_SUCCESS = "deviance/ADD_DEVIANCE_SUCCESS";
export const ADD_DEVIANCE_FAILURE = "deviance/ADD_DEVIANCE_FAILURE";

export const GET_DEVIANCES = "deviance/GET_DEVIANCES";
export const GET_DEVIANCES_SUCCESS = "deviance/GET_DEVIANCES_SUCCESS";
export const GET_DEVIANCES_FAILURE = "deviance/GET_DEVIANCES_FAILURE";

export const GET_DEVIANCE = "deviance/GET_DEVIANCE";
export const GET_DEVIANCE_SUCCESS = "deviance/GET_DEVIANCE_SUCCESS";
export const GET_DEVIANCE_FAILURE = "deviance/GET_DEVIANCE_FAILURE";

export const UPDATE_DEVIANCE = "deviance/UPDATE_DEVIANCE";
export const UPDATE_DEVIANCE_SUCCESS = "deviance/UPDATE_DEVIANCE_SUCCESS";
export const UPDATE_DEVIANCE_FAILURE = "deviance/UPDATE_DEVIANCE_FAILURE";

export const DELETE_DEVIANCE = "deviance/DELETE_DEVIANCE";
export const DELETE_DEVIANCE_SUCCESS = "deviance/DELETE_DEVIANCE_SUCCESS";
export const DELETE_DEVIANCE_FAILURE = "deviance/DELETE_DEVIANCE_FAILURE";

export const ADD_CATEGORY = "category/ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "category/ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "category/ADD_CATEGORY_FAILURE";

export const GET_CATEGORIES = "category/GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "category/GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "category/GET_CATEGORIES_FAILURE";

export const GET_CATEGORY = "category/GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "category/GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "category/GET_CATEGORY_FAILURE";

export const UPDATE_CATEGORY = "category/UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "category/UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "category/UPDATE_CATEGORY_FAILURE";

export const DELETE_CATEGORY = "category/DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "category/DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "category/DELETE_CATEGORY_FAILURE";

export const ADD_HMSK_TEMPLATE = "hmsk/ADD_HMSK_TEMPLATE";
export const ADD_HMSK_TEMPLATE_SUCCESS = "hmsk/ADD_HMSK_TEMPLATE_SUCCESS";
export const ADD_HMSK_TEMPLATE_FAILURE = "hmsk/ADD_HMSK_TEMPLATE_FAILURE";

export const GET_HMSK_TEMPLATES = "hmsk/GET_HMSK_TEMPLATES";
export const GET_HMSK_TEMPLATES_SUCCESS = "hmsk/GET_HMSK_TEMPLATES_SUCCESS";
export const GET_HMSK_TEMPLATES_FAILURE = "hmsk/GET_HMSK_TEMPLATES_FAILURE";

export const GET_HMSK_TEMPLATE = "hmsk/GET_HMSK_TEMPLATE";
export const GET_HMSK_TEMPLATE_SUCCESS = "hmsk/GET_HMSK_TEMPLATE_SUCCESS";
export const GET_HMSK_TEMPLATE_FAILURE = "hmsk/GET_HMSK_TEMPLATE_FAILURE";

export const UPDATE_HMSK_TEMPLATE = "hmsk/UPDATE_HMSK_TEMPLATE";
export const UPDATE_HMSK_TEMPLATE_SUCCESS = "hmsk/UPDATE_HMSK_TEMPLATE_SUCCESS";
export const UPDATE_HMSK_TEMPLATE_FAILURE = "hmsk/UPDATE_HMSK_TEMPLATE_FAILURE";

export const DELETE_HMSK_TEMPLATE = "hmsk/DELETE_HMSK_TEMPLATE";
export const DELETE_HMSK_TEMPLATE_SUCCESS = "hmsk/DELETE_HMSK_TEMPLATE_SUCCESS";
export const DELETE_HMSK_TEMPLATE_FAILURE = "hmsk/DELETE_HMSK_TEMPLATE_FAILURE";

export const ADD_HMSK_CHECK_LIST = "hmsk/ADD_HMSK_CHECK_LIST";
export const ADD_HMSK_CHECK_LIST_SUCCESS = "hmsk/ADD_HMSK_CHECK_LIST_SUCCESS";
export const ADD_HMSK_CHECK_LIST_FAILURE = "hmsk/ADD_HMSK_CHECK_LIST_FAILURE";

export const GET_HMSK_CHECK_LISTS = "hmsk/GET_HMSK_CHECK_LISTS";
export const GET_HMSK_CHECK_LISTS_SUCCESS = "hmsk/GET_HMSK_CHECK_LISTS_SUCCESS";
export const GET_HMSK_CHECK_LISTS_FAILURE = "hmsk/GET_HMSK_CHECK_LISTS_FAILURE";

export const GET_HMSK_CHECK_LIST = "hmsk/GET_HMSK_CHECK_LIST";
export const GET_HMSK_CHECK_LIST_SUCCESS = "hmsk/GET_HMSK_CHECK_LIST_SUCCESS";
export const GET_HMSK_CHECK_LIST_FAILURE = "hmsk/GET_HMSK_CHECK_LIST_FAILURE";

export const UPDATE_HMSK_CHECK_LIST = "hmsk/UPDATE_HMSK_CHECK_LIST";
export const UPDATE_HMSK_CHECK_LIST_SUCCESS =
  "hmsk/UPDATE_HMSK_CHECK_LIST_SUCCESS";
export const UPDATE_HMSK_CHECK_LIST_FAILURE =
  "hmsk/UPDATE_HMSK_CHECK_LIST_FAILURE";

export const DELETE_HMSK_CHECK_LIST = "hmsk/DELETE_HMSK_CHECK_LIST";
export const DELETE_HMSK_CHECK_LIST_SUCCESS =
  "hmsk/DELETE_HMSK_CHECK_LIST_SUCCESS";
export const DELETE_HMSK_CHECK_LIST_FAILURE =
  "hmsk/DELETE_HMSK_CHECK_LIST_FAILURE";

export const GET_HMSK_CHECK_LISTS_NEED_TO_FILL =
  "hmsk/GET_HMSK_CHECK_LISTS_NEED_TO_FILL";
export const GET_HMSK_CHECK_LISTS_NEED_TO_FILL_SUCCESS =
  "hmsk/GET_HMSK_CHECK_LISTS_NEED_TO_FILL_SUCCESS";
export const GET_HMSK_CHECK_LISTS_NEED_TO_FILL_FAILURE =
  "hmsk/GET_HMSK_CHECK_LISTS_NEED_TO_FILL_FAILURE";

export const UPLOAD_IMAGE = "image/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "image/UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "image/UPLOAD_IMAGE_FAILURE";

export const DELETE_IMAGE = "image/DELETE_IMAGE";
export const DELETE_IMAGE_SUCCESS = "image/DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "image/DELETE_HMSK_CHECK_LIST_FAILURE";

export const CREATE_PLAN = "planning/CREATE_PLAN";
export const CREATE_PLAN_SUCCESS = "planning/CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_FAILURE = "planning/CREATE_PLAN_FAILURE";

export const CREATE_PLAN_MULTI_PROJECT = "planning/CREATE_PLAN_MULTI_PROJECT";
export const CREATE_PLAN_MULTI_PROJECT_SUCCESS =
  "planning/CREATE_PLAN_MULTI_PROJECT_SUCCESS";
export const CREATE_PLAN_MULTI_PROJECT_FAILURE =
  "planning/CREATE_PLAN_MULTI_PROJECT_FAILURE";

export const GET_COMPANY_USERS = "planning/COMPANY_USERS";
export const GET_COMPANY_USERS_SUCCESS = "planning/GET_COMPANY_USERS_SUCCESS";
export const GET_COMPANY_USERS_FAILURE = "planning/GET_COMPANY_USERS_FAILURE";

export const GET_PLANS = "planning/GET_PLANS";
export const GET_PLANS_SUCCESS = "planning/GET_PLANS_SUCCESS";
export const GET_PLANS_FAILURE = "planning/GET_PLANS_FAILURE";

export const UPDATE_PLAN = "planning/UPDATE_PLAN";
export const UPDATE_PLAN_SUCCESS = "planning/UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAILURE = "planning/UPDATE_PLAN_FAILURE";

export const GET_PLAN = "planning/GET_PLAN";
export const GET_PLAN_SUCCESS = "planning/GET_PLAN_SUCCESS";
export const GET_PLAN_FAILURE = "planning/GET_PLAN_FAILURE";

export const DELETE_PLAN = "planning/DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "planning/DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = "planning/DELETE_PLAN_FAILURE";

export const ASSIGN_PLAN = "planning/ASSIGN_PLAN";
export const ASSIGN_PLAN_SUCCESS = "planning/ASSIGN_PLAN_SUCCESS";
export const ASSIGN_PLAN_FAILURE = "planning/ASSIGN_PLAN_FAILURE";

export const APPROVE_PLAN = "planning/APPROVE_PLAN";
export const APPROVE_PLAN_SUCCESS = "planning/APPROVE_PLAN_SUCCESS";
export const APPROVE_PLAN_FAILURE = "planning/APPROVE_PLAN_FAILURE";

export const REJECT_PLAN = "planning/REJECT_PLAN";
export const REJECT_PLAN_SUCCESS = "planning/REJECT_PLAN_SUCCESS";
export const REJECT_PLAN_FAILURE = "planning/REJECT_PLAN_FAILURE";

export const APPROVE_USER_PLAN = "planning/APPROVE_USER_PLAN";
export const APPROVE_USER_PLAN_SUCCESS = "planning/APPROVE_USER_PLAN_SUCCESS";
export const APPROVE_USER_PLAN_FAILURE = "planning/APPROVE_USER_PLAN_FAILURE";

export const REJECT_USER_PLAN = "planning/REJECT_USER_PLAN";
export const REJECT_USER_PLAN_SUCCESS = "planning/REJECT_USER_PLAN_SUCCESS";
export const REJECT_USER_PLAN_FAILURE = "planning/REJECT_USER_PLAN_FAILURE";

export const GET_AVAILABILITY = "planning/GET_AVAILABILITY";
export const GET_AVAILABILITY_SUCCESS = "planning/GET_AVAILABILITY_SUCCESS";
export const GET_AVAILABILITY_FAILURE = "planning/GET_AVAILABILITY_FAILURE";

export const GET_SPECIFIC_PLAN_USERS = "planning/GET_SPECIFIC_PLAN_USERS";
export const GET_SPECIFIC_PLAN_USERS_SUCCESS =
  "planning/GET_SPECIFIC_PLAN_USERS_SUCCESS";
export const GET_SPECIFIC_PLAN_USERS_FAILURE =
  "planning/GET_SPECIFIC_PLAN_USERS_FAILURE";

export const GET_RULES = "rules/GET_RULES";
export const GET_RULES_SUCCESS = "rules/GET_RULES_SUCCESS";
export const GET_RULES_FAILURE = "rules/GET_RULES_FAILURE";

export const CREATE_RULE = "rules/CREATE_RULE";
export const CREATE_RULE_SUCCESS = "rules/CREATE_RULE_SUCCESS";
export const CREATE_RULE_FAILURE = "rules/CREATE_RULE_FAILURE";

export const UPDATE_RULE = "rules/UPDATE_RULE";
export const UPDATE_RULE_SUCCESS = "rules/UPDATE_RULE_SUCCESS";
export const UPDATE_RULE_FAILURE = "rules/UPDATE_RULE_FAILURE";

export const DELETE_RULE = "rules/DELETE_RULE";
export const DELETE_RULE_SUCCESS = "rules/DELETE_RULE_SUCCESS";
export const DELETE_RULE_FAILURE = "rules/DELETE_RULE_FAILURE";
export const GET_ADDON_TYPES = "addontypes/GET_ADDON_TYPES";
export const GET_ADDON_TYPES_SUCCESS = "addontypes/GET_ADDON_TYPES_SUCCESS";
export const GET_ADDON_TYPES_FAILURE = "addontypes/GET_ADDON_TYPES_FAILURE";

export const GET_OPTIONAL_ADDON_TYPES = "addontypes/GET_OPTIONAL_ADDON_TYPES";
export const GET_OPTIONAL_ADDON_TYPES_SUCCESS =
  "addontypes/GET_OPTIONAL_ADDON_TYPES_SUCCESS";
export const GET_OPTIONAL_ADDON_TYPES_FAILURE =
  "addontypes/GET_OPTIONAL_ADDON_TYPES_FAILURE";

export const SET_OPTIONAL_ADDON_TYPE = "addon/SET_OPTIONAL_ADDON_TYPE";
export const SET_OPTIONAL_ADDON_TYPE_SUCCESS =
  "addon/SET_OPTIONAL_ADDON_TYPE_SUCCESS";
export const SET_OPTIONAL_ADDON_TYPE_FAILURE =
  "addon/SET_OPTIONAL_ADDON_TYPE_FAILURE";

export const CREATE_ADDON_TYPES = "addontypes/CREATE_ADDON_TYPES";
export const CREATE_ADDON_TYPES_SUCCESS =
  "addontypes/CREATE_ADDON_TYPES_SUCCESS";
export const CREATE_ADDON_TYPES_FAILURE =
  "addontypes/CREATE_ADDON_TYPES_FAILURE";

export const DELETE_ADDON_TYPES = "addontypes/DELETE_ADDON_TYPES";
export const DELETE_ADDON_TYPES_SUCCESS =
  "addontypes/DELETE_ADDON_TYPES_SUCCESS";
export const DELETE_ADDON_TYPES_FAILURE =
  "addontypes/DELETE_ADDON_TYPES_FAILURE";

export const PUT_ADDON_TYPES = "addontypes/PUT_ADDON_TYPES";
export const PUT_ADDON_TYPES_SUCCESS = "addontypes/PUT_ADDON_TYPES_SUCCESS";
export const PUT_ADDON_TYPES_FAILURE = "addontypes/PUT_ADDON_TYPES_FAILURE";
export const UPDATE_GEOFENCE = "hmsk/UPDATE_GEOFENCE";
export const UPDATE_GEOFENCE_SUCCESS = "hmsk/UPDATE_GEOFENCE_SUCCESS";
export const UPDATE_GEOFENCE_FAILURE = "hmsk/UPDATE_GEOFENCE_FAILURE";

export const DELETE_GEOFENCE = "hmsk/DELETE_GEOFENCE";
export const DELETE_GEOFENCE_SUCCESS = "hmsk/DELETE_GEOFENCE_SUCCESS";
export const DELETE_GEOFENCE_FAILURE = "hmsk/DELETE_GEOFENCE_FAILURE";

export const ADD_GEOFENCE = "hmsk/ADD_GEOFENCE";
export const ADD_GEOFENCE_SUCCESS = "hmsk/ADD_GEOFENCE_SUCCESS";
export const ADD_GEOFENCE_FAILURE = "hmsk/ADD_GEOFENCE_FAILURE";

export const GET_GEOFENCES = "hmsk/GET_GEOFENCES";
export const GET_GEOFENCES_SUCCESS = "hmsk/GET_GEOFENCES_SUCCESS";
export const GET_GEOFENCES_FAILURE = "hmsk/GET_GEOFENCES_FAILURE";

export const GET_GEOFENCE = "hmsk/GET_GEOFENCE";
export const GET_GEOFENCE_SUCCESS = "hmsk/GET_GEOFENCE_SUCCESS";
export const GET_GEOFENCE_FAILURE = "hmsk/GET_GEOFENCE_FAILURE";

export const CREATE_USER_GROUPS = "groups/CREATE_USER_GROUPS";
export const CREATE_USER_GROUPS_SUCCESS = "groups/CREATE_USER_GROUPS_SUCCESS";
export const CREATE_USER_GROUPS_FAILURE = "groups/CREATE_USER_GROUPS_FAILURE";

export const GET_USER_GROUPS = "groups/GET_USER_GROUPS";
export const GET_USER_GROUPS_SUCCESS = "groups/GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_FAILURE = "groups/GET_USER_GROUPS_FAILURE";

export const PUT_USER_GROUPS = "groups/PUT_USER_GROUPS";
export const PUT_USER_GROUPS_SUCCESS = "groups/PUT_USER_GROUPS_SUCCESS";
export const PUT_USER_GROUPS_FAILURE = "groups/PUT_USER_GROUPS_FAILURE";

export const DELETE_USER_GROUPS = "groups/DELETE_USER_GROUPS";
export const DELETE_USER_GROUPS_SUCCESS = "groups/DELETE_USER_GROUPS_SUCCESS";
export const DELETE_USER_GROUPS_FAILURE = "groups/DELETE_USER_GROUPS_FAILURE";

export const ADD_COMPANY_HOLIDAYS = "holidays/ADD_COMPANY_HOLIDAYS";
export const ADD_COMPANY_HOLIDAYS_SUCCESS =
  "holidays/ADD_COMPANY_HOLIDAYSE_SUCCESS";
export const ADD_COMPANY_HOLIDAYS_FAILURE =
  "holidays/ADD_COMPANY_HOLIDAYS_FAILURE";

export const GET_HOLIDAYS = "holidays/GET_HOLIDAYS";
export const GET_HOLIDAYS_SUCCESS = "holidays/GET_HOLIDAYS_SUCCESS";
export const GET_HOLIDAYS_FAILURE = "holidays/GET_HOLIDAYS_FAILURE";

export const GET_COMPANY_HOLIDAYS = "holidays/GET_COMPANY_HOLIDAYS";
export const GET_COMPANY_HOLIDAYS_SUCCESS =
  "holidays/GET_COMPANY_HOLIDAYS_SUCCESS";
export const GET_COMPANY_HOLIDAYS_FAILURE =
  "holidays/GET_COMPANY_HOLIDAYS_FAILURE";

export const PUT_COMPANY_HOLIDAY = "holidays/PUT_COMPANY_HOLIDAY";
export const PUT_COMPANY_HOLIDAY_SUCCESS =
  "holidays/PUT_COMPANY_HOLIDAY_SUCCESS";
export const PUT_COMPANY_HOLIDAY_FAILURE =
  "holidays/PUT_COMPANY_HOLIDAY_FAILURE";

export const DELETE_COMPANY_HOLIDAY = "holidays/DELETE_COMPANY_HOLIDAY";
export const DELETE_COMPANY_HOLIDAY_SUCCESS =
  "holidays/DELETE_COMPANY_HOLIDAY_SUCCESS";
export const DELETE_COMPANY_HOLIDAY_FAILURE =
  "holidays/DELETE_COMPANY_HOLIDAY_FAILURE";

export const GET_APPROVAL_DETAILS = "approvals/GET_APPROVAL_DETAILS";
export const GET_APPROVAL_DETAILS_SUCCESS =
  "approvals/GET_APPROVAL_DETAILS_SUCCESS";
export const GET_APPROVAL_DETAILS_FAILURE =
  "approvals/GET_APPROVAL_DETAILS_FAILURE";

export const GET_APPROVAL_DETAILS_SIMPLIFIED =
  "approvals/GET_APPROVAL_DETAILS_SIMPLIFIED";
export const GET_APPROVAL_DETAILS_SIMPLIFIED_SUCCESS =
  "approvals/GET_APPROVAL_DETAILS_SIMPLIFIED_SUCCESS";
export const GET_APPROVAL_DETAILS_SIMPLIFIED_FAILURE =
  "approvals/GET_APPROVAL_DETAILS_SIMPLIFIED_FAILURE";

export const GET_CHANGE_DETAILS = "approvals/GET_CHANGE_DETAILS";
export const GET_CHANGE_DETAILS_SUCCESS =
  "approvals/GET_CHANGE_DETAILS_SUCCESS";
export const GET_CHANGE_DETAILS_FAILURE =
  "approvals/GET_CHANGE_DETAILS_FAILURE";

export const GET_CHANGE_DETAILS_SIMPLIFIED =
  "approvals/GET_CHANGE_DETAILS_SIMPLIFIED";
export const GET_CHANGE_DETAILS_SIMPLIFIED_SUCCESS =
  "approvals/GET_CHANGE_DETAILS_SIMPLIFIED_SUCCESS";
export const GET_CHANGE_DETAILS_SIMPLIFIED_FAILURE =
  "approvals/GET_CHANGE_DETAILS_SIMPLIFIED_FAILURE";

export const GET_APPROVAL_SUMMARY = "approvals/GET_APPROVAL_SUMMARY";
export const GET_APPROVAL_SUMMARY_SUCCESS =
  "approvals/GET_APPROVAL_SUMMARY_SUCCESS";
export const GET_APPROVAL_SUMMARY_FAILURE =
  "approvals/GET_APPROVAL_SUMMARY_FAILURE";

export const GET_CHANGE_SUMMARY = "approvals/GET_CHANGE_SUMMARY";
export const GET_CHANGE_SUMMARY_SUCCESS =
  "approvals/GET_CHANGE_SUMMARY_SUCCESS";
export const GET_CHANGE_SUMMARY_FAILURE =
  "approvals/GET_CHANGE_SUMMARY_FAILURE";

export const GET_APPROVAL_USERS = "approval/GET_APPROVAL_USERS";
export const GET_APPROVAL_USERS_SUCCESS = "approval/GET_APPROVAL_USERS_SUCCESS";
export const GET_APPROVAL_USERS_FAILURE = "approval/GET_APPROVAL_USERS_FAILURE";

export const GET_CHANGE_APPROVAL_USERS = "approval/GET_CHANGE_APPROVAL_USERS";
export const GET_CHANGE_APPROVAL_USERS_SUCCESS =
  "approval/GET_CHANGE_APPROVAL_USERS_SUCCESS";
export const GET_CHANGE_APPROVAL_USERS_FAILURE =
  "approval/GET_CHANGE_APPROVAL_USERS_FAILURE";

export const APPROVE_HOURS = "hours/APPROVE_HOURS";
export const APPROVE_HOURS_SUCCESS = "hours/APPROVE_HOURS_SUCCESS";
export const APPROVE_HOURS_FAILURE = "hours/APPROVE_HOURS_FAILURE";

export const REJECT_HOURS = "hours/REJECT_HOURS";
export const REJECT_HOURS_SUCCESS = "hours/REJECT_HOURS_SUCCESS";
export const REJECT_HOURS_FAILURE = "hours/REJECT_HOURS_FAILURE";

export const APPROVE_ALL = "hours/APPROVE_ALL";
export const APPROVE_ALL_SUCCESS = "hours/APPROVE_ALL_SUCCESS";
export const APPROVE_ALL_FAILURE = "hours/APPROVE_ALL_FAILURE";

export const APPROVE_CHANGE_ALL = "hours/APPROVE_CHANGE_ALL";
export const APPROVE_CHANGE_ALL_SUCCESS = "hours/APPROVE_CHANGE_ALL_SUCCESS";
export const APPROVE_CHANGE_ALL_FAILURE = "hours/APPROVE_CHANGE_ALL_FAILURE";

export const REJECT_ALL = "hours/REJECT_ALL";
export const REJECT_ALL_SUCCESS = "hours/REJECT_ALL_SUCCESS";
export const REJECT_ALL_FAILURE = "hours/REJECT_ALL_FAILURE";

export const CHANGE_HOURS = "hours/CHANGE_HOURS";
export const CHANGE_HOURS_SUCCESS = "hours/CHANGE_HOURS_SUCCESS";
export const CHANGE_HOURS_FAILURE = "hours/CHANGE_HOURS_FAILURE";

export const APPROVE_ADDONS = "addon/APPROVE_ADDONS";
export const APPROVE_ADDONS_SUCCESS = "addon/APPROVE_ADDONS_SUCCESS";
export const APPROVE_ADDONS_FAILURE = "addon/APPROVE_ADDONS_FAILURE";

export const REJECT_ADDONS = "addon/REJECT_ADDONS";
export const REJECT_ADDONS_SUCCESS = "addon/REJECT_ADDONS_SUCCESS";
export const REJECT_ADDONS_FAILURE = "addon/REJECT_ADDONS_FAILURE";

export const CHANGE_ADDONS = "addon/CHANGE_ADDONS";
export const CHANGE_ADDONS_SUCCESS = "addon/CHANGE_ADDONS_SUCCESS";
export const CHANGE_ADDONS_FAILURE = "addon/CHANGE_ADDONS_FAILURE";

export const GET_ADDONS = "addon/GET_ADDONS";
export const GET_ADDONS_SUCCESS = "addon/GET_ADDONS_SUCCESS";
export const GET_ADDONS_FAILURE = "addon/GET_ADDONS_FAILURE";

export const APPROVE_BATCH = "batch/APPROVE_BATCH";
export const APPROVE_BATCH_SUCCESS = "batch/APPROVE_BATCH_SUCCESS";
export const APPROVE_BATCH_FAILURE = "batch/APPROVE_BATCH_FAILURE";

export const REJECT_BATCH = "batch/REJECT_BATCH";
export const REJECT_BATCH_SUCCESS = "batch/REJECT_BATCH_SUCCESS";
export const REJECT_BATCH_FAILURE = "batch/REJECT_BATCH_FAILURE";

export const GET_BATCH_RULES = "batch/GET_BATCH_RULES";
export const GET_BATCH_RULES_SUCCESS = "batch/GET_BATCH_RULES_SUCCESS";
export const GET_BATCH_RULES_FAILURE = "batch/GET_BATCH_RULES_FAILURE";

export const CHANGE_BATCH = "batch/CHANGE_BATCH";
export const CHANGE_BATCH_SUCCESS = "batch/CHANGE_BATCH_SUCCESS";
export const CHANGE_BATCH_FAILURE = "batch/CHANGE_BATCH_FAILURE";

export const CREATE_CHECKIN_HOUR = "hour/CREATE_CHECKIN_HOUR";
export const CREATE_CHECKIN_HOUR_SUCCESS = "hour/CREATE_CHECKIN_HOUR_SUCCESS";
export const CREATE_CHECKIN_HOUR_FAILURE = "hour/CREATE_CHECKIN_HOUR_FAILURE";

export const GET_CHECKIN_HOUR = "hour/GET_CHECKIN_HOUR";
export const GET_CHECKIN_HOUR_SUCCESS = "hour/GET_CHECKIN_HOUR_SUCCESS";
export const GET_CHECKIN_HOUR_FAILURE = "hour/GET_CHECKIN_HOUR_FAILURE";

export const UPDATE_CHECKIN_HOUR = "hour/UPDATE_CHECKIN_HOUR";
export const UPDATE_CHECKIN_HOUR_SUCCESS = "hour/UPDATE_CHECKIN_HOUR_SUCCESS";
export const UPDATE_CHECKIN_HOUR_FAILURE = "hour/UPDATE_CHECKIN_HOUR_FAILURE";

export const DELETE_CHECKIN_HOUR = "hour/DELETE_CHECKIN_HOUR";
export const DELETE_CHECKIN_HOUR_SUCCESS = "hour/DELETE_CHECKIN_HOUR_SUCCESS";
export const DELETE_CHECKIN_HOUR_FAILURE = "hour/DELETE_CHECKIN_HOUR_FAILURE";

export const CREATE_SCHEDULES = "schedules/CREATE_SCHEDULES";
export const CREATE_SCHEDULES_SUCCESS = "schedules/CREATE_SCHEDULES_SUCCESS";
export const CREATE_SCHEDULES_FAILURE = "schedules/CREATE_SCHEDULES_FAILURE";

export const GET_SCHEDULES = "schedules/GET_SCHEDULES";
export const GET_SCHEDULES_SUCCESS = "schedules/GET_SCHEDULES_SUCCESS";
export const GET_SCHEDULES_FAILURE = "schedules/GET_SCHEDULES_FAILURE";

export const PUT_SCHEDULES = "schedules/PUT_SCHEDULES";
export const PUT_SCHEDULES_SUCCESS = "schedules/PUT_SCHEDULES_SUCCESS";
export const PUT_SCHEDULES_FAILURE = "schedules/PUT_SCHEDULES_FAILURE";

export const DELETE_SCHEDULES = "schedules/DELETE_SCHEDULES";
export const DELETE_SCHEDULES_SUCCESS = "schedules/DELETE_SCHEDULES_SUCCESS";
export const DELETE_SCHEDULES_FAILURE = "schedules/DELETE_SCHEDULES_FAILURE";

export const PUT_SCHEDULES_USER = "schedules/PUT_SCHEDULES_USER";
export const PUT_SCHEDULES_USER_SUCCESS =
  "schedules/PUT_SCHEDULES_USER_SUCCESS";
export const PUT_SCHEDULES_USER_FAILURE =
  "schedules/PUT_SCHEDULES_USER_FAILURE";

export const APPROVE_BATCH_GROUP = "group/APPROVE_BATCH_GROUP";
export const APPROVE_BATCH_GROUP_SUCCESS = "group/APPROVE_BATCH_GROUP_SUCCESS";
export const APPROVE_BATCH_GROUP_FAILURE = "group/APPROVE_BATCH_GROUP_FAILURE";

export const REJECT_BATCH_GROUP = "group/REJECT_BATCH_GROUP";
export const REJECT_BATCH_GROUP_SUCCESS = "group/REJECT_BATCH_GROUP_SUCCESS";
export const REJECT_BATCH_GROUP_FAILURE = "group/REJECT_BATCH_GROUP_FAILURE";

export const CHANGE_BATCH_GROUP = "group/CHANGE_BATCH_GROUP";
export const CHANGE_BATCH_GROUP_SUCCESS = "group/CHANGE_BATCH_GROUP_SUCCESS";
export const CHANGE_BATCH_GROUP_FAILURE = "group/CHANGE_BATCH_GROUP_FAILURE";

export const CREATE_USER_ATTENDANCE = "user/CREATE_USER_ATTENDANCE";
export const CREATE_USER_ATTENDANCE_SUCCESS =
  "user/CREATE_USER_ATTENDANCE_SUCCESS";
export const CREATE_USER_ATTENDANCE_FAILURE =
  "user/CREATE_USER_ATTENDANCE_FAILURE";

export const GET_USER_ATTENDANCE = "user/GET_USER_ATTENDANCE";
export const GET_USER_ATTENDANCE_SUCCESS = "user/GET_USER_ATTENDANCE_SUCCESS";
export const GET_USER_ATTENDANCE_FAILURE = "user/GET_USER_ATTENDANCE_FAILURE";

export const GET_USER_ATTENDANCE_LAST = "user/GET_USER_ATTENDANCE_LAST";
export const GET_USER_ATTENDANCE_LAST_SUCCESS =
  "user/GET_USER_ATTENDANCE_LAST_SUCCESS";
export const GET_USER_ATTENDANCE_LAST_FAILURE =
  "user/GET_USER_ATTENDANCE_LAST_FAILURE";

export const GET_GUEST_ATTENDANCE = "user/GET_GUEST_ATTENDANCE";
export const GET_GUEST_ATTENDANCE_SUCCESS = "user/GET_GUEST_ATTENDANCE_SUCCESS";
export const GET_GUEST_ATTENDANCE_FAILURE = "user/GET_GUEST_ATTENDANCE_FAILURE";

export const UPDATE_USER_ATTENDANCE = "user/UPDATE_USER_ATTENDANCE";
export const UPDATE_USER_ATTENDANCE_SUCCESS =
  "user/UPDATE_USER_ATTENDANCE_SUCCESS";
export const UPDATE_USER_ATTENDANCE_FAILURE =
  "user/UPDATE_USER_ATTENDANCE_FAILURE";

export const CREATE_MULTI_PROJECT_HOURS = "hours/CREATE_MULTI_PROJECT_HOURS";
export const CREATE_MULTI_PROJECT_HOURS_SUCCESS =
  "hours/CREATE_MULTI_PROJECT_HOURS_SUCCESS";
export const CREATE_MULTI_PROJECT_HOURS_FAILURE =
  "hours/CREATE_MULTI_PROJECT_HOURS_FAILURE";

export const UPDATE_BATCH_GROUP = "hours/UPDATE_BATCH_GROUP";
export const UPDATE_BATCH_GROUP_SUCCESS = "hours/UPDATE_BATCH_GROUP_SUCCESS";
export const UPDATE_BATCH_GROUP_FAILURE = "hours/UPDATE_BATCH_GROUP_FAILURE";

export const CREATE_CHECKOUT_HOUR = "hours/CREATE_CHECKOUT_HOUR";
export const CREATE_CHECKOUT_HOUR_SUCCESS =
  "hours/CREATE_CHECKOUT_HOUR_SUCCESS";
export const CREATE_CHECKOUT_HOUR_FAILURE =
  "hours/CREATE_CHECKOUT_HOUR_FAILURE";

export const DELETE_BATCH_GROUP = "hours/DELETE_BATCH_GROUP";
export const DELETE_BATCH_GROUP_SUCCESS = "hours/DELETE_BATCH_GROUP_SUCCESS";
export const DELETE_BATCH_GROUP_FAILURE = "hours/DELETE_BATCH_GROUP_FAILURE";

export const GET_SALARY_EXPORT_HISTORY = "report/GET_SALARY_EXPORT_HISTORY";
export const GET_SALARY_EXPORT_HISTORY_SUCCESS =
  "report/GET_SALARY_EXPORT_HISTORY_SUCCESS";
export const GET_SALARY_EXPORT_HISTORY_FAILURE =
  "report/GET_SALARY_EXPORT_HISTORY_FAILURE";

export const GET_REVERT_SALARY_REPORT = "report/GET_REVERT_SALARY_REPORT";
export const GET_REVERT_SALARY_REPORT_SUCCESS =
  "report/GET_REVERT_SALARY_REPORT_SUCCESS";
export const GET_REVERT_SALARY_REPORT_FAILURE =
  "report/GET_REVERT_SALARY_REPORT_FAILURE";

export const GET_DASHBOARD_BLOCKS = "dashboard/GET_DASHBOARD_BLOCKS";
export const GET_DASHBOARD_BLOCKS_SUCCESS =
  "dashboard/GET_DASHBOARD_BLOCKS_SUCCESS";
export const GET_DASHBOARD_BLOCKS_FAILURE =
  "dashboard/GET_DASHBOARD_BLOCKS_FAILURE";

export const GET_HOURS_BY_USER = "dashboard/GET_HOURS_BY_USER";
export const GET_HOURS_BY_USER_SUCCESS = "dashboard/GET_HOURS_BY_USER_SUCCESS";
export const GET_HOURS_BY_USER_FAILURE = "dashboard/GET_HOURS_BY_USER_FAILURE";

export const GET_HOUR_ADDON_TYPE_SUMMARY =
  "dashboard/GET_HOUR_ADDON_TYPE_SUMMARY";
export const GET_HOUR_ADDON_TYPE_SUMMARY_SUCCESS =
  "dashboard/GET_HOUR_ADDON_TYPE_SUMMARY_SUCCESS";
export const GET_HOUR_ADDON_TYPE_SUMMARY_FAILURE =
  "dashboard/GET_HOUR_ADDON_TYPE_SUMMARY_FAILURE";

export const GET_DASHBOARD_LINEGRAPH = "dashboard/GET_DASHBOARD_LINEGRAPH";
export const GET_DASHBOARD_LINEGRAPH_SUCCESS =
  "dashboard/GET_DASHBOARD_LINEGRAPH_SUCCESS";
export const GET_DASHBOARD_LINEGRAPH_FAILURE =
  "dashboard/GET_DASHBOARD_LINEGRAPH_FAILURE";

export const PREVIEW_MULTI_PROJECT_HOURS = "hours/PREVIEW_MULTI_PROJECT_HOURS";
export const PREVIEW_MULTI_PROJECT_HOURS_SUCCESS =
  "hours/PREVIEW_MULTI_PROJECT_HOURS_SUCCESS";
export const PREVIEW_MULTI_PROJECT_HOURS_FAILURE =
  "hours/PREVIEW_MULTI_PROJECT_HOURS_FAILURE";

export const COMPANY_CONTRACT = "company/COMPANY_CONTRACT";
export const COMPANY_CONTRACT_SUCCESS = "company/COMPANY_CONTRACT_SUCCESS";
export const COMPANY_CONTRACT_FAILURE = "company/COMPANY_CONTRACT_FAILURE";

export const PUT_COMPANY_CONTRACT = "company/PUT_COMPANY_CONTRACT";
export const PUT_COMPANY_CONTRACT_SUCCESS =
  "company/PUT_COMPANY_CONTRACT_SUCCESS";
export const PUT_COMPANY_CONTRACT_FAILURE =
  "company/PUT_COMPANY_CONTRACT_FAILURE";

export const SEND_USER_CONTRACT = "user/SEND_USER_CONTRACT";
export const SEND_USER_CONTRACT_SUCCESS = "user/SEND_USER_CONTRACT_SUCCESS";
export const SEND_USER_CONTRACT_FAILURE = "user/SEND_USER_CONTRACT_FAILURE";

export const GET_USER_CONTRACTS_DATA = "user/GET_USER_CONTRACTS_DATA";
export const GET_USER_CONTRACTS_DATA_SUCCESS =
  "user/GET_USER_CONTRACTS_DATA_SUCCESS";
export const GET_USER_CONTRACTS_DATA_FAILURE =
  "user/GET_USER_CONTRACTS_DATA_FAILURE";

export const GET_USER_CONTRACT = "user/GET_USER_CONTRACT";
export const GET_USER_CONTRACT_SUCCESS = "user/GET_USER_CONTRACT_SUCCESS";
export const GET_USER_CONTRACT_FAILURE = "user/GET_USER_CONTRACT_FAILURE";

export const UPDATE_USER_CONTRACT = "user/UPDATE_USER_CONTRACT";
export const UPDATE_USER_CONTRACT_SUCCESS = "user/UPDATE_USER_CONTRACT_SUCCESS";
export const UPDATE_USER_CONTRACT_FAILURE = "user/UPDATE_USER_CONTRACT_FAILURE";

export const RESEND_USER_CONTRACT = "user/RESEND_USER_CONTRACT";
export const RESEND_CONTRACT_SUCCESS = "user/RESEND_CONTRACT_SUCCESS";
export const RESEND_CONTRACT_FAILURE = "user/RESEND_CONTRACT_FAILURE";
export const CREATE_ITEM_TYPES = "items/CREATE_ITEM_TYPES";
export const CREATE_ITEM_TYPES_SUCCESS = "items/CREATE_ITEM_TYPES_SUCCESS";
export const CREATE_ITEM_TYPES_FAILURE = "items/CREATE_ITEM_TYPES_FAILURE";

export const GET_ITEM_TYPES = "items/GET_ITEM_TYPES";
export const GET_ITEM_TYPES_SUCCESS = "items/GET_ITEM_TYPES_SUCCESS";
export const GET_ITEM_TYPES_FAILURE = "items/GET_ITEM_TYPES_FAILURE";

export const PUT_ITEM_TYPES = "items/PUT_ITEM_TYPES";
export const PUT_ITEM_TYPES_SUCCESS = "items/PUT_ITEM_TYPES_SUCCESS";
export const PUT_ITEM_TYPES_FAILURE = "items/PUT_ITEM_TYPES_FAILURE";

export const DELETE_ITEM_TYPES = "items/DELETE_ITEM_TYPES";
export const DELETE_ITEM_TYPES_SUCCESS = "items/DELETE_ITEM_TYPES_SUCCESS";
export const DELETE_ITEM_TYPES_FAILURE = "items/DELETE_ITEM_TYPES_FAILURE";

export const GET_UNITS = "items/GET_UNITS";
export const GET_UNITS_SUCCESS = "items/GET_UNITS_SUCCESS";
export const GET_UNITS_FAILURE = "items/GET_UNITS_FAILURE";

export const CREATE_ITEM = "items/CREATE_ITEM";
export const CREATE_ITEM_SUCCESS = "items/CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_FAILURE = "items/CREATE_ITEM_FAILURE";

export const GET_ITEMS = "items/GET_ITEMS";
export const GET_ITEMS_SUCCESS = "items/GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAILURE = "items/GET_ITEMS_FAILURE";

export const PUT_ITEM = "items/PUT_ITEM";
export const PUT_ITEM_SUCCESS = "items/PUT_ITEM_SUCCESS";
export const PUT_ITEM_FAILURE = "items/PUT_ITEM_FAILURE";

export const DELETE_ITEM = "items/DELETE_ITEM";
export const DELETE_ITEM_SUCCESS = "items/DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "items/DELETE_ITEM_FAILURE";

export const CREATE_ITEM_TYPE_CATEGORY = "items/CREATE_ITEM_TYPE_CATEGORY";
export const CREATE_ITEM_TYPE_CATEGORY_SUCCESS =
  "items/CREATE_ITEM_TYPE_CATEGORY_SUCCESS";
export const CREATE_ITEM_TYPE_CATEGORY_FAILURE =
  "items/CREATE_ITEM_TYPE_CATEGORY_FAILURE";

export const GET_ITEM_TYPE_CATEGORIES = "items/GET_ITEM_TYPE_CATEGORIES";
export const GET_ITEM_TYPE_CATEGORIES_SUCCESS =
  "items/GET_ITEM_TYPE_CATEGORIES_SUCCESS";
export const GET_ITEM_TYPE_CATEGORIES_FAILURE =
  "items/GET_ITEM_TYPE_CATEGORIES_FAILURE";

export const GET_PLAN_APPROVAL_USERS = "approval/GET_PLAN_APPROVAL_USERS";
export const GET_PLAN_APPROVAL_USERS_SUCCESS =
  "approval/GET_PLAN_APPROVAL_USERS_SUCCESS";
export const GET_PLAN_APPROVAL_USERS_FAILURE =
  "approval/GET_PLAN_APPROVAL_USERS_FAILURE";

export const GET_PLAN_APPROVAL_DETAILS = "approvals/GET_PLAN_APPROVAL_DETAILS";
export const GET_PLAN_APPROVAL_DETAILS_SUCCESS =
  "approvals/GET_PLAN_APPROVAL_DETAILS_SUCCESS";
export const GET_PLAN_APPROVAL_DETAILS_FAILURE =
  "approvals/GET_PLAN_APPROVAL_DETAILS_FAILURE";

export const ADD_USER_TIMEBANK = "user/ADD_USER_TIMEBANK";
export const ADD_USER_TIMEBANK_SUCCESS = "user/ADD_USER_TIMEBANK_SUCCESS";
export const ADD_USER_TIMEBANK_FAILURE = "user/ADD_USER_TIMEBANK_FAILURE";

export const GET_USER_TIMEBANK_DATA = "user/v";
export const GET_USER_TIMEBANK_DATA_SUCCESS =
  "user/GET_USER_TIMEBANK_DATA_SUCCESS";
export const GET_USER_TIMEBANK_DATA_FAILURE =
  "user/GET_USER_TIMEBANK_DATA_FAILURE";

export const DELETE_USER_TIMEBANK = "user/DELETE_USER_TIMEBANK";
export const DELETE_USER_TIMEBANK_SUCCESS = "user/DELETE_USER_TIMEBANK_SUCCESS";
export const DELETE_USER_TIMEBANK_FAILURE = "user/DELETE_USER_TIMEBANK_FAILURE";

export const UPDATE_USER_TIMEBANK = "user/UPDATE_USER_TIMEBANK";
export const UPDATE_USER_TIMEBANK_SUCCESS = "user/UPDATE_USER_TIMEBANK_SUCCESS";
export const UPDATE_USER_TIMEBANK_FAILURE = "user/UPDATE_USER_TIMEBANK_FAILURE";

export const GET_PLAN_APPROVAL_PLANS = "approval/GET_PLAN_APPROVAL_PLANS";
export const GET_PLAN_APPROVAL_PLANS_SUCCESS =
  "approval/GET_PLAN_APPROVAL_PLANS_SUCCESS";
export const GET_PLAN_APPROVAL_PLANS_FAILURE =
  "approval/GET_PLAN_APPROVAL_PLANS_FAILURE";

export const GET_PLAN_APPROVAL_USER_DETAILS =
  "approvals/GET_PLAN_APPROVAL_USER_DETAILS";
export const GET_PLAN_APPROVAL_USER_DETAILS_SUCCESS =
  "approvals/GET_PLAN_APPROVAL_USER_DETAILS_SUCCESS";
export const GET_PLAN_APPROVAL_USER_DETAILS_FAILURE =
  "approvals/GET_PLAN_APPROVAL_USER_DETAILS_FAILURE";

export const CREATE_PLAN_SCHEDULE = "planning/CREATE_PLAN_SCHEDULE";
export const CREATE_PLAN_SCHEDULE_SUCCESS =
  "planning/CREATE_PLAN_SCHEDULE_SUCCESS";
export const CREATE_PLAN_SCHEDULE_FAILURE =
  "planning/CREATE_PLAN_SCHEDULE_FAILURE";

export const GET_PLAN_SCHEDULES = "planning/GET_PLAN_SCHEDULES";
export const GET_PLAN_SCHEDULES_SUCCESS = "planning/GET_PLAN_SCHEDULES_SUCCESS";
export const GET_PLAN_SCHEDULES_FAILURE = "planning/GET_PLAN_SCHEDULES_FAILURE";

export const GET_PLAN_SCHEDULE = "planning/GET_PLAN_SCHEDULE";
export const GET_PLAN_SCHEDULE_SUCCESS = "planning/GET_PLAN_SCHEDULE_SUCCESS";
export const GET_PLAN_SCHEDULE_FAILURE = "planning/GET_PLAN_SCHEDULE_FAILURE";

export const PUT_PLAN_SCHEDULE = "planning/PUT_PLAN_SCHEDULE";
export const PUT_PLAN_SCHEDULE_SUCCESS = "planning/PUT_PLAN_SCHEDULE_SUCCESS";
export const PUT_PLAN_SCHEDULE_FAILURE = "planning/PUT_PLAN_SCHEDULE_FAILURE";

export const DELETE_PLAN_SCHEDULE = "planning/DELETE_PLAN_SCHEDULE";
export const DELETE_PLAN_SCHEDULE_SUCCESS =
  "planning/DELETE_PLAN_SCHEDULE_SUCCESS";
export const DELETE_PLAN_SCHEDULE_FAILURE =
  "planning/DELETE_PLAN_SCHEDULE_FAILURE";
