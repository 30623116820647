import * as types from "./types";
import { core } from "./core";

export const getGeoFences = (data) => {
  return core.API(
    "GET",
    `/geo-fences/?fenceable_type=${data.type}&fenceable_id=${data.id}`,
    types.GET_GEOFENCES,
    types.GET_GEOFENCES_SUCCESS,
    types.GET_GEOFENCES_FAILURE
  );
};

export const getGeoFence = (id) => {
  return core.API(
    "GET",
    `/geo-fence/${id}`,
    types.GET_GEOFENCE,
    types.GET_GEOFENCE_SUCCESS,
    types.GET_GEOFENCE_FAILURE
  );
};

export const deleteGeoFence = (id) => {
  return core.API(
    "DELETE",
    `/geo-fence/${id}`,
    types.DELETE_GEOFENCE,
    types.DELETE_GEOFENCE_SUCCESS,
    types.DELETE_GEOFENCE_FAILURE
  );
};

export const updateGeoFence = (id, data) => {
  return core.API(
    "PUT",
    `/geo-fence/${id}/`,
    types.UPDATE_GEOFENCE,
    types.UPDATE_GEOFENCE_SUCCESS,
    types.UPDATE_GEOFENCE_FAILURE,
    data
  );
};

export const addGeoFence = (data) => {
  return core.API(
    "POST",
    `/geo-fence/`,
    types.ADD_GEOFENCE,
    types.ADD_GEOFENCE_SUCCESS,
    types.ADD_GEOFENCE_FAILURE,
    data
  );
};
