import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GuestTab from "./components/GuestTab";
import EmployeeTab from "./components/EmployeeTab";

import i18next from "i18next";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { actions as authActions } from "@/utils/auth";
import { CommonHeaderInList } from "@/common/CommonHeaderInList";

import { getItem } from "@/utils/localStorage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "20px 30px 30px 30px",
    borderRadius: 8,
  },
  appBar: {
    borderRadius: 8,
    boxShadow: theme.customShadows.hourCard,
  },
  tabContainer: {
    width: "100%",
    display: "flex",
  },
  swipableTab: {
    padding: "8px 12px",
    transition: "0.1s ease-in-out",
    marginRight: 14,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const Attendance = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [openAttendanceAddModal, setOpenAttendanceAddModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const isAdmin =
    getItem("userRole") && getItem("userRole") === "admin" ? true : false;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleAddAttendanceClick = () => {
    setOpenAttendanceAddModal(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const isToday =
    moment(selectedDate).format("DD,MM,YY") === moment().format("DD,MM,YY")
      ? true
      : false;

  return !authActions.getAccessToken() ? (
    <Redirect to={`/token/${authActions.getCompanyToken()}`} />
  ) : (
    <Fragment>
      <CommonHeaderInList
        title={i18next.t("Attendance")}
        onButtonClick={(e) => {
          e.preventDefault();
          handleAddAttendanceClick();
        }}
        buttonTitle={value === 0 ? i18next.t("Attendance") : null}
        isDisabledButton={!isToday}
        removeFilter={true}
      />

      <div className={classes.root}>
        {!isAdmin ? (
          <Typography>You do not have access to this page</Typography>
        ) : (
          <Fragment>
            <AppBar
              position="static"
              color="default"
              className={classes.appBar}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                //variant="fullWidth"
                centered
                className={classes.tabContainer}
              >
                <Tab
                  className={classes.swipableTab}
                  label={i18next.t("Employee")}
                  {...a11yProps(1)}
                />
                <Tab
                  className={classes.swipableTab}
                  label={i18next.t("Guest")}
                  {...a11yProps(0)}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <EmployeeTab
                  openAttendanceAddModal={openAttendanceAddModal}
                  setOpenAttendanceAddModal={setOpenAttendanceAddModal}
                  handleDateChange={handleDateChange}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <GuestTab />
              </TabPanel>
            </SwipeableViews>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Attendance;
