import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import validate from "validate.js";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";

import { getCheckInGuestList } from "@/services/guest";

import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  selectInput: {
    display: "flex",
    padding: "0px 15px 0px 10px",
    height: "50px",
  },
  /*selectPaper: {
    position: "absolute",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    zIndex: 1001,
    left: 0,
    right: 0,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  selectMenuItem: {
    fontSize: 16,
  },
  errorText: {
    color: theme.palette.error.main,
    padding: "0px 5px 10px 15px",
  },
}));

let schema = {
  name: {
    presence: { allowEmpty: false },
    length: {
      minimum: 3,
      maximum: 64,
    },
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
    length: {
      maximum: 64,
    },
  },
  visiting: {
    presence: { allowEmpty: false },
    length: {
      maximum: 64,
    },
  },
  company: {
    presence: { allowEmpty: false },
    length: {
      maximum: 64,
    },
  },
};

const GuestDetails = (props) => {
  const {
    className,
    checkInError,
    onGuestCheckin,
    isNewGuest,
    onNewGuest,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedGuest, setSelectedGuest] = useState();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      name: "",
      email: "",
      visiting: "",
      company: "",
    },
    touched: {},
    errors: {},
  });

  const loadGuestList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    const limit = 20;
    let queryString = `limit=${limit}&offset=${offset}&attendanceable_type=Guest`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    const response = await getCheckInGuestList(queryString);
    const result = await getGuestListOptions(response);
    return result;
  };

  const getGuestListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let entries = response.data.entries;
        let guestList = [];
        for (let i = 0; i < entries.length; i++) {
          let element = entries[i];

          // ignore same user entry (multiple check-in)
          let attendaceElement = null;
          if (
            element.guest_attendances &&
            element.guest_attendances.length > 0
          ) {
            attendaceElement = element.guest_attendances[0];
          }

          if (attendaceElement) {
            guestList.push({
              value: element.guest_id.toString(),
              label: element.guest_name,
              email: element.guest_email,
              visiting: attendaceElement.visiting,
              company: element.guest_company_name,
              attendaceId: attendaceElement.id,
            });
          }
        }

        resolve({
          options: guestList,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (selectedGuest && selectedGuest.value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          name: selectedGuest.label,
          email: selectedGuest.email ? selectedGuest.email : "",
          visiting: selectedGuest.visiting ? selectedGuest.visiting : "",
          company: selectedGuest.company ? selectedGuest.company : "",
        },
        touched: {
          email: true,
          visiting: true,
          company: true,
        },
      }));
    }
  }, [selectedGuest]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isNewGuest) {
      const postObj = {
        name: formState.values.name,
        email: formState.values.email,
        guest_company_name: formState.values.company,
        visiting: formState.values.visiting,
        company_id: sessionStorage.getItem("companyId")
          ? sessionStorage.getItem("companyId")
          : null,
      };
      onGuestCheckin(postObj, false);
    } else {
      const postObj = {
        visiting: formState.values.visiting,
      };
      onGuestCheckin(postObj, selectedGuest.value);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <CardHeader
          subheader={i18next.t("Enter guest details for check-in")}
          title={i18next.t("Guest details")}
        />
        {checkInError && (
          <Typography variant="body1" className={classes.errorText}>
            {checkInError}
          </Typography>
        )}
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MaterialAsyncPaginate
                defualtOptions
                classes={classes}
                textFieldProps={{
                  label: i18next.t("Name"),
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                name="guest"
                value={selectedGuest}
                loadOptions={async (search, loadedOptions) => {
                  return loadGuestList(search, loadedOptions);
                }}
                onChange={(option) => {
                  setSelectedGuest(option);
                  if (option.__isNew__) {
                    onNewGuest(true);
                  } else {
                    onNewGuest(false);
                  }
                }}
                isCreatable={true}
                autoFocus
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasError("email")}
                fullWidth
                label={i18next.t("Email")}
                name="email"
                onChange={handleChange}
                required
                type="email"
                value={formState.values.email}
                variant="outlined"
                disabled={!isNewGuest}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasError("company")}
                fullWidth
                label={i18next.t("Company name")}
                name="company"
                onChange={handleChange}
                required
                type="text"
                value={formState.values.company}
                variant="outlined"
                disabled={!isNewGuest}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasError("visiting")}
                fullWidth
                label={i18next.t("Visiting")}
                name="visiting"
                onChange={handleChange}
                required
                type="text"
                value={formState.values.visiting}
                variant="outlined"
                //disabled={!isNewGuest}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {i18next.t("Check-in")}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

GuestDetails.propTypes = {
  className: PropTypes.string,
};

export default GuestDetails;
