import React, { Fragment } from "react";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Typography,
  SvgIcon,
  Paper,
  Grid,
  Fab,
  FormControl,
} from "@mui/material";
import MaterialTextfield from "@/common/MaterialTextfield";
import DeleteModal from "@/common/deleteModal";
import Resizer from "react-image-file-resizer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LightBox from "@/common/LightBox";
import { withSnackbar } from "notistack";

import ResponseComponents from "./ResponseComponents";

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  listItem: {
    margin: "10px 20px",
    cursor: "pointer",
    maxWidth: 600,
  },
  spaceButtonContainer: {
    display: "flex",
    height: "120px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  centerButtonContainer: {
    textAlign: "center",
    position: "relative",
    justifyContent: "space-evenly",
    display: "flex",
    marginBottom: 5,
  },
  sliderStyle: {
    height: 120,
    overflow: "hidden",
    padding: 5,
    cursor: "pointer",
    margin: "0 auto",
    //borderRadius: "10px",
    transition: "height 0.3s ease-in-out",
  },
  sliderOverride: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    "& .slick-prev": {
      "&:before": {
        color: theme.palette.text.primary,
      },
    },
    "& .slick-next": {
      "&:before": {
        color: theme.palette.text.primary,
      },
    },
  },
  sliderImg: {
    width: "100%",
    display: "block",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  blockLeft: {
    padding: "10px 10px 10px 20px",
  },
  blockRight: {
    padding: "10px 20px 10px 10px",
  },
  svgIcon: {
    width: 18,
    height: 18,
  },
  fabButton: {
    width: 28,
    height: 28,
    minHeight: 28,
    borderRadius: 4,
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "#00000000",
    },
  },
  formControl: {
    marginBottom: 14,
    width: "100%",
  },
});

class QuestionAnswerBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
      lightboxIndex: 0,
      checkImageResponse: false,
      isFullScreen: false,
      openDeleteModal: false,
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.hmskCheckListReducer !== this.props.hmskCheckListReducer) {
      if (
        this.props.hmskCheckListReducer &&
        this.props.hmskCheckListReducer.imageSuccess &&
        this.state.checkImageResponse
      ) {
        this.setState({
          checkImageResponse: false,
        });

        if (this.props.hmskCheckListReducer.imageSuccess.data) {
          const { answers, index } = this.props;
          let images =
            answers[index] &&
            answers[index]["images"] &&
            answers[index]["images"].length > 0
              ? answers[index]["images"]
              : [];
          images.push(this.props.hmskCheckListReducer.imageSuccess.data);
          await this.props.onValueChange(index, "answers", "images", images);
          //await this.setState({
          //selectedItem: images.length - 1,
          //});
        } else {
          this.props.enqueueSnackbar(
            this.props.hmskCheckListReducer.imageSuccess.message,
            { variant: "success" }
          );
        }
      }

      if (
        this.props.hmskCheckListReducer &&
        this.props.hmskCheckListReducer.imageError &&
        this.state.checkImageResponse
      ) {
        this.setState({
          checkImageResponse: false,
        });
        this.props.enqueueSnackbar(
          this.props.hmskCheckListReducer.imageError.response.message,
          { variant: "error" }
        );
      }
    }
  };

  fileChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        1080,
        1920,
        "JPEG",
        80,
        0,
        async (uri) => {
          await this.props.actions.uploadImage({
            images_data: [
              {
                contents: uri,
                name: `image-${new Date().getTime()}`,
              },
            ],
          });
          await this.setState({ checkImageResponse: true });
        },
        "base64"
      );
    }
  };

  deleteImage = async () => {
    const { index, answers, onValueChange, checkListId } = this.props;

    let image_id =
      answers[index]["images"][this.state.selectedItem]["image_id"];
    const isExisting =
      answers[index]["images"][this.state.selectedItem]["existing"];

    if (image_id) {
      let recordId = "";
      let recordType = "";
      if (checkListId && isExisting) {
        recordId = checkListId;
        recordType = "Modules::Hmsk::CheckList";
      } else {
        recordId = sessionStorage.getItem("companyId")
          ? sessionStorage.getItem("companyId")
          : "";
        recordType = "Company";
      }

      const queryString = `image_id=${image_id}&record_id=${recordId}&record_type=${recordType}`;
      await this.props.actions.deleteImage(queryString);
      await this.setState({ checkImageResponse: true, openDeleteModal: false });

      onValueChange(
        index,
        "answers",
        "images",
        answers[index]["images"].filter((imageData) => {
          if (imageData.image_id === image_id) {
            return null;
          }
          return imageData;
        })
      );
    }
  };

  sliderChange = (current, next) => {
    this.setState({
      selectedItem: next,
    });
  };

  render = () => {
    const {
      index,
      classes,
      question,
      answers,
      disabled,
      isPreviewOff,
      onValueChange,
    } = this.props;

    const haveImages =
      answers &&
      answers[index] &&
      answers[index]["images"] &&
      answers[index]["images"].length > 0
        ? true
        : false;

    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.sliderChange,
    };

    const lightBoxImages = answers[index]?.["images"]?.map((img) =>
      img?.image_url ? baseUrl + img.image_url : ""
    );

    return (
      <Fragment>
        <Typography component="div" className={classes.listItem}>
          <Paper
            elevation={1}
            style={{
              borderBottom:
                answers &&
                answers[index] &&
                (answers[index]["answer_value"] || answers[index]["comments"])
                  ? "2px solid #18B24B"
                  : "none",
            }}
            className={classes.root}
          >
            <Grid container>
              <Grid item xs={8} className={classes.blockLeft}>
                <Typography component="div" style={{ marginBottom: 12 }}>
                  {`${index + 1}. ${question.question}`}
                </Typography>
                <Typography component="div">
                  <FormControl className={classes.formControl}>
                    <ResponseComponents
                      answer={answers && answers[index]}
                      onValueChange={onValueChange}
                      disabled={disabled}
                      index={index}
                      component={question.answer_type}
                    />
                  </FormControl>
                </Typography>
                <Typography component="div" style={{ marginBottom: 8 }}>
                  <MaterialTextfield
                    disabled={disabled}
                    label={i18next.t("Comment")}
                    type="text"
                    adornment="no_endAdornment"
                    value={
                      answers && answers[index] && answers[index]["comments"]
                        ? answers[index]["comments"]
                        : ""
                    }
                    onChange={(event) => {
                      this.props.onValueChange(
                        index,
                        "answers",
                        "comments",
                        event.target.value
                      );
                    }}
                    multiline={true}
                    maxRows={8}
                    maxLength={10000}
                  />
                </Typography>
              </Grid>

              <Grid item xs={4} className={classes.blockRight}>
                {haveImages && !isPreviewOff && this.state.isFullScreen && (
                  <Typography component="div">
                    <LightBox
                      currentImageIndex={this.state.lightboxIndex}
                      setCurrentImageIndex={(newIndex) =>
                        this.setState({ lightboxIndex: newIndex })
                      }
                      isOpen={true}
                      onClose={() => this.setState({ isFullScreen: false })}
                      images={lightBoxImages}
                    />
                  </Typography>
                )}
                <Typography component="div" className="answerBlockCarousel">
                  {!disabled && (
                    <Typography
                      component="div"
                      className={
                        haveImages
                          ? classes.centerButtonContainer
                          : classes.spaceButtonContainer
                      }
                    >
                      <Fab
                        size="small"
                        color="primary"
                        component="label"
                        disabled={disabled}
                        className={classes.fabButton}
                      >
                        <SvgIcon className={classes.svgIcon}>
                          <path d="M9,3L7.17,5H4A2,2 0 0,0 2,7V19A2,2 0 0,0 4,21H20A2,2 0 0,0 22,19V7A2,2 0 0,0 20,5H16.83L15,3M12,18A5,5 0 0,1 7,13A5,5 0 0,1 12,8A5,5 0 0,1 17,13A5,5 0 0,1 12,18M12,17L13.25,14.25L16,13L13.25,11.75L12,9L10.75,11.75L8,13L10.75,14.25" />
                        </SvgIcon>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={this.fileChangedHandler}
                        />
                      </Fab>
                      {haveImages && (
                        <Fab
                          size="medium"
                          color="primary"
                          disabled={disabled}
                          onClick={() => {
                            this.setState({ openDeleteModal: true });
                          }}
                          className={classes.fabButton}
                        >
                          <SvgIcon className={classes.svgIcon}>
                            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                          </SvgIcon>
                        </Fab>
                      )}
                    </Typography>
                  )}

                  {haveImages ? (
                    <div className={classes.sliderOverride}>
                      <Slider {...settings}>
                        {answers[index]["images"].map((data, d_index) => {
                          return (
                            <div className={classes.sliderStyle} key={d_index}>
                              <img
                                alt=""
                                src={`${baseUrl}${data.image_url}`}
                                className={classes.sliderImg}
                                onClick={() => {
                                  if (!isPreviewOff) {
                                    this.setState({
                                      lightboxIndex: d_index,
                                      isFullScreen: true,
                                    });
                                  }
                                }}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Typography>

        {haveImages && (
          <DeleteModal
            isOpen={this.state.openDeleteModal}
            handleCancel={() => {
              this.setState({ openDeleteModal: false });
            }}
            handleSubmit={this.deleteImage}
            reducer={this.props.hmskCheckListReducer}
          />
        )}
      </Fragment>
    );
  };
}

export default withStyles(styles)(withSnackbar(QuestionAnswerBlock));
