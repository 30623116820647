import i18next from "i18next";

const errorMessages = {
  required: i18next.t("Field is required"),
  passwordMismatch: i18next.t("Password does not match"),
  invalidPassword: i18next.t(
    "Your password must be at least 6 characters long, contain at least one number and have a mixture of uppercase and lowercase letters."
  ),
  invalid: i18next.t("Please enter a valid value"),
  invalidEmail: i18next.t("Please enter a valid email"),
  invalidPhone: i18next.t("Please enter a valid phone number"),
  user_exist: i18next.t("A user with this email already exists!"),
  invalidOrganisationNumber: i18next.t(
    "Organisation number must contain minimum 9 characters"
  ),
  duplicateEmail: i18next.t("Duplicate email"),
  invalidBirthID: i18next.t("Birth ID must contain 11 digits"),
  invalidBankAccountNumber: i18next.t(
    "Bank account number must contain 11 digits"
  ),
};

export default errorMessages;
