import { combineReducers } from "redux";

import { profile } from "./reducers/profile";
import { commonReducer } from "./reducers/common";
import { departmentReducer } from "./reducers/department";
import { hourReducer } from "./reducers/hour";
import { companyReducer } from "./reducers/company";
import { hmskTemplateReducer } from "./reducers/hmskTemplate";
import { hmskCheckListReducer } from "./reducers/hmskCheckList";
import { task } from "./reducers/task";
import { projectReducer } from "./reducers/projects";
import { customer } from "./reducers/customer";
import { hourTypes } from "./reducers/hourTypes";
import { planningReducer } from "./reducers/planning";
import { addonTypes } from "./reducers/addonTypes";
import { geofenceReducer } from "./reducers/geoFence";
import { filters } from "./reducers/filters";
import { logOut } from "./reducers/logout";

const appReducer = combineReducers({
  logOut,
  projectReducer,
  profile,
  commonReducer,
  departmentReducer,
  hourReducer,
  task,
  customer,
  hourTypes,
  companyReducer,
  hmskTemplateReducer,
  hmskCheckListReducer,
  planningReducer,
  addonTypes,
  geofenceReducer,
  filters,
});

export const rootReducer = (state, action) => {
  if (action.type === "logout/POST_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
