import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, IconButton, Button } from "@mui/material";
import DeviceSetting from "./components/DeviceSetting";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  formContainer: {
    //paddingTop: 100,
    display: "flex",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const ProjectSetting = (props) => {
  const { history } = props;

  const classes = useStyles();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentHeader}>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIcon />
        </IconButton>
      </div>

      <div className={classes.contentBody}>
        <Grid container className={classes.formContainer}>
          <Grid item xl={6} lg={8} md={8} sm={10} xs={10}>
            <DeviceSetting history={history} />
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                onClick={handleBack}
                sx={{ textTransform: "uppercase" }}
              >
                {i18next.t("Home")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProjectSetting;
