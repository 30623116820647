import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import validate from "validate.js";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  Grid,
  TextField,
  Link,
  Stack,
  Avatar,
  Box,
  Typography,
  Divider,
  MenuList,
  MenuItem,
  Button,
  Hidden,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import i18next from "i18next";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: 216,
    borderRadius: 8,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    marginBottom: 30,
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  pageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginInline: "auto",
  },
  cardHeader: {
    padding: 12,
  },
  userName: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  rowItem: {
    minHeight: 40,
  },
  activeRow: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}1a`,
  },
  pinButtonDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pinButton: {
    height: 60,
    margin: 12,
    fontSize: 24,
    fontWeight: 700,
    borderRadius: 8,
    // minWidth: 52,
  },
  errorContainer: {
    marginInline: 30,
  },
  enterPinButton: {
    height: 60,
    width: 60,
    margin: 12,
    fontSize: 16,
    fontWeight: 700,
  },
  clearPinButton: {
    height: 60,
    width: 60,
    margin: 12,
    color: theme.palette.error.main,
  },
  astericContainer: {
    display: "flex",
    justifyContent: "center",
  },
  asteric: {
    fontSize: 60,
    fontWeight: 700,
    padding: 8,
    height: 60,
    marginBottom: 24,
    color: theme.palette.text.disabled,
  },
  astericStylish: {
    color: theme.palette.primary.main,
  },
  astericError: {
    color: theme.palette.error.main,
  },
  btnContainer: {
    paddingInline: 12,
    paddingBottom: 8,
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingInline: 32,
      paddingBottom: 24,
    },
  },
  astericDivider: {
    marginRight: 40,
    marginLeft: 40,
    backgroundColor: theme.palette.primary.main,
  },
  astericDividerError: {
    backgroundColor: theme.palette.error.main,
  },
  pinText: {
    marginTop: 24,
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
  },
  textField: {
    // margin: theme.spacing(2),
    // marginTop: theme.spacing(2),
  },
  errorText: {
    marginTop: 16,
    marginBottom: 12,
    color: theme.palette.error.main,
    padding: "0px 5px 10px 24px",
  },
}));

let schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {},
  pin: {},
};

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const SignInForm = (props) => {
  const {
    loginError,
    handleSignIn,
    userPin,
    // onForgotPIN,
    handleAzureClick,
    setUserPin,
  } = props;

  const classes = useStyles();

  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const passwordSign = ["*", "*", "*", "*"];

  const [error, setError] = useState(loginError);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      email: getItem("userEmail") ? getItem("userEmail") : "",
      password: "",
      pin: "",
      avatarUrl: getItem("avatarUrl") ? getItem("avatarUrl") : "",
      userName: getItem("userName") ? getItem("userName") : "",
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (userPin) {
      setUserPin(true);
    }
  }, [userPin, setUserPin]);

  useEffect(() => {
    setError(loginError);

    if (loginError && userPin) {
      handleClear();
    }
  }, [loginError, userPin]);

  if (userPin) {
    schema["pin"] = {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 4,
        minimum: 4,
      },
    };
    schema["password"] = {};
  } else {
    schema["pin"] = {};
    schema["password"] = {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 128,
      },
    };
  }

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  // const handleForgotPIN = (event) => {
  //   event.preventDefault();
  //   onForgotPIN();
  // };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleButtonClick = (num) => {
    if (formState.values.pin.length < 4) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          pin: formState.values.pin + `${num}`,
        },
        touched: {
          ...formState.touched,
          pin: true,
        },
      }));
    }
  };

  const handleClear = () => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        pin: "",
      },
    }));
    setError("");
  };

  const handleSubmit = () => {
    handleSignIn({
      email: formState.values.email,
      password: formState.values.password,
      pin: formState.values.pin,
    });
  };

  return (
    <div className={classes.content}>
      {/* {loginError && (
        <Typography variant="body1" className={classes.errorText}>
          {loginError}
        </Typography>
      )} */}
      <div className={classes.contentBody}>
        <Grid container className={classes.pageContainer} spacing={3}>
          <Hidden lgDown>
            <Grid item lg={1}></Grid>
          </Hidden>

          <Grid item lg={5} sm={6} xs={10}>
            <Card className={classes.root}>
              <Stack
                direction="row"
                alignItems="center"
                key={formState.values.id}
                className={classes.cardHeader}
              >
                <Avatar
                  src={
                    formState.values.avatarUrl
                      ? `${baseUrl}${formState.values.avatarUrl}`
                      : ""
                  }
                  sx={{ width: 48, height: 48 }}
                />
                <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 0.5 }}
                    className={classes.userName}
                    noWrap
                  >
                    {formState.values.userName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {formState.values.email}
                  </Typography>
                </Box>
              </Stack>
              <Divider style={{ marginRight: 12, marginLeft: 12 }} />
              <Stack>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setUserPin(true);
                    }}
                    className={`${classes.rowItem} ${
                      userPin ? classes.activeRow : ""
                    }`}
                  >
                    {i18next.t("Sign in with PIN")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setUserPin(false);
                    }}
                    className={`${classes.rowItem} 
                    ${!userPin ? classes.activeRow : ""}`}
                  >
                    {i18next.t("Sign in with password")}
                  </MenuItem>
                  <MenuItem
                    onClick={handleAzureClick}
                    className={`${classes.rowItem}`}
                  >
                    {i18next.t("Sign in with Azure AD")}
                  </MenuItem>
                </MenuList>
              </Stack>
            </Card>
          </Grid>

          <Grid item lg={5} sm={6} xs={10}>
            {!userPin ? (
              <Card className={classes.root}>
                <Typography variant="h5" className={classes.pinText} noWrap>
                  {i18next.t("Enter your password")}
                </Typography>
                <Grid item xs={12} style={{ margin: 24 }}>
                  <TextField
                    autoFocus
                    className={classes.textField}
                    error={hasError("password")}
                    fullWidth
                    label={i18next.t("Password")}
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ""}
                    variant="outlined"
                    data-sentry-ignore
                  />
                  <div style={{ marginTop: 16 }}>
                    <Button
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      {i18next.t("Sign in")}
                    </Button>
                  </div>
                  <div style={{ marginTop: 12 }}>
                    <Link
                      href="https://app.visualregistration.no/forgotPassword"
                      target="_blank"
                      rel="noopener"
                      color="primary"
                      variant="subtitle2"
                      underline="hover"
                    >
                      {i18next.t("Forgot password?")}
                    </Link>
                  </div>
                </Grid>
              </Card>
            ) : (
              <Card className={classes.root}>
                <Typography variant="h5" className={classes.pinText} noWrap>
                  {i18next.t("Enter your PIN")}
                </Typography>
                <div className={classes.astericContainer}>
                  {passwordSign.map((sign, index) => {
                    return (
                      <Typography
                        key={index}
                        className={`${classes.asteric} ${
                          index < formState.values.pin.length
                            ? error
                              ? classes.astericError
                              : classes.astericStylish
                            : ""
                        }`}
                      >
                        {sign}
                      </Typography>
                    );
                  })}
                </div>
                <Divider
                  className={`${classes.astericDivider} ${
                    loginError ? classes.astericDividerError : ""
                  }`}
                />

                <Grid container className={classes.btnContainer}>
                  {buttons.map((btn, index) => {
                    return (
                      <Grid item xs={4} key={index} data-sentry-block>
                        <div className={classes.pinButtonDiv}>
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.pinButton}
                            onClick={() => handleButtonClick(btn)}
                          >
                            {btn}
                          </Button>
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={4}>
                    <div className={classes.pinButtonDiv}>
                      <Button
                        color="error"
                        variant="outlined"
                        className={classes.clearPinButton}
                        onClick={handleClear}
                        startIcon={<ClearIcon />}
                        sx={{
                          "& .MuiButton-startIcon": {
                            margin: "0px",
                            "& > *:first-of-type": {
                              fontSize: "28px",
                            },
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.pinButtonDiv}>
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.pinButton}
                        onClick={() => handleButtonClick(0)}
                      >
                        0
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.pinButtonDiv}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.enterPinButton}
                        disabled={!formState.isValid}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {i18next.t("Enter")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>

          <Hidden lgDown>
            <Grid item lg={1}></Grid>
          </Hidden>
        </Grid>
      </div>
    </div>
  );
};

SignInForm.propTypes = {
  className: PropTypes.string,
};

export default SignInForm;
