import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Stack,
  LinearProgress,
  Divider,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CelebrationIcon from "@mui/icons-material/Celebration";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import Label from "@/common/Label";
import i18next from "i18next";
import { getItem } from "@/utils/localStorage";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingRight: 10,
    paddingLeft: 10,
  },
  header: {
    fontSize: 20,
    paddingLeft: 12,
    paddingTop: 16,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 16,
  },
  iconBlock: {
    display: "block",
    textAlign: "center",
  },
  blockText: {
    fontSize: 14,
    fontWeight: 700,
  },
  hourIcon: {
    color: theme.palette.primary.main,
    height: "20px",
    width: "20px",
  },
  addonIcon: {
    color: theme.palette.secondary.main,
    height: "20px",
    width: "20px",
  },
  blackIcon: {
    color: theme.palette.text.primary,
    height: "20px",
    width: "20px",
  },
  title: {
    fontSize: 20,
  },
  barContainer: {
    padding: "12px 12px 32px 12px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  actionButton: {
    fontSize: 16,
    fontWeight: 700,
    padding: "16px 60px",
    margin: "13px",
  },
}));

function Summary({ userId, selectedUser, hourReducer, addonModuleAssigned }) {
  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  const {
    hourSummary,
    addonSummary,
    hourTypeSummaryList,
    addonTypeSummaryList,
    fixedAddonTypeSummaryList,
  } = hourReducer;

  // convert hour type and addon data to percentage to use in progress bar ...
  const isAdminUser = getItem("userRole") === "admin";

  const currentUserId = selectedUser
    ? selectedUser.value
    : isAdminUser && userId
      ? userId
      : parseInt(getItem("userId"), 10);

  const hourTypeSummary = hourTypeSummaryList?.[currentUserId] || [];
  const addonTypeSummary =
    addonTypeSummaryList?.[currentUserId]?.map((value) => ({
      ...value,
      isAddon: true,
    })) || [];
  const fixedAddonTypeSummary =
    fixedAddonTypeSummaryList?.[currentUserId]?.map((value) => ({
      ...value,
      isFixedAddon: true,
    })) || [];

  const hourTypeSummmaryNumber = hourTypeSummary.map((i) => Number(i.amount));
  const addonTypeSummaryNumber = addonTypeSummary.map((i) => Number(i.amount));

  const maxHours = Math.max(
    ...hourTypeSummmaryNumber,
    ...addonTypeSummaryNumber
  );

  const hourTypesListPercent = hourTypeSummmaryNumber.map(
    (hour) => (hour * 100) / maxHours
  );
  const addonTypesListPercent = addonTypeSummaryNumber.map(
    (hour) => (hour * 100) / maxHours
  );

  const newHourTypeSummary = hourTypeSummary
    .map((percent, i) => {
      return { ...percent, percentage: hourTypesListPercent[i] };
    })
    .sort((a, b) => b.percentage - a.percentage);

  const newAddonTypeSummary = addonTypeSummary
    .map((percent, i) => {
      return (percent = {
        ...percent,
        percentage: addonTypesListPercent[i],
      });
    })
    .sort((a, b) => b.percentage - a.percentage);

  const totalHourTypes = newHourTypeSummary.length;
  const totalTimeAddons = newAddonTypeSummary.length;
  const totalFixedAddons = fixedAddonTypeSummary.length;
  const totalCount = totalHourTypes + totalTimeAddons + totalFixedAddons;

  // create an array of exactly 3 items from Hour type and Addon type (if Hour type has less than 3 items)
  const itemsToShow = 3;
  const typeArray = [];
  let itemCounter =
    totalHourTypes > 0
      ? totalHourTypes >= itemsToShow
        ? itemsToShow
        : totalHourTypes
      : 0; // get upto 3 items from hour type array

  for (let i = 0; i < itemCounter; i++) {
    typeArray.push(newHourTypeSummary[i]);
  }

  // if hour type array has < 3 items, consider addon type array and remaining items form it
  if (itemCounter < itemsToShow) {
    const itemsRemained = itemsToShow - itemCounter;
    const itemToIterate =
      totalTimeAddons > 0
        ? totalTimeAddons >= itemsRemained
          ? itemsRemained
          : totalTimeAddons
        : 0; // get remaining items from addon type array

    for (let i = 0; i < itemToIterate; i++) {
      typeArray.push(newAddonTypeSummary[i]);
    }

    itemCounter += itemToIterate;
  }

  // if hour+addon type array has < 3 items, consider fixed addon type array and aremaining items form it
  if (itemCounter < itemsToShow) {
    const itemsRemained = itemsToShow - itemCounter;
    const itemToIterate =
      totalFixedAddons > 0
        ? totalFixedAddons >= itemsRemained
          ? itemsRemained
          : totalFixedAddons
        : 0; // get remaining items from fixed addon type array

    for (let i = 0; i < itemToIterate; i++) {
      typeArray.push(fixedAddonTypeSummary[i]);
    }

    itemCounter += itemToIterate;
  }

  const showSummaryDialog = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.header}>
          {i18next.t("Summary")}
        </Typography>
        <div className={classes.iconContainer}>
          <div className={classes.iconBlock}>
            <WatchLaterIcon className={classes.hourIcon} />
            <Typography className={classes.blockText}>
              <DecimalToHoursMin>{hourSummary?.hours || 0}</DecimalToHoursMin>
            </Typography>
          </div>

          {addonModuleAssigned && (
            <div className={classes.iconBlock}>
              <AddToPhotosIcon className={classes.addonIcon} />
              <Typography className={classes.blockText}>
                <DecimalToHoursMin>
                  {addonSummary?.addons || 0}
                </DecimalToHoursMin>
              </Typography>
            </div>
          )}

          <div className={classes.iconBlock}>
            <CelebrationIcon className={classes.blackIcon} />
            <Typography className={classes.blockText}>
              {hourSummary?.adjusted_holiday || 0}
            </Typography>
          </div>

          <div className={classes.iconBlock}>
            <HourglassBottomIcon className={classes.blackIcon} />
            <Typography className={classes.blockText}>
              <DecimalToHoursMin>
                {hourSummary?.time_bank || 0}
              </DecimalToHoursMin>
            </Typography>
          </div>
        </div>

        <Divider sx={{ mt: 1.5, mb: 1.5 }} />

        <Stack
          spacing={1}
          direction={{
            xs: "row",
            sm: "row",
            md: "column",
            lg: "column",
            xl: "row",
          }}
          sx={{ justifyContent: "space-between" }}
        >
          <Label variant="outlined" color="primary">
            {i18next.t(`${totalHourTypes} Hour types`)}
          </Label>
          <Label variant="outlined" color="primary">
            {i18next.t(`${totalTimeAddons} Time addons`)}
          </Label>
          <Label variant="outlined" color="primary">
            {i18next.t(`${totalFixedAddons} Fixed addons`)}
          </Label>
        </Stack>

        {/* Linear progress for hour types and addon ... */}
        <div>
          <Stack spacing={2} sx={{ p: 1, pb: 4, pt: 3 }}>
            {itemCounter > 0 &&
              typeArray.map((item, i) => {
                return item.isFixedAddon ? (
                  <FixedProgressBar
                    key={i}
                    name={item?.name || ""}
                    amount={item?.measurement_value || 0}
                    label={item?.measurement}
                    color={item?.color}
                    isLast={i === typeArray.length - 1}
                  />
                ) : (
                  <LinearProgressBar
                    key={i}
                    name={item?.name || ""}
                    amount={item?.amount || 0}
                    percentage={item?.percentage}
                    color={item?.isAddon ? "secondary" : "primary"}
                  />
                );
              })}

            {totalCount === 0 && (
              <Stack spacing={3} style={{ paddingLeft: 8 }}>
                <Typography variant="body2">
                  {i18next.t("No data to display")}
                </Typography>
              </Stack>
            )}

            {totalCount > 3 && (
              <Button
                fullWidth
                onClick={showSummaryDialog}
                color="primary"
                variant="outlined"
              >
                {i18next.t("View full summary")}
              </Button>
            )}
          </Stack>
        </div>
      </div>
      <TypeDialog
        showDialog={showDialog}
        onCloseDialog={onCloseDialog}
        hourTypes={newHourTypeSummary}
        addonTypes={newAddonTypeSummary}
        fixedAddonTypes={fixedAddonTypeSummary}
      />
    </>
  );
}

function LinearProgressBar({ name, amount, percentage, color }) {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          {name}
        </Typography>
        <Typography variant="body2">
          <DecimalToHoursMin>{amount}</DecimalToHoursMin>
        </Typography>
      </Stack>

      <LinearProgress variant="determinate" value={percentage} color={color} />
    </Stack>
  );
}

function FixedProgressBar({ name, amount, label, color, isLast }) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" style={{ flexGrow: 1 }}>
          {name}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" style={{ marginRight: 6 }}>
            {amount}
          </Typography>
          <Label variant="custom" hexColor={color} height="small">
            {label}
          </Label>
        </div>
      </Stack>

      {!isLast && (
        <LinearProgress
          variant="determinate"
          value={0}
          color={"inherit"}
          sx={{ height: "1px", opacity: "0.4" }}
        />
      )}
    </Stack>
  );
}

function TypeDialog({
  hourTypes,
  addonTypes,
  fixedAddonTypes,
  showDialog,
  onCloseDialog,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={showDialog}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={onCloseDialog}
    >
      <DialogContent>
        <Typography variant="h5">{i18next.t("Hour types")}</Typography>
        <Stack spacing={3.5} sx={{ p: 1.5, pb: 4 }}>
          {hourTypes.length > 0 ? (
            hourTypes.map((item, i) => {
              return (
                <LinearProgressBar
                  key={i}
                  name={item?.name || ""}
                  amount={item?.amount || 0}
                  percentage={item?.percentage}
                  color={"primary"}
                />
              );
            })
          ) : (
            <Typography variant="body2">
              {i18next.t("No data to display")}
            </Typography>
          )}
        </Stack>

        <Typography variant="h5">{i18next.t("Time addons")}</Typography>
        <Stack spacing={3.5} sx={{ p: 1.5, pb: 4 }}>
          {addonTypes.length > 0 ? (
            addonTypes.map((item, i) => {
              return (
                <LinearProgressBar
                  key={i}
                  name={item?.name || ""}
                  amount={item?.amount || 0}
                  percentage={item?.percentage}
                  color={"secondary"}
                />
              );
            })
          ) : (
            <Typography variant="body2">
              {i18next.t("No data to display")}
            </Typography>
          )}
        </Stack>

        <Typography variant="h5">{i18next.t("Fixed addons")}</Typography>
        <Stack spacing={2} sx={{ p: 1.8, justifyItems: "center" }}>
          {fixedAddonTypes.length > 0 ? (
            fixedAddonTypes.map((item, i) => {
              return (
                <FixedProgressBar
                  key={i}
                  name={item?.name || ""}
                  amount={item?.measurement_value || 0}
                  label={item?.measurement}
                  color={item?.color}
                  isLast={i === fixedAddonTypes.length - 1}
                />
              );
            })
          ) : (
            <Typography variant="body2">
              {i18next.t("No data to display")}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          type="submit"
          onClick={onCloseDialog}
          size="large"
          variant="contained"
          className={classes.actionButton}
        >
          {i18next.t("Done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Summary;
