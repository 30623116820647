import { get, put, post } from "@/utils/api.config.js";
import { actions as authActions } from "@/utils/auth.js";

export const getGuestList = (queryString) => {
  const request = {
    subUrl: `/attendance/guests/${queryString ? `?${queryString}` : ``}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const getCheckInGuestList = (queryString) => {
  const request = {
    subUrl: `/attendance/v2/attendances/${
      queryString ? `?${queryString}` : ``
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const createGuest = (requestBody) => {
  const request = {
    subUrl: `/attendance/guest`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const getGuestAttendance = (queryString) => {
  const request = {
    subUrl: `/attendance/v2/attendances/${
      queryString ? `?${queryString}` : ``
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};

export const postGuestAttendance = (requestBody) => {
  const request = {
    subUrl: `/attendance/attendance`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return post(request);
};

export const updateGuestAttendance = (id, requestBody) => {
  const request = {
    subUrl: `/attendance/attendance/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
    data: requestBody,
  };
  return put(request);
};

export const getCompanySettings = () => {
  const request = {
    subUrl: `/attendance/company-settings`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
      "Company-Token": authActions.getCompanyToken(),
    },
  };
  return get(request);
};
