import React from "react";
import i18next from "i18next";
import makeStyles from "@mui/styles/makeStyles";
import { Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  checkInButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    fontSize: 16,
    fontWeight: 700,
    padding: "14px 16px",
    borderRadius: "0.5rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
    minWidth: 170,
  },
}));

const CheckInButton = (props) => {
  const classes = useStyles();

  const { allowCheckIn, onButtonClick } = props;

  return (
    allowCheckIn && (
      <div className={classes.checkInButtonContainer}>
        <Button
          aria-label="check-in"
          onClick={() => {
            onButtonClick(false);
          }}
          disabled={!allowCheckIn}
          id="check-in-fab-button"
          className={`${classes.button}`}
          variant="contained"
        >
          {i18next.t("Check in")}
        </Button>
      </div>
    )
  );
};

export default CheckInButton;
