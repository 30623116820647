import React, { Fragment } from "react";
import i18next from "i18next";
import useToggleMenubar from "@/hooks/useToggleMenubar";
import AdditionalHourFilter from "./AdditionalHourFilter";
import { Typography, AppBar, IconButton, Toolbar, Hidden } from "@mui/material";
import { withStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";

const styles = (theme) => ({
  appbar: {
    zIndex: 900,
    position: "sticky",
    top: -5,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
  },
  toolbar: {
    position: "sticky",
    top: -5,
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 3),
    height: 64,
  },
  title: {
    color: theme.palette.text.primary,
    marginRight: 16,
  },
  /*btnHoursPerType: {
    color: "#ffffff",
    borderColor: "#ffffff",
    fontSize: 12,
  },
  viewContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 15,
    marginRight: 15,
  },
  roundBackIcon: {
    fontSize: "1.6rem",
    color: theme.palette.text.primary,
  },
  toolbarIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  icon: {
    cursor: "pointer",
  },
  middleGrid: {
    paddingTop: "7px",
  },
  badge: {
    top: -2,
    color: theme.palette.text.primary,
    backgroundColor: "#2D99FF",
    boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.0),
       0px 6px 10px 0px rgba(0,0,0,0.12),
        0px 1px 18px 0px rgba(0,0,0,0.10)`,
  },
  selectInput: {
    display: "flex",
    padding: "0px 10px 0px 10px",
    minHeight: 48,
    height: "auto",
    fontSize: 14,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  selectPaper: {
    position: "absolute",
    overflow: "visible",
    marginTop: theme.spacing(),
    zIndex: 1001,
    left: 0,
    right: 0,
  },
  selectMenuItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 16,
  },*/
  toggleButton: {
    color: theme.palette.text.primary,
  },
});

const MobileMenuButton = withStyles(styles)((props) => {
  const { onToggleMenubar } = useToggleMenubar();
  const { classes } = props;

  return (
    <IconButton
      onClick={() => {
        onToggleMenubar();
      }}
      id="menu-toggle-button"
      size="large"
      sx={{
        marginRight: "8px",
      }}
      className={classes.toggleButton}
    >
      <MenuIcon />
    </IconButton>
  );
});

class HourFilters extends React.Component {
  componentDidMount() {}

  render() {
    const { classes, showOnlyDashboard } = this.props;

    return (
      <Fragment>
        <AppBar elevation={0} className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <MobileMenuButton />
            <Typography variant="h4" className={classes.title}>
              {i18next.t("Hours")}
            </Typography>

            {!showOnlyDashboard && (
              <Hidden lgDown>
                <AdditionalHourFilter {...this.props} />
              </Hidden>
            )}
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(HourFilters);
