import React from "react";
import spinner from "@/assets/images/spinner.svg";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  overlayDiv: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    opacity: "0.8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    //left: 0,
    zIndex: 1001,
  },
  overlaySpinner: {
    width: 100,
  },
}));

const OverlayIndicator = ({ isLoading }) => {
  const classes = useStyles();
  return (
    isLoading && (
      <div className={classes.overlayDiv} style={{ zIndex: 1001 }}>
        <img
          src={spinner}
          className={classes.overlaySpinner}
          alt="Loading..."
        />
      </div>
    )
  );
};

export default OverlayIndicator;
