import React, { Component, Fragment } from "react";
import { withStyles } from "@mui/styles";
import { Typography, SvgIcon, Paper, Tooltip, Button } from "@mui/material";
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded";
import handleFileDownload from "@/utils/handleFileDownload";
import moment from "moment";
import i18next from "i18next";

const styles = (theme) => ({
  planCard: {
    minWidth: 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 2px 8px 0 rgba(158,158,158,0.35)",
    cursor: "pointer",
  },
  icon: {
    fontSize: 18,
  },
  cardText: {
    fontSize: 12,
  },
  flexLine: {
    display: "flex",
    alignItems: "center",
  },
  cardIconContainer: {
    paddingRight: 3,
    display: "flex",
  },
  cardIconContainer1: {
    padding: "0px 7px 0px 3px",
    display: "flex",
  },
  separator: {
    marginLeft: 5,
  },
  separatorV: {
    marginTop: 5,
  },
  tooltip: {
    fontSize: 12,
    whiteSpace: "pre-line",
  },
  tooltipContainer: {
    marginLeft: 15,
    cursor: "pointer",
    display: "flex",
  },
  requestContainer: {
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    display: "flex",
    borderRadius: "0px 0px 4px 4px",
  },
  requestBlock: {
    fontWeight: 500,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: 8,
  },
  planContainer: {
    height: "100%",
    "& > :not(:last-child)": {
      marginBottom: "8px",
    },
  },
  cardBottomLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  doneIcon: {
    cursor: "pointer",
    fontSize: 14,
    marginLeft: 5,
    color: theme.palette.text.secondary,
  },
  avatar: {
    width: 24,
    height: 24,
  },
  bottomBorderDiv: {
    height: 4,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginTop: 5,
  },
  tooltipIcon: {
    fontSize: 16,
    marginLeft: 4,
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
});

class PlanCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTooltip: [],
    };
  }

  handleTooltipClose = (id) => {
    const openFlags = this.state.openTooltip;
    openFlags[id] = false;
    this.setState({
      openTooltip: openFlags,
    });
  };

  handleTooltipOpen = (id) => {
    const openFlags = this.state.openTooltip;
    openFlags[id] = true;
    this.setState({
      openTooltip: openFlags,
    });
  };

  render() {
    const { classes, state, dateStr } = this.props;

    var enLocale = moment();
    enLocale.locale("en");

    let plans = [];
    if (state.hourlist) {
      if (Object.prototype.hasOwnProperty.call(state.hourlist, "")) {
        plans = state.hourlist[""];
      }
    }

    return (
      <Fragment>
        <div className={classes.planContainer}>
          {plans &&
            plans.length > 0 &&
            plans.map((plan) => {
              return (
                dateStr === plan.start_date_str && (
                  <Paper
                    elevation={1}
                    className={classes.planCard}
                    style={{
                      cursor: state.isAdminUser ? "pointer" : "unset",
                    }}
                    key={`p${plan.id}`}
                  >
                    <Typography component="div" style={{ padding: "5px" }}>
                      <Typography component="div" className={classes.flexLine}>
                        <Typography
                          component="div"
                          className={classes.cardIconContainer}
                        >
                          <SvgIcon style={{ fontSize: 22 }}>
                            <path
                              fill={`${
                                plan.status === "inactive"
                                  ? "#d20404"
                                  : plan.project_color
                                    ? plan.project_color
                                    : "#18B24B"
                              }`}
                              d={
                                "M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z"
                              }
                            />
                          </SvgIcon>
                        </Typography>
                        <Typography
                          component="p"
                          noWrap
                          className={classes.cardText}
                        >
                          {plan.project_name}
                        </Typography>
                      </Typography>
                      <Typography
                        component="div"
                        className={classes.separatorV}
                      />
                      <Typography component="div" className={classes.flexLine}>
                        <Typography
                          component="div"
                          className={classes.cardIconContainer1}
                        >
                          <SvgIcon className={classes.icon}>
                            <path
                              fill="#637381"
                              d={
                                "M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"
                              }
                            />
                          </SvgIcon>
                        </Typography>
                        <Typography
                          component="p"
                          noWrap
                          className={classes.cardText}
                        >
                          {plan.task_name}
                        </Typography>
                      </Typography>
                      <Typography
                        component="div"
                        className={classes.separatorV}
                      />
                      <Typography
                        component="div"
                        className={classes.cardBottomLine}
                      >
                        <Typography
                          component="div"
                          noWrap
                          className={classes.flexLine}
                        >
                          <Typography
                            component="div"
                            className={classes.cardIconContainer1}
                          >
                            <SvgIcon className={classes.icon}>
                              <path
                                fill="#637381"
                                d={
                                  "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M16.2,16.2L11,13V7H12.5V12.2L17,14.9L16.2,16.2Z"
                                }
                              />
                            </SvgIcon>
                          </Typography>
                          <Typography
                            component="p"
                            className={classes.cardText}
                          >
                            {moment(plan.start_time).utc().format(`HH:mm`)} -{" "}
                            {moment(plan.end_time).utc().format(`HH:mm`)}
                          </Typography>
                        </Typography>

                        <div style={{ flexGrow: 1 }} />

                        {plan.comment && (
                          <Tooltip
                            title={plan.comment}
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                            onOpen={() => this.handleTooltipOpen(plan.id)}
                            onClose={() => this.handleTooltipClose(plan.id)}
                            open={
                              this.state.openTooltip[plan.id]
                                ? this.state.openTooltip[plan.id]
                                : false
                            }
                            enterTouchDelay={200}
                          >
                            <SvgIcon className={classes.tooltipIcon}>
                              <path
                                d={
                                  "M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M5,5V7H19V5H5M5,9V11H13V9H5M5,13V15H15V13H5Z"
                                }
                              />
                            </SvgIcon>
                          </Tooltip>
                        )}

                        {plan.document_id && (
                          <Tooltip title={plan?.document_name} placement="top">
                            <FilePresentRoundedIcon
                              className={classes.doneIcon}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFileDownload(
                                  plan?.document_url,
                                  plan?.document_name
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                      </Typography>
                    </Typography>

                    <Typography
                      component="div"
                      className={classes.requestContainer}
                      onClick={() => {
                        this.props.requestAssignPlan(plan.id);
                      }}
                    >
                      <Typography
                        component="div"
                        className={classes.requestBlock}
                      >
                        <Button variant="outlined" fullWidth>
                          {i18next.t("Request")}
                        </Button>
                      </Typography>
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.bottomBorderDiv}
                      style={{
                        background:
                          plan.status === "inactive"
                            ? "#d20404"
                            : plan.project_color
                              ? plan.project_color
                              : "#18B24B",
                      }}
                    />
                  </Paper>
                )
              );
            })}
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(PlanCard);
