import moment from "moment";

const initialState = {
  isLoading: true,
  startDate: moment().clone().startOf("week"),
  endDate: moment().clone().endOf("week"),
};

export const types = {
  CHANGE_FILTER: "CHANGE_FILTER",
};
export const filters = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_FILTER: {
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        isLoading: false,
        startDate,
        endDate,
      };
    }
    default:
      return state;
  }
};
export const changeFilter = (filters) => {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_FILTER,
      payload: {
        startDate: filters.startDate.clone(),
        endDate: filters.endDate.clone(),
      },
    });
  };
};
export const actions = {
  changeFilter,
};
