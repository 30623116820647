import PropTypes from "prop-types";
import { createContext, useState } from "react";

// ----------------------------------------------------------------------

const initialState = {
  isMenuOpen: false,
  onToggleMenubar: () => {},
};

const ToggleMenubarContext = createContext(initialState);

// ----------------------------------------------------------------------

ToggleMenubarProvider.propTypes = {
  children: PropTypes.node,
};

function ToggleMenubarProvider({ children }) {
  const [toggleMenubar, setToggleMenubar] = useState(false);

  const handleToggleMenubar = () => {
    setToggleMenubar(!toggleMenubar);
  };

  return (
    <ToggleMenubarContext.Provider
      value={{
        isMenuOpen: toggleMenubar,
        onToggleMenubar: handleToggleMenubar,
      }}
    >
      {children}
    </ToggleMenubarContext.Provider>
  );
}

export { ToggleMenubarProvider, ToggleMenubarContext };
