import React, { useState, useEffect, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MaterialDatePicker } from "@/common/Pickers";

import SearchInput from "@/common/SearchInput";
import UsersTable from "@/common/GuestTable";
import OverlayIndicator from "@/common/OverlayIndicator";

import { getGuestAttendance } from "@/services/guest";
import { getErrorObject } from "@/utils/api.config";
import {
  getDateFormat,
  getDateWithFixedTimeFormat,
} from "@/utils/dateTimeFunctions";

import moment from "moment";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const GuestTab = () => {
  const classes = useStyles();

  const [guestList, setGuestList] = useState([]);
  const [limit, setLimit] = useState(10);
  //const [offset, setOffset] = useState(0);
  //const [hasMore, setHasMore] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [apiQuery, setApiQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getGuestAttendanceList = async (queryString) => {
      try {
        const response = await getGuestAttendance(queryString);
        if (response && response.data && response.data.entries) {
          let users = [];
          let element = null;
          const dateTimeFormat = getDateWithFixedTimeFormat();

          for (let i = 0; i < response.data.entries.length; i++) {
            element = response.data.entries[i];

            // group multiple check-in of same user in single record
            let check_in = "";
            let check_out = "";
            let check_in_time = "";
            let check_out_time = "";
            let visiting = "";
            let isOut = false;
            let id = null;
            if (
              element.guest_attendances &&
              element.guest_attendances.length > 0
            ) {
              for (let j = 0; j < element.guest_attendances.length; j++) {
                const attendanceElement = element.guest_attendances[j];

                if (attendanceElement.is_utc) {
                  check_in_time = attendanceElement.start_time
                    ? moment(attendanceElement.start_time).format(
                        dateTimeFormat
                      )
                    : "-";

                  check_out_time = attendanceElement.end_time
                    ? moment(attendanceElement.end_time).format(dateTimeFormat)
                    : "-";
                } else {
                  check_in_time = attendanceElement.start_time
                    ? moment(attendanceElement.start_time)
                        .utc()
                        .format(dateTimeFormat)
                    : "-";

                  check_out_time = attendanceElement.end_time
                    ? moment(attendanceElement.end_time)
                        .utc()
                        .format(dateTimeFormat)
                    : "-";
                }

                check_in =
                  check_in.length > 0
                    ? `${check_in}|${check_in_time}`
                    : check_in_time;
                check_out =
                  check_out.length > 0
                    ? `${check_out}|${check_out_time}`
                    : check_out_time;
                visiting =
                  visiting.length > 0
                    ? `${visiting}|${attendanceElement.visiting}`
                    : attendanceElement.visiting;
                isOut = attendanceElement.end_time ? true : false;
                id = attendanceElement.id;
              }
            }

            users.push({
              id: id.toString(),
              user_id: element.guest_id.toString(),
              name: element.guest_name,
              email: element.guest_email,
              visiting: visiting ? visiting : "-",
              company: element.guest_company_name,
              check_in: check_in,
              check_out: check_out,
              isOut: isOut,
            });
          }

          if (response && response.data && response.data.pagination) {
            //setOffset(response.data.pagination.offset);
            setTotalRecords(response.data.pagination.total_items);
            //setHasMore(response.data.pagination.has_more_items);
          }

          setGuestList(users);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        let { message } = getErrorObject(error);
        console.log(message);
      }
    };

    setIsLoading(true);
    getGuestAttendanceList(apiQuery);
  }, [apiQuery]);

  const loadGuests = (page) => {
    //if (hasMore) {
    const currentDate = moment(selectedDate).format("YYYY-MM-DD");
    const startDate =
      moment(`${currentDate}T00:00:00.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const endTime =
      moment(`${currentDate}T23:59:59.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const queryString = `offset=${
      page * limit
    }&limit=${limit}&start_time=${startDate}&end_time=${endTime}&attendanceable_type=Guest`;

    setApiQuery(queryString);
    //}
  };

  const handleSearch = (event) => {
    setSearchVal(event.target.value);
  };

  const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      setSelectedDate(date);
    }
  };

  // Call once, onComponentDidMound
  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    const startDate =
      moment(`${currentDate}T00:00:00.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const endTime =
      moment(`${currentDate}T23:59:59.000`)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    const queryString = `limit=10&start_time=${startDate}&end_time=${endTime}&attendanceable_type=Guest`;

    setApiQuery(queryString);
  }, []);

  // Calls everytime, the searchVal changes
  useEffect(() => {
    const onGuestSearch = (searchTerm) => {
      const currentDate = moment(selectedDate).format("YYYY-MM-DD");
      const startDate =
        moment(`${currentDate}T00:00:00.000`)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      const endTime =
        moment(`${currentDate}T23:59:59.000`)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      let queryString = `limit=${limit}&start_time=${startDate}&end_time=${endTime}&attendanceable_type=Guest`;

      if (searchTerm && searchTerm.length > 0) {
        queryString += `&name=${searchTerm}`;
      }

      setApiQuery(queryString);
    };

    // search delay
    const handler = setTimeout(() => {
      onGuestSearch(searchVal);
    }, 1000);

    // A cleanup function that will be called every time useEffect is re-called. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [searchVal, limit, selectedDate]);

  const onLimitChange = (limit) => {
    setLimit(limit);
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <SearchInput
            placeholder={i18next.t("Search guest")}
            handleSearch={handleSearch}
          />
          <div style={{ maxWidth: 190 }}>
            <MaterialDatePicker
              format={getDateFormat()}
              disableFuture
              value={selectedDate}
              onChangeDate={(date) => handleDateChange(date)}
            />
          </div>
        </div>
        <div className={classes.content}>
          <UsersTable
            users={guestList}
            loadUsers={loadGuests}
            totalRecords={totalRecords}
            onLimitChange={onLimitChange}
          />
        </div>
      </div>
      <OverlayIndicator isLoading={isLoading} />
    </Fragment>
  );
};

export default GuestTab;
