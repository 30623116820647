import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Drawer, Box } from "@mui/material";
import SidebarNav from "./components/SidebarNav";
import UserProfile from "./components/UserProfile";
import VrsLogo from "@/assets/images/vrsLogo.svg?react";
import { getCompanySettings, getCompanyModules } from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    //padding: theme.spacing(2)
  },
  drawer: {
    width: 280,
  },
  flexGrow: {
    flexGrow: 1,
  },
  footerLogo: {
    padding: "18px 18px 18px 22px",
  },
}));

const allowedPages = [
  "/dashboard",
  "/logout",
  "/hours",
  "/employee/create-pin",
];

const Sidebar = (props) => {
  const {
    open,
    onClose,
    className,
    path,
    history,
    showOnlyDashboard,
    setLimitedAccess,
    ...rest
  } = props;

  const classes = useStyles();

  const [isAttendanceModule, setIsAttendanceModule] = useState(false);
  const [allowAttendanceChange, setAllowAttendanceChange] = useState(false);
  const [isGeoFenceForAttendace, setIsGeoFenceForAttendace] = useState(false);
  const [isGeoFenceDisabled, setIsGeoFenceDisabled] = useState(false);
  const [checkInForAttendanceOnly, setCheckInForAttendanceOnly] =
    useState(false);
  const [isPlanningModule, setIsPlanningModule] = useState(false);
  const [showAdminPlanningView, setShowAdminPlanningView] = useState(false);
  const [isLoading, setIsLoading] = useState({
    companySetting: true,
    companyModule: true,
  });

  const checkAssignedModules = async () => {
    try {
      const response = await getCompanyModules();
      if (response.data && response.data.entries) {
        if (response.data.length > 0) {
          if (
            response.data.find((data) => {
              return data.name === "Attendance";
            })
          ) {
            setIsAttendanceModule(true);
          }
          if (
            response.data.find((data) => {
              return data.name === "Planning";
            })
          ) {
            setIsPlanningModule(true);
          }
        }
      }
      setIsLoading((isLoading) => ({
        ...isLoading,
        companyModule: false,
      }));
    } catch (error) {
      let { message } = getErrorObject(error);
      setIsLoading((isLoading) => ({
        ...isLoading,
        companyModule: false,
      }));
      console.log(message);
    }
  };

  useEffect(() => {
    const userId = getItem("userId") ? parseInt(getItem("userId"), 10) : null;

    async function checkCompanySetting() {
      try {
        const settings = await getCompanySettings();
        if (settings.data && settings.data.entries) {
          if (settings.data.entries.length > 0) {
            let entries = settings.data.entries;
            if (entries && entries.length > 0) {
              for (let i = 0; i < entries.length; i++) {
                if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Use Ipad only for attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setCheckInForAttendanceOnly(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Geofence restriction for attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceForAttendace(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Disable geofence on Tablet app"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceDisabled(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Separate hours from attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setAllowAttendanceChange(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "On Tablet, show only Dashboard"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setLimitedAccess();
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Allow users to see other users planned hours"
                ) {
                  if (entries[i].default_user_value === "TRUE") {
                    setShowAdminPlanningView(true);
                  } else if (
                    entries[i].company_settings_value &&
                    entries[i].company_settings_value.values &&
                    entries[i].company_settings_value.values.user_ids
                  ) {
                    let user_ids =
                      entries[i].company_settings_value.values.user_ids;
                    if (Array.isArray(user_ids) && user_ids.length > 0) {
                      if (user_ids.indexOf(userId) !== -1) {
                        // if current user in the selected list and it is normal user then, show the admin view
                        if (getItem("userRole") === "user") {
                          setShowAdminPlanningView(true);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        setIsLoading((isLoading) => ({
          ...isLoading,
          companySetting: false,
        }));
      } catch (error) {
        let { message } = getErrorObject(error);
        setIsLoading((isLoading) => ({
          ...isLoading,
          companySetting: false,
        }));
        console.log(message);
      }
    }

    checkAssignedModules();
    checkCompanySetting();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showOnlyDashboard) {
      if (allowedPages.indexOf(path) === -1) {
        history.push("/not-found");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, showOnlyDashboard]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Box
          spacing={3}
          sx={{
            py: 2,
            px: 2,
            flexShrink: 0,
          }}
        >
          <UserProfile
            isAttendanceModule={isAttendanceModule}
            allowAttendanceChange={allowAttendanceChange}
            isGeoFenceForAttendace={isGeoFenceForAttendace}
            isGeoFenceDisabled={isGeoFenceDisabled}
            checkInForAttendanceOnly={checkInForAttendanceOnly}
            isLoading={isLoading}
          />
        </Box>
        <SidebarNav
          isPlanningModule={isPlanningModule}
          showAdminPlanningView={showAdminPlanningView}
          showOnlyDashboard={showOnlyDashboard}
        />
        <div className={classes.flexGrow} />
        <div className={classes.footerLogo}>
          <VrsLogo fill={"black"} height={38} />
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default Sidebar;
