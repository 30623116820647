import * as types from "./types";
import { core } from "./core";
import moment from "moment";

export const getProjects = (queryString) => {
  if (!queryString) {
    queryString = "more_details=false";
  }

  return core.API(
    "GET",
    `/projects${queryString ? `?${queryString}` : ""}`,
    types.GET_PROJECTS,
    types.GET_PROJECTS_SUCCESS,
    types.GET_PROJECTS_FAILURE
  );
};

export const getProject = (data) => {
  return core.API(
    "GET",
    `/project/${data.id}?more_details=true${
      data.fromDate
        ? `&start_time=${moment(data.fromDate).format("YYYY-MM-DD")}`
        : ""
    }${
      data.toDate ? `&end_time=${moment(data.toDate).format("YYYY-MM-DD")}` : ""
    }`,
    types.GET_PROJECT,
    types.GET_PROJECT_SUCCESS,
    types.GET_PROJECT_FAILURE
  );
};

export const deleteProject = (id) => {
  return core.API(
    "DELETE",
    `/project/${id}`,
    types.DELETE_PROJECTS,
    types.DELETE_PROJECTS_SUCCESS,
    types.DELETE_PROJECTS_FAILURE
  );
};

export const updateProject = (id, data) => {
  return core.API(
    "PUT",
    `/project/${id}/`,
    types.PUT_PROJECTS_DATA,
    types.PUT_PROJECTS_DATA_SUCCESS,
    types.PUT_PROJECTS_DATA_FAILURE,
    data
  );
};

export const addProject = (data) => {
  return core.API(
    "POST",
    `/project/`,
    types.ADD_PROJECT,
    types.ADD_PROJECT_SUCCESS,
    types.ADD_PROJECT_FAILURE,
    data
  );
};

export const userDetialsByProject = (data) => {
  return core.API(
    "GET",
    `/project/${data.id}/users${
      data.fromDate
        ? `?start_time=${moment(data.fromDate).format("YYYY-MM-DD")}`
        : ""
    }${
      data.toDate ? `&end_time=${moment(data.toDate).format("YYYY-MM-DD")}` : ""
    }`,
    types.GET_USES_BY_PROJECT,
    types.GET_USES_BY_PROJECT_SUCCESS,
    types.GET_USES_BY_PROJECT_FAILURE
  );
};
