import createReducer from "./createReducer";
import * as types from "@/redux/actions/types";
import { logout } from "@/utils/api.config";

const initialState = {
  isLoading: false,
  data: {},
  hourTypesList: {},
  departmentList: {},
  error: null,
  succMsg: null,
  errMsg: null,
  projectHourTypeLoading: false,
  projectHourTypeList: {},
  projectAddonTypeLoading: false,
  projectAddonTypeList: {},
  optionalHourTypes: {},
};

export const hourTypes = createReducer(initialState, {
  [types.GET_PROJECT_HOUR_TYPE](state) {
    return Object.assign({}, state, {
      ...state,
      projectHourTypeLoading: true,
      error: null,
    });
  },
  [types.GET_PROJECT_HOUR_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      projectHourTypeList: {
        ...state.projectHourTypeList,
        [action.meta.id]: action.payload,
      },
      projectHourTypeLoading: false,
    });
  },
  [types.GET_PROJECT_HOUR_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      projectHourTypeLoading: false,
    });
  },

  [types.GET_PROJECT_ADDON_TYPE](state) {
    return Object.assign({}, state, {
      ...state,
      projectAddonTypeLoading: true,
      error: null,
    });
  },
  [types.GET_PROJECT_ADDON_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      projectAddonTypeList: {
        ...state.projectAddonTypeList,
        [action.meta.id]: action.payload,
      },
      projectAddonTypeLoading: false,
    });
  },
  [types.GET_PROJECT_ADDON_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      projectAddonTypeLoading: false,
    });
  },
  [types.GET_HOUR_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_HOUR_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hourTypesList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HOUR_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_HOUR_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_HOUR_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_HOUR_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_HOUR_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_HOUR_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_HOUR_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_HOUR_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_HOUR_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_HOUR_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DEPARTMENTS_HOUR_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_DEPARTMENTS_HOUR_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      departmentList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DEPARTMENTS_HOUR_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_OPTIONAL_HOUR_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      optionalHourTypes: {},
      error: null,
    });
  },
  [types.GET_OPTIONAL_HOUR_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      optionalHourTypes: action.payload,
      isLoading: false,
    });
  },
  [types.GET_OPTIONAL_HOUR_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.SET_OPTIONAL_HOUR_TYPE](state) {
    return Object.assign({}, state, {
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.SET_OPTIONAL_HOUR_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.SET_OPTIONAL_HOUR_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
});
