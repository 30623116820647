import React from "react";
import { Grid, Typography } from "@mui/material";
import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import { makeStyles } from "@mui/styles";
import i18next from "i18next";
import Label from "@/common/Label";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  hourTypeItemTitle: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  addonTypeItemTitle: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
  projectItemDescription: {
    fontWeight: 500,
    fontSize: 13,
    color: theme.palette.text.secondary,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "14px",
  },
  node: {
    padding: 10,
  },
}));

const summarizeHoursByProject = (items) => {
  return Object.values(
    items
      ?.filter((item) => item.project_id)
      ?.reduce((accumulator, currentValue) => {
        let lunchTime = 0;
        if (currentValue?.lunch_start_time !== "") {
          lunchTime =
            (currentValue.lunch_end_time - currentValue.lunch_start_time) / 60;
        }

        if (accumulator[currentValue.project_id]) {
          accumulator[currentValue.project_id].amount +=
            (currentValue.end_time - currentValue.start_time) / 60;

          if (lunchTime) {
            accumulator[currentValue.project_id].amount -= lunchTime;
          }

          return accumulator;
        } else {
          let amount = (currentValue.end_time - currentValue.start_time) / 60;

          if (lunchTime) {
            amount -= lunchTime;
          }

          return {
            ...accumulator,
            [currentValue.project_id]: {
              id: currentValue.project_id,
              name: currentValue.project_name,
              amount: amount,
            },
          };
        }
      }, {})
  );
};

const summarizeFixedAddonsByProject = (items) => {
  return Object.values(
    items
      ?.filter((item) => item.project_id)
      ?.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue.project_id]) {
          accumulator[currentValue.project_id].count++;

          return accumulator;
        } else {
          return {
            ...accumulator,
            [currentValue.project_id]: {
              id: currentValue.project_id,
              name: currentValue.project_name,
              count: 1,
            },
          };
        }
      }, {})
  );
};

function HoursPerProject({
  multiProjectTasks,
  multiProjectAddonTasks,
  fixedAddonTasks,
  previewError,
}) {
  const classes = useStyles();

  const hoursPerProjectSummary = summarizeHoursByProject(multiProjectTasks);
  const addonHoursPerProjectSummary = summarizeHoursByProject(
    multiProjectAddonTasks
  );
  const fixedAddonPerProjectSummary =
    summarizeFixedAddonsByProject(fixedAddonTasks);

  return (
    <Grid container className={classes.container}>
      {previewError && (
        <Grid item md={12} className={classes.node}>
          <Typography component="div" className={classes.errorText}>
            {previewError}
          </Typography>
        </Grid>
      )}
      {hoursPerProjectSummary?.map((item) => (
        <Grid item md={6} className={classes.node} key={item.id}>
          <Typography className={classes.hourTypeItemTitle}>
            <DecimalToHoursMin>{item.amount}</DecimalToHoursMin>
          </Typography>
          <Typography className={classes.projectItemDescription}>
            {item.name}
          </Typography>
        </Grid>
      ))}
      {addonHoursPerProjectSummary?.map((item) => (
        <Grid item md={6} className={classes.node} key={item.id}>
          <Typography className={classes.addonTypeItemTitle}>
            <DecimalToHoursMin>{item.amount}</DecimalToHoursMin>
          </Typography>
          <Typography className={classes.projectItemDescription}>
            {item.name}
          </Typography>
        </Grid>
      ))}
      {fixedAddonPerProjectSummary?.map((item) => (
        <Grid item md={6} className={classes.node} key={item.id}>
          <Label variant="outlined" color="secondary" height="small">
            {item.count} {i18next.t("Fixed addons")}
          </Label>
          <Typography
            className={classes.projectItemDescription}
            sx={{ pt: 0.5 }}
          >
            {item.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default HoursPerProject;
