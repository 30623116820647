import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, IconButton } from "@mui/material";

import { SignIn, SignOut } from "@/assets/icons/SvgIcon";
import PageButton from "@/common/PageButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    marginTop: "-90px",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  pageContainer: {
    //paddingTop: 100,
    display: "flex",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

const GuestDashboard = (props) => {
  const { history } = props;

  const classes = useStyles();

  const handleSignIn = (event) => {
    event.preventDefault();
    history.push("/guest/check-in");
  };

  const handleSignOut = (event) => {
    event.preventDefault();
    history.push("/guest/check-out");
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentHeader}>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className={classes.contentBody}>
        <Grid container className={classes.pageContainer}>
          <Grid
            item
            xl={6}
            lg={8}
            sm={12}
            xs={10}
            className={classes.buttonContainer}
          >
            <PageButton
              title={i18next.t("Check-in")}
              icon={<SignIn />}
              onClick={handleSignIn}
            />
            <PageButton
              title={i18next.t("Check-out")}
              icon={<SignOut />}
              onClick={handleSignOut}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GuestDashboard;
