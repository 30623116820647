import * as React from "react";
import { InputAdornment } from "@mui/material";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import EventNoteIcon from "@mui/icons-material/EventNote";
import i18next from "i18next";

export default function CalendarDateRangePicker({
  label,
  dates,
  format,
  onChangeDate,
  shouldDisableDate = null,
}) {
  const [value, setValue] = React.useState([null, null]);

  React.useEffect(() => {
    if (dates?.length) {
      setValue([dates[0], dates[1]]);
    }
  }, [dates]);

  const handleClose = () => {
    if (value[0] && value[1]) {
      onChangeDate([value[0], value[1]]);
      setValue([value[0], value[1]]);
    }
  };

  return (
    <div>
      <MobileDateRangePicker
        calendars={1}
        value={value}
        onChange={(newValue) => {
          setValue([newValue[0], newValue[1] ? newValue[1] : newValue[0]]);
        }}
        onClose={handleClose}
        format={format}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          toolbar: {
            hidden: true,
          },
          textField: {
            label: label,
            InputProps: {
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    marginRight: "-5px",
                    marginTop: "2px",
                    cursor: "pointer",
                  }}
                >
                  <EventNoteIcon />
                </InputAdornment>
              ),
            },
            fullWidth: true,
            variant: "outlined",
          },
        }}
        shouldDisableDate={shouldDisableDate}
        localeText={{
          okButtonLabel: i18next.t("Ok"),
          cancelButtonLabel: i18next.t("Cancel"),
        }}
      />
    </div>
  );
}
