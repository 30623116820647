import React from "react";
import SubmitSpinner from "./Spinners";
import i18next from "i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { MaterialTimePicker } from "./Pickers";
import ConfirmModal from "./ConfirmModal";
import moment from "moment";

const styles = (theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnDanger: {
    textTransform: "uppercase",
    color: theme.palette.error.main,
  },
  header: {
    marginBottom: 4,
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  checkBox: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
});

class CheckoutModal extends React.Component {
  constructor(props) {
    super(props);

    let tempStartTime = new Date();
    tempStartTime = new Date(tempStartTime.setHours(0));
    tempStartTime = new Date(tempStartTime.setMinutes(0));

    let tempEndTime = tempStartTime;

    this.state = {
      startTime: moment(tempStartTime),
      endTime: moment(tempEndTime),
      startTimeError: false,
      endTimeError: false,
      disableLunch: false,
      openConfirmModal: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    // Check if Lunch Time need to fill as per the settings
    // other if condition is required when Checkout modal opens when it redirects from Planning page, the settings are not loaded by that time so, process the lunch logic again when company settings are loaded
    if (
      (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) ||
      (prevProps.isLunchTime !== this.props.isLunchTime &&
        this.props.isLunchTime &&
        this.props.isOpen) ||
      (prevProps.isAutoFillLunch !== this.props.isAutoFillLunch &&
        this.props.isAutoFillLunch &&
        this.props.isOpen)
    ) {
      const {
        isAutoFillLunch,
        autoFillLunchAmount,
        standardLunchTime,
        checkInStartTime,
        isLunchTime,
        loginUserGroupLunchByDay,
        changeState,
      } = this.props;

      if (isLunchTime && checkInStartTime) {
        if (isAutoFillLunch) {
          // auto fill logic
          if (autoFillLunchAmount > 0 && standardLunchTime > 0) {
            const checkInStart = moment(checkInStartTime)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS");
            const timeDeference = moment().diff(checkInStart);
            const amount = moment.duration(timeDeference).asHours();
            const totalLunchTimeAmount =
              parseFloat(autoFillLunchAmount) + parseFloat(standardLunchTime);

            if (Math.abs(amount) > totalLunchTimeAmount) {
              const tempStartTime = moment(checkInStart).add(
                autoFillLunchAmount,
                "hours"
              );
              const tempEndTime = moment(checkInStart).add(
                totalLunchTimeAmount,
                "hours"
              );
              this.setState({
                startTime: tempStartTime,
                endTime: tempEndTime,
              });
              changeState({
                lunchStartTime: moment(tempStartTime).format("HH:mm"),
                lunchEndTime: moment(tempEndTime).format("HH:mm"),
              });
              this.isRestrictEditLunch();
            }
          }
        } else {
          // group lunch time logic
          let lunchStartTime = null;
          let lunchEndTime = null;

          if (loginUserGroupLunchByDay) {
            var enLocale = moment(checkInStartTime);
            enLocale.locale("en");
            const weekDay = enLocale.format("dddd").toLowerCase();
            if (loginUserGroupLunchByDay[weekDay]) {
              const dayObj = loginUserGroupLunchByDay[weekDay];
              lunchStartTime = dayObj.lunch_start_time;
              lunchEndTime = dayObj.lunch_end_time;
            }
          }

          if (lunchStartTime && lunchEndTime) {
            const tempStartTime = moment(lunchStartTime, "HH:mm");
            const tempEndTime = moment(lunchEndTime, "HH:mm");

            const checkInStart = moment(checkInStartTime)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS");
            const checkInEnd = moment();

            if (
              moment(tempStartTime).isAfter(checkInStart) &&
              moment(tempEndTime).isBefore(checkInEnd)
            ) {
              this.setState({
                startTime: tempStartTime,
                endTime: tempEndTime,
              });
              changeState({
                lunchStartTime: lunchStartTime,
                lunchEndTime: lunchEndTime,
              });
              this.isRestrictEditLunch();
            }
          }
        }
      }
    }
  };

  isRestrictEditLunch = () => {
    // In case auto fill lunch value present and following setting are ON, disable lunch section
    if (this.props.restrictLunchEditIfAutofill) {
      this.setState({ disableLunch: true });
    }
  };

  resetLocalLunchTime = () => {
    let tempStartTime = new Date();
    tempStartTime = new Date(tempStartTime.setHours(0));
    tempStartTime = new Date(tempStartTime.setMinutes(0));

    let tempEndTime = tempStartTime;
    this.setState({
      startTime: moment(tempStartTime),
      endTime: moment(tempEndTime),
      startTimeError: false,
      endTimeError: false,
      disableLunch: false,
    });
  };

  handleCancel = () => {
    this.resetLocalLunchTime();
    this.props.handleCancel();
  };

  handleSubmit = () => {
    this.resetLocalLunchTime();
    this.props.handleSubmit();
  };

  handleDiscardClick = () => {
    this.setState({
      openConfirmModal: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      openConfirmModal: false,
    });
  };

  submitDiscardClick = () => {
    this.closeConfirmModal();
    this.props.handleCheckInDiscard();
  };

  render() {
    const {
      reducer,
      isOpen,
      classes,
      isLunchTime,
      lateComment,
      isLateComment,
      changeState,
      isContinuesCheckIn,
      allowFinishTask,
      isTaskFinished,
    } = this.props;

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={this.handleCancel}
          fullWidth={true}
          maxWidth={"sm"}
          id="check-out-hour-dialog"
        >
          <DialogTitle
            className={classes.header}
            id="check-out-hour-dialog-title"
          >
            {i18next.t("Check out")}
          </DialogTitle>
          <DialogContent>
            {isLunchTime && (
              <Grid container spacing={2} style={{ marginBottom: 15 }}>
                <Grid item xs={12} id="check-out-hour-dialog-lunch-time-input">
                  <DialogContentText>
                    {i18next.t("Lunch Time")}
                  </DialogContentText>
                  <Typography component="div" style={{ marginTop: 10 }}>
                    <Grid item container xs={12}>
                      <Grid
                        item
                        xs={6}
                        style={{ paddingRight: 10 }}
                        id="check-out-hour-dialog-start-time-input"
                      >
                        <MaterialTimePicker
                          error={this.state.startTimeError}
                          label={i18next.t("Start time")}
                          value={this.state.startTime}
                          onChangeTime={async (date) => {
                            this.setState({
                              startTime: date,
                            });
                            this.props.changeState({
                              lunchStartTime: moment(date).format("HH:mm"),
                            });
                          }}
                          disabled={this.state.disableLunch ? true : false}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ paddingLeft: 10 }}
                        id="check-out-hour-dialog-end-time-input"
                      >
                        <MaterialTimePicker
                          error={this.state.endTimeError}
                          label={i18next.t("End time")}
                          value={this.state.endTime}
                          onChangeTime={async (date) => {
                            this.setState({
                              endTime: date,
                            });
                            this.props.changeState({
                              lunchEndTime: moment(date).format("HH:mm"),
                            });
                          }}
                          disabled={this.state.disableLunch ? true : false}
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {allowFinishTask && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    id="check-out-hour-dialog-task-finished-block"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isTaskFinished}
                          onChange={() => {
                            changeState({
                              isTaskFinished: !isTaskFinished,
                            });
                          }}
                          classes={{
                            root: classes.checkBox,
                            checked: classes.checked,
                          }}
                          id="check-out-hour-dialog-task-finished-tickbox"
                        />
                      }
                      label={i18next.t("Task finished")}
                      style={{ marginBottom: 0 }}
                      id="check-out-hour-dialog-task-finished-input"
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
            {isLateComment && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  id="check-out-hour-dialog-comment-input-container"
                >
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="standard"
                    label={i18next.t("Comment")}
                    type="text"
                    value={lateComment}
                    style={{ padding: 0 }}
                    onChange={(e) => {
                      changeState({
                        lateComment: e.target.value,
                      });
                    }}
                    id="check-out-hour-dialog-comment-input"
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <div>
              <Button
                onClick={this.handleDiscardClick}
                className={classes.btnDanger}
                id="check-out-hour-dialog-discard-button"
              >
                {i18next.t("Discard check-in")}
              </Button>
            </div>
            <div>
              <Button
                onClick={this.handleCancel}
                className={classes.btnCancel}
                id="check-out-hour-dialog-cancel-button"
              >
                {i18next.t("Cancel")}
              </Button>
              <Button
                disabled={reducer ? reducer.isLoading : false}
                onClick={this.handleSubmit}
                className={classes.button}
                id={`check-out-hour-dialog-${
                  isContinuesCheckIn ? "next" : "Ok"
                }-button`}
              >
                {isContinuesCheckIn ? i18next.t("Next") : i18next.t("Ok")}{" "}
                <SubmitSpinner
                  isLoading={reducer ? reducer.isLoading : false}
                />
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <ConfirmModal
          isOpen={this.state.openConfirmModal}
          handleCancel={this.closeConfirmModal}
          handleSubmit={this.submitDiscardClick}
          message={i18next.t(
            "Are you sure you want to discard the current check-in?"
          )}
        />
      </>
    );
  }
}
export default withStyles(styles)(CheckoutModal);
