import React, { useState } from "react";
import moment from "moment";
import i18next from "i18next";
import {
  TextField,
  FormControl,
  Chip,
  Dialog,
  DialogActions,
  Button,
  Autocomplete,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { PickersDay, DateCalendar } from "@mui/x-date-pickers";
import { getDateFormat } from "@/utils/dateTimeFunctions";

const MultiSelectDatePicker = ({
  selectedDate,
  onChange,
  isDefaultDateEditable,
  excludeDefaultDate = true,
  changeSelectedDate,
  isDateError,
  isDisabled,
}) => {
  const dateFormat = getDateFormat();

  const [selectedCalendarDates, setSelectedCalendarDates] = useState(
    selectedDate ? [moment(selectedDate)] : []
  );
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isError, setIsError] = useState(false);

  const RenderPickersDay = ({ day, ...others }) => {
    const selected = selectedCalendarDates?.find((d) => {
      return moment(d).isSame(day, "day");
    });

    return (
      <PickersDay
        disableMargin
        {...others}
        day={day}
        selected={selected ? true : false}
      />
    );
  };

  const findIndexDate = (dates, date) => {
    const dateTime = date.valueOf();
    return dates.findIndex((item) => item.valueOf() === dateTime);
  };

  const handleDateChange = (newValue) => {
    const calenderDates = [...selectedCalendarDates];

    if (!isDefaultDateEditable) {
      if (moment(newValue).isSame(moment(selectedDate), "day")) {
        return;
      }
    }

    const date = moment(newValue).startOf("day");

    const index = findIndexDate(calenderDates, date);

    if (index > -1) {
      calenderDates.splice(index, 1);
    } else {
      calenderDates.push(date);
    }

    const sortedDates = calenderDates.sort((a, b) => a.valueOf() - b.valueOf());

    setSelectedCalendarDates(sortedDates);
  };

  const returnFormattedDates = (dates) => {
    const formattedDates = dates.map((date) =>
      moment(date).format("YYYY-MM-DD")
    );

    onChange(formattedDates);
  };

  const handleSubmit = (dates) => {
    if (dates.length === 0) {
      setIsError(true);
      isDateError(true);

      onChange(dates);
    } else {
      setIsError(false);
      isDateError(false);

      const isSelectedDateExist = selectedDate
        ? dates.find((date) => {
            return moment(date).isSame(moment(selectedDate), "day");
          })
        : false;

      let calendarDates = [];
      let currentSelectedDate = null;
      if (isSelectedDateExist) {
        calendarDates = dates.filter((date) => {
          return !moment(date).isSame(moment(selectedDate), "day");
        });

        currentSelectedDate = selectedDate;
      } else {
        calendarDates = dates.filter((_, index) => {
          return index !== 0;
        });

        changeSelectedDate(dates[0]);

        currentSelectedDate = dates[0];
      }

      if (excludeDefaultDate) {
        // Hour section
        returnFormattedDates(calendarDates);
      } else {
        // Plan section
        returnFormattedDates([currentSelectedDate, ...calendarDates]);
      }
    }
  };

  const removeDate = (index) => {
    const newDates = selectedCalendarDates.filter(
      (value, position) => index !== position
    );

    setSelectedCalendarDates(newDates);

    handleSubmit(newDates);
  };

  const handleOnClose = () => {
    handleSubmit(selectedCalendarDates);

    setIsOpenCalendar(false);
  };

  return (
    <>
      <FormControl style={{ width: "100%" }}>
        <Autocomplete
          multiple
          options={[]}
          getOptionLabel={(option) => moment(option).format(dateFormat)}
          value={selectedCalendarDates}
          open={false}
          popupIcon={<DateRangeIcon />}
          disableClearable
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={isError}
                fullWidth
                variant="outlined"
                label={i18next.t("Dates")}
                onClick={isDisabled ? null : () => setIsOpenCalendar(true)}
              />
            );
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue?.map((option, index) => {
              const isSelectedDate = moment(option).isSame(
                moment(selectedDate),
                "day"
              );
              return (
                <Chip
                  key={index}
                  label={moment(option).format(dateFormat)}
                  color={isSelectedDate ? "primary" : "default"}
                  {...getTagProps({ index })}
                  onDelete={
                    isSelectedDate && !isDefaultDateEditable
                      ? null
                      : () => removeDate(index)
                  }
                />
              );
            })
          }
          defaultValue={[]}
          disabled={isDisabled}
        />
      </FormControl>

      <Dialog open={isOpenCalendar} onClose={handleOnClose}>
        <DateCalendar
          value={selectedCalendarDates[0]}
          onChange={handleDateChange}
          slots={{
            day: RenderPickersDay,
          }}
          disabled={isDisabled}
        />

        <DialogActions>
          <Button onClick={handleOnClose}>{i18next.t("Ok")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiSelectDatePicker;
