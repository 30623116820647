import React, { useEffect, useState } from "react";
import i18next from "i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { actions as authActions } from "@/utils/auth";
import { logout } from "@/utils/api.config.js";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
}));

const AutoLogoutModal = (props) => {
  const { isOpen, history, isCheckOut, onModalClose } = props;

  const classes = useStyles();
  const companyToken = authActions.getCompanyToken();
  const [timer, setTimer] = useState(null);

  const redirectToHome = async () => {
    const postObj = {
      client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
      client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
      token: authActions.getAccessToken(),
    };

    onModalClose();
    await logout(postObj);
    history.push("/token/" + companyToken);
  };

  const redirectToDashboard = () => {
    clearTimeout(timer);
    setTimer(null);
    onModalClose();
    history.push("/dashboard");
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      redirectToHome();
    }, 5000);
    setTimer(timerId);

    return () => {
      clearTimeout(timer);
      setTimer(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        {i18next.t(
          `You are successfully ${isCheckOut ? `Check-out` : `Check-in`}.`
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18next.t(
            "You will be signed out and redirected to the home page in 3 seconds"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.preventDefault();
            clearTimeout(timer);
            setTimer(null);
            redirectToHome();
          }}
          className={classes.btnCancel}
        >
          {i18next.t("Sign out")}
        </Button>
        <Button
          onClick={(event) => {
            event.preventDefault();
            redirectToDashboard();
          }}
          className={classes.button}
        >
          {i18next.t("Dashboard")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoLogoutModal;
