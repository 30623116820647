import React, { Fragment, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Divider,
  Typography,
  Menu,
} from "@mui/material";
import Person from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";

import i18next from "i18next";

import { actions as authActions } from "@/utils/auth";
import performLogout from "@/utils/performLogout";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: 900,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
    boxShadow: "none",
  },
  toolbar: {
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 3),
    height: 64,
  },
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 24,
  },
  dropdownItem: {
    color: theme.palette.text.primary,
  },
}));

const Topbar = (props) => {
  const { history, onToggleMenubar, title, showOnlyDashboard } = props;

  const classes = useStyles();

  const [openProfile, setOpenProfile] = useState(null);

  const isUserLogin =
    getItem("userId") && authActions.getAccessToken() ? true : false;

  const isAdmin =
    getItem("userRole") && getItem("userRole") === "admin" ? true : false;

  const companyToken = authActions.getCompanyToken();

  const subscriptionStatus = sessionStorage.getItem("status");
  if (subscriptionStatus && subscriptionStatus === "expired") {
    // If plan expired, and user tries to access any other page, redirect them back to index page
    const pageName = props.location.pathname;
    if (pageName && pageName !== "/") {
      history.push("/token/" + companyToken);
    }
  }

  const redirectToHome = async () => {
    performLogout(history);
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    setOpenProfile(null);
    redirectToHome();
  };

  const handleResetPIN = (event) => {
    event.preventDefault();
    setOpenProfile(null);
    history.push("/employee/create-pin");
  };

  const handleAddUser = (event) => {
    event.preventDefault();
    setOpenProfile(null);
    history.push("/employee/add-user");
  };

  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={onToggleMenubar}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          {title ? i18next.t(title) : ""}
        </Typography>
        <div className={classes.flexGrow} />
        {isUserLogin && (
          <Fragment>
            <IconButton
              className={classes.signOutButton}
              onClick={handleClickProfile}
              color="inherit"
              size="large"
            >
              <Person />
            </IconButton>
            <Menu
              id="profile-menu"
              open={Boolean(openProfile)}
              anchorEl={openProfile}
              onClose={handleCloseProfile}
            >
              {isAdmin && !showOnlyDashboard && (
                <MenuItem
                  onClick={handleAddUser}
                  className={classes.dropdownItem}
                >
                  {i18next.t("Add user")}
                </MenuItem>
              )}
              <MenuItem
                onClick={handleResetPIN}
                className={classes.dropdownItem}
              >
                {i18next.t("Reset PIN")}
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout} className={classes.dropdownItem}>
                {i18next.t("Sign out")}
              </MenuItem>
            </Menu>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
