import React from "react";
import i18next from "i18next";
import withStyles from "@mui/styles/withStyles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import { MaterialTimePicker } from "@/common/Pickers";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const styles = (theme) => ({
  button: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnCancel: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  header: {
    marginBottom: 7,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButtons: {
    padding: "0 10px 10px 10px",
  },
  timerDiv: {
    paddingTop: 24,
  },
});

class AttendanceEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attendanceInputTime: moment(),
      attendanceTimeError: false,
    };
  }

  handleDateTimeChange = (date) => {
    const { attendanceTimeToCompare } = this.props;

    if (moment(date).isBefore(attendanceTimeToCompare)) {
      this.setState({
        attendanceInputTime: date,
        attendanceTimeError: true,
      });
    } else {
      this.setState({
        attendanceInputTime: date,
        attendanceTimeError: false,
      });
    }
  };

  render() {
    const {
      isOpen,
      classes,
      onSubmit,
      onClose,
      isCheckOut,
      attendanceUserName,
    } = this.props;

    let title = isCheckOut
      ? i18next.t("check-out-attendance-table-message")
      : i18next.t("check-in-attendance-table-message");

    return (
      <Typography component="div">
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={isOpen}
          onClose={onClose}
        >
          <DialogTitle onClose={onClose} className={classes.header}>
            {`${i18next.t("Attendance status")} (${attendanceUserName})`}
            <IconButton
              aria-label={i18next.t("Close")}
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography component="div">{title}</Typography>
            <div className={classes.timerDiv}>
              <MaterialTimePicker
                error={this.state.attendanceTimeError}
                label={
                  isCheckOut
                    ? i18next.t("Check-in time")
                    : i18next.t("Check-out time")
                }
                value={this.state.attendanceInputTime}
                onChangeTime={this.handleDateTimeChange}
              />
            </div>
          </DialogContent>
          <DialogActions className={classes.actionButtons}>
            <Typography component="div">
              <Button className={classes.btnCancel} onClick={onClose}>
                {i18next.t("No")}
              </Button>{" "}
              <Button
                type="submit"
                className={classes.button}
                onClick={() => {
                  onSubmit(this.state.attendanceInputTime);
                }}
                disabled={this.state.attendanceTimeError}
              >
                {i18next.t("Yes")}
              </Button>
            </Typography>
          </DialogActions>
        </Dialog>
      </Typography>
    );
  }
}

export default withStyles(styles)(AttendanceEditModal);
