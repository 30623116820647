import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import CreatePinForm from "./components/CreatePinForm";
import i18next from "i18next";

import { generateEmployeePin } from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const CreatePin = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [pinError, setPinError] = useState("");

  const handlePinCreation = (params) => {
    if (params.pin !== params.confirm_pin) {
      setPinError(i18next.t("PIN and Confirm PIN doesn't match"));
    } else {
      postPIN(params);
    }
  };

  const postPIN = async (params) => {
    try {
      const userId = getItem("userId") ? getItem("userId") : null;

      if (userId) {
        const response = await generateEmployeePin(userId, params);
        if (response.data) {
          history.push("/dashboard");
        }
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      setPinError(message);
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.push("/dashboard");
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentBody}>
        <Grid container className={classes.formContainer}>
          <Grid item xl={4} lg={4} md={5} sm={10} xs={10}>
            <CreatePinForm
              handlePinCreation={handlePinCreation}
              cancel={handleCancel}
              pinError={pinError}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CreatePin;
