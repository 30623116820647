import * as types from "./types";
import { core } from "./core";

export const getUsersByDepartment = (id, queryString) => {
  return core.API(
    "GET",
    `/company/department/${id}/users${queryString ? `?${queryString}` : ""}`,
    types.GET_USERS_BY_DEPARTMENT,
    types.GET_USERS_BY_DEPARTMENT_SUCCESS,
    types.GET_USERS_BY_DEPARTMENT_FAILURE
  );
};

export const resetStore = () => {
  return async (dispatch) => {
    dispatch({
      type: types.RESET_STORE,
    });
  };
};

export const getProfileData = (id, queryString) => {
  return core.API(
    "GET",
    `${
      id
        ? `/user/${id}/${queryString ? `?${queryString}` : ""}`
        : `/user/current/${queryString ? `?${queryString}` : ""}`
    }`,
    types.GET_DATA,
    types.GET_DATA_SUCCESS,
    types.GET_DATA_FAILURE
  );
};

export const getUserSpecificDefaultData = (id) => {
  return core.API(
    "GET",
    `/user/${id}/preferences`,
    types.GET_USER_DEFAULT_DATA,
    types.GET_USER_DEFAULT_DATA_SUCCESS,
    types.GET_USER_DEFAULT_DATA_FAILURE
  );
};

export const resendConfirmation = (email) => {
  return core.API(
    "GET",
    `/users/resend-confirmation?email=${email}`,
    types.RESEND_CONFIRMATION,
    types.RESEND_CONFIRMATION_SUCCESS,
    types.RESEND_CONFIRMATION_FAILURE,
    null,
    null,
    true
  );
};

export const getUsers = (queryString) => {
  return core.API(
    "GET",
    `/users${queryString ? `?${queryString}` : ""}`,
    types.GET_USERS,
    types.GET_USERS_SUCCESS,
    types.GET_USERS_FAILURE
  );
};

export const deleteUser = (id) => {
  return core.API(
    "DELETE",
    `/user/${id}`,
    types.DELETE_USERS,
    types.DELETE_USERS_SUCCESS,
    types.DELETE_USERS_FAILURE
  );
};

export const putProfileData = (id, data) => {
  return core.API(
    "PUT",
    `/user/${id}/`,
    types.PUT_PROFILE_DATA,
    types.PUT_PROFILE_DATA_SUCCESS,
    types.PUT_PROFILE_DATA_FAILURE,
    data
  );
};

export const addUser = (data) => {
  return core.API(
    "POST",
    `/user/`,
    types.ADD_USER,
    types.ADD_USER_SUCCESS,
    types.ADD_USER_FAILURE,
    data
  );
};

export const forgotPassword = (data) => {
  return core.API(
    "GET",
    `/users/forget-password?email=${data.email}`,
    types.FORGOT_PASSWORD,
    types.FORGOT_PASSWORD_SUCCESS,
    types.FORGOT_PASSWORD_FAILURE,
    null,
    null,
    true
  );
};

export const resetPassword = (data) => {
  return core.API(
    "PUT",
    `/users/reset-password/`,
    types.RESET_PASSWORD,
    types.RESET_PASSWORD_SUCCESS,
    types.RESET_PASSWORD_FAILURE,
    data,
    null,
    true
  );
};

export const changePassword = (data) => {
  return core.API(
    "PUT",
    `/user/change-password`,
    types.CHANGE_PASSWORD,
    types.CHANGE_PASSWORD_SUCCESS,
    types.CHANGE_PASSWORD_FAILURE,
    data
  );
};

export const changePasswordByAdmin = (id, data) => {
  return core.API(
    "PUT",
    `/user/${id}/change-password`,
    types.CHANGE_PASSWORD_BY_ADMIN,
    types.CHANGE_PASSWORD_BY_ADMIN_SUCCESS,
    types.CHANGE_PASSWORD_BY_ADMIN_FAILURE,
    data
  );
};

export const getUserHours = (data, queryString) => {
  return core.API(
    "GET",
    `/user/${data.id}/hours?${
      queryString ? `${queryString}` : ""
    }&hour_type=true&task=true&project=true&customer=true${
      data && data.start_time ? `&start_time=${data.start_time}` : ""
    }${data && data.end_time ? `&end_time=${data.end_time}` : ""}`,
    types.GET_USERS_HOURS,
    types.GET_USERS_HOURS_SUCCESS,
    types.GET_USERS_HOURS_FAILURE,
    null,
    { id: data.id }
  );
};

export const getHourTypesSummary = (data) => {
  return core.API(
    "GET",
    `/user/${
      data.id
    }/hour-types-summary?hour_type=true&task=true&project=true&customer=true${
      data && data.start_time ? `&start_time=${data.start_time}` : ""
    }${data && data.end_time ? `&end_time=${data.end_time}` : ""}`,
    types.GET_HOUR_TYPE_SUMMARY,
    types.GET_HOUR_TYPE_SUMMARY_SUCCESS,
    types.GET_HOUR_TYPE_SUMMARY_FAILURE,
    null,
    { id: data.id }
  );
};

export const updateUsersGroup = (data) => {
  return core.API(
    "PUT",
    `/user/update-users-group`,
    types.UPDATE_USERS_GROUP,
    types.UPDATE_USERS_GROUP_SUCCESS,
    types.UPDATE_USERS_GROUP_FAILURE,
    data
  );
};

export const getUserAddons = (data, queryString) => {
  return core.API(
    "GET",
    `/user/${data.id}/addons?${queryString ? `${queryString}` : ""}${
      data && data.start_time ? `&start_time=${data.start_time}` : ""
    }${data && data.end_time ? `&end_time=${data.end_time}` : ""}`,
    types.GET_USERS_ADDONS,
    types.GET_USERS_ADDONS_SUCCESS,
    types.GET_USERS_ADDONS_FAILURE,
    null,
    { id: data.id }
  );
};

export const getAddonTypesSummary = (data) => {
  return core.API(
    "GET",
    `/user/${data.id}/addon-types-summary?${
      data && data.start_time ? `&start_time=${data.start_time}` : ""
    }${data && data.end_time ? `&end_time=${data.end_time}` : ""}`,
    types.GET_ADDON_TYPE_SUMMARY,
    types.GET_ADDON_TYPE_SUMMARY_SUCCESS,
    types.GET_ADDON_TYPE_SUMMARY_FAILURE,
    null,
    { id: data.id }
  );
};

export const getFixedAddonTypesSummary = (data) => {
  return core.API(
    "GET",
    `/user/${data.id}/fixed-addon-types-summary?${
      data && data.start_time ? `&start_time=${data.start_time}` : ""
    }${data && data.end_time ? `&end_time=${data.end_time}` : ""}`,
    types.GET_FIXED_ADDON_TYPE_SUMMARY,
    types.GET_FIXED_ADDON_TYPE_SUMMARY_SUCCESS,
    types.GET_FIXED_ADDON_TYPE_SUMMARY_FAILURE,
    null,
    { id: data.id }
  );
};

export const enableUser = (id) => {
  return core.API(
    "PUT",
    `/user/${id}/enable`,
    types.ENABLE_USER,
    types.ENABLE_USER_SUCCESS,
    types.ENABLE_USER_FAILURE
  );
};

export const getUsersNotCheckIn = (queryString) => {
  return core.API(
    "GET",
    `/attendance/not-check-in-users${queryString ? `?${queryString}` : ""}`,
    types.GET_NOT_CHECKIN_USERS,
    types.GET_NOT_CHECKIN_USERS_SUCCESS,
    types.GET_NOT_CHECKIN_USERS_FAILURE
  );
};

export const sendUserContract = (data) => {
  return core.API(
    "POST",
    `/user/${data.user_id}/contract`,
    types.SEND_USER_CONTRACT,
    types.SEND_USER_CONTRACT_SUCCESS,
    types.SEND_USER_CONTRACT_FAILURE,
    data
  );
};

export const getUserContractsData = (user_id) => {
  return core.API(
    "GET",
    `/user/${user_id}/contracts`,
    types.GET_USER_CONTRACTS_DATA,
    types.GET_USER_CONTRACTS_DATA_SUCCESS,
    types.GET_USER_CONTRACTS_DATA_FAILURE
  );
};

export const getUserContract = (user_id, contract_id) => {
  return core.API(
    "GET",
    `/user/${user_id}/contract/${contract_id}`,
    types.GET_USER_CONTRACT,
    types.GET_USER_CONTRACT_SUCCESS,
    types.GET_USER_CONTRACT_FAILURE
  );
};

export const updateUserContract = (data, contract_id) => {
  return core.API(
    "PUT",
    `/user/${data.user_id}/contract/${contract_id}`,
    types.UPDATE_USER_CONTRACT,
    types.UPDATE_USER_CONTRACT_SUCCESS,
    types.UPDATE_USER_CONTRACT_FAILURE,
    data
  );
};

export const resendUserContract = (user_id, contract_id) => {
  return core.API(
    "GET",
    `/user/${user_id}/contract/${contract_id}/resend`,
    types.RESEND_USER_CONTRACT,
    types.RESEND_CONTRACT_SUCCESS,
    types.RESEND_CONTRACT_FAILURE
  );
};

export const addUserTimebank = (data) => {
  return core.API(
    "POST",
    `/user/${data.user_id}/timebank-adjustment`,
    types.ADD_USER_TIMEBANK,
    types.ADD_USER_TIMEBANK_SUCCESS,
    types.ADD_USER_TIMEBANK_FAILURE,
    data
  );
};

export const getUserTimebankData = (user_id) => {
  return core.API(
    "GET",
    `/user/${user_id}/timebank-adjustments`,
    types.GET_USER_TIMEBANK_DATA,
    types.GET_USER_TIMEBANK_DATA_SUCCESS,
    types.GET_USER_TIMEBANK_DATA_FAILURE
  );
};

export const updateUserTimebank = (data, id) => {
  return core.API(
    "PUT",
    `/user/${data.user_id}/timebank-adjustment/${id}`,
    types.UPDATE_USER_TIMEBANK,
    types.UPDATE_USER_TIMEBANK_SUCCESS,
    types.UPDATE_USER_TIMEBANK_FAILURE,
    data
  );
};

export const deleteUserTimebank = (user_id, id) => {
  return core.API(
    "DELETE",
    `/user/${user_id}/timebank-adjustment/${id}`,
    types.DELETE_USER_TIMEBANK,
    types.DELETE_USER_TIMEBANK_SUCCESS,
    types.DELETE_USER_TIMEBANK_FAILURE
  );
};
