import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SignInForm from "./components/SignInForm";
import { withSnackbar } from "notistack";

import { postSignInAuthorizeApi, postSignInTokenApi } from "@/services/signin";
import { getErrorCode } from "@/utils/api.config";
import { actions as authActions } from "@/utils/auth";
import i18next from "i18next";
import * as msal from "@azure/msal-browser";

import { getItem, setItem, removeItem } from "@/utils/localStorage";
import { getLocale } from "@/utils/dateTimeFunctions";
import getLanguage from "@/utils/getLanguage";

import moment from "moment";

import crypto from "crypto-browserify";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  appbar: {
    zIndex: 900,
    position: "sticky",
    top: -5,
    background:
      theme.palette.mode === "light"
        ? `${theme.palette.background.default}cc`
        : `${theme.palette.background.default}cc`,
  },
  toolbar: {
    position: "sticky",
    top: -5,
    width: "100%",
    display: "flex",
    backdropFilter: "blur(6px)",
    padding: theme.spacing(0, 3),
    height: 64,
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  header: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: 28,
    color: theme.palette.text.primary,
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    paddingTop: theme.spacing(5),
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  formContainer: {
    //paddingTop: 100,
    display: "flex",
    justifyContent: "center",
  },
}));

const EmployeeSignIn = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [loginError, setLoginError] = useState("");
  const [userPin, setUserPin] = useState(false);

  useEffect(() => {
    const isUserPin = getItem("userPin")
      ? getItem("userPin") === "true"
        ? true
        : false
      : false;

    setUserPin(isUserPin);
  }, []);

  const onForgotPIN = () => {
    removeItem("userPin");
    setLoginError("");
    setUserPin(false);
  };

  const handleSignIn = (params) => {
    setLoginError("");
    postLogin(params);
  };

  const handleBack = () => {
    history.goBack();
  };

  function base64URLEncode(str) {
    return str
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  }

  function sha256(buffer) {
    return crypto.createHash("sha256").update(buffer).digest();
  }

  function setLanguagePreference() {
    let language = getItem("userLanguage")
      ? getItem("userLanguage")
      : "browser";
    if (language === "browser") {
      localStorage.removeItem("language");
    } else {
      localStorage.setItem("language", language);
    }

    // as per the user preference, set locale and i18n translation
    language = getLanguage();
    i18next.changeLanguage(language);
    moment.locale(getLocale(language));
  }

  const postLogin = async (loginData, isAzure) => {
    try {
      const verifier = base64URLEncode(crypto.randomBytes(32));
      const challenge = base64URLEncode(sha256(verifier));

      const postAuthorizeObj = {
        email: loginData.email,
        response_type: "code",
        client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
        redirect_uri: import.meta.env.VITE_OAUTH_REDIRECT_URI,
        scope: "read write",
        code_challenge: challenge,
        code_challenge_method: "S256",
      };

      if (isAzure) {
        postAuthorizeObj["microsoft_id"] = loginData.microsoft_id;
        postAuthorizeObj["microsoft_token"] = loginData.microsoft_token;
      } else {
        if (userPin) {
          postAuthorizeObj["pin"] = loginData.pin;
        } else {
          postAuthorizeObj["password"] = loginData.password;
        }
      }

      // call authorize api and get oAuth Authorization Code in response
      const authResponse = await postSignInAuthorizeApi(postAuthorizeObj);

      if (authResponse.data && authResponse.data.code) {
        const postTokenObj = {
          client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
          client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
          redirect_uri: import.meta.env.VITE_OAUTH_REDIRECT_URI,
          code_verifier: verifier,
          grant_type: "authorization_code",
          code: authResponse.data.code,
        };

        // call token api and get Access Token in response
        const tokenResponse = await postSignInTokenApi(postTokenObj);

        if (tokenResponse && tokenResponse.data) {
          authActions.saveSession(tokenResponse.data);

          setLanguagePreference();

          if (userPin) {
            history.push("/dashboard");
          } else {
            history.push("/employee/create-pin");
          }

          if (isAzure) {
            setItem("azureAccount", loginData.home_account_id);
          } else {
            removeItem("azureAccount");
          }
        }
      }
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Authentication failed");
      if (errorCode) {
        switch (errorCode) {
          case 500:
            message = i18next.t("Authentication failed");
            break;
          case 401:
            message =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : i18next.t(
                    "Incorrect email/password combination. Please try again"
                  );
            break;
          case 404:
            message = i18next.t(
              "Incorrect email/password combination. Please try again"
            );
            break;
          default:
            message = i18next.t("Authentication failed");
            break;
        }
      }

      setLoginError(message);
      props.enqueueSnackbar(message, { variant: "error" });
    }
  };

  const handleAzureClick = async () => {
    const msalConfig = {
      auth: {
        clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID,
      },
    };
    const request = {
      scopes: ["profile", "user.read"],
    };

    try {
      const msalInstance = new msal.PublicClientApplication(msalConfig);
      const tokenResponse = await msalInstance.acquireTokenPopup(request);
      if (tokenResponse && tokenResponse.accessToken) {
        // compair selected email with Azure login email
        const email = getItem("userEmail") ? getItem("userEmail") : "";
        if (email === tokenResponse.account.username) {
          const data = {
            email: tokenResponse.account.username,
            home_account_id: tokenResponse.account.homeAccountId,
            microsoft_id: tokenResponse.uniqueId,
            microsoft_token: tokenResponse.accessToken,
          };
          await postLogin(data, true);
        } else {
          props.enqueueSnackbar(
            i18next.t(
              "The selected user email doesn't match with the Azure account"
            ),
            { variant: "error" }
          );
          setLoginError(
            i18next.t(
              "The selected user email doesn't match with the Azure account"
            )
          );
        }
      } else {
        props.enqueueSnackbar(i18next.t("Azure AD authentication failed"), {
          variant: "error",
        });
        setLoginError(i18next.t("Azure AD authentication failed"));
      }
    } catch (err) {
      props.enqueueSnackbar(i18next.t("Azure AD authentication failed"), {
        variant: "error",
      });
      setLoginError(i18next.t("Azure AD authentication failed"));
    }
  };

  return (
    <div className={classes.content}>
      <AppBar elevation={0} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon className={classes.icon} />
          </IconButton>
          <div className={classes.headerContainer}>
            <Typography variant="h4" className={classes.header}>
              {i18next.t("Sign in")}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.contentBody}>
        <Grid container className={classes.formContainer}>
          <Grid item md={8} sm={10} xs={12}>
            <SignInForm
              handleSignIn={handleSignIn}
              setUserPin={setUserPin}
              userPin={userPin}
              loginError={loginError}
              onForgotPIN={onForgotPIN}
              handleAzureClick={handleAzureClick}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withSnackbar(EmployeeSignIn);
