import { useEffect, useState } from "react";
import { Button } from "@mui/material";

import i18next from "i18next";

function ResumeCheckInButton({ lastCheckInData, onResumeCheckIn }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timerTime = lastCheckInData?.timeRemain * 60 * 1000;

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, timerTime);

    return () => {
      clearTimeout(timer);
      setIsVisible(false);
    };

    // eslint-disable-next-line
  }, []);

  return isVisible ? (
    <div style={{ marginTop: 12 }}>
      <Button
        color="primary"
        variant="outlined"
        fullWidth={true}
        onClick={(e) => {
          e.stopPropagation();
          onResumeCheckIn(lastCheckInData);
        }}
      >
        {i18next.t("Resume Checkin")}
      </Button>
    </div>
  ) : null;
}

export default ResumeCheckInButton;
