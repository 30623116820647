const baseUrl = import.meta.env.VITE_API_BASE_URL;

const handleFileDownload = (uploadedFilePath, uploadedFileName) => {
  const url = baseUrl + uploadedFilePath;
  const link = document.createElement("a");
  link.href = url;
  link.download = `attached-file-${uploadedFileName.split(".").pop()}`;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};

export default handleFileDownload;
