import React from "react";
import Pagination from "@mui/material/Pagination";
import WeekDay from "./weekday";
import { makeStyles } from "@mui/styles";
import { isWeekStartWithMonday } from "@/utils/dateTimeFunctions";
import { Grid, Card, Hidden } from "@mui/material";
import AdditionalHourFilter from "./AdditionalHourFilter";
import Summary from "../Summary";

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(3),
    borderRadius: 16,
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    padding: 0,
  },
  gridBorder: {
    border: `0.5px solid ${theme.palette.background.neutral}`,
    width: "100%",
  },
  headerContainer: {
    display: "flex",
  },
  filterContainer: {
    width: "100%",
    display: "flex",
    marginTop: 18,
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4),
    },
  },
}));

function Table(props) {
  const classes = useStyles();

  const { state, hourReducer, selectedUser, loadMoreHours, ...others } = props;

  const weekdays = isWeekStartWithMonday()
    ? [
        { name: "Monday", index: "1" },
        { name: "Tuesday", index: "2" },
        { name: "Wednesday", index: "3" },
        { name: "Thursday", index: "4" },
        { name: "Friday", index: "5" },
        { name: "Saturday", index: "6" },
        { name: "Sunday", index: "7" },
      ]
    : [
        { name: "Sunday", index: "0" },
        { name: "Monday", index: "1" },
        { name: "Tuesday", index: "2" },
        { name: "Wednesday", index: "3" },
        { name: "Thursday", index: "4" },
        { name: "Friday", index: "5" },
        { name: "Saturday", index: "6" },
      ];

  return (
    <>
      <Hidden lgUp>
        <div className={classes.filterContainer}>
          <AdditionalHourFilter {...props} />
        </div>
      </Hidden>

      <Card className={classes.root}>
        <Grid item container sm={12}>
          {weekdays.map((data, index) => (
            <Grid
              item
              md={3}
              sm={12}
              className={classes.gridBorder}
              key={index}
            >
              <WeekDay
                key={index}
                day={data.index.toString()}
                dayName={data.name}
                state={state}
                {...others}
              />
            </Grid>
          ))}
          <Grid item md={3} sm={12} className={classes.gridBorder}>
            <Summary
              userId={state.userId}
              selectedUser={selectedUser}
              hourReducer={hourReducer}
              addonModuleAssigned={state.addonModuleAssigned}
            />
          </Grid>
        </Grid>

        {state?.pagination?.count &&
        state.limit < state.pagination.total_items ? (
          <div className={classes.paginationContainer}>
            <Pagination
              color="primary"
              count={Math.ceil(state.pagination.total_items / state.limit)}
              onChange={(e, page) => loadMoreHours((page - 1) * state.limit)}
            />
          </div>
        ) : null}
      </Card>

      <Hidden lgUp>
        <div style={{ height: 12 }} />
      </Hidden>
    </>
  );
}

export default Table;
