import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import Sidebar from "../components/Sidebar";

import useToggleMenubar from "@/hooks/useToggleMenubar";

import { actions as authActions } from "@/utils/auth";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  content: {
    height: "100%",
  },
}));

const Main = (props) => {
  const { children, path, history } = props;

  const classes = useStyles();
  const [showOnlyDashboard, setShowOnlyDashboard] = useState(false);

  const { isMenuOpen, onToggleMenubar } = useToggleMenubar();

  const setLimitedAccess = () => {
    setShowOnlyDashboard(true);
  };

  const companyToken = authActions.getCompanyToken();

  const subscriptionStatus = sessionStorage.getItem("status");
  if (subscriptionStatus && subscriptionStatus === "expired") {
    // If plan expired, and user tries to access any other page, redirect them back to index page
    if (path && path !== "/token/:token") {
      history.push("/token/" + companyToken);
    }
  }

  return (
    <div className={classes.root}>
      {path && !(path === "/token/:token" || path === "/login") && (
        <Sidebar
          onClose={onToggleMenubar}
          open={isMenuOpen}
          path={path}
          history={history}
          showOnlyDashboard={showOnlyDashboard}
          setLimitedAccess={setLimitedAccess}
        />
      )}
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
