import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import { Topbar } from "./components";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: "100%",
  },
  content: {
    height: "100%",
  },
}));

const Minimal = (props) => {
  const { children, history, location } = props;

  const [logoUrl, setLogoUrl] = useState("");

  const classes = useStyles();

  const onSetLogoURL = (url) => {
    setLogoUrl(url);
  };

  return (
    <div className={classes.root}>
      <Topbar history={history} location={location} logoUrl={logoUrl} />
      <main className={classes.content}>
        {React.cloneElement(children, {
          ...children.props,
          onSetLogoURL: onSetLogoURL,
        })}
      </main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
