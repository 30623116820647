import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Paper, Typography, Button, Grid } from "@mui/material";
import { WatchLater } from "@mui/icons-material";
import i18next from "i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  hourContainer: {
    display: "flex",
    marginTop: 10,
    marginBottom: 24,
    margin: "0px 0",
    padding: 8,
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.lighter}1a`,
  },
  timerIcon: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginRight: 4,
    position: "relative",
  },
  hourDetails: {
    display: "flex",
    alignItems: "center",
  },
  checkoutButtonContainer: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 12,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 16,
    },
  },
  checkoutButton: {
    width: "100%",
  },
  taskDetails: {
    marginLeft: 10,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  duration: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
}));

const CheckinHour = (props) => {
  const { checkInHour, checkoutAction, checkInStatus } = props;

  const classes = useStyles();

  const [timeDuration, setTimeDuration] = useState(0);

  const currentTime = moment(checkInHour.start_time)
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss.SSS");

  useEffect(() => {
    // initial set
    const timeDeference = moment().diff(moment(currentTime));
    setTimeDuration(moment.duration(timeDeference));

    // loop
    let tempId = setInterval(async () => {
      const timeDeference = moment().diff(moment(currentTime));
      const timeDuration = moment.duration(timeDeference);
      setTimeDuration(timeDuration);
    }, 2000);

    return function cleanup() {
      clearInterval(tempId);
      setTimeDuration(0);
    };
  }, [currentTime]);

  let durationStr = "";
  if (timeDuration) {
    if (timeDuration.days() > 0) {
      durationStr = `${timeDuration.days()}d ${timeDuration.hours()}${i18next.t(
        "h"
      )} ${timeDuration.minutes()}m ${timeDuration.seconds()}s`;
    } else {
      durationStr = `${timeDuration.hours()}${i18next.t(
        "h"
      )} ${timeDuration.minutes()}m ${timeDuration.seconds()}s`;
    }
  }

  return (
    <Paper className={classes.hourContainer}>
      <Grid container>
        <Grid item md={2} xs={4} className={classes.hourDetails}>
          <WatchLater className={classes.timerIcon} />
          <Typography className={classes.duration}>{durationStr}</Typography>
        </Grid>
        <Grid item md={8} xs={8} className={classes.hourDetails}>
          <div style={{ display: "flex", width: "100%" }}>
            <Typography component="div" className={classes.taskDetails}>
              {checkInHour.project_name ? checkInHour.project_name : ""},{" "}
              {checkInHour.task_name ? checkInHour.task_name : ""}
            </Typography>
          </div>
        </Grid>
        <Grid item md={2} xs={12}>
          {checkoutAction && (
            <div className={classes.checkoutButtonContainer}>
              <Button
                className={classes.checkoutButton}
                variant="outlined"
                color="error"
                onClick={async () => {
                  checkInStatus(checkInHour);
                }}
              >
                {i18next.t("Check-out")}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CheckinHour;
