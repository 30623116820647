import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import i18next from "i18next";
import moment from "moment";

export const MaterialDatePicker = ({
  label,
  format,
  value,
  onChangeDate,
  views,
  clearable,
  openTo,
  disablePast,
  disableFuture,
  ...props
}) => {
  const [date, setDate] = React.useState(value);

  React.useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <DatePicker
      label={label}
      format={format}
      views={views ? views : ["day"]}
      value={date}
      onChange={(newDate) => {
        setDate(newDate);
        if (moment(newDate).isValid()) {
          onChangeDate(newDate);
        } else if (clearable && !newDate) {
          onChangeDate(newDate);
        }
      }}
      openTo={openTo ? openTo : "day"}
      disablePast={disablePast ? disablePast : false}
      disableFuture={disableFuture ? disableFuture : false}
      localeText={{
        okButtonLabel: i18next.t("Ok"),
        cancelButtonLabel: i18next.t("Cancel"),
      }}
      slotProps={{
        toolbar: {
          hidden: true,
        },
        textField: {
          fullWidth: true,
        },
        actionBar: () => ({
          actions: clearable ? ["clear"] : [],
        }),
      }}
      {...props}
    />
  );
};

export const MaterialTimePicker = ({
  label,
  value,
  onChangeTime,
  error,
  disabled,
  variant = "standard",
  clearable = "false",
  ...props
}) => {
  const [date, setDate] = React.useState(value ? value : moment());

  React.useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <TimePicker
      label={label}
      format="HH:mm"
      value={date}
      error={error}
      disabled={disabled}
      ampm={false}
      onChange={(newDate) => {
        setDate(newDate);
        if (moment(newDate).isValid()) {
          onChangeTime(newDate);
        } else if (clearable && newDate === null) {
          onChangeTime(newDate);
        }
      }}
      slotProps={{
        toolbar: {
          hidden: true,
        },
        textField: {
          variant: variant,
          fullWidth: true,
          autoComplete: "off",
        },
        actionBar: () => ({
          actions: clearable ? ["clear"] : [],
        }),
      }}
      localeText={{
        okButtonLabel: i18next.t("Ok"),
        cancelButtonLabel: i18next.t("Cancel"),
      }}
      {...props}
    />
  );
};

export const MaterialDateTimePicker = ({
  label,
  format,
  value,
  onChange,
  disablePast,
  disableFuture,
  clearable = "false",
  ...props
}) => {
  const [date, setDate] = React.useState(value ? value : moment());

  React.useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <DateTimePicker
      label={label}
      format={format}
      value={date}
      ampm={false}
      onChange={(newDate) => {
        setDate(newDate);
        //if (moment(newDate).isValid()) {
        onChange(newDate);
        //}
      }}
      slotProps={{
        textField: {
          fullWidth: true,
        },
        actionBar: () => ({
          actions: clearable ? ["clear"] : [],
        }),
      }}
      localeText={{
        okButtonLabel: i18next.t("Ok"),
        cancelButtonLabel: i18next.t("Cancel"),
      }}
      disablePast={disablePast ? disablePast : false}
      disableFuture={disableFuture ? disableFuture : false}
      {...props}
    />
  );
};
