export const SET_ITEM = "profile/SET_ITEM";
export const CLEAR_ITEMS = "profile/CLEAR_ITEMS";

export const setParam = (key, value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ITEM,
      payload: {
        key,
        value,
      },
    });
  };
};

export const clearParams = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_ITEMS,
      payload: {},
    });
  };
};
