import React from "react";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  SvgIcon,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import makeStyles from "@mui/styles/makeStyles";
import i18next from "i18next";
import moment from "moment";
import { getDateFormat } from "@/utils/dateTimeFunctions";
import getInitials from "@/utils/getInitials";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    alignItems: "center",
    overflowX: "auto",
    borderRadius: theme.spacing(2),
    boxShadow: theme.customShadows.card,
    background: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.background.neutral}`,
  },
  listContainer: {
    maxWidth: "1024px",
    margin: "0px auto",
    textAlign: "left",
    padding: "26px 32px",
  },
  content: {
    padding: 0,
  },
  headerRow: {
    "& .MuiTableCell-root:first-of-type": {
      paddingLeft: "24px !important",
    },
    "& .MuiTableCell-root:last-of-type": {
      paddingRight: "24px !important",
    },
  },
  tableRow: {
    cursor: "pointer",
    "& .MuiTableCell-root:first-of-type": {
      paddingLeft: "24px !important",
    },
    "& .MuiTableCell-root:last-of-type": {
      paddingRight: "32px !important",
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.disabled,
  },
  itemTypeText: {
    fontSize: 14,
    fontWeight: 600,
    maxWidth: 220,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  folderIcon: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  projectName: {
    fontSize: 14,
    maxWidth: 240,
  },
  expiryDateText: {
    fontSize: 14,
    maxWidth: 240,
    color: theme.palette.text.secondary,
  },
  heading: {
    maxWidth: "1024px",
    margin: "0px auto",
    textAlign: "left",
    paddingLeft: "32px",
    paddingTop: "26px",
    color: theme.palette.text.primary,
  },
  errorText: {
    color: theme.palette.error.main,
    paddingLeft: "32px",
    paddingTop: "18px",
  },
  successText: {
    color: theme.palette.success.main,
    paddingLeft: "32px",
    paddingTop: "18px",
  },
}));

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const ItemList = ({ itemList, errorMsg, successMsg, handleEditClick }) => {
  const classes = useStyles();

  return (
    <Typography component="div">
      {errorMsg && (
        <Typography variant="body1" className={classes.errorText}>
          {errorMsg}
        </Typography>
      )}
      {successMsg && (
        <Typography variant="body1" className={classes.successText}>
          {successMsg}
        </Typography>
      )}
      <Typography component="div" className={classes.listContainer}>
        <Card className={classes.root}>
          <CardHeader
            title={i18next.t("Recent items")}
            titleTypographyProps={{
              fontSize: 20,
            }}
          />

          <CardContent className={classes.content}>
            <TableContainer style={{ padding: "16px 8px" }}>
              <Table aria-label="items table">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    <TableCell>{i18next.t("Date")}</TableCell>
                    <TableCell align="left">{i18next.t("Item type")}</TableCell>
                    <TableCell align="left">
                      {i18next.t("item-amount")}
                    </TableCell>
                    <TableCell align="left">
                      {i18next.t("Assigned to")}
                    </TableCell>
                    <TableCell align="left">
                      {i18next.t("Created by")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemList && itemList.length > 0 ? (
                    itemList.map((item) => {
                      return (
                        <Row
                          item={item}
                          handleEditClick={handleEditClick}
                          key={item.id}
                        />
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        {i18next.t("No record found")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Typography>
    </Typography>
  );
};

function Row({ item, handleEditClick }) {
  const classes = useStyles();
  const assignedToProject = item.itemable_type === "Task";

  return (
    <TableRow
      sx={{
        "& > *": { borderBottom: "unset" },
      }}
      className={classes.tableRow}
      hover
      onClick={() => {
        handleEditClick(item.id);
      }}
    >
      <TableCell align="left">
        <Typography noWrap variant="body2">
          {moment(item.date).format(getDateFormat())}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography className={`${classes.itemTypeText}`}>
          {item.item_type_name ? item.item_type_name : ""}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography noWrap variant="body2">
          {item.amount ? item.amount : "0"}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography component="div">
          {assignedToProject ? (
            <Typography component="div" style={{ display: "flex" }}>
              <SvgIcon
                className={classes.folderIcon}
                style={{
                  color: item.project_color ? item.project_color : "#18B24B",
                }}
              >
                <FolderIcon />
              </SvgIcon>
              <Typography component="div">
                <Typography noWrap className={classes.projectName}>
                  {item.project_name ? item.project_name : ""}
                </Typography>
                <Typography noWrap className={classes.expiryDateText}>
                  {item.task_name ? item.task_name : ""}
                </Typography>
              </Typography>
            </Typography>
          ) : (
            <Typography component="div" style={{ display: "flex" }}>
              <Avatar
                className={classes.avatar}
                src={
                  item.user_avatar_thumb_url
                    ? `${baseUrl}${item.user_avatar_thumb_url}`
                    : null
                }
              >
                {getInitials(item.user_name ? item.user_name : "")}
              </Avatar>
              <Typography component="div">
                <Typography noWrap className={classes.projectName}>
                  {item.user_name ? item.user_name : ""}
                </Typography>
                <Typography noWrap className={classes.expiryDateText}>
                  {`${i18next.t("Expiry date")} ${": "} ${
                    item.expiration_date
                      ? moment(item.expiration_date).format(getDateFormat())
                      : `${i18next.t("Not available")}`
                  }`}
                </Typography>
              </Typography>
            </Typography>
          )}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          component="div"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Avatar
            className={classes.avatar}
            src={
              item.created_by_avatar_thumb_url
                ? `${baseUrl}${item.created_by_avatar_thumb_url}`
                : null
            }
          >
            {getInitials(item?.created_by_name ? item.created_by_name : "")}
          </Avatar>
          <Typography noWrap variant="body2">
            {item?.created_by_name}
          </Typography>
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default ItemList;
