import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

import Sidebar from "../components/Sidebar";
import { Topbar } from "./components";

import useToggleMenubar from "@/hooks/useToggleMenubar";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  content: {
    height: "100%",
  },
}));

const Main = (props) => {
  const { children, title, history, location, path } = props;

  const classes = useStyles();
  const [showOnlyDashboard, setShowOnlyDashboard] = useState(false);

  const { isMenuOpen, onToggleMenubar } = useToggleMenubar();

  const setLimitedAccess = () => {
    setShowOnlyDashboard(true);
  };

  return (
    <div className={classes.root}>
      <Topbar
        onToggleMenubar={onToggleMenubar}
        title={title}
        history={history}
        location={location}
        showOnlyDashboard={showOnlyDashboard}
      />
      <Sidebar
        onClose={onToggleMenubar}
        open={isMenuOpen}
        path={path}
        history={history}
        showOnlyDashboard={showOnlyDashboard}
        setLimitedAccess={setLimitedAccess}
      />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
