import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import moment from "moment";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  btnGreen: {
    fontSize: 16,
    fontWeight: 700,
    borderRadius: "0.5rem",
    whiteSpace: "nowrap",
    width: "100%",
  },
  btnOutline: {
    fontSize: 16,
    fontWeight: 700,
    width: "100%",
    whiteSpace: "nowrap",
  },
  actionButtons: {
    padding: "16px !important",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  btnCancel: {
    color: theme.palette.text.secondary,
  },
}));

export default function ResumeCheckInDialog({
  open,
  data,
  handleClose,
  handleCheckInButtonClick,
  handleResumeCheckInButtonClick,
}) {
  const classes = useStyles();

  let startTimeFormatted = "";
  let endTimeFormatted = "";

  if (data?.startTime && data?.endTime) {
    startTimeFormatted = moment(data?.startTime)
      .utc()
      .format("ddd. MMMM Do, HH:mm");
    endTimeFormatted = moment(data?.endTime).format("HH:mm");
  }

  const onResumeButtonClick = () => {
    handleResumeCheckInButtonClick(data);
    handleClose();
  };

  const onNewCheckInButtonClick = () => {
    handleCheckInButtonClick();
    handleClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>{i18next.t("Resume Checkin")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18next.t("A previous registration is available to resume ")}
          <div>
            {startTimeFormatted} - {endTimeFormatted}
          </div>
        </DialogContentText>
        <Stack spacing={1} style={{ marginTop: "16px" }}>
          <Button
            aria-label="resume-check-in"
            onClick={onResumeButtonClick}
            id="check-in-fab-button"
            fullWidth
            className={classes.btnGreen}
            variant="contained"
          >
            {i18next.t("Resume Checkin")}
          </Button>
          <Button
            variant="outlined"
            className={classes.btnOutline}
            fullWidth
            nowrap="true"
            onClick={onNewCheckInButtonClick}
            id="request-leave-button"
          >
            {i18next.t("Start New Checkin")}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button className={classes.btnCancel} onClick={handleClose}>
          {i18next.t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
