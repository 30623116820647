import * as types from "./types";
import { core } from "./core";
import moment from "moment";

export const getProjectHourTypesSummary = (data) => {
  return core.API(
    "GET",
    `/project/${data.id}/hour-types${
      data.fromDate
        ? `?start_time=${moment(data.fromDate).format("YYYY-MM-DD")}`
        : ""
    }${
      data.toDate ? `&end_time=${moment(data.toDate).format("YYYY-MM-DD")}` : ""
    }`,
    types.GET_PROJECT_HOUR_TYPE,
    types.GET_PROJECT_HOUR_TYPE_SUCCESS,
    types.GET_PROJECT_HOUR_TYPE_FAILURE,
    null,
    { id: data.id }
  );
};

export const getProjectAddonTypesSummary = (data) => {
  return core.API(
    "GET",
    `/project/${data.id}/addon-types${
      data.fromDate
        ? `?start_time=${moment(data.fromDate).format("YYYY-MM-DD")}`
        : ""
    }${
      data.toDate ? `&end_time=${moment(data.toDate).format("YYYY-MM-DD")}` : ""
    }`,
    types.GET_PROJECT_ADDON_TYPE,
    types.GET_PROJECT_ADDON_TYPE_SUCCESS,
    types.GET_PROJECT_ADDON_TYPE_FAILURE,
    null,
    { id: data.id }
  );
};

export const getHourTypes = (id, exception_types) => {
  return core.API(
    "GET",
    `/hour-types?limit=200&offset=0${id ? `&user_id=${id}` : ""}${
      exception_types ? `&exception_types=${exception_types}` : ""
    }`,
    types.GET_HOUR_TYPES,
    types.GET_HOUR_TYPES_SUCCESS,
    types.GET_HOUR_TYPES_FAILURE
  );
};

export const getDepartmentsHourTypes = (data) => {
  const limit = data && data.limit ? data.limit : 50;
  return core.API(
    "GET",
    `/company/departments/?more_details=false&limit=${limit}`,
    types.GET_DEPARTMENTS_HOUR_TYPES,
    types.GET_DEPARTMENTS_HOUR_TYPES_SUCCESS,
    types.GET_DEPARTMENTS_HOUR_TYPES_FAILURE,
    data
  );
};

export const createHourTypes = (data) => {
  return core.API(
    "POST",
    `/hour-type/`,
    types.CREATE_HOUR_TYPES,
    types.CREATE_HOUR_TYPES_SUCCESS,
    types.CREATE_HOUR_TYPES_FAILURE,
    data
  );
};

export const deleteHourTypes = (id) => {
  return core.API(
    "DELETE",
    `/hour-type/${id}`,
    types.DELETE_HOUR_TYPES,
    types.DELETE_HOUR_TYPES_SUCCESS,
    types.DELETE_HOUR_TYPES_FAILURE
  );
};

export const putHourTypes = (id, data) => {
  return core.API(
    "PUT",
    `/hour-type/${id}/`,
    types.PUT_HOUR_TYPES,
    types.PUT_HOUR_TYPES_SUCCESS,
    types.PUT_HOUR_TYPES_FAILURE,
    data
  );
};

export const getOptionalHourTypes = (id) => {
  return core.API(
    "GET",
    `/hour-type/${id}/optional`,
    types.GET_OPTIONAL_HOUR_TYPES,
    types.GET_OPTIONAL_HOUR_TYPES_SUCCESS,
    types.GET_OPTIONAL_HOUR_TYPES_FAILURE
  );
};

export const setOptionalHourType = (id, data) => {
  return core.API(
    "PUT",
    `/hour/${id}/optional-hour-type`,
    types.SET_OPTIONAL_HOUR_TYPE,
    types.SET_OPTIONAL_HOUR_TYPE_SUCCESS,
    types.SET_OPTIONAL_HOUR_TYPE_FAILURE,
    data
  );
};
