import React from "react";
import CheckInDialog from "./CheckInDialog";
import CheckOutDialog from "./CheckOutDialog";
import ErrorDialog from "./ErrorDialog";

const Alerts = ({
  open,
  operation, // check-in | check-out | error
  message,
  onClose,
}) => {
  if (!open) return null;
  switch (operation) {
    case "check-in":
      return <CheckInDialog open={open} message={message} onClose={onClose} />;
    case "check-out":
      return <CheckOutDialog open={open} message={message} onClose={onClose} />;
    case "error":
      return <ErrorDialog open={open} message={message} onClose={onClose} />;
    default:
      return null;
  }
};

export default Alerts;
