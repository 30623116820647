import React from "react";
import { Switch, Redirect } from "react-router-dom";
import RouteWithLayout from "@/common/RouteWithLayout";
import { Minimal as MinimalLayout } from "@/layouts";
import { Main as MainLayout } from "@/layouts";
import { Custom as CustomLayout } from "@/layouts";

import {
  Main as MainView,
  GuestDashboard as GuestView,
  GuestCheckIn as GuestCheckInView,
  GuestCheckOut as GuestCheckOutView,
  EmployeeSignIn as EmployeeSignInView,
  Attendance as AttendanceView,
  CreatePin as CreatePinView,
  Hours as HoursView,
  Planning as PlanningView,
  PlannedHours as PlannedHoursView,
  Dashboard as DashboardView,
  Items as ItemsView,
  NotFound as NotFoundView,
  AddUser as AddUserView,
  Logout as LogoutView,
  CheckList as CheckListView,
  CheckListDetail as checkListDetailView,
  AddCheckList as AddCheckListView,
  ProjectSetting as ProjectSettingView,
  KioskMode as KioskModeView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      {/*<Redirect exact from="/" to="/main" />*/}
      <RouteWithLayout
        component={MainView}
        exact
        layout={CustomLayout}
        path="/token/:token"
      />
      <RouteWithLayout
        component={GuestView}
        exact
        layout={MinimalLayout}
        path="/guest"
      />
      <RouteWithLayout
        component={GuestView}
        exact
        layout={MinimalLayout}
        path="/guest"
      />
      <RouteWithLayout
        component={GuestCheckInView}
        exact
        layout={MinimalLayout}
        path="/guest/check-in"
      />
      <RouteWithLayout
        component={GuestCheckOutView}
        exact
        layout={MinimalLayout}
        path="/guest/check-out"
      />
      <RouteWithLayout
        component={EmployeeSignInView}
        exact
        layout={CustomLayout}
        path="/login"
      />
      <RouteWithLayout
        component={CreatePinView}
        exact
        layout={MainLayout}
        path="/employee/create-pin"
        title="Create PIN"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
        title="Dashboard"
      />
      <RouteWithLayout
        component={HoursView}
        exact
        layout={CustomLayout}
        path="/hours"
        title="Hours"
      />
      <RouteWithLayout
        component={PlanningView}
        exact
        layout={CustomLayout}
        path="/planning"
        title="Planning"
      />
      <RouteWithLayout
        component={PlannedHoursView}
        exact
        layout={CustomLayout}
        path="/planned-hours"
        title="Planned hours"
      />
      <RouteWithLayout
        component={ItemsView}
        exact
        layout={CustomLayout}
        path="/items"
        title="Items"
      />
      <RouteWithLayout
        component={AttendanceView}
        exact
        layout={CustomLayout}
        path="/attendance"
        title="Attendance"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={AddUserView}
        exact
        layout={MainLayout}
        path="/employee/add-user"
        title="Add user"
      />
      <RouteWithLayout
        component={CheckListView}
        exact
        layout={CustomLayout}
        path="/hmsk/checkList"
        title="Check list"
      />
      <RouteWithLayout
        component={checkListDetailView}
        exact
        layout={CustomLayout}
        path="/hmsk/checkListDetail/:id"
        title="Checklist details"
      />
      <RouteWithLayout
        component={AddCheckListView}
        exact
        layout={CustomLayout}
        path="/hmsk/addCheckList/"
        title="Your checklists"
      />
      <RouteWithLayout
        component={LogoutView}
        exact
        layout={MainLayout}
        path="/logout"
        title="Sign out"
      />
      <RouteWithLayout
        component={ProjectSettingView}
        exact
        layout={MinimalLayout}
        path="/login/projectsetting"
        title="Project Setting"
      />
      <RouteWithLayout
        component={KioskModeView}
        exact
        layout={MinimalLayout}
        path="/kiosk/:token"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
